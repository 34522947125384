import { Box, Code, Text, useDisclosure } from '@chakra-ui/react';
import React from 'react';

import SCLogo from '@app/icons/LogoSmarterContactIcon.svg?react';
import { getVersionString } from '@app/utils/buildInfo';
import { CopyToClipboard } from '../CopyToClipboard';
import Popup from '../Popup';

const SCClickableLogo: React.FC = () => {
  const versionModal = useDisclosure();

  function onLogoClick() {
    versionModal.onOpen();
  }

  const feBuild = getVersionString();

  return (
    <>
      <SCLogo
        onClick={(e) => {
          if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
            e.preventDefault();
            onLogoClick();
            return false;
          }
        }}
      />

      <Popup
        isOpen={versionModal.isOpen}
        maxW="566px"
        title="System Info"
        onClose={versionModal.onClose}>
        <Box margin="35px 40px">
          <Code display="flex" flexDirection="column" gap="5px" padding="20px">
            <Text>
              <Text display="inline-block" fontWeight="bold">
                FE build:&nbsp;
              </Text>
              {feBuild}
            </Text>
          </Code>
          <CopyToClipboard
            withLabel
            copy={getVersionString()}
            marginTop="20px"
          />
        </Box>
      </Popup>
    </>
  );
};

export default SCClickableLogo;
