import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
  Email: { input: string; output: string };
  Money: { input: number; output: number };
  PhoneNumberType: { input: string; output: string };
};

/** A2P brand */
export type A2PBrand = {
  __typename?: 'A2PBrand';
  city: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createWebsite: Scalars['Boolean']['output'];
  customerCareEmail?: Maybe<Scalars['String']['output']>;
  customerCarePhone?: Maybe<Scalars['String']['output']>;
  ein: Scalars['String']['output'];
  einFile?: Maybe<BrandRegistrationFile>;
  einIssuingCountry: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  incorporationDate?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  termsAndConditionsUrl?: Maybe<Scalars['String']['output']>;
  vertical: A2PBrandVertical;
  website?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

/** A2P brand campaign */
export type A2PBrandCampaign = {
  __typename?: 'A2PBrandCampaign';
  description: Scalars['String']['output'];
  helpKeywords: Array<Scalars['String']['output']>;
  helpMessage: Scalars['String']['output'];
  messageFlow: Scalars['String']['output'];
  optInKeywords: Array<Scalars['String']['output']>;
  optInMessage: Scalars['String']['output'];
  optOutKeywords: Array<Scalars['String']['output']>;
  optOutMessage: Scalars['String']['output'];
  sample1: Scalars['String']['output'];
  sample2: Scalars['String']['output'];
  sendAffiliateMarketing: Scalars['Boolean']['output'];
  sendAgeGatedContent: Scalars['Boolean']['output'];
  sendDirectLending: Scalars['Boolean']['output'];
  sendHelp: Scalars['Boolean']['output'];
  sendLinks: Scalars['Boolean']['output'];
  sendOptIn: Scalars['Boolean']['output'];
  sendOptOut: Scalars['Boolean']['output'];
  sendPhones: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  usecase: A2PBrandCampaignUseCase;
};

export type A2PBrandCampaignInput = {
  description: Scalars['String']['input'];
  messageFlow: Scalars['String']['input'];
  sample1: Scalars['String']['input'];
  sample2: Scalars['String']['input'];
  sendPhones: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  usecase: A2PBrandCampaignUseCase;
};

/** A2P brand campaign use case */
export enum A2PBrandCampaignUseCase {
  ACCOUNT_NOTIFICATION = 'ACCOUNT_NOTIFICATION',
  /** @deprecated Legacy */
  AGENTS_FRANCHISES = 'AGENTS_FRANCHISES',
  /** @deprecated Legacy */
  CARRIER_EXEMPT = 'CARRIER_EXEMPT',
  /** @deprecated Legacy */
  CHARITY = 'CHARITY',
  CUSTOMER_CARE = 'CUSTOMER_CARE',
  /** @deprecated Legacy */
  DELIVERY_NOTIFICATION = 'DELIVERY_NOTIFICATION',
  /** @deprecated Legacy */
  EMERGENCY = 'EMERGENCY',
  /** @deprecated Legacy */
  EMPTY = 'EMPTY',
  FRAUD_ALERT = 'FRAUD_ALERT',
  /** @deprecated Legacy */
  HIGHER_EDUCATION = 'HIGHER_EDUCATION',
  /** @deprecated Legacy */
  K12_EDUCATION = 'K12_EDUCATION',
  /** @deprecated Legacy */
  LOW_VOLUME = 'LOW_VOLUME',
  /** @deprecated Legacy */
  M2M = 'M2M',
  MARKETING = 'MARKETING',
  /** @deprecated Legacy */
  MIXED = 'MIXED',
  /** @deprecated Legacy */
  POLITICAL = 'POLITICAL',
  /** @deprecated Legacy */
  POLLING_VOTING = 'POLLING_VOTING',
  /** @deprecated Legacy */
  PROXY = 'PROXY',
  PUBLIC_SERVICE_ANNOUNCEMENT = 'PUBLIC_SERVICE_ANNOUNCEMENT',
  /** @deprecated Legacy */
  SECURITY_ALERT = 'SECURITY_ALERT',
  /** @deprecated Legacy */
  SOCIAL = 'SOCIAL',
  /** @deprecated Legacy */
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  /** @deprecated Legacy */
  STARTER = 'STARTER',
  /** @deprecated Legacy */
  SWEEPSTAKE = 'SWEEPSTAKE',
  /** @deprecated Legacy */
  TRIAL = 'TRIAL',
  /** @deprecated Legacy */
  TWO_FACTOR_AUTH = 'TWO_FACTOR_AUTH',
  /** @deprecated Legacy */
  UCAAS_HIGH = 'UCAAS_HIGH',
  /** @deprecated Legacy */
  UCAAS_LOW = 'UCAAS_LOW',
}

export type A2PBrandInput = {
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  country: Scalars['String']['input'];
  createWebsite?: InputMaybe<Scalars['Boolean']['input']>;
  customerCareEmail?: InputMaybe<Scalars['String']['input']>;
  customerCarePhone?: InputMaybe<Scalars['String']['input']>;
  ein: Scalars['String']['input'];
  einFile?: InputMaybe<UploadEinFileInput>;
  einIssuingCountry: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  incorporationDate: Scalars['DateTime']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  termsAndConditionsUrl?: InputMaybe<Scalars['String']['input']>;
  vertical: A2PBrandVertical;
  website?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

/** A2P brand vertical */
export enum A2PBrandVertical {
  AGRICULTURE = 'AGRICULTURE',
  /** @deprecated Legacy */
  AUTOMOTIVE = 'AUTOMOTIVE',
  /** @deprecated Legacy */
  BANKING = 'BANKING',
  COMMUNICATION = 'COMMUNICATION',
  CONSTRUCTION = 'CONSTRUCTION',
  /** @deprecated Legacy */
  CONSUMER = 'CONSUMER',
  EDUCATION = 'EDUCATION',
  /** @deprecated Legacy */
  ELECTRONICS = 'ELECTRONICS',
  /** @deprecated Legacy */
  EMPTY = 'EMPTY',
  ENERGY = 'ENERGY',
  /** @deprecated Legacy */
  ENGINEERING = 'ENGINEERING',
  ENTERTAINMENT = 'ENTERTAINMENT',
  /** @deprecated Legacy */
  FAST_MOVING_CONSUMER_GOODS = 'FAST_MOVING_CONSUMER_GOODS',
  FINANCIAL = 'FINANCIAL',
  /** @deprecated Legacy */
  FINTECH = 'FINTECH',
  /** @deprecated Legacy */
  FOOD_AND_BEVERAGE = 'FOOD_AND_BEVERAGE',
  GAMBLING = 'GAMBLING',
  /** @deprecated Legacy */
  GOVERNMENT = 'GOVERNMENT',
  HEALTHCARE = 'HEALTHCARE',
  HOSPITALITY = 'HOSPITALITY',
  /** @deprecated Legacy */
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
  INSURANCE = 'INSURANCE',
  /** @deprecated Legacy */
  JEWELRY = 'JEWELRY',
  /** @deprecated Legacy */
  LEGAL = 'LEGAL',
  MANUFACTURING = 'MANUFACTURING',
  /** @deprecated Legacy */
  MEDIA = 'MEDIA',
  NGO = 'NGO',
  /** @deprecated Legacy */
  NOT_FOR_PROFIT = 'NOT_FOR_PROFIT',
  /** @deprecated Legacy */
  OIL_AND_GAS = 'OIL_AND_GAS',
  /** @deprecated Legacy */
  ONLINE = 'ONLINE',
  /** @deprecated Legacy */
  POLITICAL = 'POLITICAL',
  /** @deprecated Legacy */
  POSTAL = 'POSTAL',
  /** @deprecated Legacy */
  PROFESSIONAL = 'PROFESSIONAL',
  /** @deprecated Legacy */
  RAW_MATERIALS = 'RAW_MATERIALS',
  REAL_ESTATE = 'REAL_ESTATE',
  /** @deprecated Legacy */
  RELIGION = 'RELIGION',
  RETAIL = 'RETAIL',
  TECHNOLOGY = 'TECHNOLOGY',
  /** @deprecated Legacy */
  TELECOMMUNICATIONS = 'TELECOMMUNICATIONS',
  /** @deprecated Legacy */
  TRANSPORTATION = 'TRANSPORTATION',
  /** @deprecated Legacy */
  TRAVEL = 'TRAVEL',
}

/** A2P brand registration general info */
export type A2PInfo = {
  __typename?: 'A2PInfo';
  brandId?: Maybe<Scalars['String']['output']>;
  campaignId?: Maybe<Scalars['String']['output']>;
  incorporationDate?: Maybe<Scalars['DateTime']['output']>;
  maxTpm: Scalars['Float']['output'];
  status: A2PInfoStatus;
  statusUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** A2P brand registration general status */
export enum A2PInfoStatus {
  APPROVED = 'APPROVED',
  DEACTIVATED = 'DEACTIVATED',
  DECLINED = 'DECLINED',
  NONE = 'NONE',
  PENDING = 'PENDING',
  REACTIVATION_SUBMITTED = 'REACTIVATION_SUBMITTED',
  REVIEW = 'REVIEW',
}

/** A2P registration all info */
export type A2PRegistration = {
  __typename?: 'A2PRegistration';
  brand: A2PBrand;
  campaign: A2PBrandCampaign;
  ownPhone?: Maybe<OwnPhone>;
};

/** Account */
export type Account = {
  __typename?: 'Account';
  a2PStatus?: Maybe<A2PInfoStatus>;
  affiliate?: Maybe<Affiliate>;
  analytics?: Maybe<AccountAnalytics>;
  billingProfile?: Maybe<BillingProfile>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Return dialer status. */
  dialerStatus: DialerStatus;
  email: Scalars['Email']['output'];
  /** List of account features */
  features?: Maybe<Array<Feature>>;
  firestoreId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  inBusinessHours: Scalars['Boolean']['output'];
  isOnboarded: Scalars['Boolean']['output'];
  isSubAccount: Scalars['Boolean']['output'];
  isTest: Scalars['Boolean']['output'];
  messagingProfile?: Maybe<MessagingProfile>;
  name?: Maybe<Name>;
  ownerId: Scalars['String']['output'];
  parentAccount?: Maybe<Account>;
  permissions: AccountPermissionsView;
  phone?: Maybe<Scalars['String']['output']>;
  products: Array<Product>;
  questionnaire?: Maybe<AccountQuestionnaire>;
  quotasUsage: Array<QuotaUsage>;
  secondaryPlans?: Maybe<Array<Plan>>;
  secondarySubscriptions?: Maybe<Array<CurrentSubscription>>;
  settings?: Maybe<AccountSettings>;
  status: AccountStatus;
  subAccounts?: Maybe<Array<Account>>;
  subscription?: Maybe<CurrentSubscription>;
  subscriptions?: Maybe<Array<CurrentSubscription>>;
  summary: AccountSummary;
  timeZone?: Maybe<Scalars['String']['output']>;
  tollFreeStatus?: Maybe<TollFreeInfoStatus>;
  type: AccountType;
};

/** Account analytics */
export type AccountAnalytics = {
  __typename?: 'AccountAnalytics';
  campaign?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  fbclid?: Maybe<Scalars['String']['output']>;
  gclid?: Maybe<Scalars['String']['output']>;
  googleClientId?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  msclkid?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AccountAnalyticsInput = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  fbclid?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  googleClientId?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  msclkid?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Account counters */
export type AccountCounters = {
  __typename?: 'AccountCounters';
  activePrimary?: Maybe<Scalars['Int']['output']>;
  activeSecondary?: Maybe<Scalars['Int']['output']>;
  inactive?: Maybe<Scalars['Int']['output']>;
  monthNewActive?: Maybe<Scalars['Int']['output']>;
  plans: Array<AccountCountersPlan>;
  trial?: Maybe<Scalars['Int']['output']>;
};

/** Account counters plan */
export type AccountCountersPlan = {
  __typename?: 'AccountCountersPlan';
  total?: Maybe<Scalars['Int']['output']>;
  type: PlanType;
};

export type AccountList = {
  __typename?: 'AccountList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Account>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** The view of restricted actions outside business hours for an account */
export type AccountOutsideBusinessHoursRestrictionsView = {
  __typename?: 'AccountOutsideBusinessHoursRestrictionsView';
  accountId: Scalars['String']['output'];
  restriction: OutsideBusinessHoursRestrictionType;
};

/** Account password */
export type AccountPassword = {
  __typename?: 'AccountPassword';
  password: Scalars['String']['output'];
};

/** Account permissions */
export type AccountPermissionsView = {
  __typename?: 'AccountPermissionsView';
  allowCrmIntegrations: Scalars['Boolean']['output'];
  allowDripCampaign: Scalars['Boolean']['output'];
  allowKeywordCampaign: Scalars['Boolean']['output'];
  allowOptOutDisable: Scalars['Boolean']['output'];
  allowScheduleCampaign: Scalars['Boolean']['output'];
  allowThrottleCampaign: Scalars['Boolean']['output'];
  optOutDisabled: Scalars['Boolean']['output'];
};

/** Account popup */
export type AccountPopup = {
  __typename?: 'AccountPopup';
  accountId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  popupId: Scalars['String']['output'];
};

/** Account questionnaire */
export type AccountQuestionnaire = {
  __typename?: 'AccountQuestionnaire';
  employees?: Maybe<Scalars['String']['output']>;
  experience?: Maybe<Scalars['String']['output']>;
  industry?: Maybe<Scalars['String']['output']>;
  marketingChannels?: Maybe<Array<Scalars['String']['output']>>;
  marketingTraining?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  realEstate?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  smsMarketing?: Maybe<Scalars['String']['output']>;
};

export type AccountQuestionnaireInput = {
  employees: Scalars['String']['input'];
  industry: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

/** Account settings */
export type AccountSettings = {
  __typename?: 'AccountSettings';
  defaultLabelColor?: Maybe<Scalars['String']['output']>;
  openNewTab?: Maybe<Scalars['Boolean']['output']>;
  refreshRate?: Maybe<Scalars['Float']['output']>;
  viewedPopups?: Maybe<Array<Scalars['String']['output']>>;
};

/** The ID for a given popup */
export enum AccountSettingsPopup {
  anyPasswordReset = 'anyPasswordReset',
  campaignNewTemplatesModal = 'campaignNewTemplatesModal',
  campaignPromoModal = 'campaignPromoModal',
  firstLogin = 'firstLogin',
  forcePasswordReset = 'forcePasswordReset',
  newTermsAndConditions = 'newTermsAndConditions',
  passwordResetPopup = 'passwordResetPopup',
  popupOnboarding = 'popupOnboarding',
  popupReferralPromo = 'popupReferralPromo',
  popupTrialFirstDay = 'popupTrialFirstDay',
  popupTrialSecondDay = 'popupTrialSecondDay',
  showPasswordResetPopup = 'showPasswordResetPopup',
  softPasswordReset = 'softPasswordReset',
}

/** Account status */
export enum AccountStatus {
  ABANDONED = 'ABANDONED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  TRIAL = 'TRIAL',
}

/** Account summary */
export type AccountSummary = {
  __typename?: 'AccountSummary';
  /** @deprecated The type will be changed to `ProfileBIlling` in the on dialer step */
  lastBilling?: Maybe<LastBilling>;
  productsFees?: Maybe<Array<ProfileProductFees>>;
  totalBilled?: Maybe<Scalars['Int']['output']>;
  totalBilledToDate?: Maybe<Scalars['Int']['output']>;
  /** @deprecated User `productsFees` instead */
  totalMembershipFees?: Maybe<Scalars['Int']['output']>;
  totalSkiptraceUsed?: Maybe<Scalars['Int']['output']>;
  totalSmsUsed?: Maybe<Scalars['Int']['output']>;
  trialPeriod?: Maybe<Period>;
};

/** Account type */
export enum AccountType {
  REAL_ESTATE = 'REAL_ESTATE',
  UNIVERSAL = 'UNIVERSAL',
}

export type AccountsFilter = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  fullSearch?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Accounts summary */
export type AccountsSummary = {
  __typename?: 'AccountsSummary';
  products: Array<ProductSummary>;
  subscriptions: SubscriptionsSummary;
};

export type AddContactToDncInput = {
  contactId: Scalars['String']['input'];
};

export type AddGroupToContactsInput = {
  groupId: Scalars['String']['input'];
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AddLabelToCallAndContactInput = {
  applicationCallId: Scalars['String']['input'];
  batchId: Scalars['String']['input'];
  labelId: Scalars['String']['input'];
};

export type AddLabelsToContactsInput = {
  ids: Array<Scalars['String']['input']>;
  labelIds: Array<Scalars['String']['input']>;
};

export type AdminAddOwnPhoneInput = {
  accountId: Scalars['String']['input'];
  phone: Scalars['PhoneNumberType']['input'];
  scope?: InputMaybe<Scope>;
  type?: InputMaybe<OwnPhoneType>;
};

export type AdminChangeAccountMessagingProviderInput = {
  accountId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider: MessagingProviderType;
};

export type AdminChangeSubAccountMessagingProviderInput = {
  accountId: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AdminRegisterAffiliateLeadInput = {
  accountId: Scalars['String']['input'];
  affiliateId: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AdminRemoveOwnPhoneInput = {
  phone: Scalars['String']['input'];
};

export type AdminUpdateAccountInput = {
  email?: InputMaybe<Scalars['Email']['input']>;
  enterpriseLevel?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  isOnboarded?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  optOutDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptions?: InputMaybe<Array<UpdateAccountSubscriptionInput>>;
};

/** Affiliate */
export type Affiliate = {
  __typename?: 'Affiliate';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['Email']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  referralId: Scalars['String']['output'];
  referralLink: Scalars['String']['output'];
};

export type AffiliateFilter = {
  fullSearch?: InputMaybe<Scalars['String']['input']>;
};

export type AffiliateList = {
  __typename?: 'AffiliateList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Affiliate>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Possible machine detection results */
export enum AmdResult {
  FAILED = 'FAILED',
  HUMAN = 'HUMAN',
  MACHINE = 'MACHINE',
  UNKNOWN = 'UNKNOWN',
}

export type ApplyDiscountInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  coupon?: InputMaybe<Scalars['String']['input']>;
};

/** Article */
export type Article = {
  __typename?: 'Article';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

/** Available phone */
export type AvailablePhoneView = {
  __typename?: 'AvailablePhoneView';
  phone: Scalars['String']['output'];
};

/** Balance */
export type Balance = {
  __typename?: 'Balance';
  balance: Scalars['Money']['output'];
  firstTopUpBonusApplied: Scalars['Boolean']['output'];
  lastTopUp?: Maybe<LastTopUp>;
  schedule?: Maybe<BalanceSchedule>;
  topUpAmount: Scalars['Money']['output'];
  topUpBonus: Scalars['Money']['output'];
};

/** Balance schedule */
export type BalanceSchedule = {
  __typename?: 'BalanceSchedule';
  amount: Scalars['Money']['output'];
  enabled: Scalars['Boolean']['output'];
  limit: Scalars['Money']['output'];
};

/** Billing interval */
export enum BillingInterval {
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

/** Billing profile */
export type BillingProfile = {
  __typename?: 'BillingProfile';
  backupPaymentMethod: Scalars['Boolean']['output'];
  balance?: Maybe<Balance>;
  email: Scalars['Email']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Last billing */
  lastBilling?: Maybe<ProfileBilling>;
  productsFees?: Maybe<Array<ProfileProductFees>>;
  sources?: Maybe<Array<PaymentSource>>;
  unsubscribeCoupons?: Maybe<Array<Scalars['String']['output']>>;
};

/** Billing report */
export type BillingReport = {
  __typename?: 'BillingReport';
  url: Scalars['String']['output'];
};

export type BlockContactsInput = {
  labelIds: Array<Scalars['String']['input']>;
};

/** Brand registration file */
export type BrandRegistrationFile = {
  __typename?: 'BrandRegistrationFile';
  contentType: Scalars['String']['output'];
  filePath: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type BuildMessageTemplateInput = {
  content: Scalars['String']['input'];
  optOut: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};

/** Calendar event */
export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  relationId?: Maybe<Scalars['String']['output']>;
  startAt: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  type: CalendarEventType;
};

export type CalendarEventList = {
  __typename?: 'CalendarEventList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<CalendarEvent>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Calendar event type */
export enum CalendarEventType {
  CAMPAIGN = 'CAMPAIGN',
  CONTACT = 'CONTACT',
  FOLLOWUP = 'FOLLOWUP',
}

export type CalendarEventsFilter = {
  endAt?: InputMaybe<QueryDateFilter>;
  startAt?: InputMaybe<QueryDateFilter>;
};

/** Call mode */
export enum CallMode {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

/** Call recording action */
export enum CallRecordingAction {
  PAUSE = 'PAUSE',
  RESUME = 'RESUME',
  START = 'START',
  STOP = 'STOP',
}

/** Call Script */
export type CallScript = {
  __typename?: 'CallScript';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CallScriptList = {
  __typename?: 'CallScriptList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<CallScript>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CallScriptsFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Call settings */
export type CallSettings = {
  __typename?: 'CallSettings';
  activeIn?: Maybe<Scalars['String']['output']>;
  callAutoReply?: Maybe<CallSettingsAutoReply>;
  isSipEnabled?: Maybe<Scalars['Boolean']['output']>;
  voiceMail?: Maybe<CallSettingsAutoReply>;
};

/** Call settings auto reply */
export type CallSettingsAutoReply = {
  __typename?: 'CallSettingsAutoReply';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  voiceType?: Maybe<CallSettingsVoiceType>;
};

export type CallSettingsAutoReplyInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  voiceType?: InputMaybe<CallSettingsVoiceType>;
};

export type CallSettingsInput = {
  activeIn?: InputMaybe<Scalars['String']['input']>;
  callAutoReply?: InputMaybe<CallSettingsAutoReplyInput>;
  isSipEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  voiceMail?: InputMaybe<CallSettingsAutoReplyInput>;
};

/** Call voice type */
export enum CallSettingsVoiceType {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

/** Dialer call state */
export enum CallState {
  ANSWERED = 'ANSWERED',
  CANCELLED = 'CANCELLED',
  DIALING = 'DIALING',
  DISCONNECTED = 'DISCONNECTED',
  FAILED = 'FAILED',
  INITIAL = 'INITIAL',
  RINGING = 'RINGING',
}

/** Campaign */
export type Campaign = {
  __typename?: 'Campaign';
  clickThroughProgress?: Maybe<Scalars['Float']['output']>;
  clickThroughStartAfter?: Maybe<ClickThroughStartAfterView>;
  contacts: Scalars['Int']['output'];
  costs?: Maybe<CampaignCosts>;
  createdAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  firestoreId?: Maybe<Scalars['String']['output']>;
  firstMessageTemplate?: Maybe<MessageTemplate>;
  groups?: Maybe<Array<GroupView>>;
  id: Scalars['String']['output'];
  keyword?: Maybe<KeywordTemplate>;
  lastSentAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  ownPhones: Array<OwnPhone>;
  rule?: Maybe<CampaignRule>;
  scheduleOptions: CampaignScheduleOptions;
  sendFirstMessage?: Maybe<Scalars['Boolean']['output']>;
  sequences: Array<SequenceView>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  state: CampaignState;
  stats?: Maybe<CampaignStats>;
  type: CampaignType;
};

/** Campaign costs */
export type CampaignCosts = {
  __typename?: 'CampaignCosts';
  captured: Scalars['Float']['output'];
  refunded: Scalars['Float']['output'];
};

export type CampaignDynamicDataInput = {
  clickThroughProgress?: InputMaybe<Scalars['Int']['input']>;
  clickThroughStartAfter?: InputMaybe<ClickThroughStartAfter>;
};

/** Campaign export type */
export enum CampaignExportType {
  ALL = 'ALL',
  CARRIER_BLOCKED = 'CARRIER_BLOCKED',
  DELIVERED = 'DELIVERED',
  RESPONDED = 'RESPONDED',
  SENT = 'SENT',
  UNDELIVERED = 'UNDELIVERED',
  UNRESPONDED = 'UNRESPONDED',
  UNSENT = 'UNSENT',
}

export type CampaignFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  profileId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<CampaignStatus>>;
  type?: InputMaybe<CampaignType>;
};

export type CampaignList = {
  __typename?: 'CampaignList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Campaign>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Campaign message status */
export enum CampaignMessageStatus {
  ACTIVE = 'ACTIVE',
  DONE = 'DONE',
  INACTIVE = 'INACTIVE',
}

/** Informs of a change on the progress of the campaign's sequences */
export type CampaignProgressUpdated = {
  __typename?: 'CampaignProgressUpdated';
  campaignId: Scalars['String']['output'];
  /** Amount already sent */
  progress: Array<SequenceProgress>;
};

/** Campaign report view */
export type CampaignReport = {
  __typename?: 'CampaignReport';
  totalKeywordCampaigns: Scalars['Float']['output'];
  totalStandardCampaigns: Scalars['Float']['output'];
};

/** Campaign rule */
export type CampaignRule = {
  __typename?: 'CampaignRule';
  groupId?: Maybe<Scalars['String']['output']>;
  labelId?: Maybe<Scalars['String']['output']>;
  removeFromNextSequences?: Maybe<Scalars['Boolean']['output']>;
  strategy?: Maybe<CampaignRuleStrategy>;
};

export type CampaignRuleInput = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  labelId?: InputMaybe<Scalars['String']['input']>;
  removeFromNextSequences?: InputMaybe<Scalars['Boolean']['input']>;
  strategy?: InputMaybe<CampaignRuleStrategy>;
};

/** Campaign rule strategy */
export enum CampaignRuleStrategy {
  CALL_REPLY = 'CALL_REPLY',
  NO_REPLY = 'NO_REPLY',
  REPLY = 'REPLY',
  SMS_REPLY = 'SMS_REPLY',
}

/** Campaign schedule */
export type CampaignSchedule = {
  __typename?: 'CampaignSchedule';
  endTime: Scalars['Float']['output'];
  nextTime?: Maybe<Scalars['Float']['output']>;
  startTime: Scalars['Float']['output'];
};

/** Campaign schedule options */
export type CampaignScheduleOptions = {
  __typename?: 'CampaignScheduleOptions';
  frequency?: Maybe<Frequency>;
  hasCustomBatchSize?: Maybe<Scalars['Boolean']['output']>;
  hasCustomStartDate?: Maybe<Scalars['Boolean']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
  perBatch?: Maybe<Scalars['Float']['output']>;
  startAt?: Maybe<Scalars['DateTime']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type CampaignScheduleOptionsInput = {
  expiresAt?: InputMaybe<Scalars['Float']['input']>;
  frequency: Frequency;
  hasCustomBatchSize?: InputMaybe<Scalars['Boolean']['input']>;
  hasCustomStartDate?: InputMaybe<Scalars['Boolean']['input']>;
  interval: Scalars['Int']['input'];
  ownPhones: Array<Scalars['String']['input']>;
  perBatch: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  sequence: Array<SequenceOptions>;
  startAt: Scalars['DateTime']['input'];
};

export type CampaignSequenceInput = {
  days?: InputMaybe<Array<Scalars['Int']['input']>>;
  delay?: InputMaybe<Scalars['Float']['input']>;
  hasCustomTimeRestrictions?: InputMaybe<Scalars['Boolean']['input']>;
  rule?: InputMaybe<CampaignRuleInput>;
  step?: InputMaybe<Scalars['Int']['input']>;
  templates: Array<Scalars['String']['input']>;
  timeRange: TimeRangeInput;
  type: SequenceType;
};

/** Campaign state */
export type CampaignState = {
  __typename?: 'CampaignState';
  reason?: Maybe<StateReason>;
  status: CampaignStatus;
};

/** Campaign state reason type */
export enum CampaignStateReasonType {
  HIGH_CARRIER_BLOCK_RATE = 'HIGH_CARRIER_BLOCK_RATE',
  HIGH_OPT_OUT_RATE = 'HIGH_OPT_OUT_RATE',
  LOW_DELIVERY_RATE = 'LOW_DELIVERY_RATE',
  PROVIDER_SUSPENDED = 'PROVIDER_SUSPENDED',
  RVM_LIMIT = 'RVM_LIMIT',
  SMS_WEEKLY_LIMIT = 'SMS_WEEKLY_LIMIT',
}

/** Campaign stats */
export type CampaignStats = {
  __typename?: 'CampaignStats';
  smsRefunded?: Maybe<Scalars['Float']['output']>;
  smsUsed?: Maybe<Scalars['Float']['output']>;
};

/** Campaign status */
export enum CampaignStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED',
  PAUSED = 'PAUSED',
  STOPPED = 'STOPPED',
}

/** Campaign type */
export enum CampaignType {
  KEYWORD = 'KEYWORD',
  STANDARD = 'STANDARD',
}

/** Campaigns aggregation counters */
export type CampaignsAggregationCountersView = {
  __typename?: 'CampaignsAggregationCountersView';
  active: Scalars['Float']['output'];
  deleted: Scalars['Float']['output'];
  draft: Scalars['Float']['output'];
  ended: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

/** Campaigns aggregation */
export type CampaignsAggregationView = {
  __typename?: 'CampaignsAggregationView';
  keyword: CampaignsAggregationCountersView;
  standard: CampaignsAggregationCountersView;
};

export type ChangesItemView = {
  __typename?: 'ChangesItemView';
  entityId: Scalars['String']['output'];
  operation: ChangesOperation;
  operationAt: Scalars['DateTime']['output'];
};

/** Changes operation */
export enum ChangesOperation {
  CREATED = 'CREATED',
  REMOVED = 'REMOVED',
  UPDATED = 'UPDATED',
}

export type ChangesView = {
  __typename?: 'ChangesView';
  items: Array<ChangesItemView>;
};

/** Click-through start after */
export type ClickThroughStartAfter = {
  createdAt: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
};

/** Click-through start after */
export type ClickThroughStartAfterView = {
  __typename?: 'ClickThroughStartAfterView';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
};

/** Contact */
export type Contact = {
  __typename?: 'Contact';
  attrs?: Maybe<Array<ContactAttribute>>;
  blocked?: Maybe<Scalars['Boolean']['output']>;
  canBeRemoved: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  dealClosed?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['Email']['output']>;
  favorite?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<GroupView>>;
  id: Scalars['String']['output'];
  labels?: Maybe<Array<Label>>;
  lastName?: Maybe<Scalars['String']['output']>;
  notes: Array<ContactNote>;
  phone: Scalars['String']['output'];
  realEstateAttrs?: Maybe<ContactRealEstateAttributes>;
  status: ContactStatus;
  unsubscribed?: Maybe<Scalars['Boolean']['output']>;
};

/** Contact attribute */
export type ContactAttribute = {
  __typename?: 'ContactAttribute';
  fieldId: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ContactAttributesInput = {
  fieldId: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Contact counters */
export type ContactCountersView = {
  __typename?: 'ContactCountersView';
  activeContacts: Scalars['Int']['output'];
  activeGroups: Scalars['Int']['output'];
  deletedContacts: Scalars['Int']['output'];
};

export type ContactFilter = {
  fullSearch?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  isBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  status: ContactStatus;
};

/** List of contacts */
export type ContactList = {
  __typename?: 'ContactList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Contact>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Contact note */
export type ContactNote = {
  __typename?: 'ContactNote';
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ContactNoteFilter = {
  contactId: Scalars['String']['input'];
  profileId?: InputMaybe<Scalars['String']['input']>;
};

export type ContactNoteList = {
  __typename?: 'ContactNoteList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<ContactNote>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ContactOrder = {
  by?: InputMaybe<ContactOrderBy>;
  direction?: InputMaybe<OrderQuery>;
};

/** Contact order pagination */
export enum ContactOrderBy {
  CREATED_AT = 'CREATED_AT',
  FAVORITE = 'FAVORITE',
}

/** Contact real estate attribute */
export type ContactRealEstateAttribute = {
  __typename?: 'ContactRealEstateAttribute';
  id: RealEstateAttribute;
  value: Scalars['String']['output'];
};

/** Contact real estate attributes */
export type ContactRealEstateAttributes = {
  __typename?: 'ContactRealEstateAttributes';
  attrs: Array<ContactRealEstateAttribute>;
  provider: Scalars['String']['output'];
};

export type ContactRealEstateAttributesInput = {
  id: RealEstateAttribute;
  value: Scalars['String']['input'];
};

/** Contact report view */
export type ContactReportView = {
  __typename?: 'ContactReportView';
  leadCount: Scalars['Float']['output'];
  totalContacts: Scalars['Float']['output'];
  totalContactsResponded: Scalars['Float']['output'];
};

/** Contact status */
export enum ContactStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
}

/** Conversation */
export type Conversation = {
  __typename?: 'Conversation';
  campaigns?: Maybe<Array<Campaign>>;
  canBeRemoved: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isAiFiltered?: Maybe<Scalars['Boolean']['output']>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  isRead?: Maybe<Scalars['Boolean']['output']>;
  isUnsubscribed?: Maybe<Scalars['Boolean']['output']>;
  labels?: Maybe<Array<Label>>;
  lastMessage?: Maybe<Message>;
  lastMessageAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  outgoingPhone?: Maybe<Scalars['PhoneNumberType']['output']>;
  ownPhone?: Maybe<OwnPhone>;
  phone: Scalars['PhoneNumberType']['output'];
  profileId: Scalars['String']['output'];
  status: ConversationStatus;
  unreadMessages?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Conversation category */
export enum ConversationCategory {
  AI_FILTERED = 'AI_FILTERED',
  ALL = 'ALL',
  DELETED = 'DELETED',
  MISSED_CALL = 'MISSED_CALL',
  OPTED_OUT = 'OPTED_OUT',
  SENT = 'SENT',
  UNREAD = 'UNREAD',
}

export type ConversationFilter = {
  campaigns?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<ConversationCategory>;
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  fullSearch?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  profileId?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConversationList = {
  __typename?: 'ConversationList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Conversation>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ConversationOrder = {
  by?: InputMaybe<ConversationOrderBy>;
  direction?: InputMaybe<OrderQuery>;
};

/** Conversation order pagination */
export enum ConversationOrderBy {
  DEAL_CLOSED_AT = 'DEAL_CLOSED_AT',
  FAVORITE = 'FAVORITE',
  ID = 'ID',
  UPDATED_AT = 'UPDATED_AT',
}

/** Conversation status */
export enum ConversationStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

/** Coupon */
export type Coupon = {
  __typename?: 'Coupon';
  amountOff?: Maybe<Scalars['Money']['output']>;
  id: Scalars['String']['output'];
  percentOff?: Maybe<Scalars['Int']['output']>;
};

export type CreateCalendarEventInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endAt: Scalars['DateTime']['input'];
  relationId?: InputMaybe<Scalars['String']['input']>;
  startAt: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
  type: CalendarEventType;
};

export type CreateCallScriptInput = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateCampaignInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  ownPhones: Array<Scalars['String']['input']>;
  rule?: InputMaybe<CampaignRuleInput>;
  scheduleOptions: ScheduleOptionsInput;
  sendFirstMessage?: InputMaybe<Scalars['Boolean']['input']>;
  sequences?: InputMaybe<Array<CampaignSequenceInput>>;
  type: CampaignType;
};

export type CreateContactInput = {
  attrs?: InputMaybe<Array<ContactAttributesInput>>;
  firstName: Scalars['String']['input'];
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CreateContactNoteInput = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  text: Scalars['String']['input'];
};

export type CreateConversationInput = {
  contactId: Scalars['String']['input'];
};

export type CreateCrmIntegrationInput = {
  name: Scalars['String']['input'];
  provider?: InputMaybe<CrmProviderInput>;
  status: CrmIntegrationStatus;
  type: CrmProviderType;
};

export type CreateDncPhoneInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CreateDialerCampaignInput = {
  groups: Array<Scalars['String']['input']>;
  labels: Array<Scalars['String']['input']>;
  mode?: InputMaybe<CallMode>;
  name: Scalars['String']['input'];
  phones: Array<Scalars['String']['input']>;
  script: Scalars['String']['input'];
};

export type CreateFeedbackInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  score: Scalars['Int']['input'];
  source?: InputMaybe<FeedbackSource>;
};

export type CreateFieldInput = {
  name: Scalars['String']['input'];
};

export type CreateFieldsTemplateInput = {
  fields: Array<InputMaybe<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
};

export type CreateGroupInput = {
  name: Scalars['String']['input'];
};

export type CreateKeywordTemplateInput = {
  keyword: Scalars['String']['input'];
  template: KeywordTemplateTemplateInput;
};

export type CreateLabelInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  scopes?: InputMaybe<Scope>;
  title: Scalars['String']['input'];
};

export type CreateListInput = {
  fileName: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
  type: ListType;
};

export type CreateMacroInput = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateMarketingPopupInput = {
  audiences?: InputMaybe<Array<MarketingPopupAudience>>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<MarketingPopupStatus>;
  template?: InputMaybe<MarketingPopupTemplateInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMessageTemplateInput = {
  name: Scalars['String']['input'];
  template: MessageTemplateTemplateInput;
  type: MessageTemplateType;
};

export type CreateRestrictedPhoneInput = {
  phone: Scalars['String']['input'];
};

export type CreateScheduledMessageInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<Array<MessageAttachmentInput>>;
  contactId: Scalars['String']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  fromNumber?: InputMaybe<Scalars['String']['input']>;
  scheduledAt: Scalars['DateTime']['input'];
};

export type CreateSubaccountInput = {
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['PhoneNumberType']['input'];
  products?: InputMaybe<Array<Product>>;
};

/** CRM integration */
export type CrmIntegration = {
  __typename?: 'CrmIntegration';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  provider?: Maybe<CrmProviderView>;
  status: CrmIntegrationStatus;
  type?: Maybe<CrmProviderType>;
};

export type CrmIntegrationFilter = {
  status?: InputMaybe<CrmIntegrationStatus>;
};

/** List of CRM integrations */
export type CrmIntegrationList = {
  __typename?: 'CrmIntegrationList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<CrmIntegration>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CrmIntegrationOrder = {
  by?: InputMaybe<CrmIntegrationOrderBy>;
  direction?: InputMaybe<OrderQuery>;
};

/** CRM integration order by */
export enum CrmIntegrationOrderBy {
  TYPE = 'TYPE',
}

/** CRM integration status */
export enum CrmIntegrationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type CrmProviderInput = {
  apiKey?: InputMaybe<Scalars['String']['input']>;
  labelsPhoneStatuses?: InputMaybe<Array<CrmProviderLabelMap>>;
  labelsPhoneTags?: InputMaybe<Array<Scalars['String']['input']>>;
  labelsPropertyStatuses?: InputMaybe<Array<CrmProviderLabelMap>>;
  sendNotes?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CrmProviderLabelMap = {
  labelId: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

/** CRM provider label data */
export type CrmProviderLabelView = {
  __typename?: 'CrmProviderLabelView';
  label: Label;
  status: Scalars['String']['output'];
};

/** CRM provider type */
export enum CrmProviderType {
  REISIFT = 'REISIFT',
  ZAPIER = 'ZAPIER',
}

/** CRM provider */
export type CrmProviderView = {
  __typename?: 'CrmProviderView';
  apiKey?: Maybe<Scalars['String']['output']>;
  labelsPhoneStatuses?: Maybe<Array<CrmProviderLabelView>>;
  labelsPhoneTags?: Maybe<Array<Label>>;
  labelsPropertyStatuses?: Maybe<Array<CrmProviderLabelView>>;
  sendNotes?: Maybe<Scalars['Boolean']['output']>;
  targetUrl?: Maybe<Scalars['String']['output']>;
};

/** Current subscription */
export type CurrentSubscription = {
  __typename?: 'CurrentSubscription';
  base: Scalars['Boolean']['output'];
  discount?: Maybe<Discount>;
  endAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  membershipMonths?: Maybe<Scalars['Float']['output']>;
  nextBillingDate?: Maybe<Scalars['DateTime']['output']>;
  plan?: Maybe<Plan>;
  price: Scalars['Money']['output'];
  product: Product;
  reason?: Maybe<Scalars['String']['output']>;
  secondary: Scalars['Boolean']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: SubscriptionStatus;
  title: Scalars['String']['output'];
  trialEndedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CursorPagination = {
  /** Max number of items to return in the query. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Token for fetching the next page of items. */
  nextPageToken?: InputMaybe<Scalars['String']['input']>;
};

/** Deals closed report view */
export type DealsClosedReport = {
  __typename?: 'DealsClosedReport';
  dealsClosed: Scalars['Float']['output'];
};

/** Device platform */
export enum DevicePlatform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

/** Dialer call outcome report */
export type DialerCallOutcomeReportView = {
  __typename?: 'DialerCallOutcomeReportView';
  allLabeledContacts: Scalars['Float']['output'];
  contactsWithCurrentLabel: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};

/** Dialer call report view */
export type DialerCallReportView = {
  __typename?: 'DialerCallReportView';
  answered: Scalars['Float']['output'];
  averageDuration: Scalars['Float']['output'];
  conversationRate: Scalars['Float']['output'];
  conversations: Scalars['Float']['output'];
  responseRate: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

/** Dialer Campaign */
export type DialerCampaign = {
  __typename?: 'DialerCampaign';
  contacts: Scalars['Int']['output'];
  contactsWithOutcome?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  groups?: Maybe<Array<GroupView>>;
  id: Scalars['String']['output'];
  labels: Array<Scalars['String']['output']>;
  lastCallAt?: Maybe<Scalars['DateTime']['output']>;
  lines: Array<Scalars['String']['output']>;
  mode: CallMode;
  name: Scalars['String']['output'];
  ownPhones: Array<OwnPhone>;
  phones: Array<Scalars['String']['output']>;
  /** Script ID */
  script?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: CampaignStatus;
};

/** Type of export for dialer campaign */
export enum DialerCampaignExportType {
  ALL = 'ALL',
  ANSWERED = 'ANSWERED',
  NOT_ANSWERED = 'NOT_ANSWERED',
}

export type DialerCampaignFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  profileId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<CampaignStatus>>;
};

export type DialerCampaignList = {
  __typename?: 'DialerCampaignList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<DialerCampaign>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Dialer campaigns aggregation counters */
export type DialerCampaignsAggregationCountersView = {
  __typename?: 'DialerCampaignsAggregationCountersView';
  active: Scalars['Float']['output'];
  deleted: Scalars['Float']['output'];
  draft: Scalars['Float']['output'];
  ended: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

/** Dialer Campaigns aggregation */
export type DialerCampaignsAggregationView = {
  __typename?: 'DialerCampaignsAggregationView';
  dialer: DialerCampaignsAggregationCountersView;
};

/** Dialer contact report view */
export type DialerContactReportView = {
  __typename?: 'DialerContactReportView';
  deals: Scalars['Float']['output'];
  leads: Scalars['Float']['output'];
};

export type DialerReportsFilter = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['String']['input']>;
};

/** Dialer status */
export enum DialerStatus {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

/** Discount */
export type Discount = {
  __typename?: 'Discount';
  amount: Scalars['Money']['output'];
  coupon: Scalars['String']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  percent?: Maybe<Scalars['Int']['output']>;
  promoCode?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EndTrialInput = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type ExportCampaignToFileInput = {
  id: Scalars['String']['input'];
  type: CampaignExportType;
};

export type ExportCampaignToGroupInput = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  newGroupName?: InputMaybe<Scalars['String']['input']>;
  type: CampaignExportType;
};

export type ExportConversationsToCrmIntegrationInput = {
  conversationId?: InputMaybe<Scalars['String']['input']>;
  crmIntegrationId: Scalars['String']['input'];
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  exportType?: InputMaybe<ExportType>;
  status?: InputMaybe<ConversationStatus>;
};

/** Export conversations to crm integration result */
export type ExportConversationsToCrmIntegrationResultView = {
  __typename?: 'ExportConversationsToCRMIntegrationResultView';
  success: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ExportConversationsToFileInput = {
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  exportType: ExportType;
  status?: InputMaybe<ConversationStatus>;
};

export type ExportDialerCampaignToFileInput = {
  id: Scalars['String']['input'];
  type: DialerCampaignExportType;
};

export type ExportDialerCampaignToGroupInput = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  newGroupName?: InputMaybe<Scalars['String']['input']>;
  type: DialerCampaignExportType;
};

export type ExportListInput = {
  id: Scalars['String']['input'];
};

export type ExportSkiptraceInput = {
  exportType: ExportSkiptraceType;
  id: Scalars['String']['input'];
};

/** Export skiptrace type */
export enum ExportSkiptraceType {
  ALL = 'ALL',
  LANDLINE_ONLY = 'LANDLINE_ONLY',
  MOBILE_ONLY = 'MOBILE_ONLY',
}

export type ExportSuppressionsInput = {
  source?: InputMaybe<SuppressionSource>;
};

/** Export conversation type */
export enum ExportType {
  CAMPAIGNS = 'CAMPAIGNS',
  GROUPS = 'GROUPS',
  LABELS = 'LABELS',
}

/** Feature */
export type Feature = {
  __typename?: 'Feature';
  limit?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Money']['output']>;
  product?: Maybe<Product>;
  tier?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  type: FeatureType;
};

/** Feature */
export enum FeatureType {
  A2P_10DLC = 'A2P_10DLC',
  ALLOW_OPT_OUT_DISABLE = 'ALLOW_OPT_OUT_DISABLE',
  CALL = 'CALL',
  CAMPAIGN_SMS = 'CAMPAIGN_SMS',
  CONTACTS = 'CONTACTS',
  CRM_INTEGRATIONS = 'CRM_INTEGRATIONS',
  CUSTOM_WEBSITE = 'CUSTOM_WEBSITE',
  DIALER_CALL = 'DIALER_CALL',
  DIALER_CONTACTS = 'DIALER_CONTACTS',
  DIALER_SUB_ACCOUNTS = 'DIALER_SUB_ACCOUNTS',
  DRIP_CAMPAIGN = 'DRIP_CAMPAIGN',
  KEYWORD_CAMPAIGN = 'KEYWORD_CAMPAIGN',
  LOCAL_PHONE = 'LOCAL_PHONE',
  LOOKUP = 'LOOKUP',
  MESSAGING = 'MESSAGING',
  MMS = 'MMS',
  OPT_OUT_DISABLED = 'OPT_OUT_DISABLED',
  OWN_PHONE = 'OWN_PHONE',
  RVM = 'RVM',
  SCHEDULE_CAMPAIGN = 'SCHEDULE_CAMPAIGN',
  SKIPTRACE = 'SKIPTRACE',
  SMS = 'SMS',
  SMS_ALERT = 'SMS_ALERT',
  SUB_ACCOUNTS = 'SUB_ACCOUNTS',
  TEMPLATES = 'TEMPLATES',
  TEMPLATES_AI = 'TEMPLATES_AI',
  THROTTLE_CAMPAIGN = 'THROTTLE_CAMPAIGN',
  TOLL_FREE_PHONE = 'TOLL_FREE_PHONE',
  TRIAL_PHONE = 'TRIAL_PHONE',
}

/** Feedback */
export type Feedback = {
  __typename?: 'Feedback';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  source: FeedbackSource;
};

/** Feedback Source */
export enum FeedbackSource {
  MOBILE = 'MOBILE',
  WEB = 'WEB',
}

/** Field */
export type Field = {
  __typename?: 'Field';
  id: Scalars['String']['output'];
  key?: Maybe<PredefinedField>;
  name: Scalars['String']['output'];
  predefined: Scalars['Boolean']['output'];
};

export type FieldsFilter = {
  predefined?: InputMaybe<Scalars['Boolean']['input']>;
  subAccountId?: InputMaybe<Scalars['String']['input']>;
};

/** Fields template */
export type FieldsTemplate = {
  __typename?: 'FieldsTemplate';
  createdAt: Scalars['DateTime']['output'];
  fields?: Maybe<Array<Field>>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FieldsTemplateList = {
  __typename?: 'FieldsTemplateList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<FieldsTemplate>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type FindAvailablePhonesFilter = {
  areaCode?: InputMaybe<Scalars['String']['input']>;
  type: OwnPhoneType;
};

/** Frequency */
export enum Frequency {
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
  MINUTELY = 'MINUTELY',
}

export type GenerateSignedUrlInput = {
  /** The action to be permitted by signed url */
  action?: InputMaybe<SignedUrlAction>;
  /** The content type of the file */
  contentType: Scalars['String']['input'];
  /** The name of file */
  fileName: Scalars['String']['input'];
};

export type GetMessageAttachmentSignedUrlInput = {
  /** The action to be permitted by signed url */
  action?: InputMaybe<SignedUrlAction>;
  /** The content length of the file */
  contentLength: Scalars['Int']['input'];
  /** The content type of the file */
  contentType: Scalars['String']['input'];
  /** The name of file */
  fileName: Scalars['String']['input'];
};

export type GroupList = {
  __typename?: 'GroupList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<GroupView>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Group */
export type GroupView = {
  __typename?: 'GroupView';
  activeChats: Scalars['Int']['output'];
  campaigns: Array<Campaign>;
  canBeCloned: Scalars['Boolean']['output'];
  canBeRemoved: Scalars['Boolean']['output'];
  contacts: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  dialerCampaigns: Array<DialerCampaign>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type GroupsFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Health */
export type Health = {
  __typename?: 'Health';
  status: HealthStatus;
};

/** Health status */
export enum HealthStatus {
  DOWN = 'DOWN',
  UP = 'UP',
}

export type HourMinuteInput = {
  hour: Scalars['Int']['input'];
  minute: Scalars['Int']['input'];
};

export type HourMinuteView = {
  __typename?: 'HourMinuteView';
  hour: Scalars['Int']['output'];
  minute: Scalars['Int']['output'];
};

/** HubSpot profile field */
export enum HubSpotProfileField {
  A2P10DLC_REGISTRATION_STATUS = 'A2P10DLC_REGISTRATION_STATUS',
  A2P10DLC_REGISTRATION_STATUS_DATE = 'A2P10DLC_REGISTRATION_STATUS_DATE',
  ABANDONED_SIGN_UP = 'ABANDONED_SIGN_UP',
  ABANDONED_SIGN_UP_DATE = 'ABANDONED_SIGN_UP_DATE',
  ACCOUNT_TYPE = 'ACCOUNT_TYPE',
  ADDED_NEW_CARD = 'ADDED_NEW_CARD',
  ADDED_NEW_CARD_DATE = 'ADDED_NEW_CARD_DATE',
  ADDED_PAYMENT_INFO = 'ADDED_PAYMENT_INFO',
  ADDED_PAYMENT_INFO_DATE = 'ADDED_PAYMENT_INFO_DATE',
  AMOUNT_SPENT_ON_MESSAGES = 'AMOUNT_SPENT_ON_MESSAGES',
  AMOUNT_SPENT_ON_PHONE_NUMBERS = 'AMOUNT_SPENT_ON_PHONE_NUMBERS',
  AMOUNT_SPENT_ON_RVM = 'AMOUNT_SPENT_ON_RVM',
  AMOUNT_SPENT_ON_SKIPTRACING = 'AMOUNT_SPENT_ON_SKIPTRACING',
  AUTO_RECHARGE = 'AUTO_RECHARGE',
  AUTO_RECHARGE_ACTIVE_DATE = 'AUTO_RECHARGE_ACTIVE_DATE',
  AUTO_RECHARGE_INACTIVE_DATE = 'AUTO_RECHARGE_INACTIVE_DATE',
  BALANCE = 'BALANCE',
  CALLING_MONTHS_OF_MEMBERSHIP_STARTED_AT = 'CALLING_MONTHS_OF_MEMBERSHIP_STARTED_AT',
  CALLING_PLAN_AMOUNT = 'CALLING_PLAN_AMOUNT',
  CALLING_PLAN_INTERVAL = 'CALLING_PLAN_INTERVAL',
  CALLING_PLAN_NAME = 'CALLING_PLAN_NAME',
  CALLING_SUBSCRIPTION_STATUS = 'CALLING_SUBSCRIPTION_STATUS',
  CALLING_SUBSCRIPTION_STATUS_DATE = 'CALLING_SUBSCRIPTION_STATUS_DATE',
  CANCELED_SUBSCRIPTION = 'CANCELED_SUBSCRIPTION',
  CANCELED_SUBSCRIPTION_DATE = 'CANCELED_SUBSCRIPTION_DATE',
  COMPANY_NAME = 'COMPANY_NAME',
  COMPLETED_SIGN_UP = 'COMPLETED_SIGN_UP',
  COMPLETED_SIGN_UP_DATE = 'COMPLETED_SIGN_UP_DATE',
  CREATED_ACCOUNT = 'CREATED_ACCOUNT',
  CREATED_ACCOUNT_DATE = 'CREATED_ACCOUNT_DATE',
  CREATED_AT = 'CREATED_AT',
  CURRENT_BALANCE = 'CURRENT_BALANCE',
  DOWNGRADED_PLAN = 'DOWNGRADED_PLAN',
  DOWNGRADED_PLAN_DATE = 'DOWNGRADED_PLAN_DATE',
  EMAIL = 'EMAIL',
  EMPLOYEES = 'EMPLOYEES',
  ENDED_TRIAL = 'ENDED_TRIAL',
  ENDED_TRIAL_DATE = 'ENDED_TRIAL_DATE',
  FAILED_PAYMENT = 'FAILED_PAYMENT',
  FAILED_PAYMENT_REASON = 'FAILED_PAYMENT_REASON',
  FIRST_NAME = 'FIRST_NAME',
  INDUSTRY = 'INDUSTRY',
  INTEGRATION_STATUS = 'INTEGRATION_STATUS',
  JOB_TITLE = 'JOB_TITLE',
  LAST_BILLED_AMOUNT = 'LAST_BILLED_AMOUNT',
  LAST_BILLING_DATE = 'LAST_BILLING_DATE',
  LAST_INTEGRATION_ACTIVATED = 'LAST_INTEGRATION_ACTIVATED',
  LAST_INTEGRATION_ACTIVATED_DATE = 'LAST_INTEGRATION_ACTIVATED_DATE',
  LAST_INTEGRATION_INACTIVATED = 'LAST_INTEGRATION_INACTIVATED',
  LAST_INTEGRATION_INACTIVATED_DATE = 'LAST_INTEGRATION_INACTIVATED_DATE',
  LAST_LOGIN_DATE = 'LAST_LOGIN_DATE',
  LAST_NAME = 'LAST_NAME',
  MARKETING_CONTACT_STATUS = 'MARKETING_CONTACT_STATUS',
  MEMBERSHIP_STARTED_AT = 'MEMBERSHIP_STARTED_AT',
  MESSAGING_MONTHS_OF_MEMBERSHIP_STARTED_AT = 'MESSAGING_MONTHS_OF_MEMBERSHIP_STARTED_AT',
  MESSAGING_SUBSCRIPTION_STATUS = 'MESSAGING_SUBSCRIPTION_STATUS',
  MESSAGING_SUBSCRIPTION_STATUS_DATE = 'MESSAGING_SUBSCRIPTION_STATUS_DATE',
  MOBILE_APP_DOWNLOADED = 'MOBILE_APP_DOWNLOADED',
  MOBILE_APP_DOWNLOADED_DATE = 'MOBILE_APP_DOWNLOADED_DATE',
  NUMBER_OF_ACTIVE_INTEGRATIONS = 'NUMBER_OF_ACTIVE_INTEGRATIONS',
  NUMBER_OF_CARDS_ON_FILE = 'NUMBER_OF_CARDS_ON_FILE',
  NUMBER_OF_CONTACTS_SKIPTRACED = 'NUMBER_OF_CONTACTS_SKIPTRACED',
  NUMBER_OF_MESSAGES_RECEIVED = 'NUMBER_OF_MESSAGES_RECEIVED',
  NUMBER_OF_MESSAGES_SENT = 'NUMBER_OF_MESSAGES_SENT',
  NUMBER_OF_RVM_SENT = 'NUMBER_OF_RVM_SENT',
  PERSONA = 'PERSONA',
  PHONE = 'PHONE',
  PHONE_NUMBER_REGISTRATION_STATUS = 'PHONE_NUMBER_REGISTRATION_STATUS',
  PLAN = 'PLAN',
  PLAN_AMOUNT = 'PLAN_AMOUNT',
  PLAN_INTERVAL = 'PLAN_INTERVAL',
  PLAN_NAME = 'PLAN_NAME',
  PREVIOUS_SMS_PROVIDER = 'PREVIOUS_SMS_PROVIDER',
  RATING_REVIEW = 'RATING_REVIEW',
  REAL_ESTATE = 'REAL_ESTATE',
  RECHARGED_ACCOUNT = 'RECHARGED_ACCOUNT',
  RECHARGED_ACCOUNT_AMOUNT = 'RECHARGED_ACCOUNT_AMOUNT',
  RECHARGED_ACCOUNT_DATE = 'RECHARGED_ACCOUNT_DATE',
  REMOVED_CARD = 'REMOVED_CARD',
  REMOVED_CARD_DATE = 'REMOVED_CARD_DATE',
  STARTED_SIGN_UP = 'STARTED_SIGN_UP',
  STARTED_SIGN_UP_DATE = 'STARTED_SIGN_UP_DATE',
  STARTED_SUBSCRIPTION = 'STARTED_SUBSCRIPTION',
  STARTED_SUBSCRIPTION_DATE = 'STARTED_SUBSCRIPTION_DATE',
  STARTED_TRIAL = 'STARTED_TRIAL',
  STARTED_TRIAL_DATE = 'STARTED_TRIAL_DATE',
  TIMEZONE = 'TIMEZONE',
  TOTAL_BILLING_TO_DATE = 'TOTAL_BILLING_TO_DATE',
  TOTAL_MEMBERSHIP_FEES = 'TOTAL_MEMBERSHIP_FEES',
  TOTAL_SKIPTRACE_USAGE = 'TOTAL_SKIPTRACE_USAGE',
  TOTAL_SMS_USAGE = 'TOTAL_SMS_USAGE',
  TRIAL_STARTED_AT = 'TRIAL_STARTED_AT',
  TYPE = 'TYPE',
  UNSUBSCRIBE_REASON = 'UNSUBSCRIBE_REASON',
  UPDATED_FROM = 'UPDATED_FROM',
  UPDATED_FROM_DATE = 'UPDATED_FROM_DATE',
  UPGRADED_PLAN = 'UPGRADED_PLAN',
  UPGRADED_PLAN_DATE = 'UPGRADED_PLAN_DATE',
}

/** ImpersonateToken */
export type ImpersonateToken = {
  __typename?: 'ImpersonateToken';
  token: Scalars['String']['output'];
};

/** Inactive account summary */
export type InactiveAccountSummary = {
  __typename?: 'InactiveAccountSummary';
  total?: Maybe<Scalars['Int']['output']>;
};

/** Configuration values for Infobip. */
export type InfobipConfigView = {
  __typename?: 'InfobipConfigView';
  applicationId: Scalars['String']['output'];
  callsConfigId: Scalars['String']['output'];
};

/** Keyword template */
export type KeywordTemplate = {
  __typename?: 'KeywordTemplate';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  keyword: Scalars['String']['output'];
  template: KeywordTemplateTemplate;
};

export type KeywordTemplateList = {
  __typename?: 'KeywordTemplateList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<KeywordTemplate>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Keyword template template */
export type KeywordTemplateTemplate = {
  __typename?: 'KeywordTemplateTemplate';
  characters?: Maybe<Scalars['Float']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  optOut?: Maybe<Scalars['Boolean']['output']>;
  segments?: Maybe<Scalars['Float']['output']>;
};

export type KeywordTemplateTemplateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  optOut?: InputMaybe<Scalars['Boolean']['input']>;
};

export type KeywordTemplatesFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

/** Label */
export type Label = {
  __typename?: 'Label';
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  default: Scalars['Boolean']['output'];
  firestoreId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  readOnly: Scalars['Boolean']['output'];
  scopes: Array<Scope>;
  title: Scalars['String']['output'];
};

export type LabelFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  scopes?: InputMaybe<Array<Scope>>;
};

/** List of labels */
export type LabelList = {
  __typename?: 'LabelList';
  items: Array<Label>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Label report */
export type LabelReportView = {
  __typename?: 'LabelReportView';
  allLabeledContacts: Scalars['Float']['output'];
  contactsWithCurrentLabel: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** Last billing */
export type LastBilling = {
  __typename?: 'LastBilling';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
};

/** Last balance top up */
export type LastTopUp = {
  __typename?: 'LastTopUp';
  amount: Scalars['Money']['output'];
  bonus?: Maybe<Scalars['Money']['output']>;
  topUpAt: Scalars['DateTime']['output'];
};

/** Information about the line */
export type LineView = {
  __typename?: 'LineView';
  amdResult?: Maybe<AmdResult>;
  callId?: Maybe<Scalars['String']['output']>;
  contact: Contact;
  id: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  state: CallState;
  stateReason?: Maybe<Scalars['String']['output']>;
};

export type ListItemList = {
  __typename?: 'ListItemList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<ListItemView>;
  nextRowIndex?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** ListItem */
export type ListItemView = {
  __typename?: 'ListItemView';
  data: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  rowIndex: Scalars['Float']['output'];
};

export type ListItemsPagination = {
  /** Number of items returned in query. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Row index after which to fetch records */
  nextRowIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type ListOptions = {
  __typename?: 'ListOptions';
  removeCarrierBlocked: Scalars['Boolean']['output'];
  removeExisting: Scalars['Boolean']['output'];
  removeFiltered: Scalars['Boolean']['output'];
  removeGlobalUnsubscribed: Scalars['Boolean']['output'];
  removeInternalDnc: Scalars['Boolean']['output'];
  removeLitigators: Scalars['Boolean']['output'];
  removeNationalDnc: Scalars['Boolean']['output'];
  removeNonSmsCapable: Scalars['Boolean']['output'];
  removeUndeliverable: Scalars['Boolean']['output'];
  removeUnsubscribed: Scalars['Boolean']['output'];
};

/** List type */
export enum ListType {
  GROUP = 'GROUP',
  SKIPTRACE = 'SKIPTRACE',
}

/** List upload completed */
export type ListUploadCompleted = {
  __typename?: 'ListUploadCompleted';
  listId: Scalars['String']['output'];
};

/** List upload progress */
export type ListUploadProgress = {
  __typename?: 'ListUploadProgress';
  listId: Scalars['String']['output'];
  progress: Scalars['Float']['output'];
};

/** List verification progress */
export type ListVerificationProgress = {
  __typename?: 'ListVerificationProgress';
  listId: Scalars['String']['output'];
  progress: Scalars['Float']['output'];
};

/** List verification result */
export type ListVerificationResult = {
  __typename?: 'ListVerificationResult';
  carrierBlocked: Scalars['Int']['output'];
  existing: Scalars['Int']['output'];
  filtered: Scalars['Int']['output'];
  globalUnsubscribed: Scalars['Int']['output'];
  internalDnc: Scalars['Int']['output'];
  listId: Scalars['String']['output'];
  litigators: Scalars['Int']['output'];
  nationalDnc: Scalars['Int']['output'];
  nonSmsCapable: Scalars['Int']['output'];
  totalPhones: Scalars['Int']['output'];
  undeliverable: Scalars['Int']['output'];
  unsubscribed: Scalars['Int']['output'];
  verified: Scalars['Boolean']['output'];
  verifiedPhones: Scalars['Int']['output'];
};

/** List */
export type ListView = {
  __typename?: 'ListView';
  fields?: Maybe<Array<Field>>;
  id: Scalars['String']['output'];
  options?: Maybe<ListOptions>;
  type: ListType;
};

/** Macro */
export type Macro = {
  __typename?: 'Macro';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** List of macros */
export type MacroList = {
  __typename?: 'MacroList';
  items: Array<Macro>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type MacrosFilter = {
  /** Search by macro name and content with merge tags */
  fullSearch?: InputMaybe<Scalars['String']['input']>;
  /** Search by macro name and content without merge tags */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ManageCallRecordingInput = {
  action: CallRecordingAction;
  id: Scalars['String']['input'];
};

export type ManagePowerDialerCallRecordingInput = {
  action: CallRecordingAction;
  campaignId: Scalars['String']['input'];
};

export type MarkAccountPopupViewedInput = {
  popupId: Scalars['String']['input'];
};

export type MarkConversationsReadInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isRead: Scalars['Boolean']['input'];
  profileId?: InputMaybe<Scalars['String']['input']>;
};

export type MarkNotificationsReadInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MarkNotificationsSeenInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Marketing popup */
export type MarketingPopup = {
  __typename?: 'MarketingPopup';
  audiences?: Maybe<Array<MarketingPopupAudience>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status: MarketingPopupStatus;
  template?: Maybe<MarketingPopupTemplate>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Marketing popup audience */
export enum MarketingPopupAudience {
  ACTIVE_DIALER = 'ACTIVE_DIALER',
  ACTIVE_MESSAGING = 'ACTIVE_MESSAGING',
  AFFILIATES = 'AFFILIATES',
  ALL = 'ALL',
  CANCELLED_DIALER = 'CANCELLED_DIALER',
  CANCELLED_MESSAGING = 'CANCELLED_MESSAGING',
  INCOMPLETE_MESSAGING = 'INCOMPLETE_MESSAGING',
  PAST_DUE_DIALER = 'PAST_DUE_DIALER',
  PAST_DUE_MESSAGING = 'PAST_DUE_MESSAGING',
  PENDING_CANCELLATION_DIALER = 'PENDING_CANCELLATION_DIALER',
  PENDING_CANCELLATION_MESSAGING = 'PENDING_CANCELLATION_MESSAGING',
  SKIPTRACE_USED = 'SKIPTRACE_USED',
  TEST = 'TEST',
  TRIAL_MESSAGING = 'TRIAL_MESSAGING',
}

export type MarketingPopupList = {
  __typename?: 'MarketingPopupList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<MarketingPopup>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Marketing popup status */
export enum MarketingPopupStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

/** Marketing popup template */
export type MarketingPopupTemplate = {
  __typename?: 'MarketingPopupTemplate';
  buttonLink?: Maybe<Scalars['String']['output']>;
  buttonText?: Maybe<Scalars['String']['output']>;
  imageSrc?: Maybe<Scalars['String']['output']>;
  type: MarketingPopupTemplateType;
  videoSrc?: Maybe<Scalars['String']['output']>;
};

export type MarketingPopupTemplateInput = {
  buttonLink?: InputMaybe<Scalars['String']['input']>;
  buttonText?: InputMaybe<Scalars['String']['input']>;
  imagePath?: InputMaybe<Scalars['String']['input']>;
  type: MarketingPopupTemplateType;
  videoSrc?: InputMaybe<Scalars['String']['input']>;
};

/** Marketing popup template type */
export enum MarketingPopupTemplateType {
  IMAGE = 'IMAGE',
  IMAGE_WITH_BUTTON = 'IMAGE_WITH_BUTTON',
  VIDEO = 'VIDEO',
  VIDEO_WITH_BUTTON = 'VIDEO_WITH_BUTTON',
}

/** Merge tag */
export type MergeTag = {
  __typename?: 'MergeTag';
  id: Scalars['String']['output'];
  key?: Maybe<PredefinedField>;
  mergeTag: Scalars['String']['output'];
  name: Scalars['String']['output'];
  predefined: Scalars['Boolean']['output'];
};

/** Message */
export type Message = {
  __typename?: 'Message';
  attachments?: Maybe<Array<MessageAttachment>>;
  campaign?: Maybe<Campaign>;
  content?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<MessageContentType>;
  conversationId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dialerCampaign?: Maybe<DialerCampaign>;
  direction: MessageDirection;
  duration?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<MessageError>;
  fromNumber?: Maybe<PhoneNumber>;
  id: Scalars['String']['output'];
  isConversation?: Maybe<Scalars['Boolean']['output']>;
  isManualRecording?: Maybe<Scalars['Boolean']['output']>;
  keyword?: Maybe<KeywordTemplate>;
  profileId: Scalars['String']['output'];
  status: MessageStatus;
  statusReason?: Maybe<MessageStateReasonType>;
  template?: Maybe<MessageTemplate>;
  toNumber?: Maybe<Scalars['String']['output']>;
  type: MessageType;
};

/** Message attachment */
export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  bucket: Scalars['String']['output'];
  contentType?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type MessageAttachmentInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  path: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Message content type */
export enum MessageContentType {
  INCOMING_CALL = 'INCOMING_CALL',
  MEDIA_AUDIO = 'MEDIA_AUDIO',
  MEDIA_IMAGE = 'MEDIA_IMAGE',
  MISSED_CALL = 'MISSED_CALL',
  OUTGOING_CALL = 'OUTGOING_CALL',
  TEXT = 'TEXT',
  UNSUPPORTED_FILE = 'UNSUPPORTED_FILE',
  VOICE = 'VOICE',
  VOICEMAIL = 'VOICEMAIL',
}

/** Message direction */
export enum MessageDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

/** Message error */
export type MessageError = {
  __typename?: 'MessageError';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type MessageFilter = {
  profileId?: InputMaybe<Scalars['String']['input']>;
};

export type MessageList = {
  __typename?: 'MessageList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Message>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type MessageOrder = {
  by?: InputMaybe<MessageOrderBy>;
  direction?: InputMaybe<OrderQuery>;
};

/** Message order by */
export enum MessageOrderBy {
  CREATED_AT = 'CREATED_AT',
}

/** Message report view */
export type MessageReport = {
  __typename?: 'MessageReport';
  campaignAiFilteredMessages: Scalars['Float']['output'];
  campaignIncomingOptedOut: Scalars['Float']['output'];
  campaignOutgoingBlocked: Scalars['Float']['output'];
  campaignOutgoingDelivered: Scalars['Float']['output'];
  campaignOutgoingTotal: Scalars['Float']['output'];
  incomingTotal: Scalars['Float']['output'];
  /** Represents a median response time in this format: 1min 15sec, 1d 3h */
  medianResponseTime: Scalars['String']['output'];
  outgoingTotal: Scalars['Float']['output'];
};

/** Message state reason */
export enum MessageStateReasonType {
  AI_FILTERED = 'AI_FILTERED',
  CARRIER_BLOCKED = 'CARRIER_BLOCKED',
  DAILY_LIMIT_REACHED = 'DAILY_LIMIT_REACHED',
  FLOODING = 'FLOODING',
  LIMIT_REACHED = 'LIMIT_REACHED',
  OPTED_OUT = 'OPTED_OUT',
  PROVIDER_DNC = 'PROVIDER_DNC',
  RECEIVER_NON_SMS_CAPABLE = 'RECEIVER_NON_SMS_CAPABLE',
}

/** Message status */
export enum MessageStatus {
  CALL_FINISHED = 'CALL_FINISHED',
  CALL_MISSED = 'CALL_MISSED',
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  SENT = 'SENT',
  UNDELIVERED = 'UNDELIVERED',
}

/** Message template */
export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  characters?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  firestoreId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  segments?: Maybe<Scalars['Float']['output']>;
  template: MessageTemplateTemplate;
  type: MessageTemplateType;
};

export type MessageTemplateList = {
  __typename?: 'MessageTemplateList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<MessageTemplate>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Message Template message type */
export enum MessageTemplateMessageType {
  FOLLOWUP = 'FOLLOWUP',
  INITIAL = 'INITIAL',
}

/** Message template template */
export type MessageTemplateTemplate = {
  __typename?: 'MessageTemplateTemplate';
  audioFileBucket?: Maybe<Scalars['String']['output']>;
  audioFilePath?: Maybe<Scalars['String']['output']>;
  audioFileUrl?: Maybe<Scalars['String']['output']>;
  characters?: Maybe<Scalars['Float']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  messageType?: Maybe<MessageTemplateMessageType>;
  optOut?: Maybe<Scalars['Boolean']['output']>;
  segments?: Maybe<Scalars['Float']['output']>;
};

export type MessageTemplateTemplateInput = {
  audioFileBucket?: InputMaybe<Scalars['String']['input']>;
  audioFilePath?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  messageType?: InputMaybe<MessageTemplateMessageType>;
  optOut?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Message Template type */
export enum MessageTemplateType {
  KEYWORD = 'KEYWORD',
  RVM = 'RVM',
  SMS = 'SMS',
}

export type MessageTemplatesFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MessageTemplateType>;
};

/** Message type */
export enum MessageType {
  CALL = 'CALL',
  MMS = 'MMS',
  RVM = 'RVM',
  SMS = 'SMS',
}

/** Messaging profile */
export type MessagingProfile = {
  __typename?: 'MessagingProfile';
  a2pInfo?: Maybe<A2PInfo>;
  allowUrl: Scalars['Boolean']['output'];
  callSettings?: Maybe<CallSettings>;
  id: Scalars['String']['output'];
  isEINDocRequired: Scalars['Boolean']['output'];
  phoneSettings?: Maybe<PhoneSettings>;
  provider?: Maybe<MessagingProvider>;
  responseRemoval?: Maybe<ResponseRemoval>;
  tollFreeInfo?: Maybe<TollFreeInfo>;
};

/** Messaging provider */
export type MessagingProvider = {
  __typename?: 'MessagingProvider';
  sipPassword?: Maybe<Scalars['String']['output']>;
  sipUsername?: Maybe<Scalars['String']['output']>;
  type: MessagingProviderType;
};

/** Single provider statistic */
export type MessagingProviderCounters = {
  __typename?: 'MessagingProviderCounters';
  approved?: Maybe<Scalars['Int']['output']>;
  declined?: Maybe<Scalars['Int']['output']>;
  deliveryRate?: Maybe<Scalars['Float']['output']>;
  pending?: Maybe<Scalars['Int']['output']>;
  weeklySmsUsage?: Maybe<Scalars['Int']['output']>;
};

/** Messaging provider type */
export enum MessagingProviderType {
  COMMIO = 'COMMIO',
  INFOBIP = 'INFOBIP',
  PLIVO = 'PLIVO',
  TELNYX = 'TELNYX',
  TWILIO = 'TWILIO',
}

/** Total provider statistics */
export type MessagingProvidersCounters = {
  __typename?: 'MessagingProvidersCounters';
  commio: MessagingProviderCounters;
  infobip: MessagingProviderCounters;
  plivo: MessagingProviderCounters;
  telnyx: MessagingProviderCounters;
  twilio: MessagingProviderCounters;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Mark latest Terms and Conditions as accepted */
  acceptTermsAndConditions: OperationStatus;
  /** Add label to the contact */
  addContactLabel: OperationStatus;
  /** Add contact to DNC List */
  addContactToDNC: Suppression;
  /** Add group to contacts */
  addGroupToContacts: OperationStatus;
  /** Adds outcome labels to a particular call and the contact */
  addLabelToCallAndContact: OperationStatus;
  /** Add labels to contacts */
  addLabelsToContacts: Array<Contact>;
  /** Add payment source */
  addPaymentSource: OperationStatus;
  /** Add own phone to account by admin */
  adminAddOwnPhone: OperationStatus;
  /** Update account and subaccounts messaging provider */
  adminChangeAccountMessagingProvider: OperationStatus;
  /** Update sub account messaging provider to parent messaging provider */
  adminChangeSubAccountMessagingProvider: OperationStatus;
  /** An admin is deleting a phone number from another account. Generally used in the "user setup" panel */
  adminDeletePhone: Account;
  /** Admin migrate accounts features */
  adminMigrateAccountsFeatures: OperationStatus;
  /** Remove account and all related data */
  adminRemoveAccount: OperationStatus;
  /** Remove own phone by admin */
  adminRemoveOwnPhone: OperationStatus;
  /** Resync whole list of local phones with 10DLC campaign */
  adminResyncLocalOwnPhones: OperationStatus;
  /** Admin schedule check stucked campaigns tasks */
  adminScheduleCheckStuckedCampaigns: OperationStatus;
  /** Admin sync `Not interested 📞` labels */
  adminSyncNotInterestedLabels: OperationStatus;
  /** Top Up account balance by admin */
  adminTopUpBalance: OperationStatus;
  /** An admin account is updating another account. Generally used in the "user setup" panel */
  adminUpdateAccount: Account;
  /** Admin update account password */
  adminUpdatePassword: AccountPassword;
  /** Admin setup upsert campaigns progress tasks */
  adminUpsertCampaignProgress: OperationStatus;
  /** Manually verify caller id for bring your own carrier feature */
  adminVerifyCallerIdForBYOC: OperationStatus;
  /** Apply discount to subscription */
  applyDiscount: Discount;
  /** Add contacts to DNC by criteria */
  blockContacts: OperationStatus;
  /** Cancel scheduled messaging payments for inactive users */
  cancelScheduleMessagingPaymentInactiveUser: OperationStatus;
  confirmPasswordReset: OperationStatus;
  /** Create a remove account request */
  createAccountRemoveRequest: OperationStatus;
  /** Create new calendar event */
  createCalendarEvent: CalendarEvent;
  /** Create new call script */
  createCallScript: CallScript;
  /** Create new campaign */
  createCampaign: Campaign;
  /** Create new contact */
  createContact: Contact;
  /** Create new contact note */
  createContactNote: ContactNote;
  /** Create conversation */
  createConversation: Conversation;
  /** Create CRM integration */
  createCrmIntegration: CrmIntegration;
  /** Create or update existing phone to add it to DNC */
  createDNCPhone: Suppression;
  /** Create new dialer campaign */
  createDialerCampaign: DialerCampaign;
  /** Create feedback */
  createFeedback: OperationStatus;
  /** Create new field */
  createField: Field;
  /** Create new fields template */
  createFieldsTemplate: FieldsTemplate;
  /** Create group */
  createGroup: GroupView;
  /** Create impersonate token for account */
  createImpersonateToken: ImpersonateToken;
  /** Create new keyword template */
  createKeywordTemplate: KeywordTemplate;
  /** Create new label */
  createLabel: Label;
  /** Upload list by csv file */
  createList: ListView;
  /** Create new macro */
  createMacro: Macro;
  /** Create new marketing popup */
  createMarketingPopup: MarketingPopup;
  /** Create new message template */
  createMessageTemplate: MessageTemplate;
  /** Create restricted phone and remove it from all contacts */
  createRestrictedPhone: OperationStatus;
  /** Create a scheduled message */
  createScheduledMessage: ScheduledMessage;
  /** Create subAccount */
  createSubaccount: Account;
  /** Duplicate group */
  duplicateGroups: GroupView;
  /** End subscription trial period */
  endTrial: CurrentSubscription;
  /** Export dialer campaign results to the new or existing group */
  exportDialerCampaignToGroup: GroupView;
  /** Hang up a power dialer batch */
  hangUpPowerDialerCall: OperationStatus;
  /** Manage call recording */
  manageCallRecording: OperationStatus;
  /** Manage call recording */
  managePowerDialerCallRecording: OperationStatus;
  /** Mark account popup viewed */
  markAccountPopupViewed: AccountPopup;
  /** Mark conversations as read or unread */
  markConversationsRead: OperationStatus;
  /** Mark notifications as read or unread */
  markNotificationsRead: OperationStatus;
  /** Mark notifications as seen or unseen */
  markNotificationsSeen: OperationStatus;
  /** Pause campaign */
  pauseCampaign: OperationStatus;
  /** Pause dialer campaign */
  pauseDialerCampaign: OperationStatus;
  /** Pay bill manually */
  payBill: OperationStatus;
  /** Buy new phone */
  purchaseOwnPhone: OwnPhone;
  /** Push skiptrace to group */
  pushSkiptraceToGroup: ListView;
  /** Rank call script */
  rankCallScript: OperationStatus;
  /** Rank keyword template */
  rankKeywordTemplate: OperationStatus;
  /** Rank label */
  rankLabel: RankResult;
  /** Rank macro */
  rankMacro: OperationStatus;
  /** Rank message template */
  rankMessageTemplate: OperationStatus;
  /** Reactivate A2P registration */
  reactivateA2P: OperationStatus;
  /** Recover contacts */
  recoverContacts: OperationStatus;
  /** Resume campaign */
  refreshCampaignMessages: OperationStatus;
  /** Register A2P brand */
  registerA2P: OperationStatus;
  /** Register new affiliate */
  registerAffiliate: Affiliate;
  /** Admin set 'Referred By affiliate' on account */
  registerAffiliateLead: Affiliate;
  /** Register A2P campaign for subaccount */
  registerSubAccountA2P: OperationStatus;
  /** Register toll-free brand */
  registerTollFree: OperationStatus;
  /** Remove affiliate */
  removeAffiliate: OperationStatus;
  /** Remove calendar event and all related data */
  removeCalendarEvent: OperationStatus;
  /** Remove call script and all related data */
  removeCallScript: OperationStatus;
  /** Remove campaign and all related data */
  removeCampaign: OperationStatus;
  /** Remove contact from DNC List */
  removeContactFromDNCSuppressions: OperationStatus;
  /** Remove label from the contact */
  removeContactLabel: OperationStatus;
  /** Remove contact note */
  removeContactNote: OperationStatus;
  /** Remove contacts */
  removeContacts: OperationStatus;
  /** Remove CRM integration */
  removeCrmIntegration: OperationStatus;
  /** Remove dialer campaign and all related data */
  removeDialerCampaign: OperationStatus;
  /** Remove field and all related data */
  removeField: OperationStatus;
  /** Remove fields template and all related data */
  removeFieldsTemplate: OperationStatus;
  /** Remove group from contacts */
  removeGroupFromContacts: OperationStatus;
  /** Remove groups */
  removeGroups: OperationStatus;
  /** Remove keyword template and all related data */
  removeKeywordTemplate: OperationStatus;
  /** Remove label */
  removeLabel: OperationStatus;
  /** Remove labels from contacts */
  removeLabelsFromContacts: Array<Contact>;
  /** Remove list item */
  removeListItem: OperationStatus;
  /** Remove macro and all related data */
  removeMacro: OperationStatus;
  /** Remove marketing popup */
  removeMarketingPopup: OperationStatus;
  /** Remove message template and all related data */
  removeMessageTemplate: OperationStatus;
  /** Remove own phone */
  removeOwnPhone: OperationStatus;
  /** Remove payment source */
  removePaymentSource: OperationStatus;
  /** Remove a scheduled message */
  removeScheduledMessage: OperationStatus;
  /** Remove skiptraces */
  removeSkiptraces: OperationStatus;
  /** Remove subAccount */
  removeSubaccount: OperationStatus;
  /** Remove suppressions by source or by ids */
  removeSuppressions: OperationStatus;
  resendPasswordResetToken: OperationStatus;
  /** Resume campaign */
  resumeCampaign: OperationStatus;
  /** Resume dialer campaign */
  resumeDialerCampaign: OperationStatus;
  /** Run skiptrace */
  runSkiptrace: OperationStatus;
  /** Schedule balance */
  scheduleBalance: OperationStatus;
  /** Admin schedule check months of membership daily task */
  scheduleCheckMonthsOfMembershipTask: OperationStatus;
  /** Admin schedule daily sync messaging usage to HubSpot task */
  scheduleDailySyncMessagingUsageTask: OperationStatus;
  /** Admin schedule delete old notifications weekly task */
  scheduleDeleteOldNotificationsTask: OperationStatus;
  /** Scheduling expiring phone verifications */
  scheduleExpirePhoneVerifications: OperationStatus;
  /** Scheduling marking landline phones */
  scheduleMarkLandlinePhones: OperationStatus;
  /** Scheduling migrating old monthly subscriptions to new quarterly plans */
  scheduleMigratingSubscriptionsToQuarterlyPlans: OperationStatus;
  /** Schedules a monthly job to run and deliver reports */
  scheduleMonthlyReports: OperationStatus;
  /** Scheduling payments for own_phones */
  scheduleOwnPhonePayments: OperationStatus;
  /** Admin schedule send email exhausted phones weekly task */
  scheduleSendEmailExhaustedPhonesTask: OperationStatus;
  /** Scheduling weekly statistics job */
  scheduleWeeklyStatistics: OperationStatus;
  /** Send message */
  sendMessage: Message;
  /** Set default payment source */
  setDefaultPaymentSource: OperationStatus;
  /** Admin setup messaging quota usage reset tasks */
  setupMessagingQuotaUsageResetTasks: OperationStatus;
  /** Create account */
  signUp: Account;
  /** Start campaign */
  startCampaign: OperationStatus;
  /** Start dialer campaign */
  startDialerCampaign: OperationStatus;
  /** Indicates to the BE that the user wants to start dialing */
  startPowerDialing: OperationStatus;
  /** Submit A2P reactivation */
  submitA2PReactivation: OperationStatus;
  /** Submit 10DLC brand registration for parent account */
  submitA2PRegistration: OperationStatus;
  /** Submit 10DLC brand registration for subaccount */
  submitA2PSubAccountRegistration: OperationStatus;
  /** Submit toll-free brand registration */
  submitTollFreeRegistration: OperationStatus;
  /** Subscribe to plan */
  subscribe: CurrentSubscription;
  /** Switch dialer offline */
  switchDialerOffline: OperationStatus;
  /** Switch dialer online */
  switchDialerOnline: OperationStatus;
  /** Sync hubspot profiles */
  syncHubspotProfiles: OperationStatus;
  /** Top Up balance */
  topUpBalance: OperationStatus;
  /** Unsubscribe to plan */
  unsubscribe: OperationStatus;
  /** Update A2P registration external data */
  updateA2PRegistrationExternalData: OperationStatus;
  /** Update A2P registration info */
  updateA2PRegistrationInfo: A2PRegistration;
  /** Update account info */
  updateAccount: Account;
  /** Update account phone number in Auth DB and in Postgres */
  updateAccountPhoneNumber: OperationStatus;
  /** Update account subscription */
  updateAccountSubscription: CurrentSubscription;
  /** Update backup payment method */
  updateBillingProfile: OperationStatus;
  /** Update calendar event */
  updateCalendarEvent: CalendarEvent;
  /** Update call script */
  updateCallScript: CallScript;
  /** Update new campaign */
  updateCampaign: Campaign;
  /** Update campaign dynamic data */
  updateCampaignDynamicData: OperationStatus;
  /** Update campaign own phones */
  updateCampaignOwnPhones: OperationStatus;
  /** Update contact */
  updateContact: Contact;
  /** Update contact note */
  updateContactNote: ContactNote;
  /** Update conversation outgoing phone */
  updateConversationOutgoingPhone: OperationStatus;
  /** Update CRM integration */
  updateCrmIntegration: CrmIntegration;
  /** Update device */
  updateDevice: OperationStatus;
  /** Update dialer campaign */
  updateDialerCampaign: DialerCampaign;
  /** Update field */
  updateField: Field;
  /** Update fields template */
  updateFieldsTemplate: FieldsTemplate;
  /** Update group */
  updateGroup: GroupView;
  /** Update keyword template */
  updateKeywordTemplate: KeywordTemplate;
  /** Update label */
  updateLabel: Label;
  /** Update list */
  updateList: ListView;
  /** Update list item */
  updateListItem: OperationStatus;
  /** Update macro */
  updateMacro: Macro;
  /** Update marketing popup */
  updateMarketingPopup: MarketingPopup;
  /** Update message template */
  updateMessageTemplate: MessageTemplate;
  /** Update messaging profile */
  updateMessagingProfile: MessagingProfile;
  /** Update notification settings */
  updateNotificationSettings: OperationStatus;
  /** Update a scheduled message */
  updateScheduledMessage: ScheduledMessage;
  /** Update skiptrace */
  updateSkiptrace: Skiptrace;
  /** Update subAccount */
  updateSubaccount: Account;
  /** Update subscription */
  updateSubscription: CurrentSubscription;
  /** Update A2P registration info */
  updateTollFreeRegistrationInfo: TollFreeRegistration;
  /** Upload list of phone numbers to add them to DNC by csv file */
  uploadDNCPhones: OperationStatus;
  /** Upload EIN file */
  uploadEINFile: OperationStatus;
  /** Verify list according to selected fields */
  verifyList: OperationStatus;
  /** Verify promo code */
  verifyPromoCode: PromoCode;
};

export type MutationAddContactLabelArgs = {
  contactId: Scalars['String']['input'];
  labelId: Scalars['String']['input'];
};

export type MutationAddContactToDncArgs = {
  input: AddContactToDncInput;
};

export type MutationAddGroupToContactsArgs = {
  input: AddGroupToContactsInput;
};

export type MutationAddLabelToCallAndContactArgs = {
  input: AddLabelToCallAndContactInput;
};

export type MutationAddLabelsToContactsArgs = {
  input: AddLabelsToContactsInput;
};

export type MutationAddPaymentSourceArgs = {
  input: PaymentSourceInput;
};

export type MutationAdminAddOwnPhoneArgs = {
  input: AdminAddOwnPhoneInput;
};

export type MutationAdminChangeAccountMessagingProviderArgs = {
  input: AdminChangeAccountMessagingProviderInput;
};

export type MutationAdminChangeSubAccountMessagingProviderArgs = {
  input: AdminChangeSubAccountMessagingProviderInput;
};

export type MutationAdminDeletePhoneArgs = {
  id: Scalars['String']['input'];
};

export type MutationAdminMigrateAccountsFeaturesArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type MutationAdminRemoveAccountArgs = {
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationAdminRemoveOwnPhoneArgs = {
  input: AdminRemoveOwnPhoneInput;
};

export type MutationAdminResyncLocalOwnPhonesArgs = {
  profileId: Scalars['String']['input'];
  scope?: InputMaybe<Scope>;
};

export type MutationAdminTopUpBalanceArgs = {
  input: TopUpAccountBalanceInput;
};

export type MutationAdminUpdateAccountArgs = {
  data: AdminUpdateAccountInput;
  id: Scalars['String']['input'];
};

export type MutationAdminUpdatePasswordArgs = {
  id: Scalars['String']['input'];
};

export type MutationAdminUpsertCampaignProgressArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAdminVerifyCallerIdForByocArgs = {
  phone: Scalars['PhoneNumberType']['input'];
};

export type MutationApplyDiscountArgs = {
  input: ApplyDiscountInput;
  subscriptionId: Scalars['String']['input'];
};

export type MutationBlockContactsArgs = {
  input: BlockContactsInput;
};

export type MutationCancelScheduleMessagingPaymentInactiveUserArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationConfirmPasswordResetArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationCreateCalendarEventArgs = {
  input: CreateCalendarEventInput;
};

export type MutationCreateCallScriptArgs = {
  input: CreateCallScriptInput;
};

export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};

export type MutationCreateContactArgs = {
  input: CreateContactInput;
};

export type MutationCreateContactNoteArgs = {
  contactId: Scalars['String']['input'];
  input: CreateContactNoteInput;
};

export type MutationCreateConversationArgs = {
  input: CreateConversationInput;
};

export type MutationCreateCrmIntegrationArgs = {
  input: CreateCrmIntegrationInput;
};

export type MutationCreateDncPhoneArgs = {
  input: CreateDncPhoneInput;
};

export type MutationCreateDialerCampaignArgs = {
  input: CreateDialerCampaignInput;
};

export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};

export type MutationCreateFieldArgs = {
  input: CreateFieldInput;
};

export type MutationCreateFieldsTemplateArgs = {
  input: CreateFieldsTemplateInput;
};

export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};

export type MutationCreateImpersonateTokenArgs = {
  id: Scalars['String']['input'];
};

export type MutationCreateKeywordTemplateArgs = {
  input: CreateKeywordTemplateInput;
};

export type MutationCreateLabelArgs = {
  input: CreateLabelInput;
};

export type MutationCreateListArgs = {
  input: CreateListInput;
};

export type MutationCreateMacroArgs = {
  input: CreateMacroInput;
};

export type MutationCreateMarketingPopupArgs = {
  input: CreateMarketingPopupInput;
};

export type MutationCreateMessageTemplateArgs = {
  input: CreateMessageTemplateInput;
};

export type MutationCreateRestrictedPhoneArgs = {
  input: CreateRestrictedPhoneInput;
};

export type MutationCreateScheduledMessageArgs = {
  input: CreateScheduledMessageInput;
};

export type MutationCreateSubaccountArgs = {
  input: CreateSubaccountInput;
};

export type MutationDuplicateGroupsArgs = {
  id: Scalars['String']['input'];
};

export type MutationEndTrialArgs = {
  input?: InputMaybe<EndTrialInput>;
  subscriptionId: Scalars['String']['input'];
};

export type MutationExportDialerCampaignToGroupArgs = {
  id: Scalars['String']['input'];
  input: ExportDialerCampaignToGroupInput;
};

export type MutationHangUpPowerDialerCallArgs = {
  batchId: Scalars['String']['input'];
};

export type MutationManageCallRecordingArgs = {
  input: ManageCallRecordingInput;
};

export type MutationManagePowerDialerCallRecordingArgs = {
  input: ManagePowerDialerCallRecordingInput;
};

export type MutationMarkAccountPopupViewedArgs = {
  input: MarkAccountPopupViewedInput;
};

export type MutationMarkConversationsReadArgs = {
  input: MarkConversationsReadInput;
};

export type MutationMarkNotificationsReadArgs = {
  input: MarkNotificationsReadInput;
};

export type MutationMarkNotificationsSeenArgs = {
  input: MarkNotificationsSeenInput;
};

export type MutationPauseCampaignArgs = {
  id: Scalars['String']['input'];
};

export type MutationPauseDialerCampaignArgs = {
  id: Scalars['String']['input'];
};

export type MutationPayBillArgs = {
  input: PayBillInput;
};

export type MutationPurchaseOwnPhoneArgs = {
  input: PurchaseOwnPhoneInput;
};

export type MutationPushSkiptraceToGroupArgs = {
  input: PushSkiptraceToGroupInput;
};

export type MutationRankCallScriptArgs = {
  id: Scalars['String']['input'];
  input: RankCallScriptInput;
};

export type MutationRankKeywordTemplateArgs = {
  id: Scalars['String']['input'];
  input: RankKeywordTemplateInput;
};

export type MutationRankLabelArgs = {
  id: Scalars['String']['input'];
  input: RankLabelInput;
};

export type MutationRankMacroArgs = {
  id: Scalars['String']['input'];
  input: RankMacroInput;
};

export type MutationRankMessageTemplateArgs = {
  id: Scalars['String']['input'];
  input: RankMessageTemplateInput;
};

export type MutationReactivateA2PArgs = {
  accountId: Scalars['String']['input'];
};

export type MutationRecoverContactsArgs = {
  input: RecoverContactsInput;
};

export type MutationRefreshCampaignMessagesArgs = {
  id: Scalars['String']['input'];
};

export type MutationRegisterA2PArgs = {
  accountId: Scalars['String']['input'];
};

export type MutationRegisterAffiliateArgs = {
  input: RegisterAffiliateInput;
};

export type MutationRegisterAffiliateLeadArgs = {
  input: AdminRegisterAffiliateLeadInput;
};

export type MutationRegisterSubAccountA2PArgs = {
  accountId: Scalars['String']['input'];
};

export type MutationRegisterTollFreeArgs = {
  accountId: Scalars['String']['input'];
};

export type MutationRemoveAffiliateArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveCalendarEventArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveCallScriptArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveCampaignArgs = {
  input: RemoveCampaignInput;
};

export type MutationRemoveContactFromDncSuppressionsArgs = {
  input: RemoveContactFromDncSuppressionsInput;
};

export type MutationRemoveContactLabelArgs = {
  contactId: Scalars['String']['input'];
  labelId: Scalars['String']['input'];
};

export type MutationRemoveContactNoteArgs = {
  contactId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationRemoveContactsArgs = {
  input: RemoveContactsInput;
};

export type MutationRemoveCrmIntegrationArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveDialerCampaignArgs = {
  input: RemoveDialerCampaignInput;
};

export type MutationRemoveFieldArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveFieldsTemplateArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveGroupFromContactsArgs = {
  input: RemoveGroupFromContactsInput;
};

export type MutationRemoveGroupsArgs = {
  input: RemoveGroupsInput;
};

export type MutationRemoveKeywordTemplateArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveLabelArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveLabelsFromContactsArgs = {
  input: RemoveLabelsFromContactsInput;
};

export type MutationRemoveListItemArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveMacroArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveMarketingPopupArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveMessageTemplateArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveOwnPhoneArgs = {
  ownPhoneId: Scalars['String']['input'];
};

export type MutationRemovePaymentSourceArgs = {
  input: PaymentSourceInput;
};

export type MutationRemoveScheduledMessageArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveSkiptracesArgs = {
  input: RemoveSkiptracesInput;
};

export type MutationRemoveSubaccountArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveSuppressionsArgs = {
  input: RemoveSuppressionsInput;
};

export type MutationResumeCampaignArgs = {
  id: Scalars['String']['input'];
};

export type MutationResumeDialerCampaignArgs = {
  id: Scalars['String']['input'];
};

export type MutationRunSkiptraceArgs = {
  input: RunSkiptraceInput;
};

export type MutationScheduleBalanceArgs = {
  input: ScheduleBalanceInput;
};

export type MutationScheduleCheckMonthsOfMembershipTaskArgs = {
  input: ScheduleCheckMonthsOfMembershipTaskInput;
};

export type MutationScheduleMigratingSubscriptionsToQuarterlyPlansArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationScheduleOwnPhonePaymentsArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type MutationSendMessageArgs = {
  input: SendMessageInput;
};

export type MutationSetDefaultPaymentSourceArgs = {
  input: PaymentSourceInput;
};

export type MutationSetupMessagingQuotaUsageResetTasksArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type MutationStartCampaignArgs = {
  id: Scalars['String']['input'];
};

export type MutationStartDialerCampaignArgs = {
  id: Scalars['String']['input'];
};

export type MutationStartPowerDialingArgs = {
  input: StartPowerDialingInput;
};

export type MutationSubmitA2PRegistrationArgs = {
  input: SubmitA2PRegistrationInput;
};

export type MutationSubmitA2PSubAccountRegistrationArgs = {
  input: SubmitA2PSubAccountRegistrationInput;
};

export type MutationSubmitTollFreeRegistrationArgs = {
  input: TollFreeBrandInput;
};

export type MutationSubscribeArgs = {
  input: SubscribeInput;
};

export type MutationSwitchDialerOfflineArgs = {
  inPowerDialer: Scalars['Boolean']['input'];
};

export type MutationSwitchDialerOnlineArgs = {
  campaignId: Scalars['String']['input'];
};

export type MutationSyncHubspotProfilesArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<SyncHubspotProfilesInput>;
};

export type MutationTopUpBalanceArgs = {
  input: TopUpBalanceInput;
};

export type MutationUnsubscribeArgs = {
  reason?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['String']['input'];
};

export type MutationUpdateA2PRegistrationExternalDataArgs = {
  accountId: Scalars['String']['input'];
  input: UpdateA2PRegistrationExternalDataInput;
};

export type MutationUpdateA2PRegistrationInfoArgs = {
  accountId: Scalars['String']['input'];
  input: UpdateA2PRegistrationInfoInput;
};

export type MutationUpdateAccountArgs = {
  data: UpdateAccountInput;
};

export type MutationUpdateAccountPhoneNumberArgs = {
  accountId: Scalars['String']['input'];
  phone: Scalars['PhoneNumberType']['input'];
};

export type MutationUpdateAccountSubscriptionArgs = {
  accountId: Scalars['String']['input'];
  input: UpdateSubscriptionInput;
  subscriptionId: Scalars['String']['input'];
};

export type MutationUpdateBillingProfileArgs = {
  backupPaymentMethod: Scalars['Boolean']['input'];
};

export type MutationUpdateCalendarEventArgs = {
  id: Scalars['String']['input'];
  input: UpdateCalendarEventInput;
};

export type MutationUpdateCallScriptArgs = {
  id: Scalars['String']['input'];
  input: UpdateCallScriptInput;
};

export type MutationUpdateCampaignArgs = {
  id: Scalars['String']['input'];
  input: UpdateCampaignInput;
};

export type MutationUpdateCampaignDynamicDataArgs = {
  id: Scalars['String']['input'];
  input: CampaignDynamicDataInput;
};

export type MutationUpdateCampaignOwnPhonesArgs = {
  id: Scalars['String']['input'];
  input: UpdateCampaignOwnPhonesInput;
};

export type MutationUpdateContactArgs = {
  id: Scalars['String']['input'];
  input: UpdateContactInput;
};

export type MutationUpdateContactNoteArgs = {
  contactId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  input: UpdateContactNoteInput;
};

export type MutationUpdateConversationOutgoingPhoneArgs = {
  input: UpdateConversationOutgoingPhoneInput;
};

export type MutationUpdateCrmIntegrationArgs = {
  id: Scalars['String']['input'];
  input: UpdateCrmIntegrationInput;
};

export type MutationUpdateDeviceArgs = {
  input: UpdateDeviceInput;
};

export type MutationUpdateDialerCampaignArgs = {
  id: Scalars['String']['input'];
  input: UpdateDialerCampaignInput;
};

export type MutationUpdateFieldArgs = {
  id: Scalars['String']['input'];
  input: UpdateFieldInput;
};

export type MutationUpdateFieldsTemplateArgs = {
  id: Scalars['String']['input'];
  input: UpdateFieldsTemplateInput;
};

export type MutationUpdateGroupArgs = {
  id: Scalars['String']['input'];
  input: UpdateGroupInput;
};

export type MutationUpdateKeywordTemplateArgs = {
  id: Scalars['String']['input'];
  input: UpdateKeywordTemplateInput;
};

export type MutationUpdateLabelArgs = {
  id: Scalars['String']['input'];
  input: UpdateLabelInput;
};

export type MutationUpdateListArgs = {
  id: Scalars['String']['input'];
  input: UpdateListInput;
};

export type MutationUpdateListItemArgs = {
  id: Scalars['String']['input'];
  input: UpdateListItemInput;
};

export type MutationUpdateMacroArgs = {
  id: Scalars['String']['input'];
  input: UpdateMacroInput;
};

export type MutationUpdateMarketingPopupArgs = {
  id: Scalars['String']['input'];
  input: UpdateMarketingPopupInput;
};

export type MutationUpdateMessageTemplateArgs = {
  id: Scalars['String']['input'];
  input: UpdateMessageTemplateInput;
};

export type MutationUpdateMessagingProfileArgs = {
  input: UpdateMessagingProfileInput;
};

export type MutationUpdateNotificationSettingsArgs = {
  input: UpdateNotificationSettingsInput;
};

export type MutationUpdateScheduledMessageArgs = {
  id: Scalars['String']['input'];
  input: UpdateScheduledMessageInput;
};

export type MutationUpdateSkiptraceArgs = {
  id: Scalars['String']['input'];
  input: UpdateSkiptraceInput;
};

export type MutationUpdateSubaccountArgs = {
  id: Scalars['String']['input'];
  input: UpdateSubaccountInput;
};

export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
  subscriptionId: Scalars['String']['input'];
};

export type MutationUpdateTollFreeRegistrationInfoArgs = {
  accountId: Scalars['String']['input'];
  input: UpdateTollFreeRegistrationInfoInput;
};

export type MutationUploadDncPhonesArgs = {
  input: UploadDncPhonesInput;
};

export type MutationUploadEinFileArgs = {
  input: UploadEinFileInput;
};

export type MutationVerifyListArgs = {
  id: Scalars['String']['input'];
};

export type MutationVerifyPromoCodeArgs = {
  input: VerifyPromoCodeInput;
};

/** Name */
export type Name = {
  __typename?: 'Name';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

/** Notification */
export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime']['output'];
  details?: Maybe<NotificationDetails>;
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  isSeen: Scalars['Boolean']['output'];
  type: NotificationType;
};

/** Notification counter */
export type NotificationCounter = {
  __typename?: 'NotificationCounter';
  accountId: Scalars['String']['output'];
  unreadCount: Scalars['Int']['output'];
};

/** Notification details */
export type NotificationDetails = {
  __typename?: 'NotificationDetails';
  calendarEventDate?: Maybe<Scalars['String']['output']>;
  calendarEventId?: Maybe<Scalars['String']['output']>;
  calendarEventName?: Maybe<Scalars['String']['output']>;
  isKeywordCampaign?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  relationId?: Maybe<Scalars['String']['output']>;
};

export type NotificationList = {
  __typename?: 'NotificationList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Notification>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Notification settings */
export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  enabledCalendar?: Maybe<Scalars['Boolean']['output']>;
  enabledCampaigns?: Maybe<Scalars['Boolean']['output']>;
  enabledPayments?: Maybe<Scalars['Boolean']['output']>;
};

/** Notification Type */
export enum NotificationType {
  BLOCKED_CAMPAIGN = 'BLOCKED_CAMPAIGN',
  CALENDAR_EVENT_CREATED = 'CALENDAR_EVENT_CREATED',
  CAMPAIGN_ENDED = 'CAMPAIGN_ENDED',
  CAMPAIGN_STARTED = 'CAMPAIGN_STARTED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PHONE_EXHAUSTED = 'PHONE_EXHAUSTED',
}

export type OperationStatus = {
  __typename?: 'OperationStatus';
  success: Scalars['Boolean']['output'];
};

export type OptInWorkflowFileInput = {
  contentType: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
};

export type Order = {
  direction?: InputMaybe<OrderQuery>;
};

/** Order pagination */
export enum OrderPagination {
  NEXT = 'NEXT',
  PREV = 'PREV',
}

/** Order direction */
export enum OrderQuery {
  ASC = 'ASC',
  DESC = 'DESC',
}

/** Outside business hours restriction type */
export enum OutsideBusinessHoursRestrictionType {
  CAMPAIGN = 'CAMPAIGN',
}

/** View for reporting outbound call counts for a phone number */
export type OwnDialerPhoneReportView = {
  __typename?: 'OwnDialerPhoneReportView';
  outboundCalls?: Maybe<Scalars['Float']['output']>;
  phoneId: Scalars['String']['output'];
};

/** Own phone */
export type OwnPhone = {
  __typename?: 'OwnPhone';
  callingEnabled: Scalars['Boolean']['output'];
  canBeRemoved: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isTrial: Scalars['Boolean']['output'];
  location?: Maybe<PhoneNumberLocation>;
  messagingEnabled: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  reportDialer?: Maybe<OwnDialerPhoneReportView>;
  reportMessage?: Maybe<OwnPhoneReport>;
  scope: Scope;
  status: OwnPhoneStatus;
  type: OwnPhoneType;
};

/** Own phone counters */
export type OwnPhoneCounters = {
  __typename?: 'OwnPhoneCounters';
  local: Scalars['Int']['output'];
  tollFree: Scalars['Int']['output'];
};

export type OwnPhoneFilter = {
  callingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  messagingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  scope?: InputMaybe<Scope>;
  status?: InputMaybe<OwnPhoneStatus>;
  type?: InputMaybe<OwnPhoneType>;
};

export type OwnPhoneList = {
  __typename?: 'OwnPhoneList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<OwnPhone>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Own phone report view */
export type OwnPhoneReport = {
  __typename?: 'OwnPhoneReport';
  activeConversations: Scalars['Float']['output'];
  blockedRateMessages: Scalars['Float']['output'];
  lastUsedAt?: Maybe<Scalars['DateTime']['output']>;
  outgoingMessages: Scalars['Float']['output'];
  phoneId: Scalars['String']['output'];
};

/** Own phone status */
export enum OwnPhoneStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  NONE = 'NONE',
  PENDING = 'PENDING',
}

/** Own phone type */
export enum OwnPhoneType {
  LOCAL = 'LOCAL',
  TOLL_FREE = 'TOLL_FREE',
}

export type Pagination = {
  /** Number of items returned in query. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Direction getting the corresponding page of data. */
  moveTo?: InputMaybe<OrderPagination>;
  /** Created at after which to fetch records. */
  nextCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** ID after which to fetch records. */
  nextId?: InputMaybe<Scalars['String']['input']>;
};

export type PayBillInput = {
  paymentSourceId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

/** Payment */
export type Payment = {
  __typename?: 'Payment';
  amount?: Maybe<Scalars['Float']['output']>;
  amountBonus?: Maybe<Scalars['Float']['output']>;
  amountCapture?: Maybe<Scalars['Float']['output']>;
  amountRefund?: Maybe<Scalars['Float']['output']>;
  billingProfile?: Maybe<BillingProfile>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  feature: PaymentFeature;
  id: Scalars['String']['output'];
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  method: PaymentMethod;
  profileId: Scalars['String']['output'];
  purpose: PaymentPurpose;
  status: PaymentStatus;
  type: PaymentType;
};

/** Payment feature */
export enum PaymentFeature {
  A2P_10DLC = 'A2P_10DLC',
  CALL = 'CALL',
  CAMPAIGN = 'CAMPAIGN',
  CUSTOM_WEBSITE = 'CUSTOM_WEBSITE',
  DIALER_CALL = 'DIALER_CALL',
  LOOKUP = 'LOOKUP',
  MESSAGING = 'MESSAGING',
  MMS = 'MMS',
  PHONE = 'PHONE',
  RECHARGE = 'RECHARGE',
  RVM = 'RVM',
  SKIPTRACE = 'SKIPTRACE',
  SMS = 'SMS',
  SMS_ALERT = 'SMS_ALERT',
  SUBACCOUNT = 'SUBACCOUNT',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export type PaymentList = {
  __typename?: 'PaymentList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Payment>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Payment method */
export enum PaymentMethod {
  BALANCE = 'BALANCE',
  CARD = 'CARD',
}

/** Payment purpose */
export enum PaymentPurpose {
  FEATURE = 'FEATURE',
  RECHARGE = 'RECHARGE',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

/** Payment source */
export type PaymentSource = {
  __typename?: 'PaymentSource';
  brand?: Maybe<Scalars['String']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  expMonth?: Maybe<Scalars['Float']['output']>;
  expYear?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  last4?: Maybe<Scalars['String']['output']>;
};

export type PaymentSourceInput = {
  id: Scalars['String']['input'];
};

/** Payment status */
export enum PaymentStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
}

/** Payment type */
export enum PaymentType {
  CAPTURE = 'CAPTURE',
  REFUND = 'REFUND',
}

export type PaymentsFilter = {
  createdAtEnd?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtStart?: InputMaybe<Scalars['DateTime']['input']>;
  method?: InputMaybe<PaymentMethod>;
};

/** Period */
export type Period = {
  __typename?: 'Period';
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  startedAt: Scalars['DateTime']['output'];
};

/** Phone number */
export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  location?: Maybe<PhoneNumberLocation>;
  number: Scalars['String']['output'];
};

/** Phone number location */
export type PhoneNumberLocation = {
  __typename?: 'PhoneNumberLocation';
  abbreviation: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

/** Phone settings */
export type PhoneSettings = {
  __typename?: 'PhoneSettings';
  activeIn?: Maybe<Scalars['String']['output']>;
  alertFilter?: Maybe<PhoneSettingsFilter>;
  callAutoReply?: Maybe<PhoneSettingsAutoReply>;
  isSipEnabled?: Maybe<Scalars['Boolean']['output']>;
  notificationFilter?: Maybe<PhoneSettingsFilter>;
  notificationsEnabled?: Maybe<Scalars['Boolean']['output']>;
  voiceMail?: Maybe<PhoneSettingsAutoReply>;
};

/** Phone settings auto reply */
export type PhoneSettingsAutoReply = {
  __typename?: 'PhoneSettingsAutoReply';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  voiceType?: Maybe<PhoneSettingsVoiceType>;
};

export type PhoneSettingsAutoReplyInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  voiceType?: InputMaybe<PhoneSettingsVoiceType>;
};

/** Phone settings filter */
export type PhoneSettingsFilter = {
  __typename?: 'PhoneSettingsFilter';
  campaigns?: Maybe<Array<Scalars['String']['output']>>;
  groups?: Maybe<Array<Scalars['String']['output']>>;
  labels?: Maybe<Array<Scalars['String']['output']>>;
};

export type PhoneSettingsFilterInput = {
  campaigns?: InputMaybe<Array<Scalars['String']['input']>>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PhoneSettingsInput = {
  activeIn?: InputMaybe<Scalars['String']['input']>;
  alertFilter?: InputMaybe<PhoneSettingsFilterInput>;
  callAutoReply?: InputMaybe<PhoneSettingsAutoReplyInput>;
  isSipEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  notificationFilter?: InputMaybe<PhoneSettingsFilterInput>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  voiceMail?: InputMaybe<PhoneSettingsAutoReplyInput>;
};

/** Phone settings voice type */
export enum PhoneSettingsVoiceType {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

/** Plan */
export type Plan = {
  __typename?: 'Plan';
  active: Scalars['Boolean']['output'];
  base: Scalars['Boolean']['output'];
  couponId?: Maybe<Scalars['String']['output']>;
  default: Scalars['Boolean']['output'];
  discount: Scalars['Money']['output'];
  displayPrice: Scalars['Money']['output'];
  enterprise: Scalars['Boolean']['output'];
  features: Array<PlanFeature>;
  id: Scalars['String']['output'];
  interval: BillingInterval;
  lexorank?: Maybe<Scalars['String']['output']>;
  monthlyPrice?: Maybe<Scalars['Money']['output']>;
  mostPopular: Scalars['Boolean']['output'];
  price: Scalars['Money']['output'];
  product: Product;
  secondary: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: PlanType;
  userTitle: Scalars['String']['output'];
};

/** Plan feature */
export type PlanFeature = {
  __typename?: 'PlanFeature';
  limit?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Money']['output']>;
  product?: Maybe<Product>;
  tier?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  trialLimits: PlanFeatureTrial;
  type: FeatureType;
};

/** Plan feature trial */
export type PlanFeatureTrial = {
  __typename?: 'PlanFeatureTrial';
  limit?: Maybe<Scalars['Float']['output']>;
  tier?: Maybe<Scalars['Float']['output']>;
  use: Scalars['Boolean']['output'];
};

/** Plan type */
export enum PlanType {
  BASIC = 'BASIC',
  ELITE = 'ELITE',
  ELITE_OLD = 'ELITE_OLD',
  PRO = 'PRO',
  STANDARD = 'STANDARD',
}

/** Power dialer error type */
export enum PowerDialerFailedErrorType {
  ALREADY_ONLINE = 'ALREADY_ONLINE',
  BAD_MESSAGING_PROVIDER_TYPE = 'BAD_MESSAGING_PROVIDER_TYPE',
  CAMPAIGN_COULDNT_START = 'CAMPAIGN_COULDNT_START',
  CAMPAIGN_NOT_FOUND = 'CAMPAIGN_NOT_FOUND',
  DAILY_CONTACT_LIMIT_REACHED = 'DAILY_CONTACT_LIMIT_REACHED',
  FAILED_TO_CONNECT_CALL = 'FAILED_TO_CONNECT_CALL',
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  LABEL_NOT_APPLIED = 'LABEL_NOT_APPLIED',
}

/** Error encountered during power dialer execution */
export type PowerDialerFailedView = {
  __typename?: 'PowerDialerFailedView';
  errorMessage: Scalars['String']['output'];
  errorType: PowerDialerFailedErrorType;
};

/** Info about the latest Dialer call state. */
export type PowerDialerStateUpdatedView = {
  __typename?: 'PowerDialerStateUpdatedView';
  batchId: Scalars['String']['output'];
  campaignId: Scalars['String']['output'];
  campaignIsCompleted?: Maybe<Scalars['Boolean']['output']>;
  connectedLineId?: Maybe<Scalars['String']['output']>;
  currentContactId?: Maybe<Scalars['String']['output']>;
  currentOutboundNumber?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  establishedAt?: Maybe<Scalars['DateTime']['output']>;
  hangupIsDisabled: Scalars['Boolean']['output'];
  isConnecting?: Maybe<Scalars['Boolean']['output']>;
  isDialing: Scalars['Boolean']['output'];
  isRecording?: Maybe<Scalars['Boolean']['output']>;
  lines: Array<LineView>;
  showOutcomeLabels: Scalars['Boolean']['output'];
};

/** Predefined field type */
export enum PredefinedField {
  ADDRESS = 'ADDRESS',
  CITY = 'CITY',
  EMAIL = 'EMAIL',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  MAILING_ADDRESS = 'MAILING_ADDRESS',
  MAILING_CITY = 'MAILING_CITY',
  MAILING_STATE = 'MAILING_STATE',
  MAILING_ZIP = 'MAILING_ZIP',
  PHONE = 'PHONE',
  STATE = 'STATE',
  ZIP = 'ZIP',
}

/** Product */
export enum Product {
  BASE = 'BASE',
  DIALER = 'DIALER',
  MESSAGING = 'MESSAGING',
}

/** Product summary */
export type ProductSummary = {
  __typename?: 'ProductSummary';
  activePrimary?: Maybe<Scalars['Int']['output']>;
  activeSecondary?: Maybe<Scalars['Int']['output']>;
  inactive?: Maybe<Scalars['Int']['output']>;
  monthNewActive?: Maybe<Scalars['Int']['output']>;
  product: Product;
  trial?: Maybe<Scalars['Int']['output']>;
};

/** Profile billing */
export type ProfileBilling = {
  __typename?: 'ProfileBilling';
  amount: Scalars['Money']['output'];
  paidAt: Scalars['DateTime']['output'];
};

/** Product fees */
export type ProfileProductFees = {
  __typename?: 'ProfileProductFees';
  amount: Scalars['Money']['output'];
  product: Product;
};

/** Promotional code */
export type PromoCode = {
  __typename?: 'PromoCode';
  code: Scalars['String']['output'];
  coupon: Coupon;
  id: Scalars['String']['output'];
};

export type PurchaseOwnPhoneInput = {
  phone: Scalars['String']['input'];
  scope: Scope;
  type: OwnPhoneType;
};

export type PushSkiptraceToGroupInput = {
  id: Scalars['String']['input'];
  type: PushToGroupSkiptraceType;
};

/** Push to group skiptrace type */
export enum PushToGroupSkiptraceType {
  ALL = 'ALL',
  MOBILE_ONLY = 'MOBILE_ONLY',
}

export type Query = {
  __typename?: 'Query';
  /** Get current account info */
  account: Account;
  /** Get current account affiliate */
  affiliate: Affiliate;
  /** Build message templates utilizing AI completion */
  buildMessageTemplates: Array<MessageTemplate>;
  /** Download billing report */
  downloadBillingReport: BillingReport;
  /** Export accounts */
  exportAccounts: SignedUrl;
  /** Export campaign results to file */
  exportCampaignToFile: SignedUrl;
  /** Export campaign results to group */
  exportCampaignToGroup: GroupView;
  /** Export contacts to CRM integration */
  exportConversationsToCrmIntegration: ExportConversationsToCrmIntegrationResultView;
  /** Export conversations to file */
  exportConversationsToFile: SignedUrl;
  /** Export dialer campaign results to file */
  exportDialerCampaignToFile: SignedUrl;
  /** Export group */
  exportGroup: SignedUrl;
  /** Generate signed url for downloading list from storage */
  exportList: SignedUrl;
  /** Export skiptrace to CSV file */
  exportSkiptrace: SignedUrl;
  /** Export suppressions */
  exportSuppressions: SignedUrl;
  /** Find accounts */
  findAccounts: AccountList;
  /** Find active marketing popups */
  findActiveMarketingPopups: Array<MarketingPopup>;
  /** Get list of affiliates */
  findAffiliates: AffiliateList;
  /** Find help articles */
  findArticles: Array<Article>;
  /** Find available phones */
  findAvailablePhones: Array<AvailablePhoneView>;
  /** Find calendar events */
  findCalendarEvents: CalendarEventList;
  /** Find call script */
  findCallScripts: CallScriptList;
  /** Find campaign messages */
  findCampaignMessages: ScheduledMessageList;
  /** Find campaigns */
  findCampaigns: CampaignList;
  /** Find closest calendar event for relation ID for application access */
  findClosestCalendarEventApplicationForRelation?: Maybe<CalendarEvent>;
  /** Find closest calendar event for relation ID */
  findClosestCalendarEventForRelation?: Maybe<CalendarEvent>;
  /** Find contact notes */
  findContactNotes: ContactNoteList;
  /** Find contact notes for contact by Firestore ID for application access */
  findContactNotesApplicationForContactByFirestoreId: Array<ContactNote>;
  /** Find contact notes for contact by Firestore ID */
  findContactNotesForContactByFirestoreId: ContactNoteList;
  /** Find contacts */
  findContacts: ContactList;
  /** Find conversation messages */
  findConversationMessages: MessageList;
  /** Find conversations */
  findConversations: ConversationList;
  /** Find CRM integrations */
  findCrmIntegrations: CrmIntegrationList;
  /** Find dialer campaigns */
  findDialerCampaigns: DialerCampaignList;
  /**
   * Find feedbacks
   * @deprecated Legacy API, use findLastBeedback instead
   */
  findFeedbacks: Array<Feedback>;
  /** Find fields */
  findFields: Array<Field>;
  /** Find fields templates */
  findFieldsTemplates: FieldsTemplateList;
  /** Find groups */
  findGroups: GroupList;
  /** Find keyword templates */
  findKeywordTemplates: KeywordTemplateList;
  /** Find labels */
  findLabels: LabelList;
  /**
   * Find labels for Firestore. It will be removed after the complete migration.
   * @deprecated It's will be removed soon.
   */
  findLabelsForFuckingFirestore: Array<Label>;
  /** Find last feedback by source */
  findLastFeedback?: Maybe<Feedback>;
  /** Find list items */
  findListItems: ListItemList;
  /** Find macros */
  findMacros: MacroList;
  /** Find marketing popups */
  findMarketingPopups: MarketingPopupList;
  /** Find fields that could be used as merge tags */
  findMergeTags: Array<MergeTag>;
  /** Find message templates */
  findMessageTemplates: MessageTemplateList;
  /**
   * Find message templates for Firestore. It will be removed after the complete migration.
   * @deprecated It's will be removed soon.
   */
  findMessageTemplatesForFuckingFirestore: Array<MessageTemplate>;
  /** Get list of notifications */
  findNotifications: NotificationList;
  /** Find own phones */
  findOwnPhones: OwnPhoneList;
  /** Get list of payments */
  findPayments: PaymentList;
  /** Find refunded bonus payments. Temporary integration query. */
  findRefundedBonusPayments: Array<Payment>;
  /** Find scheduled messages */
  findScheduledMessages: ScheduledMessageList;
  /** Find skiptrace fields */
  findSkiptraceFields: Array<SkiptraceField>;
  /** Find skiptraces */
  findSkiptraces: SkiptraceList;
  /** Find suppressions */
  findSuppressions: SuppressionList;
  /** Generate WebRTC token for PowerDialer use */
  generatePowerDialerWebRTCToken: WebRtcToken;
  /** Generate WebRTC token for web client */
  generateWebRTCToken: WebRtcToken;
  /** Get A2P registration info for reactivation form */
  getA2PReactivationInfo: A2PRegistration;
  /** Get A2P registration info */
  getA2PRegistrationInfo: A2PRegistration;
  /** Find account by ID */
  getAccount: Account;
  /**
   * Get accounts counters
   * @deprecated Use `getAccountsSummary` instead. This query will be removed once `getAccountsSummary` implemented.
   */
  getAccountsCounters: AccountCounters;
  /** Get accounts summary */
  getAccountsSummary: AccountsSummary;
  /** Get active plans */
  getActivePlans: Array<Plan>;
  /** Get affiliate by ID */
  getAffiliate: Affiliate;
  /** Find calendar event by ID */
  getCalendarEvent: CalendarEvent;
  /** Get call script by ID */
  getCallScript: CallScript;
  /** Find campaign by ID */
  getCampaign: Campaign;
  /** Get campaign report */
  getCampaignReport: CampaignReport;
  /** Get campaign schedule */
  getCampaignSchedule: CampaignSchedule;
  /** Get campaigns counters */
  getCampaignsCounters: CampaignsAggregationView;
  /** Find contact by ID */
  getContact: Contact;
  /** Generate signed url for uploading contact note images to Storage */
  getContactNoteSignedUrl: SignedUrl;
  /** Get contact report */
  getContactReport: ContactReportView;
  /** Get contact counters */
  getContactsCounters: ContactCountersView;
  /** Find conversation by ID */
  getConversation: Conversation;
  /** Get CRM integration */
  getCrmIntegration: CrmIntegration;
  /** Get current call */
  getCurrentCall: Message;
  /** Get deals closed report */
  getDealsClosedReport: DealsClosedReport;
  /** Get dialer call outcome report */
  getDialerCallOutcomeReport: Array<DialerCallOutcomeReportView>;
  /** Get dialer call report */
  getDialerCallReport: DialerCallReportView;
  /** Find dialer campaign by ID */
  getDialerCampaign: DialerCampaign;
  /** Get dialer campaigns counters */
  getDialerCampaignsCounters: DialerCampaignsAggregationView;
  /** Get dialer contact report */
  getDialerContactReport: DialerContactReportView;
  /** Generate signed url for uploading EIN file */
  getEINFileUploadSignedUrl: SignedUrl;
  /** Find fields template by ID */
  getFieldsTemplate: FieldsTemplate;
  /** Get group by id */
  getGroup: GroupView;
  /** Get some Infobip configuration values */
  getInfobipConfig: InfobipConfigView;
  /** Get keyword template by ID */
  getKeywordTemplate: KeywordTemplate;
  /** Find label by ID */
  getLabel: Label;
  /** Get label report */
  getLabelReport: Array<LabelReportView>;
  /** Get list by id */
  getList: ListView;
  /** Generate signed url for uploading list to storage */
  getListUploadSignedUrl: SignedUrl;
  /** Find macro by ID */
  getMacro: Macro;
  /** Get marketing popup by ID */
  getMarketingPopup: MarketingPopup;
  /** Generate signed url for uploading marketing popups images to Google Cloud Storage */
  getMarketingPopupSignedUrl: SignedUrl;
  /** Generate signed url for uploading message attachments */
  getMessageAttachmentSignedUrl: SignedUrl;
  /** Get message report */
  getMessageReport: MessageReport;
  /** Get message template by ID */
  getMessageTemplate: MessageTemplate;
  /** Generate signed url for uploading audio files */
  getMessageTemplateSignedUrl: SignedUrl;
  /** Get messaging profile */
  getMessagingProfile: MessagingProfile;
  /** Get notification settings */
  getNotificationSettings: NotificationSettings;
  /** Generate signed url for uploading opt-in workflow file */
  getOptInWorkflowFileSignedUrl: SignedUrl;
  /** Find own phone by id */
  getOwnPhone: OwnPhone;
  /** Get counters by own phone types */
  getOwnPhoneCounters: OwnPhoneCounters;
  /** Get plans */
  getPlans: Array<Plan>;
  /** Get skiptrace by id */
  getSkiptrace: Skiptrace;
  /** Get provider-related account statistics */
  getStatistics: MessagingProvidersCounters;
  /** Get toll-free registration info */
  getTollFreeRegistrationInfo: TollFreeRegistration;
  /** Get unread conversations counters */
  getUnreadConversationsCounters: UnreadConversationsCountersView;
  /** Get unread notification counter */
  getUnreadNotificationCounter: NotificationCounter;
  /** Generate signed url for uploading list of phone numbers to add them to DNC */
  getUploadDNCPhonesSignedUrl: SignedUrl;
  health: Health;
  /** Retrieve upcoming invoice */
  retrieveUpcomingInvoice: UpcomingInvoice;
  /** Get account subscriptions */
  subscriptions: Array<CurrentSubscription>;
  /** Suppressions summary */
  suppressionsSummary: SuppressionsSummary;
  version: Version;
};

export type QueryBuildMessageTemplatesArgs = {
  input: BuildMessageTemplateInput;
};

export type QueryDownloadBillingReportArgs = {
  filter?: InputMaybe<PaymentsFilter>;
};

export type QueryExportAccountsArgs = {
  filter?: InputMaybe<AccountsFilter>;
  password: Scalars['String']['input'];
};

export type QueryExportCampaignToFileArgs = {
  input: ExportCampaignToFileInput;
};

export type QueryExportCampaignToGroupArgs = {
  id: Scalars['String']['input'];
  input: ExportCampaignToGroupInput;
};

export type QueryExportConversationsToCrmIntegrationArgs = {
  input: ExportConversationsToCrmIntegrationInput;
};

export type QueryExportConversationsToFileArgs = {
  input: ExportConversationsToFileInput;
};

export type QueryExportDialerCampaignToFileArgs = {
  input: ExportDialerCampaignToFileInput;
};

export type QueryExportGroupArgs = {
  id: Scalars['String']['input'];
};

export type QueryExportListArgs = {
  input: ExportListInput;
};

export type QueryExportSkiptraceArgs = {
  input: ExportSkiptraceInput;
};

export type QueryExportSuppressionsArgs = {
  input: ExportSuppressionsInput;
};

export type QueryFindAccountsArgs = {
  filter?: InputMaybe<AccountsFilter>;
  order?: InputMaybe<Order>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindAffiliatesArgs = {
  filter?: InputMaybe<AffiliateFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindAvailablePhonesArgs = {
  filter: FindAvailablePhonesFilter;
};

export type QueryFindCalendarEventsArgs = {
  filter?: InputMaybe<CalendarEventsFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindCallScriptsArgs = {
  filter?: InputMaybe<CallScriptsFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindCampaignMessagesArgs = {
  filter?: InputMaybe<ScheduledMessageFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindCampaignsArgs = {
  filter?: InputMaybe<CampaignFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindClosestCalendarEventApplicationForRelationArgs = {
  relationId: Scalars['String']['input'];
};

export type QueryFindClosestCalendarEventForRelationArgs = {
  relationId: Scalars['String']['input'];
};

export type QueryFindContactNotesArgs = {
  filter: ContactNoteFilter;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindContactNotesApplicationForContactByFirestoreIdArgs = {
  firestoreId: Scalars['String']['input'];
};

export type QueryFindContactNotesForContactByFirestoreIdArgs = {
  filter: ContactNoteFilter;
  firestoreId: Scalars['String']['input'];
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindContactsArgs = {
  filter?: InputMaybe<ContactFilter>;
  order?: InputMaybe<ContactOrder>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindConversationMessagesArgs = {
  contactId: Scalars['String']['input'];
  filter?: InputMaybe<MessageFilter>;
  order?: InputMaybe<MessageOrder>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindConversationsArgs = {
  filter?: InputMaybe<ConversationFilter>;
  order?: InputMaybe<ConversationOrder>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindCrmIntegrationsArgs = {
  filter?: InputMaybe<CrmIntegrationFilter>;
  order?: InputMaybe<CrmIntegrationOrder>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindDialerCampaignsArgs = {
  filter?: InputMaybe<DialerCampaignFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindFieldsArgs = {
  filter?: InputMaybe<FieldsFilter>;
};

export type QueryFindFieldsTemplatesArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindGroupsArgs = {
  filter?: InputMaybe<GroupsFilter>;
  order?: InputMaybe<Order>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindKeywordTemplatesArgs = {
  filter?: InputMaybe<KeywordTemplatesFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindLabelsArgs = {
  filter?: InputMaybe<LabelFilter>;
  pagination?: InputMaybe<CursorPagination>;
};

export type QueryFindLabelsForFuckingFirestoreArgs = {
  filter?: InputMaybe<LabelFilter>;
  pagination?: InputMaybe<CursorPagination>;
};

export type QueryFindLastFeedbackArgs = {
  source: FeedbackSource;
};

export type QueryFindListItemsArgs = {
  listId: Scalars['String']['input'];
  pagination?: InputMaybe<ListItemsPagination>;
};

export type QueryFindMacrosArgs = {
  filter?: InputMaybe<MacrosFilter>;
  pagination?: InputMaybe<CursorPagination>;
};

export type QueryFindMarketingPopupsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindMessageTemplatesArgs = {
  filter?: InputMaybe<MessageTemplatesFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindMessageTemplatesForFuckingFirestoreArgs = {
  filter?: InputMaybe<MessageTemplatesFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindNotificationsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindOwnPhonesArgs = {
  filter?: InputMaybe<OwnPhoneFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindPaymentsArgs = {
  filter?: InputMaybe<PaymentsFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindRefundedBonusPaymentsArgs = {
  filter?: InputMaybe<PaymentsFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindScheduledMessagesArgs = {
  filter?: InputMaybe<ScheduledMessageFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindSkiptracesArgs = {
  order?: InputMaybe<Order>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindSuppressionsArgs = {
  filter?: InputMaybe<SuppressionsFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryGeneratePowerDialerWebRtcTokenArgs = {
  devicePlatform?: InputMaybe<DevicePlatform>;
};

export type QueryGenerateWebRtcTokenArgs = {
  devicePlatform?: InputMaybe<DevicePlatform>;
};

export type QueryGetA2PRegistrationInfoArgs = {
  accountId: Scalars['String']['input'];
};

export type QueryGetAccountArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetAffiliateArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetCalendarEventArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetCallScriptArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetCampaignArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetCampaignReportArgs = {
  filter: ReportsFilter;
};

export type QueryGetCampaignScheduleArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<CampaignScheduleOptionsInput>;
};

export type QueryGetContactArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetContactNoteSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};

export type QueryGetContactReportArgs = {
  filter: ReportsFilter;
};

export type QueryGetConversationArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetCrmIntegrationArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetCurrentCallArgs = {
  contactId: Scalars['String']['input'];
};

export type QueryGetDialerCallOutcomeReportArgs = {
  filter?: InputMaybe<DialerReportsFilter>;
};

export type QueryGetDialerCallReportArgs = {
  filter?: InputMaybe<DialerReportsFilter>;
};

export type QueryGetDialerCampaignArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetDialerContactReportArgs = {
  filter?: InputMaybe<DialerReportsFilter>;
};

export type QueryGetEinFileUploadSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};

export type QueryGetFieldsTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetGroupArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetKeywordTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetLabelArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetLabelReportArgs = {
  filter: ReportsFilter;
};

export type QueryGetListArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetListUploadSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};

export type QueryGetMacroArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetMarketingPopupArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetMarketingPopupSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};

export type QueryGetMessageAttachmentSignedUrlArgs = {
  input: GetMessageAttachmentSignedUrlInput;
};

export type QueryGetMessageReportArgs = {
  filter: ReportsFilter;
};

export type QueryGetMessageTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetMessageTemplateSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};

export type QueryGetOptInWorkflowFileSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};

export type QueryGetOwnPhoneArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetOwnPhoneCountersArgs = {
  scope?: InputMaybe<Scope>;
};

export type QueryGetSkiptraceArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetTollFreeRegistrationInfoArgs = {
  accountId: Scalars['String']['input'];
};

export type QueryGetUnreadConversationsCountersArgs = {
  profileId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetUploadDncPhonesSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};

export type QueryRetrieveUpcomingInvoiceArgs = {
  products: Array<Product>;
  subAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryDateFilter = {
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Quota type */
export enum QuotaType {
  CALL = 'CALL',
  CAMPAIGN_SMS = 'CAMPAIGN_SMS',
  CONTACTS = 'CONTACTS',
  DIALER_CALL = 'DIALER_CALL',
  DIALER_CONTACTS = 'DIALER_CONTACTS',
  DIALER_SUB_ACCOUNTS = 'DIALER_SUB_ACCOUNTS',
  LOCAL_PHONE = 'LOCAL_PHONE',
  LOOKUP = 'LOOKUP',
  OWN_PHONE = 'OWN_PHONE',
  RVM = 'RVM',
  SKIPTRACE = 'SKIPTRACE',
  SMS = 'SMS',
  SMS_ALERT = 'SMS_ALERT',
  SUB_ACCOUNTS = 'SUB_ACCOUNTS',
  TEMPLATES = 'TEMPLATES',
  TEMPLATES_AI = 'TEMPLATES_AI',
  TOLL_FREE_PHONE = 'TOLL_FREE_PHONE',
  TRIAL_PHONE = 'TRIAL_PHONE',
}

/** Quota Usage */
export type QuotaUsage = {
  __typename?: 'QuotaUsage';
  type: QuotaType;
  used: Scalars['Int']['output'];
};

export type RankCallScriptInput = {
  afterId?: InputMaybe<Scalars['String']['input']>;
  beforeId?: InputMaybe<Scalars['String']['input']>;
};

export type RankKeywordTemplateInput = {
  afterId?: InputMaybe<Scalars['String']['input']>;
  beforeId?: InputMaybe<Scalars['String']['input']>;
};

export type RankLabelInput = {
  afterId?: InputMaybe<Scalars['String']['input']>;
  beforeId?: InputMaybe<Scalars['String']['input']>;
};

export type RankMacroInput = {
  afterId?: InputMaybe<Scalars['String']['input']>;
  beforeId?: InputMaybe<Scalars['String']['input']>;
};

export type RankMessageTemplateInput = {
  afterId?: InputMaybe<Scalars['String']['input']>;
  beforeId?: InputMaybe<Scalars['String']['input']>;
};

/** Rank result view */
export type RankResult = {
  __typename?: 'RankResult';
  afterId?: Maybe<Scalars['String']['output']>;
  beforeId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

/** Real Estate Attribute */
export enum RealEstateAttribute {
  AC = 'AC',
  BATH = 'BATH',
  BED = 'BED',
  GARAGE = 'GARAGE',
  LAST_SOLD_DATE = 'LAST_SOLD_DATE',
  LOT_SIZE = 'LOT_SIZE',
  OCCUPANCY = 'OCCUPANCY',
  OFFER = 'OFFER',
  OWES = 'OWES',
  POOL = 'POOL',
  PROPERTY_TYPE = 'PROPERTY_TYPE',
  RENT = 'RENT',
  REPAIRS = 'REPAIRS',
  ROOF = 'ROOF',
  SQ_FT = 'SQ_FT',
  TAX_ASSESSMENT = 'TAX_ASSESSMENT',
  YEAR_BUILT = 'YEAR_BUILT',
  ZILLOW_ESTIMATE = 'ZILLOW_ESTIMATE',
}

export type RecoverContactsInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  subAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterAffiliateInput = {
  referralId: Scalars['String']['input'];
};

export type RemoveCampaignInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RemoveContactFromDncSuppressionsInput = {
  contactId: Scalars['String']['input'];
};

export type RemoveContactsInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  subAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveDialerCampaignInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RemoveGroupFromContactsInput = {
  groupId: Scalars['String']['input'];
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RemoveGroupsInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RemoveLabelsFromContactsInput = {
  ids: Array<Scalars['String']['input']>;
  labelIds: Array<Scalars['String']['input']>;
};

export type RemoveSkiptracesInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RemoveSuppressionsInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  source: SuppressionSource;
};

export type ReportsFilter = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  endAt: Scalars['DateTime']['input'];
  profileId?: InputMaybe<Scalars['String']['input']>;
  startAt: Scalars['DateTime']['input'];
  templateId?: InputMaybe<Scalars['String']['input']>;
};

/** Response removal */
export type ResponseRemoval = {
  __typename?: 'ResponseRemoval';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  responseFilters?: Maybe<Array<Scalars['String']['output']>>;
};

export type ResponseRemovalInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  responseFilters?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RunSkiptraceInput = {
  excludeFirstRow?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  useCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ScheduleBalanceInput = {
  amount: Scalars['Int']['input'];
  enabled: Scalars['Boolean']['input'];
  limit: Scalars['Int']['input'];
};

export type ScheduleCheckMonthsOfMembershipTaskInput = {
  profileIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ScheduleOptionsInput = {
  frequency: Frequency;
  hasCustomBatchSize?: InputMaybe<Scalars['Boolean']['input']>;
  hasCustomStartDate?: InputMaybe<Scalars['Boolean']['input']>;
  interval: Scalars['Int']['input'];
  perBatch: Scalars['Int']['input'];
  startAt: Scalars['DateTime']['input'];
};

/** ScheduledMessage */
export type ScheduledMessage = {
  __typename?: 'ScheduledMessage';
  attachments?: Maybe<Array<MessageAttachment>>;
  contact?: Maybe<Contact>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  template?: Maybe<MessageTemplate>;
};

export type ScheduledMessageFilter = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  contactId?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CampaignMessageStatus>;
  step?: InputMaybe<Scalars['Int']['input']>;
};

export type ScheduledMessageList = {
  __typename?: 'ScheduledMessageList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<ScheduledMessage>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Application Scope */
export enum Scope {
  DIALER = 'DIALER',
  MESSAGE = 'MESSAGE',
}

export type SendMessageInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<Array<MessageAttachmentInput>>;
  contactId: Scalars['String']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  fromNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SequenceOptions = {
  days?: InputMaybe<Array<Scalars['Int']['input']>>;
  delay?: InputMaybe<Scalars['Float']['input']>;
  hasCustomTimeRestrictions?: InputMaybe<Scalars['Boolean']['input']>;
  timeRange?: InputMaybe<TimeRangeInput>;
};

/** Sequence progress */
export type SequenceProgress = {
  __typename?: 'SequenceProgress';
  sent: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Sequence rule */
export type SequenceRule = {
  __typename?: 'SequenceRule';
  groupId?: Maybe<Scalars['String']['output']>;
  labelId?: Maybe<Scalars['String']['output']>;
  removeFromNextSequences?: Maybe<Scalars['Boolean']['output']>;
  strategy?: Maybe<CampaignRuleStrategy>;
};

/** Sequence type */
export enum SequenceType {
  RVM = 'RVM',
  SMS = 'SMS',
}

/** Sequence view */
export type SequenceView = {
  __typename?: 'SequenceView';
  content?: Maybe<Scalars['String']['output']>;
  days: Array<Scalars['Int']['output']>;
  delay: Scalars['Float']['output'];
  hasCustomTimeRestrictions: Scalars['Boolean']['output'];
  progress?: Maybe<SequenceProgress>;
  rule?: Maybe<SequenceRule>;
  templates: Array<Scalars['String']['output']>;
  timeRange: TimeRange;
  type: SequenceType;
};

/** Session */
export type Session = {
  __typename?: 'Session';
  online: Scalars['Boolean']['output'];
};

export type SignUpInput = {
  accountType?: InputMaybe<AccountType>;
  analytics?: InputMaybe<AccountAnalyticsInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['PhoneNumberType']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  questionnaire: AccountQuestionnaireInput;
  referralId?: InputMaybe<Scalars['String']['input']>;
  timeZone: Scalars['String']['input'];
};

/** Signed URL */
export type SignedUrl = {
  __typename?: 'SignedUrl';
  filePath: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

/** Signed url action */
export enum SignedUrlAction {
  DELETE = 'DELETE',
  READ = 'READ',
  RESUMABLE = 'RESUMABLE',
  WRITE = 'WRITE',
}

/** Skiptrace */
export type Skiptrace = {
  __typename?: 'Skiptrace';
  cachedContacts?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  excludeFirstRow?: Maybe<Scalars['Boolean']['output']>;
  fields?: Maybe<Array<SkiptraceField>>;
  fileName: Scalars['String']['output'];
  foundContacts?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  landlineContacts?: Maybe<Scalars['Int']['output']>;
  mobileContacts?: Maybe<Scalars['Int']['output']>;
  processedContacts?: Maybe<Scalars['Int']['output']>;
};

/** Skiptrace completed */
export type SkiptraceCompleted = {
  __typename?: 'SkiptraceCompleted';
  skiptraceId: Scalars['String']['output'];
};

/** Skiptrace field */
export type SkiptraceField = {
  __typename?: 'SkiptraceField';
  id: Scalars['String']['output'];
  key?: Maybe<SkiptracePredefinedField>;
  name: Scalars['String']['output'];
  predefined: Scalars['Boolean']['output'];
};

/** List of skiptraces */
export type SkiptraceList = {
  __typename?: 'SkiptraceList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Skiptrace>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Skiptrace predefined field */
export enum SkiptracePredefinedField {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  MAILING_ADDRESS = 'MAILING_ADDRESS',
  MAILING_CITY = 'MAILING_CITY',
  MAILING_STATE = 'MAILING_STATE',
  MAILING_ZIP = 'MAILING_ZIP',
  PHONE = 'PHONE',
  PHONE_TYPE = 'PHONE_TYPE',
  PROPERTY_ADDRESS = 'PROPERTY_ADDRESS',
  PROPERTY_CITY = 'PROPERTY_CITY',
  PROPERTY_STATE = 'PROPERTY_STATE',
  PROPERTY_ZIP = 'PROPERTY_ZIP',
}

/** Skiptrace progress */
export type SkiptraceProgress = {
  __typename?: 'SkiptraceProgress';
  progress: Scalars['Float']['output'];
  skiptraceId: Scalars['String']['output'];
};

export type StartPowerDialingInput = {
  applicationCallId: Scalars['String']['input'];
  dialerCampaignId: Scalars['String']['input'];
};

/** State reason */
export type StateReason = {
  __typename?: 'StateReason';
  ttl?: Maybe<Scalars['DateTime']['output']>;
  type: CampaignStateReasonType;
};

export type SubmitA2PRegistrationInput = {
  brand: A2PBrandInput;
  campaign: A2PBrandCampaignInput;
};

export type SubmitA2PSubAccountRegistrationInput = {
  campaign: A2PBrandCampaignInput;
};

export type SubscribeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Account updated event */
  accountUpdated: ChangesItemView;
  /** Change on the progress of the campaign's sequences */
  campaignProgressUpdated: CampaignProgressUpdated;
  /** Campaign upserted event */
  campaignUpserted: ChangesItemView;
  /** Contacts changes */
  contactsChanges: ChangesView;
  /** Conversation created event */
  conversationCreated: ChangesItemView;
  /** Conversation updated event */
  conversationUpdated: ChangesItemView;
  /** Message received event */
  incomingMessageReceived: Message;
  /** List upload completed event */
  listUploadCompleted: ListUploadCompleted;
  /** List upload progress updated event */
  listUploadProgress: ListUploadProgress;
  /** List verification completed event */
  listVerificationCompleted: ListVerificationResult;
  /** List verification progress updated event */
  listVerificationProgress: ListVerificationProgress;
  /** Message created event */
  messageCreated: ChangesItemView;
  /** Message updated event */
  messageUpdated: ChangesItemView;
  /** Missed conversations calls counter updated event */
  missedConversationsCallsCounterUpdated: UnreadConversationsCounterUpdatedView;
  /** Notification created event */
  notificationCreated: Notification;
  /** Notification counter updated event */
  notificationUnreadCounterUpdated: NotificationCounter;
  /** Notifies about restricted actions outside business hours */
  outsideBusinessHoursRestrictions: AccountOutsideBusinessHoursRestrictionsView;
  /** Indicates an error during power dialer execution */
  powerDialerFailed: PowerDialerFailedView;
  /** Latest power dialer state to display for a given campaign */
  powerDialerStateUpdated: PowerDialerStateUpdatedView;
  /** Session updated event */
  sessionUpdated: Session;
  /** Skiptrace completed event */
  skiptraceCompleted: SkiptraceCompleted;
  /** Skiptrace progress updated event */
  skiptraceProgress: SkiptraceProgress;
  /** Unread conversations messages counter updated event */
  unreadConversationsMessagesCounterUpdated: UnreadConversationsCounterUpdatedView;
};

export type SubscriptionMessageCreatedArgs = {
  contactId: Scalars['String']['input'];
};

export type SubscriptionMessageUpdatedArgs = {
  contactId: Scalars['String']['input'];
};

export type SubscriptionMissedConversationsCallsCounterUpdatedArgs = {
  profileId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionUnreadConversationsMessagesCounterUpdatedArgs = {
  profileId?: InputMaybe<Scalars['String']['input']>;
};

/** Subscription status */
export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  INCOMPLETE = 'INCOMPLETE',
  PAST_DUE = 'PAST_DUE',
  TRIALING = 'TRIALING',
}

/** Subscription summary */
export type SubscriptionSummary = {
  __typename?: 'SubscriptionSummary';
  total?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};

/** Subscriptions summary */
export type SubscriptionsSummary = {
  __typename?: 'SubscriptionsSummary';
  dialer: Array<SubscriptionSummary>;
  elite: Array<SubscriptionSummary>;
  inactiveDialer: Array<InactiveAccountSummary>;
  inactiveMessaging: Array<InactiveAccountSummary>;
  messaging: Array<SubscriptionSummary>;
};

/** Suppression phone */
export type Suppression = {
  __typename?: 'Suppression';
  createdAt: Scalars['DateTime']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  source: SuppressionSource;
};

export type SuppressionList = {
  __typename?: 'SuppressionList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Suppression>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Suppression source */
export enum SuppressionSource {
  CARRIER_BLOCKED = 'CARRIER_BLOCKED',
  DNC = 'DNC',
  FILTERED = 'FILTERED',
  UNDELIVERABLE = 'UNDELIVERABLE',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}

export type SuppressionsFilter = {
  source?: InputMaybe<SuppressionSource>;
};

/** Suppressions summary */
export type SuppressionsSummary = {
  __typename?: 'SuppressionsSummary';
  carrierBlocked?: Maybe<Scalars['Int']['output']>;
  dnc?: Maybe<Scalars['Int']['output']>;
  filtered?: Maybe<Scalars['Int']['output']>;
  undeliverable?: Maybe<Scalars['Int']['output']>;
  unsubscribed?: Maybe<Scalars['Int']['output']>;
};

export type SyncHubspotProfilesInput = {
  fields?: InputMaybe<Array<HubSpotProfileField>>;
};

/** Time range */
export type TimeRange = {
  __typename?: 'TimeRange';
  from: HourMinuteView;
  to: HourMinuteView;
};

export type TimeRangeInput = {
  from: HourMinuteInput;
  to: HourMinuteInput;
};

/** Toll-free brand */
export type TollFreeBrand = {
  __typename?: 'TollFreeBrand';
  address1: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  country: Scalars['String']['output'];
  description: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  messageVolume: Scalars['String']['output'];
  optInWorkflow: Scalars['String']['output'];
  optInWorkflowFiles: Array<BrandRegistrationFile>;
  phone: Scalars['String']['output'];
  sampleMessage: Scalars['String']['output'];
  state: Scalars['String']['output'];
  usecase: TollFreeBrandUseCase;
  website: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type TollFreeBrandInput = {
  address1: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  country: Scalars['String']['input'];
  description: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  messageVolume: Scalars['String']['input'];
  optInWorkflow: Scalars['String']['input'];
  optInWorkflowFiles: Array<OptInWorkflowFileInput>;
  phone: Scalars['String']['input'];
  sampleMessage: Scalars['String']['input'];
  state: Scalars['String']['input'];
  usecase: TollFreeBrandUseCase;
  website: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

/** Toll-free brand use case */
export enum TollFreeBrandUseCase {
  APPOINTMENTS = 'APPOINTMENTS',
  APP_NOTIFICATIONS = 'APP_NOTIFICATIONS',
  AUCTIONS = 'AUCTIONS',
  AUTO_REPAIR_SERVICE = 'AUTO_REPAIR_SERVICE',
  BANK_TRANSFERS = 'BANK_TRANSFERS',
  BILLING = 'BILLING',
  BOOKING_CONFIRMATIONS = 'BOOKING_CONFIRMATIONS',
  BUSINESS_UPDATES = 'BUSINESS_UPDATES',
  CAREER_TRAINING = 'CAREER_TRAINING',
  CHATBOT = 'CHATBOT',
  CONVERSATIONAL_OR_ALERTS = 'CONVERSATIONAL_OR_ALERTS',
  COURIER_SERVICCESE_AND_DELIVERIES = 'COURIER_SERVICCESE_AND_DELIVERIES',
  COVID_19_ALERTS = 'COVID_19_ALERTS',
  EMERGENCY_ALERTS = 'EMERGENCY_ALERTS',
  EVENTS_AND_PLANING = 'EVENTS_AND_PLANING',
  FINANCIAL_SERVICES = 'FINANCIAL_SERVICES',
  FRAUD_ALERTS = 'FRAUD_ALERTS',
  FUNDRAISING = 'FUNDRAISING',
  GENERAL_MARKETING = 'GENERAL_MARKETING',
  GENERAL_SCHOOL_UPDATES = 'GENERAL_SCHOOL_UPDATES',
  HEALTHCARE_ALERTS = 'HEALTHCARE_ALERTS',
  HOUSING_COMMUNITY_UPDATES = 'HOUSING_COMMUNITY_UPDATES',
  HR_OR_STAFFING = 'HR_OR_STAFFING',
  INSURANCE_SERVICES = 'INSURANCE_SERVICES',
  JOB_DISPATCH = 'JOB_DISPATCH',
  LEGAL_SERVICES = 'LEGAL_SERVICES',
  MIXED = 'MIXED',
  MOTIVATIONAL_REMINDERS = 'MOTIVATIONAL_REMINDERS',
  NOTARY_NOTIFICATIONS = 'NOTARY_NOTIFICATIONS',
  ORDER_NOTIFICATIONS = 'ORDER_NOTIFICATIONS',
  POLITICAL = 'POLITICAL',
  PUBLIC_WORKS = 'PUBLIC_WORKS',
  REAL_ESTATE_SERVICES = 'REAL_ESTATE_SERVICES',
  RELIGIOUS_SERVICES = 'RELIGIOUS_SERVICES',
  REPAIR_AND_DIAGNOSTICS_ALERTS = 'REPAIR_AND_DIAGNOSTICS_ALERTS',
  REWARDS_PROGRAM = 'REWARDS_PROGRAM',
  SURVEYS = 'SURVEYS',
  SYSTEM_ALERTS = 'SYSTEM_ALERTS',
  TWO_FACTOR_AUTH = 'TWO_FACTOR_AUTH',
  VOTING_REMINDERS = 'VOTING_REMINDERS',
  WAITLIST_ALERTS = 'WAITLIST_ALERTS',
  WEBINAR_REMINDERS = 'WEBINAR_REMINDERS',
  WORKSHOP_ALERTS = 'WORKSHOP_ALERTS',
}

/** Toll-Free brand registration general info */
export type TollFreeInfo = {
  __typename?: 'TollFreeInfo';
  limits: TollFreeInfoLimits;
  maxTpm: Scalars['Float']['output'];
  status: TollFreeInfoStatus;
};

/** Toll-Free brand registration limits */
export type TollFreeInfoLimits = {
  __typename?: 'TollFreeInfoLimits';
  daily: Scalars['Float']['output'];
  monthly: Scalars['Float']['output'];
  weekly: Scalars['Float']['output'];
};

/** Toll-brand registration general status */
export enum TollFreeInfoStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  NONE = 'NONE',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
}

/** Toll-free registration all info */
export type TollFreeRegistration = {
  __typename?: 'TollFreeRegistration';
  brand: TollFreeBrand;
  ownPhone?: Maybe<OwnPhone>;
};

export type TopUpAccountBalanceInput = {
  accountId: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
};

export type TopUpBalanceInput = {
  amount: Scalars['Int']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
};

/** Unread conversation counter for GQL subscription. It might contain unread messages or missed calls depending on specific subscription */
export type UnreadConversationsCounterUpdatedView = {
  __typename?: 'UnreadConversationsCounterUpdatedView';
  counter: Scalars['Float']['output'];
  profileId: Scalars['String']['output'];
};

/** Unread conversation counters */
export type UnreadConversationsCountersView = {
  __typename?: 'UnreadConversationsCountersView';
  withMissedCalls?: Maybe<Scalars['Float']['output']>;
  withUnreadMessages?: Maybe<Scalars['Float']['output']>;
};

/** Upcoming invoice */
export type UpcomingInvoice = {
  __typename?: 'UpcomingInvoice';
  totalBilledNow: Scalars['Money']['output'];
};

export type UpdateA2PBrandCampaignInput = {
  description: Scalars['String']['input'];
  messageFlow: Scalars['String']['input'];
  sample1: Scalars['String']['input'];
  sample2: Scalars['String']['input'];
  sendLinks: Scalars['Boolean']['input'];
  sendPhones: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  usecase: A2PBrandCampaignUseCase;
};

export type UpdateA2PRegistrationExternalDataInput = {
  brandId?: InputMaybe<Scalars['String']['input']>;
  campaignId: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type UpdateA2PRegistrationInfoInput = {
  brand?: InputMaybe<A2PBrandInput>;
  campaign?: InputMaybe<UpdateA2PBrandCampaignInput>;
};

export type UpdateAccountInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  defaultLabelColor?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  openNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  refreshRate?: InputMaybe<Scalars['Int']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  viewedPopup?: InputMaybe<AccountSettingsPopup>;
};

export type UpdateAccountSubscriptionInput = {
  /** Plan ID that should be updated */
  planId?: InputMaybe<Scalars['String']['input']>;
  /** Subscription status */
  status?: InputMaybe<SubscriptionStatus>;
  /** Subscription ID */
  subscriptionId: Scalars['String']['input'];
};

export type UpdateCalendarEventInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  relationId?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCallScriptInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCampaignInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownPhones: Array<Scalars['String']['input']>;
  rule?: InputMaybe<CampaignRuleInput>;
  scheduleOptions?: InputMaybe<ScheduleOptionsInput>;
  sendFirstMessage?: InputMaybe<Scalars['Boolean']['input']>;
  sequences?: InputMaybe<Array<CampaignSequenceInput>>;
};

export type UpdateCampaignOwnPhonesInput = {
  ownPhones: Array<Scalars['String']['input']>;
};

export type UpdateContactInput = {
  attrs?: InputMaybe<Array<ContactAttributesInput>>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  dealClosed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  favorite?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  realEstateAttrs?: InputMaybe<Array<ContactRealEstateAttributesInput>>;
};

export type UpdateContactNoteInput = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateConversationOutgoingPhoneInput = {
  id: Scalars['String']['input'];
  outgoingPhone: Scalars['String']['input'];
};

export type UpdateCrmIntegrationInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<CrmProviderInput>;
  status?: InputMaybe<CrmIntegrationStatus>;
};

export type UpdateDeviceInput = {
  appVersion: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  platform: DevicePlatform;
  token: Scalars['String']['input'];
};

export type UpdateDialerCampaignInput = {
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  mode?: InputMaybe<CallMode>;
  name: Scalars['String']['input'];
  phones?: InputMaybe<Array<Scalars['String']['input']>>;
  scriptId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFieldInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFieldsTemplateInput = {
  fields: Array<InputMaybe<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupInput = {
  name: Scalars['String']['input'];
};

export type UpdateKeywordTemplateInput = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<KeywordTemplateTemplateInput>;
};

export type UpdateLabelInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateListInput = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  options?: InputMaybe<UpdateListOptionsInput>;
};

export type UpdateListItemInput = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateListOptionsInput = {
  removeCarrierBlocked: Scalars['Boolean']['input'];
  removeExisting: Scalars['Boolean']['input'];
  removeFiltered: Scalars['Boolean']['input'];
  removeGlobalUnsubscribed: Scalars['Boolean']['input'];
  removeInternalDnc: Scalars['Boolean']['input'];
  removeLitigators: Scalars['Boolean']['input'];
  removeNationalDnc: Scalars['Boolean']['input'];
  removeNonSmsCapable: Scalars['Boolean']['input'];
  removeUndeliverable: Scalars['Boolean']['input'];
  removeUnsubscribed: Scalars['Boolean']['input'];
};

export type UpdateMacroInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMarketingPopupInput = {
  audiences?: InputMaybe<Array<MarketingPopupAudience>>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<MarketingPopupStatus>;
  template?: InputMaybe<MarketingPopupTemplateInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMessageTemplateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<MessageTemplateTemplateInput>;
};

export type UpdateMessagingProfileInput = {
  callSettings?: InputMaybe<CallSettingsInput>;
  phoneSettings?: InputMaybe<PhoneSettingsInput>;
  responseRemoval?: InputMaybe<ResponseRemovalInput>;
};

export type UpdateNotificationSettingsInput = {
  enabledCalendar?: InputMaybe<Scalars['Boolean']['input']>;
  enabledCampaigns?: InputMaybe<Scalars['Boolean']['input']>;
  enabledPayments?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateScheduledMessageInput = {
  templateId: Scalars['String']['input'];
};

export type UpdateSkiptraceInput = {
  excludeFirstRow: Scalars['Boolean']['input'];
  fileName: Scalars['String']['input'];
};

export type UpdateSubaccountInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  products?: InputMaybe<Array<Product>>;
  status: AccountStatus;
};

export type UpdateSubscriptionInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['String']['input'];
};

export type UpdateTollFreeRegistrationInfoInput = {
  brand: TollFreeBrandInput;
};

export type UploadDncPhonesInput = {
  filePath: Scalars['String']['input'];
};

export type UploadEinFileInput = {
  contentType: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
};

export type VerifyPromoCodeInput = {
  code: Scalars['String']['input'];
  planId: Scalars['String']['input'];
};

/** Version */
export type Version = {
  __typename?: 'Version';
  version: Scalars['String']['output'];
};

/** WebRTC Token for web client */
export type WebRtcToken = {
  __typename?: 'WebRTCToken';
  expiresAt: Scalars['DateTime']['output'];
  token: Scalars['String']['output'];
};

export type A2PBrandFragment = {
  __typename?: 'A2PBrand';
  companyName: string;
  vertical: A2PBrandVertical;
  ein: string;
  einIssuingCountry: string;
  country: string;
  state: string;
  city: string;
  street: string;
  zip: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  website?: string | null;
  createWebsite: boolean;
  customerCarePhone?: string | null;
  customerCareEmail?: string | null;
  termsAndConditionsUrl?: string | null;
  incorporationDate?: string | null;
  einFile?: {
    __typename?: 'BrandRegistrationFile';
    filePath: string;
    contentType: string;
    url: string;
  } | null;
};

export type A2PBrandCampaignFragment = {
  __typename?: 'A2PBrandCampaign';
  title: string;
  description: string;
  usecase: A2PBrandCampaignUseCase;
  sample1: string;
  sample2: string;
  messageFlow: string;
  sendPhones: boolean;
  sendLinks: boolean;
  sendDirectLending: boolean;
  sendAgeGatedContent: boolean;
  sendAffiliateMarketing: boolean;
  sendOptIn: boolean;
  optInKeywords: Array<string>;
  optInMessage: string;
  sendOptOut: boolean;
  optOutKeywords: Array<string>;
  optOutMessage: string;
  sendHelp: boolean;
  helpKeywords: Array<string>;
  helpMessage: string;
};

export type GetA2PRegistrationInfoQueryVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type GetA2PRegistrationInfoQuery = {
  __typename?: 'Query';
  getA2PRegistrationInfo: {
    __typename?: 'A2PRegistration';
    brand: {
      __typename?: 'A2PBrand';
      companyName: string;
      vertical: A2PBrandVertical;
      ein: string;
      einIssuingCountry: string;
      country: string;
      state: string;
      city: string;
      street: string;
      zip: string;
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      website?: string | null;
      createWebsite: boolean;
      customerCarePhone?: string | null;
      customerCareEmail?: string | null;
      termsAndConditionsUrl?: string | null;
      incorporationDate?: string | null;
      einFile?: {
        __typename?: 'BrandRegistrationFile';
        filePath: string;
        contentType: string;
        url: string;
      } | null;
    };
    campaign: {
      __typename?: 'A2PBrandCampaign';
      title: string;
      description: string;
      usecase: A2PBrandCampaignUseCase;
      sample1: string;
      sample2: string;
      messageFlow: string;
      sendPhones: boolean;
      sendLinks: boolean;
      sendDirectLending: boolean;
      sendAgeGatedContent: boolean;
      sendAffiliateMarketing: boolean;
      sendOptIn: boolean;
      optInKeywords: Array<string>;
      optInMessage: string;
      sendOptOut: boolean;
      optOutKeywords: Array<string>;
      optOutMessage: string;
      sendHelp: boolean;
      helpKeywords: Array<string>;
      helpMessage: string;
    };
    ownPhone?: {
      __typename?: 'OwnPhone';
      id: string;
      status: OwnPhoneStatus;
      type: OwnPhoneType;
      number: string;
      scope: Scope;
      isTrial: boolean;
      createdAt: string;
      canBeRemoved: boolean;
      callingEnabled: boolean;
      messagingEnabled: boolean;
      location?: {
        __typename?: 'PhoneNumberLocation';
        abbreviation: string;
        state: string;
      } | null;
    } | null;
  };
};

export type UpdateA2PRegistrationInfoMutationVariables = Exact<{
  input: UpdateA2PRegistrationInfoInput;
  accountId: Scalars['String']['input'];
}>;

export type UpdateA2PRegistrationInfoMutation = {
  __typename?: 'Mutation';
  updateA2PRegistrationInfo: {
    __typename?: 'A2PRegistration';
    brand: {
      __typename?: 'A2PBrand';
      companyName: string;
      vertical: A2PBrandVertical;
      ein: string;
      einIssuingCountry: string;
      country: string;
      state: string;
      city: string;
      street: string;
      zip: string;
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      website?: string | null;
      createWebsite: boolean;
      customerCarePhone?: string | null;
      customerCareEmail?: string | null;
      termsAndConditionsUrl?: string | null;
      incorporationDate?: string | null;
      einFile?: {
        __typename?: 'BrandRegistrationFile';
        filePath: string;
        contentType: string;
        url: string;
      } | null;
    };
    campaign: {
      __typename?: 'A2PBrandCampaign';
      title: string;
      description: string;
      usecase: A2PBrandCampaignUseCase;
      sample1: string;
      sample2: string;
      messageFlow: string;
      sendPhones: boolean;
      sendLinks: boolean;
      sendDirectLending: boolean;
      sendAgeGatedContent: boolean;
      sendAffiliateMarketing: boolean;
      sendOptIn: boolean;
      optInKeywords: Array<string>;
      optInMessage: string;
      sendOptOut: boolean;
      optOutKeywords: Array<string>;
      optOutMessage: string;
      sendHelp: boolean;
      helpKeywords: Array<string>;
      helpMessage: string;
    };
    ownPhone?: {
      __typename?: 'OwnPhone';
      id: string;
      status: OwnPhoneStatus;
      type: OwnPhoneType;
      number: string;
      scope: Scope;
      isTrial: boolean;
      createdAt: string;
      canBeRemoved: boolean;
      callingEnabled: boolean;
      messagingEnabled: boolean;
      location?: {
        __typename?: 'PhoneNumberLocation';
        abbreviation: string;
        state: string;
      } | null;
    } | null;
  };
};

export type RegisterA2PMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type RegisterA2PMutation = {
  __typename?: 'Mutation';
  registerA2P: { __typename?: 'OperationStatus'; success: boolean };
};

export type RegisterSubAccountA2PMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type RegisterSubAccountA2PMutation = {
  __typename?: 'Mutation';
  registerSubAccountA2P: { __typename?: 'OperationStatus'; success: boolean };
};

export type ReactivateA2PMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type ReactivateA2PMutation = {
  __typename?: 'Mutation';
  reactivateA2P: { __typename?: 'OperationStatus'; success: boolean };
};

export type GetA2PReactivationInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetA2PReactivationInfoQuery = {
  __typename?: 'Query';
  getA2PReactivationInfo: {
    __typename?: 'A2PRegistration';
    brand: {
      __typename?: 'A2PBrand';
      companyName: string;
      vertical: A2PBrandVertical;
      ein: string;
      einIssuingCountry: string;
      country: string;
      state: string;
      city: string;
      street: string;
      zip: string;
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      website?: string | null;
      createWebsite: boolean;
      customerCarePhone?: string | null;
      customerCareEmail?: string | null;
      termsAndConditionsUrl?: string | null;
      incorporationDate?: string | null;
      einFile?: {
        __typename?: 'BrandRegistrationFile';
        filePath: string;
        contentType: string;
        url: string;
      } | null;
    };
    campaign: {
      __typename?: 'A2PBrandCampaign';
      title: string;
      description: string;
      usecase: A2PBrandCampaignUseCase;
      sample1: string;
      sample2: string;
      messageFlow: string;
      sendPhones: boolean;
      sendLinks: boolean;
      sendDirectLending: boolean;
      sendAgeGatedContent: boolean;
      sendAffiliateMarketing: boolean;
      sendOptIn: boolean;
      optInKeywords: Array<string>;
      optInMessage: string;
      sendOptOut: boolean;
      optOutKeywords: Array<string>;
      optOutMessage: string;
      sendHelp: boolean;
      helpKeywords: Array<string>;
      helpMessage: string;
    };
    ownPhone?: {
      __typename?: 'OwnPhone';
      id: string;
      status: OwnPhoneStatus;
      type: OwnPhoneType;
      number: string;
      scope: Scope;
      isTrial: boolean;
      createdAt: string;
      canBeRemoved: boolean;
      callingEnabled: boolean;
      messagingEnabled: boolean;
      location?: {
        __typename?: 'PhoneNumberLocation';
        abbreviation: string;
        state: string;
      } | null;
    } | null;
  };
};

export type GetEinFileUploadSignedUrlQueryVariables = Exact<{
  input: GenerateSignedUrlInput;
}>;

export type GetEinFileUploadSignedUrlQuery = {
  __typename?: 'Query';
  getEINFileUploadSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type SubmitA2PReactivationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SubmitA2PReactivationMutation = {
  __typename?: 'Mutation';
  submitA2PReactivation: { __typename?: 'OperationStatus'; success: boolean };
};

export type SubmitA2PRegistrationMutationVariables = Exact<{
  input: SubmitA2PRegistrationInput;
}>;

export type SubmitA2PRegistrationMutation = {
  __typename?: 'Mutation';
  submitA2PRegistration: { __typename?: 'OperationStatus'; success: boolean };
};

export type SubmitA2PSubAccountRegistrationMutationVariables = Exact<{
  input: SubmitA2PSubAccountRegistrationInput;
}>;

export type SubmitA2PSubAccountRegistrationMutation = {
  __typename?: 'Mutation';
  submitA2PSubAccountRegistration: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type GetOptInWorkflowFileSignedUrlQueryVariables = Exact<{
  input: GenerateSignedUrlInput;
}>;

export type GetOptInWorkflowFileSignedUrlQuery = {
  __typename?: 'Query';
  getOptInWorkflowFileSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type SubmitTollFreeRegistrationMutationVariables = Exact<{
  input: TollFreeBrandInput;
}>;

export type SubmitTollFreeRegistrationMutation = {
  __typename?: 'Mutation';
  submitTollFreeRegistration: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type UploadEinFileMutationVariables = Exact<{
  input: UploadEinFileInput;
}>;

export type UploadEinFileMutation = {
  __typename?: 'Mutation';
  uploadEINFile: { __typename?: 'OperationStatus'; success: boolean };
};

export type SubAccountFragmentFragment = {
  __typename?: 'Account';
  id: string;
  firestoreId?: string | null;
  ownerId: string;
  status: AccountStatus;
  createdAt: string;
  type: AccountType;
  email: string;
  products: Array<Product>;
  secondarySubscriptions?: Array<{
    __typename?: 'CurrentSubscription';
    title: string;
    product: Product;
    price: number;
    discount?: {
      __typename?: 'Discount';
      amount: number;
      coupon: string;
    } | null;
  }> | null;
  name?: {
    __typename?: 'Name';
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type BillingProfileFragment = {
  __typename?: 'BillingProfile';
  id: string;
  email: string;
  externalId?: string | null;
  unsubscribeCoupons?: Array<string> | null;
  backupPaymentMethod: boolean;
  balance?: {
    __typename?: 'Balance';
    balance: number;
    topUpAmount: number;
    topUpBonus: number;
    firstTopUpBonusApplied: boolean;
    lastTopUp?: {
      __typename?: 'LastTopUp';
      amount: number;
      bonus?: number | null;
      topUpAt: string;
    } | null;
    schedule?: {
      __typename?: 'BalanceSchedule';
      amount: number;
      limit: number;
      enabled: boolean;
    } | null;
  } | null;
};

export type AccountFragmentFragment = {
  __typename?: 'Account';
  id: string;
  firestoreId?: string | null;
  ownerId: string;
  status: AccountStatus;
  type: AccountType;
  email: string;
  companyName?: string | null;
  products: Array<Product>;
  createdAt: string;
  timeZone?: string | null;
  dialerStatus: DialerStatus;
  isOnboarded: boolean;
  isTest: boolean;
  inBusinessHours: boolean;
  secondaryPlans?: Array<{
    __typename?: 'Plan';
    id: string;
    active: boolean;
    enterprise: boolean;
    base: boolean;
    default: boolean;
    type: PlanType;
    interval: BillingInterval;
    title: string;
    userTitle: string;
    product: Product;
    price: number;
    displayPrice: number;
    discount: number;
    secondary: boolean;
    lexorank?: string | null;
    mostPopular: boolean;
    monthlyPrice?: number | null;
    couponId?: string | null;
    features: Array<{
      __typename?: 'PlanFeature';
      type: FeatureType;
      title: string;
      tier?: number | null;
      limit?: number | null;
      min?: number | null;
      price?: number | null;
      trialLimits: {
        __typename?: 'PlanFeatureTrial';
        use: boolean;
        tier?: number | null;
        limit?: number | null;
      };
    }>;
  }> | null;
  name?: {
    __typename?: 'Name';
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  affiliate?: {
    __typename?: 'Affiliate';
    id: string;
    referralId: string;
    referralLink: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  billingProfile?: {
    __typename?: 'BillingProfile';
    id: string;
    email: string;
    externalId?: string | null;
    unsubscribeCoupons?: Array<string> | null;
    backupPaymentMethod: boolean;
    balance?: {
      __typename?: 'Balance';
      balance: number;
      topUpAmount: number;
      topUpBonus: number;
      firstTopUpBonusApplied: boolean;
      lastTopUp?: {
        __typename?: 'LastTopUp';
        amount: number;
        bonus?: number | null;
        topUpAt: string;
      } | null;
      schedule?: {
        __typename?: 'BalanceSchedule';
        amount: number;
        limit: number;
        enabled: boolean;
      } | null;
    } | null;
  } | null;
  subAccounts?: Array<{
    __typename?: 'Account';
    id: string;
    firestoreId?: string | null;
    ownerId: string;
    status: AccountStatus;
    createdAt: string;
    type: AccountType;
    email: string;
    products: Array<Product>;
    secondarySubscriptions?: Array<{
      __typename?: 'CurrentSubscription';
      title: string;
      product: Product;
      price: number;
      discount?: {
        __typename?: 'Discount';
        amount: number;
        coupon: string;
      } | null;
    }> | null;
    name?: {
      __typename?: 'Name';
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  }> | null;
  questionnaire?: {
    __typename?: 'AccountQuestionnaire';
    realEstate?: string | null;
    employees?: string | null;
    role?: string | null;
    industry?: string | null;
    experience?: string | null;
    marketingChannels?: Array<string> | null;
    marketingTraining?: string | null;
    smsMarketing?: string | null;
    provider?: string | null;
  } | null;
  settings?: {
    __typename?: 'AccountSettings';
    refreshRate?: number | null;
    openNewTab?: boolean | null;
    defaultLabelColor?: string | null;
    viewedPopups?: Array<string> | null;
  } | null;
  subscription?: {
    __typename?: 'CurrentSubscription';
    id: string;
    status: SubscriptionStatus;
    startedAt?: string | null;
    endAt?: string | null;
    updatedAt?: string | null;
    nextBillingDate?: string | null;
    trialEndedAt?: string | null;
    membershipMonths?: number | null;
    product: Product;
    reason?: string | null;
    base: boolean;
    price: number;
    secondary: boolean;
    title: string;
    discount?: {
      __typename?: 'Discount';
      amount: number;
      coupon: string;
      promoCode?: string | null;
      expiresAt?: string | null;
      percent?: number | null;
      startedAt?: string | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      active: boolean;
      enterprise: boolean;
      base: boolean;
      default: boolean;
      type: PlanType;
      interval: BillingInterval;
      title: string;
      userTitle: string;
      product: Product;
      price: number;
      displayPrice: number;
      discount: number;
      secondary: boolean;
      lexorank?: string | null;
      mostPopular: boolean;
      monthlyPrice?: number | null;
      couponId?: string | null;
      features: Array<{
        __typename?: 'PlanFeature';
        type: FeatureType;
        title: string;
        tier?: number | null;
        limit?: number | null;
        min?: number | null;
        price?: number | null;
        trialLimits: {
          __typename?: 'PlanFeatureTrial';
          use: boolean;
          tier?: number | null;
          limit?: number | null;
        };
      }>;
    } | null;
  } | null;
  subscriptions?: Array<{
    __typename?: 'CurrentSubscription';
    id: string;
    status: SubscriptionStatus;
    startedAt?: string | null;
    endAt?: string | null;
    updatedAt?: string | null;
    nextBillingDate?: string | null;
    trialEndedAt?: string | null;
    membershipMonths?: number | null;
    product: Product;
    reason?: string | null;
    base: boolean;
    price: number;
    secondary: boolean;
    title: string;
    discount?: {
      __typename?: 'Discount';
      amount: number;
      coupon: string;
      promoCode?: string | null;
      expiresAt?: string | null;
      percent?: number | null;
      startedAt?: string | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      active: boolean;
      enterprise: boolean;
      base: boolean;
      default: boolean;
      type: PlanType;
      interval: BillingInterval;
      title: string;
      userTitle: string;
      product: Product;
      price: number;
      displayPrice: number;
      discount: number;
      secondary: boolean;
      lexorank?: string | null;
      mostPopular: boolean;
      monthlyPrice?: number | null;
      couponId?: string | null;
      features: Array<{
        __typename?: 'PlanFeature';
        type: FeatureType;
        title: string;
        tier?: number | null;
        limit?: number | null;
        min?: number | null;
        price?: number | null;
        trialLimits: {
          __typename?: 'PlanFeatureTrial';
          use: boolean;
          tier?: number | null;
          limit?: number | null;
        };
      }>;
    } | null;
  }> | null;
  messagingProfile?: {
    __typename?: 'MessagingProfile';
    id: string;
    allowUrl: boolean;
    isEINDocRequired: boolean;
    phoneSettings?: {
      __typename?: 'PhoneSettings';
      activeIn?: string | null;
      isSipEnabled?: boolean | null;
      notificationsEnabled?: boolean | null;
      alertFilter?: {
        __typename?: 'PhoneSettingsFilter';
        campaigns?: Array<string> | null;
        groups?: Array<string> | null;
        labels?: Array<string> | null;
      } | null;
      notificationFilter?: {
        __typename?: 'PhoneSettingsFilter';
        campaigns?: Array<string> | null;
        groups?: Array<string> | null;
        labels?: Array<string> | null;
      } | null;
      callAutoReply?: {
        __typename?: 'PhoneSettingsAutoReply';
        enabled?: boolean | null;
        voiceType?: PhoneSettingsVoiceType | null;
        payload?: string | null;
      } | null;
      voiceMail?: {
        __typename?: 'PhoneSettingsAutoReply';
        enabled?: boolean | null;
        voiceType?: PhoneSettingsVoiceType | null;
        payload?: string | null;
      } | null;
    } | null;
    callSettings?: {
      __typename?: 'CallSettings';
      activeIn?: string | null;
      isSipEnabled?: boolean | null;
      callAutoReply?: {
        __typename?: 'CallSettingsAutoReply';
        enabled?: boolean | null;
        voiceType?: CallSettingsVoiceType | null;
        payload?: string | null;
      } | null;
      voiceMail?: {
        __typename?: 'CallSettingsAutoReply';
        enabled?: boolean | null;
        voiceType?: CallSettingsVoiceType | null;
        payload?: string | null;
      } | null;
    } | null;
    a2pInfo?: {
      __typename?: 'A2PInfo';
      status: A2PInfoStatus;
      maxTpm: number;
    } | null;
    tollFreeInfo?: {
      __typename?: 'TollFreeInfo';
      status: TollFreeInfoStatus;
      maxTpm: number;
      limits: {
        __typename?: 'TollFreeInfoLimits';
        daily: number;
        weekly: number;
        monthly: number;
      };
    } | null;
    responseRemoval?: {
      __typename?: 'ResponseRemoval';
      enabled?: boolean | null;
      responseFilters?: Array<string> | null;
    } | null;
    provider?: {
      __typename?: 'MessagingProvider';
      sipPassword?: string | null;
      sipUsername?: string | null;
      type: MessagingProviderType;
    } | null;
  } | null;
  quotasUsage: Array<{
    __typename?: 'QuotaUsage';
    used: number;
    type: QuotaType;
  }>;
  features?: Array<{
    __typename?: 'Feature';
    type: FeatureType;
    title: string;
    price?: number | null;
    tier?: number | null;
    limit?: number | null;
    min?: number | null;
    product?: Product | null;
  }> | null;
  permissions: {
    __typename?: 'AccountPermissionsView';
    optOutDisabled: boolean;
    allowOptOutDisable: boolean;
    allowCrmIntegrations: boolean;
    allowDripCampaign: boolean;
    allowKeywordCampaign: boolean;
    allowScheduleCampaign: boolean;
    allowThrottleCampaign: boolean;
  };
};

export type AccountQueryVariables = Exact<{ [key: string]: never }>;

export type AccountQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    isSubAccount: boolean;
    id: string;
    firestoreId?: string | null;
    ownerId: string;
    status: AccountStatus;
    type: AccountType;
    email: string;
    companyName?: string | null;
    products: Array<Product>;
    createdAt: string;
    timeZone?: string | null;
    dialerStatus: DialerStatus;
    isOnboarded: boolean;
    isTest: boolean;
    inBusinessHours: boolean;
    secondaryPlans?: Array<{
      __typename?: 'Plan';
      id: string;
      active: boolean;
      enterprise: boolean;
      base: boolean;
      default: boolean;
      type: PlanType;
      interval: BillingInterval;
      title: string;
      userTitle: string;
      product: Product;
      price: number;
      displayPrice: number;
      discount: number;
      secondary: boolean;
      lexorank?: string | null;
      mostPopular: boolean;
      monthlyPrice?: number | null;
      couponId?: string | null;
      features: Array<{
        __typename?: 'PlanFeature';
        type: FeatureType;
        title: string;
        tier?: number | null;
        limit?: number | null;
        min?: number | null;
        price?: number | null;
        trialLimits: {
          __typename?: 'PlanFeatureTrial';
          use: boolean;
          tier?: number | null;
          limit?: number | null;
        };
      }>;
    }> | null;
    name?: {
      __typename?: 'Name';
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    affiliate?: {
      __typename?: 'Affiliate';
      id: string;
      referralId: string;
      referralLink: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    billingProfile?: {
      __typename?: 'BillingProfile';
      id: string;
      email: string;
      externalId?: string | null;
      unsubscribeCoupons?: Array<string> | null;
      backupPaymentMethod: boolean;
      balance?: {
        __typename?: 'Balance';
        balance: number;
        topUpAmount: number;
        topUpBonus: number;
        firstTopUpBonusApplied: boolean;
        lastTopUp?: {
          __typename?: 'LastTopUp';
          amount: number;
          bonus?: number | null;
          topUpAt: string;
        } | null;
        schedule?: {
          __typename?: 'BalanceSchedule';
          amount: number;
          limit: number;
          enabled: boolean;
        } | null;
      } | null;
    } | null;
    subAccounts?: Array<{
      __typename?: 'Account';
      id: string;
      firestoreId?: string | null;
      ownerId: string;
      status: AccountStatus;
      createdAt: string;
      type: AccountType;
      email: string;
      products: Array<Product>;
      secondarySubscriptions?: Array<{
        __typename?: 'CurrentSubscription';
        title: string;
        product: Product;
        price: number;
        discount?: {
          __typename?: 'Discount';
          amount: number;
          coupon: string;
        } | null;
      }> | null;
      name?: {
        __typename?: 'Name';
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }> | null;
    questionnaire?: {
      __typename?: 'AccountQuestionnaire';
      realEstate?: string | null;
      employees?: string | null;
      role?: string | null;
      industry?: string | null;
      experience?: string | null;
      marketingChannels?: Array<string> | null;
      marketingTraining?: string | null;
      smsMarketing?: string | null;
      provider?: string | null;
    } | null;
    settings?: {
      __typename?: 'AccountSettings';
      refreshRate?: number | null;
      openNewTab?: boolean | null;
      defaultLabelColor?: string | null;
      viewedPopups?: Array<string> | null;
    } | null;
    subscription?: {
      __typename?: 'CurrentSubscription';
      id: string;
      status: SubscriptionStatus;
      startedAt?: string | null;
      endAt?: string | null;
      updatedAt?: string | null;
      nextBillingDate?: string | null;
      trialEndedAt?: string | null;
      membershipMonths?: number | null;
      product: Product;
      reason?: string | null;
      base: boolean;
      price: number;
      secondary: boolean;
      title: string;
      discount?: {
        __typename?: 'Discount';
        amount: number;
        coupon: string;
        promoCode?: string | null;
        expiresAt?: string | null;
        percent?: number | null;
        startedAt?: string | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        id: string;
        active: boolean;
        enterprise: boolean;
        base: boolean;
        default: boolean;
        type: PlanType;
        interval: BillingInterval;
        title: string;
        userTitle: string;
        product: Product;
        price: number;
        displayPrice: number;
        discount: number;
        secondary: boolean;
        lexorank?: string | null;
        mostPopular: boolean;
        monthlyPrice?: number | null;
        couponId?: string | null;
        features: Array<{
          __typename?: 'PlanFeature';
          type: FeatureType;
          title: string;
          tier?: number | null;
          limit?: number | null;
          min?: number | null;
          price?: number | null;
          trialLimits: {
            __typename?: 'PlanFeatureTrial';
            use: boolean;
            tier?: number | null;
            limit?: number | null;
          };
        }>;
      } | null;
    } | null;
    subscriptions?: Array<{
      __typename?: 'CurrentSubscription';
      id: string;
      status: SubscriptionStatus;
      startedAt?: string | null;
      endAt?: string | null;
      updatedAt?: string | null;
      nextBillingDate?: string | null;
      trialEndedAt?: string | null;
      membershipMonths?: number | null;
      product: Product;
      reason?: string | null;
      base: boolean;
      price: number;
      secondary: boolean;
      title: string;
      discount?: {
        __typename?: 'Discount';
        amount: number;
        coupon: string;
        promoCode?: string | null;
        expiresAt?: string | null;
        percent?: number | null;
        startedAt?: string | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        id: string;
        active: boolean;
        enterprise: boolean;
        base: boolean;
        default: boolean;
        type: PlanType;
        interval: BillingInterval;
        title: string;
        userTitle: string;
        product: Product;
        price: number;
        displayPrice: number;
        discount: number;
        secondary: boolean;
        lexorank?: string | null;
        mostPopular: boolean;
        monthlyPrice?: number | null;
        couponId?: string | null;
        features: Array<{
          __typename?: 'PlanFeature';
          type: FeatureType;
          title: string;
          tier?: number | null;
          limit?: number | null;
          min?: number | null;
          price?: number | null;
          trialLimits: {
            __typename?: 'PlanFeatureTrial';
            use: boolean;
            tier?: number | null;
            limit?: number | null;
          };
        }>;
      } | null;
    }> | null;
    messagingProfile?: {
      __typename?: 'MessagingProfile';
      id: string;
      allowUrl: boolean;
      isEINDocRequired: boolean;
      phoneSettings?: {
        __typename?: 'PhoneSettings';
        activeIn?: string | null;
        isSipEnabled?: boolean | null;
        notificationsEnabled?: boolean | null;
        alertFilter?: {
          __typename?: 'PhoneSettingsFilter';
          campaigns?: Array<string> | null;
          groups?: Array<string> | null;
          labels?: Array<string> | null;
        } | null;
        notificationFilter?: {
          __typename?: 'PhoneSettingsFilter';
          campaigns?: Array<string> | null;
          groups?: Array<string> | null;
          labels?: Array<string> | null;
        } | null;
        callAutoReply?: {
          __typename?: 'PhoneSettingsAutoReply';
          enabled?: boolean | null;
          voiceType?: PhoneSettingsVoiceType | null;
          payload?: string | null;
        } | null;
        voiceMail?: {
          __typename?: 'PhoneSettingsAutoReply';
          enabled?: boolean | null;
          voiceType?: PhoneSettingsVoiceType | null;
          payload?: string | null;
        } | null;
      } | null;
      callSettings?: {
        __typename?: 'CallSettings';
        activeIn?: string | null;
        isSipEnabled?: boolean | null;
        callAutoReply?: {
          __typename?: 'CallSettingsAutoReply';
          enabled?: boolean | null;
          voiceType?: CallSettingsVoiceType | null;
          payload?: string | null;
        } | null;
        voiceMail?: {
          __typename?: 'CallSettingsAutoReply';
          enabled?: boolean | null;
          voiceType?: CallSettingsVoiceType | null;
          payload?: string | null;
        } | null;
      } | null;
      a2pInfo?: {
        __typename?: 'A2PInfo';
        status: A2PInfoStatus;
        maxTpm: number;
      } | null;
      tollFreeInfo?: {
        __typename?: 'TollFreeInfo';
        status: TollFreeInfoStatus;
        maxTpm: number;
        limits: {
          __typename?: 'TollFreeInfoLimits';
          daily: number;
          weekly: number;
          monthly: number;
        };
      } | null;
      responseRemoval?: {
        __typename?: 'ResponseRemoval';
        enabled?: boolean | null;
        responseFilters?: Array<string> | null;
      } | null;
      provider?: {
        __typename?: 'MessagingProvider';
        sipPassword?: string | null;
        sipUsername?: string | null;
        type: MessagingProviderType;
      } | null;
    } | null;
    quotasUsage: Array<{
      __typename?: 'QuotaUsage';
      used: number;
      type: QuotaType;
    }>;
    features?: Array<{
      __typename?: 'Feature';
      type: FeatureType;
      title: string;
      price?: number | null;
      tier?: number | null;
      limit?: number | null;
      min?: number | null;
      product?: Product | null;
    }> | null;
    permissions: {
      __typename?: 'AccountPermissionsView';
      optOutDisabled: boolean;
      allowOptOutDisable: boolean;
      allowCrmIntegrations: boolean;
      allowDripCampaign: boolean;
      allowKeywordCampaign: boolean;
      allowScheduleCampaign: boolean;
      allowThrottleCampaign: boolean;
    };
  };
};

export type AccountBillingSourcesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AccountBillingSourcesQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    isSubAccount: boolean;
    id: string;
    firestoreId?: string | null;
    ownerId: string;
    status: AccountStatus;
    type: AccountType;
    email: string;
    companyName?: string | null;
    products: Array<Product>;
    createdAt: string;
    timeZone?: string | null;
    dialerStatus: DialerStatus;
    isOnboarded: boolean;
    isTest: boolean;
    inBusinessHours: boolean;
    billingProfile?: {
      __typename?: 'BillingProfile';
      id: string;
      email: string;
      externalId?: string | null;
      unsubscribeCoupons?: Array<string> | null;
      backupPaymentMethod: boolean;
      sources?: Array<{
        __typename?: 'PaymentSource';
        id: string;
        brand?: string | null;
        expMonth?: number | null;
        expYear?: number | null;
        last4?: string | null;
        default?: boolean | null;
      }> | null;
      balance?: {
        __typename?: 'Balance';
        balance: number;
        topUpAmount: number;
        topUpBonus: number;
        firstTopUpBonusApplied: boolean;
        lastTopUp?: {
          __typename?: 'LastTopUp';
          amount: number;
          bonus?: number | null;
          topUpAt: string;
        } | null;
        schedule?: {
          __typename?: 'BalanceSchedule';
          amount: number;
          limit: number;
          enabled: boolean;
        } | null;
      } | null;
    } | null;
    secondaryPlans?: Array<{
      __typename?: 'Plan';
      id: string;
      active: boolean;
      enterprise: boolean;
      base: boolean;
      default: boolean;
      type: PlanType;
      interval: BillingInterval;
      title: string;
      userTitle: string;
      product: Product;
      price: number;
      displayPrice: number;
      discount: number;
      secondary: boolean;
      lexorank?: string | null;
      mostPopular: boolean;
      monthlyPrice?: number | null;
      couponId?: string | null;
      features: Array<{
        __typename?: 'PlanFeature';
        type: FeatureType;
        title: string;
        tier?: number | null;
        limit?: number | null;
        min?: number | null;
        price?: number | null;
        trialLimits: {
          __typename?: 'PlanFeatureTrial';
          use: boolean;
          tier?: number | null;
          limit?: number | null;
        };
      }>;
    }> | null;
    name?: {
      __typename?: 'Name';
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    affiliate?: {
      __typename?: 'Affiliate';
      id: string;
      referralId: string;
      referralLink: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    subAccounts?: Array<{
      __typename?: 'Account';
      id: string;
      firestoreId?: string | null;
      ownerId: string;
      status: AccountStatus;
      createdAt: string;
      type: AccountType;
      email: string;
      products: Array<Product>;
      secondarySubscriptions?: Array<{
        __typename?: 'CurrentSubscription';
        title: string;
        product: Product;
        price: number;
        discount?: {
          __typename?: 'Discount';
          amount: number;
          coupon: string;
        } | null;
      }> | null;
      name?: {
        __typename?: 'Name';
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }> | null;
    questionnaire?: {
      __typename?: 'AccountQuestionnaire';
      realEstate?: string | null;
      employees?: string | null;
      role?: string | null;
      industry?: string | null;
      experience?: string | null;
      marketingChannels?: Array<string> | null;
      marketingTraining?: string | null;
      smsMarketing?: string | null;
      provider?: string | null;
    } | null;
    settings?: {
      __typename?: 'AccountSettings';
      refreshRate?: number | null;
      openNewTab?: boolean | null;
      defaultLabelColor?: string | null;
      viewedPopups?: Array<string> | null;
    } | null;
    subscription?: {
      __typename?: 'CurrentSubscription';
      id: string;
      status: SubscriptionStatus;
      startedAt?: string | null;
      endAt?: string | null;
      updatedAt?: string | null;
      nextBillingDate?: string | null;
      trialEndedAt?: string | null;
      membershipMonths?: number | null;
      product: Product;
      reason?: string | null;
      base: boolean;
      price: number;
      secondary: boolean;
      title: string;
      discount?: {
        __typename?: 'Discount';
        amount: number;
        coupon: string;
        promoCode?: string | null;
        expiresAt?: string | null;
        percent?: number | null;
        startedAt?: string | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        id: string;
        active: boolean;
        enterprise: boolean;
        base: boolean;
        default: boolean;
        type: PlanType;
        interval: BillingInterval;
        title: string;
        userTitle: string;
        product: Product;
        price: number;
        displayPrice: number;
        discount: number;
        secondary: boolean;
        lexorank?: string | null;
        mostPopular: boolean;
        monthlyPrice?: number | null;
        couponId?: string | null;
        features: Array<{
          __typename?: 'PlanFeature';
          type: FeatureType;
          title: string;
          tier?: number | null;
          limit?: number | null;
          min?: number | null;
          price?: number | null;
          trialLimits: {
            __typename?: 'PlanFeatureTrial';
            use: boolean;
            tier?: number | null;
            limit?: number | null;
          };
        }>;
      } | null;
    } | null;
    subscriptions?: Array<{
      __typename?: 'CurrentSubscription';
      id: string;
      status: SubscriptionStatus;
      startedAt?: string | null;
      endAt?: string | null;
      updatedAt?: string | null;
      nextBillingDate?: string | null;
      trialEndedAt?: string | null;
      membershipMonths?: number | null;
      product: Product;
      reason?: string | null;
      base: boolean;
      price: number;
      secondary: boolean;
      title: string;
      discount?: {
        __typename?: 'Discount';
        amount: number;
        coupon: string;
        promoCode?: string | null;
        expiresAt?: string | null;
        percent?: number | null;
        startedAt?: string | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        id: string;
        active: boolean;
        enterprise: boolean;
        base: boolean;
        default: boolean;
        type: PlanType;
        interval: BillingInterval;
        title: string;
        userTitle: string;
        product: Product;
        price: number;
        displayPrice: number;
        discount: number;
        secondary: boolean;
        lexorank?: string | null;
        mostPopular: boolean;
        monthlyPrice?: number | null;
        couponId?: string | null;
        features: Array<{
          __typename?: 'PlanFeature';
          type: FeatureType;
          title: string;
          tier?: number | null;
          limit?: number | null;
          min?: number | null;
          price?: number | null;
          trialLimits: {
            __typename?: 'PlanFeatureTrial';
            use: boolean;
            tier?: number | null;
            limit?: number | null;
          };
        }>;
      } | null;
    }> | null;
    messagingProfile?: {
      __typename?: 'MessagingProfile';
      id: string;
      allowUrl: boolean;
      isEINDocRequired: boolean;
      phoneSettings?: {
        __typename?: 'PhoneSettings';
        activeIn?: string | null;
        isSipEnabled?: boolean | null;
        notificationsEnabled?: boolean | null;
        alertFilter?: {
          __typename?: 'PhoneSettingsFilter';
          campaigns?: Array<string> | null;
          groups?: Array<string> | null;
          labels?: Array<string> | null;
        } | null;
        notificationFilter?: {
          __typename?: 'PhoneSettingsFilter';
          campaigns?: Array<string> | null;
          groups?: Array<string> | null;
          labels?: Array<string> | null;
        } | null;
        callAutoReply?: {
          __typename?: 'PhoneSettingsAutoReply';
          enabled?: boolean | null;
          voiceType?: PhoneSettingsVoiceType | null;
          payload?: string | null;
        } | null;
        voiceMail?: {
          __typename?: 'PhoneSettingsAutoReply';
          enabled?: boolean | null;
          voiceType?: PhoneSettingsVoiceType | null;
          payload?: string | null;
        } | null;
      } | null;
      callSettings?: {
        __typename?: 'CallSettings';
        activeIn?: string | null;
        isSipEnabled?: boolean | null;
        callAutoReply?: {
          __typename?: 'CallSettingsAutoReply';
          enabled?: boolean | null;
          voiceType?: CallSettingsVoiceType | null;
          payload?: string | null;
        } | null;
        voiceMail?: {
          __typename?: 'CallSettingsAutoReply';
          enabled?: boolean | null;
          voiceType?: CallSettingsVoiceType | null;
          payload?: string | null;
        } | null;
      } | null;
      a2pInfo?: {
        __typename?: 'A2PInfo';
        status: A2PInfoStatus;
        maxTpm: number;
      } | null;
      tollFreeInfo?: {
        __typename?: 'TollFreeInfo';
        status: TollFreeInfoStatus;
        maxTpm: number;
        limits: {
          __typename?: 'TollFreeInfoLimits';
          daily: number;
          weekly: number;
          monthly: number;
        };
      } | null;
      responseRemoval?: {
        __typename?: 'ResponseRemoval';
        enabled?: boolean | null;
        responseFilters?: Array<string> | null;
      } | null;
      provider?: {
        __typename?: 'MessagingProvider';
        sipPassword?: string | null;
        sipUsername?: string | null;
        type: MessagingProviderType;
      } | null;
    } | null;
    quotasUsage: Array<{
      __typename?: 'QuotaUsage';
      used: number;
      type: QuotaType;
    }>;
    features?: Array<{
      __typename?: 'Feature';
      type: FeatureType;
      title: string;
      price?: number | null;
      tier?: number | null;
      limit?: number | null;
      min?: number | null;
      product?: Product | null;
    }> | null;
    permissions: {
      __typename?: 'AccountPermissionsView';
      optOutDisabled: boolean;
      allowOptOutDisable: boolean;
      allowCrmIntegrations: boolean;
      allowDripCampaign: boolean;
      allowKeywordCampaign: boolean;
      allowScheduleCampaign: boolean;
      allowThrottleCampaign: boolean;
    };
  };
};

export type CreateImpersonateTokenMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type CreateImpersonateTokenMutation = {
  __typename?: 'Mutation';
  createImpersonateToken: { __typename?: 'ImpersonateToken'; token: string };
};

export type UpdateAccountMutationVariables = Exact<{
  data: UpdateAccountInput;
}>;

export type UpdateAccountMutation = {
  __typename?: 'Mutation';
  updateAccount: {
    __typename?: 'Account';
    id: string;
    firestoreId?: string | null;
    ownerId: string;
    status: AccountStatus;
    type: AccountType;
    email: string;
    companyName?: string | null;
    products: Array<Product>;
    createdAt: string;
    timeZone?: string | null;
    dialerStatus: DialerStatus;
    isOnboarded: boolean;
    isTest: boolean;
    inBusinessHours: boolean;
    secondaryPlans?: Array<{
      __typename?: 'Plan';
      id: string;
      active: boolean;
      enterprise: boolean;
      base: boolean;
      default: boolean;
      type: PlanType;
      interval: BillingInterval;
      title: string;
      userTitle: string;
      product: Product;
      price: number;
      displayPrice: number;
      discount: number;
      secondary: boolean;
      lexorank?: string | null;
      mostPopular: boolean;
      monthlyPrice?: number | null;
      couponId?: string | null;
      features: Array<{
        __typename?: 'PlanFeature';
        type: FeatureType;
        title: string;
        tier?: number | null;
        limit?: number | null;
        min?: number | null;
        price?: number | null;
        trialLimits: {
          __typename?: 'PlanFeatureTrial';
          use: boolean;
          tier?: number | null;
          limit?: number | null;
        };
      }>;
    }> | null;
    name?: {
      __typename?: 'Name';
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    affiliate?: {
      __typename?: 'Affiliate';
      id: string;
      referralId: string;
      referralLink: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    billingProfile?: {
      __typename?: 'BillingProfile';
      id: string;
      email: string;
      externalId?: string | null;
      unsubscribeCoupons?: Array<string> | null;
      backupPaymentMethod: boolean;
      balance?: {
        __typename?: 'Balance';
        balance: number;
        topUpAmount: number;
        topUpBonus: number;
        firstTopUpBonusApplied: boolean;
        lastTopUp?: {
          __typename?: 'LastTopUp';
          amount: number;
          bonus?: number | null;
          topUpAt: string;
        } | null;
        schedule?: {
          __typename?: 'BalanceSchedule';
          amount: number;
          limit: number;
          enabled: boolean;
        } | null;
      } | null;
    } | null;
    subAccounts?: Array<{
      __typename?: 'Account';
      id: string;
      firestoreId?: string | null;
      ownerId: string;
      status: AccountStatus;
      createdAt: string;
      type: AccountType;
      email: string;
      products: Array<Product>;
      secondarySubscriptions?: Array<{
        __typename?: 'CurrentSubscription';
        title: string;
        product: Product;
        price: number;
        discount?: {
          __typename?: 'Discount';
          amount: number;
          coupon: string;
        } | null;
      }> | null;
      name?: {
        __typename?: 'Name';
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }> | null;
    questionnaire?: {
      __typename?: 'AccountQuestionnaire';
      realEstate?: string | null;
      employees?: string | null;
      role?: string | null;
      industry?: string | null;
      experience?: string | null;
      marketingChannels?: Array<string> | null;
      marketingTraining?: string | null;
      smsMarketing?: string | null;
      provider?: string | null;
    } | null;
    settings?: {
      __typename?: 'AccountSettings';
      refreshRate?: number | null;
      openNewTab?: boolean | null;
      defaultLabelColor?: string | null;
      viewedPopups?: Array<string> | null;
    } | null;
    subscription?: {
      __typename?: 'CurrentSubscription';
      id: string;
      status: SubscriptionStatus;
      startedAt?: string | null;
      endAt?: string | null;
      updatedAt?: string | null;
      nextBillingDate?: string | null;
      trialEndedAt?: string | null;
      membershipMonths?: number | null;
      product: Product;
      reason?: string | null;
      base: boolean;
      price: number;
      secondary: boolean;
      title: string;
      discount?: {
        __typename?: 'Discount';
        amount: number;
        coupon: string;
        promoCode?: string | null;
        expiresAt?: string | null;
        percent?: number | null;
        startedAt?: string | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        id: string;
        active: boolean;
        enterprise: boolean;
        base: boolean;
        default: boolean;
        type: PlanType;
        interval: BillingInterval;
        title: string;
        userTitle: string;
        product: Product;
        price: number;
        displayPrice: number;
        discount: number;
        secondary: boolean;
        lexorank?: string | null;
        mostPopular: boolean;
        monthlyPrice?: number | null;
        couponId?: string | null;
        features: Array<{
          __typename?: 'PlanFeature';
          type: FeatureType;
          title: string;
          tier?: number | null;
          limit?: number | null;
          min?: number | null;
          price?: number | null;
          trialLimits: {
            __typename?: 'PlanFeatureTrial';
            use: boolean;
            tier?: number | null;
            limit?: number | null;
          };
        }>;
      } | null;
    } | null;
    subscriptions?: Array<{
      __typename?: 'CurrentSubscription';
      id: string;
      status: SubscriptionStatus;
      startedAt?: string | null;
      endAt?: string | null;
      updatedAt?: string | null;
      nextBillingDate?: string | null;
      trialEndedAt?: string | null;
      membershipMonths?: number | null;
      product: Product;
      reason?: string | null;
      base: boolean;
      price: number;
      secondary: boolean;
      title: string;
      discount?: {
        __typename?: 'Discount';
        amount: number;
        coupon: string;
        promoCode?: string | null;
        expiresAt?: string | null;
        percent?: number | null;
        startedAt?: string | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        id: string;
        active: boolean;
        enterprise: boolean;
        base: boolean;
        default: boolean;
        type: PlanType;
        interval: BillingInterval;
        title: string;
        userTitle: string;
        product: Product;
        price: number;
        displayPrice: number;
        discount: number;
        secondary: boolean;
        lexorank?: string | null;
        mostPopular: boolean;
        monthlyPrice?: number | null;
        couponId?: string | null;
        features: Array<{
          __typename?: 'PlanFeature';
          type: FeatureType;
          title: string;
          tier?: number | null;
          limit?: number | null;
          min?: number | null;
          price?: number | null;
          trialLimits: {
            __typename?: 'PlanFeatureTrial';
            use: boolean;
            tier?: number | null;
            limit?: number | null;
          };
        }>;
      } | null;
    }> | null;
    messagingProfile?: {
      __typename?: 'MessagingProfile';
      id: string;
      allowUrl: boolean;
      isEINDocRequired: boolean;
      phoneSettings?: {
        __typename?: 'PhoneSettings';
        activeIn?: string | null;
        isSipEnabled?: boolean | null;
        notificationsEnabled?: boolean | null;
        alertFilter?: {
          __typename?: 'PhoneSettingsFilter';
          campaigns?: Array<string> | null;
          groups?: Array<string> | null;
          labels?: Array<string> | null;
        } | null;
        notificationFilter?: {
          __typename?: 'PhoneSettingsFilter';
          campaigns?: Array<string> | null;
          groups?: Array<string> | null;
          labels?: Array<string> | null;
        } | null;
        callAutoReply?: {
          __typename?: 'PhoneSettingsAutoReply';
          enabled?: boolean | null;
          voiceType?: PhoneSettingsVoiceType | null;
          payload?: string | null;
        } | null;
        voiceMail?: {
          __typename?: 'PhoneSettingsAutoReply';
          enabled?: boolean | null;
          voiceType?: PhoneSettingsVoiceType | null;
          payload?: string | null;
        } | null;
      } | null;
      callSettings?: {
        __typename?: 'CallSettings';
        activeIn?: string | null;
        isSipEnabled?: boolean | null;
        callAutoReply?: {
          __typename?: 'CallSettingsAutoReply';
          enabled?: boolean | null;
          voiceType?: CallSettingsVoiceType | null;
          payload?: string | null;
        } | null;
        voiceMail?: {
          __typename?: 'CallSettingsAutoReply';
          enabled?: boolean | null;
          voiceType?: CallSettingsVoiceType | null;
          payload?: string | null;
        } | null;
      } | null;
      a2pInfo?: {
        __typename?: 'A2PInfo';
        status: A2PInfoStatus;
        maxTpm: number;
      } | null;
      tollFreeInfo?: {
        __typename?: 'TollFreeInfo';
        status: TollFreeInfoStatus;
        maxTpm: number;
        limits: {
          __typename?: 'TollFreeInfoLimits';
          daily: number;
          weekly: number;
          monthly: number;
        };
      } | null;
      responseRemoval?: {
        __typename?: 'ResponseRemoval';
        enabled?: boolean | null;
        responseFilters?: Array<string> | null;
      } | null;
      provider?: {
        __typename?: 'MessagingProvider';
        sipPassword?: string | null;
        sipUsername?: string | null;
        type: MessagingProviderType;
      } | null;
    } | null;
    quotasUsage: Array<{
      __typename?: 'QuotaUsage';
      used: number;
      type: QuotaType;
    }>;
    features?: Array<{
      __typename?: 'Feature';
      type: FeatureType;
      title: string;
      price?: number | null;
      tier?: number | null;
      limit?: number | null;
      min?: number | null;
      product?: Product | null;
    }> | null;
    permissions: {
      __typename?: 'AccountPermissionsView';
      optOutDisabled: boolean;
      allowOptOutDisable: boolean;
      allowCrmIntegrations: boolean;
      allowDripCampaign: boolean;
      allowKeywordCampaign: boolean;
      allowScheduleCampaign: boolean;
      allowThrottleCampaign: boolean;
    };
  };
};

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;

export type SignUpMutation = {
  __typename?: 'Mutation';
  signUp: { __typename?: 'Account'; id: string };
};

export type CreateSubaccountMutationVariables = Exact<{
  input: CreateSubaccountInput;
}>;

export type CreateSubaccountMutation = {
  __typename?: 'Mutation';
  createSubaccount: {
    __typename?: 'Account';
    id: string;
    firestoreId?: string | null;
    ownerId: string;
    status: AccountStatus;
    createdAt: string;
    type: AccountType;
    email: string;
    products: Array<Product>;
    secondarySubscriptions?: Array<{
      __typename?: 'CurrentSubscription';
      title: string;
      product: Product;
      price: number;
      discount?: {
        __typename?: 'Discount';
        amount: number;
        coupon: string;
      } | null;
    }> | null;
    name?: {
      __typename?: 'Name';
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

export type UpdateSubaccountMutationVariables = Exact<{
  input: UpdateSubaccountInput;
  id: Scalars['String']['input'];
}>;

export type UpdateSubaccountMutation = {
  __typename?: 'Mutation';
  updateSubaccount: {
    __typename?: 'Account';
    id: string;
    firestoreId?: string | null;
    ownerId: string;
    status: AccountStatus;
    createdAt: string;
    type: AccountType;
    email: string;
    products: Array<Product>;
    secondarySubscriptions?: Array<{
      __typename?: 'CurrentSubscription';
      title: string;
      product: Product;
      price: number;
      discount?: {
        __typename?: 'Discount';
        amount: number;
        coupon: string;
      } | null;
    }> | null;
    name?: {
      __typename?: 'Name';
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

export type RemoveSubaccountMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveSubaccountMutation = {
  __typename?: 'Mutation';
  removeSubaccount: { __typename?: 'OperationStatus'; success: boolean };
};

export type AccountUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type AccountUpdatedSubscription = {
  __typename?: 'Subscription';
  accountUpdated: {
    __typename?: 'ChangesItemView';
    entityId: string;
    operation: ChangesOperation;
    operationAt: string;
  };
};

export type ResendPasswordResetTokenMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ResendPasswordResetTokenMutation = {
  __typename?: 'Mutation';
  resendPasswordResetToken: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type ConfirmPasswordResetMutationVariables = Exact<{
  token: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type ConfirmPasswordResetMutation = {
  __typename?: 'Mutation';
  confirmPasswordReset: { __typename?: 'OperationStatus'; success: boolean };
};

export type AcceptTermsAndConditionsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type AcceptTermsAndConditionsMutation = {
  __typename?: 'Mutation';
  acceptTermsAndConditions: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type AccountOutsideBusinessHoursRestrictionsSubscriptionVariables =
  Exact<{ [key: string]: never }>;

export type AccountOutsideBusinessHoursRestrictionsSubscription = {
  __typename?: 'Subscription';
  outsideBusinessHoursRestrictions: {
    __typename?: 'AccountOutsideBusinessHoursRestrictionsView';
    accountId: string;
    restriction: OutsideBusinessHoursRestrictionType;
  };
};

export type GetAccountsCountersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountsCountersQuery = {
  __typename?: 'Query';
  getAccountsCounters: {
    __typename?: 'AccountCounters';
    activePrimary?: number | null;
    activeSecondary?: number | null;
    trial?: number | null;
    inactive?: number | null;
    monthNewActive?: number | null;
    plans: Array<{
      __typename?: 'AccountCountersPlan';
      total?: number | null;
      type: PlanType;
    }>;
  };
};

export type GetAccountsSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountsSummaryQuery = {
  __typename?: 'Query';
  getAccountsSummary: {
    __typename?: 'AccountsSummary';
    products: Array<{
      __typename?: 'ProductSummary';
      activePrimary?: number | null;
      activeSecondary?: number | null;
      inactive?: number | null;
      monthNewActive?: number | null;
      product: Product;
      trial?: number | null;
    }>;
    subscriptions: {
      __typename?: 'SubscriptionsSummary';
      dialer: Array<{
        __typename?: 'SubscriptionSummary';
        total?: number | null;
        type: string;
      }>;
      elite: Array<{
        __typename?: 'SubscriptionSummary';
        total?: number | null;
        type: string;
      }>;
      messaging: Array<{
        __typename?: 'SubscriptionSummary';
        total?: number | null;
        type: string;
      }>;
      inactiveMessaging: Array<{
        __typename?: 'InactiveAccountSummary';
        total?: number | null;
      }>;
      inactiveDialer: Array<{
        __typename?: 'InactiveAccountSummary';
        total?: number | null;
      }>;
    };
  };
};

export type GetStatisticsQueryVariables = Exact<{ [key: string]: never }>;

export type GetStatisticsQuery = {
  __typename?: 'Query';
  getStatistics: {
    __typename?: 'MessagingProvidersCounters';
    infobip: {
      __typename?: 'MessagingProviderCounters';
      weeklySmsUsage?: number | null;
      deliveryRate?: number | null;
      approved?: number | null;
      declined?: number | null;
      pending?: number | null;
    };
    telnyx: {
      __typename?: 'MessagingProviderCounters';
      weeklySmsUsage?: number | null;
      deliveryRate?: number | null;
      approved?: number | null;
      declined?: number | null;
      pending?: number | null;
    };
    twilio: {
      __typename?: 'MessagingProviderCounters';
      weeklySmsUsage?: number | null;
      deliveryRate?: number | null;
      approved?: number | null;
      declined?: number | null;
      pending?: number | null;
    };
    commio: {
      __typename?: 'MessagingProviderCounters';
      weeklySmsUsage?: number | null;
      deliveryRate?: number | null;
      approved?: number | null;
      declined?: number | null;
      pending?: number | null;
    };
  };
};

export type ExportAccountsQueryVariables = Exact<{
  password: Scalars['String']['input'];
  filter?: InputMaybe<AccountsFilter>;
}>;

export type ExportAccountsQuery = {
  __typename?: 'Query';
  exportAccounts: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type AdminAccountFragmentFragment = {
  __typename?: 'Account';
  id: string;
  ownerId: string;
  status: AccountStatus;
  type: AccountType;
  email: string;
  phone?: string | null;
  isSubAccount: boolean;
  createdAt: string;
  isTest: boolean;
  isOnboarded: boolean;
  firestoreId?: string | null;
  companyName?: string | null;
  timeZone?: string | null;
  a2PStatus?: A2PInfoStatus | null;
  tollFreeStatus?: TollFreeInfoStatus | null;
  permissions: {
    __typename?: 'AccountPermissionsView';
    optOutDisabled: boolean;
    allowOptOutDisable: boolean;
    allowCrmIntegrations: boolean;
    allowDripCampaign: boolean;
    allowKeywordCampaign: boolean;
    allowScheduleCampaign: boolean;
    allowThrottleCampaign: boolean;
  };
  settings?: {
    __typename?: 'AccountSettings';
    refreshRate?: number | null;
    openNewTab?: boolean | null;
  } | null;
  name?: {
    __typename?: 'Name';
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  questionnaire?: {
    __typename?: 'AccountQuestionnaire';
    realEstate?: string | null;
    experience?: string | null;
    marketingChannels?: Array<string> | null;
    marketingTraining?: string | null;
    smsMarketing?: string | null;
    provider?: string | null;
    employees?: string | null;
    role?: string | null;
    industry?: string | null;
  } | null;
  affiliate?: {
    __typename?: 'Affiliate';
    id: string;
    referralId: string;
    referralLink: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    createdAt: string;
  } | null;
  subscription?: {
    __typename?: 'CurrentSubscription';
    id: string;
    endAt?: string | null;
    membershipMonths?: number | null;
    trialEndedAt?: string | null;
    startedAt?: string | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      title: string;
      userTitle: string;
      type: PlanType;
    } | null;
  } | null;
  subscriptions?: Array<{
    __typename?: 'CurrentSubscription';
    id: string;
    title: string;
    product: Product;
    status: SubscriptionStatus;
    price: number;
    nextBillingDate?: string | null;
    startedAt?: string | null;
    endAt?: string | null;
    updatedAt?: string | null;
    trialEndedAt?: string | null;
    membershipMonths?: number | null;
    reason?: string | null;
    base: boolean;
    secondary: boolean;
    discount?: {
      __typename?: 'Discount';
      amount: number;
      coupon: string;
      percent?: number | null;
      promoCode?: string | null;
      startedAt?: string | null;
      expiresAt?: string | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      secondary: boolean;
      product: Product;
      active: boolean;
      enterprise: boolean;
      base: boolean;
      default: boolean;
      type: PlanType;
      interval: BillingInterval;
      title: string;
      userTitle: string;
      price: number;
      displayPrice: number;
      discount: number;
      mostPopular: boolean;
      monthlyPrice?: number | null;
      couponId?: string | null;
      features: Array<{
        __typename?: 'PlanFeature';
        type: FeatureType;
        title: string;
        tier?: number | null;
        limit?: number | null;
        min?: number | null;
        price?: number | null;
        trialLimits: {
          __typename?: 'PlanFeatureTrial';
          use: boolean;
          tier?: number | null;
          limit?: number | null;
        };
      }>;
    } | null;
  }> | null;
  messagingProfile?: {
    __typename?: 'MessagingProfile';
    id: string;
    allowUrl: boolean;
    a2pInfo?: {
      __typename?: 'A2PInfo';
      maxTpm: number;
      status: A2PInfoStatus;
      campaignId?: string | null;
      brandId?: string | null;
      statusUpdatedAt?: string | null;
      incorporationDate?: string | null;
    } | null;
    tollFreeInfo?: {
      __typename?: 'TollFreeInfo';
      status: TollFreeInfoStatus;
      maxTpm: number;
      limits: {
        __typename?: 'TollFreeInfoLimits';
        daily: number;
        weekly: number;
        monthly: number;
      };
    } | null;
    provider?: {
      __typename?: 'MessagingProvider';
      type: MessagingProviderType;
    } | null;
  } | null;
  billingProfile?: {
    __typename?: 'BillingProfile';
    balance?: {
      __typename?: 'Balance';
      balance: number;
      topUpAmount: number;
      lastTopUp?: {
        __typename?: 'LastTopUp';
        topUpAt: string;
        amount: number;
      } | null;
    } | null;
  } | null;
  summary: {
    __typename?: 'AccountSummary';
    totalSmsUsed?: number | null;
    totalSkiptraceUsed?: number | null;
    totalMembershipFees?: number | null;
    totalBilledToDate?: number | null;
    totalBilled?: number | null;
    productsFees?: Array<{
      __typename?: 'ProfileProductFees';
      amount: number;
      product: Product;
    }> | null;
    lastBilling?: {
      __typename?: 'LastBilling';
      amount: number;
      createdAt: string;
    } | null;
    trialPeriod?: {
      __typename?: 'Period';
      endedAt?: string | null;
      startedAt: string;
    } | null;
  };
};

export type FindAccountsQueryVariables = Exact<{
  filter?: InputMaybe<AccountsFilter>;
  pagination?: InputMaybe<Pagination>;
  order?: InputMaybe<Order>;
}>;

export type FindAccountsQuery = {
  __typename?: 'Query';
  findAccounts: {
    __typename?: 'AccountList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'Account';
      id: string;
      ownerId: string;
      status: AccountStatus;
      type: AccountType;
      email: string;
      phone?: string | null;
      isSubAccount: boolean;
      createdAt: string;
      isTest: boolean;
      isOnboarded: boolean;
      firestoreId?: string | null;
      companyName?: string | null;
      timeZone?: string | null;
      a2PStatus?: A2PInfoStatus | null;
      tollFreeStatus?: TollFreeInfoStatus | null;
      permissions: {
        __typename?: 'AccountPermissionsView';
        optOutDisabled: boolean;
        allowOptOutDisable: boolean;
        allowCrmIntegrations: boolean;
        allowDripCampaign: boolean;
        allowKeywordCampaign: boolean;
        allowScheduleCampaign: boolean;
        allowThrottleCampaign: boolean;
      };
      settings?: {
        __typename?: 'AccountSettings';
        refreshRate?: number | null;
        openNewTab?: boolean | null;
      } | null;
      name?: {
        __typename?: 'Name';
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      questionnaire?: {
        __typename?: 'AccountQuestionnaire';
        realEstate?: string | null;
        experience?: string | null;
        marketingChannels?: Array<string> | null;
        marketingTraining?: string | null;
        smsMarketing?: string | null;
        provider?: string | null;
        employees?: string | null;
        role?: string | null;
        industry?: string | null;
      } | null;
      affiliate?: {
        __typename?: 'Affiliate';
        id: string;
        referralId: string;
        referralLink: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        createdAt: string;
      } | null;
      subscription?: {
        __typename?: 'CurrentSubscription';
        id: string;
        endAt?: string | null;
        membershipMonths?: number | null;
        trialEndedAt?: string | null;
        startedAt?: string | null;
        plan?: {
          __typename?: 'Plan';
          id: string;
          title: string;
          userTitle: string;
          type: PlanType;
        } | null;
      } | null;
      subscriptions?: Array<{
        __typename?: 'CurrentSubscription';
        id: string;
        title: string;
        product: Product;
        status: SubscriptionStatus;
        price: number;
        nextBillingDate?: string | null;
        startedAt?: string | null;
        endAt?: string | null;
        updatedAt?: string | null;
        trialEndedAt?: string | null;
        membershipMonths?: number | null;
        reason?: string | null;
        base: boolean;
        secondary: boolean;
        discount?: {
          __typename?: 'Discount';
          amount: number;
          coupon: string;
          percent?: number | null;
          promoCode?: string | null;
          startedAt?: string | null;
          expiresAt?: string | null;
        } | null;
        plan?: {
          __typename?: 'Plan';
          id: string;
          secondary: boolean;
          product: Product;
          active: boolean;
          enterprise: boolean;
          base: boolean;
          default: boolean;
          type: PlanType;
          interval: BillingInterval;
          title: string;
          userTitle: string;
          price: number;
          displayPrice: number;
          discount: number;
          mostPopular: boolean;
          monthlyPrice?: number | null;
          couponId?: string | null;
          features: Array<{
            __typename?: 'PlanFeature';
            type: FeatureType;
            title: string;
            tier?: number | null;
            limit?: number | null;
            min?: number | null;
            price?: number | null;
            trialLimits: {
              __typename?: 'PlanFeatureTrial';
              use: boolean;
              tier?: number | null;
              limit?: number | null;
            };
          }>;
        } | null;
      }> | null;
      messagingProfile?: {
        __typename?: 'MessagingProfile';
        id: string;
        allowUrl: boolean;
        a2pInfo?: {
          __typename?: 'A2PInfo';
          maxTpm: number;
          status: A2PInfoStatus;
          campaignId?: string | null;
          brandId?: string | null;
          statusUpdatedAt?: string | null;
          incorporationDate?: string | null;
        } | null;
        tollFreeInfo?: {
          __typename?: 'TollFreeInfo';
          status: TollFreeInfoStatus;
          maxTpm: number;
          limits: {
            __typename?: 'TollFreeInfoLimits';
            daily: number;
            weekly: number;
            monthly: number;
          };
        } | null;
        provider?: {
          __typename?: 'MessagingProvider';
          type: MessagingProviderType;
        } | null;
      } | null;
      billingProfile?: {
        __typename?: 'BillingProfile';
        balance?: {
          __typename?: 'Balance';
          balance: number;
          topUpAmount: number;
          lastTopUp?: {
            __typename?: 'LastTopUp';
            topUpAt: string;
            amount: number;
          } | null;
        } | null;
      } | null;
      summary: {
        __typename?: 'AccountSummary';
        totalSmsUsed?: number | null;
        totalSkiptraceUsed?: number | null;
        totalMembershipFees?: number | null;
        totalBilledToDate?: number | null;
        totalBilled?: number | null;
        productsFees?: Array<{
          __typename?: 'ProfileProductFees';
          amount: number;
          product: Product;
        }> | null;
        lastBilling?: {
          __typename?: 'LastBilling';
          amount: number;
          createdAt: string;
        } | null;
        trialPeriod?: {
          __typename?: 'Period';
          endedAt?: string | null;
          startedAt: string;
        } | null;
      };
    }>;
  };
};

export type AdminUpdateAccountMutationVariables = Exact<{
  data: AdminUpdateAccountInput;
  id: Scalars['String']['input'];
}>;

export type AdminUpdateAccountMutation = {
  __typename?: 'Mutation';
  adminUpdateAccount: { __typename?: 'Account'; id: string };
};

export type AdminDeletePhoneMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AdminDeletePhoneMutation = {
  __typename?: 'Mutation';
  adminDeletePhone: { __typename?: 'Account'; id: string };
};

export type AdminRemoveAccountMutationVariables = Exact<{
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type AdminRemoveAccountMutation = {
  __typename?: 'Mutation';
  adminRemoveAccount: { __typename?: 'OperationStatus'; success: boolean };
};

export type GetAccountQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetAccountQuery = {
  __typename?: 'Query';
  getAccount: {
    __typename?: 'Account';
    id: string;
    ownerId: string;
    status: AccountStatus;
    type: AccountType;
    email: string;
    phone?: string | null;
    isSubAccount: boolean;
    createdAt: string;
    isTest: boolean;
    isOnboarded: boolean;
    firestoreId?: string | null;
    companyName?: string | null;
    timeZone?: string | null;
    a2PStatus?: A2PInfoStatus | null;
    tollFreeStatus?: TollFreeInfoStatus | null;
    parentAccount?: {
      __typename?: 'Account';
      id: string;
      ownerId: string;
      status: AccountStatus;
      type: AccountType;
      email: string;
      isSubAccount: boolean;
      createdAt: string;
      isTest: boolean;
      isOnboarded: boolean;
      firestoreId?: string | null;
      companyName?: string | null;
      timeZone?: string | null;
      a2PStatus?: A2PInfoStatus | null;
      tollFreeStatus?: TollFreeInfoStatus | null;
      phone?: string | null;
    } | null;
    subAccounts?: Array<{ __typename?: 'Account'; id: string }> | null;
    permissions: {
      __typename?: 'AccountPermissionsView';
      optOutDisabled: boolean;
      allowOptOutDisable: boolean;
      allowCrmIntegrations: boolean;
      allowDripCampaign: boolean;
      allowKeywordCampaign: boolean;
      allowScheduleCampaign: boolean;
      allowThrottleCampaign: boolean;
    };
    settings?: {
      __typename?: 'AccountSettings';
      refreshRate?: number | null;
      openNewTab?: boolean | null;
    } | null;
    name?: {
      __typename?: 'Name';
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    questionnaire?: {
      __typename?: 'AccountQuestionnaire';
      realEstate?: string | null;
      experience?: string | null;
      marketingChannels?: Array<string> | null;
      marketingTraining?: string | null;
      smsMarketing?: string | null;
      provider?: string | null;
      employees?: string | null;
      role?: string | null;
      industry?: string | null;
    } | null;
    affiliate?: {
      __typename?: 'Affiliate';
      id: string;
      referralId: string;
      referralLink: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      createdAt: string;
    } | null;
    subscription?: {
      __typename?: 'CurrentSubscription';
      id: string;
      endAt?: string | null;
      membershipMonths?: number | null;
      trialEndedAt?: string | null;
      startedAt?: string | null;
      plan?: {
        __typename?: 'Plan';
        id: string;
        title: string;
        userTitle: string;
        type: PlanType;
      } | null;
    } | null;
    subscriptions?: Array<{
      __typename?: 'CurrentSubscription';
      id: string;
      title: string;
      product: Product;
      status: SubscriptionStatus;
      price: number;
      nextBillingDate?: string | null;
      startedAt?: string | null;
      endAt?: string | null;
      updatedAt?: string | null;
      trialEndedAt?: string | null;
      membershipMonths?: number | null;
      reason?: string | null;
      base: boolean;
      secondary: boolean;
      discount?: {
        __typename?: 'Discount';
        amount: number;
        coupon: string;
        percent?: number | null;
        promoCode?: string | null;
        startedAt?: string | null;
        expiresAt?: string | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        id: string;
        secondary: boolean;
        product: Product;
        active: boolean;
        enterprise: boolean;
        base: boolean;
        default: boolean;
        type: PlanType;
        interval: BillingInterval;
        title: string;
        userTitle: string;
        price: number;
        displayPrice: number;
        discount: number;
        mostPopular: boolean;
        monthlyPrice?: number | null;
        couponId?: string | null;
        features: Array<{
          __typename?: 'PlanFeature';
          type: FeatureType;
          title: string;
          tier?: number | null;
          limit?: number | null;
          min?: number | null;
          price?: number | null;
          trialLimits: {
            __typename?: 'PlanFeatureTrial';
            use: boolean;
            tier?: number | null;
            limit?: number | null;
          };
        }>;
      } | null;
    }> | null;
    messagingProfile?: {
      __typename?: 'MessagingProfile';
      id: string;
      allowUrl: boolean;
      a2pInfo?: {
        __typename?: 'A2PInfo';
        maxTpm: number;
        status: A2PInfoStatus;
        campaignId?: string | null;
        brandId?: string | null;
        statusUpdatedAt?: string | null;
        incorporationDate?: string | null;
      } | null;
      tollFreeInfo?: {
        __typename?: 'TollFreeInfo';
        status: TollFreeInfoStatus;
        maxTpm: number;
        limits: {
          __typename?: 'TollFreeInfoLimits';
          daily: number;
          weekly: number;
          monthly: number;
        };
      } | null;
      provider?: {
        __typename?: 'MessagingProvider';
        type: MessagingProviderType;
      } | null;
    } | null;
    billingProfile?: {
      __typename?: 'BillingProfile';
      balance?: {
        __typename?: 'Balance';
        balance: number;
        topUpAmount: number;
        lastTopUp?: {
          __typename?: 'LastTopUp';
          topUpAt: string;
          amount: number;
        } | null;
      } | null;
    } | null;
    summary: {
      __typename?: 'AccountSummary';
      totalSmsUsed?: number | null;
      totalSkiptraceUsed?: number | null;
      totalMembershipFees?: number | null;
      totalBilledToDate?: number | null;
      totalBilled?: number | null;
      productsFees?: Array<{
        __typename?: 'ProfileProductFees';
        amount: number;
        product: Product;
      }> | null;
      lastBilling?: {
        __typename?: 'LastBilling';
        amount: number;
        createdAt: string;
      } | null;
      trialPeriod?: {
        __typename?: 'Period';
        endedAt?: string | null;
        startedAt: string;
      } | null;
    };
  };
};

export type AdminUpdatePasswordMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AdminUpdatePasswordMutation = {
  __typename?: 'Mutation';
  adminUpdatePassword: { __typename?: 'AccountPassword'; password: string };
};

export type AdminTopUpBalanceMutationVariables = Exact<{
  input: TopUpAccountBalanceInput;
}>;

export type AdminTopUpBalanceMutation = {
  __typename?: 'Mutation';
  adminTopUpBalance: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateAccountSubscriptionMutationVariables = Exact<{
  input: UpdateSubscriptionInput;
  subscriptionId: Scalars['String']['input'];
  accountId: Scalars['String']['input'];
}>;

export type UpdateAccountSubscriptionMutation = {
  __typename?: 'Mutation';
  updateAccountSubscription: {
    __typename?: 'CurrentSubscription';
    id: string;
    endAt?: string | null;
    membershipMonths?: number | null;
    trialEndedAt?: string | null;
    startedAt?: string | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      userTitle: string;
      type: PlanType;
    } | null;
  };
};

export type AdminChangeAccountMessagingProviderMutationVariables = Exact<{
  input: AdminChangeAccountMessagingProviderInput;
}>;

export type AdminChangeAccountMessagingProviderMutation = {
  __typename?: 'Mutation';
  adminChangeAccountMessagingProvider: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type AdminChangeSubAccountMessagingProviderMutationVariables = Exact<{
  input: AdminChangeSubAccountMessagingProviderInput;
}>;

export type AdminChangeSubAccountMessagingProviderMutation = {
  __typename?: 'Mutation';
  adminChangeSubAccountMessagingProvider: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type UpdateA2PRegistrationExternalDataMutationVariables = Exact<{
  input: UpdateA2PRegistrationExternalDataInput;
  accountId: Scalars['String']['input'];
}>;

export type UpdateA2PRegistrationExternalDataMutation = {
  __typename?: 'Mutation';
  updateA2PRegistrationExternalData: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type AdminAddOwnPhoneMutationVariables = Exact<{
  input: AdminAddOwnPhoneInput;
}>;

export type AdminAddOwnPhoneMutation = {
  __typename?: 'Mutation';
  adminAddOwnPhone: { __typename?: 'OperationStatus'; success: boolean };
};

export type RegisterAffiliateMutationVariables = Exact<{
  input: RegisterAffiliateInput;
}>;

export type RegisterAffiliateMutation = {
  __typename?: 'Mutation';
  registerAffiliate: {
    __typename?: 'Affiliate';
    id: string;
    referralId: string;
    referralLink: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    createdAt: string;
  };
};

export type RegisterAffiliateLeadMutationVariables = Exact<{
  input: AdminRegisterAffiliateLeadInput;
}>;

export type RegisterAffiliateLeadMutation = {
  __typename?: 'Mutation';
  registerAffiliateLead: {
    __typename?: 'Affiliate';
    id: string;
    referralId: string;
    referralLink: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    createdAt: string;
  };
};

export type AffiliateFragment = {
  __typename?: 'Affiliate';
  id: string;
  referralId: string;
  referralLink: string;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  createdAt: string;
};

export type AffiliateQueryVariables = Exact<{ [key: string]: never }>;

export type AffiliateQuery = {
  __typename?: 'Query';
  affiliate: {
    __typename?: 'Affiliate';
    id: string;
    referralId: string;
    referralLink: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    createdAt: string;
  };
};

export type FindAffiliatesQueryVariables = Exact<{
  filter?: InputMaybe<AffiliateFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindAffiliatesQuery = {
  __typename?: 'Query';
  findAffiliates: {
    __typename?: 'AffiliateList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'Affiliate';
      id: string;
      referralId: string;
      referralLink: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      createdAt: string;
    }>;
  };
};

export type GetAffiliateQueryVariables = Exact<{
  getAffiliateId: Scalars['String']['input'];
}>;

export type GetAffiliateQuery = {
  __typename?: 'Query';
  getAffiliate: {
    __typename?: 'Affiliate';
    id: string;
    referralId: string;
    referralLink: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    createdAt: string;
  };
};

export type RemoveAffiliateMutationVariables = Exact<{
  removeAffiliateId: Scalars['String']['input'];
}>;

export type RemoveAffiliateMutation = {
  __typename?: 'Mutation';
  removeAffiliate: { __typename?: 'OperationStatus'; success: boolean };
};

export type ArticleFragment = {
  __typename?: 'Article';
  id: string;
  title: string;
  url: string;
  createdAt: string;
  updatedAt: string;
};

export type FindArticlesQueryVariables = Exact<{ [key: string]: never }>;

export type FindArticlesQuery = {
  __typename?: 'Query';
  findArticles: Array<{
    __typename?: 'Article';
    id: string;
    title: string;
    url: string;
    createdAt: string;
    updatedAt: string;
  }>;
};

export type PaymentFragment = {
  __typename?: 'Payment';
  invoiceUrl?: string | null;
  id: string;
  feature: PaymentFeature;
  description?: string | null;
  createdAt: string;
  amount?: number | null;
  type: PaymentType;
  method: PaymentMethod;
  purpose: PaymentPurpose;
  status: PaymentStatus;
};

export type PaymentSourceFragment = {
  __typename?: 'PaymentSource';
  id: string;
  brand?: string | null;
  expMonth?: number | null;
  expYear?: number | null;
  last4?: string | null;
  default?: boolean | null;
};

export type DownloadBillingReportQueryVariables = Exact<{
  filter?: InputMaybe<PaymentsFilter>;
}>;

export type DownloadBillingReportQuery = {
  __typename?: 'Query';
  downloadBillingReport: { __typename?: 'BillingReport'; url: string };
};

export type FindPaymentsQueryVariables = Exact<{
  filter?: InputMaybe<PaymentsFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindPaymentsQuery = {
  __typename?: 'Query';
  findPayments: {
    __typename?: 'PaymentList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'Payment';
      invoiceUrl?: string | null;
      id: string;
      feature: PaymentFeature;
      description?: string | null;
      createdAt: string;
      amount?: number | null;
      type: PaymentType;
      method: PaymentMethod;
      purpose: PaymentPurpose;
      status: PaymentStatus;
    }>;
  };
};

export type FindLatestPaymentQueryVariables = Exact<{
  filter?: InputMaybe<PaymentsFilter>;
}>;

export type FindLatestPaymentQuery = {
  __typename?: 'Query';
  findPayments: {
    __typename?: 'PaymentList';
    items: Array<{ __typename?: 'Payment'; createdAt: string }>;
  };
};

export type TopUpBalanceMutationVariables = Exact<{
  input: TopUpBalanceInput;
}>;

export type TopUpBalanceMutation = {
  __typename?: 'Mutation';
  topUpBalance: { __typename?: 'OperationStatus'; success: boolean };
};

export type SetDefaultPaymentSourceMutationVariables = Exact<{
  input: PaymentSourceInput;
}>;

export type SetDefaultPaymentSourceMutation = {
  __typename?: 'Mutation';
  setDefaultPaymentSource: { __typename?: 'OperationStatus'; success: boolean };
};

export type AddPaymentSourceMutationVariables = Exact<{
  input: PaymentSourceInput;
}>;

export type AddPaymentSourceMutation = {
  __typename?: 'Mutation';
  addPaymentSource: { __typename?: 'OperationStatus'; success: boolean };
};

export type RemovePaymentSourceMutationVariables = Exact<{
  input: PaymentSourceInput;
}>;

export type RemovePaymentSourceMutation = {
  __typename?: 'Mutation';
  removePaymentSource: { __typename?: 'OperationStatus'; success: boolean };
};

export type ScheduleBalanceMutationVariables = Exact<{
  input: ScheduleBalanceInput;
}>;

export type ScheduleBalanceMutation = {
  __typename?: 'Mutation';
  scheduleBalance: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateBillingProfileMutationVariables = Exact<{
  backupPaymentMethod: Scalars['Boolean']['input'];
}>;

export type UpdateBillingProfileMutation = {
  __typename?: 'Mutation';
  updateBillingProfile: { __typename?: 'OperationStatus'; success: boolean };
};

export type PayBillMutationVariables = Exact<{
  input: PayBillInput;
}>;

export type PayBillMutation = {
  __typename?: 'Mutation';
  payBill: { __typename?: 'OperationStatus'; success: boolean };
};

export type CalendarEventFragment = {
  __typename?: 'CalendarEvent';
  id: string;
  type: CalendarEventType;
  title: string;
  endAt: string;
  startAt: string;
  relationId?: string | null;
  description?: string | null;
};

export type FindClosestCalendarEventForRelationQueryVariables = Exact<{
  relationId: Scalars['String']['input'];
}>;

export type FindClosestCalendarEventForRelationQuery = {
  __typename?: 'Query';
  findClosestCalendarEventForRelation?: {
    __typename?: 'CalendarEvent';
    id: string;
    title: string;
    startAt: string;
    description?: string | null;
  } | null;
};

export type FindCalendarEventsQueryVariables = Exact<{
  filter?: InputMaybe<CalendarEventsFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindCalendarEventsQuery = {
  __typename?: 'Query';
  findCalendarEvents: {
    __typename?: 'CalendarEventList';
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    total?: number | null;
    items: Array<{
      __typename?: 'CalendarEvent';
      id: string;
      type: CalendarEventType;
      title: string;
      endAt: string;
      startAt: string;
      relationId?: string | null;
      description?: string | null;
    }>;
  };
};

export type CreateCalendarEventMutationVariables = Exact<{
  input: CreateCalendarEventInput;
}>;

export type CreateCalendarEventMutation = {
  __typename?: 'Mutation';
  createCalendarEvent: {
    __typename?: 'CalendarEvent';
    id: string;
    type: CalendarEventType;
    title: string;
    endAt: string;
    startAt: string;
    relationId?: string | null;
    description?: string | null;
  };
};

export type UpdateCalendarEventMutationVariables = Exact<{
  input: UpdateCalendarEventInput;
  id: Scalars['String']['input'];
}>;

export type UpdateCalendarEventMutation = {
  __typename?: 'Mutation';
  updateCalendarEvent: {
    __typename?: 'CalendarEvent';
    id: string;
    type: CalendarEventType;
    title: string;
    endAt: string;
    startAt: string;
    relationId?: string | null;
    description?: string | null;
  };
};

export type DeleteCalendarEventMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteCalendarEventMutation = {
  __typename?: 'Mutation';
  removeCalendarEvent: { __typename?: 'OperationStatus'; success: boolean };
};

export type GetCurrentCallQueryVariables = Exact<{
  contactId: Scalars['String']['input'];
}>;

export type GetCurrentCallQuery = {
  __typename?: 'Query';
  getCurrentCall: { __typename?: 'Message'; id: string };
};

export type ManageCallRecordingMutationVariables = Exact<{
  input: ManageCallRecordingInput;
}>;

export type ManageCallRecordingMutation = {
  __typename?: 'Mutation';
  manageCallRecording: { __typename?: 'OperationStatus'; success: boolean };
};

export type CallScriptFragment = {
  __typename?: 'CallScript';
  id: string;
  name: string;
  content: string;
  createdAt: string;
};

export type FindCallScriptsQueryVariables = Exact<{
  filter?: InputMaybe<CallScriptsFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindCallScriptsQuery = {
  __typename?: 'Query';
  findCallScripts: {
    __typename?: 'CallScriptList';
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    total?: number | null;
    items: Array<{
      __typename?: 'CallScript';
      id: string;
      name: string;
      content: string;
      createdAt: string;
    }>;
  };
};

export type GetCallScriptQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetCallScriptQuery = {
  __typename?: 'Query';
  getCallScript: {
    __typename?: 'CallScript';
    id: string;
    name: string;
    content: string;
    createdAt: string;
  };
};

export type CreateCallScriptMutationVariables = Exact<{
  input: CreateCallScriptInput;
}>;

export type CreateCallScriptMutation = {
  __typename?: 'Mutation';
  createCallScript: {
    __typename?: 'CallScript';
    id: string;
    name: string;
    content: string;
    createdAt: string;
  };
};

export type UpdateCallScriptMutationVariables = Exact<{
  input: UpdateCallScriptInput;
  updateCallScriptId: Scalars['String']['input'];
}>;

export type UpdateCallScriptMutation = {
  __typename?: 'Mutation';
  updateCallScript: {
    __typename?: 'CallScript';
    id: string;
    name: string;
    content: string;
    createdAt: string;
  };
};

export type RemoveCallScriptMutationVariables = Exact<{
  removeCallScriptId: Scalars['String']['input'];
}>;

export type RemoveCallScriptMutation = {
  __typename?: 'Mutation';
  removeCallScript: { __typename?: 'OperationStatus'; success: boolean };
};

export type RankCallScriptMutationVariables = Exact<{
  input: RankCallScriptInput;
  rankCallScriptId: Scalars['String']['input'];
}>;

export type RankCallScriptMutation = {
  __typename?: 'Mutation';
  rankCallScript: { __typename?: 'OperationStatus'; success: boolean };
};

export type SequenceFragmentFragment = {
  __typename?: 'SequenceView';
  type: SequenceType;
  delay: number;
  templates: Array<string>;
  content?: string | null;
  days: Array<number>;
  hasCustomTimeRestrictions: boolean;
  timeRange: {
    __typename?: 'TimeRange';
    from: { __typename?: 'HourMinuteView'; hour: number; minute: number };
    to: { __typename?: 'HourMinuteView'; hour: number; minute: number };
  };
  rule?: {
    __typename?: 'SequenceRule';
    removeFromNextSequences?: boolean | null;
    strategy?: CampaignRuleStrategy | null;
    groupId?: string | null;
    labelId?: string | null;
  } | null;
  progress?: {
    __typename?: 'SequenceProgress';
    sent: number;
    total: number;
  } | null;
};

export type StateFragmentFragment = {
  __typename?: 'CampaignState';
  status: CampaignStatus;
  reason?: {
    __typename?: 'StateReason';
    type: CampaignStateReasonType;
    ttl?: string | null;
  } | null;
};

export type KeywordTemplateFragmentFragment = {
  __typename?: 'KeywordTemplate';
  id: string;
  keyword: string;
  template: {
    __typename?: 'KeywordTemplateTemplate';
    content?: string | null;
    optOut?: boolean | null;
  };
};

export type GroupFragmentFragment = {
  __typename?: 'GroupView';
  id: string;
  name: string;
  verified: boolean;
  createdAt: string;
  contacts: number;
  activeChats: number;
  canBeCloned: boolean;
  canBeRemoved: boolean;
};

export type CostFragmentFragment = {
  __typename?: 'CampaignCosts';
  captured: number;
  refunded: number;
};

export type StatsFragmentFragment = {
  __typename?: 'CampaignStats';
  smsUsed?: number | null;
  smsRefunded?: number | null;
};

export type ScheduleOptionsFragment = {
  __typename?: 'CampaignScheduleOptions';
  startAt?: string | null;
  perBatch?: number | null;
  frequency?: Frequency | null;
  interval?: number | null;
  hasCustomBatchSize?: boolean | null;
  hasCustomStartDate?: boolean | null;
  timezone?: string | null;
};

export type CampaignFragmentFragment = {
  __typename?: 'Campaign';
  id: string;
  type: CampaignType;
  name: string;
  clickThroughProgress?: number | null;
  createdAt: string;
  firestoreId?: string | null;
  lastSentAt?: string | null;
  contacts: number;
  sendFirstMessage?: boolean | null;
  expiresAt?: string | null;
  clickThroughStartAfter?: {
    __typename?: 'ClickThroughStartAfterView';
    id: string;
    createdAt: string;
  } | null;
  ownPhones: Array<{
    __typename?: 'OwnPhone';
    id: string;
    status: OwnPhoneStatus;
    type: OwnPhoneType;
    number: string;
    isTrial: boolean;
    createdAt: string;
    location?: {
      __typename?: 'PhoneNumberLocation';
      abbreviation: string;
      state: string;
    } | null;
  }>;
  state: {
    __typename?: 'CampaignState';
    status: CampaignStatus;
    reason?: {
      __typename?: 'StateReason';
      type: CampaignStateReasonType;
      ttl?: string | null;
    } | null;
  };
  sequences: Array<{
    __typename?: 'SequenceView';
    type: SequenceType;
    delay: number;
    templates: Array<string>;
    content?: string | null;
    days: Array<number>;
    hasCustomTimeRestrictions: boolean;
    timeRange: {
      __typename?: 'TimeRange';
      from: { __typename?: 'HourMinuteView'; hour: number; minute: number };
      to: { __typename?: 'HourMinuteView'; hour: number; minute: number };
    };
    rule?: {
      __typename?: 'SequenceRule';
      removeFromNextSequences?: boolean | null;
      strategy?: CampaignRuleStrategy | null;
      groupId?: string | null;
      labelId?: string | null;
    } | null;
    progress?: {
      __typename?: 'SequenceProgress';
      sent: number;
      total: number;
    } | null;
  }>;
  groups?: Array<{
    __typename?: 'GroupView';
    id: string;
    name: string;
    verified: boolean;
    createdAt: string;
    contacts: number;
    activeChats: number;
    canBeCloned: boolean;
    canBeRemoved: boolean;
  }> | null;
  keyword?: {
    __typename?: 'KeywordTemplate';
    id: string;
    keyword: string;
    template: {
      __typename?: 'KeywordTemplateTemplate';
      content?: string | null;
      optOut?: boolean | null;
    };
  } | null;
  costs?: {
    __typename?: 'CampaignCosts';
    captured: number;
    refunded: number;
  } | null;
  stats?: {
    __typename?: 'CampaignStats';
    smsUsed?: number | null;
    smsRefunded?: number | null;
  } | null;
  scheduleOptions: {
    __typename?: 'CampaignScheduleOptions';
    startAt?: string | null;
    perBatch?: number | null;
    frequency?: Frequency | null;
    interval?: number | null;
    hasCustomBatchSize?: boolean | null;
    hasCustomStartDate?: boolean | null;
    timezone?: string | null;
  };
  rule?: {
    __typename?: 'CampaignRule';
    removeFromNextSequences?: boolean | null;
    strategy?: CampaignRuleStrategy | null;
    groupId?: string | null;
    labelId?: string | null;
  } | null;
};

export type CampaignScheduledMessageFragment = {
  __typename?: 'ScheduledMessage';
  id: string;
  content?: string | null;
  createdAt: string;
  scheduledAt?: string | null;
  template?: {
    __typename?: 'MessageTemplate';
    name: string;
    id: string;
    template: {
      __typename?: 'MessageTemplateTemplate';
      optOut?: boolean | null;
    };
  } | null;
  contact?: {
    __typename?: 'Contact';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    phone: string;
    email?: string | null;
    attrs?: Array<{
      __typename?: 'ContactAttribute';
      fieldId: string;
      value: string;
    }> | null;
  } | null;
};

export type FindCampaignsQueryVariables = Exact<{
  filter?: InputMaybe<CampaignFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindCampaignsQuery = {
  __typename?: 'Query';
  findCampaigns: {
    __typename?: 'CampaignList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'Campaign';
      id: string;
      type: CampaignType;
      name: string;
      clickThroughProgress?: number | null;
      createdAt: string;
      firestoreId?: string | null;
      lastSentAt?: string | null;
      contacts: number;
      sendFirstMessage?: boolean | null;
      expiresAt?: string | null;
      clickThroughStartAfter?: {
        __typename?: 'ClickThroughStartAfterView';
        id: string;
        createdAt: string;
      } | null;
      ownPhones: Array<{
        __typename?: 'OwnPhone';
        id: string;
        status: OwnPhoneStatus;
        type: OwnPhoneType;
        number: string;
        isTrial: boolean;
        createdAt: string;
        location?: {
          __typename?: 'PhoneNumberLocation';
          abbreviation: string;
          state: string;
        } | null;
      }>;
      state: {
        __typename?: 'CampaignState';
        status: CampaignStatus;
        reason?: {
          __typename?: 'StateReason';
          type: CampaignStateReasonType;
          ttl?: string | null;
        } | null;
      };
      sequences: Array<{
        __typename?: 'SequenceView';
        type: SequenceType;
        delay: number;
        templates: Array<string>;
        content?: string | null;
        days: Array<number>;
        hasCustomTimeRestrictions: boolean;
        timeRange: {
          __typename?: 'TimeRange';
          from: { __typename?: 'HourMinuteView'; hour: number; minute: number };
          to: { __typename?: 'HourMinuteView'; hour: number; minute: number };
        };
        rule?: {
          __typename?: 'SequenceRule';
          removeFromNextSequences?: boolean | null;
          strategy?: CampaignRuleStrategy | null;
          groupId?: string | null;
          labelId?: string | null;
        } | null;
        progress?: {
          __typename?: 'SequenceProgress';
          sent: number;
          total: number;
        } | null;
      }>;
      groups?: Array<{
        __typename?: 'GroupView';
        id: string;
        name: string;
        verified: boolean;
        createdAt: string;
        contacts: number;
        activeChats: number;
        canBeCloned: boolean;
        canBeRemoved: boolean;
      }> | null;
      keyword?: {
        __typename?: 'KeywordTemplate';
        id: string;
        keyword: string;
        template: {
          __typename?: 'KeywordTemplateTemplate';
          content?: string | null;
          optOut?: boolean | null;
        };
      } | null;
      costs?: {
        __typename?: 'CampaignCosts';
        captured: number;
        refunded: number;
      } | null;
      stats?: {
        __typename?: 'CampaignStats';
        smsUsed?: number | null;
        smsRefunded?: number | null;
      } | null;
      scheduleOptions: {
        __typename?: 'CampaignScheduleOptions';
        startAt?: string | null;
        perBatch?: number | null;
        frequency?: Frequency | null;
        interval?: number | null;
        hasCustomBatchSize?: boolean | null;
        hasCustomStartDate?: boolean | null;
        timezone?: string | null;
      };
      rule?: {
        __typename?: 'CampaignRule';
        removeFromNextSequences?: boolean | null;
        strategy?: CampaignRuleStrategy | null;
        groupId?: string | null;
        labelId?: string | null;
      } | null;
    }>;
  };
};

export type FindListCampaignsQueryVariables = Exact<{
  filter?: InputMaybe<CampaignFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindListCampaignsQuery = {
  __typename?: 'Query';
  findCampaigns: {
    __typename?: 'CampaignList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'Campaign';
      id: string;
      type: CampaignType;
      name: string;
      clickThroughProgress?: number | null;
      createdAt: string;
      firestoreId?: string | null;
      lastSentAt?: string | null;
      contacts: number;
      sendFirstMessage?: boolean | null;
      expiresAt?: string | null;
      clickThroughStartAfter?: {
        __typename?: 'ClickThroughStartAfterView';
        id: string;
        createdAt: string;
      } | null;
      ownPhones: Array<{
        __typename?: 'OwnPhone';
        id: string;
        status: OwnPhoneStatus;
        type: OwnPhoneType;
        number: string;
        isTrial: boolean;
        createdAt: string;
        location?: {
          __typename?: 'PhoneNumberLocation';
          abbreviation: string;
          state: string;
        } | null;
      }>;
      state: {
        __typename?: 'CampaignState';
        status: CampaignStatus;
        reason?: {
          __typename?: 'StateReason';
          type: CampaignStateReasonType;
          ttl?: string | null;
        } | null;
      };
      sequences: Array<{
        __typename?: 'SequenceView';
        type: SequenceType;
        delay: number;
        templates: Array<string>;
        content?: string | null;
        days: Array<number>;
        hasCustomTimeRestrictions: boolean;
        timeRange: {
          __typename?: 'TimeRange';
          from: { __typename?: 'HourMinuteView'; hour: number; minute: number };
          to: { __typename?: 'HourMinuteView'; hour: number; minute: number };
        };
        rule?: {
          __typename?: 'SequenceRule';
          removeFromNextSequences?: boolean | null;
          strategy?: CampaignRuleStrategy | null;
          groupId?: string | null;
          labelId?: string | null;
        } | null;
        progress?: {
          __typename?: 'SequenceProgress';
          sent: number;
          total: number;
        } | null;
      }>;
      groups?: Array<{
        __typename?: 'GroupView';
        id: string;
        name: string;
        verified: boolean;
        createdAt: string;
        contacts: number;
        activeChats: number;
        canBeCloned: boolean;
        canBeRemoved: boolean;
      }> | null;
      keyword?: {
        __typename?: 'KeywordTemplate';
        id: string;
        keyword: string;
        template: {
          __typename?: 'KeywordTemplateTemplate';
          content?: string | null;
          optOut?: boolean | null;
        };
      } | null;
      costs?: {
        __typename?: 'CampaignCosts';
        captured: number;
        refunded: number;
      } | null;
      stats?: {
        __typename?: 'CampaignStats';
        smsUsed?: number | null;
        smsRefunded?: number | null;
      } | null;
      scheduleOptions: {
        __typename?: 'CampaignScheduleOptions';
        startAt?: string | null;
        perBatch?: number | null;
        frequency?: Frequency | null;
        interval?: number | null;
        hasCustomBatchSize?: boolean | null;
        hasCustomStartDate?: boolean | null;
        timezone?: string | null;
      };
      rule?: {
        __typename?: 'CampaignRule';
        removeFromNextSequences?: boolean | null;
        strategy?: CampaignRuleStrategy | null;
        groupId?: string | null;
        labelId?: string | null;
      } | null;
    }>;
  };
};

export type GetCampaignsTotalQueryVariables = Exact<{
  filter?: InputMaybe<CampaignFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type GetCampaignsTotalQuery = {
  __typename?: 'Query';
  findCampaigns: { __typename?: 'CampaignList'; total?: number | null };
};

export type GetCampaignsCountersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCampaignsCountersQuery = {
  __typename?: 'Query';
  getCampaignsCounters: {
    __typename?: 'CampaignsAggregationView';
    keyword: {
      __typename?: 'CampaignsAggregationCountersView';
      active: number;
      draft: number;
      deleted: number;
      total: number;
    };
    standard: {
      __typename?: 'CampaignsAggregationCountersView';
      active: number;
      draft: number;
      deleted: number;
      total: number;
    };
  };
};

export type GetCampaignQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetCampaignQuery = {
  __typename?: 'Query';
  getCampaign: {
    __typename?: 'Campaign';
    id: string;
    type: CampaignType;
    name: string;
    clickThroughProgress?: number | null;
    createdAt: string;
    firestoreId?: string | null;
    lastSentAt?: string | null;
    contacts: number;
    sendFirstMessage?: boolean | null;
    expiresAt?: string | null;
    clickThroughStartAfter?: {
      __typename?: 'ClickThroughStartAfterView';
      id: string;
      createdAt: string;
    } | null;
    ownPhones: Array<{
      __typename?: 'OwnPhone';
      id: string;
      status: OwnPhoneStatus;
      type: OwnPhoneType;
      number: string;
      isTrial: boolean;
      createdAt: string;
      location?: {
        __typename?: 'PhoneNumberLocation';
        abbreviation: string;
        state: string;
      } | null;
    }>;
    state: {
      __typename?: 'CampaignState';
      status: CampaignStatus;
      reason?: {
        __typename?: 'StateReason';
        type: CampaignStateReasonType;
        ttl?: string | null;
      } | null;
    };
    sequences: Array<{
      __typename?: 'SequenceView';
      type: SequenceType;
      delay: number;
      templates: Array<string>;
      content?: string | null;
      days: Array<number>;
      hasCustomTimeRestrictions: boolean;
      timeRange: {
        __typename?: 'TimeRange';
        from: { __typename?: 'HourMinuteView'; hour: number; minute: number };
        to: { __typename?: 'HourMinuteView'; hour: number; minute: number };
      };
      rule?: {
        __typename?: 'SequenceRule';
        removeFromNextSequences?: boolean | null;
        strategy?: CampaignRuleStrategy | null;
        groupId?: string | null;
        labelId?: string | null;
      } | null;
      progress?: {
        __typename?: 'SequenceProgress';
        sent: number;
        total: number;
      } | null;
    }>;
    groups?: Array<{
      __typename?: 'GroupView';
      id: string;
      name: string;
      verified: boolean;
      createdAt: string;
      contacts: number;
      activeChats: number;
      canBeCloned: boolean;
      canBeRemoved: boolean;
    }> | null;
    keyword?: {
      __typename?: 'KeywordTemplate';
      id: string;
      keyword: string;
      template: {
        __typename?: 'KeywordTemplateTemplate';
        content?: string | null;
        optOut?: boolean | null;
      };
    } | null;
    costs?: {
      __typename?: 'CampaignCosts';
      captured: number;
      refunded: number;
    } | null;
    stats?: {
      __typename?: 'CampaignStats';
      smsUsed?: number | null;
      smsRefunded?: number | null;
    } | null;
    scheduleOptions: {
      __typename?: 'CampaignScheduleOptions';
      startAt?: string | null;
      perBatch?: number | null;
      frequency?: Frequency | null;
      interval?: number | null;
      hasCustomBatchSize?: boolean | null;
      hasCustomStartDate?: boolean | null;
      timezone?: string | null;
    };
    rule?: {
      __typename?: 'CampaignRule';
      removeFromNextSequences?: boolean | null;
      strategy?: CampaignRuleStrategy | null;
      groupId?: string | null;
      labelId?: string | null;
    } | null;
  };
};

export type ExportCampaignToFileQueryVariables = Exact<{
  input: ExportCampaignToFileInput;
}>;

export type ExportCampaignToFileQuery = {
  __typename?: 'Query';
  exportCampaignToFile: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type GetCampaignScheduleQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<CampaignScheduleOptionsInput>;
}>;

export type GetCampaignScheduleQuery = {
  __typename?: 'Query';
  getCampaignSchedule: {
    __typename?: 'CampaignSchedule';
    startTime: number;
    endTime: number;
    nextTime?: number | null;
  };
};

export type FindCampaignMessagesQueryVariables = Exact<{
  filter?: InputMaybe<ScheduledMessageFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindCampaignMessagesQuery = {
  __typename?: 'Query';
  findCampaignMessages: {
    __typename?: 'ScheduledMessageList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'ScheduledMessage';
      id: string;
      content?: string | null;
      createdAt: string;
      scheduledAt?: string | null;
      template?: {
        __typename?: 'MessageTemplate';
        name: string;
        id: string;
        template: {
          __typename?: 'MessageTemplateTemplate';
          optOut?: boolean | null;
        };
      } | null;
      contact?: {
        __typename?: 'Contact';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        phone: string;
        email?: string | null;
        attrs?: Array<{
          __typename?: 'ContactAttribute';
          fieldId: string;
          value: string;
        }> | null;
      } | null;
    }>;
  };
};

export type CreateCampaignMutationVariables = Exact<{
  input: CreateCampaignInput;
}>;

export type CreateCampaignMutation = {
  __typename?: 'Mutation';
  createCampaign: {
    __typename?: 'Campaign';
    id: string;
    type: CampaignType;
    name: string;
    clickThroughProgress?: number | null;
    createdAt: string;
    firestoreId?: string | null;
    lastSentAt?: string | null;
    contacts: number;
    sendFirstMessage?: boolean | null;
    expiresAt?: string | null;
    clickThroughStartAfter?: {
      __typename?: 'ClickThroughStartAfterView';
      id: string;
      createdAt: string;
    } | null;
    ownPhones: Array<{
      __typename?: 'OwnPhone';
      id: string;
      status: OwnPhoneStatus;
      type: OwnPhoneType;
      number: string;
      isTrial: boolean;
      createdAt: string;
      location?: {
        __typename?: 'PhoneNumberLocation';
        abbreviation: string;
        state: string;
      } | null;
    }>;
    state: {
      __typename?: 'CampaignState';
      status: CampaignStatus;
      reason?: {
        __typename?: 'StateReason';
        type: CampaignStateReasonType;
        ttl?: string | null;
      } | null;
    };
    sequences: Array<{
      __typename?: 'SequenceView';
      type: SequenceType;
      delay: number;
      templates: Array<string>;
      content?: string | null;
      days: Array<number>;
      hasCustomTimeRestrictions: boolean;
      timeRange: {
        __typename?: 'TimeRange';
        from: { __typename?: 'HourMinuteView'; hour: number; minute: number };
        to: { __typename?: 'HourMinuteView'; hour: number; minute: number };
      };
      rule?: {
        __typename?: 'SequenceRule';
        removeFromNextSequences?: boolean | null;
        strategy?: CampaignRuleStrategy | null;
        groupId?: string | null;
        labelId?: string | null;
      } | null;
      progress?: {
        __typename?: 'SequenceProgress';
        sent: number;
        total: number;
      } | null;
    }>;
    groups?: Array<{
      __typename?: 'GroupView';
      id: string;
      name: string;
      verified: boolean;
      createdAt: string;
      contacts: number;
      activeChats: number;
      canBeCloned: boolean;
      canBeRemoved: boolean;
    }> | null;
    keyword?: {
      __typename?: 'KeywordTemplate';
      id: string;
      keyword: string;
      template: {
        __typename?: 'KeywordTemplateTemplate';
        content?: string | null;
        optOut?: boolean | null;
      };
    } | null;
    costs?: {
      __typename?: 'CampaignCosts';
      captured: number;
      refunded: number;
    } | null;
    stats?: {
      __typename?: 'CampaignStats';
      smsUsed?: number | null;
      smsRefunded?: number | null;
    } | null;
    scheduleOptions: {
      __typename?: 'CampaignScheduleOptions';
      startAt?: string | null;
      perBatch?: number | null;
      frequency?: Frequency | null;
      interval?: number | null;
      hasCustomBatchSize?: boolean | null;
      hasCustomStartDate?: boolean | null;
      timezone?: string | null;
    };
    rule?: {
      __typename?: 'CampaignRule';
      removeFromNextSequences?: boolean | null;
      strategy?: CampaignRuleStrategy | null;
      groupId?: string | null;
      labelId?: string | null;
    } | null;
  };
};

export type UpdateCampaignMutationVariables = Exact<{
  input: UpdateCampaignInput;
  id: Scalars['String']['input'];
}>;

export type UpdateCampaignMutation = {
  __typename?: 'Mutation';
  updateCampaign: {
    __typename?: 'Campaign';
    id: string;
    type: CampaignType;
    name: string;
    clickThroughProgress?: number | null;
    createdAt: string;
    firestoreId?: string | null;
    lastSentAt?: string | null;
    contacts: number;
    sendFirstMessage?: boolean | null;
    expiresAt?: string | null;
    clickThroughStartAfter?: {
      __typename?: 'ClickThroughStartAfterView';
      id: string;
      createdAt: string;
    } | null;
    ownPhones: Array<{
      __typename?: 'OwnPhone';
      id: string;
      status: OwnPhoneStatus;
      type: OwnPhoneType;
      number: string;
      isTrial: boolean;
      createdAt: string;
      location?: {
        __typename?: 'PhoneNumberLocation';
        abbreviation: string;
        state: string;
      } | null;
    }>;
    state: {
      __typename?: 'CampaignState';
      status: CampaignStatus;
      reason?: {
        __typename?: 'StateReason';
        type: CampaignStateReasonType;
        ttl?: string | null;
      } | null;
    };
    sequences: Array<{
      __typename?: 'SequenceView';
      type: SequenceType;
      delay: number;
      templates: Array<string>;
      content?: string | null;
      days: Array<number>;
      hasCustomTimeRestrictions: boolean;
      timeRange: {
        __typename?: 'TimeRange';
        from: { __typename?: 'HourMinuteView'; hour: number; minute: number };
        to: { __typename?: 'HourMinuteView'; hour: number; minute: number };
      };
      rule?: {
        __typename?: 'SequenceRule';
        removeFromNextSequences?: boolean | null;
        strategy?: CampaignRuleStrategy | null;
        groupId?: string | null;
        labelId?: string | null;
      } | null;
      progress?: {
        __typename?: 'SequenceProgress';
        sent: number;
        total: number;
      } | null;
    }>;
    groups?: Array<{
      __typename?: 'GroupView';
      id: string;
      name: string;
      verified: boolean;
      createdAt: string;
      contacts: number;
      activeChats: number;
      canBeCloned: boolean;
      canBeRemoved: boolean;
    }> | null;
    keyword?: {
      __typename?: 'KeywordTemplate';
      id: string;
      keyword: string;
      template: {
        __typename?: 'KeywordTemplateTemplate';
        content?: string | null;
        optOut?: boolean | null;
      };
    } | null;
    costs?: {
      __typename?: 'CampaignCosts';
      captured: number;
      refunded: number;
    } | null;
    stats?: {
      __typename?: 'CampaignStats';
      smsUsed?: number | null;
      smsRefunded?: number | null;
    } | null;
    scheduleOptions: {
      __typename?: 'CampaignScheduleOptions';
      startAt?: string | null;
      perBatch?: number | null;
      frequency?: Frequency | null;
      interval?: number | null;
      hasCustomBatchSize?: boolean | null;
      hasCustomStartDate?: boolean | null;
      timezone?: string | null;
    };
    rule?: {
      __typename?: 'CampaignRule';
      removeFromNextSequences?: boolean | null;
      strategy?: CampaignRuleStrategy | null;
      groupId?: string | null;
      labelId?: string | null;
    } | null;
  };
};

export type RemoveCampaignMutationVariables = Exact<{
  input: RemoveCampaignInput;
}>;

export type RemoveCampaignMutation = {
  __typename?: 'Mutation';
  removeCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type ExportCampaignToGroupQueryVariables = Exact<{
  input: ExportCampaignToGroupInput;
  id: Scalars['String']['input'];
}>;

export type ExportCampaignToGroupQuery = {
  __typename?: 'Query';
  exportCampaignToGroup: { __typename?: 'GroupView'; id: string };
};

export type StartCampaignMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type StartCampaignMutation = {
  __typename?: 'Mutation';
  startCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type PauseCampaignMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type PauseCampaignMutation = {
  __typename?: 'Mutation';
  pauseCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type ResumeCampaignMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type ResumeCampaignMutation = {
  __typename?: 'Mutation';
  resumeCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateCampaignDynamicDataMutationVariables = Exact<{
  input: CampaignDynamicDataInput;
  id: Scalars['String']['input'];
}>;

export type UpdateCampaignDynamicDataMutation = {
  __typename?: 'Mutation';
  updateCampaignDynamicData: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type UpdateScheduledMessageMutationVariables = Exact<{
  input: UpdateScheduledMessageInput;
  id: Scalars['String']['input'];
}>;

export type UpdateScheduledMessageMutation = {
  __typename?: 'Mutation';
  updateScheduledMessage: {
    __typename?: 'ScheduledMessage';
    id: string;
    content?: string | null;
    createdAt: string;
    scheduledAt?: string | null;
    template?: {
      __typename?: 'MessageTemplate';
      name: string;
      id: string;
      template: {
        __typename?: 'MessageTemplateTemplate';
        optOut?: boolean | null;
      };
    } | null;
    contact?: {
      __typename?: 'Contact';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      phone: string;
      email?: string | null;
      attrs?: Array<{
        __typename?: 'ContactAttribute';
        fieldId: string;
        value: string;
      }> | null;
    } | null;
  };
};

export type RefreshCampaignMessagesMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RefreshCampaignMessagesMutation = {
  __typename?: 'Mutation';
  refreshCampaignMessages: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateCampaignOwnPhonesMutationVariables = Exact<{
  input: UpdateCampaignOwnPhonesInput;
  updateCampaignOwnPhonesId: Scalars['String']['input'];
}>;

export type UpdateCampaignOwnPhonesMutation = {
  __typename?: 'Mutation';
  updateCampaignOwnPhones: { __typename?: 'OperationStatus'; success: boolean };
};

export type CampaignUpsertedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type CampaignUpsertedSubscription = {
  __typename?: 'Subscription';
  campaignUpserted: {
    __typename?: 'ChangesItemView';
    operation: ChangesOperation;
    entityId: string;
  };
};

export type CampaignProgressUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type CampaignProgressUpdatedSubscription = {
  __typename?: 'Subscription';
  campaignProgressUpdated: {
    __typename?: 'CampaignProgressUpdated';
    campaignId: string;
    progress: Array<{
      __typename?: 'SequenceProgress';
      sent: number;
      total: number;
    }>;
  };
};

export type ContactNoteFragment = {
  __typename?: 'ContactNote';
  id: string;
  text: string;
  attachments?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
};

export type GetContactNoteSignedUrlQueryVariables = Exact<{
  input: GenerateSignedUrlInput;
}>;

export type GetContactNoteSignedUrlQuery = {
  __typename?: 'Query';
  getContactNoteSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type FindContactNotesQueryVariables = Exact<{
  filter: ContactNoteFilter;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindContactNotesQuery = {
  __typename?: 'Query';
  findContactNotes: {
    __typename?: 'ContactNoteList';
    hasNext?: boolean | null;
    nextCreatedAt?: string | null;
    total?: number | null;
    nextId?: string | null;
    items: Array<{
      __typename?: 'ContactNote';
      id: string;
      text: string;
      attachments?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
    }>;
  };
};

export type CreateContactNoteMutationVariables = Exact<{
  input: CreateContactNoteInput;
  contactId: Scalars['String']['input'];
}>;

export type CreateContactNoteMutation = {
  __typename?: 'Mutation';
  createContactNote: {
    __typename?: 'ContactNote';
    id: string;
    text: string;
    attachments?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type UpdateContactNoteMutationVariables = Exact<{
  input: UpdateContactNoteInput;
  noteId: Scalars['String']['input'];
  contactId: Scalars['String']['input'];
}>;

export type UpdateContactNoteMutation = {
  __typename?: 'Mutation';
  updateContactNote: {
    __typename?: 'ContactNote';
    id: string;
    text: string;
    attachments?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type RemoveContactNoteMutationVariables = Exact<{
  noteId: Scalars['String']['input'];
  contactId: Scalars['String']['input'];
}>;

export type RemoveContactNoteMutation = {
  __typename?: 'Mutation';
  removeContactNote: { __typename?: 'OperationStatus'; success: boolean };
};

export type ContactAttributeFragment = {
  __typename?: 'ContactAttribute';
  fieldId: string;
  value: string;
};

export type RealEstateAttrsFragment = {
  __typename?: 'ContactRealEstateAttribute';
  id: RealEstateAttribute;
  value: string;
};

export type ContactFragment = {
  __typename?: 'Contact';
  id: string;
  status: ContactStatus;
  createdAt: string;
  phone: string;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  favorite?: boolean | null;
  blocked?: boolean | null;
  unsubscribed?: boolean | null;
  dealClosed?: boolean | null;
  labels?: Array<{
    __typename?: 'Label';
    id: string;
    title: string;
    color: string;
    default: boolean;
    scopes: Array<Scope>;
    readOnly: boolean;
    createdAt: string;
    firestoreId: string;
  }> | null;
  groups?: Array<{ __typename?: 'GroupView'; id: string; name: string }> | null;
  attrs?: Array<{
    __typename?: 'ContactAttribute';
    fieldId: string;
    value: string;
  }> | null;
  realEstateAttrs?: {
    __typename?: 'ContactRealEstateAttributes';
    provider: string;
    attrs: Array<{
      __typename?: 'ContactRealEstateAttribute';
      id: RealEstateAttribute;
      value: string;
    }>;
  } | null;
};

export type ContactPreviewFragment = {
  __typename?: 'Contact';
  id: string;
  createdAt: string;
  phone: string;
  firstName?: string | null;
  lastName?: string | null;
  favorite?: boolean | null;
  dealClosed?: boolean | null;
  canBeRemoved: boolean;
  status: ContactStatus;
  labels?: Array<{
    __typename?: 'Label';
    id: string;
    title: string;
    color: string;
    createdAt: string;
    default: boolean;
    firestoreId: string;
    readOnly: boolean;
    scopes: Array<Scope>;
  }> | null;
  groups?: Array<{ __typename?: 'GroupView'; id: string; name: string }> | null;
};

export type GetContactsCountersQueryVariables = Exact<{ [key: string]: never }>;

export type GetContactsCountersQuery = {
  __typename?: 'Query';
  getContactsCounters: {
    __typename?: 'ContactCountersView';
    activeGroups: number;
    activeContacts: number;
    deletedContacts: number;
  };
};

export type FindContactsQueryVariables = Exact<{
  filter?: InputMaybe<ContactFilter>;
  pagination?: InputMaybe<Pagination>;
  order?: InputMaybe<ContactOrder>;
}>;

export type FindContactsQuery = {
  __typename?: 'Query';
  findContacts: {
    __typename?: 'ContactList';
    hasNext?: boolean | null;
    nextCreatedAt?: string | null;
    total?: number | null;
    nextId?: string | null;
    items: Array<{
      __typename?: 'Contact';
      id: string;
      createdAt: string;
      phone: string;
      firstName?: string | null;
      lastName?: string | null;
      favorite?: boolean | null;
      dealClosed?: boolean | null;
      canBeRemoved: boolean;
      status: ContactStatus;
      labels?: Array<{
        __typename?: 'Label';
        id: string;
        title: string;
        color: string;
        createdAt: string;
        default: boolean;
        firestoreId: string;
        readOnly: boolean;
        scopes: Array<Scope>;
      }> | null;
      groups?: Array<{
        __typename?: 'GroupView';
        id: string;
        name: string;
      }> | null;
    }>;
  };
};

export type CountContactsQueryVariables = Exact<{
  filter?: InputMaybe<ContactFilter>;
  pagination?: InputMaybe<Pagination>;
  order?: InputMaybe<ContactOrder>;
}>;

export type CountContactsQuery = {
  __typename?: 'Query';
  findContacts: { __typename?: 'ContactList'; total?: number | null };
};

export type FindContactsPhonesQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<ContactFilter>;
}>;

export type FindContactsPhonesQuery = {
  __typename?: 'Query';
  findContacts: {
    __typename?: 'ContactList';
    hasNext?: boolean | null;
    nextCreatedAt?: string | null;
    total?: number | null;
    nextId?: string | null;
    items: Array<{
      __typename?: 'Contact';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      phone: string;
    }>;
  };
};

export type GetContactQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetContactQuery = {
  __typename?: 'Query';
  getContact: {
    __typename?: 'Contact';
    id: string;
    status: ContactStatus;
    createdAt: string;
    phone: string;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    favorite?: boolean | null;
    blocked?: boolean | null;
    unsubscribed?: boolean | null;
    dealClosed?: boolean | null;
    labels?: Array<{
      __typename?: 'Label';
      id: string;
      title: string;
      color: string;
      default: boolean;
      scopes: Array<Scope>;
      readOnly: boolean;
      createdAt: string;
      firestoreId: string;
    }> | null;
    groups?: Array<{
      __typename?: 'GroupView';
      id: string;
      name: string;
    }> | null;
    attrs?: Array<{
      __typename?: 'ContactAttribute';
      fieldId: string;
      value: string;
    }> | null;
    realEstateAttrs?: {
      __typename?: 'ContactRealEstateAttributes';
      provider: string;
      attrs: Array<{
        __typename?: 'ContactRealEstateAttribute';
        id: RealEstateAttribute;
        value: string;
      }>;
    } | null;
  };
};

export type GetContactPhoneQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetContactPhoneQuery = {
  __typename?: 'Query';
  getContact: {
    __typename?: 'Contact';
    phone: string;
    firstName?: string | null;
    lastName?: string | null;
  };
};

export type GetContactNotesQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetContactNotesQuery = {
  __typename?: 'Query';
  getContact: {
    __typename?: 'Contact';
    notes: Array<{
      __typename?: 'ContactNote';
      id: string;
      text: string;
      attachments?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
    }>;
  };
};

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;

export type CreateContactMutation = {
  __typename?: 'Mutation';
  createContact: {
    __typename?: 'Contact';
    id: string;
    status: ContactStatus;
    createdAt: string;
    phone: string;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    favorite?: boolean | null;
    blocked?: boolean | null;
    unsubscribed?: boolean | null;
    dealClosed?: boolean | null;
    labels?: Array<{
      __typename?: 'Label';
      id: string;
      title: string;
      color: string;
      default: boolean;
      scopes: Array<Scope>;
      readOnly: boolean;
      createdAt: string;
      firestoreId: string;
    }> | null;
    groups?: Array<{
      __typename?: 'GroupView';
      id: string;
      name: string;
    }> | null;
    attrs?: Array<{
      __typename?: 'ContactAttribute';
      fieldId: string;
      value: string;
    }> | null;
    realEstateAttrs?: {
      __typename?: 'ContactRealEstateAttributes';
      provider: string;
      attrs: Array<{
        __typename?: 'ContactRealEstateAttribute';
        id: RealEstateAttribute;
        value: string;
      }>;
    } | null;
  };
};

export type UpdateContactMutationVariables = Exact<{
  input: UpdateContactInput;
  id: Scalars['String']['input'];
}>;

export type UpdateContactMutation = {
  __typename?: 'Mutation';
  updateContact: {
    __typename?: 'Contact';
    id: string;
    status: ContactStatus;
    createdAt: string;
    phone: string;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    favorite?: boolean | null;
    blocked?: boolean | null;
    unsubscribed?: boolean | null;
    dealClosed?: boolean | null;
    labels?: Array<{
      __typename?: 'Label';
      id: string;
      title: string;
      color: string;
      default: boolean;
      scopes: Array<Scope>;
      readOnly: boolean;
      createdAt: string;
      firestoreId: string;
    }> | null;
    groups?: Array<{
      __typename?: 'GroupView';
      id: string;
      name: string;
    }> | null;
    attrs?: Array<{
      __typename?: 'ContactAttribute';
      fieldId: string;
      value: string;
    }> | null;
    realEstateAttrs?: {
      __typename?: 'ContactRealEstateAttributes';
      provider: string;
      attrs: Array<{
        __typename?: 'ContactRealEstateAttribute';
        id: RealEstateAttribute;
        value: string;
      }>;
    } | null;
  };
};

export type RecoverContactsMutationVariables = Exact<{
  input: RecoverContactsInput;
}>;

export type RecoverContactsMutation = {
  __typename?: 'Mutation';
  recoverContacts: { __typename?: 'OperationStatus'; success: boolean };
};

export type RemoveContactsMutationVariables = Exact<{
  input: RemoveContactsInput;
}>;

export type RemoveContactsMutation = {
  __typename?: 'Mutation';
  removeContacts: { __typename?: 'OperationStatus'; success: boolean };
};

export type BlockContactsMutationVariables = Exact<{
  input: BlockContactsInput;
}>;

export type BlockContactsMutation = {
  __typename?: 'Mutation';
  blockContacts: { __typename?: 'OperationStatus'; success: boolean };
};

export type AddLabelsToContactsMutationVariables = Exact<{
  input: AddLabelsToContactsInput;
}>;

export type AddLabelsToContactsMutation = {
  __typename?: 'Mutation';
  addLabelsToContacts: Array<{
    __typename?: 'Contact';
    id: string;
    firstName?: string | null;
    email?: string | null;
    lastName?: string | null;
    labels?: Array<{
      __typename?: 'Label';
      title: string;
      id: string;
      color: string;
      createdAt: string;
      default: boolean;
      firestoreId: string;
    }> | null;
  }>;
};

export type RemoveLabelsFromContactsMutationVariables = Exact<{
  input: RemoveLabelsFromContactsInput;
}>;

export type RemoveLabelsFromContactsMutation = {
  __typename?: 'Mutation';
  removeLabelsFromContacts: Array<{
    __typename?: 'Contact';
    firstName?: string | null;
    lastName?: string | null;
    id: string;
    labels?: Array<{
      __typename?: 'Label';
      title: string;
      id: string;
      color: string;
      createdAt: string;
      default: boolean;
      firestoreId: string;
    }> | null;
  }>;
};

export type SingleMessageCampaignFragment = {
  __typename?: 'Campaign';
  id: string;
  name: string;
  type: CampaignType;
};

export type SingleMessageTemplateFragment = {
  __typename?: 'MessageTemplate';
  id: string;
  name: string;
  characters?: number | null;
  createdAt: string;
  template: {
    __typename?: 'MessageTemplateTemplate';
    audioFilePath?: string | null;
  };
};

export type SingleMessageKeywordFragment = {
  __typename?: 'KeywordTemplate';
  id: string;
  keyword: string;
};

export type AttachmentFragment = {
  __typename?: 'MessageAttachment';
  path: string;
  url?: string | null;
  contentType?: string | null;
};

export type MessageFragment = {
  __typename?: 'Message';
  id: string;
  status: MessageStatus;
  direction: MessageDirection;
  type: MessageType;
  createdAt: string;
  content?: string | null;
  contentType?: MessageContentType | null;
  duration?: number | null;
  isConversation?: boolean | null;
  isManualRecording?: boolean | null;
  attachments?: Array<{
    __typename?: 'MessageAttachment';
    path: string;
    url?: string | null;
    contentType?: string | null;
  }> | null;
  campaign?: {
    __typename?: 'Campaign';
    id: string;
    name: string;
    type: CampaignType;
  } | null;
  template?: {
    __typename?: 'MessageTemplate';
    id: string;
    name: string;
    characters?: number | null;
    createdAt: string;
    template: {
      __typename?: 'MessageTemplateTemplate';
      audioFilePath?: string | null;
    };
  } | null;
  keyword?: {
    __typename?: 'KeywordTemplate';
    id: string;
    keyword: string;
  } | null;
  dialerCampaign?: { __typename?: 'DialerCampaign'; name: string } | null;
  fromNumber?: { __typename?: 'PhoneNumber'; number: string } | null;
};

export type LastMessageFragment = {
  __typename?: 'Message';
  id: string;
  status: MessageStatus;
  direction: MessageDirection;
  type: MessageType;
  content?: string | null;
  contentType?: MessageContentType | null;
};

export type ConversationFragment = {
  __typename?: 'Conversation';
  id: string;
  profileId: string;
  isFavorite?: boolean | null;
  isRead?: boolean | null;
  isAiFiltered?: boolean | null;
  name?: string | null;
  lastMessageAt?: string | null;
  updatedAt: string;
  unreadMessages?: number | null;
  phone: string;
  createdAt: string;
  canBeRemoved: boolean;
  lastMessage?: {
    __typename?: 'Message';
    id: string;
    status: MessageStatus;
    direction: MessageDirection;
    type: MessageType;
    content?: string | null;
    contentType?: MessageContentType | null;
  } | null;
  labels?: Array<{
    __typename?: 'Label';
    id: string;
    title: string;
    color: string;
  }> | null;
};

export type FindConversationsQueryVariables = Exact<{
  filter?: InputMaybe<ConversationFilter>;
  pagination?: InputMaybe<Pagination>;
  order?: InputMaybe<ConversationOrder>;
}>;

export type FindConversationsQuery = {
  __typename?: 'Query';
  findConversations: {
    __typename?: 'ConversationList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'Conversation';
      id: string;
      profileId: string;
      isFavorite?: boolean | null;
      isRead?: boolean | null;
      isAiFiltered?: boolean | null;
      name?: string | null;
      lastMessageAt?: string | null;
      updatedAt: string;
      unreadMessages?: number | null;
      phone: string;
      createdAt: string;
      canBeRemoved: boolean;
      lastMessage?: {
        __typename?: 'Message';
        id: string;
        status: MessageStatus;
        direction: MessageDirection;
        type: MessageType;
        content?: string | null;
        contentType?: MessageContentType | null;
      } | null;
      labels?: Array<{
        __typename?: 'Label';
        id: string;
        title: string;
        color: string;
      }> | null;
    }>;
  };
};

export type FindConversationMessagesQueryVariables = Exact<{
  contactId: Scalars['String']['input'];
  filter?: InputMaybe<MessageFilter>;
  pagination?: InputMaybe<Pagination>;
  order?: InputMaybe<MessageOrder>;
}>;

export type FindConversationMessagesQuery = {
  __typename?: 'Query';
  findConversationMessages: {
    __typename?: 'MessageList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'Message';
      id: string;
      status: MessageStatus;
      direction: MessageDirection;
      type: MessageType;
      createdAt: string;
      content?: string | null;
      contentType?: MessageContentType | null;
      duration?: number | null;
      isConversation?: boolean | null;
      isManualRecording?: boolean | null;
      attachments?: Array<{
        __typename?: 'MessageAttachment';
        path: string;
        url?: string | null;
        contentType?: string | null;
      }> | null;
      campaign?: {
        __typename?: 'Campaign';
        id: string;
        name: string;
        type: CampaignType;
      } | null;
      template?: {
        __typename?: 'MessageTemplate';
        id: string;
        name: string;
        characters?: number | null;
        createdAt: string;
        template: {
          __typename?: 'MessageTemplateTemplate';
          audioFilePath?: string | null;
        };
      } | null;
      keyword?: {
        __typename?: 'KeywordTemplate';
        id: string;
        keyword: string;
      } | null;
      dialerCampaign?: { __typename?: 'DialerCampaign'; name: string } | null;
      fromNumber?: { __typename?: 'PhoneNumber'; number: string } | null;
    }>;
  };
};

export type FindScheduledMessagesQueryVariables = Exact<{
  filter?: InputMaybe<ScheduledMessageFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindScheduledMessagesQuery = {
  __typename?: 'Query';
  findScheduledMessages: {
    __typename?: 'ScheduledMessageList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'ScheduledMessage';
      id: string;
      createdAt: string;
      scheduledAt?: string | null;
      content?: string | null;
      attachments?: Array<{
        __typename?: 'MessageAttachment';
        path: string;
        url?: string | null;
        contentType?: string | null;
      }> | null;
    }>;
  };
};

export type GetMessageAttachmentSignedUrlQueryVariables = Exact<{
  input: GetMessageAttachmentSignedUrlInput;
}>;

export type GetMessageAttachmentSignedUrlQuery = {
  __typename?: 'Query';
  getMessageAttachmentSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type GetConversationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetConversationQuery = {
  __typename?: 'Query';
  getConversation: {
    __typename?: 'Conversation';
    id: string;
    lastMessageAt?: string | null;
    isFavorite?: boolean | null;
    isRead?: boolean | null;
    isUnsubscribed?: boolean | null;
    status: ConversationStatus;
    name?: string | null;
    isBlocked?: boolean | null;
    phone: string;
    outgoingPhone?: string | null;
    lastMessage?: {
      __typename?: 'Message';
      id: string;
      status: MessageStatus;
      direction: MessageDirection;
      type: MessageType;
      content?: string | null;
      contentType?: MessageContentType | null;
    } | null;
    labels?: Array<{
      __typename?: 'Label';
      id: string;
      title: string;
      color: string;
    }> | null;
    campaigns?: Array<{
      __typename?: 'Campaign';
      id: string;
      name: string;
    }> | null;
    ownPhone?: {
      __typename?: 'OwnPhone';
      id: string;
      type: OwnPhoneType;
      number: string;
      location?: {
        __typename?: 'PhoneNumberLocation';
        abbreviation: string;
        state: string;
      } | null;
    } | null;
  };
};

export type GetConversationOwnPhoneQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetConversationOwnPhoneQuery = {
  __typename?: 'Query';
  getConversation: {
    __typename?: 'Conversation';
    ownPhone?: {
      __typename?: 'OwnPhone';
      id: string;
      status: OwnPhoneStatus;
      type: OwnPhoneType;
      number: string;
      isTrial: boolean;
      messagingEnabled: boolean;
      callingEnabled: boolean;
    } | null;
  };
};

export type GetUnreadConversationsCountersQueryVariables = Exact<{
  profileId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetUnreadConversationsCountersQuery = {
  __typename?: 'Query';
  getUnreadConversationsCounters: {
    __typename?: 'UnreadConversationsCountersView';
    withUnreadMessages?: number | null;
    withMissedCalls?: number | null;
  };
};

export type CreateConversationMutationVariables = Exact<{
  input: CreateConversationInput;
}>;

export type CreateConversationMutation = {
  __typename?: 'Mutation';
  createConversation: { __typename?: 'Conversation'; id: string };
};

export type MarkConversationsReadMutationVariables = Exact<{
  input: MarkConversationsReadInput;
}>;

export type MarkConversationsReadMutation = {
  __typename?: 'Mutation';
  markConversationsRead: { __typename?: 'OperationStatus'; success: boolean };
};

export type SendMessageMutationVariables = Exact<{
  input: SendMessageInput;
}>;

export type SendMessageMutation = {
  __typename?: 'Mutation';
  sendMessage: {
    __typename?: 'Message';
    id: string;
    status: MessageStatus;
    direction: MessageDirection;
    type: MessageType;
    createdAt: string;
    content?: string | null;
    contentType?: MessageContentType | null;
    duration?: number | null;
    isConversation?: boolean | null;
    isManualRecording?: boolean | null;
    attachments?: Array<{
      __typename?: 'MessageAttachment';
      path: string;
      url?: string | null;
      contentType?: string | null;
    }> | null;
    campaign?: {
      __typename?: 'Campaign';
      id: string;
      name: string;
      type: CampaignType;
    } | null;
    template?: {
      __typename?: 'MessageTemplate';
      id: string;
      name: string;
      characters?: number | null;
      createdAt: string;
      template: {
        __typename?: 'MessageTemplateTemplate';
        audioFilePath?: string | null;
      };
    } | null;
    keyword?: {
      __typename?: 'KeywordTemplate';
      id: string;
      keyword: string;
    } | null;
    dialerCampaign?: { __typename?: 'DialerCampaign'; name: string } | null;
    fromNumber?: { __typename?: 'PhoneNumber'; number: string } | null;
  };
};

export type CreateScheduledMessageMutationVariables = Exact<{
  input: CreateScheduledMessageInput;
}>;

export type CreateScheduledMessageMutation = {
  __typename?: 'Mutation';
  createScheduledMessage: {
    __typename?: 'ScheduledMessage';
    id: string;
    createdAt: string;
    scheduledAt?: string | null;
    content?: string | null;
    attachments?: Array<{
      __typename?: 'MessageAttachment';
      path: string;
      url?: string | null;
      contentType?: string | null;
    }> | null;
  };
};

export type RemoveScheduledMessageMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveScheduledMessageMutation = {
  __typename?: 'Mutation';
  removeScheduledMessage: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateConversationOutgoingPhoneMutationVariables = Exact<{
  input: UpdateConversationOutgoingPhoneInput;
}>;

export type UpdateConversationOutgoingPhoneMutation = {
  __typename?: 'Mutation';
  updateConversationOutgoingPhone: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type ConversationUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ConversationUpdatedSubscription = {
  __typename?: 'Subscription';
  conversationUpdated: {
    __typename?: 'ChangesItemView';
    entityId: string;
    operation: ChangesOperation;
  };
};

export type MessageCreatedSubscriptionVariables = Exact<{
  contactId: Scalars['String']['input'];
}>;

export type MessageCreatedSubscription = {
  __typename?: 'Subscription';
  messageCreated: { __typename?: 'ChangesItemView'; entityId: string };
};

export type MessageUpdatedSubscriptionVariables = Exact<{
  contactId: Scalars['String']['input'];
}>;

export type MessageUpdatedSubscription = {
  __typename?: 'Subscription';
  messageUpdated: { __typename?: 'ChangesItemView'; entityId: string };
};

export type IncomingMessageReceivedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type IncomingMessageReceivedSubscription = {
  __typename?: 'Subscription';
  incomingMessageReceived: {
    __typename?: 'Message';
    profileId: string;
    status: MessageStatus;
    conversationId: string;
    type: MessageType;
    statusReason?: MessageStateReasonType | null;
    contentType?: MessageContentType | null;
    id: string;
  };
};

export type UnreadConversationsMessagesCounterUpdatedSubscriptionVariables =
  Exact<{
    profileId?: InputMaybe<Scalars['String']['input']>;
  }>;

export type UnreadConversationsMessagesCounterUpdatedSubscription = {
  __typename?: 'Subscription';
  unreadConversationsMessagesCounterUpdated: {
    __typename?: 'UnreadConversationsCounterUpdatedView';
    counter: number;
    profileId: string;
  };
};

export type MissedConversationsCallsCounterUpdatedSubscriptionVariables =
  Exact<{
    profileId?: InputMaybe<Scalars['String']['input']>;
  }>;

export type MissedConversationsCallsCounterUpdatedSubscription = {
  __typename?: 'Subscription';
  missedConversationsCallsCounterUpdated: {
    __typename?: 'UnreadConversationsCounterUpdatedView';
    counter: number;
    profileId: string;
  };
};

export type GenerateWebRtcTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GenerateWebRtcTokenQuery = {
  __typename?: 'Query';
  generateWebRTCToken: {
    __typename?: 'WebRTCToken';
    expiresAt: string;
    token: string;
  };
};

export type GetInfobipConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetInfobipConfigQuery = {
  __typename?: 'Query';
  getInfobipConfig: {
    __typename?: 'InfobipConfigView';
    applicationId: string;
    callsConfigId: string;
  };
};

export type DialerCampaignFragmentFragment = {
  __typename?: 'DialerCampaign';
  id: string;
  name: string;
  status: CampaignStatus;
  createdAt: string;
  lastCallAt?: string | null;
  contacts: number;
  mode: CallMode;
  script?: string | null;
  phones: Array<string>;
  labels: Array<string>;
  lines: Array<string>;
  deletedAt?: string | null;
  endedAt?: string | null;
  startedAt?: string | null;
  groups?: Array<{ __typename?: 'GroupView'; id: string; name: string }> | null;
  ownPhones: Array<{
    __typename?: 'OwnPhone';
    id: string;
    status: OwnPhoneStatus;
    type: OwnPhoneType;
    number: string;
    scope: Scope;
    isTrial: boolean;
    createdAt: string;
    canBeRemoved: boolean;
    callingEnabled: boolean;
    messagingEnabled: boolean;
    location?: {
      __typename?: 'PhoneNumberLocation';
      abbreviation: string;
      state: string;
    } | null;
  }>;
};

export type DialerCampaignDetailedFragmentFragment = {
  __typename?: 'DialerCampaign';
  id: string;
  name: string;
  status: CampaignStatus;
  createdAt: string;
  lastCallAt?: string | null;
  contacts: number;
  mode: CallMode;
  script?: string | null;
  phones: Array<string>;
  labels: Array<string>;
  contactsWithOutcome?: number | null;
  lines: Array<string>;
  deletedAt?: string | null;
  endedAt?: string | null;
  startedAt?: string | null;
  groups?: Array<{
    __typename?: 'GroupView';
    id: string;
    name: string;
    verified: boolean;
    createdAt: string;
    contacts: number;
  }> | null;
  ownPhones: Array<{
    __typename?: 'OwnPhone';
    id: string;
    status: OwnPhoneStatus;
    type: OwnPhoneType;
    number: string;
    scope: Scope;
    isTrial: boolean;
    createdAt: string;
    canBeRemoved: boolean;
    callingEnabled: boolean;
    messagingEnabled: boolean;
    location?: {
      __typename?: 'PhoneNumberLocation';
      abbreviation: string;
      state: string;
    } | null;
  }>;
};

export type FindDialerCampaignsQueryVariables = Exact<{
  filter?: InputMaybe<DialerCampaignFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindDialerCampaignsQuery = {
  __typename?: 'Query';
  findDialerCampaigns: {
    __typename?: 'DialerCampaignList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'DialerCampaign';
      id: string;
      name: string;
      status: CampaignStatus;
      createdAt: string;
      lastCallAt?: string | null;
      contacts: number;
      mode: CallMode;
      script?: string | null;
      phones: Array<string>;
      labels: Array<string>;
      lines: Array<string>;
      deletedAt?: string | null;
      endedAt?: string | null;
      startedAt?: string | null;
      groups?: Array<{
        __typename?: 'GroupView';
        id: string;
        name: string;
      }> | null;
      ownPhones: Array<{
        __typename?: 'OwnPhone';
        id: string;
        status: OwnPhoneStatus;
        type: OwnPhoneType;
        number: string;
        scope: Scope;
        isTrial: boolean;
        createdAt: string;
        canBeRemoved: boolean;
        callingEnabled: boolean;
        messagingEnabled: boolean;
        location?: {
          __typename?: 'PhoneNumberLocation';
          abbreviation: string;
          state: string;
        } | null;
      }>;
    }>;
  };
};

export type FindListDialerCampaignsQueryVariables = Exact<{
  filter?: InputMaybe<DialerCampaignFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindListDialerCampaignsQuery = {
  __typename?: 'Query';
  findDialerCampaigns: {
    __typename?: 'DialerCampaignList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{ __typename?: 'DialerCampaign'; id: string; name: string }>;
  };
};

export type GetDialerCampaignsTotalQueryVariables = Exact<{
  filter?: InputMaybe<DialerCampaignFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type GetDialerCampaignsTotalQuery = {
  __typename?: 'Query';
  findDialerCampaigns: {
    __typename?: 'DialerCampaignList';
    total?: number | null;
  };
};

export type GetDialerCampaignsCountersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDialerCampaignsCountersQuery = {
  __typename?: 'Query';
  getDialerCampaignsCounters: {
    __typename?: 'DialerCampaignsAggregationView';
    dialer: {
      __typename?: 'DialerCampaignsAggregationCountersView';
      active: number;
      draft: number;
      deleted: number;
      total: number;
    };
  };
};

export type GetDialerCampaignQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetDialerCampaignQuery = {
  __typename?: 'Query';
  getDialerCampaign: {
    __typename?: 'DialerCampaign';
    id: string;
    name: string;
    status: CampaignStatus;
    createdAt: string;
    lastCallAt?: string | null;
    contacts: number;
    mode: CallMode;
    script?: string | null;
    phones: Array<string>;
    labels: Array<string>;
    contactsWithOutcome?: number | null;
    lines: Array<string>;
    deletedAt?: string | null;
    endedAt?: string | null;
    startedAt?: string | null;
    groups?: Array<{
      __typename?: 'GroupView';
      id: string;
      name: string;
      verified: boolean;
      createdAt: string;
      contacts: number;
    }> | null;
    ownPhones: Array<{
      __typename?: 'OwnPhone';
      id: string;
      status: OwnPhoneStatus;
      type: OwnPhoneType;
      number: string;
      scope: Scope;
      isTrial: boolean;
      createdAt: string;
      canBeRemoved: boolean;
      callingEnabled: boolean;
      messagingEnabled: boolean;
      location?: {
        __typename?: 'PhoneNumberLocation';
        abbreviation: string;
        state: string;
      } | null;
    }>;
  };
};

export type ExportDialerCampaignToFileQueryVariables = Exact<{
  input: ExportDialerCampaignToFileInput;
}>;

export type ExportDialerCampaignToFileQuery = {
  __typename?: 'Query';
  exportDialerCampaignToFile: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type CreateDialerCampaignMutationVariables = Exact<{
  input: CreateDialerCampaignInput;
}>;

export type CreateDialerCampaignMutation = {
  __typename?: 'Mutation';
  createDialerCampaign: {
    __typename?: 'DialerCampaign';
    id: string;
    name: string;
    status: CampaignStatus;
    createdAt: string;
    lastCallAt?: string | null;
    contacts: number;
    mode: CallMode;
    script?: string | null;
    phones: Array<string>;
    labels: Array<string>;
    lines: Array<string>;
    deletedAt?: string | null;
    endedAt?: string | null;
    startedAt?: string | null;
    groups?: Array<{
      __typename?: 'GroupView';
      id: string;
      name: string;
    }> | null;
    ownPhones: Array<{
      __typename?: 'OwnPhone';
      id: string;
      status: OwnPhoneStatus;
      type: OwnPhoneType;
      number: string;
      scope: Scope;
      isTrial: boolean;
      createdAt: string;
      canBeRemoved: boolean;
      callingEnabled: boolean;
      messagingEnabled: boolean;
      location?: {
        __typename?: 'PhoneNumberLocation';
        abbreviation: string;
        state: string;
      } | null;
    }>;
  };
};

export type RemoveDialerCampaignMutationVariables = Exact<{
  input: RemoveDialerCampaignInput;
}>;

export type RemoveDialerCampaignMutation = {
  __typename?: 'Mutation';
  removeDialerCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type StartDialerCampaignMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type StartDialerCampaignMutation = {
  __typename?: 'Mutation';
  startDialerCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type PauseDialerCampaignMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type PauseDialerCampaignMutation = {
  __typename?: 'Mutation';
  pauseDialerCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type ResumeDialerCampaignMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type ResumeDialerCampaignMutation = {
  __typename?: 'Mutation';
  resumeDialerCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type ExportDialerCampaignToGroupMutationVariables = Exact<{
  input: ExportDialerCampaignToGroupInput;
  id: Scalars['String']['input'];
}>;

export type ExportDialerCampaignToGroupMutation = {
  __typename?: 'Mutation';
  exportDialerCampaignToGroup: { __typename?: 'GroupView'; id: string };
};

export type UpdateDialerCampaignMutationVariables = Exact<{
  input: UpdateDialerCampaignInput;
  updateDialerCampaignId: Scalars['String']['input'];
}>;

export type UpdateDialerCampaignMutation = {
  __typename?: 'Mutation';
  updateDialerCampaign: { __typename?: 'DialerCampaign'; id: string };
};

export type ExportConversationsToFileQueryVariables = Exact<{
  input: ExportConversationsToFileInput;
}>;

export type ExportConversationsToFileQuery = {
  __typename?: 'Query';
  exportConversationsToFile: {
    __typename?: 'SignedUrl';
    filePath: string;
    signedUrl: string;
  };
};

export type FindLastFeedbackQueryVariables = Exact<{
  source: FeedbackSource;
}>;

export type FindLastFeedbackQuery = {
  __typename?: 'Query';
  findLastFeedback?: {
    __typename?: 'Feedback';
    id: string;
    score: number;
    createdAt: string;
    source: FeedbackSource;
    message?: string | null;
  } | null;
};

export type CreateFeedbackMutationVariables = Exact<{
  input: CreateFeedbackInput;
}>;

export type CreateFeedbackMutation = {
  __typename?: 'Mutation';
  createFeedback: { __typename?: 'OperationStatus'; success: boolean };
};

export type FieldsTemplateFragment = {
  __typename?: 'FieldsTemplate';
  id: string;
  name: string;
  createdAt: string;
  fields?: Array<{
    __typename?: 'Field';
    id: string;
    key?: PredefinedField | null;
    predefined: boolean;
    name: string;
  }> | null;
};

export type FindFieldsTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type FindFieldsTemplatesQuery = {
  __typename?: 'Query';
  findFieldsTemplates: {
    __typename?: 'FieldsTemplateList';
    items: Array<{
      __typename?: 'FieldsTemplate';
      id: string;
      name: string;
      createdAt: string;
      fields?: Array<{
        __typename?: 'Field';
        id: string;
        key?: PredefinedField | null;
        predefined: boolean;
        name: string;
      }> | null;
    }>;
  };
};

export type CreateFieldsTemplateMutationVariables = Exact<{
  input: CreateFieldsTemplateInput;
}>;

export type CreateFieldsTemplateMutation = {
  __typename?: 'Mutation';
  createFieldsTemplate: {
    __typename?: 'FieldsTemplate';
    id: string;
    name: string;
    createdAt: string;
    fields?: Array<{
      __typename?: 'Field';
      id: string;
      key?: PredefinedField | null;
      predefined: boolean;
      name: string;
    }> | null;
  };
};

export type RemoveFieldsTemplateMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveFieldsTemplateMutation = {
  __typename?: 'Mutation';
  removeFieldsTemplate: { __typename?: 'OperationStatus'; success: boolean };
};

export type FieldFragment = {
  __typename?: 'Field';
  id: string;
  key?: PredefinedField | null;
  predefined: boolean;
  name: string;
};

export type FindFieldsQueryVariables = Exact<{
  filter?: InputMaybe<FieldsFilter>;
}>;

export type FindFieldsQuery = {
  __typename?: 'Query';
  findFields: Array<{
    __typename?: 'Field';
    id: string;
    key?: PredefinedField | null;
    predefined: boolean;
    name: string;
  }>;
};

export type FindMergeTagsQueryVariables = Exact<{ [key: string]: never }>;

export type FindMergeTagsQuery = {
  __typename?: 'Query';
  findMergeTags: Array<{
    __typename?: 'MergeTag';
    id: string;
    key?: PredefinedField | null;
    predefined: boolean;
    name: string;
    mergeTag: string;
  }>;
};

export type CreateFieldMutationVariables = Exact<{
  input: CreateFieldInput;
}>;

export type CreateFieldMutation = {
  __typename?: 'Mutation';
  createField: {
    __typename?: 'Field';
    id: string;
    key?: PredefinedField | null;
    predefined: boolean;
    name: string;
  };
};

export type UpdateFieldMutationVariables = Exact<{
  input: UpdateFieldInput;
  id: Scalars['String']['input'];
}>;

export type UpdateFieldMutation = {
  __typename?: 'Mutation';
  updateField: {
    __typename?: 'Field';
    id: string;
    key?: PredefinedField | null;
    predefined: boolean;
    name: string;
  };
};

export type RemoveFieldMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveFieldMutation = {
  __typename?: 'Mutation';
  removeField: { __typename?: 'OperationStatus'; success: boolean };
};

export type GroupViewFragment = {
  __typename?: 'GroupView';
  id: string;
  name: string;
  verified: boolean;
  createdAt: string;
  contacts: number;
  activeChats: number;
  canBeCloned: boolean;
  canBeRemoved: boolean;
  campaigns: Array<{ __typename?: 'Campaign'; name: string }>;
  dialerCampaigns: Array<{ __typename?: 'DialerCampaign'; name: string }>;
};

export type GetGroupQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetGroupQuery = {
  __typename?: 'Query';
  getGroup: {
    __typename?: 'GroupView';
    id: string;
    name: string;
    verified: boolean;
    createdAt: string;
    contacts: number;
    activeChats: number;
    canBeCloned: boolean;
    canBeRemoved: boolean;
    campaigns: Array<{ __typename?: 'Campaign'; name: string }>;
    dialerCampaigns: Array<{ __typename?: 'DialerCampaign'; name: string }>;
  };
};

export type FindGroupsQueryVariables = Exact<{
  order?: InputMaybe<Order>;
  filter?: InputMaybe<GroupsFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindGroupsQuery = {
  __typename?: 'Query';
  findGroups: {
    __typename?: 'GroupList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'GroupView';
      id: string;
      name: string;
      verified: boolean;
      createdAt: string;
      contacts: number;
      activeChats: number;
      canBeCloned: boolean;
      canBeRemoved: boolean;
      campaigns: Array<{ __typename?: 'Campaign'; name: string }>;
      dialerCampaigns: Array<{ __typename?: 'DialerCampaign'; name: string }>;
    }>;
  };
};

export type FindAlertGroupsQueryVariables = Exact<{
  order?: InputMaybe<Order>;
  filter?: InputMaybe<GroupsFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindAlertGroupsQuery = {
  __typename?: 'Query';
  findGroups: {
    __typename?: 'GroupList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'GroupView';
      id: string;
      name: string;
      verified: boolean;
      createdAt: string;
      contacts: number;
      activeChats: number;
      canBeCloned: boolean;
      canBeRemoved: boolean;
      campaigns: Array<{ __typename?: 'Campaign'; name: string }>;
      dialerCampaigns: Array<{ __typename?: 'DialerCampaign'; name: string }>;
    }>;
  };
};

export type CreateGroupMutationVariables = Exact<{
  input: CreateGroupInput;
}>;

export type CreateGroupMutation = {
  __typename?: 'Mutation';
  createGroup: {
    __typename?: 'GroupView';
    id: string;
    name: string;
    verified: boolean;
    createdAt: string;
    canBeCloned: boolean;
  };
};

export type UpdateGroupMutationVariables = Exact<{
  input: UpdateGroupInput;
  id: Scalars['String']['input'];
}>;

export type UpdateGroupMutation = {
  __typename?: 'Mutation';
  updateGroup: {
    __typename?: 'GroupView';
    id: string;
    name: string;
    verified: boolean;
    createdAt: string;
    contacts: number;
    activeChats: number;
    canBeCloned: boolean;
    canBeRemoved: boolean;
    campaigns: Array<{ __typename?: 'Campaign'; name: string }>;
    dialerCampaigns: Array<{ __typename?: 'DialerCampaign'; name: string }>;
  };
};

export type RemoveGroupsMutationVariables = Exact<{
  input: RemoveGroupsInput;
}>;

export type RemoveGroupsMutation = {
  __typename?: 'Mutation';
  removeGroups: { __typename?: 'OperationStatus'; success: boolean };
};

export type DuplicateGroupsMutationVariables = Exact<{
  duplicateGroupsId: Scalars['String']['input'];
}>;

export type DuplicateGroupsMutation = {
  __typename?: 'Mutation';
  duplicateGroups: {
    __typename?: 'GroupView';
    id: string;
    name: string;
    verified: boolean;
    createdAt: string;
    contacts: number;
    activeChats: number;
    canBeCloned: boolean;
    canBeRemoved: boolean;
    campaigns: Array<{ __typename?: 'Campaign'; name: string }>;
    dialerCampaigns: Array<{ __typename?: 'DialerCampaign'; name: string }>;
  };
};

export type AddGroupToContactsMutationVariables = Exact<{
  input: AddGroupToContactsInput;
}>;

export type AddGroupToContactsMutation = {
  __typename?: 'Mutation';
  addGroupToContacts: { __typename?: 'OperationStatus'; success: boolean };
};

export type RemoveGroupFromContactsMutationVariables = Exact<{
  input: RemoveGroupFromContactsInput;
}>;

export type RemoveGroupFromContactsMutation = {
  __typename?: 'Mutation';
  removeGroupFromContacts: { __typename?: 'OperationStatus'; success: boolean };
};

export type ExportGroupQueryVariables = Exact<{
  exportGroupId: Scalars['String']['input'];
}>;

export type ExportGroupQuery = {
  __typename?: 'Query';
  exportGroup: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type CrmIntegrationFragmentFragment = {
  __typename?: 'CrmIntegration';
  id: string;
  createdAt: string;
  status: CrmIntegrationStatus;
  name: string;
  type?: CrmProviderType | null;
};

export type CrmIntegrationFieldsFragment = {
  __typename?: 'CrmIntegration';
  id: string;
  createdAt: string;
  status: CrmIntegrationStatus;
  name: string;
  type?: CrmProviderType | null;
  provider?: {
    __typename?: 'CrmProviderView';
    apiKey?: string | null;
    sendNotes?: boolean | null;
    labelsPropertyStatuses?: Array<{
      __typename?: 'CrmProviderLabelView';
      status: string;
      label: {
        __typename?: 'Label';
        id: string;
        title: string;
        color: string;
        default: boolean;
        firestoreId: string;
      };
    }> | null;
    labelsPhoneStatuses?: Array<{
      __typename?: 'CrmProviderLabelView';
      status: string;
      label: {
        __typename?: 'Label';
        id: string;
        title: string;
        color: string;
        default: boolean;
        firestoreId: string;
      };
    }> | null;
    labelsPhoneTags?: Array<{
      __typename?: 'Label';
      id: string;
      title: string;
      color: string;
      default: boolean;
      firestoreId: string;
    }> | null;
  } | null;
};

export type FindCrmIntegrationsQueryVariables = Exact<{
  filter?: InputMaybe<CrmIntegrationFilter>;
  pagination?: InputMaybe<Pagination>;
  order?: InputMaybe<CrmIntegrationOrder>;
}>;

export type FindCrmIntegrationsQuery = {
  __typename?: 'Query';
  findCrmIntegrations: {
    __typename?: 'CrmIntegrationList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'CrmIntegration';
      id: string;
      createdAt: string;
      status: CrmIntegrationStatus;
      name: string;
      type?: CrmProviderType | null;
    }>;
  };
};

export type GetCrmIntegrationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetCrmIntegrationQuery = {
  __typename?: 'Query';
  getCrmIntegration: {
    __typename?: 'CrmIntegration';
    id: string;
    createdAt: string;
    status: CrmIntegrationStatus;
    name: string;
    type?: CrmProviderType | null;
    provider?: {
      __typename?: 'CrmProviderView';
      apiKey?: string | null;
      sendNotes?: boolean | null;
      labelsPropertyStatuses?: Array<{
        __typename?: 'CrmProviderLabelView';
        status: string;
        label: {
          __typename?: 'Label';
          id: string;
          title: string;
          color: string;
          default: boolean;
          firestoreId: string;
        };
      }> | null;
      labelsPhoneStatuses?: Array<{
        __typename?: 'CrmProviderLabelView';
        status: string;
        label: {
          __typename?: 'Label';
          id: string;
          title: string;
          color: string;
          default: boolean;
          firestoreId: string;
        };
      }> | null;
      labelsPhoneTags?: Array<{
        __typename?: 'Label';
        id: string;
        title: string;
        color: string;
        default: boolean;
        firestoreId: string;
      }> | null;
    } | null;
  };
};

export type ExportConversationsToCrmIntegrationQueryVariables = Exact<{
  input: ExportConversationsToCrmIntegrationInput;
}>;

export type ExportConversationsToCrmIntegrationQuery = {
  __typename?: 'Query';
  exportConversationsToCrmIntegration: {
    __typename?: 'ExportConversationsToCRMIntegrationResultView';
    success: number;
    total: number;
  };
};

export type CreateCrmIntegrationMutationVariables = Exact<{
  input: CreateCrmIntegrationInput;
}>;

export type CreateCrmIntegrationMutation = {
  __typename?: 'Mutation';
  createCrmIntegration: {
    __typename?: 'CrmIntegration';
    id: string;
    createdAt: string;
    status: CrmIntegrationStatus;
    name: string;
    type?: CrmProviderType | null;
    provider?: {
      __typename?: 'CrmProviderView';
      apiKey?: string | null;
      sendNotes?: boolean | null;
      labelsPropertyStatuses?: Array<{
        __typename?: 'CrmProviderLabelView';
        status: string;
        label: {
          __typename?: 'Label';
          id: string;
          title: string;
          color: string;
          default: boolean;
          firestoreId: string;
        };
      }> | null;
      labelsPhoneStatuses?: Array<{
        __typename?: 'CrmProviderLabelView';
        status: string;
        label: {
          __typename?: 'Label';
          id: string;
          title: string;
          color: string;
          default: boolean;
          firestoreId: string;
        };
      }> | null;
      labelsPhoneTags?: Array<{
        __typename?: 'Label';
        id: string;
        title: string;
        color: string;
        default: boolean;
        firestoreId: string;
      }> | null;
    } | null;
  };
};

export type UpdateCrmIntegrationMutationVariables = Exact<{
  input: UpdateCrmIntegrationInput;
  id: Scalars['String']['input'];
}>;

export type UpdateCrmIntegrationMutation = {
  __typename?: 'Mutation';
  updateCrmIntegration: {
    __typename?: 'CrmIntegration';
    id: string;
    createdAt: string;
    status: CrmIntegrationStatus;
    name: string;
    type?: CrmProviderType | null;
    provider?: {
      __typename?: 'CrmProviderView';
      apiKey?: string | null;
      sendNotes?: boolean | null;
      labelsPropertyStatuses?: Array<{
        __typename?: 'CrmProviderLabelView';
        status: string;
        label: {
          __typename?: 'Label';
          id: string;
          title: string;
          color: string;
          default: boolean;
          firestoreId: string;
        };
      }> | null;
      labelsPhoneStatuses?: Array<{
        __typename?: 'CrmProviderLabelView';
        status: string;
        label: {
          __typename?: 'Label';
          id: string;
          title: string;
          color: string;
          default: boolean;
          firestoreId: string;
        };
      }> | null;
      labelsPhoneTags?: Array<{
        __typename?: 'Label';
        id: string;
        title: string;
        color: string;
        default: boolean;
        firestoreId: string;
      }> | null;
    } | null;
  };
};

export type RemoveCrmIntegrationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveCrmIntegrationMutation = {
  __typename?: 'Mutation';
  removeCrmIntegration: { __typename?: 'OperationStatus'; success: boolean };
};

export type KeywordTemplateFragment = {
  __typename?: 'KeywordTemplate';
  id: string;
  keyword: string;
  createdAt: string;
  template: {
    __typename?: 'KeywordTemplateTemplate';
    content?: string | null;
    optOut?: boolean | null;
    characters?: number | null;
    segments?: number | null;
  };
};

export type FindKeywordTemplatesQueryVariables = Exact<{
  filter?: InputMaybe<KeywordTemplatesFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindKeywordTemplatesQuery = {
  __typename?: 'Query';
  findKeywordTemplates: {
    __typename?: 'KeywordTemplateList';
    hasNext?: boolean | null;
    nextCreatedAt?: string | null;
    nextId?: string | null;
    total?: number | null;
    items: Array<{
      __typename?: 'KeywordTemplate';
      id: string;
      keyword: string;
      createdAt: string;
      template: {
        __typename?: 'KeywordTemplateTemplate';
        content?: string | null;
        optOut?: boolean | null;
        characters?: number | null;
        segments?: number | null;
      };
    }>;
  };
};

export type GetKeywordTemplateQueryVariables = Exact<{
  getKeywordTemplateId: Scalars['String']['input'];
}>;

export type GetKeywordTemplateQuery = {
  __typename?: 'Query';
  getKeywordTemplate: {
    __typename?: 'KeywordTemplate';
    id: string;
    keyword: string;
    createdAt: string;
    template: {
      __typename?: 'KeywordTemplateTemplate';
      content?: string | null;
      optOut?: boolean | null;
      characters?: number | null;
      segments?: number | null;
    };
  };
};

export type CreateKeywordTemplateMutationVariables = Exact<{
  input: CreateKeywordTemplateInput;
}>;

export type CreateKeywordTemplateMutation = {
  __typename?: 'Mutation';
  createKeywordTemplate: {
    __typename?: 'KeywordTemplate';
    id: string;
    keyword: string;
    createdAt: string;
    template: {
      __typename?: 'KeywordTemplateTemplate';
      content?: string | null;
      optOut?: boolean | null;
      characters?: number | null;
      segments?: number | null;
    };
  };
};

export type UpdateKeywordTemplateMutationVariables = Exact<{
  input: UpdateKeywordTemplateInput;
  updateKeywordTemplateId: Scalars['String']['input'];
}>;

export type UpdateKeywordTemplateMutation = {
  __typename?: 'Mutation';
  updateKeywordTemplate: {
    __typename?: 'KeywordTemplate';
    id: string;
    keyword: string;
    createdAt: string;
    template: {
      __typename?: 'KeywordTemplateTemplate';
      content?: string | null;
      optOut?: boolean | null;
      characters?: number | null;
      segments?: number | null;
    };
  };
};

export type RemoveKeywordTemplateMutationVariables = Exact<{
  removeKeywordTemplateId: Scalars['String']['input'];
}>;

export type RemoveKeywordTemplateMutation = {
  __typename?: 'Mutation';
  removeKeywordTemplate: { __typename?: 'OperationStatus'; success: boolean };
};

export type RankKeywordTemplateMutationVariables = Exact<{
  input: RankKeywordTemplateInput;
  rankKeywordTemplateId: Scalars['String']['input'];
}>;

export type RankKeywordTemplateMutation = {
  __typename?: 'Mutation';
  rankKeywordTemplate: { __typename?: 'OperationStatus'; success: boolean };
};

export type LabelFragment = {
  __typename?: 'Label';
  id: string;
  title: string;
  color: string;
  default: boolean;
  scopes: Array<Scope>;
  readOnly: boolean;
  createdAt: string;
  firestoreId: string;
};

export type RankResultFragment = {
  __typename?: 'RankResult';
  id: string;
  afterId?: string | null;
  beforeId?: string | null;
};

export type FindLabelsQueryVariables = Exact<{
  filter?: InputMaybe<LabelFilter>;
  pagination?: InputMaybe<CursorPagination>;
}>;

export type FindLabelsQuery = {
  __typename?: 'Query';
  findLabels: {
    __typename?: 'LabelList';
    total?: number | null;
    nextPageToken?: string | null;
    items: Array<{
      __typename?: 'Label';
      id: string;
      title: string;
      color: string;
      default: boolean;
      scopes: Array<Scope>;
      readOnly: boolean;
      createdAt: string;
      firestoreId: string;
    }>;
  };
};

export type GetLabelQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetLabelQuery = {
  __typename?: 'Query';
  getLabel: {
    __typename?: 'Label';
    id: string;
    title: string;
    color: string;
    default: boolean;
    scopes: Array<Scope>;
    readOnly: boolean;
    createdAt: string;
    firestoreId: string;
  };
};

export type CreateLabelMutationVariables = Exact<{
  input: CreateLabelInput;
}>;

export type CreateLabelMutation = {
  __typename?: 'Mutation';
  createLabel: {
    __typename?: 'Label';
    id: string;
    title: string;
    color: string;
    default: boolean;
    scopes: Array<Scope>;
    readOnly: boolean;
    createdAt: string;
    firestoreId: string;
  };
};

export type UpdateLabelMutationVariables = Exact<{
  input: UpdateLabelInput;
  id: Scalars['String']['input'];
}>;

export type UpdateLabelMutation = {
  __typename?: 'Mutation';
  updateLabel: {
    __typename?: 'Label';
    id: string;
    title: string;
    color: string;
    default: boolean;
    scopes: Array<Scope>;
    readOnly: boolean;
    createdAt: string;
    firestoreId: string;
  };
};

export type RemoveLabelMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveLabelMutation = {
  __typename?: 'Mutation';
  removeLabel: { __typename?: 'OperationStatus'; success: boolean };
};

export type RankLabelMutationVariables = Exact<{
  input: RankLabelInput;
  rankLabelId: Scalars['String']['input'];
}>;

export type RankLabelMutation = {
  __typename?: 'Mutation';
  rankLabel: {
    __typename?: 'RankResult';
    id: string;
    afterId?: string | null;
    beforeId?: string | null;
  };
};

export type ListOptionsFragment = {
  __typename?: 'ListOptions';
  removeFiltered: boolean;
  removeUnsubscribed: boolean;
  removeInternalDnc: boolean;
  removeExisting: boolean;
  removeNonSmsCapable: boolean;
  removeLitigators: boolean;
  removeNationalDnc: boolean;
  removeCarrierBlocked: boolean;
  removeGlobalUnsubscribed: boolean;
  removeUndeliverable: boolean;
};

export type ListVerificationResultFragment = {
  __typename?: 'ListVerificationResult';
  listId: string;
  verified: boolean;
  totalPhones: number;
  verifiedPhones: number;
  filtered: number;
  unsubscribed: number;
  internalDnc: number;
  existing: number;
  nonSmsCapable: number;
  litigators: number;
  nationalDnc: number;
  undeliverable: number;
  carrierBlocked: number;
  globalUnsubscribed: number;
};

export type GetListQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetListQuery = {
  __typename?: 'Query';
  getList: {
    __typename?: 'ListView';
    id: string;
    type: ListType;
    options?: {
      __typename?: 'ListOptions';
      removeFiltered: boolean;
      removeUnsubscribed: boolean;
      removeInternalDnc: boolean;
      removeExisting: boolean;
      removeNonSmsCapable: boolean;
      removeLitigators: boolean;
      removeNationalDnc: boolean;
      removeCarrierBlocked: boolean;
      removeGlobalUnsubscribed: boolean;
      removeUndeliverable: boolean;
    } | null;
    fields?: Array<{
      __typename?: 'Field';
      id: string;
      key?: PredefinedField | null;
      predefined: boolean;
      name: string;
    }> | null;
  };
};

export type ExportListQueryVariables = Exact<{
  input: ExportListInput;
}>;

export type ExportListQuery = {
  __typename?: 'Query';
  exportList: { __typename?: 'SignedUrl'; signedUrl: string; filePath: string };
};

export type FindListItemsQueryVariables = Exact<{
  listId: Scalars['String']['input'];
  pagination?: InputMaybe<ListItemsPagination>;
}>;

export type FindListItemsQuery = {
  __typename?: 'Query';
  findListItems: {
    __typename?: 'ListItemList';
    total?: number | null;
    hasNext?: boolean | null;
    nextRowIndex?: number | null;
    items: Array<{
      __typename?: 'ListItemView';
      id: string;
      data: Array<string>;
      rowIndex: number;
    }>;
  };
};

export type UpdateListMutationVariables = Exact<{
  input: UpdateListInput;
  id: Scalars['String']['input'];
}>;

export type UpdateListMutation = {
  __typename?: 'Mutation';
  updateList: {
    __typename?: 'ListView';
    id: string;
    options?: {
      __typename?: 'ListOptions';
      removeFiltered: boolean;
      removeUnsubscribed: boolean;
      removeInternalDnc: boolean;
      removeExisting: boolean;
      removeNonSmsCapable: boolean;
      removeLitigators: boolean;
      removeNationalDnc: boolean;
      removeCarrierBlocked: boolean;
      removeGlobalUnsubscribed: boolean;
      removeUndeliverable: boolean;
    } | null;
    fields?: Array<{
      __typename?: 'Field';
      id: string;
      key?: PredefinedField | null;
      predefined: boolean;
      name: string;
    }> | null;
  };
};

export type VerifyListMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type VerifyListMutation = {
  __typename?: 'Mutation';
  verifyList: { __typename?: 'OperationStatus'; success: boolean };
};

export type RemoveListItemMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveListItemMutation = {
  __typename?: 'Mutation';
  removeListItem: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateListItemMutationVariables = Exact<{
  input: UpdateListItemInput;
  id: Scalars['String']['input'];
}>;

export type UpdateListItemMutation = {
  __typename?: 'Mutation';
  updateListItem: { __typename?: 'OperationStatus'; success: boolean };
};

export type GetListUploadSignedUrlQueryVariables = Exact<{
  input: GenerateSignedUrlInput;
}>;

export type GetListUploadSignedUrlQuery = {
  __typename?: 'Query';
  getListUploadSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type CreateListMutationVariables = Exact<{
  input: CreateListInput;
}>;

export type CreateListMutation = {
  __typename?: 'Mutation';
  createList: {
    __typename?: 'ListView';
    id: string;
    type: ListType;
    options?: {
      __typename?: 'ListOptions';
      removeFiltered: boolean;
      removeUnsubscribed: boolean;
      removeInternalDnc: boolean;
      removeExisting: boolean;
      removeNonSmsCapable: boolean;
      removeLitigators: boolean;
      removeNationalDnc: boolean;
      removeCarrierBlocked: boolean;
      removeGlobalUnsubscribed: boolean;
      removeUndeliverable: boolean;
    } | null;
    fields?: Array<{
      __typename?: 'Field';
      id: string;
      name: string;
      predefined: boolean;
      key?: PredefinedField | null;
    }> | null;
  };
};

export type ListVerificationProgressSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ListVerificationProgressSubscription = {
  __typename?: 'Subscription';
  listVerificationProgress: {
    __typename?: 'ListVerificationProgress';
    listId: string;
    progress: number;
  };
};

export type ListVerificationCompletedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ListVerificationCompletedSubscription = {
  __typename?: 'Subscription';
  listVerificationCompleted: {
    __typename?: 'ListVerificationResult';
    listId: string;
    verified: boolean;
    totalPhones: number;
    verifiedPhones: number;
    filtered: number;
    unsubscribed: number;
    internalDnc: number;
    existing: number;
    nonSmsCapable: number;
    litigators: number;
    nationalDnc: number;
    undeliverable: number;
    carrierBlocked: number;
    globalUnsubscribed: number;
  };
};

export type ListUploadProgressSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ListUploadProgressSubscription = {
  __typename?: 'Subscription';
  listUploadProgress: {
    __typename?: 'ListUploadProgress';
    listId: string;
    progress: number;
  };
};

export type ListUploadCompletedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ListUploadCompletedSubscription = {
  __typename?: 'Subscription';
  listUploadCompleted: { __typename?: 'ListUploadCompleted'; listId: string };
};

export type MacroFragment = {
  __typename?: 'Macro';
  id: string;
  name: string;
  content: string;
};

export type FindMacrosQueryVariables = Exact<{
  filter?: InputMaybe<MacrosFilter>;
  pagination?: InputMaybe<CursorPagination>;
}>;

export type FindMacrosQuery = {
  __typename?: 'Query';
  findMacros: {
    __typename?: 'MacroList';
    total?: number | null;
    nextPageToken?: string | null;
    items: Array<{
      __typename?: 'Macro';
      id: string;
      name: string;
      content: string;
    }>;
  };
};

export type GetMacroQueryVariables = Exact<{
  getMacroId: Scalars['String']['input'];
}>;

export type GetMacroQuery = {
  __typename?: 'Query';
  getMacro: { __typename?: 'Macro'; id: string; name: string; content: string };
};

export type CreateMacroMutationVariables = Exact<{
  input: CreateMacroInput;
}>;

export type CreateMacroMutation = {
  __typename?: 'Mutation';
  createMacro: {
    __typename?: 'Macro';
    id: string;
    name: string;
    content: string;
  };
};

export type UpdateMacroMutationVariables = Exact<{
  input: UpdateMacroInput;
  updateMacroId: Scalars['String']['input'];
}>;

export type UpdateMacroMutation = {
  __typename?: 'Mutation';
  updateMacro: {
    __typename?: 'Macro';
    id: string;
    name: string;
    content: string;
  };
};

export type RemoveMacroMutationVariables = Exact<{
  removeMacroId: Scalars['String']['input'];
}>;

export type RemoveMacroMutation = {
  __typename?: 'Mutation';
  removeMacro: { __typename?: 'OperationStatus'; success: boolean };
};

export type MessageTemplateFragment = {
  __typename?: 'MessageTemplate';
  id: string;
  firestoreId?: string | null;
  name: string;
  type: MessageTemplateType;
  characters?: number | null;
  segments?: number | null;
  createdAt: string;
  template: {
    __typename?: 'MessageTemplateTemplate';
    audioFileBucket?: string | null;
    audioFilePath?: string | null;
    audioFileUrl?: string | null;
    content?: string | null;
    contentType?: string | null;
    description?: string | null;
    optOut?: boolean | null;
    messageType?: MessageTemplateMessageType | null;
    characters?: number | null;
    segments?: number | null;
  };
};

export type FindMessageTemplatesQueryVariables = Exact<{
  filter?: InputMaybe<MessageTemplatesFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindMessageTemplatesQuery = {
  __typename?: 'Query';
  findMessageTemplates: {
    __typename?: 'MessageTemplateList';
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    total?: number | null;
    items: Array<{
      __typename?: 'MessageTemplate';
      id: string;
      firestoreId?: string | null;
      name: string;
      type: MessageTemplateType;
      characters?: number | null;
      segments?: number | null;
      createdAt: string;
      template: {
        __typename?: 'MessageTemplateTemplate';
        audioFileBucket?: string | null;
        audioFilePath?: string | null;
        audioFileUrl?: string | null;
        content?: string | null;
        contentType?: string | null;
        description?: string | null;
        optOut?: boolean | null;
        messageType?: MessageTemplateMessageType | null;
        characters?: number | null;
        segments?: number | null;
      };
    }>;
  };
};

export type GetMessageTemplateQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetMessageTemplateQuery = {
  __typename?: 'Query';
  getMessageTemplate: {
    __typename?: 'MessageTemplate';
    id: string;
    firestoreId?: string | null;
    name: string;
    type: MessageTemplateType;
    characters?: number | null;
    segments?: number | null;
    createdAt: string;
    template: {
      __typename?: 'MessageTemplateTemplate';
      audioFileBucket?: string | null;
      audioFilePath?: string | null;
      audioFileUrl?: string | null;
      content?: string | null;
      contentType?: string | null;
      description?: string | null;
      optOut?: boolean | null;
      messageType?: MessageTemplateMessageType | null;
      characters?: number | null;
      segments?: number | null;
    };
  };
};

export type FindMessageTemplatesForFuckingFirestoreQueryVariables = Exact<{
  filter?: InputMaybe<MessageTemplatesFilter>;
}>;

export type FindMessageTemplatesForFuckingFirestoreQuery = {
  __typename?: 'Query';
  findMessageTemplatesForFuckingFirestore: Array<{
    __typename?: 'MessageTemplate';
    id: string;
    firestoreId?: string | null;
    name: string;
    type: MessageTemplateType;
    characters?: number | null;
    segments?: number | null;
    createdAt: string;
    template: {
      __typename?: 'MessageTemplateTemplate';
      audioFileBucket?: string | null;
      audioFilePath?: string | null;
      audioFileUrl?: string | null;
      content?: string | null;
      contentType?: string | null;
      description?: string | null;
      optOut?: boolean | null;
      messageType?: MessageTemplateMessageType | null;
      characters?: number | null;
      segments?: number | null;
    };
  }>;
};

export type CreateMessageTemplateMutationVariables = Exact<{
  input: CreateMessageTemplateInput;
}>;

export type CreateMessageTemplateMutation = {
  __typename?: 'Mutation';
  createMessageTemplate: {
    __typename?: 'MessageTemplate';
    id: string;
    firestoreId?: string | null;
    name: string;
    type: MessageTemplateType;
    characters?: number | null;
    segments?: number | null;
    createdAt: string;
    template: {
      __typename?: 'MessageTemplateTemplate';
      audioFileBucket?: string | null;
      audioFilePath?: string | null;
      audioFileUrl?: string | null;
      content?: string | null;
      contentType?: string | null;
      description?: string | null;
      optOut?: boolean | null;
      messageType?: MessageTemplateMessageType | null;
      characters?: number | null;
      segments?: number | null;
    };
  };
};

export type UpdateMessageTemplateMutationVariables = Exact<{
  input: UpdateMessageTemplateInput;
  updateMessageTemplateId: Scalars['String']['input'];
}>;

export type UpdateMessageTemplateMutation = {
  __typename?: 'Mutation';
  updateMessageTemplate: {
    __typename?: 'MessageTemplate';
    id: string;
    firestoreId?: string | null;
    name: string;
    type: MessageTemplateType;
    characters?: number | null;
    segments?: number | null;
    createdAt: string;
    template: {
      __typename?: 'MessageTemplateTemplate';
      audioFileBucket?: string | null;
      audioFilePath?: string | null;
      audioFileUrl?: string | null;
      content?: string | null;
      contentType?: string | null;
      description?: string | null;
      optOut?: boolean | null;
      messageType?: MessageTemplateMessageType | null;
      characters?: number | null;
      segments?: number | null;
    };
  };
};

export type RemoveMessageTemplateMutationVariables = Exact<{
  removeMessageTemplateId: Scalars['String']['input'];
}>;

export type RemoveMessageTemplateMutation = {
  __typename?: 'Mutation';
  removeMessageTemplate: { __typename?: 'OperationStatus'; success: boolean };
};

export type RankMessageTemplateMutationVariables = Exact<{
  input: RankMessageTemplateInput;
  rankMessageTemplateId: Scalars['String']['input'];
}>;

export type RankMessageTemplateMutation = {
  __typename?: 'Mutation';
  rankMessageTemplate: { __typename?: 'OperationStatus'; success: boolean };
};

export type BuildMessageTemplatesQueryVariables = Exact<{
  input: BuildMessageTemplateInput;
}>;

export type BuildMessageTemplatesQuery = {
  __typename?: 'Query';
  buildMessageTemplates: Array<{
    __typename?: 'MessageTemplate';
    id: string;
    firestoreId?: string | null;
    name: string;
    type: MessageTemplateType;
    characters?: number | null;
    segments?: number | null;
    createdAt: string;
    template: {
      __typename?: 'MessageTemplateTemplate';
      audioFileBucket?: string | null;
      audioFilePath?: string | null;
      audioFileUrl?: string | null;
      content?: string | null;
      contentType?: string | null;
      description?: string | null;
      optOut?: boolean | null;
      messageType?: MessageTemplateMessageType | null;
      characters?: number | null;
      segments?: number | null;
    };
  }>;
};

export type GetMessageTemplateSignedUrlQueryVariables = Exact<{
  input: GenerateSignedUrlInput;
}>;

export type GetMessageTemplateSignedUrlQuery = {
  __typename?: 'Query';
  getMessageTemplateSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type MessagingProfileFragment = {
  __typename?: 'MessagingProfile';
  id: string;
  allowUrl: boolean;
  isEINDocRequired: boolean;
  phoneSettings?: {
    __typename?: 'PhoneSettings';
    activeIn?: string | null;
    isSipEnabled?: boolean | null;
    notificationsEnabled?: boolean | null;
    alertFilter?: {
      __typename?: 'PhoneSettingsFilter';
      campaigns?: Array<string> | null;
      groups?: Array<string> | null;
      labels?: Array<string> | null;
    } | null;
    notificationFilter?: {
      __typename?: 'PhoneSettingsFilter';
      campaigns?: Array<string> | null;
      groups?: Array<string> | null;
      labels?: Array<string> | null;
    } | null;
    callAutoReply?: {
      __typename?: 'PhoneSettingsAutoReply';
      enabled?: boolean | null;
      voiceType?: PhoneSettingsVoiceType | null;
      payload?: string | null;
    } | null;
    voiceMail?: {
      __typename?: 'PhoneSettingsAutoReply';
      enabled?: boolean | null;
      voiceType?: PhoneSettingsVoiceType | null;
      payload?: string | null;
    } | null;
  } | null;
  callSettings?: {
    __typename?: 'CallSettings';
    activeIn?: string | null;
    isSipEnabled?: boolean | null;
    callAutoReply?: {
      __typename?: 'CallSettingsAutoReply';
      enabled?: boolean | null;
      voiceType?: CallSettingsVoiceType | null;
      payload?: string | null;
    } | null;
    voiceMail?: {
      __typename?: 'CallSettingsAutoReply';
      enabled?: boolean | null;
      voiceType?: CallSettingsVoiceType | null;
      payload?: string | null;
    } | null;
  } | null;
  a2pInfo?: {
    __typename?: 'A2PInfo';
    status: A2PInfoStatus;
    maxTpm: number;
  } | null;
  tollFreeInfo?: {
    __typename?: 'TollFreeInfo';
    status: TollFreeInfoStatus;
    maxTpm: number;
    limits: {
      __typename?: 'TollFreeInfoLimits';
      daily: number;
      weekly: number;
      monthly: number;
    };
  } | null;
  responseRemoval?: {
    __typename?: 'ResponseRemoval';
    enabled?: boolean | null;
    responseFilters?: Array<string> | null;
  } | null;
  provider?: {
    __typename?: 'MessagingProvider';
    sipPassword?: string | null;
    sipUsername?: string | null;
    type: MessagingProviderType;
  } | null;
};

export type GetMessagingProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetMessagingProfileQuery = {
  __typename?: 'Query';
  getMessagingProfile: {
    __typename?: 'MessagingProfile';
    id: string;
    allowUrl: boolean;
    isEINDocRequired: boolean;
    phoneSettings?: {
      __typename?: 'PhoneSettings';
      activeIn?: string | null;
      isSipEnabled?: boolean | null;
      notificationsEnabled?: boolean | null;
      alertFilter?: {
        __typename?: 'PhoneSettingsFilter';
        campaigns?: Array<string> | null;
        groups?: Array<string> | null;
        labels?: Array<string> | null;
      } | null;
      notificationFilter?: {
        __typename?: 'PhoneSettingsFilter';
        campaigns?: Array<string> | null;
        groups?: Array<string> | null;
        labels?: Array<string> | null;
      } | null;
      callAutoReply?: {
        __typename?: 'PhoneSettingsAutoReply';
        enabled?: boolean | null;
        voiceType?: PhoneSettingsVoiceType | null;
        payload?: string | null;
      } | null;
      voiceMail?: {
        __typename?: 'PhoneSettingsAutoReply';
        enabled?: boolean | null;
        voiceType?: PhoneSettingsVoiceType | null;
        payload?: string | null;
      } | null;
    } | null;
    callSettings?: {
      __typename?: 'CallSettings';
      activeIn?: string | null;
      isSipEnabled?: boolean | null;
      callAutoReply?: {
        __typename?: 'CallSettingsAutoReply';
        enabled?: boolean | null;
        voiceType?: CallSettingsVoiceType | null;
        payload?: string | null;
      } | null;
      voiceMail?: {
        __typename?: 'CallSettingsAutoReply';
        enabled?: boolean | null;
        voiceType?: CallSettingsVoiceType | null;
        payload?: string | null;
      } | null;
    } | null;
    a2pInfo?: {
      __typename?: 'A2PInfo';
      status: A2PInfoStatus;
      maxTpm: number;
    } | null;
    tollFreeInfo?: {
      __typename?: 'TollFreeInfo';
      status: TollFreeInfoStatus;
      maxTpm: number;
      limits: {
        __typename?: 'TollFreeInfoLimits';
        daily: number;
        weekly: number;
        monthly: number;
      };
    } | null;
    responseRemoval?: {
      __typename?: 'ResponseRemoval';
      enabled?: boolean | null;
      responseFilters?: Array<string> | null;
    } | null;
    provider?: {
      __typename?: 'MessagingProvider';
      sipPassword?: string | null;
      sipUsername?: string | null;
      type: MessagingProviderType;
    } | null;
  };
};

export type UpdateMessagingProfileMutationVariables = Exact<{
  input: UpdateMessagingProfileInput;
}>;

export type UpdateMessagingProfileMutation = {
  __typename?: 'Mutation';
  updateMessagingProfile: { __typename?: 'MessagingProfile'; id: string };
};

export type DetailsNotificationFragment = {
  __typename?: 'NotificationDetails';
  name?: string | null;
  relationId?: string | null;
  isKeywordCampaign?: boolean | null;
  calendarEventName?: string | null;
  calendarEventDate?: string | null;
  calendarEventId?: string | null;
};

export type NotificationFragment = {
  __typename?: 'Notification';
  id: string;
  type: NotificationType;
  isRead: boolean;
  isSeen: boolean;
  createdAt: string;
  details?: {
    __typename?: 'NotificationDetails';
    name?: string | null;
    relationId?: string | null;
    isKeywordCampaign?: boolean | null;
    calendarEventName?: string | null;
    calendarEventDate?: string | null;
    calendarEventId?: string | null;
  } | null;
};

export type NotificationSettingsFragment = {
  __typename?: 'NotificationSettings';
  enabledCampaigns?: boolean | null;
  enabledPayments?: boolean | null;
  enabledCalendar?: boolean | null;
};

export type FindNotificationsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;

export type FindNotificationsQuery = {
  __typename?: 'Query';
  findNotifications: {
    __typename?: 'NotificationList';
    hasNext?: boolean | null;
    nextCreatedAt?: string | null;
    total?: number | null;
    nextId?: string | null;
    items: Array<{
      __typename?: 'Notification';
      id: string;
      type: NotificationType;
      isRead: boolean;
      isSeen: boolean;
      createdAt: string;
      details?: {
        __typename?: 'NotificationDetails';
        name?: string | null;
        relationId?: string | null;
        isKeywordCampaign?: boolean | null;
        calendarEventName?: string | null;
        calendarEventDate?: string | null;
        calendarEventId?: string | null;
      } | null;
    }>;
  };
};

export type GetNotificationSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetNotificationSettingsQuery = {
  __typename?: 'Query';
  getNotificationSettings: {
    __typename?: 'NotificationSettings';
    enabledCampaigns?: boolean | null;
    enabledPayments?: boolean | null;
    enabledCalendar?: boolean | null;
  };
};

export type GetUnreadNotificationCounterQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUnreadNotificationCounterQuery = {
  __typename?: 'Query';
  getUnreadNotificationCounter: {
    __typename?: 'NotificationCounter';
    accountId: string;
    unreadCount: number;
  };
};

export type MarkNotificationsReadMutationVariables = Exact<{
  input: MarkNotificationsReadInput;
}>;

export type MarkNotificationsReadMutation = {
  __typename?: 'Mutation';
  markNotificationsRead: { __typename?: 'OperationStatus'; success: boolean };
};

export type MarkNotificationsSeenMutationVariables = Exact<{
  input: MarkNotificationsSeenInput;
}>;

export type MarkNotificationsSeenMutation = {
  __typename?: 'Mutation';
  markNotificationsSeen: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateNotificationSettingsMutationVariables = Exact<{
  input: UpdateNotificationSettingsInput;
}>;

export type UpdateNotificationSettingsMutation = {
  __typename?: 'Mutation';
  updateNotificationSettings: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type NotificationCreatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type NotificationCreatedSubscription = {
  __typename?: 'Subscription';
  notificationCreated: {
    __typename?: 'Notification';
    id: string;
    type: NotificationType;
    isRead: boolean;
    isSeen: boolean;
    createdAt: string;
    details?: {
      __typename?: 'NotificationDetails';
      name?: string | null;
      relationId?: string | null;
      isKeywordCampaign?: boolean | null;
      calendarEventName?: string | null;
      calendarEventDate?: string | null;
      calendarEventId?: string | null;
    } | null;
  };
};

export type NotificationUnreadCounterUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type NotificationUnreadCounterUpdatedSubscription = {
  __typename?: 'Subscription';
  notificationUnreadCounterUpdated: {
    __typename?: 'NotificationCounter';
    accountId: string;
    unreadCount: number;
  };
};

export type OwnPhoneFragment = {
  __typename?: 'OwnPhone';
  id: string;
  status: OwnPhoneStatus;
  type: OwnPhoneType;
  number: string;
  scope: Scope;
  isTrial: boolean;
  createdAt: string;
  canBeRemoved: boolean;
  callingEnabled: boolean;
  messagingEnabled: boolean;
  location?: {
    __typename?: 'PhoneNumberLocation';
    abbreviation: string;
    state: string;
  } | null;
};

export type OwnPhoneReportFragment = {
  __typename?: 'OwnPhone';
  id: string;
  status: OwnPhoneStatus;
  type: OwnPhoneType;
  number: string;
  scope: Scope;
  isTrial: boolean;
  createdAt: string;
  canBeRemoved: boolean;
  callingEnabled: boolean;
  messagingEnabled: boolean;
  location?: {
    __typename?: 'PhoneNumberLocation';
    abbreviation: string;
    state: string;
  } | null;
  reportDialer?: {
    __typename?: 'OwnDialerPhoneReportView';
    phoneId: string;
    outboundCalls?: number | null;
  } | null;
  reportMessage?: {
    __typename?: 'OwnPhoneReport';
    phoneId: string;
    outgoingMessages: number;
    blockedRateMessages: number;
    activeConversations: number;
    lastUsedAt?: string | null;
  } | null;
};

export type FindAvailablePhonesQueryVariables = Exact<{
  filter: FindAvailablePhonesFilter;
}>;

export type FindAvailablePhonesQuery = {
  __typename?: 'Query';
  findAvailablePhones: Array<{
    __typename?: 'AvailablePhoneView';
    phone: string;
  }>;
};

export type FindOwnPhonesQueryVariables = Exact<{
  filter?: InputMaybe<OwnPhoneFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindOwnPhonesQuery = {
  __typename?: 'Query';
  findOwnPhones: {
    __typename?: 'OwnPhoneList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'OwnPhone';
      id: string;
      status: OwnPhoneStatus;
      type: OwnPhoneType;
      number: string;
      scope: Scope;
      isTrial: boolean;
      createdAt: string;
      canBeRemoved: boolean;
      callingEnabled: boolean;
      messagingEnabled: boolean;
      location?: {
        __typename?: 'PhoneNumberLocation';
        abbreviation: string;
        state: string;
      } | null;
    }>;
  };
};

export type FindOwnPhonesReportQueryVariables = Exact<{
  filter?: InputMaybe<OwnPhoneFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindOwnPhonesReportQuery = {
  __typename?: 'Query';
  findOwnPhones: {
    __typename?: 'OwnPhoneList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'OwnPhone';
      id: string;
      status: OwnPhoneStatus;
      type: OwnPhoneType;
      number: string;
      scope: Scope;
      isTrial: boolean;
      createdAt: string;
      canBeRemoved: boolean;
      callingEnabled: boolean;
      messagingEnabled: boolean;
      location?: {
        __typename?: 'PhoneNumberLocation';
        abbreviation: string;
        state: string;
      } | null;
      reportDialer?: {
        __typename?: 'OwnDialerPhoneReportView';
        phoneId: string;
        outboundCalls?: number | null;
      } | null;
      reportMessage?: {
        __typename?: 'OwnPhoneReport';
        phoneId: string;
        outgoingMessages: number;
        blockedRateMessages: number;
        activeConversations: number;
        lastUsedAt?: string | null;
      } | null;
    }>;
  };
};

export type GetOwnPhoneCountersQueryVariables = Exact<{
  scope?: InputMaybe<Scope>;
}>;

export type GetOwnPhoneCountersQuery = {
  __typename?: 'Query';
  getOwnPhoneCounters: {
    __typename?: 'OwnPhoneCounters';
    local: number;
    tollFree: number;
  };
};

export type PurchaseOwnPhoneMutationVariables = Exact<{
  input: PurchaseOwnPhoneInput;
}>;

export type PurchaseOwnPhoneMutation = {
  __typename?: 'Mutation';
  purchaseOwnPhone: { __typename?: 'OwnPhone'; id: string; number: string };
};

export type RemoveOwnPhoneMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveOwnPhoneMutation = {
  __typename?: 'Mutation';
  removeOwnPhone: { __typename?: 'OperationStatus'; success: boolean };
};

export type PlanFragment = {
  __typename?: 'Plan';
  id: string;
  active: boolean;
  enterprise: boolean;
  base: boolean;
  default: boolean;
  type: PlanType;
  interval: BillingInterval;
  title: string;
  userTitle: string;
  product: Product;
  price: number;
  displayPrice: number;
  discount: number;
  secondary: boolean;
  lexorank?: string | null;
  mostPopular: boolean;
  monthlyPrice?: number | null;
  couponId?: string | null;
  features: Array<{
    __typename?: 'PlanFeature';
    type: FeatureType;
    title: string;
    tier?: number | null;
    limit?: number | null;
    min?: number | null;
    price?: number | null;
    trialLimits: {
      __typename?: 'PlanFeatureTrial';
      use: boolean;
      tier?: number | null;
      limit?: number | null;
    };
  }>;
};

export type GetActivePlansQueryVariables = Exact<{ [key: string]: never }>;

export type GetActivePlansQuery = {
  __typename?: 'Query';
  getActivePlans: Array<{
    __typename?: 'Plan';
    id: string;
    active: boolean;
    enterprise: boolean;
    base: boolean;
    default: boolean;
    type: PlanType;
    interval: BillingInterval;
    title: string;
    userTitle: string;
    product: Product;
    price: number;
    displayPrice: number;
    discount: number;
    secondary: boolean;
    lexorank?: string | null;
    mostPopular: boolean;
    monthlyPrice?: number | null;
    couponId?: string | null;
    features: Array<{
      __typename?: 'PlanFeature';
      type: FeatureType;
      title: string;
      tier?: number | null;
      limit?: number | null;
      min?: number | null;
      price?: number | null;
      trialLimits: {
        __typename?: 'PlanFeatureTrial';
        use: boolean;
        tier?: number | null;
        limit?: number | null;
      };
    }>;
  }>;
};

export type GetPlansQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlansQuery = {
  __typename?: 'Query';
  getPlans: Array<{
    __typename?: 'Plan';
    id: string;
    active: boolean;
    enterprise: boolean;
    base: boolean;
    default: boolean;
    type: PlanType;
    interval: BillingInterval;
    title: string;
    userTitle: string;
    product: Product;
    price: number;
    displayPrice: number;
    discount: number;
    secondary: boolean;
    lexorank?: string | null;
    mostPopular: boolean;
    monthlyPrice?: number | null;
    couponId?: string | null;
    features: Array<{
      __typename?: 'PlanFeature';
      type: FeatureType;
      title: string;
      tier?: number | null;
      limit?: number | null;
      min?: number | null;
      price?: number | null;
      trialLimits: {
        __typename?: 'PlanFeatureTrial';
        use: boolean;
        tier?: number | null;
        limit?: number | null;
      };
    }>;
  }>;
};

export type MarketingPopupFragment = {
  __typename?: 'MarketingPopup';
  id: string;
  status: MarketingPopupStatus;
  audiences?: Array<MarketingPopupAudience> | null;
  title?: string | null;
  description?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  createdAt: string;
  updatedAt: string;
  template?: {
    __typename?: 'MarketingPopupTemplate';
    type: MarketingPopupTemplateType;
    imageSrc?: string | null;
    videoSrc?: string | null;
    buttonText?: string | null;
    buttonLink?: string | null;
  } | null;
};

export type CreateMarketingPopupMutationVariables = Exact<{
  input: CreateMarketingPopupInput;
}>;

export type CreateMarketingPopupMutation = {
  __typename?: 'Mutation';
  createMarketingPopup: {
    __typename?: 'MarketingPopup';
    id: string;
    status: MarketingPopupStatus;
    audiences?: Array<MarketingPopupAudience> | null;
    title?: string | null;
    description?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    createdAt: string;
    updatedAt: string;
    template?: {
      __typename?: 'MarketingPopupTemplate';
      type: MarketingPopupTemplateType;
      imageSrc?: string | null;
      videoSrc?: string | null;
      buttonText?: string | null;
      buttonLink?: string | null;
    } | null;
  };
};

export type GetMarketingPopupSignedUrlQueryVariables = Exact<{
  input: GenerateSignedUrlInput;
}>;

export type GetMarketingPopupSignedUrlQuery = {
  __typename?: 'Query';
  getMarketingPopupSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type FindMarketingPopupsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;

export type FindMarketingPopupsQuery = {
  __typename?: 'Query';
  findMarketingPopups: {
    __typename?: 'MarketingPopupList';
    total?: number | null;
    nextId?: string | null;
    hasNext?: boolean | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'MarketingPopup';
      id: string;
      status: MarketingPopupStatus;
      audiences?: Array<MarketingPopupAudience> | null;
      title?: string | null;
      description?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      createdAt: string;
      updatedAt: string;
      template?: {
        __typename?: 'MarketingPopupTemplate';
        type: MarketingPopupTemplateType;
        imageSrc?: string | null;
        videoSrc?: string | null;
        buttonText?: string | null;
        buttonLink?: string | null;
      } | null;
    }>;
  };
};

export type RemoveMarketingPopupMutationVariables = Exact<{
  removeMarketingPopupId: Scalars['String']['input'];
}>;

export type RemoveMarketingPopupMutation = {
  __typename?: 'Mutation';
  removeMarketingPopup: { __typename?: 'OperationStatus'; success: boolean };
};

export type GetMarketingPopupQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetMarketingPopupQuery = {
  __typename?: 'Query';
  getMarketingPopup: {
    __typename?: 'MarketingPopup';
    id: string;
    status: MarketingPopupStatus;
    audiences?: Array<MarketingPopupAudience> | null;
    title?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
    template?: {
      __typename?: 'MarketingPopupTemplate';
      type: MarketingPopupTemplateType;
      imageSrc?: string | null;
      videoSrc?: string | null;
      buttonText?: string | null;
      buttonLink?: string | null;
    } | null;
  };
};

export type UpdateMarketingPopupMutationVariables = Exact<{
  input: UpdateMarketingPopupInput;
  id: Scalars['String']['input'];
}>;

export type UpdateMarketingPopupMutation = {
  __typename?: 'Mutation';
  updateMarketingPopup: {
    __typename?: 'MarketingPopup';
    id: string;
    status: MarketingPopupStatus;
    audiences?: Array<MarketingPopupAudience> | null;
    title?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
    template?: {
      __typename?: 'MarketingPopupTemplate';
      type: MarketingPopupTemplateType;
      imageSrc?: string | null;
      videoSrc?: string | null;
      buttonText?: string | null;
      buttonLink?: string | null;
    } | null;
  };
};

export type FindActiveMarketingPopupsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FindActiveMarketingPopupsQuery = {
  __typename?: 'Query';
  findActiveMarketingPopups: Array<{
    __typename?: 'MarketingPopup';
    id: string;
    status: MarketingPopupStatus;
    title?: string | null;
    description?: string | null;
    template?: {
      __typename?: 'MarketingPopupTemplate';
      type: MarketingPopupTemplateType;
      imageSrc?: string | null;
      videoSrc?: string | null;
      buttonText?: string | null;
      buttonLink?: string | null;
    } | null;
  }>;
};

export type MarkAccountPopupViewedMutationVariables = Exact<{
  input: MarkAccountPopupViewedInput;
}>;

export type MarkAccountPopupViewedMutation = {
  __typename?: 'Mutation';
  markAccountPopupViewed: { __typename?: 'AccountPopup'; popupId: string };
};

export type GetPowerDialerCampaignQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetPowerDialerCampaignQuery = {
  __typename?: 'Query';
  getDialerCampaign: {
    __typename?: 'DialerCampaign';
    id: string;
    status: CampaignStatus;
    lines: Array<string>;
    labels: Array<string>;
    script?: string | null;
    contactsWithOutcome?: number | null;
    contacts: number;
  };
};

export type FindPowerDialerOutcomeLabelsQueryVariables = Exact<{
  filter?: InputMaybe<LabelFilter>;
}>;

export type FindPowerDialerOutcomeLabelsQuery = {
  __typename?: 'Query';
  findLabels: {
    __typename?: 'LabelList';
    items: Array<{
      __typename?: 'Label';
      id: string;
      title: string;
      color: string;
    }>;
  };
};

export type GeneratePowerDialerWebRtcTokenQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GeneratePowerDialerWebRtcTokenQuery = {
  __typename?: 'Query';
  generatePowerDialerWebRTCToken: { __typename?: 'WebRTCToken'; token: string };
};

export type GetPowerDialerCallingBarContactQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetPowerDialerCallingBarContactQuery = {
  __typename?: 'Query';
  getContact: {
    __typename?: 'Contact';
    phone: string;
    firstName?: string | null;
    lastName?: string | null;
  };
};

export type ManagePowerDialerCallRecordingMutationVariables = Exact<{
  input: ManagePowerDialerCallRecordingInput;
}>;

export type ManagePowerDialerCallRecordingMutation = {
  __typename?: 'Mutation';
  managePowerDialerCallRecording: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type StartPowerDialingMutationVariables = Exact<{
  input: StartPowerDialingInput;
}>;

export type StartPowerDialingMutation = {
  __typename?: 'Mutation';
  startPowerDialing: { __typename?: 'OperationStatus'; success: boolean };
};

export type HangUpPowerDialerCallMutationVariables = Exact<{
  batchId: Scalars['String']['input'];
}>;

export type HangUpPowerDialerCallMutation = {
  __typename?: 'Mutation';
  hangUpPowerDialerCall: { __typename?: 'OperationStatus'; success: boolean };
};

export type AddLabelToCallAndContactMutationVariables = Exact<{
  input: AddLabelToCallAndContactInput;
}>;

export type AddLabelToCallAndContactMutation = {
  __typename?: 'Mutation';
  addLabelToCallAndContact: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type SwitchDialerOfflineMutationVariables = Exact<{
  inPowerDialer: Scalars['Boolean']['input'];
}>;

export type SwitchDialerOfflineMutation = {
  __typename?: 'Mutation';
  switchDialerOffline: { __typename?: 'OperationStatus'; success: boolean };
};

export type SwitchDialerOnlineMutationVariables = Exact<{
  campaignId: Scalars['String']['input'];
}>;

export type SwitchDialerOnlineMutation = {
  __typename?: 'Mutation';
  switchDialerOnline: { __typename?: 'OperationStatus'; success: boolean };
};

export type LineFragment = {
  __typename?: 'LineView';
  id: string;
  callId?: string | null;
  state: CallState;
  stateReason?: string | null;
  amdResult?: AmdResult | null;
  phone: string;
  contact: {
    __typename?: 'Contact';
    id: string;
    phone: string;
    firstName?: string | null;
    lastName?: string | null;
  };
};

export type PowerDialerStateUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type PowerDialerStateUpdatedSubscription = {
  __typename?: 'Subscription';
  powerDialerStateUpdated: {
    __typename?: 'PowerDialerStateUpdatedView';
    batchId: string;
    campaignId: string;
    connectedLineId?: string | null;
    currentOutboundNumber?: string | null;
    currentContactId?: string | null;
    establishedAt?: string | null;
    duration?: number | null;
    campaignIsCompleted?: boolean | null;
    showOutcomeLabels: boolean;
    isConnecting?: boolean | null;
    isDialing: boolean;
    hangupIsDisabled: boolean;
    isRecording?: boolean | null;
    lines: Array<{
      __typename?: 'LineView';
      id: string;
      callId?: string | null;
      state: CallState;
      stateReason?: string | null;
      amdResult?: AmdResult | null;
      phone: string;
      contact: {
        __typename?: 'Contact';
        id: string;
        phone: string;
        firstName?: string | null;
        lastName?: string | null;
      };
    }>;
  };
};

export type PowerDialerFailedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type PowerDialerFailedSubscription = {
  __typename?: 'Subscription';
  powerDialerFailed: {
    __typename?: 'PowerDialerFailedView';
    errorType: PowerDialerFailedErrorType;
    errorMessage: string;
  };
};

export type GetCampaignReportQueryVariables = Exact<{
  filter: ReportsFilter;
}>;

export type GetCampaignReportQuery = {
  __typename?: 'Query';
  getCampaignReport: {
    __typename?: 'CampaignReport';
    totalStandardCampaigns: number;
    totalKeywordCampaigns: number;
  };
};

export type GetMessageReportQueryVariables = Exact<{
  filter: ReportsFilter;
}>;

export type GetMessageReportQuery = {
  __typename?: 'Query';
  getMessageReport: {
    __typename?: 'MessageReport';
    incomingTotal: number;
    outgoingTotal: number;
    campaignOutgoingTotal: number;
    campaignOutgoingDelivered: number;
    campaignOutgoingBlocked: number;
    campaignIncomingOptedOut: number;
    campaignAiFilteredMessages: number;
    medianResponseTime: string;
  };
};

export type GetLabelReportQueryVariables = Exact<{
  filter: ReportsFilter;
}>;

export type GetLabelReportQuery = {
  __typename?: 'Query';
  getLabelReport: Array<{
    __typename?: 'LabelReportView';
    id: string;
    title: string;
    contactsWithCurrentLabel: number;
    allLabeledContacts: number;
  }>;
};

export type FindCampaignsReportQueryVariables = Exact<{
  filter?: InputMaybe<CampaignFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindCampaignsReportQuery = {
  __typename?: 'Query';
  findCampaigns: {
    __typename?: 'CampaignList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'Campaign';
      id: string;
      type: CampaignType;
      name: string;
      sendFirstMessage?: boolean | null;
      sequences: Array<{
        __typename?: 'SequenceView';
        templates: Array<string>;
      }>;
    }>;
  };
};

export type GetContactReportQueryVariables = Exact<{
  filter: ReportsFilter;
}>;

export type GetContactReportQuery = {
  __typename?: 'Query';
  getContactReport: {
    __typename?: 'ContactReportView';
    leadCount: number;
    totalContacts: number;
    totalContactsResponded: number;
  };
};

export type GetDialerCallReportQueryVariables = Exact<{
  filter?: InputMaybe<DialerReportsFilter>;
}>;

export type GetDialerCallReportQuery = {
  __typename?: 'Query';
  getDialerCallReport: {
    __typename?: 'DialerCallReportView';
    answered: number;
    averageDuration: number;
    conversationRate: number;
    conversations: number;
    responseRate: number;
    total: number;
  };
};

export type GetDialerContactReportQueryVariables = Exact<{
  filter?: InputMaybe<DialerReportsFilter>;
}>;

export type GetDialerContactReportQuery = {
  __typename?: 'Query';
  getDialerContactReport: {
    __typename?: 'DialerContactReportView';
    deals: number;
    leads: number;
  };
};

export type GetDialerCallOutcomeReportQueryVariables = Exact<{
  filter?: InputMaybe<DialerReportsFilter>;
}>;

export type GetDialerCallOutcomeReportQuery = {
  __typename?: 'Query';
  getDialerCallOutcomeReport: Array<{
    __typename?: 'DialerCallOutcomeReportView';
    title: string;
    contactsWithCurrentLabel: number;
    allLabeledContacts: number;
  }>;
};

export type SessionUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type SessionUpdatedSubscription = {
  __typename?: 'Subscription';
  sessionUpdated: { __typename?: 'Session'; online: boolean };
};

export type SkiptraceFieldFragment = {
  __typename?: 'SkiptraceField';
  id: string;
  name: string;
  predefined: boolean;
  key?: SkiptracePredefinedField | null;
};

export type FindSkiptracesQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  order?: InputMaybe<Order>;
}>;

export type FindSkiptracesQuery = {
  __typename?: 'Query';
  findSkiptraces: {
    __typename?: 'SkiptraceList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'Skiptrace';
      id: string;
      fileName: string;
      mobileContacts?: number | null;
      landlineContacts?: number | null;
      createdAt: string;
      excludeFirstRow?: boolean | null;
      fields?: Array<{
        __typename?: 'SkiptraceField';
        id: string;
        name: string;
        predefined: boolean;
        key?: SkiptracePredefinedField | null;
      }> | null;
    }>;
  };
};

export type FindSkiptraceFieldsQueryVariables = Exact<{ [key: string]: never }>;

export type FindSkiptraceFieldsQuery = {
  __typename?: 'Query';
  findSkiptraceFields: Array<{
    __typename?: 'SkiptraceField';
    id: string;
    name: string;
    predefined: boolean;
    key?: SkiptracePredefinedField | null;
  }>;
};

export type GetSkiptraceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetSkiptraceQuery = {
  __typename?: 'Query';
  getSkiptrace: {
    __typename?: 'Skiptrace';
    id: string;
    fileName: string;
    mobileContacts?: number | null;
    landlineContacts?: number | null;
    createdAt: string;
    excludeFirstRow?: boolean | null;
    processedContacts?: number | null;
    foundContacts?: number | null;
    cachedContacts?: number | null;
    fields?: Array<{
      __typename?: 'SkiptraceField';
      id: string;
      name: string;
      predefined: boolean;
      key?: SkiptracePredefinedField | null;
    }> | null;
  };
};

export type RemoveSkiptracesMutationVariables = Exact<{
  input: RemoveSkiptracesInput;
}>;

export type RemoveSkiptracesMutation = {
  __typename?: 'Mutation';
  removeSkiptraces: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateSkiptraceMutationVariables = Exact<{
  input: UpdateSkiptraceInput;
  updateSkiptraceId: Scalars['String']['input'];
}>;

export type UpdateSkiptraceMutation = {
  __typename?: 'Mutation';
  updateSkiptrace: {
    __typename?: 'Skiptrace';
    id: string;
    fileName: string;
    mobileContacts?: number | null;
    landlineContacts?: number | null;
    createdAt: string;
    excludeFirstRow?: boolean | null;
    fields?: Array<{
      __typename?: 'SkiptraceField';
      id: string;
      name: string;
      predefined: boolean;
      key?: SkiptracePredefinedField | null;
    }> | null;
  };
};

export type RunSkiptraceMutationVariables = Exact<{
  input: RunSkiptraceInput;
}>;

export type RunSkiptraceMutation = {
  __typename?: 'Mutation';
  runSkiptrace: { __typename?: 'OperationStatus'; success: boolean };
};

export type ExportSkiptraceQueryVariables = Exact<{
  input: ExportSkiptraceInput;
}>;

export type ExportSkiptraceQuery = {
  __typename?: 'Query';
  exportSkiptrace: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type PushSkiptraceToGroupMutationVariables = Exact<{
  input: PushSkiptraceToGroupInput;
}>;

export type PushSkiptraceToGroupMutation = {
  __typename?: 'Mutation';
  pushSkiptraceToGroup: {
    __typename?: 'ListView';
    id: string;
    type: ListType;
    fields?: Array<{
      __typename?: 'Field';
      id: string;
      name: string;
      predefined: boolean;
      key?: PredefinedField | null;
    }> | null;
  };
};

export type SkiptraceProgressSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type SkiptraceProgressSubscription = {
  __typename?: 'Subscription';
  skiptraceProgress: {
    __typename?: 'SkiptraceProgress';
    skiptraceId: string;
    progress: number;
  };
};

export type SkiptraceCompletedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type SkiptraceCompletedSubscription = {
  __typename?: 'Subscription';
  skiptraceCompleted: {
    __typename?: 'SkiptraceCompleted';
    skiptraceId: string;
  };
};

export type SubscriptionFragment = {
  __typename?: 'CurrentSubscription';
  id: string;
  status: SubscriptionStatus;
  startedAt?: string | null;
  endAt?: string | null;
  updatedAt?: string | null;
  nextBillingDate?: string | null;
  trialEndedAt?: string | null;
  membershipMonths?: number | null;
  product: Product;
  reason?: string | null;
  base: boolean;
  price: number;
  secondary: boolean;
  title: string;
  discount?: {
    __typename?: 'Discount';
    amount: number;
    coupon: string;
    promoCode?: string | null;
    expiresAt?: string | null;
    percent?: number | null;
    startedAt?: string | null;
  } | null;
  plan?: {
    __typename?: 'Plan';
    id: string;
    active: boolean;
    enterprise: boolean;
    base: boolean;
    default: boolean;
    type: PlanType;
    interval: BillingInterval;
    title: string;
    userTitle: string;
    product: Product;
    price: number;
    displayPrice: number;
    discount: number;
    secondary: boolean;
    lexorank?: string | null;
    mostPopular: boolean;
    monthlyPrice?: number | null;
    couponId?: string | null;
    features: Array<{
      __typename?: 'PlanFeature';
      type: FeatureType;
      title: string;
      tier?: number | null;
      limit?: number | null;
      min?: number | null;
      price?: number | null;
      trialLimits: {
        __typename?: 'PlanFeatureTrial';
        use: boolean;
        tier?: number | null;
        limit?: number | null;
      };
    }>;
  } | null;
};

export type SubscribeMutationVariables = Exact<{
  input: SubscribeInput;
}>;

export type SubscribeMutation = {
  __typename?: 'Mutation';
  subscribe: {
    __typename?: 'CurrentSubscription';
    id: string;
    status: SubscriptionStatus;
    startedAt?: string | null;
    endAt?: string | null;
    updatedAt?: string | null;
    nextBillingDate?: string | null;
    trialEndedAt?: string | null;
    membershipMonths?: number | null;
    product: Product;
    reason?: string | null;
    base: boolean;
    price: number;
    secondary: boolean;
    title: string;
    discount?: {
      __typename?: 'Discount';
      amount: number;
      coupon: string;
      promoCode?: string | null;
      expiresAt?: string | null;
      percent?: number | null;
      startedAt?: string | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      active: boolean;
      enterprise: boolean;
      base: boolean;
      default: boolean;
      type: PlanType;
      interval: BillingInterval;
      title: string;
      userTitle: string;
      product: Product;
      price: number;
      displayPrice: number;
      discount: number;
      secondary: boolean;
      lexorank?: string | null;
      mostPopular: boolean;
      monthlyPrice?: number | null;
      couponId?: string | null;
      features: Array<{
        __typename?: 'PlanFeature';
        type: FeatureType;
        title: string;
        tier?: number | null;
        limit?: number | null;
        min?: number | null;
        price?: number | null;
        trialLimits: {
          __typename?: 'PlanFeatureTrial';
          use: boolean;
          tier?: number | null;
          limit?: number | null;
        };
      }>;
    } | null;
  };
};

export type UnsubscribeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
}>;

export type UnsubscribeMutation = {
  __typename?: 'Mutation';
  unsubscribe: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateSubscriptionMutationVariables = Exact<{
  input: UpdateSubscriptionInput;
  id: Scalars['String']['input'];
}>;

export type UpdateSubscriptionMutation = {
  __typename?: 'Mutation';
  updateSubscription: {
    __typename?: 'CurrentSubscription';
    id: string;
    status: SubscriptionStatus;
    startedAt?: string | null;
    endAt?: string | null;
    updatedAt?: string | null;
    nextBillingDate?: string | null;
    trialEndedAt?: string | null;
    membershipMonths?: number | null;
    product: Product;
    reason?: string | null;
    base: boolean;
    price: number;
    secondary: boolean;
    title: string;
    discount?: {
      __typename?: 'Discount';
      amount: number;
      coupon: string;
      promoCode?: string | null;
      expiresAt?: string | null;
      percent?: number | null;
      startedAt?: string | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      active: boolean;
      enterprise: boolean;
      base: boolean;
      default: boolean;
      type: PlanType;
      interval: BillingInterval;
      title: string;
      userTitle: string;
      product: Product;
      price: number;
      displayPrice: number;
      discount: number;
      secondary: boolean;
      lexorank?: string | null;
      mostPopular: boolean;
      monthlyPrice?: number | null;
      couponId?: string | null;
      features: Array<{
        __typename?: 'PlanFeature';
        type: FeatureType;
        title: string;
        tier?: number | null;
        limit?: number | null;
        min?: number | null;
        price?: number | null;
        trialLimits: {
          __typename?: 'PlanFeatureTrial';
          use: boolean;
          tier?: number | null;
          limit?: number | null;
        };
      }>;
    } | null;
  };
};

export type EndTrialMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: EndTrialInput;
}>;

export type EndTrialMutation = {
  __typename?: 'Mutation';
  endTrial: {
    __typename?: 'CurrentSubscription';
    id: string;
    status: SubscriptionStatus;
    startedAt?: string | null;
    endAt?: string | null;
    updatedAt?: string | null;
    nextBillingDate?: string | null;
    trialEndedAt?: string | null;
    membershipMonths?: number | null;
    product: Product;
    reason?: string | null;
    base: boolean;
    price: number;
    secondary: boolean;
    title: string;
    discount?: {
      __typename?: 'Discount';
      amount: number;
      coupon: string;
      promoCode?: string | null;
      expiresAt?: string | null;
      percent?: number | null;
      startedAt?: string | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      active: boolean;
      enterprise: boolean;
      base: boolean;
      default: boolean;
      type: PlanType;
      interval: BillingInterval;
      title: string;
      userTitle: string;
      product: Product;
      price: number;
      displayPrice: number;
      discount: number;
      secondary: boolean;
      lexorank?: string | null;
      mostPopular: boolean;
      monthlyPrice?: number | null;
      couponId?: string | null;
      features: Array<{
        __typename?: 'PlanFeature';
        type: FeatureType;
        title: string;
        tier?: number | null;
        limit?: number | null;
        min?: number | null;
        price?: number | null;
        trialLimits: {
          __typename?: 'PlanFeatureTrial';
          use: boolean;
          tier?: number | null;
          limit?: number | null;
        };
      }>;
    } | null;
  };
};

export type VerifyPromoCodeMutationVariables = Exact<{
  input: VerifyPromoCodeInput;
}>;

export type VerifyPromoCodeMutation = {
  __typename?: 'Mutation';
  verifyPromoCode: {
    __typename?: 'PromoCode';
    id: string;
    code: string;
    coupon: {
      __typename?: 'Coupon';
      id: string;
      amountOff?: number | null;
      percentOff?: number | null;
    };
  };
};

export type ApplyDiscountMutationVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
  input: ApplyDiscountInput;
}>;

export type ApplyDiscountMutation = {
  __typename?: 'Mutation';
  applyDiscount: {
    __typename?: 'Discount';
    amount: number;
    coupon: string;
    startedAt?: string | null;
    promoCode?: string | null;
    expiresAt?: string | null;
    percent?: number | null;
  };
};

export type SubscriptionsQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionsQuery = {
  __typename?: 'Query';
  subscriptions: Array<{
    __typename?: 'CurrentSubscription';
    id: string;
    product: Product;
    status: SubscriptionStatus;
    price: number;
    title: string;
    nextBillingDate?: string | null;
    startedAt?: string | null;
    endAt?: string | null;
    updatedAt?: string | null;
    trialEndedAt?: string | null;
    membershipMonths?: number | null;
    reason?: string | null;
    base: boolean;
    secondary: boolean;
    discount?: {
      __typename?: 'Discount';
      amount: number;
      coupon: string;
      percent?: number | null;
      promoCode?: string | null;
      startedAt?: string | null;
      expiresAt?: string | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      active: boolean;
      enterprise: boolean;
      base: boolean;
      default: boolean;
      type: PlanType;
      interval: BillingInterval;
      title: string;
      userTitle: string;
      product: Product;
      price: number;
      displayPrice: number;
      discount: number;
      secondary: boolean;
      lexorank?: string | null;
      mostPopular: boolean;
      monthlyPrice?: number | null;
      couponId?: string | null;
      features: Array<{
        __typename?: 'PlanFeature';
        type: FeatureType;
        title: string;
        tier?: number | null;
        limit?: number | null;
        min?: number | null;
        price?: number | null;
        trialLimits: {
          __typename?: 'PlanFeatureTrial';
          use: boolean;
          tier?: number | null;
          limit?: number | null;
        };
      }>;
    } | null;
  }>;
};

export type RetrieveUpcomingInvoiceQueryVariables = Exact<{
  products: Array<Product> | Product;
  subAccountId?: InputMaybe<Scalars['String']['input']>;
}>;

export type RetrieveUpcomingInvoiceQuery = {
  __typename?: 'Query';
  retrieveUpcomingInvoice: {
    __typename?: 'UpcomingInvoice';
    totalBilledNow: number;
  };
};

export type SuppressionFragment = {
  __typename?: 'Suppression';
  id: string;
  number: string;
  createdAt: string;
  source: SuppressionSource;
  firstName?: string | null;
  lastName?: string | null;
};

export type FindSuppressionsQueryVariables = Exact<{
  filter?: InputMaybe<SuppressionsFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindSuppressionsQuery = {
  __typename?: 'Query';
  findSuppressions: {
    __typename?: 'SuppressionList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'Suppression';
      id: string;
      number: string;
      createdAt: string;
      source: SuppressionSource;
      firstName?: string | null;
      lastName?: string | null;
    }>;
  };
};

export type SuppressionsSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type SuppressionsSummaryQuery = {
  __typename?: 'Query';
  suppressionsSummary: {
    __typename?: 'SuppressionsSummary';
    dnc?: number | null;
    unsubscribed?: number | null;
    filtered?: number | null;
    undeliverable?: number | null;
    carrierBlocked?: number | null;
  };
};

export type ExportSuppressionsQueryVariables = Exact<{
  input: ExportSuppressionsInput;
}>;

export type ExportSuppressionsQuery = {
  __typename?: 'Query';
  exportSuppressions: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type GetUploadDncPhonesSignedUrlQueryVariables = Exact<{
  input: GenerateSignedUrlInput;
}>;

export type GetUploadDncPhonesSignedUrlQuery = {
  __typename?: 'Query';
  getUploadDNCPhonesSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type UploadDncPhonesMutationVariables = Exact<{
  input: UploadDncPhonesInput;
}>;

export type UploadDncPhonesMutation = {
  __typename?: 'Mutation';
  uploadDNCPhones: { __typename?: 'OperationStatus'; success: boolean };
};

export type CreateDncPhoneMutationVariables = Exact<{
  input: CreateDncPhoneInput;
}>;

export type CreateDncPhoneMutation = {
  __typename?: 'Mutation';
  createDNCPhone: {
    __typename?: 'Suppression';
    id: string;
    number: string;
    createdAt: string;
    source: SuppressionSource;
    firstName?: string | null;
    lastName?: string | null;
  };
};

export type AddContactToDncMutationVariables = Exact<{
  input: AddContactToDncInput;
}>;

export type AddContactToDncMutation = {
  __typename?: 'Mutation';
  addContactToDNC: {
    __typename?: 'Suppression';
    id: string;
    number: string;
    createdAt: string;
    source: SuppressionSource;
    firstName?: string | null;
    lastName?: string | null;
  };
};

export type RemoveContactFromDncSuppressionsMutationVariables = Exact<{
  input: RemoveContactFromDncSuppressionsInput;
}>;

export type RemoveContactFromDncSuppressionsMutation = {
  __typename?: 'Mutation';
  removeContactFromDNCSuppressions: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type RemoveSuppressionsMutationVariables = Exact<{
  input: RemoveSuppressionsInput;
}>;

export type RemoveSuppressionsMutation = {
  __typename?: 'Mutation';
  removeSuppressions: { __typename?: 'OperationStatus'; success: boolean };
};

export type TollFreeBrandFragment = {
  __typename?: 'TollFreeBrand';
  companyName: string;
  usecase: TollFreeBrandUseCase;
  messageVolume: string;
  country: string;
  state: string;
  city: string;
  address1: string;
  address2: string;
  zip: string;
  firstName: string;
  lastName: string;
  phone: string;
  website: string;
  email: string;
  description: string;
  sampleMessage: string;
  optInWorkflow: string;
  optInWorkflowFiles: Array<{
    __typename?: 'BrandRegistrationFile';
    filePath: string;
    contentType: string;
    url: string;
  }>;
};

export type GetTollFreeRegistrationInfoQueryVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type GetTollFreeRegistrationInfoQuery = {
  __typename?: 'Query';
  getTollFreeRegistrationInfo: {
    __typename?: 'TollFreeRegistration';
    brand: {
      __typename?: 'TollFreeBrand';
      companyName: string;
      usecase: TollFreeBrandUseCase;
      messageVolume: string;
      country: string;
      state: string;
      city: string;
      address1: string;
      address2: string;
      zip: string;
      firstName: string;
      lastName: string;
      phone: string;
      website: string;
      email: string;
      description: string;
      sampleMessage: string;
      optInWorkflow: string;
      optInWorkflowFiles: Array<{
        __typename?: 'BrandRegistrationFile';
        filePath: string;
        contentType: string;
        url: string;
      }>;
    };
    ownPhone?: {
      __typename?: 'OwnPhone';
      id: string;
      status: OwnPhoneStatus;
      type: OwnPhoneType;
      number: string;
      scope: Scope;
      isTrial: boolean;
      createdAt: string;
      canBeRemoved: boolean;
      callingEnabled: boolean;
      messagingEnabled: boolean;
      location?: {
        __typename?: 'PhoneNumberLocation';
        abbreviation: string;
        state: string;
      } | null;
    } | null;
  };
};

export type RegisterTollFreeMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type RegisterTollFreeMutation = {
  __typename?: 'Mutation';
  registerTollFree: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateTollFreeRegistrationInfoMutationVariables = Exact<{
  input: UpdateTollFreeRegistrationInfoInput;
  accountId: Scalars['String']['input'];
}>;

export type UpdateTollFreeRegistrationInfoMutation = {
  __typename?: 'Mutation';
  updateTollFreeRegistrationInfo: {
    __typename?: 'TollFreeRegistration';
    brand: {
      __typename?: 'TollFreeBrand';
      companyName: string;
      usecase: TollFreeBrandUseCase;
      messageVolume: string;
      country: string;
      state: string;
      city: string;
      address1: string;
      address2: string;
      zip: string;
      firstName: string;
      lastName: string;
      phone: string;
      website: string;
      email: string;
      description: string;
      sampleMessage: string;
      optInWorkflow: string;
      optInWorkflowFiles: Array<{
        __typename?: 'BrandRegistrationFile';
        filePath: string;
        contentType: string;
        url: string;
      }>;
    };
    ownPhone?: {
      __typename?: 'OwnPhone';
      id: string;
      status: OwnPhoneStatus;
      type: OwnPhoneType;
      number: string;
      scope: Scope;
      isTrial: boolean;
      createdAt: string;
      canBeRemoved: boolean;
      callingEnabled: boolean;
      messagingEnabled: boolean;
      location?: {
        __typename?: 'PhoneNumberLocation';
        abbreviation: string;
        state: string;
      } | null;
    } | null;
  };
};

export const A2PBrandFragmentDoc = gql`
  fragment A2PBrand on A2PBrand {
    companyName
    vertical
    ein
    einIssuingCountry
    country
    state
    city
    street
    zip
    firstName
    lastName
    email
    phone
    website
    createWebsite
    customerCarePhone
    customerCareEmail
    termsAndConditionsUrl
    einFile {
      filePath
      contentType
      url
    }
    incorporationDate
  }
`;
export const A2PBrandCampaignFragmentDoc = gql`
  fragment A2PBrandCampaign on A2PBrandCampaign {
    title
    description
    usecase
    sample1
    sample2
    messageFlow
    sendPhones
    sendLinks
    sendDirectLending
    sendAgeGatedContent
    sendAffiliateMarketing
    sendOptIn
    optInKeywords
    optInMessage
    sendOptOut
    optOutKeywords
    optOutMessage
    sendHelp
    helpKeywords
    helpMessage
  }
`;
export const PlanFragmentDoc = gql`
  fragment Plan on Plan {
    id
    active
    enterprise
    base
    default
    type
    interval
    title
    userTitle
    product
    price
    displayPrice
    discount
    secondary
    lexorank
    features {
      type
      title
      tier
      limit
      min
      price
      trialLimits {
        use
        tier
        limit
      }
    }
    mostPopular
    monthlyPrice
    couponId
  }
`;
export const BillingProfileFragmentDoc = gql`
  fragment BillingProfile on BillingProfile {
    id
    email
    externalId
    balance {
      balance
      topUpAmount
      topUpBonus
      firstTopUpBonusApplied
      lastTopUp {
        amount
        bonus
        topUpAt
      }
      schedule {
        amount
        limit
        enabled
      }
    }
    unsubscribeCoupons
    backupPaymentMethod
  }
`;
export const SubAccountFragmentFragmentDoc = gql`
  fragment SubAccountFragment on Account {
    id
    firestoreId
    ownerId
    status
    createdAt
    secondarySubscriptions {
      title
      product
      price
      discount {
        amount
        coupon
      }
    }
    type
    email
    products
    name {
      firstName
      lastName
    }
  }
`;
export const SubscriptionFragmentDoc = gql`
  fragment Subscription on CurrentSubscription {
    id
    status
    startedAt
    endAt
    updatedAt
    nextBillingDate
    trialEndedAt
    membershipMonths
    product
    reason
    discount {
      amount
      coupon
      promoCode
      expiresAt
      percent
      startedAt
    }
    plan {
      ...Plan
    }
    base
    price
    secondary
    title
  }
  ${PlanFragmentDoc}
`;
export const MessagingProfileFragmentDoc = gql`
  fragment MessagingProfile on MessagingProfile {
    id
    phoneSettings {
      activeIn
      isSipEnabled
      alertFilter {
        campaigns
        groups
        labels
      }
      notificationFilter {
        campaigns
        groups
        labels
      }
      notificationsEnabled
      callAutoReply {
        enabled
        voiceType
        payload
      }
      voiceMail {
        enabled
        voiceType
        payload
      }
    }
    callSettings {
      activeIn
      isSipEnabled
      callAutoReply {
        enabled
        voiceType
        payload
      }
      voiceMail {
        enabled
        voiceType
        payload
      }
    }
    allowUrl
    a2pInfo {
      status
      maxTpm
    }
    tollFreeInfo {
      status
      maxTpm
      limits {
        daily
        weekly
        monthly
      }
    }
    isEINDocRequired
    responseRemoval {
      enabled
      responseFilters
    }
    provider {
      sipPassword
      sipUsername
      type
    }
  }
`;
export const AccountFragmentFragmentDoc = gql`
  fragment AccountFragment on Account {
    id
    firestoreId
    ownerId
    status
    type
    email
    companyName
    products
    secondaryPlans {
      ...Plan
    }
    name {
      firstName
      lastName
    }
    affiliate {
      id
      referralId
      referralLink
      email
      firstName
      lastName
    }
    billingProfile {
      ...BillingProfile
    }
    createdAt
    subAccounts {
      ...SubAccountFragment
    }
    questionnaire {
      realEstate
      employees
      role
      industry
    }
    settings {
      refreshRate
      openNewTab
      defaultLabelColor
      viewedPopups
    }
    subscription {
      ...Subscription
    }
    subscriptions {
      ...Subscription
    }
    questionnaire {
      realEstate
      experience
      marketingChannels
      marketingTraining
      smsMarketing
      provider
      employees
      role
      industry
    }
    timeZone
    messagingProfile {
      ...MessagingProfile
    }
    quotasUsage {
      used
      type
    }
    features {
      type
      title
      price
      tier
      limit
      min
      product
    }
    dialerStatus
    isOnboarded
    isTest
    permissions {
      optOutDisabled
      allowOptOutDisable
      allowCrmIntegrations
      allowDripCampaign
      allowKeywordCampaign
      allowScheduleCampaign
      allowThrottleCampaign
    }
    inBusinessHours
  }
  ${PlanFragmentDoc}
  ${BillingProfileFragmentDoc}
  ${SubAccountFragmentFragmentDoc}
  ${SubscriptionFragmentDoc}
  ${MessagingProfileFragmentDoc}
`;
export const AdminAccountFragmentFragmentDoc = gql`
  fragment AdminAccountFragment on Account {
    id
    ownerId
    status
    type
    email
    phone
    permissions {
      optOutDisabled
      allowOptOutDisable
      allowCrmIntegrations
      allowDripCampaign
      allowKeywordCampaign
      allowScheduleCampaign
      allowThrottleCampaign
    }
    isSubAccount
    createdAt
    isTest
    isOnboarded
    firestoreId
    settings {
      refreshRate
      openNewTab
    }
    name {
      firstName
      lastName
    }
    companyName
    timeZone
    questionnaire {
      realEstate
      experience
      marketingChannels
      marketingTraining
      smsMarketing
      provider
      employees
      role
      industry
    }
    a2PStatus
    tollFreeStatus
    affiliate {
      id
      referralId
      referralLink
      email
      firstName
      lastName
      createdAt
    }
    subscription {
      id
      endAt
      membershipMonths
      trialEndedAt
      startedAt
      plan {
        id
        title
        userTitle
        type
      }
    }
    subscriptions {
      id
      title
      product
      status
      price
      nextBillingDate
      discount {
        amount
        coupon
        percent
        promoCode
        startedAt
        expiresAt
      }
      startedAt
      endAt
      updatedAt
      trialEndedAt
      membershipMonths
      reason
      base
      secondary
      plan {
        id
        secondary
        product
        active
        enterprise
        base
        default
        type
        interval
        title
        userTitle
        price
        displayPrice
        discount
        features {
          type
          title
          tier
          limit
          min
          price
          trialLimits {
            use
            tier
            limit
          }
        }
        mostPopular
        monthlyPrice
        couponId
      }
    }
    messagingProfile {
      id
      allowUrl
      a2pInfo {
        maxTpm
        status
        campaignId
        brandId
        statusUpdatedAt
        incorporationDate
      }
      tollFreeInfo {
        status
        maxTpm
        limits {
          daily
          weekly
          monthly
        }
      }
      provider {
        type
      }
    }
    billingProfile {
      balance {
        balance
        lastTopUp {
          topUpAt
          amount
        }
        topUpAmount
      }
    }
    summary {
      totalSmsUsed
      totalSkiptraceUsed
      totalMembershipFees
      productsFees {
        amount
        product
      }
      totalBilledToDate
      totalBilled
      lastBilling {
        amount
        createdAt
      }
      trialPeriod {
        endedAt
        startedAt
      }
    }
  }
`;
export const AffiliateFragmentDoc = gql`
  fragment Affiliate on Affiliate {
    id
    referralId
    referralLink
    email
    firstName
    lastName
    createdAt
  }
`;
export const ArticleFragmentDoc = gql`
  fragment Article on Article {
    id
    title
    url
    createdAt
    updatedAt
  }
`;
export const PaymentFragmentDoc = gql`
  fragment Payment on Payment {
    invoiceUrl
    id
    feature
    description
    createdAt
    amount
    type
    method
    purpose
    status
  }
`;
export const PaymentSourceFragmentDoc = gql`
  fragment PaymentSource on PaymentSource {
    id
    brand
    expMonth
    expYear
    last4
    default
  }
`;
export const CalendarEventFragmentDoc = gql`
  fragment CalendarEvent on CalendarEvent {
    id
    type
    title
    endAt
    startAt
    relationId
    description
  }
`;
export const CallScriptFragmentDoc = gql`
  fragment CallScript on CallScript {
    id
    name
    content
    createdAt
  }
`;
export const StateFragmentFragmentDoc = gql`
  fragment StateFragment on CampaignState {
    status
    reason {
      type
      ttl
    }
  }
`;
export const SequenceFragmentFragmentDoc = gql`
  fragment SequenceFragment on SequenceView {
    type
    delay
    templates
    content
    days
    hasCustomTimeRestrictions
    timeRange {
      from {
        hour
        minute
      }
      to {
        hour
        minute
      }
    }
    rule {
      removeFromNextSequences
      strategy
      groupId
      labelId
    }
    progress {
      sent
      total
    }
  }
`;
export const GroupFragmentFragmentDoc = gql`
  fragment GroupFragment on GroupView {
    id
    name
    verified
    createdAt
    contacts
    activeChats
    canBeCloned
    canBeRemoved
  }
`;
export const KeywordTemplateFragmentFragmentDoc = gql`
  fragment KeywordTemplateFragment on KeywordTemplate {
    id
    keyword
    template {
      content
      optOut
    }
  }
`;
export const CostFragmentFragmentDoc = gql`
  fragment CostFragment on CampaignCosts {
    captured
    refunded
  }
`;
export const StatsFragmentFragmentDoc = gql`
  fragment StatsFragment on CampaignStats {
    smsUsed
    smsRefunded
  }
`;
export const ScheduleOptionsFragmentDoc = gql`
  fragment ScheduleOptions on CampaignScheduleOptions {
    startAt
    perBatch
    frequency
    interval
    hasCustomBatchSize
    hasCustomStartDate
    timezone
  }
`;
export const CampaignFragmentFragmentDoc = gql`
  fragment CampaignFragment on Campaign {
    id
    type
    name
    clickThroughStartAfter {
      id
      createdAt
    }
    clickThroughProgress
    ownPhones {
      id
      status
      type
      number
      location {
        abbreviation
        state
      }
      isTrial
      createdAt
    }
    state {
      ...StateFragment
    }
    sequences {
      ...SequenceFragment
    }
    createdAt
    firestoreId
    groups {
      ...GroupFragment
    }
    lastSentAt
    contacts
    sendFirstMessage
    expiresAt
    keyword {
      ...KeywordTemplateFragment
    }
    costs {
      ...CostFragment
    }
    stats {
      ...StatsFragment
    }
    scheduleOptions {
      ...ScheduleOptions
    }
    rule {
      removeFromNextSequences
      strategy
      groupId
      labelId
    }
  }
  ${StateFragmentFragmentDoc}
  ${SequenceFragmentFragmentDoc}
  ${GroupFragmentFragmentDoc}
  ${KeywordTemplateFragmentFragmentDoc}
  ${CostFragmentFragmentDoc}
  ${StatsFragmentFragmentDoc}
  ${ScheduleOptionsFragmentDoc}
`;
export const CampaignScheduledMessageFragmentDoc = gql`
  fragment CampaignScheduledMessage on ScheduledMessage {
    id
    content
    createdAt
    scheduledAt
    template {
      name
      id
      template {
        optOut
      }
    }
    contact {
      id
      firstName
      lastName
      phone
      email
      attrs {
        fieldId
        value
      }
    }
  }
`;
export const ContactNoteFragmentDoc = gql`
  fragment ContactNote on ContactNote {
    id
    text
    attachments
    createdAt
    updatedAt
  }
`;
export const LabelFragmentDoc = gql`
  fragment Label on Label {
    id
    title
    color
    default
    scopes
    readOnly
    createdAt
    firestoreId
  }
`;
export const ContactAttributeFragmentDoc = gql`
  fragment ContactAttribute on ContactAttribute {
    fieldId
    value
  }
`;
export const RealEstateAttrsFragmentDoc = gql`
  fragment RealEstateAttrs on ContactRealEstateAttribute {
    id
    value
  }
`;
export const ContactFragmentDoc = gql`
  fragment Contact on Contact {
    id
    status
    createdAt
    phone
    email
    firstName
    lastName
    favorite
    blocked
    unsubscribed
    dealClosed
    labels {
      ...Label
    }
    groups {
      id
      name
    }
    attrs {
      ...ContactAttribute
    }
    realEstateAttrs {
      attrs {
        ...RealEstateAttrs
      }
      provider
    }
  }
  ${LabelFragmentDoc}
  ${ContactAttributeFragmentDoc}
  ${RealEstateAttrsFragmentDoc}
`;
export const ContactPreviewFragmentDoc = gql`
  fragment ContactPreview on Contact {
    id
    createdAt
    phone
    firstName
    lastName
    favorite
    dealClosed
    canBeRemoved
    status
    labels {
      id
      title
      color
      createdAt
      default
      firestoreId
      readOnly
      scopes
    }
    groups {
      id
      name
    }
  }
`;
export const AttachmentFragmentDoc = gql`
  fragment Attachment on MessageAttachment {
    path
    url
    contentType
  }
`;
export const SingleMessageCampaignFragmentDoc = gql`
  fragment SingleMessageCampaign on Campaign {
    id
    name
    type
  }
`;
export const SingleMessageTemplateFragmentDoc = gql`
  fragment SingleMessageTemplate on MessageTemplate {
    id
    name
    template {
      audioFilePath
    }
    characters
    createdAt
  }
`;
export const SingleMessageKeywordFragmentDoc = gql`
  fragment SingleMessageKeyword on KeywordTemplate {
    id
    keyword
  }
`;
export const MessageFragmentDoc = gql`
  fragment Message on Message {
    id
    status
    direction
    type
    createdAt
    content
    contentType
    duration
    attachments {
      ...Attachment
    }
    isConversation
    isManualRecording
    campaign {
      ...SingleMessageCampaign
    }
    template {
      ...SingleMessageTemplate
    }
    keyword {
      ...SingleMessageKeyword
    }
    dialerCampaign {
      name
    }
    fromNumber {
      number
    }
  }
  ${AttachmentFragmentDoc}
  ${SingleMessageCampaignFragmentDoc}
  ${SingleMessageTemplateFragmentDoc}
  ${SingleMessageKeywordFragmentDoc}
`;
export const LastMessageFragmentDoc = gql`
  fragment LastMessage on Message {
    id
    status
    direction
    type
    content
    contentType
    type
  }
`;
export const ConversationFragmentDoc = gql`
  fragment Conversation on Conversation {
    id
    profileId
    isFavorite
    isRead
    isAiFiltered
    name
    lastMessageAt
    updatedAt
    unreadMessages
    phone
    createdAt
    canBeRemoved
    lastMessage {
      ...LastMessage
    }
    labels {
      id
      title
      color
    }
  }
  ${LastMessageFragmentDoc}
`;
export const OwnPhoneFragmentDoc = gql`
  fragment OwnPhone on OwnPhone {
    id
    status
    type
    number
    location {
      abbreviation
      state
    }
    scope
    isTrial
    createdAt
    canBeRemoved
    callingEnabled
    messagingEnabled
  }
`;
export const DialerCampaignFragmentFragmentDoc = gql`
  fragment DialerCampaignFragment on DialerCampaign {
    id
    name
    status
    createdAt
    groups {
      id
      name
    }
    lastCallAt
    contacts
    mode
    script
    phones
    labels
    ownPhones {
      ...OwnPhone
    }
    lines
    deletedAt
    endedAt
    startedAt
  }
  ${OwnPhoneFragmentDoc}
`;
export const DialerCampaignDetailedFragmentFragmentDoc = gql`
  fragment DialerCampaignDetailedFragment on DialerCampaign {
    id
    name
    status
    createdAt
    groups {
      id
      name
      verified
      createdAt
      contacts
    }
    lastCallAt
    contacts
    mode
    script
    phones
    labels
    contactsWithOutcome
    lines
    deletedAt
    endedAt
    startedAt
    ownPhones {
      ...OwnPhone
    }
  }
  ${OwnPhoneFragmentDoc}
`;
export const FieldFragmentDoc = gql`
  fragment Field on Field {
    id
    key
    predefined
    name
  }
`;
export const FieldsTemplateFragmentDoc = gql`
  fragment FieldsTemplate on FieldsTemplate {
    id
    name
    fields {
      ...Field
    }
    createdAt
  }
  ${FieldFragmentDoc}
`;
export const GroupViewFragmentDoc = gql`
  fragment GroupView on GroupView {
    id
    name
    verified
    createdAt
    contacts
    activeChats
    campaigns {
      name
    }
    dialerCampaigns {
      name
    }
    canBeCloned
    canBeRemoved
  }
`;
export const CrmIntegrationFragmentFragmentDoc = gql`
  fragment crmIntegrationFragment on CrmIntegration {
    id
    createdAt
    status
    name
    type
  }
`;
export const CrmIntegrationFieldsFragmentDoc = gql`
  fragment crmIntegrationFields on CrmIntegration {
    ...crmIntegrationFragment
    provider {
      labelsPropertyStatuses {
        label {
          id
          title
          color
          default
          firestoreId
        }
        status
      }
      apiKey
      sendNotes
      labelsPhoneStatuses {
        label {
          id
          title
          color
          default
          firestoreId
        }
        status
      }
      labelsPhoneTags {
        id
        title
        color
        default
        firestoreId
      }
    }
  }
  ${CrmIntegrationFragmentFragmentDoc}
`;
export const KeywordTemplateFragmentDoc = gql`
  fragment KeywordTemplate on KeywordTemplate {
    id
    keyword
    createdAt
    template {
      content
      optOut
      characters
      segments
    }
    createdAt
  }
`;
export const RankResultFragmentDoc = gql`
  fragment RankResult on RankResult {
    id
    afterId
    beforeId
  }
`;
export const ListOptionsFragmentDoc = gql`
  fragment ListOptions on ListOptions {
    removeFiltered
    removeUnsubscribed
    removeInternalDnc
    removeExisting
    removeNonSmsCapable
    removeLitigators
    removeNationalDnc
    removeCarrierBlocked
    removeGlobalUnsubscribed
    removeUndeliverable
  }
`;
export const ListVerificationResultFragmentDoc = gql`
  fragment ListVerificationResult on ListVerificationResult {
    listId
    verified
    totalPhones
    verifiedPhones
    filtered
    unsubscribed
    internalDnc
    existing
    nonSmsCapable
    litigators
    nationalDnc
    undeliverable
    carrierBlocked
    globalUnsubscribed
  }
`;
export const MacroFragmentDoc = gql`
  fragment Macro on Macro {
    id
    name
    content
  }
`;
export const MessageTemplateFragmentDoc = gql`
  fragment MessageTemplate on MessageTemplate {
    id
    firestoreId
    name
    template {
      audioFileBucket
      audioFilePath
      audioFileUrl
      content
      contentType
      description
      optOut
      messageType
      characters
      segments
    }
    type
    characters
    segments
    createdAt
  }
`;
export const DetailsNotificationFragmentDoc = gql`
  fragment DetailsNotification on NotificationDetails {
    name
    relationId
    isKeywordCampaign
    calendarEventName
    calendarEventDate
    calendarEventId
  }
`;
export const NotificationFragmentDoc = gql`
  fragment Notification on Notification {
    id
    type
    isRead
    isSeen
    createdAt
    details {
      ...DetailsNotification
    }
  }
  ${DetailsNotificationFragmentDoc}
`;
export const NotificationSettingsFragmentDoc = gql`
  fragment NotificationSettings on NotificationSettings {
    enabledCampaigns
    enabledPayments
    enabledCalendar
  }
`;
export const OwnPhoneReportFragmentDoc = gql`
  fragment OwnPhoneReport on OwnPhone {
    id
    status
    type
    number
    location {
      abbreviation
      state
    }
    scope
    isTrial
    createdAt
    canBeRemoved
    callingEnabled
    reportDialer {
      phoneId
      outboundCalls
    }
    messagingEnabled
    reportMessage {
      phoneId
      outgoingMessages
      blockedRateMessages
      activeConversations
      lastUsedAt
    }
  }
`;
export const MarketingPopupFragmentDoc = gql`
  fragment MarketingPopup on MarketingPopup {
    id
    status
    audiences
    template {
      type
      imageSrc
      videoSrc
      buttonText
      buttonLink
    }
    title
    description
    startDate
    endDate
    createdAt
    updatedAt
  }
`;
export const LineFragmentDoc = gql`
  fragment Line on LineView {
    id
    callId
    state
    stateReason
    amdResult
    contact {
      id
      phone
      firstName
      lastName
    }
    phone
  }
`;
export const SkiptraceFieldFragmentDoc = gql`
  fragment SkiptraceField on SkiptraceField {
    id
    name
    predefined
    key
  }
`;
export const SuppressionFragmentDoc = gql`
  fragment Suppression on Suppression {
    id
    number
    createdAt
    source
    firstName
    lastName
  }
`;
export const TollFreeBrandFragmentDoc = gql`
  fragment TollFreeBrand on TollFreeBrand {
    companyName
    usecase
    messageVolume
    country
    state
    city
    address1
    address2
    zip
    firstName
    lastName
    phone
    website
    email
    description
    sampleMessage
    optInWorkflow
    optInWorkflowFiles {
      filePath
      contentType
      url
    }
  }
`;
export const GetA2PRegistrationInfoDocument = gql`
  query GetA2PRegistrationInfo($accountId: String!) {
    getA2PRegistrationInfo(accountId: $accountId) {
      brand {
        ...A2PBrand
      }
      campaign {
        ...A2PBrandCampaign
      }
      ownPhone {
        ...OwnPhone
      }
    }
  }
  ${A2PBrandFragmentDoc}
  ${A2PBrandCampaignFragmentDoc}
  ${OwnPhoneFragmentDoc}
`;

/**
 * __useGetA2PRegistrationInfoQuery__
 *
 * To run a query within a React component, call `useGetA2PRegistrationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetA2PRegistrationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetA2PRegistrationInfoQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetA2PRegistrationInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetA2PRegistrationInfoQuery,
    GetA2PRegistrationInfoQueryVariables
  > &
    (
      | { variables: GetA2PRegistrationInfoQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetA2PRegistrationInfoQuery,
    GetA2PRegistrationInfoQueryVariables
  >(GetA2PRegistrationInfoDocument, options);
}
export function useGetA2PRegistrationInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetA2PRegistrationInfoQuery,
    GetA2PRegistrationInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetA2PRegistrationInfoQuery,
    GetA2PRegistrationInfoQueryVariables
  >(GetA2PRegistrationInfoDocument, options);
}
export function useGetA2PRegistrationInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetA2PRegistrationInfoQuery,
        GetA2PRegistrationInfoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetA2PRegistrationInfoQuery,
    GetA2PRegistrationInfoQueryVariables
  >(GetA2PRegistrationInfoDocument, options);
}
export type GetA2PRegistrationInfoQueryHookResult = ReturnType<
  typeof useGetA2PRegistrationInfoQuery
>;
export type GetA2PRegistrationInfoLazyQueryHookResult = ReturnType<
  typeof useGetA2PRegistrationInfoLazyQuery
>;
export type GetA2PRegistrationInfoSuspenseQueryHookResult = ReturnType<
  typeof useGetA2PRegistrationInfoSuspenseQuery
>;
export type GetA2PRegistrationInfoQueryResult = Apollo.QueryResult<
  GetA2PRegistrationInfoQuery,
  GetA2PRegistrationInfoQueryVariables
>;
export const UpdateA2PRegistrationInfoDocument = gql`
  mutation UpdateA2PRegistrationInfo(
    $input: UpdateA2PRegistrationInfoInput!
    $accountId: String!
  ) {
    updateA2PRegistrationInfo(input: $input, accountId: $accountId) {
      brand {
        ...A2PBrand
      }
      campaign {
        ...A2PBrandCampaign
      }
      ownPhone {
        ...OwnPhone
      }
    }
  }
  ${A2PBrandFragmentDoc}
  ${A2PBrandCampaignFragmentDoc}
  ${OwnPhoneFragmentDoc}
`;
export type UpdateA2PRegistrationInfoMutationFn = Apollo.MutationFunction<
  UpdateA2PRegistrationInfoMutation,
  UpdateA2PRegistrationInfoMutationVariables
>;

/**
 * __useUpdateA2PRegistrationInfoMutation__
 *
 * To run a mutation, you first call `useUpdateA2PRegistrationInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateA2PRegistrationInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateA2PRegistrationInfoMutation, { data, loading, error }] = useUpdateA2PRegistrationInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useUpdateA2PRegistrationInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateA2PRegistrationInfoMutation,
    UpdateA2PRegistrationInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateA2PRegistrationInfoMutation,
    UpdateA2PRegistrationInfoMutationVariables
  >(UpdateA2PRegistrationInfoDocument, options);
}
export type UpdateA2PRegistrationInfoMutationHookResult = ReturnType<
  typeof useUpdateA2PRegistrationInfoMutation
>;
export type UpdateA2PRegistrationInfoMutationResult =
  Apollo.MutationResult<UpdateA2PRegistrationInfoMutation>;
export type UpdateA2PRegistrationInfoMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateA2PRegistrationInfoMutation,
    UpdateA2PRegistrationInfoMutationVariables
  >;
export const RegisterA2PDocument = gql`
  mutation RegisterA2P($accountId: String!) {
    registerA2P(accountId: $accountId) {
      success
    }
  }
`;
export type RegisterA2PMutationFn = Apollo.MutationFunction<
  RegisterA2PMutation,
  RegisterA2PMutationVariables
>;

/**
 * __useRegisterA2PMutation__
 *
 * To run a mutation, you first call `useRegisterA2PMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterA2PMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerA2PMutation, { data, loading, error }] = useRegisterA2PMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRegisterA2PMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterA2PMutation,
    RegisterA2PMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterA2PMutation, RegisterA2PMutationVariables>(
    RegisterA2PDocument,
    options,
  );
}
export type RegisterA2PMutationHookResult = ReturnType<
  typeof useRegisterA2PMutation
>;
export type RegisterA2PMutationResult =
  Apollo.MutationResult<RegisterA2PMutation>;
export type RegisterA2PMutationOptions = Apollo.BaseMutationOptions<
  RegisterA2PMutation,
  RegisterA2PMutationVariables
>;
export const RegisterSubAccountA2PDocument = gql`
  mutation RegisterSubAccountA2P($accountId: String!) {
    registerSubAccountA2P(accountId: $accountId) {
      success
    }
  }
`;
export type RegisterSubAccountA2PMutationFn = Apollo.MutationFunction<
  RegisterSubAccountA2PMutation,
  RegisterSubAccountA2PMutationVariables
>;

/**
 * __useRegisterSubAccountA2PMutation__
 *
 * To run a mutation, you first call `useRegisterSubAccountA2PMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterSubAccountA2PMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerSubAccountA2PMutation, { data, loading, error }] = useRegisterSubAccountA2PMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRegisterSubAccountA2PMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterSubAccountA2PMutation,
    RegisterSubAccountA2PMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterSubAccountA2PMutation,
    RegisterSubAccountA2PMutationVariables
  >(RegisterSubAccountA2PDocument, options);
}
export type RegisterSubAccountA2PMutationHookResult = ReturnType<
  typeof useRegisterSubAccountA2PMutation
>;
export type RegisterSubAccountA2PMutationResult =
  Apollo.MutationResult<RegisterSubAccountA2PMutation>;
export type RegisterSubAccountA2PMutationOptions = Apollo.BaseMutationOptions<
  RegisterSubAccountA2PMutation,
  RegisterSubAccountA2PMutationVariables
>;
export const ReactivateA2PDocument = gql`
  mutation ReactivateA2P($accountId: String!) {
    reactivateA2P(accountId: $accountId) {
      success
    }
  }
`;
export type ReactivateA2PMutationFn = Apollo.MutationFunction<
  ReactivateA2PMutation,
  ReactivateA2PMutationVariables
>;

/**
 * __useReactivateA2PMutation__
 *
 * To run a mutation, you first call `useReactivateA2PMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateA2PMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateA2PMutation, { data, loading, error }] = useReactivateA2PMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useReactivateA2PMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReactivateA2PMutation,
    ReactivateA2PMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReactivateA2PMutation,
    ReactivateA2PMutationVariables
  >(ReactivateA2PDocument, options);
}
export type ReactivateA2PMutationHookResult = ReturnType<
  typeof useReactivateA2PMutation
>;
export type ReactivateA2PMutationResult =
  Apollo.MutationResult<ReactivateA2PMutation>;
export type ReactivateA2PMutationOptions = Apollo.BaseMutationOptions<
  ReactivateA2PMutation,
  ReactivateA2PMutationVariables
>;
export const GetA2PReactivationInfoDocument = gql`
  query GetA2PReactivationInfo {
    getA2PReactivationInfo {
      brand {
        ...A2PBrand
      }
      campaign {
        ...A2PBrandCampaign
      }
      ownPhone {
        ...OwnPhone
      }
    }
  }
  ${A2PBrandFragmentDoc}
  ${A2PBrandCampaignFragmentDoc}
  ${OwnPhoneFragmentDoc}
`;

/**
 * __useGetA2PReactivationInfoQuery__
 *
 * To run a query within a React component, call `useGetA2PReactivationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetA2PReactivationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetA2PReactivationInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetA2PReactivationInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetA2PReactivationInfoQuery,
    GetA2PReactivationInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetA2PReactivationInfoQuery,
    GetA2PReactivationInfoQueryVariables
  >(GetA2PReactivationInfoDocument, options);
}
export function useGetA2PReactivationInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetA2PReactivationInfoQuery,
    GetA2PReactivationInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetA2PReactivationInfoQuery,
    GetA2PReactivationInfoQueryVariables
  >(GetA2PReactivationInfoDocument, options);
}
export function useGetA2PReactivationInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetA2PReactivationInfoQuery,
        GetA2PReactivationInfoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetA2PReactivationInfoQuery,
    GetA2PReactivationInfoQueryVariables
  >(GetA2PReactivationInfoDocument, options);
}
export type GetA2PReactivationInfoQueryHookResult = ReturnType<
  typeof useGetA2PReactivationInfoQuery
>;
export type GetA2PReactivationInfoLazyQueryHookResult = ReturnType<
  typeof useGetA2PReactivationInfoLazyQuery
>;
export type GetA2PReactivationInfoSuspenseQueryHookResult = ReturnType<
  typeof useGetA2PReactivationInfoSuspenseQuery
>;
export type GetA2PReactivationInfoQueryResult = Apollo.QueryResult<
  GetA2PReactivationInfoQuery,
  GetA2PReactivationInfoQueryVariables
>;
export const GetEinFileUploadSignedUrlDocument = gql`
  query GetEINFileUploadSignedUrl($input: GenerateSignedUrlInput!) {
    getEINFileUploadSignedUrl(input: $input) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useGetEinFileUploadSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetEinFileUploadSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEinFileUploadSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEinFileUploadSignedUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEinFileUploadSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEinFileUploadSignedUrlQuery,
    GetEinFileUploadSignedUrlQueryVariables
  > &
    (
      | { variables: GetEinFileUploadSignedUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEinFileUploadSignedUrlQuery,
    GetEinFileUploadSignedUrlQueryVariables
  >(GetEinFileUploadSignedUrlDocument, options);
}
export function useGetEinFileUploadSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEinFileUploadSignedUrlQuery,
    GetEinFileUploadSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEinFileUploadSignedUrlQuery,
    GetEinFileUploadSignedUrlQueryVariables
  >(GetEinFileUploadSignedUrlDocument, options);
}
export function useGetEinFileUploadSignedUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetEinFileUploadSignedUrlQuery,
        GetEinFileUploadSignedUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEinFileUploadSignedUrlQuery,
    GetEinFileUploadSignedUrlQueryVariables
  >(GetEinFileUploadSignedUrlDocument, options);
}
export type GetEinFileUploadSignedUrlQueryHookResult = ReturnType<
  typeof useGetEinFileUploadSignedUrlQuery
>;
export type GetEinFileUploadSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetEinFileUploadSignedUrlLazyQuery
>;
export type GetEinFileUploadSignedUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetEinFileUploadSignedUrlSuspenseQuery
>;
export type GetEinFileUploadSignedUrlQueryResult = Apollo.QueryResult<
  GetEinFileUploadSignedUrlQuery,
  GetEinFileUploadSignedUrlQueryVariables
>;
export const SubmitA2PReactivationDocument = gql`
  mutation SubmitA2PReactivation {
    submitA2PReactivation {
      success
    }
  }
`;
export type SubmitA2PReactivationMutationFn = Apollo.MutationFunction<
  SubmitA2PReactivationMutation,
  SubmitA2PReactivationMutationVariables
>;

/**
 * __useSubmitA2PReactivationMutation__
 *
 * To run a mutation, you first call `useSubmitA2PReactivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitA2PReactivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitA2PReactivationMutation, { data, loading, error }] = useSubmitA2PReactivationMutation({
 *   variables: {
 *   },
 * });
 */
export function useSubmitA2PReactivationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitA2PReactivationMutation,
    SubmitA2PReactivationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitA2PReactivationMutation,
    SubmitA2PReactivationMutationVariables
  >(SubmitA2PReactivationDocument, options);
}
export type SubmitA2PReactivationMutationHookResult = ReturnType<
  typeof useSubmitA2PReactivationMutation
>;
export type SubmitA2PReactivationMutationResult =
  Apollo.MutationResult<SubmitA2PReactivationMutation>;
export type SubmitA2PReactivationMutationOptions = Apollo.BaseMutationOptions<
  SubmitA2PReactivationMutation,
  SubmitA2PReactivationMutationVariables
>;
export const SubmitA2PRegistrationDocument = gql`
  mutation SubmitA2PRegistration($input: SubmitA2PRegistrationInput!) {
    submitA2PRegistration(input: $input) {
      success
    }
  }
`;
export type SubmitA2PRegistrationMutationFn = Apollo.MutationFunction<
  SubmitA2PRegistrationMutation,
  SubmitA2PRegistrationMutationVariables
>;

/**
 * __useSubmitA2PRegistrationMutation__
 *
 * To run a mutation, you first call `useSubmitA2PRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitA2PRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitA2PRegistrationMutation, { data, loading, error }] = useSubmitA2PRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitA2PRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitA2PRegistrationMutation,
    SubmitA2PRegistrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitA2PRegistrationMutation,
    SubmitA2PRegistrationMutationVariables
  >(SubmitA2PRegistrationDocument, options);
}
export type SubmitA2PRegistrationMutationHookResult = ReturnType<
  typeof useSubmitA2PRegistrationMutation
>;
export type SubmitA2PRegistrationMutationResult =
  Apollo.MutationResult<SubmitA2PRegistrationMutation>;
export type SubmitA2PRegistrationMutationOptions = Apollo.BaseMutationOptions<
  SubmitA2PRegistrationMutation,
  SubmitA2PRegistrationMutationVariables
>;
export const SubmitA2PSubAccountRegistrationDocument = gql`
  mutation SubmitA2PSubAccountRegistration(
    $input: SubmitA2PSubAccountRegistrationInput!
  ) {
    submitA2PSubAccountRegistration(input: $input) {
      success
    }
  }
`;
export type SubmitA2PSubAccountRegistrationMutationFn = Apollo.MutationFunction<
  SubmitA2PSubAccountRegistrationMutation,
  SubmitA2PSubAccountRegistrationMutationVariables
>;

/**
 * __useSubmitA2PSubAccountRegistrationMutation__
 *
 * To run a mutation, you first call `useSubmitA2PSubAccountRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitA2PSubAccountRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitA2PSubAccountRegistrationMutation, { data, loading, error }] = useSubmitA2PSubAccountRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitA2PSubAccountRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitA2PSubAccountRegistrationMutation,
    SubmitA2PSubAccountRegistrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitA2PSubAccountRegistrationMutation,
    SubmitA2PSubAccountRegistrationMutationVariables
  >(SubmitA2PSubAccountRegistrationDocument, options);
}
export type SubmitA2PSubAccountRegistrationMutationHookResult = ReturnType<
  typeof useSubmitA2PSubAccountRegistrationMutation
>;
export type SubmitA2PSubAccountRegistrationMutationResult =
  Apollo.MutationResult<SubmitA2PSubAccountRegistrationMutation>;
export type SubmitA2PSubAccountRegistrationMutationOptions =
  Apollo.BaseMutationOptions<
    SubmitA2PSubAccountRegistrationMutation,
    SubmitA2PSubAccountRegistrationMutationVariables
  >;
export const GetOptInWorkflowFileSignedUrlDocument = gql`
  query GetOptInWorkflowFileSignedUrl($input: GenerateSignedUrlInput!) {
    getOptInWorkflowFileSignedUrl(input: $input) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useGetOptInWorkflowFileSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetOptInWorkflowFileSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOptInWorkflowFileSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOptInWorkflowFileSignedUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOptInWorkflowFileSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOptInWorkflowFileSignedUrlQuery,
    GetOptInWorkflowFileSignedUrlQueryVariables
  > &
    (
      | {
          variables: GetOptInWorkflowFileSignedUrlQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOptInWorkflowFileSignedUrlQuery,
    GetOptInWorkflowFileSignedUrlQueryVariables
  >(GetOptInWorkflowFileSignedUrlDocument, options);
}
export function useGetOptInWorkflowFileSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOptInWorkflowFileSignedUrlQuery,
    GetOptInWorkflowFileSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOptInWorkflowFileSignedUrlQuery,
    GetOptInWorkflowFileSignedUrlQueryVariables
  >(GetOptInWorkflowFileSignedUrlDocument, options);
}
export function useGetOptInWorkflowFileSignedUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetOptInWorkflowFileSignedUrlQuery,
        GetOptInWorkflowFileSignedUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOptInWorkflowFileSignedUrlQuery,
    GetOptInWorkflowFileSignedUrlQueryVariables
  >(GetOptInWorkflowFileSignedUrlDocument, options);
}
export type GetOptInWorkflowFileSignedUrlQueryHookResult = ReturnType<
  typeof useGetOptInWorkflowFileSignedUrlQuery
>;
export type GetOptInWorkflowFileSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetOptInWorkflowFileSignedUrlLazyQuery
>;
export type GetOptInWorkflowFileSignedUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetOptInWorkflowFileSignedUrlSuspenseQuery
>;
export type GetOptInWorkflowFileSignedUrlQueryResult = Apollo.QueryResult<
  GetOptInWorkflowFileSignedUrlQuery,
  GetOptInWorkflowFileSignedUrlQueryVariables
>;
export const SubmitTollFreeRegistrationDocument = gql`
  mutation SubmitTollFreeRegistration($input: TollFreeBrandInput!) {
    submitTollFreeRegistration(input: $input) {
      success
    }
  }
`;
export type SubmitTollFreeRegistrationMutationFn = Apollo.MutationFunction<
  SubmitTollFreeRegistrationMutation,
  SubmitTollFreeRegistrationMutationVariables
>;

/**
 * __useSubmitTollFreeRegistrationMutation__
 *
 * To run a mutation, you first call `useSubmitTollFreeRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitTollFreeRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitTollFreeRegistrationMutation, { data, loading, error }] = useSubmitTollFreeRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitTollFreeRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitTollFreeRegistrationMutation,
    SubmitTollFreeRegistrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitTollFreeRegistrationMutation,
    SubmitTollFreeRegistrationMutationVariables
  >(SubmitTollFreeRegistrationDocument, options);
}
export type SubmitTollFreeRegistrationMutationHookResult = ReturnType<
  typeof useSubmitTollFreeRegistrationMutation
>;
export type SubmitTollFreeRegistrationMutationResult =
  Apollo.MutationResult<SubmitTollFreeRegistrationMutation>;
export type SubmitTollFreeRegistrationMutationOptions =
  Apollo.BaseMutationOptions<
    SubmitTollFreeRegistrationMutation,
    SubmitTollFreeRegistrationMutationVariables
  >;
export const UploadEinFileDocument = gql`
  mutation UploadEINFile($input: UploadEINFileInput!) {
    uploadEINFile(input: $input) {
      success
    }
  }
`;
export type UploadEinFileMutationFn = Apollo.MutationFunction<
  UploadEinFileMutation,
  UploadEinFileMutationVariables
>;

/**
 * __useUploadEinFileMutation__
 *
 * To run a mutation, you first call `useUploadEinFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEinFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEinFileMutation, { data, loading, error }] = useUploadEinFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadEinFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadEinFileMutation,
    UploadEinFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadEinFileMutation,
    UploadEinFileMutationVariables
  >(UploadEinFileDocument, options);
}
export type UploadEinFileMutationHookResult = ReturnType<
  typeof useUploadEinFileMutation
>;
export type UploadEinFileMutationResult =
  Apollo.MutationResult<UploadEinFileMutation>;
export type UploadEinFileMutationOptions = Apollo.BaseMutationOptions<
  UploadEinFileMutation,
  UploadEinFileMutationVariables
>;
export const AccountDocument = gql`
  query Account {
    account {
      isSubAccount
      ...AccountFragment
    }
  }
  ${AccountFragmentFragmentDoc}
`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    options,
  );
}
export function useAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountQuery,
    AccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    options,
  );
}
export function useAccountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AccountQuery, AccountQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    options,
  );
}
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountSuspenseQueryHookResult = ReturnType<
  typeof useAccountSuspenseQuery
>;
export type AccountQueryResult = Apollo.QueryResult<
  AccountQuery,
  AccountQueryVariables
>;
export const AccountBillingSourcesDocument = gql`
  query AccountBillingSources {
    account {
      isSubAccount
      ...AccountFragment
      billingProfile {
        ...BillingProfile
        sources {
          ...PaymentSource
        }
      }
    }
  }
  ${AccountFragmentFragmentDoc}
  ${BillingProfileFragmentDoc}
  ${PaymentSourceFragmentDoc}
`;

/**
 * __useAccountBillingSourcesQuery__
 *
 * To run a query within a React component, call `useAccountBillingSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountBillingSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountBillingSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountBillingSourcesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountBillingSourcesQuery,
    AccountBillingSourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountBillingSourcesQuery,
    AccountBillingSourcesQueryVariables
  >(AccountBillingSourcesDocument, options);
}
export function useAccountBillingSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountBillingSourcesQuery,
    AccountBillingSourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountBillingSourcesQuery,
    AccountBillingSourcesQueryVariables
  >(AccountBillingSourcesDocument, options);
}
export function useAccountBillingSourcesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AccountBillingSourcesQuery,
        AccountBillingSourcesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AccountBillingSourcesQuery,
    AccountBillingSourcesQueryVariables
  >(AccountBillingSourcesDocument, options);
}
export type AccountBillingSourcesQueryHookResult = ReturnType<
  typeof useAccountBillingSourcesQuery
>;
export type AccountBillingSourcesLazyQueryHookResult = ReturnType<
  typeof useAccountBillingSourcesLazyQuery
>;
export type AccountBillingSourcesSuspenseQueryHookResult = ReturnType<
  typeof useAccountBillingSourcesSuspenseQuery
>;
export type AccountBillingSourcesQueryResult = Apollo.QueryResult<
  AccountBillingSourcesQuery,
  AccountBillingSourcesQueryVariables
>;
export const CreateImpersonateTokenDocument = gql`
  mutation CreateImpersonateToken($id: String!) {
    createImpersonateToken(id: $id) {
      token
    }
  }
`;
export type CreateImpersonateTokenMutationFn = Apollo.MutationFunction<
  CreateImpersonateTokenMutation,
  CreateImpersonateTokenMutationVariables
>;

/**
 * __useCreateImpersonateTokenMutation__
 *
 * To run a mutation, you first call `useCreateImpersonateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImpersonateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImpersonateTokenMutation, { data, loading, error }] = useCreateImpersonateTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateImpersonateTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateImpersonateTokenMutation,
    CreateImpersonateTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateImpersonateTokenMutation,
    CreateImpersonateTokenMutationVariables
  >(CreateImpersonateTokenDocument, options);
}
export type CreateImpersonateTokenMutationHookResult = ReturnType<
  typeof useCreateImpersonateTokenMutation
>;
export type CreateImpersonateTokenMutationResult =
  Apollo.MutationResult<CreateImpersonateTokenMutation>;
export type CreateImpersonateTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateImpersonateTokenMutation,
  CreateImpersonateTokenMutationVariables
>;
export const UpdateAccountDocument = gql`
  mutation UpdateAccount($data: UpdateAccountInput!) {
    updateAccount(data: $data) {
      ...AccountFragment
    }
  }
  ${AccountFragmentFragmentDoc}
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >(UpdateAccountDocument, options);
}
export type UpdateAccountMutationHookResult = ReturnType<
  typeof useUpdateAccountMutation
>;
export type UpdateAccountMutationResult =
  Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const SignUpDocument = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      id
    }
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options,
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const CreateSubaccountDocument = gql`
  mutation CreateSubaccount($input: CreateSubaccountInput!) {
    createSubaccount(input: $input) {
      ...SubAccountFragment
    }
  }
  ${SubAccountFragmentFragmentDoc}
`;
export type CreateSubaccountMutationFn = Apollo.MutationFunction<
  CreateSubaccountMutation,
  CreateSubaccountMutationVariables
>;

/**
 * __useCreateSubaccountMutation__
 *
 * To run a mutation, you first call `useCreateSubaccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubaccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubaccountMutation, { data, loading, error }] = useCreateSubaccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubaccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubaccountMutation,
    CreateSubaccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSubaccountMutation,
    CreateSubaccountMutationVariables
  >(CreateSubaccountDocument, options);
}
export type CreateSubaccountMutationHookResult = ReturnType<
  typeof useCreateSubaccountMutation
>;
export type CreateSubaccountMutationResult =
  Apollo.MutationResult<CreateSubaccountMutation>;
export type CreateSubaccountMutationOptions = Apollo.BaseMutationOptions<
  CreateSubaccountMutation,
  CreateSubaccountMutationVariables
>;
export const UpdateSubaccountDocument = gql`
  mutation UpdateSubaccount($input: UpdateSubaccountInput!, $id: String!) {
    updateSubaccount(input: $input, id: $id) {
      ...SubAccountFragment
    }
  }
  ${SubAccountFragmentFragmentDoc}
`;
export type UpdateSubaccountMutationFn = Apollo.MutationFunction<
  UpdateSubaccountMutation,
  UpdateSubaccountMutationVariables
>;

/**
 * __useUpdateSubaccountMutation__
 *
 * To run a mutation, you first call `useUpdateSubaccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubaccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubaccountMutation, { data, loading, error }] = useUpdateSubaccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSubaccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubaccountMutation,
    UpdateSubaccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSubaccountMutation,
    UpdateSubaccountMutationVariables
  >(UpdateSubaccountDocument, options);
}
export type UpdateSubaccountMutationHookResult = ReturnType<
  typeof useUpdateSubaccountMutation
>;
export type UpdateSubaccountMutationResult =
  Apollo.MutationResult<UpdateSubaccountMutation>;
export type UpdateSubaccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubaccountMutation,
  UpdateSubaccountMutationVariables
>;
export const RemoveSubaccountDocument = gql`
  mutation RemoveSubaccount($id: String!) {
    removeSubaccount(id: $id) {
      success
    }
  }
`;
export type RemoveSubaccountMutationFn = Apollo.MutationFunction<
  RemoveSubaccountMutation,
  RemoveSubaccountMutationVariables
>;

/**
 * __useRemoveSubaccountMutation__
 *
 * To run a mutation, you first call `useRemoveSubaccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSubaccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSubaccountMutation, { data, loading, error }] = useRemoveSubaccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSubaccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSubaccountMutation,
    RemoveSubaccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveSubaccountMutation,
    RemoveSubaccountMutationVariables
  >(RemoveSubaccountDocument, options);
}
export type RemoveSubaccountMutationHookResult = ReturnType<
  typeof useRemoveSubaccountMutation
>;
export type RemoveSubaccountMutationResult =
  Apollo.MutationResult<RemoveSubaccountMutation>;
export type RemoveSubaccountMutationOptions = Apollo.BaseMutationOptions<
  RemoveSubaccountMutation,
  RemoveSubaccountMutationVariables
>;
export const AccountUpdatedDocument = gql`
  subscription AccountUpdated {
    accountUpdated {
      entityId
      operation
      operationAt
    }
  }
`;

/**
 * __useAccountUpdatedSubscription__
 *
 * To run a query within a React component, call `useAccountUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    AccountUpdatedSubscription,
    AccountUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    AccountUpdatedSubscription,
    AccountUpdatedSubscriptionVariables
  >(AccountUpdatedDocument, options);
}
export type AccountUpdatedSubscriptionHookResult = ReturnType<
  typeof useAccountUpdatedSubscription
>;
export type AccountUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<AccountUpdatedSubscription>;
export const ResendPasswordResetTokenDocument = gql`
  mutation ResendPasswordResetToken {
    resendPasswordResetToken {
      success
    }
  }
`;
export type ResendPasswordResetTokenMutationFn = Apollo.MutationFunction<
  ResendPasswordResetTokenMutation,
  ResendPasswordResetTokenMutationVariables
>;

/**
 * __useResendPasswordResetTokenMutation__
 *
 * To run a mutation, you first call `useResendPasswordResetTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendPasswordResetTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendPasswordResetTokenMutation, { data, loading, error }] = useResendPasswordResetTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendPasswordResetTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendPasswordResetTokenMutation,
    ResendPasswordResetTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendPasswordResetTokenMutation,
    ResendPasswordResetTokenMutationVariables
  >(ResendPasswordResetTokenDocument, options);
}
export type ResendPasswordResetTokenMutationHookResult = ReturnType<
  typeof useResendPasswordResetTokenMutation
>;
export type ResendPasswordResetTokenMutationResult =
  Apollo.MutationResult<ResendPasswordResetTokenMutation>;
export type ResendPasswordResetTokenMutationOptions =
  Apollo.BaseMutationOptions<
    ResendPasswordResetTokenMutation,
    ResendPasswordResetTokenMutationVariables
  >;
export const ConfirmPasswordResetDocument = gql`
  mutation ConfirmPasswordReset($token: String!, $password: String!) {
    confirmPasswordReset(token: $token, password: $password) {
      success
    }
  }
`;
export type ConfirmPasswordResetMutationFn = Apollo.MutationFunction<
  ConfirmPasswordResetMutation,
  ConfirmPasswordResetMutationVariables
>;

/**
 * __useConfirmPasswordResetMutation__
 *
 * To run a mutation, you first call `useConfirmPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPasswordResetMutation, { data, loading, error }] = useConfirmPasswordResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useConfirmPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmPasswordResetMutation,
    ConfirmPasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmPasswordResetMutation,
    ConfirmPasswordResetMutationVariables
  >(ConfirmPasswordResetDocument, options);
}
export type ConfirmPasswordResetMutationHookResult = ReturnType<
  typeof useConfirmPasswordResetMutation
>;
export type ConfirmPasswordResetMutationResult =
  Apollo.MutationResult<ConfirmPasswordResetMutation>;
export type ConfirmPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  ConfirmPasswordResetMutation,
  ConfirmPasswordResetMutationVariables
>;
export const AcceptTermsAndConditionsDocument = gql`
  mutation AcceptTermsAndConditions {
    acceptTermsAndConditions {
      success
    }
  }
`;
export type AcceptTermsAndConditionsMutationFn = Apollo.MutationFunction<
  AcceptTermsAndConditionsMutation,
  AcceptTermsAndConditionsMutationVariables
>;

/**
 * __useAcceptTermsAndConditionsMutation__
 *
 * To run a mutation, you first call `useAcceptTermsAndConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermsAndConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermsAndConditionsMutation, { data, loading, error }] = useAcceptTermsAndConditionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptTermsAndConditionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptTermsAndConditionsMutation,
    AcceptTermsAndConditionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptTermsAndConditionsMutation,
    AcceptTermsAndConditionsMutationVariables
  >(AcceptTermsAndConditionsDocument, options);
}
export type AcceptTermsAndConditionsMutationHookResult = ReturnType<
  typeof useAcceptTermsAndConditionsMutation
>;
export type AcceptTermsAndConditionsMutationResult =
  Apollo.MutationResult<AcceptTermsAndConditionsMutation>;
export type AcceptTermsAndConditionsMutationOptions =
  Apollo.BaseMutationOptions<
    AcceptTermsAndConditionsMutation,
    AcceptTermsAndConditionsMutationVariables
  >;
export const AccountOutsideBusinessHoursRestrictionsDocument = gql`
  subscription AccountOutsideBusinessHoursRestrictions {
    outsideBusinessHoursRestrictions {
      accountId
      restriction
    }
  }
`;

/**
 * __useAccountOutsideBusinessHoursRestrictionsSubscription__
 *
 * To run a query within a React component, call `useAccountOutsideBusinessHoursRestrictionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountOutsideBusinessHoursRestrictionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountOutsideBusinessHoursRestrictionsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountOutsideBusinessHoursRestrictionsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    AccountOutsideBusinessHoursRestrictionsSubscription,
    AccountOutsideBusinessHoursRestrictionsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    AccountOutsideBusinessHoursRestrictionsSubscription,
    AccountOutsideBusinessHoursRestrictionsSubscriptionVariables
  >(AccountOutsideBusinessHoursRestrictionsDocument, options);
}
export type AccountOutsideBusinessHoursRestrictionsSubscriptionHookResult =
  ReturnType<typeof useAccountOutsideBusinessHoursRestrictionsSubscription>;
export type AccountOutsideBusinessHoursRestrictionsSubscriptionResult =
  Apollo.SubscriptionResult<AccountOutsideBusinessHoursRestrictionsSubscription>;
export const GetAccountsCountersDocument = gql`
  query GetAccountsCounters {
    getAccountsCounters {
      activePrimary
      activeSecondary
      trial
      inactive
      monthNewActive
      plans {
        total
        type
      }
    }
  }
`;

/**
 * __useGetAccountsCountersQuery__
 *
 * To run a query within a React component, call `useGetAccountsCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsCountersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountsCountersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountsCountersQuery,
    GetAccountsCountersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountsCountersQuery,
    GetAccountsCountersQueryVariables
  >(GetAccountsCountersDocument, options);
}
export function useGetAccountsCountersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountsCountersQuery,
    GetAccountsCountersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountsCountersQuery,
    GetAccountsCountersQueryVariables
  >(GetAccountsCountersDocument, options);
}
export function useGetAccountsCountersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAccountsCountersQuery,
        GetAccountsCountersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAccountsCountersQuery,
    GetAccountsCountersQueryVariables
  >(GetAccountsCountersDocument, options);
}
export type GetAccountsCountersQueryHookResult = ReturnType<
  typeof useGetAccountsCountersQuery
>;
export type GetAccountsCountersLazyQueryHookResult = ReturnType<
  typeof useGetAccountsCountersLazyQuery
>;
export type GetAccountsCountersSuspenseQueryHookResult = ReturnType<
  typeof useGetAccountsCountersSuspenseQuery
>;
export type GetAccountsCountersQueryResult = Apollo.QueryResult<
  GetAccountsCountersQuery,
  GetAccountsCountersQueryVariables
>;
export const GetAccountsSummaryDocument = gql`
  query GetAccountsSummary {
    getAccountsSummary {
      products {
        activePrimary
        activeSecondary
        inactive
        monthNewActive
        product
        trial
      }
      subscriptions {
        dialer {
          total
          type
        }
        elite {
          total
          type
        }
        messaging {
          total
          type
        }
        inactiveMessaging {
          total
        }
        inactiveDialer {
          total
        }
      }
    }
  }
`;

/**
 * __useGetAccountsSummaryQuery__
 *
 * To run a query within a React component, call `useGetAccountsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountsSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountsSummaryQuery,
    GetAccountsSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountsSummaryQuery,
    GetAccountsSummaryQueryVariables
  >(GetAccountsSummaryDocument, options);
}
export function useGetAccountsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountsSummaryQuery,
    GetAccountsSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountsSummaryQuery,
    GetAccountsSummaryQueryVariables
  >(GetAccountsSummaryDocument, options);
}
export function useGetAccountsSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAccountsSummaryQuery,
        GetAccountsSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAccountsSummaryQuery,
    GetAccountsSummaryQueryVariables
  >(GetAccountsSummaryDocument, options);
}
export type GetAccountsSummaryQueryHookResult = ReturnType<
  typeof useGetAccountsSummaryQuery
>;
export type GetAccountsSummaryLazyQueryHookResult = ReturnType<
  typeof useGetAccountsSummaryLazyQuery
>;
export type GetAccountsSummarySuspenseQueryHookResult = ReturnType<
  typeof useGetAccountsSummarySuspenseQuery
>;
export type GetAccountsSummaryQueryResult = Apollo.QueryResult<
  GetAccountsSummaryQuery,
  GetAccountsSummaryQueryVariables
>;
export const GetStatisticsDocument = gql`
  query GetStatistics {
    getStatistics {
      infobip {
        weeklySmsUsage
        deliveryRate
        approved
        declined
        pending
      }
      telnyx {
        weeklySmsUsage
        deliveryRate
        approved
        declined
        pending
      }
      twilio {
        weeklySmsUsage
        deliveryRate
        approved
        declined
        pending
      }
      commio {
        weeklySmsUsage
        deliveryRate
        approved
        declined
        pending
      }
    }
  }
`;

/**
 * __useGetStatisticsQuery__
 *
 * To run a query within a React component, call `useGetStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatisticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStatisticsQuery,
    GetStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatisticsQuery, GetStatisticsQueryVariables>(
    GetStatisticsDocument,
    options,
  );
}
export function useGetStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStatisticsQuery,
    GetStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatisticsQuery, GetStatisticsQueryVariables>(
    GetStatisticsDocument,
    options,
  );
}
export function useGetStatisticsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetStatisticsQuery,
        GetStatisticsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetStatisticsQuery,
    GetStatisticsQueryVariables
  >(GetStatisticsDocument, options);
}
export type GetStatisticsQueryHookResult = ReturnType<
  typeof useGetStatisticsQuery
>;
export type GetStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetStatisticsLazyQuery
>;
export type GetStatisticsSuspenseQueryHookResult = ReturnType<
  typeof useGetStatisticsSuspenseQuery
>;
export type GetStatisticsQueryResult = Apollo.QueryResult<
  GetStatisticsQuery,
  GetStatisticsQueryVariables
>;
export const ExportAccountsDocument = gql`
  query ExportAccounts($password: String!, $filter: AccountsFilter) {
    exportAccounts(password: $password, filter: $filter) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useExportAccountsQuery__
 *
 * To run a query within a React component, call `useExportAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportAccountsQuery({
 *   variables: {
 *      password: // value for 'password'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportAccountsQuery,
    ExportAccountsQueryVariables
  > &
    (
      | { variables: ExportAccountsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportAccountsQuery, ExportAccountsQueryVariables>(
    ExportAccountsDocument,
    options,
  );
}
export function useExportAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportAccountsQuery,
    ExportAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportAccountsQuery, ExportAccountsQueryVariables>(
    ExportAccountsDocument,
    options,
  );
}
export function useExportAccountsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExportAccountsQuery,
        ExportAccountsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExportAccountsQuery,
    ExportAccountsQueryVariables
  >(ExportAccountsDocument, options);
}
export type ExportAccountsQueryHookResult = ReturnType<
  typeof useExportAccountsQuery
>;
export type ExportAccountsLazyQueryHookResult = ReturnType<
  typeof useExportAccountsLazyQuery
>;
export type ExportAccountsSuspenseQueryHookResult = ReturnType<
  typeof useExportAccountsSuspenseQuery
>;
export type ExportAccountsQueryResult = Apollo.QueryResult<
  ExportAccountsQuery,
  ExportAccountsQueryVariables
>;
export const FindAccountsDocument = gql`
  query FindAccounts(
    $filter: AccountsFilter
    $pagination: Pagination
    $order: Order
  ) {
    findAccounts(filter: $filter, pagination: $pagination, order: $order) {
      items {
        ...AdminAccountFragment
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${AdminAccountFragmentFragmentDoc}
`;

/**
 * __useFindAccountsQuery__
 *
 * To run a query within a React component, call `useFindAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAccountsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useFindAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindAccountsQuery,
    FindAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAccountsQuery, FindAccountsQueryVariables>(
    FindAccountsDocument,
    options,
  );
}
export function useFindAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAccountsQuery,
    FindAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAccountsQuery, FindAccountsQueryVariables>(
    FindAccountsDocument,
    options,
  );
}
export function useFindAccountsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindAccountsQuery,
        FindAccountsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindAccountsQuery, FindAccountsQueryVariables>(
    FindAccountsDocument,
    options,
  );
}
export type FindAccountsQueryHookResult = ReturnType<
  typeof useFindAccountsQuery
>;
export type FindAccountsLazyQueryHookResult = ReturnType<
  typeof useFindAccountsLazyQuery
>;
export type FindAccountsSuspenseQueryHookResult = ReturnType<
  typeof useFindAccountsSuspenseQuery
>;
export type FindAccountsQueryResult = Apollo.QueryResult<
  FindAccountsQuery,
  FindAccountsQueryVariables
>;
export const AdminUpdateAccountDocument = gql`
  mutation AdminUpdateAccount($data: AdminUpdateAccountInput!, $id: String!) {
    adminUpdateAccount(data: $data, id: $id) {
      id
    }
  }
`;
export type AdminUpdateAccountMutationFn = Apollo.MutationFunction<
  AdminUpdateAccountMutation,
  AdminUpdateAccountMutationVariables
>;

/**
 * __useAdminUpdateAccountMutation__
 *
 * To run a mutation, you first call `useAdminUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateAccountMutation, { data, loading, error }] = useAdminUpdateAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateAccountMutation,
    AdminUpdateAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateAccountMutation,
    AdminUpdateAccountMutationVariables
  >(AdminUpdateAccountDocument, options);
}
export type AdminUpdateAccountMutationHookResult = ReturnType<
  typeof useAdminUpdateAccountMutation
>;
export type AdminUpdateAccountMutationResult =
  Apollo.MutationResult<AdminUpdateAccountMutation>;
export type AdminUpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateAccountMutation,
  AdminUpdateAccountMutationVariables
>;
export const AdminDeletePhoneDocument = gql`
  mutation AdminDeletePhone($id: String!) {
    adminDeletePhone(id: $id) {
      id
    }
  }
`;
export type AdminDeletePhoneMutationFn = Apollo.MutationFunction<
  AdminDeletePhoneMutation,
  AdminDeletePhoneMutationVariables
>;

/**
 * __useAdminDeletePhoneMutation__
 *
 * To run a mutation, you first call `useAdminDeletePhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeletePhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeletePhoneMutation, { data, loading, error }] = useAdminDeletePhoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeletePhoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeletePhoneMutation,
    AdminDeletePhoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminDeletePhoneMutation,
    AdminDeletePhoneMutationVariables
  >(AdminDeletePhoneDocument, options);
}
export type AdminDeletePhoneMutationHookResult = ReturnType<
  typeof useAdminDeletePhoneMutation
>;
export type AdminDeletePhoneMutationResult =
  Apollo.MutationResult<AdminDeletePhoneMutation>;
export type AdminDeletePhoneMutationOptions = Apollo.BaseMutationOptions<
  AdminDeletePhoneMutation,
  AdminDeletePhoneMutationVariables
>;
export const AdminRemoveAccountDocument = gql`
  mutation AdminRemoveAccount($id: String!, $password: String!) {
    adminRemoveAccount(id: $id, password: $password) {
      success
    }
  }
`;
export type AdminRemoveAccountMutationFn = Apollo.MutationFunction<
  AdminRemoveAccountMutation,
  AdminRemoveAccountMutationVariables
>;

/**
 * __useAdminRemoveAccountMutation__
 *
 * To run a mutation, you first call `useAdminRemoveAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRemoveAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRemoveAccountMutation, { data, loading, error }] = useAdminRemoveAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAdminRemoveAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminRemoveAccountMutation,
    AdminRemoveAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminRemoveAccountMutation,
    AdminRemoveAccountMutationVariables
  >(AdminRemoveAccountDocument, options);
}
export type AdminRemoveAccountMutationHookResult = ReturnType<
  typeof useAdminRemoveAccountMutation
>;
export type AdminRemoveAccountMutationResult =
  Apollo.MutationResult<AdminRemoveAccountMutation>;
export type AdminRemoveAccountMutationOptions = Apollo.BaseMutationOptions<
  AdminRemoveAccountMutation,
  AdminRemoveAccountMutationVariables
>;
export const GetAccountDocument = gql`
  query GetAccount($id: String!) {
    getAccount(id: $id) {
      ...AdminAccountFragment
      parentAccount {
        id
        ownerId
        status
        type
        email
        isSubAccount
        createdAt
        isTest
        isOnboarded
        firestoreId
        companyName
        timeZone
        a2PStatus
        tollFreeStatus
        phone
      }
      subAccounts {
        id
      }
    }
  }
  ${AdminAccountFragmentFragmentDoc}
`;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountQuery,
    GetAccountQueryVariables
  > &
    (
      | { variables: GetAccountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(
    GetAccountDocument,
    options,
  );
}
export function useGetAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountQuery,
    GetAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(
    GetAccountDocument,
    options,
  );
}
export function useGetAccountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAccountQuery,
        GetAccountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAccountQuery, GetAccountQueryVariables>(
    GetAccountDocument,
    options,
  );
}
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<
  typeof useGetAccountLazyQuery
>;
export type GetAccountSuspenseQueryHookResult = ReturnType<
  typeof useGetAccountSuspenseQuery
>;
export type GetAccountQueryResult = Apollo.QueryResult<
  GetAccountQuery,
  GetAccountQueryVariables
>;
export const AdminUpdatePasswordDocument = gql`
  mutation AdminUpdatePassword($id: String!) {
    adminUpdatePassword(id: $id) {
      password
    }
  }
`;
export type AdminUpdatePasswordMutationFn = Apollo.MutationFunction<
  AdminUpdatePasswordMutation,
  AdminUpdatePasswordMutationVariables
>;

/**
 * __useAdminUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useAdminUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdatePasswordMutation, { data, loading, error }] = useAdminUpdatePasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdatePasswordMutation,
    AdminUpdatePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdatePasswordMutation,
    AdminUpdatePasswordMutationVariables
  >(AdminUpdatePasswordDocument, options);
}
export type AdminUpdatePasswordMutationHookResult = ReturnType<
  typeof useAdminUpdatePasswordMutation
>;
export type AdminUpdatePasswordMutationResult =
  Apollo.MutationResult<AdminUpdatePasswordMutation>;
export type AdminUpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdatePasswordMutation,
  AdminUpdatePasswordMutationVariables
>;
export const AdminTopUpBalanceDocument = gql`
  mutation AdminTopUpBalance($input: TopUpAccountBalanceInput!) {
    adminTopUpBalance(input: $input) {
      success
    }
  }
`;
export type AdminTopUpBalanceMutationFn = Apollo.MutationFunction<
  AdminTopUpBalanceMutation,
  AdminTopUpBalanceMutationVariables
>;

/**
 * __useAdminTopUpBalanceMutation__
 *
 * To run a mutation, you first call `useAdminTopUpBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminTopUpBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminTopUpBalanceMutation, { data, loading, error }] = useAdminTopUpBalanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminTopUpBalanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminTopUpBalanceMutation,
    AdminTopUpBalanceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminTopUpBalanceMutation,
    AdminTopUpBalanceMutationVariables
  >(AdminTopUpBalanceDocument, options);
}
export type AdminTopUpBalanceMutationHookResult = ReturnType<
  typeof useAdminTopUpBalanceMutation
>;
export type AdminTopUpBalanceMutationResult =
  Apollo.MutationResult<AdminTopUpBalanceMutation>;
export type AdminTopUpBalanceMutationOptions = Apollo.BaseMutationOptions<
  AdminTopUpBalanceMutation,
  AdminTopUpBalanceMutationVariables
>;
export const UpdateAccountSubscriptionDocument = gql`
  mutation UpdateAccountSubscription(
    $input: UpdateSubscriptionInput!
    $subscriptionId: String!
    $accountId: String!
  ) {
    updateAccountSubscription(
      input: $input
      subscriptionId: $subscriptionId
      accountId: $accountId
    ) {
      id
      endAt
      membershipMonths
      trialEndedAt
      startedAt
      plan {
        id
        userTitle
        type
      }
    }
  }
`;
export type UpdateAccountSubscriptionMutationFn = Apollo.MutationFunction<
  UpdateAccountSubscriptionMutation,
  UpdateAccountSubscriptionMutationVariables
>;

/**
 * __useUpdateAccountSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateAccountSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountSubscriptionMutation, { data, loading, error }] = useUpdateAccountSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      subscriptionId: // value for 'subscriptionId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useUpdateAccountSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountSubscriptionMutation,
    UpdateAccountSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountSubscriptionMutation,
    UpdateAccountSubscriptionMutationVariables
  >(UpdateAccountSubscriptionDocument, options);
}
export type UpdateAccountSubscriptionMutationHookResult = ReturnType<
  typeof useUpdateAccountSubscriptionMutation
>;
export type UpdateAccountSubscriptionMutationResult =
  Apollo.MutationResult<UpdateAccountSubscriptionMutation>;
export type UpdateAccountSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAccountSubscriptionMutation,
    UpdateAccountSubscriptionMutationVariables
  >;
export const AdminChangeAccountMessagingProviderDocument = gql`
  mutation AdminChangeAccountMessagingProvider(
    $input: AdminChangeAccountMessagingProviderInput!
  ) {
    adminChangeAccountMessagingProvider(input: $input) {
      success
    }
  }
`;
export type AdminChangeAccountMessagingProviderMutationFn =
  Apollo.MutationFunction<
    AdminChangeAccountMessagingProviderMutation,
    AdminChangeAccountMessagingProviderMutationVariables
  >;

/**
 * __useAdminChangeAccountMessagingProviderMutation__
 *
 * To run a mutation, you first call `useAdminChangeAccountMessagingProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminChangeAccountMessagingProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminChangeAccountMessagingProviderMutation, { data, loading, error }] = useAdminChangeAccountMessagingProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminChangeAccountMessagingProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminChangeAccountMessagingProviderMutation,
    AdminChangeAccountMessagingProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminChangeAccountMessagingProviderMutation,
    AdminChangeAccountMessagingProviderMutationVariables
  >(AdminChangeAccountMessagingProviderDocument, options);
}
export type AdminChangeAccountMessagingProviderMutationHookResult = ReturnType<
  typeof useAdminChangeAccountMessagingProviderMutation
>;
export type AdminChangeAccountMessagingProviderMutationResult =
  Apollo.MutationResult<AdminChangeAccountMessagingProviderMutation>;
export type AdminChangeAccountMessagingProviderMutationOptions =
  Apollo.BaseMutationOptions<
    AdminChangeAccountMessagingProviderMutation,
    AdminChangeAccountMessagingProviderMutationVariables
  >;
export const AdminChangeSubAccountMessagingProviderDocument = gql`
  mutation AdminChangeSubAccountMessagingProvider(
    $input: AdminChangeSubAccountMessagingProviderInput!
  ) {
    adminChangeSubAccountMessagingProvider(input: $input) {
      success
    }
  }
`;
export type AdminChangeSubAccountMessagingProviderMutationFn =
  Apollo.MutationFunction<
    AdminChangeSubAccountMessagingProviderMutation,
    AdminChangeSubAccountMessagingProviderMutationVariables
  >;

/**
 * __useAdminChangeSubAccountMessagingProviderMutation__
 *
 * To run a mutation, you first call `useAdminChangeSubAccountMessagingProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminChangeSubAccountMessagingProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminChangeSubAccountMessagingProviderMutation, { data, loading, error }] = useAdminChangeSubAccountMessagingProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminChangeSubAccountMessagingProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminChangeSubAccountMessagingProviderMutation,
    AdminChangeSubAccountMessagingProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminChangeSubAccountMessagingProviderMutation,
    AdminChangeSubAccountMessagingProviderMutationVariables
  >(AdminChangeSubAccountMessagingProviderDocument, options);
}
export type AdminChangeSubAccountMessagingProviderMutationHookResult =
  ReturnType<typeof useAdminChangeSubAccountMessagingProviderMutation>;
export type AdminChangeSubAccountMessagingProviderMutationResult =
  Apollo.MutationResult<AdminChangeSubAccountMessagingProviderMutation>;
export type AdminChangeSubAccountMessagingProviderMutationOptions =
  Apollo.BaseMutationOptions<
    AdminChangeSubAccountMessagingProviderMutation,
    AdminChangeSubAccountMessagingProviderMutationVariables
  >;
export const UpdateA2PRegistrationExternalDataDocument = gql`
  mutation UpdateA2PRegistrationExternalData(
    $input: UpdateA2PRegistrationExternalDataInput!
    $accountId: String!
  ) {
    updateA2PRegistrationExternalData(input: $input, accountId: $accountId) {
      success
    }
  }
`;
export type UpdateA2PRegistrationExternalDataMutationFn =
  Apollo.MutationFunction<
    UpdateA2PRegistrationExternalDataMutation,
    UpdateA2PRegistrationExternalDataMutationVariables
  >;

/**
 * __useUpdateA2PRegistrationExternalDataMutation__
 *
 * To run a mutation, you first call `useUpdateA2PRegistrationExternalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateA2PRegistrationExternalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateA2PRegistrationExternalDataMutation, { data, loading, error }] = useUpdateA2PRegistrationExternalDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useUpdateA2PRegistrationExternalDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateA2PRegistrationExternalDataMutation,
    UpdateA2PRegistrationExternalDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateA2PRegistrationExternalDataMutation,
    UpdateA2PRegistrationExternalDataMutationVariables
  >(UpdateA2PRegistrationExternalDataDocument, options);
}
export type UpdateA2PRegistrationExternalDataMutationHookResult = ReturnType<
  typeof useUpdateA2PRegistrationExternalDataMutation
>;
export type UpdateA2PRegistrationExternalDataMutationResult =
  Apollo.MutationResult<UpdateA2PRegistrationExternalDataMutation>;
export type UpdateA2PRegistrationExternalDataMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateA2PRegistrationExternalDataMutation,
    UpdateA2PRegistrationExternalDataMutationVariables
  >;
export const AdminAddOwnPhoneDocument = gql`
  mutation AdminAddOwnPhone($input: AdminAddOwnPhoneInput!) {
    adminAddOwnPhone(input: $input) {
      success
    }
  }
`;
export type AdminAddOwnPhoneMutationFn = Apollo.MutationFunction<
  AdminAddOwnPhoneMutation,
  AdminAddOwnPhoneMutationVariables
>;

/**
 * __useAdminAddOwnPhoneMutation__
 *
 * To run a mutation, you first call `useAdminAddOwnPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddOwnPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddOwnPhoneMutation, { data, loading, error }] = useAdminAddOwnPhoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminAddOwnPhoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminAddOwnPhoneMutation,
    AdminAddOwnPhoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminAddOwnPhoneMutation,
    AdminAddOwnPhoneMutationVariables
  >(AdminAddOwnPhoneDocument, options);
}
export type AdminAddOwnPhoneMutationHookResult = ReturnType<
  typeof useAdminAddOwnPhoneMutation
>;
export type AdminAddOwnPhoneMutationResult =
  Apollo.MutationResult<AdminAddOwnPhoneMutation>;
export type AdminAddOwnPhoneMutationOptions = Apollo.BaseMutationOptions<
  AdminAddOwnPhoneMutation,
  AdminAddOwnPhoneMutationVariables
>;
export const RegisterAffiliateDocument = gql`
  mutation RegisterAffiliate($input: RegisterAffiliateInput!) {
    registerAffiliate(input: $input) {
      ...Affiliate
    }
  }
  ${AffiliateFragmentDoc}
`;
export type RegisterAffiliateMutationFn = Apollo.MutationFunction<
  RegisterAffiliateMutation,
  RegisterAffiliateMutationVariables
>;

/**
 * __useRegisterAffiliateMutation__
 *
 * To run a mutation, you first call `useRegisterAffiliateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAffiliateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAffiliateMutation, { data, loading, error }] = useRegisterAffiliateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterAffiliateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterAffiliateMutation,
    RegisterAffiliateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterAffiliateMutation,
    RegisterAffiliateMutationVariables
  >(RegisterAffiliateDocument, options);
}
export type RegisterAffiliateMutationHookResult = ReturnType<
  typeof useRegisterAffiliateMutation
>;
export type RegisterAffiliateMutationResult =
  Apollo.MutationResult<RegisterAffiliateMutation>;
export type RegisterAffiliateMutationOptions = Apollo.BaseMutationOptions<
  RegisterAffiliateMutation,
  RegisterAffiliateMutationVariables
>;
export const RegisterAffiliateLeadDocument = gql`
  mutation RegisterAffiliateLead($input: AdminRegisterAffiliateLeadInput!) {
    registerAffiliateLead(input: $input) {
      ...Affiliate
    }
  }
  ${AffiliateFragmentDoc}
`;
export type RegisterAffiliateLeadMutationFn = Apollo.MutationFunction<
  RegisterAffiliateLeadMutation,
  RegisterAffiliateLeadMutationVariables
>;

/**
 * __useRegisterAffiliateLeadMutation__
 *
 * To run a mutation, you first call `useRegisterAffiliateLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAffiliateLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAffiliateLeadMutation, { data, loading, error }] = useRegisterAffiliateLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterAffiliateLeadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterAffiliateLeadMutation,
    RegisterAffiliateLeadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterAffiliateLeadMutation,
    RegisterAffiliateLeadMutationVariables
  >(RegisterAffiliateLeadDocument, options);
}
export type RegisterAffiliateLeadMutationHookResult = ReturnType<
  typeof useRegisterAffiliateLeadMutation
>;
export type RegisterAffiliateLeadMutationResult =
  Apollo.MutationResult<RegisterAffiliateLeadMutation>;
export type RegisterAffiliateLeadMutationOptions = Apollo.BaseMutationOptions<
  RegisterAffiliateLeadMutation,
  RegisterAffiliateLeadMutationVariables
>;
export const AffiliateDocument = gql`
  query Affiliate {
    affiliate {
      ...Affiliate
    }
  }
  ${AffiliateFragmentDoc}
`;

/**
 * __useAffiliateQuery__
 *
 * To run a query within a React component, call `useAffiliateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliateQuery({
 *   variables: {
 *   },
 * });
 */
export function useAffiliateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AffiliateQuery,
    AffiliateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AffiliateQuery, AffiliateQueryVariables>(
    AffiliateDocument,
    options,
  );
}
export function useAffiliateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AffiliateQuery,
    AffiliateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AffiliateQuery, AffiliateQueryVariables>(
    AffiliateDocument,
    options,
  );
}
export function useAffiliateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AffiliateQuery, AffiliateQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AffiliateQuery, AffiliateQueryVariables>(
    AffiliateDocument,
    options,
  );
}
export type AffiliateQueryHookResult = ReturnType<typeof useAffiliateQuery>;
export type AffiliateLazyQueryHookResult = ReturnType<
  typeof useAffiliateLazyQuery
>;
export type AffiliateSuspenseQueryHookResult = ReturnType<
  typeof useAffiliateSuspenseQuery
>;
export type AffiliateQueryResult = Apollo.QueryResult<
  AffiliateQuery,
  AffiliateQueryVariables
>;
export const FindAffiliatesDocument = gql`
  query FindAffiliates($filter: AffiliateFilter, $pagination: Pagination) {
    findAffiliates(filter: $filter, pagination: $pagination) {
      items {
        ...Affiliate
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${AffiliateFragmentDoc}
`;

/**
 * __useFindAffiliatesQuery__
 *
 * To run a query within a React component, call `useFindAffiliatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAffiliatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAffiliatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindAffiliatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindAffiliatesQuery,
    FindAffiliatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAffiliatesQuery, FindAffiliatesQueryVariables>(
    FindAffiliatesDocument,
    options,
  );
}
export function useFindAffiliatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAffiliatesQuery,
    FindAffiliatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAffiliatesQuery, FindAffiliatesQueryVariables>(
    FindAffiliatesDocument,
    options,
  );
}
export function useFindAffiliatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindAffiliatesQuery,
        FindAffiliatesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindAffiliatesQuery,
    FindAffiliatesQueryVariables
  >(FindAffiliatesDocument, options);
}
export type FindAffiliatesQueryHookResult = ReturnType<
  typeof useFindAffiliatesQuery
>;
export type FindAffiliatesLazyQueryHookResult = ReturnType<
  typeof useFindAffiliatesLazyQuery
>;
export type FindAffiliatesSuspenseQueryHookResult = ReturnType<
  typeof useFindAffiliatesSuspenseQuery
>;
export type FindAffiliatesQueryResult = Apollo.QueryResult<
  FindAffiliatesQuery,
  FindAffiliatesQueryVariables
>;
export const GetAffiliateDocument = gql`
  query GetAffiliate($getAffiliateId: String!) {
    getAffiliate(id: $getAffiliateId) {
      ...Affiliate
    }
  }
  ${AffiliateFragmentDoc}
`;

/**
 * __useGetAffiliateQuery__
 *
 * To run a query within a React component, call `useGetAffiliateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAffiliateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAffiliateQuery({
 *   variables: {
 *      getAffiliateId: // value for 'getAffiliateId'
 *   },
 * });
 */
export function useGetAffiliateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAffiliateQuery,
    GetAffiliateQueryVariables
  > &
    (
      | { variables: GetAffiliateQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAffiliateQuery, GetAffiliateQueryVariables>(
    GetAffiliateDocument,
    options,
  );
}
export function useGetAffiliateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAffiliateQuery,
    GetAffiliateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAffiliateQuery, GetAffiliateQueryVariables>(
    GetAffiliateDocument,
    options,
  );
}
export function useGetAffiliateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAffiliateQuery,
        GetAffiliateQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAffiliateQuery, GetAffiliateQueryVariables>(
    GetAffiliateDocument,
    options,
  );
}
export type GetAffiliateQueryHookResult = ReturnType<
  typeof useGetAffiliateQuery
>;
export type GetAffiliateLazyQueryHookResult = ReturnType<
  typeof useGetAffiliateLazyQuery
>;
export type GetAffiliateSuspenseQueryHookResult = ReturnType<
  typeof useGetAffiliateSuspenseQuery
>;
export type GetAffiliateQueryResult = Apollo.QueryResult<
  GetAffiliateQuery,
  GetAffiliateQueryVariables
>;
export const RemoveAffiliateDocument = gql`
  mutation RemoveAffiliate($removeAffiliateId: String!) {
    removeAffiliate(id: $removeAffiliateId) {
      success
    }
  }
`;
export type RemoveAffiliateMutationFn = Apollo.MutationFunction<
  RemoveAffiliateMutation,
  RemoveAffiliateMutationVariables
>;

/**
 * __useRemoveAffiliateMutation__
 *
 * To run a mutation, you first call `useRemoveAffiliateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAffiliateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAffiliateMutation, { data, loading, error }] = useRemoveAffiliateMutation({
 *   variables: {
 *      removeAffiliateId: // value for 'removeAffiliateId'
 *   },
 * });
 */
export function useRemoveAffiliateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAffiliateMutation,
    RemoveAffiliateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAffiliateMutation,
    RemoveAffiliateMutationVariables
  >(RemoveAffiliateDocument, options);
}
export type RemoveAffiliateMutationHookResult = ReturnType<
  typeof useRemoveAffiliateMutation
>;
export type RemoveAffiliateMutationResult =
  Apollo.MutationResult<RemoveAffiliateMutation>;
export type RemoveAffiliateMutationOptions = Apollo.BaseMutationOptions<
  RemoveAffiliateMutation,
  RemoveAffiliateMutationVariables
>;
export const FindArticlesDocument = gql`
  query FindArticles {
    findArticles {
      ...Article
    }
  }
  ${ArticleFragmentDoc}
`;

/**
 * __useFindArticlesQuery__
 *
 * To run a query within a React component, call `useFindArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindArticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindArticlesQuery,
    FindArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindArticlesQuery, FindArticlesQueryVariables>(
    FindArticlesDocument,
    options,
  );
}
export function useFindArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindArticlesQuery,
    FindArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindArticlesQuery, FindArticlesQueryVariables>(
    FindArticlesDocument,
    options,
  );
}
export function useFindArticlesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindArticlesQuery,
        FindArticlesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindArticlesQuery, FindArticlesQueryVariables>(
    FindArticlesDocument,
    options,
  );
}
export type FindArticlesQueryHookResult = ReturnType<
  typeof useFindArticlesQuery
>;
export type FindArticlesLazyQueryHookResult = ReturnType<
  typeof useFindArticlesLazyQuery
>;
export type FindArticlesSuspenseQueryHookResult = ReturnType<
  typeof useFindArticlesSuspenseQuery
>;
export type FindArticlesQueryResult = Apollo.QueryResult<
  FindArticlesQuery,
  FindArticlesQueryVariables
>;
export const DownloadBillingReportDocument = gql`
  query DownloadBillingReport($filter: PaymentsFilter) {
    downloadBillingReport(filter: $filter) {
      url
    }
  }
`;

/**
 * __useDownloadBillingReportQuery__
 *
 * To run a query within a React component, call `useDownloadBillingReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadBillingReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadBillingReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDownloadBillingReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DownloadBillingReportQuery,
    DownloadBillingReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadBillingReportQuery,
    DownloadBillingReportQueryVariables
  >(DownloadBillingReportDocument, options);
}
export function useDownloadBillingReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadBillingReportQuery,
    DownloadBillingReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadBillingReportQuery,
    DownloadBillingReportQueryVariables
  >(DownloadBillingReportDocument, options);
}
export function useDownloadBillingReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DownloadBillingReportQuery,
        DownloadBillingReportQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DownloadBillingReportQuery,
    DownloadBillingReportQueryVariables
  >(DownloadBillingReportDocument, options);
}
export type DownloadBillingReportQueryHookResult = ReturnType<
  typeof useDownloadBillingReportQuery
>;
export type DownloadBillingReportLazyQueryHookResult = ReturnType<
  typeof useDownloadBillingReportLazyQuery
>;
export type DownloadBillingReportSuspenseQueryHookResult = ReturnType<
  typeof useDownloadBillingReportSuspenseQuery
>;
export type DownloadBillingReportQueryResult = Apollo.QueryResult<
  DownloadBillingReportQuery,
  DownloadBillingReportQueryVariables
>;
export const FindPaymentsDocument = gql`
  query FindPayments($filter: PaymentsFilter, $pagination: Pagination) {
    findPayments(filter: $filter, pagination: $pagination) {
      items {
        ...Payment
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${PaymentFragmentDoc}
`;

/**
 * __useFindPaymentsQuery__
 *
 * To run a query within a React component, call `useFindPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPaymentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindPaymentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindPaymentsQuery,
    FindPaymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindPaymentsQuery, FindPaymentsQueryVariables>(
    FindPaymentsDocument,
    options,
  );
}
export function useFindPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindPaymentsQuery,
    FindPaymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindPaymentsQuery, FindPaymentsQueryVariables>(
    FindPaymentsDocument,
    options,
  );
}
export function useFindPaymentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindPaymentsQuery,
        FindPaymentsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindPaymentsQuery, FindPaymentsQueryVariables>(
    FindPaymentsDocument,
    options,
  );
}
export type FindPaymentsQueryHookResult = ReturnType<
  typeof useFindPaymentsQuery
>;
export type FindPaymentsLazyQueryHookResult = ReturnType<
  typeof useFindPaymentsLazyQuery
>;
export type FindPaymentsSuspenseQueryHookResult = ReturnType<
  typeof useFindPaymentsSuspenseQuery
>;
export type FindPaymentsQueryResult = Apollo.QueryResult<
  FindPaymentsQuery,
  FindPaymentsQueryVariables
>;
export const FindLatestPaymentDocument = gql`
  query FindLatestPayment($filter: PaymentsFilter) {
    findPayments(filter: $filter, pagination: { limit: 1 }) {
      items {
        createdAt
      }
    }
  }
`;

/**
 * __useFindLatestPaymentQuery__
 *
 * To run a query within a React component, call `useFindLatestPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLatestPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLatestPaymentQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindLatestPaymentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindLatestPaymentQuery,
    FindLatestPaymentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindLatestPaymentQuery,
    FindLatestPaymentQueryVariables
  >(FindLatestPaymentDocument, options);
}
export function useFindLatestPaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindLatestPaymentQuery,
    FindLatestPaymentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindLatestPaymentQuery,
    FindLatestPaymentQueryVariables
  >(FindLatestPaymentDocument, options);
}
export function useFindLatestPaymentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindLatestPaymentQuery,
        FindLatestPaymentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindLatestPaymentQuery,
    FindLatestPaymentQueryVariables
  >(FindLatestPaymentDocument, options);
}
export type FindLatestPaymentQueryHookResult = ReturnType<
  typeof useFindLatestPaymentQuery
>;
export type FindLatestPaymentLazyQueryHookResult = ReturnType<
  typeof useFindLatestPaymentLazyQuery
>;
export type FindLatestPaymentSuspenseQueryHookResult = ReturnType<
  typeof useFindLatestPaymentSuspenseQuery
>;
export type FindLatestPaymentQueryResult = Apollo.QueryResult<
  FindLatestPaymentQuery,
  FindLatestPaymentQueryVariables
>;
export const TopUpBalanceDocument = gql`
  mutation TopUpBalance($input: TopUpBalanceInput!) {
    topUpBalance(input: $input) {
      success
    }
  }
`;
export type TopUpBalanceMutationFn = Apollo.MutationFunction<
  TopUpBalanceMutation,
  TopUpBalanceMutationVariables
>;

/**
 * __useTopUpBalanceMutation__
 *
 * To run a mutation, you first call `useTopUpBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTopUpBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [topUpBalanceMutation, { data, loading, error }] = useTopUpBalanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTopUpBalanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TopUpBalanceMutation,
    TopUpBalanceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TopUpBalanceMutation,
    TopUpBalanceMutationVariables
  >(TopUpBalanceDocument, options);
}
export type TopUpBalanceMutationHookResult = ReturnType<
  typeof useTopUpBalanceMutation
>;
export type TopUpBalanceMutationResult =
  Apollo.MutationResult<TopUpBalanceMutation>;
export type TopUpBalanceMutationOptions = Apollo.BaseMutationOptions<
  TopUpBalanceMutation,
  TopUpBalanceMutationVariables
>;
export const SetDefaultPaymentSourceDocument = gql`
  mutation SetDefaultPaymentSource($input: PaymentSourceInput!) {
    setDefaultPaymentSource(input: $input) {
      success
    }
  }
`;
export type SetDefaultPaymentSourceMutationFn = Apollo.MutationFunction<
  SetDefaultPaymentSourceMutation,
  SetDefaultPaymentSourceMutationVariables
>;

/**
 * __useSetDefaultPaymentSourceMutation__
 *
 * To run a mutation, you first call `useSetDefaultPaymentSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultPaymentSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultPaymentSourceMutation, { data, loading, error }] = useSetDefaultPaymentSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDefaultPaymentSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDefaultPaymentSourceMutation,
    SetDefaultPaymentSourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetDefaultPaymentSourceMutation,
    SetDefaultPaymentSourceMutationVariables
  >(SetDefaultPaymentSourceDocument, options);
}
export type SetDefaultPaymentSourceMutationHookResult = ReturnType<
  typeof useSetDefaultPaymentSourceMutation
>;
export type SetDefaultPaymentSourceMutationResult =
  Apollo.MutationResult<SetDefaultPaymentSourceMutation>;
export type SetDefaultPaymentSourceMutationOptions = Apollo.BaseMutationOptions<
  SetDefaultPaymentSourceMutation,
  SetDefaultPaymentSourceMutationVariables
>;
export const AddPaymentSourceDocument = gql`
  mutation AddPaymentSource($input: PaymentSourceInput!) {
    addPaymentSource(input: $input) {
      success
    }
  }
`;
export type AddPaymentSourceMutationFn = Apollo.MutationFunction<
  AddPaymentSourceMutation,
  AddPaymentSourceMutationVariables
>;

/**
 * __useAddPaymentSourceMutation__
 *
 * To run a mutation, you first call `useAddPaymentSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentSourceMutation, { data, loading, error }] = useAddPaymentSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPaymentSourceMutation,
    AddPaymentSourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPaymentSourceMutation,
    AddPaymentSourceMutationVariables
  >(AddPaymentSourceDocument, options);
}
export type AddPaymentSourceMutationHookResult = ReturnType<
  typeof useAddPaymentSourceMutation
>;
export type AddPaymentSourceMutationResult =
  Apollo.MutationResult<AddPaymentSourceMutation>;
export type AddPaymentSourceMutationOptions = Apollo.BaseMutationOptions<
  AddPaymentSourceMutation,
  AddPaymentSourceMutationVariables
>;
export const RemovePaymentSourceDocument = gql`
  mutation RemovePaymentSource($input: PaymentSourceInput!) {
    removePaymentSource(input: $input) {
      success
    }
  }
`;
export type RemovePaymentSourceMutationFn = Apollo.MutationFunction<
  RemovePaymentSourceMutation,
  RemovePaymentSourceMutationVariables
>;

/**
 * __useRemovePaymentSourceMutation__
 *
 * To run a mutation, you first call `useRemovePaymentSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePaymentSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePaymentSourceMutation, { data, loading, error }] = useRemovePaymentSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePaymentSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePaymentSourceMutation,
    RemovePaymentSourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemovePaymentSourceMutation,
    RemovePaymentSourceMutationVariables
  >(RemovePaymentSourceDocument, options);
}
export type RemovePaymentSourceMutationHookResult = ReturnType<
  typeof useRemovePaymentSourceMutation
>;
export type RemovePaymentSourceMutationResult =
  Apollo.MutationResult<RemovePaymentSourceMutation>;
export type RemovePaymentSourceMutationOptions = Apollo.BaseMutationOptions<
  RemovePaymentSourceMutation,
  RemovePaymentSourceMutationVariables
>;
export const ScheduleBalanceDocument = gql`
  mutation ScheduleBalance($input: ScheduleBalanceInput!) {
    scheduleBalance(input: $input) {
      success
    }
  }
`;
export type ScheduleBalanceMutationFn = Apollo.MutationFunction<
  ScheduleBalanceMutation,
  ScheduleBalanceMutationVariables
>;

/**
 * __useScheduleBalanceMutation__
 *
 * To run a mutation, you first call `useScheduleBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleBalanceMutation, { data, loading, error }] = useScheduleBalanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleBalanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScheduleBalanceMutation,
    ScheduleBalanceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ScheduleBalanceMutation,
    ScheduleBalanceMutationVariables
  >(ScheduleBalanceDocument, options);
}
export type ScheduleBalanceMutationHookResult = ReturnType<
  typeof useScheduleBalanceMutation
>;
export type ScheduleBalanceMutationResult =
  Apollo.MutationResult<ScheduleBalanceMutation>;
export type ScheduleBalanceMutationOptions = Apollo.BaseMutationOptions<
  ScheduleBalanceMutation,
  ScheduleBalanceMutationVariables
>;
export const UpdateBillingProfileDocument = gql`
  mutation UpdateBillingProfile($backupPaymentMethod: Boolean!) {
    updateBillingProfile(backupPaymentMethod: $backupPaymentMethod) {
      success
    }
  }
`;
export type UpdateBillingProfileMutationFn = Apollo.MutationFunction<
  UpdateBillingProfileMutation,
  UpdateBillingProfileMutationVariables
>;

/**
 * __useUpdateBillingProfileMutation__
 *
 * To run a mutation, you first call `useUpdateBillingProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingProfileMutation, { data, loading, error }] = useUpdateBillingProfileMutation({
 *   variables: {
 *      backupPaymentMethod: // value for 'backupPaymentMethod'
 *   },
 * });
 */
export function useUpdateBillingProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBillingProfileMutation,
    UpdateBillingProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBillingProfileMutation,
    UpdateBillingProfileMutationVariables
  >(UpdateBillingProfileDocument, options);
}
export type UpdateBillingProfileMutationHookResult = ReturnType<
  typeof useUpdateBillingProfileMutation
>;
export type UpdateBillingProfileMutationResult =
  Apollo.MutationResult<UpdateBillingProfileMutation>;
export type UpdateBillingProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateBillingProfileMutation,
  UpdateBillingProfileMutationVariables
>;
export const PayBillDocument = gql`
  mutation PayBill($input: PayBillInput!) {
    payBill(input: $input) {
      success
    }
  }
`;
export type PayBillMutationFn = Apollo.MutationFunction<
  PayBillMutation,
  PayBillMutationVariables
>;

/**
 * __usePayBillMutation__
 *
 * To run a mutation, you first call `usePayBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payBillMutation, { data, loading, error }] = usePayBillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayBillMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayBillMutation,
    PayBillMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayBillMutation, PayBillMutationVariables>(
    PayBillDocument,
    options,
  );
}
export type PayBillMutationHookResult = ReturnType<typeof usePayBillMutation>;
export type PayBillMutationResult = Apollo.MutationResult<PayBillMutation>;
export type PayBillMutationOptions = Apollo.BaseMutationOptions<
  PayBillMutation,
  PayBillMutationVariables
>;
export const FindClosestCalendarEventForRelationDocument = gql`
  query FindClosestCalendarEventForRelation($relationId: String!) {
    findClosestCalendarEventForRelation(relationId: $relationId) {
      id
      title
      startAt
      description
    }
  }
`;

/**
 * __useFindClosestCalendarEventForRelationQuery__
 *
 * To run a query within a React component, call `useFindClosestCalendarEventForRelationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindClosestCalendarEventForRelationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindClosestCalendarEventForRelationQuery({
 *   variables: {
 *      relationId: // value for 'relationId'
 *   },
 * });
 */
export function useFindClosestCalendarEventForRelationQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindClosestCalendarEventForRelationQuery,
    FindClosestCalendarEventForRelationQueryVariables
  > &
    (
      | {
          variables: FindClosestCalendarEventForRelationQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindClosestCalendarEventForRelationQuery,
    FindClosestCalendarEventForRelationQueryVariables
  >(FindClosestCalendarEventForRelationDocument, options);
}
export function useFindClosestCalendarEventForRelationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindClosestCalendarEventForRelationQuery,
    FindClosestCalendarEventForRelationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindClosestCalendarEventForRelationQuery,
    FindClosestCalendarEventForRelationQueryVariables
  >(FindClosestCalendarEventForRelationDocument, options);
}
export function useFindClosestCalendarEventForRelationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindClosestCalendarEventForRelationQuery,
        FindClosestCalendarEventForRelationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindClosestCalendarEventForRelationQuery,
    FindClosestCalendarEventForRelationQueryVariables
  >(FindClosestCalendarEventForRelationDocument, options);
}
export type FindClosestCalendarEventForRelationQueryHookResult = ReturnType<
  typeof useFindClosestCalendarEventForRelationQuery
>;
export type FindClosestCalendarEventForRelationLazyQueryHookResult = ReturnType<
  typeof useFindClosestCalendarEventForRelationLazyQuery
>;
export type FindClosestCalendarEventForRelationSuspenseQueryHookResult =
  ReturnType<typeof useFindClosestCalendarEventForRelationSuspenseQuery>;
export type FindClosestCalendarEventForRelationQueryResult = Apollo.QueryResult<
  FindClosestCalendarEventForRelationQuery,
  FindClosestCalendarEventForRelationQueryVariables
>;
export const FindCalendarEventsDocument = gql`
  query FindCalendarEvents(
    $filter: CalendarEventsFilter
    $pagination: Pagination
  ) {
    findCalendarEvents(filter: $filter, pagination: $pagination) {
      items {
        ...CalendarEvent
      }
      hasNext
      nextId
      nextCreatedAt
      total
    }
  }
  ${CalendarEventFragmentDoc}
`;

/**
 * __useFindCalendarEventsQuery__
 *
 * To run a query within a React component, call `useFindCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCalendarEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindCalendarEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindCalendarEventsQuery,
    FindCalendarEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindCalendarEventsQuery,
    FindCalendarEventsQueryVariables
  >(FindCalendarEventsDocument, options);
}
export function useFindCalendarEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCalendarEventsQuery,
    FindCalendarEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindCalendarEventsQuery,
    FindCalendarEventsQueryVariables
  >(FindCalendarEventsDocument, options);
}
export function useFindCalendarEventsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindCalendarEventsQuery,
        FindCalendarEventsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindCalendarEventsQuery,
    FindCalendarEventsQueryVariables
  >(FindCalendarEventsDocument, options);
}
export type FindCalendarEventsQueryHookResult = ReturnType<
  typeof useFindCalendarEventsQuery
>;
export type FindCalendarEventsLazyQueryHookResult = ReturnType<
  typeof useFindCalendarEventsLazyQuery
>;
export type FindCalendarEventsSuspenseQueryHookResult = ReturnType<
  typeof useFindCalendarEventsSuspenseQuery
>;
export type FindCalendarEventsQueryResult = Apollo.QueryResult<
  FindCalendarEventsQuery,
  FindCalendarEventsQueryVariables
>;
export const CreateCalendarEventDocument = gql`
  mutation CreateCalendarEvent($input: CreateCalendarEventInput!) {
    createCalendarEvent(input: $input) {
      ...CalendarEvent
    }
  }
  ${CalendarEventFragmentDoc}
`;
export type CreateCalendarEventMutationFn = Apollo.MutationFunction<
  CreateCalendarEventMutation,
  CreateCalendarEventMutationVariables
>;

/**
 * __useCreateCalendarEventMutation__
 *
 * To run a mutation, you first call `useCreateCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarEventMutation, { data, loading, error }] = useCreateCalendarEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCalendarEventMutation,
    CreateCalendarEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCalendarEventMutation,
    CreateCalendarEventMutationVariables
  >(CreateCalendarEventDocument, options);
}
export type CreateCalendarEventMutationHookResult = ReturnType<
  typeof useCreateCalendarEventMutation
>;
export type CreateCalendarEventMutationResult =
  Apollo.MutationResult<CreateCalendarEventMutation>;
export type CreateCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  CreateCalendarEventMutation,
  CreateCalendarEventMutationVariables
>;
export const UpdateCalendarEventDocument = gql`
  mutation UpdateCalendarEvent(
    $input: UpdateCalendarEventInput!
    $id: String!
  ) {
    updateCalendarEvent(input: $input, id: $id) {
      ...CalendarEvent
    }
  }
  ${CalendarEventFragmentDoc}
`;
export type UpdateCalendarEventMutationFn = Apollo.MutationFunction<
  UpdateCalendarEventMutation,
  UpdateCalendarEventMutationVariables
>;

/**
 * __useUpdateCalendarEventMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarEventMutation, { data, loading, error }] = useUpdateCalendarEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCalendarEventMutation,
    UpdateCalendarEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCalendarEventMutation,
    UpdateCalendarEventMutationVariables
  >(UpdateCalendarEventDocument, options);
}
export type UpdateCalendarEventMutationHookResult = ReturnType<
  typeof useUpdateCalendarEventMutation
>;
export type UpdateCalendarEventMutationResult =
  Apollo.MutationResult<UpdateCalendarEventMutation>;
export type UpdateCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateCalendarEventMutation,
  UpdateCalendarEventMutationVariables
>;
export const DeleteCalendarEventDocument = gql`
  mutation DeleteCalendarEvent($id: String!) {
    removeCalendarEvent(id: $id) {
      success
    }
  }
`;
export type DeleteCalendarEventMutationFn = Apollo.MutationFunction<
  DeleteCalendarEventMutation,
  DeleteCalendarEventMutationVariables
>;

/**
 * __useDeleteCalendarEventMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarEventMutation, { data, loading, error }] = useDeleteCalendarEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCalendarEventMutation,
    DeleteCalendarEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCalendarEventMutation,
    DeleteCalendarEventMutationVariables
  >(DeleteCalendarEventDocument, options);
}
export type DeleteCalendarEventMutationHookResult = ReturnType<
  typeof useDeleteCalendarEventMutation
>;
export type DeleteCalendarEventMutationResult =
  Apollo.MutationResult<DeleteCalendarEventMutation>;
export type DeleteCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteCalendarEventMutation,
  DeleteCalendarEventMutationVariables
>;
export const GetCurrentCallDocument = gql`
  query GetCurrentCall($contactId: String!) {
    getCurrentCall(contactId: $contactId) {
      id
    }
  }
`;

/**
 * __useGetCurrentCallQuery__
 *
 * To run a query within a React component, call `useGetCurrentCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCallQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useGetCurrentCallQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentCallQuery,
    GetCurrentCallQueryVariables
  > &
    (
      | { variables: GetCurrentCallQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentCallQuery, GetCurrentCallQueryVariables>(
    GetCurrentCallDocument,
    options,
  );
}
export function useGetCurrentCallLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCallQuery,
    GetCurrentCallQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentCallQuery, GetCurrentCallQueryVariables>(
    GetCurrentCallDocument,
    options,
  );
}
export function useGetCurrentCallSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCurrentCallQuery,
        GetCurrentCallQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCurrentCallQuery,
    GetCurrentCallQueryVariables
  >(GetCurrentCallDocument, options);
}
export type GetCurrentCallQueryHookResult = ReturnType<
  typeof useGetCurrentCallQuery
>;
export type GetCurrentCallLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCallLazyQuery
>;
export type GetCurrentCallSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentCallSuspenseQuery
>;
export type GetCurrentCallQueryResult = Apollo.QueryResult<
  GetCurrentCallQuery,
  GetCurrentCallQueryVariables
>;
export const ManageCallRecordingDocument = gql`
  mutation ManageCallRecording($input: ManageCallRecordingInput!) {
    manageCallRecording(input: $input) {
      success
    }
  }
`;
export type ManageCallRecordingMutationFn = Apollo.MutationFunction<
  ManageCallRecordingMutation,
  ManageCallRecordingMutationVariables
>;

/**
 * __useManageCallRecordingMutation__
 *
 * To run a mutation, you first call `useManageCallRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageCallRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageCallRecordingMutation, { data, loading, error }] = useManageCallRecordingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageCallRecordingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManageCallRecordingMutation,
    ManageCallRecordingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManageCallRecordingMutation,
    ManageCallRecordingMutationVariables
  >(ManageCallRecordingDocument, options);
}
export type ManageCallRecordingMutationHookResult = ReturnType<
  typeof useManageCallRecordingMutation
>;
export type ManageCallRecordingMutationResult =
  Apollo.MutationResult<ManageCallRecordingMutation>;
export type ManageCallRecordingMutationOptions = Apollo.BaseMutationOptions<
  ManageCallRecordingMutation,
  ManageCallRecordingMutationVariables
>;
export const FindCallScriptsDocument = gql`
  query FindCallScripts($filter: CallScriptsFilter, $pagination: Pagination) {
    findCallScripts(filter: $filter, pagination: $pagination) {
      items {
        ...CallScript
      }
      hasNext
      nextId
      nextCreatedAt
      total
    }
  }
  ${CallScriptFragmentDoc}
`;

/**
 * __useFindCallScriptsQuery__
 *
 * To run a query within a React component, call `useFindCallScriptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCallScriptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCallScriptsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindCallScriptsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindCallScriptsQuery,
    FindCallScriptsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindCallScriptsQuery, FindCallScriptsQueryVariables>(
    FindCallScriptsDocument,
    options,
  );
}
export function useFindCallScriptsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCallScriptsQuery,
    FindCallScriptsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindCallScriptsQuery,
    FindCallScriptsQueryVariables
  >(FindCallScriptsDocument, options);
}
export function useFindCallScriptsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindCallScriptsQuery,
        FindCallScriptsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindCallScriptsQuery,
    FindCallScriptsQueryVariables
  >(FindCallScriptsDocument, options);
}
export type FindCallScriptsQueryHookResult = ReturnType<
  typeof useFindCallScriptsQuery
>;
export type FindCallScriptsLazyQueryHookResult = ReturnType<
  typeof useFindCallScriptsLazyQuery
>;
export type FindCallScriptsSuspenseQueryHookResult = ReturnType<
  typeof useFindCallScriptsSuspenseQuery
>;
export type FindCallScriptsQueryResult = Apollo.QueryResult<
  FindCallScriptsQuery,
  FindCallScriptsQueryVariables
>;
export const GetCallScriptDocument = gql`
  query GetCallScript($id: String!) {
    getCallScript(id: $id) {
      ...CallScript
    }
  }
  ${CallScriptFragmentDoc}
`;

/**
 * __useGetCallScriptQuery__
 *
 * To run a query within a React component, call `useGetCallScriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCallScriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCallScriptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCallScriptQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCallScriptQuery,
    GetCallScriptQueryVariables
  > &
    (
      | { variables: GetCallScriptQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCallScriptQuery, GetCallScriptQueryVariables>(
    GetCallScriptDocument,
    options,
  );
}
export function useGetCallScriptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCallScriptQuery,
    GetCallScriptQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCallScriptQuery, GetCallScriptQueryVariables>(
    GetCallScriptDocument,
    options,
  );
}
export function useGetCallScriptSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCallScriptQuery,
        GetCallScriptQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCallScriptQuery,
    GetCallScriptQueryVariables
  >(GetCallScriptDocument, options);
}
export type GetCallScriptQueryHookResult = ReturnType<
  typeof useGetCallScriptQuery
>;
export type GetCallScriptLazyQueryHookResult = ReturnType<
  typeof useGetCallScriptLazyQuery
>;
export type GetCallScriptSuspenseQueryHookResult = ReturnType<
  typeof useGetCallScriptSuspenseQuery
>;
export type GetCallScriptQueryResult = Apollo.QueryResult<
  GetCallScriptQuery,
  GetCallScriptQueryVariables
>;
export const CreateCallScriptDocument = gql`
  mutation CreateCallScript($input: CreateCallScriptInput!) {
    createCallScript(input: $input) {
      ...CallScript
    }
  }
  ${CallScriptFragmentDoc}
`;
export type CreateCallScriptMutationFn = Apollo.MutationFunction<
  CreateCallScriptMutation,
  CreateCallScriptMutationVariables
>;

/**
 * __useCreateCallScriptMutation__
 *
 * To run a mutation, you first call `useCreateCallScriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCallScriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCallScriptMutation, { data, loading, error }] = useCreateCallScriptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCallScriptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCallScriptMutation,
    CreateCallScriptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCallScriptMutation,
    CreateCallScriptMutationVariables
  >(CreateCallScriptDocument, options);
}
export type CreateCallScriptMutationHookResult = ReturnType<
  typeof useCreateCallScriptMutation
>;
export type CreateCallScriptMutationResult =
  Apollo.MutationResult<CreateCallScriptMutation>;
export type CreateCallScriptMutationOptions = Apollo.BaseMutationOptions<
  CreateCallScriptMutation,
  CreateCallScriptMutationVariables
>;
export const UpdateCallScriptDocument = gql`
  mutation UpdateCallScript(
    $input: UpdateCallScriptInput!
    $updateCallScriptId: String!
  ) {
    updateCallScript(input: $input, id: $updateCallScriptId) {
      ...CallScript
    }
  }
  ${CallScriptFragmentDoc}
`;
export type UpdateCallScriptMutationFn = Apollo.MutationFunction<
  UpdateCallScriptMutation,
  UpdateCallScriptMutationVariables
>;

/**
 * __useUpdateCallScriptMutation__
 *
 * To run a mutation, you first call `useUpdateCallScriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallScriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallScriptMutation, { data, loading, error }] = useUpdateCallScriptMutation({
 *   variables: {
 *      input: // value for 'input'
 *      updateCallScriptId: // value for 'updateCallScriptId'
 *   },
 * });
 */
export function useUpdateCallScriptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCallScriptMutation,
    UpdateCallScriptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCallScriptMutation,
    UpdateCallScriptMutationVariables
  >(UpdateCallScriptDocument, options);
}
export type UpdateCallScriptMutationHookResult = ReturnType<
  typeof useUpdateCallScriptMutation
>;
export type UpdateCallScriptMutationResult =
  Apollo.MutationResult<UpdateCallScriptMutation>;
export type UpdateCallScriptMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallScriptMutation,
  UpdateCallScriptMutationVariables
>;
export const RemoveCallScriptDocument = gql`
  mutation RemoveCallScript($removeCallScriptId: String!) {
    removeCallScript(id: $removeCallScriptId) {
      success
    }
  }
`;
export type RemoveCallScriptMutationFn = Apollo.MutationFunction<
  RemoveCallScriptMutation,
  RemoveCallScriptMutationVariables
>;

/**
 * __useRemoveCallScriptMutation__
 *
 * To run a mutation, you first call `useRemoveCallScriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCallScriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCallScriptMutation, { data, loading, error }] = useRemoveCallScriptMutation({
 *   variables: {
 *      removeCallScriptId: // value for 'removeCallScriptId'
 *   },
 * });
 */
export function useRemoveCallScriptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCallScriptMutation,
    RemoveCallScriptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCallScriptMutation,
    RemoveCallScriptMutationVariables
  >(RemoveCallScriptDocument, options);
}
export type RemoveCallScriptMutationHookResult = ReturnType<
  typeof useRemoveCallScriptMutation
>;
export type RemoveCallScriptMutationResult =
  Apollo.MutationResult<RemoveCallScriptMutation>;
export type RemoveCallScriptMutationOptions = Apollo.BaseMutationOptions<
  RemoveCallScriptMutation,
  RemoveCallScriptMutationVariables
>;
export const RankCallScriptDocument = gql`
  mutation RankCallScript(
    $input: RankCallScriptInput!
    $rankCallScriptId: String!
  ) {
    rankCallScript(input: $input, id: $rankCallScriptId) {
      success
    }
  }
`;
export type RankCallScriptMutationFn = Apollo.MutationFunction<
  RankCallScriptMutation,
  RankCallScriptMutationVariables
>;

/**
 * __useRankCallScriptMutation__
 *
 * To run a mutation, you first call `useRankCallScriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRankCallScriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rankCallScriptMutation, { data, loading, error }] = useRankCallScriptMutation({
 *   variables: {
 *      input: // value for 'input'
 *      rankCallScriptId: // value for 'rankCallScriptId'
 *   },
 * });
 */
export function useRankCallScriptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RankCallScriptMutation,
    RankCallScriptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RankCallScriptMutation,
    RankCallScriptMutationVariables
  >(RankCallScriptDocument, options);
}
export type RankCallScriptMutationHookResult = ReturnType<
  typeof useRankCallScriptMutation
>;
export type RankCallScriptMutationResult =
  Apollo.MutationResult<RankCallScriptMutation>;
export type RankCallScriptMutationOptions = Apollo.BaseMutationOptions<
  RankCallScriptMutation,
  RankCallScriptMutationVariables
>;
export const FindCampaignsDocument = gql`
  query FindCampaigns($filter: CampaignFilter, $pagination: Pagination) {
    findCampaigns(pagination: $pagination, filter: $filter) {
      items {
        ...CampaignFragment
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${CampaignFragmentFragmentDoc}
`;

/**
 * __useFindCampaignsQuery__
 *
 * To run a query within a React component, call `useFindCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCampaignsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindCampaignsQuery,
    FindCampaignsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindCampaignsQuery, FindCampaignsQueryVariables>(
    FindCampaignsDocument,
    options,
  );
}
export function useFindCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCampaignsQuery,
    FindCampaignsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindCampaignsQuery, FindCampaignsQueryVariables>(
    FindCampaignsDocument,
    options,
  );
}
export function useFindCampaignsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindCampaignsQuery,
        FindCampaignsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindCampaignsQuery,
    FindCampaignsQueryVariables
  >(FindCampaignsDocument, options);
}
export type FindCampaignsQueryHookResult = ReturnType<
  typeof useFindCampaignsQuery
>;
export type FindCampaignsLazyQueryHookResult = ReturnType<
  typeof useFindCampaignsLazyQuery
>;
export type FindCampaignsSuspenseQueryHookResult = ReturnType<
  typeof useFindCampaignsSuspenseQuery
>;
export type FindCampaignsQueryResult = Apollo.QueryResult<
  FindCampaignsQuery,
  FindCampaignsQueryVariables
>;
export const FindListCampaignsDocument = gql`
  query FindListCampaigns($filter: CampaignFilter, $pagination: Pagination) {
    findCampaigns(pagination: $pagination, filter: $filter) {
      items {
        ...CampaignFragment
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${CampaignFragmentFragmentDoc}
`;

/**
 * __useFindListCampaignsQuery__
 *
 * To run a query within a React component, call `useFindListCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindListCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindListCampaignsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindListCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindListCampaignsQuery,
    FindListCampaignsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindListCampaignsQuery,
    FindListCampaignsQueryVariables
  >(FindListCampaignsDocument, options);
}
export function useFindListCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindListCampaignsQuery,
    FindListCampaignsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindListCampaignsQuery,
    FindListCampaignsQueryVariables
  >(FindListCampaignsDocument, options);
}
export function useFindListCampaignsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindListCampaignsQuery,
        FindListCampaignsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindListCampaignsQuery,
    FindListCampaignsQueryVariables
  >(FindListCampaignsDocument, options);
}
export type FindListCampaignsQueryHookResult = ReturnType<
  typeof useFindListCampaignsQuery
>;
export type FindListCampaignsLazyQueryHookResult = ReturnType<
  typeof useFindListCampaignsLazyQuery
>;
export type FindListCampaignsSuspenseQueryHookResult = ReturnType<
  typeof useFindListCampaignsSuspenseQuery
>;
export type FindListCampaignsQueryResult = Apollo.QueryResult<
  FindListCampaignsQuery,
  FindListCampaignsQueryVariables
>;
export const GetCampaignsTotalDocument = gql`
  query GetCampaignsTotal($filter: CampaignFilter, $pagination: Pagination) {
    findCampaigns(pagination: $pagination, filter: $filter) {
      total
    }
  }
`;

/**
 * __useGetCampaignsTotalQuery__
 *
 * To run a query within a React component, call `useGetCampaignsTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsTotalQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCampaignsTotalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCampaignsTotalQuery,
    GetCampaignsTotalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCampaignsTotalQuery,
    GetCampaignsTotalQueryVariables
  >(GetCampaignsTotalDocument, options);
}
export function useGetCampaignsTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCampaignsTotalQuery,
    GetCampaignsTotalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCampaignsTotalQuery,
    GetCampaignsTotalQueryVariables
  >(GetCampaignsTotalDocument, options);
}
export function useGetCampaignsTotalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCampaignsTotalQuery,
        GetCampaignsTotalQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCampaignsTotalQuery,
    GetCampaignsTotalQueryVariables
  >(GetCampaignsTotalDocument, options);
}
export type GetCampaignsTotalQueryHookResult = ReturnType<
  typeof useGetCampaignsTotalQuery
>;
export type GetCampaignsTotalLazyQueryHookResult = ReturnType<
  typeof useGetCampaignsTotalLazyQuery
>;
export type GetCampaignsTotalSuspenseQueryHookResult = ReturnType<
  typeof useGetCampaignsTotalSuspenseQuery
>;
export type GetCampaignsTotalQueryResult = Apollo.QueryResult<
  GetCampaignsTotalQuery,
  GetCampaignsTotalQueryVariables
>;
export const GetCampaignsCountersDocument = gql`
  query GetCampaignsCounters {
    getCampaignsCounters {
      keyword {
        active
        draft
        deleted
        total
      }
      standard {
        active
        draft
        deleted
        total
      }
    }
  }
`;

/**
 * __useGetCampaignsCountersQuery__
 *
 * To run a query within a React component, call `useGetCampaignsCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsCountersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCampaignsCountersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCampaignsCountersQuery,
    GetCampaignsCountersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCampaignsCountersQuery,
    GetCampaignsCountersQueryVariables
  >(GetCampaignsCountersDocument, options);
}
export function useGetCampaignsCountersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCampaignsCountersQuery,
    GetCampaignsCountersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCampaignsCountersQuery,
    GetCampaignsCountersQueryVariables
  >(GetCampaignsCountersDocument, options);
}
export function useGetCampaignsCountersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCampaignsCountersQuery,
        GetCampaignsCountersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCampaignsCountersQuery,
    GetCampaignsCountersQueryVariables
  >(GetCampaignsCountersDocument, options);
}
export type GetCampaignsCountersQueryHookResult = ReturnType<
  typeof useGetCampaignsCountersQuery
>;
export type GetCampaignsCountersLazyQueryHookResult = ReturnType<
  typeof useGetCampaignsCountersLazyQuery
>;
export type GetCampaignsCountersSuspenseQueryHookResult = ReturnType<
  typeof useGetCampaignsCountersSuspenseQuery
>;
export type GetCampaignsCountersQueryResult = Apollo.QueryResult<
  GetCampaignsCountersQuery,
  GetCampaignsCountersQueryVariables
>;
export const GetCampaignDocument = gql`
  query GetCampaign($id: String!) {
    getCampaign(id: $id) {
      ...CampaignFragment
    }
  }
  ${CampaignFragmentFragmentDoc}
`;

/**
 * __useGetCampaignQuery__
 *
 * To run a query within a React component, call `useGetCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCampaignQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCampaignQuery,
    GetCampaignQueryVariables
  > &
    (
      | { variables: GetCampaignQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCampaignQuery, GetCampaignQueryVariables>(
    GetCampaignDocument,
    options,
  );
}
export function useGetCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCampaignQuery,
    GetCampaignQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCampaignQuery, GetCampaignQueryVariables>(
    GetCampaignDocument,
    options,
  );
}
export function useGetCampaignSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCampaignQuery,
        GetCampaignQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCampaignQuery, GetCampaignQueryVariables>(
    GetCampaignDocument,
    options,
  );
}
export type GetCampaignQueryHookResult = ReturnType<typeof useGetCampaignQuery>;
export type GetCampaignLazyQueryHookResult = ReturnType<
  typeof useGetCampaignLazyQuery
>;
export type GetCampaignSuspenseQueryHookResult = ReturnType<
  typeof useGetCampaignSuspenseQuery
>;
export type GetCampaignQueryResult = Apollo.QueryResult<
  GetCampaignQuery,
  GetCampaignQueryVariables
>;
export const ExportCampaignToFileDocument = gql`
  query ExportCampaignToFile($input: ExportCampaignToFileInput!) {
    exportCampaignToFile(input: $input) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useExportCampaignToFileQuery__
 *
 * To run a query within a React component, call `useExportCampaignToFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCampaignToFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCampaignToFileQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportCampaignToFileQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportCampaignToFileQuery,
    ExportCampaignToFileQueryVariables
  > &
    (
      | { variables: ExportCampaignToFileQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExportCampaignToFileQuery,
    ExportCampaignToFileQueryVariables
  >(ExportCampaignToFileDocument, options);
}
export function useExportCampaignToFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportCampaignToFileQuery,
    ExportCampaignToFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExportCampaignToFileQuery,
    ExportCampaignToFileQueryVariables
  >(ExportCampaignToFileDocument, options);
}
export function useExportCampaignToFileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExportCampaignToFileQuery,
        ExportCampaignToFileQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExportCampaignToFileQuery,
    ExportCampaignToFileQueryVariables
  >(ExportCampaignToFileDocument, options);
}
export type ExportCampaignToFileQueryHookResult = ReturnType<
  typeof useExportCampaignToFileQuery
>;
export type ExportCampaignToFileLazyQueryHookResult = ReturnType<
  typeof useExportCampaignToFileLazyQuery
>;
export type ExportCampaignToFileSuspenseQueryHookResult = ReturnType<
  typeof useExportCampaignToFileSuspenseQuery
>;
export type ExportCampaignToFileQueryResult = Apollo.QueryResult<
  ExportCampaignToFileQuery,
  ExportCampaignToFileQueryVariables
>;
export const GetCampaignScheduleDocument = gql`
  query GetCampaignSchedule(
    $id: String
    $options: CampaignScheduleOptionsInput
  ) {
    getCampaignSchedule(id: $id, options: $options) {
      startTime
      endTime
      nextTime
    }
  }
`;

/**
 * __useGetCampaignScheduleQuery__
 *
 * To run a query within a React component, call `useGetCampaignScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignScheduleQuery({
 *   variables: {
 *      id: // value for 'id'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetCampaignScheduleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCampaignScheduleQuery,
    GetCampaignScheduleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCampaignScheduleQuery,
    GetCampaignScheduleQueryVariables
  >(GetCampaignScheduleDocument, options);
}
export function useGetCampaignScheduleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCampaignScheduleQuery,
    GetCampaignScheduleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCampaignScheduleQuery,
    GetCampaignScheduleQueryVariables
  >(GetCampaignScheduleDocument, options);
}
export function useGetCampaignScheduleSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCampaignScheduleQuery,
        GetCampaignScheduleQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCampaignScheduleQuery,
    GetCampaignScheduleQueryVariables
  >(GetCampaignScheduleDocument, options);
}
export type GetCampaignScheduleQueryHookResult = ReturnType<
  typeof useGetCampaignScheduleQuery
>;
export type GetCampaignScheduleLazyQueryHookResult = ReturnType<
  typeof useGetCampaignScheduleLazyQuery
>;
export type GetCampaignScheduleSuspenseQueryHookResult = ReturnType<
  typeof useGetCampaignScheduleSuspenseQuery
>;
export type GetCampaignScheduleQueryResult = Apollo.QueryResult<
  GetCampaignScheduleQuery,
  GetCampaignScheduleQueryVariables
>;
export const FindCampaignMessagesDocument = gql`
  query FindCampaignMessages(
    $filter: ScheduledMessageFilter
    $pagination: Pagination
  ) {
    findCampaignMessages(filter: $filter, pagination: $pagination) {
      items {
        ...CampaignScheduledMessage
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${CampaignScheduledMessageFragmentDoc}
`;

/**
 * __useFindCampaignMessagesQuery__
 *
 * To run a query within a React component, call `useFindCampaignMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCampaignMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCampaignMessagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindCampaignMessagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindCampaignMessagesQuery,
    FindCampaignMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindCampaignMessagesQuery,
    FindCampaignMessagesQueryVariables
  >(FindCampaignMessagesDocument, options);
}
export function useFindCampaignMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCampaignMessagesQuery,
    FindCampaignMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindCampaignMessagesQuery,
    FindCampaignMessagesQueryVariables
  >(FindCampaignMessagesDocument, options);
}
export function useFindCampaignMessagesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindCampaignMessagesQuery,
        FindCampaignMessagesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindCampaignMessagesQuery,
    FindCampaignMessagesQueryVariables
  >(FindCampaignMessagesDocument, options);
}
export type FindCampaignMessagesQueryHookResult = ReturnType<
  typeof useFindCampaignMessagesQuery
>;
export type FindCampaignMessagesLazyQueryHookResult = ReturnType<
  typeof useFindCampaignMessagesLazyQuery
>;
export type FindCampaignMessagesSuspenseQueryHookResult = ReturnType<
  typeof useFindCampaignMessagesSuspenseQuery
>;
export type FindCampaignMessagesQueryResult = Apollo.QueryResult<
  FindCampaignMessagesQuery,
  FindCampaignMessagesQueryVariables
>;
export const CreateCampaignDocument = gql`
  mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      ...CampaignFragment
    }
  }
  ${CampaignFragmentFragmentDoc}
`;
export type CreateCampaignMutationFn = Apollo.MutationFunction<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;

/**
 * __useCreateCampaignMutation__
 *
 * To run a mutation, you first call `useCreateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignMutation, { data, loading, error }] = useCreateCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >(CreateCampaignDocument, options);
}
export type CreateCampaignMutationHookResult = ReturnType<
  typeof useCreateCampaignMutation
>;
export type CreateCampaignMutationResult =
  Apollo.MutationResult<CreateCampaignMutation>;
export type CreateCampaignMutationOptions = Apollo.BaseMutationOptions<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;
export const UpdateCampaignDocument = gql`
  mutation UpdateCampaign($input: UpdateCampaignInput!, $id: String!) {
    updateCampaign(input: $input, id: $id) {
      ...CampaignFragment
    }
  }
  ${CampaignFragmentFragmentDoc}
`;
export type UpdateCampaignMutationFn = Apollo.MutationFunction<
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables
>;

/**
 * __useUpdateCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignMutation, { data, loading, error }] = useUpdateCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCampaignMutation,
    UpdateCampaignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCampaignMutation,
    UpdateCampaignMutationVariables
  >(UpdateCampaignDocument, options);
}
export type UpdateCampaignMutationHookResult = ReturnType<
  typeof useUpdateCampaignMutation
>;
export type UpdateCampaignMutationResult =
  Apollo.MutationResult<UpdateCampaignMutation>;
export type UpdateCampaignMutationOptions = Apollo.BaseMutationOptions<
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables
>;
export const RemoveCampaignDocument = gql`
  mutation RemoveCampaign($input: RemoveCampaignInput!) {
    removeCampaign(input: $input) {
      success
    }
  }
`;
export type RemoveCampaignMutationFn = Apollo.MutationFunction<
  RemoveCampaignMutation,
  RemoveCampaignMutationVariables
>;

/**
 * __useRemoveCampaignMutation__
 *
 * To run a mutation, you first call `useRemoveCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCampaignMutation, { data, loading, error }] = useRemoveCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCampaignMutation,
    RemoveCampaignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCampaignMutation,
    RemoveCampaignMutationVariables
  >(RemoveCampaignDocument, options);
}
export type RemoveCampaignMutationHookResult = ReturnType<
  typeof useRemoveCampaignMutation
>;
export type RemoveCampaignMutationResult =
  Apollo.MutationResult<RemoveCampaignMutation>;
export type RemoveCampaignMutationOptions = Apollo.BaseMutationOptions<
  RemoveCampaignMutation,
  RemoveCampaignMutationVariables
>;
export const ExportCampaignToGroupDocument = gql`
  query ExportCampaignToGroup(
    $input: ExportCampaignToGroupInput!
    $id: String!
  ) {
    exportCampaignToGroup(input: $input, id: $id) {
      id
    }
  }
`;

/**
 * __useExportCampaignToGroupQuery__
 *
 * To run a query within a React component, call `useExportCampaignToGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCampaignToGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCampaignToGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportCampaignToGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportCampaignToGroupQuery,
    ExportCampaignToGroupQueryVariables
  > &
    (
      | { variables: ExportCampaignToGroupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExportCampaignToGroupQuery,
    ExportCampaignToGroupQueryVariables
  >(ExportCampaignToGroupDocument, options);
}
export function useExportCampaignToGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportCampaignToGroupQuery,
    ExportCampaignToGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExportCampaignToGroupQuery,
    ExportCampaignToGroupQueryVariables
  >(ExportCampaignToGroupDocument, options);
}
export function useExportCampaignToGroupSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExportCampaignToGroupQuery,
        ExportCampaignToGroupQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExportCampaignToGroupQuery,
    ExportCampaignToGroupQueryVariables
  >(ExportCampaignToGroupDocument, options);
}
export type ExportCampaignToGroupQueryHookResult = ReturnType<
  typeof useExportCampaignToGroupQuery
>;
export type ExportCampaignToGroupLazyQueryHookResult = ReturnType<
  typeof useExportCampaignToGroupLazyQuery
>;
export type ExportCampaignToGroupSuspenseQueryHookResult = ReturnType<
  typeof useExportCampaignToGroupSuspenseQuery
>;
export type ExportCampaignToGroupQueryResult = Apollo.QueryResult<
  ExportCampaignToGroupQuery,
  ExportCampaignToGroupQueryVariables
>;
export const StartCampaignDocument = gql`
  mutation StartCampaign($id: String!) {
    startCampaign(id: $id) {
      success
    }
  }
`;
export type StartCampaignMutationFn = Apollo.MutationFunction<
  StartCampaignMutation,
  StartCampaignMutationVariables
>;

/**
 * __useStartCampaignMutation__
 *
 * To run a mutation, you first call `useStartCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCampaignMutation, { data, loading, error }] = useStartCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartCampaignMutation,
    StartCampaignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartCampaignMutation,
    StartCampaignMutationVariables
  >(StartCampaignDocument, options);
}
export type StartCampaignMutationHookResult = ReturnType<
  typeof useStartCampaignMutation
>;
export type StartCampaignMutationResult =
  Apollo.MutationResult<StartCampaignMutation>;
export type StartCampaignMutationOptions = Apollo.BaseMutationOptions<
  StartCampaignMutation,
  StartCampaignMutationVariables
>;
export const PauseCampaignDocument = gql`
  mutation PauseCampaign($id: String!) {
    pauseCampaign(id: $id) {
      success
    }
  }
`;
export type PauseCampaignMutationFn = Apollo.MutationFunction<
  PauseCampaignMutation,
  PauseCampaignMutationVariables
>;

/**
 * __usePauseCampaignMutation__
 *
 * To run a mutation, you first call `usePauseCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseCampaignMutation, { data, loading, error }] = usePauseCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePauseCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PauseCampaignMutation,
    PauseCampaignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PauseCampaignMutation,
    PauseCampaignMutationVariables
  >(PauseCampaignDocument, options);
}
export type PauseCampaignMutationHookResult = ReturnType<
  typeof usePauseCampaignMutation
>;
export type PauseCampaignMutationResult =
  Apollo.MutationResult<PauseCampaignMutation>;
export type PauseCampaignMutationOptions = Apollo.BaseMutationOptions<
  PauseCampaignMutation,
  PauseCampaignMutationVariables
>;
export const ResumeCampaignDocument = gql`
  mutation ResumeCampaign($id: String!) {
    resumeCampaign(id: $id) {
      success
    }
  }
`;
export type ResumeCampaignMutationFn = Apollo.MutationFunction<
  ResumeCampaignMutation,
  ResumeCampaignMutationVariables
>;

/**
 * __useResumeCampaignMutation__
 *
 * To run a mutation, you first call `useResumeCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeCampaignMutation, { data, loading, error }] = useResumeCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResumeCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResumeCampaignMutation,
    ResumeCampaignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResumeCampaignMutation,
    ResumeCampaignMutationVariables
  >(ResumeCampaignDocument, options);
}
export type ResumeCampaignMutationHookResult = ReturnType<
  typeof useResumeCampaignMutation
>;
export type ResumeCampaignMutationResult =
  Apollo.MutationResult<ResumeCampaignMutation>;
export type ResumeCampaignMutationOptions = Apollo.BaseMutationOptions<
  ResumeCampaignMutation,
  ResumeCampaignMutationVariables
>;
export const UpdateCampaignDynamicDataDocument = gql`
  mutation UpdateCampaignDynamicData(
    $input: CampaignDynamicDataInput!
    $id: String!
  ) {
    updateCampaignDynamicData(input: $input, id: $id) {
      success
    }
  }
`;
export type UpdateCampaignDynamicDataMutationFn = Apollo.MutationFunction<
  UpdateCampaignDynamicDataMutation,
  UpdateCampaignDynamicDataMutationVariables
>;

/**
 * __useUpdateCampaignDynamicDataMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignDynamicDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignDynamicDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignDynamicDataMutation, { data, loading, error }] = useUpdateCampaignDynamicDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCampaignDynamicDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCampaignDynamicDataMutation,
    UpdateCampaignDynamicDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCampaignDynamicDataMutation,
    UpdateCampaignDynamicDataMutationVariables
  >(UpdateCampaignDynamicDataDocument, options);
}
export type UpdateCampaignDynamicDataMutationHookResult = ReturnType<
  typeof useUpdateCampaignDynamicDataMutation
>;
export type UpdateCampaignDynamicDataMutationResult =
  Apollo.MutationResult<UpdateCampaignDynamicDataMutation>;
export type UpdateCampaignDynamicDataMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCampaignDynamicDataMutation,
    UpdateCampaignDynamicDataMutationVariables
  >;
export const UpdateScheduledMessageDocument = gql`
  mutation UpdateScheduledMessage(
    $input: UpdateScheduledMessageInput!
    $id: String!
  ) {
    updateScheduledMessage(input: $input, id: $id) {
      ...CampaignScheduledMessage
    }
  }
  ${CampaignScheduledMessageFragmentDoc}
`;
export type UpdateScheduledMessageMutationFn = Apollo.MutationFunction<
  UpdateScheduledMessageMutation,
  UpdateScheduledMessageMutationVariables
>;

/**
 * __useUpdateScheduledMessageMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledMessageMutation, { data, loading, error }] = useUpdateScheduledMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateScheduledMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScheduledMessageMutation,
    UpdateScheduledMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScheduledMessageMutation,
    UpdateScheduledMessageMutationVariables
  >(UpdateScheduledMessageDocument, options);
}
export type UpdateScheduledMessageMutationHookResult = ReturnType<
  typeof useUpdateScheduledMessageMutation
>;
export type UpdateScheduledMessageMutationResult =
  Apollo.MutationResult<UpdateScheduledMessageMutation>;
export type UpdateScheduledMessageMutationOptions = Apollo.BaseMutationOptions<
  UpdateScheduledMessageMutation,
  UpdateScheduledMessageMutationVariables
>;
export const RefreshCampaignMessagesDocument = gql`
  mutation RefreshCampaignMessages($id: String!) {
    refreshCampaignMessages(id: $id) {
      success
    }
  }
`;
export type RefreshCampaignMessagesMutationFn = Apollo.MutationFunction<
  RefreshCampaignMessagesMutation,
  RefreshCampaignMessagesMutationVariables
>;

/**
 * __useRefreshCampaignMessagesMutation__
 *
 * To run a mutation, you first call `useRefreshCampaignMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshCampaignMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshCampaignMessagesMutation, { data, loading, error }] = useRefreshCampaignMessagesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefreshCampaignMessagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshCampaignMessagesMutation,
    RefreshCampaignMessagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshCampaignMessagesMutation,
    RefreshCampaignMessagesMutationVariables
  >(RefreshCampaignMessagesDocument, options);
}
export type RefreshCampaignMessagesMutationHookResult = ReturnType<
  typeof useRefreshCampaignMessagesMutation
>;
export type RefreshCampaignMessagesMutationResult =
  Apollo.MutationResult<RefreshCampaignMessagesMutation>;
export type RefreshCampaignMessagesMutationOptions = Apollo.BaseMutationOptions<
  RefreshCampaignMessagesMutation,
  RefreshCampaignMessagesMutationVariables
>;
export const UpdateCampaignOwnPhonesDocument = gql`
  mutation UpdateCampaignOwnPhones(
    $input: UpdateCampaignOwnPhonesInput!
    $updateCampaignOwnPhonesId: String!
  ) {
    updateCampaignOwnPhones(input: $input, id: $updateCampaignOwnPhonesId) {
      success
    }
  }
`;
export type UpdateCampaignOwnPhonesMutationFn = Apollo.MutationFunction<
  UpdateCampaignOwnPhonesMutation,
  UpdateCampaignOwnPhonesMutationVariables
>;

/**
 * __useUpdateCampaignOwnPhonesMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignOwnPhonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignOwnPhonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignOwnPhonesMutation, { data, loading, error }] = useUpdateCampaignOwnPhonesMutation({
 *   variables: {
 *      input: // value for 'input'
 *      updateCampaignOwnPhonesId: // value for 'updateCampaignOwnPhonesId'
 *   },
 * });
 */
export function useUpdateCampaignOwnPhonesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCampaignOwnPhonesMutation,
    UpdateCampaignOwnPhonesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCampaignOwnPhonesMutation,
    UpdateCampaignOwnPhonesMutationVariables
  >(UpdateCampaignOwnPhonesDocument, options);
}
export type UpdateCampaignOwnPhonesMutationHookResult = ReturnType<
  typeof useUpdateCampaignOwnPhonesMutation
>;
export type UpdateCampaignOwnPhonesMutationResult =
  Apollo.MutationResult<UpdateCampaignOwnPhonesMutation>;
export type UpdateCampaignOwnPhonesMutationOptions = Apollo.BaseMutationOptions<
  UpdateCampaignOwnPhonesMutation,
  UpdateCampaignOwnPhonesMutationVariables
>;
export const CampaignUpsertedDocument = gql`
  subscription CampaignUpserted {
    campaignUpserted {
      operation
      entityId
    }
  }
`;

/**
 * __useCampaignUpsertedSubscription__
 *
 * To run a query within a React component, call `useCampaignUpsertedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCampaignUpsertedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignUpsertedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCampaignUpsertedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CampaignUpsertedSubscription,
    CampaignUpsertedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CampaignUpsertedSubscription,
    CampaignUpsertedSubscriptionVariables
  >(CampaignUpsertedDocument, options);
}
export type CampaignUpsertedSubscriptionHookResult = ReturnType<
  typeof useCampaignUpsertedSubscription
>;
export type CampaignUpsertedSubscriptionResult =
  Apollo.SubscriptionResult<CampaignUpsertedSubscription>;
export const CampaignProgressUpdatedDocument = gql`
  subscription CampaignProgressUpdated {
    campaignProgressUpdated {
      campaignId
      progress {
        sent
        total
      }
    }
  }
`;

/**
 * __useCampaignProgressUpdatedSubscription__
 *
 * To run a query within a React component, call `useCampaignProgressUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCampaignProgressUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignProgressUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCampaignProgressUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CampaignProgressUpdatedSubscription,
    CampaignProgressUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CampaignProgressUpdatedSubscription,
    CampaignProgressUpdatedSubscriptionVariables
  >(CampaignProgressUpdatedDocument, options);
}
export type CampaignProgressUpdatedSubscriptionHookResult = ReturnType<
  typeof useCampaignProgressUpdatedSubscription
>;
export type CampaignProgressUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<CampaignProgressUpdatedSubscription>;
export const GetContactNoteSignedUrlDocument = gql`
  query GetContactNoteSignedUrl($input: GenerateSignedUrlInput!) {
    getContactNoteSignedUrl(input: $input) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useGetContactNoteSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetContactNoteSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactNoteSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactNoteSignedUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContactNoteSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactNoteSignedUrlQuery,
    GetContactNoteSignedUrlQueryVariables
  > &
    (
      | { variables: GetContactNoteSignedUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContactNoteSignedUrlQuery,
    GetContactNoteSignedUrlQueryVariables
  >(GetContactNoteSignedUrlDocument, options);
}
export function useGetContactNoteSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactNoteSignedUrlQuery,
    GetContactNoteSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContactNoteSignedUrlQuery,
    GetContactNoteSignedUrlQueryVariables
  >(GetContactNoteSignedUrlDocument, options);
}
export function useGetContactNoteSignedUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContactNoteSignedUrlQuery,
        GetContactNoteSignedUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContactNoteSignedUrlQuery,
    GetContactNoteSignedUrlQueryVariables
  >(GetContactNoteSignedUrlDocument, options);
}
export type GetContactNoteSignedUrlQueryHookResult = ReturnType<
  typeof useGetContactNoteSignedUrlQuery
>;
export type GetContactNoteSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetContactNoteSignedUrlLazyQuery
>;
export type GetContactNoteSignedUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetContactNoteSignedUrlSuspenseQuery
>;
export type GetContactNoteSignedUrlQueryResult = Apollo.QueryResult<
  GetContactNoteSignedUrlQuery,
  GetContactNoteSignedUrlQueryVariables
>;
export const FindContactNotesDocument = gql`
  query FindContactNotes($filter: ContactNoteFilter!, $pagination: Pagination) {
    findContactNotes(filter: $filter, pagination: $pagination) {
      hasNext
      items {
        ...ContactNote
      }
      nextCreatedAt
      total
      nextId
    }
  }
  ${ContactNoteFragmentDoc}
`;

/**
 * __useFindContactNotesQuery__
 *
 * To run a query within a React component, call `useFindContactNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindContactNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindContactNotesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindContactNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindContactNotesQuery,
    FindContactNotesQueryVariables
  > &
    (
      | { variables: FindContactNotesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindContactNotesQuery, FindContactNotesQueryVariables>(
    FindContactNotesDocument,
    options,
  );
}
export function useFindContactNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindContactNotesQuery,
    FindContactNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindContactNotesQuery,
    FindContactNotesQueryVariables
  >(FindContactNotesDocument, options);
}
export function useFindContactNotesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindContactNotesQuery,
        FindContactNotesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindContactNotesQuery,
    FindContactNotesQueryVariables
  >(FindContactNotesDocument, options);
}
export type FindContactNotesQueryHookResult = ReturnType<
  typeof useFindContactNotesQuery
>;
export type FindContactNotesLazyQueryHookResult = ReturnType<
  typeof useFindContactNotesLazyQuery
>;
export type FindContactNotesSuspenseQueryHookResult = ReturnType<
  typeof useFindContactNotesSuspenseQuery
>;
export type FindContactNotesQueryResult = Apollo.QueryResult<
  FindContactNotesQuery,
  FindContactNotesQueryVariables
>;
export const CreateContactNoteDocument = gql`
  mutation CreateContactNote(
    $input: CreateContactNoteInput!
    $contactId: String!
  ) {
    createContactNote(input: $input, contactId: $contactId) {
      ...ContactNote
    }
  }
  ${ContactNoteFragmentDoc}
`;
export type CreateContactNoteMutationFn = Apollo.MutationFunction<
  CreateContactNoteMutation,
  CreateContactNoteMutationVariables
>;

/**
 * __useCreateContactNoteMutation__
 *
 * To run a mutation, you first call `useCreateContactNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactNoteMutation, { data, loading, error }] = useCreateContactNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useCreateContactNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContactNoteMutation,
    CreateContactNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContactNoteMutation,
    CreateContactNoteMutationVariables
  >(CreateContactNoteDocument, options);
}
export type CreateContactNoteMutationHookResult = ReturnType<
  typeof useCreateContactNoteMutation
>;
export type CreateContactNoteMutationResult =
  Apollo.MutationResult<CreateContactNoteMutation>;
export type CreateContactNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateContactNoteMutation,
  CreateContactNoteMutationVariables
>;
export const UpdateContactNoteDocument = gql`
  mutation UpdateContactNote(
    $input: UpdateContactNoteInput!
    $noteId: String!
    $contactId: String!
  ) {
    updateContactNote(input: $input, id: $noteId, contactId: $contactId) {
      ...ContactNote
    }
  }
  ${ContactNoteFragmentDoc}
`;
export type UpdateContactNoteMutationFn = Apollo.MutationFunction<
  UpdateContactNoteMutation,
  UpdateContactNoteMutationVariables
>;

/**
 * __useUpdateContactNoteMutation__
 *
 * To run a mutation, you first call `useUpdateContactNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactNoteMutation, { data, loading, error }] = useUpdateContactNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      noteId: // value for 'noteId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useUpdateContactNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactNoteMutation,
    UpdateContactNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContactNoteMutation,
    UpdateContactNoteMutationVariables
  >(UpdateContactNoteDocument, options);
}
export type UpdateContactNoteMutationHookResult = ReturnType<
  typeof useUpdateContactNoteMutation
>;
export type UpdateContactNoteMutationResult =
  Apollo.MutationResult<UpdateContactNoteMutation>;
export type UpdateContactNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactNoteMutation,
  UpdateContactNoteMutationVariables
>;
export const RemoveContactNoteDocument = gql`
  mutation RemoveContactNote($noteId: String!, $contactId: String!) {
    removeContactNote(id: $noteId, contactId: $contactId) {
      success
    }
  }
`;
export type RemoveContactNoteMutationFn = Apollo.MutationFunction<
  RemoveContactNoteMutation,
  RemoveContactNoteMutationVariables
>;

/**
 * __useRemoveContactNoteMutation__
 *
 * To run a mutation, you first call `useRemoveContactNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactNoteMutation, { data, loading, error }] = useRemoveContactNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useRemoveContactNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveContactNoteMutation,
    RemoveContactNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveContactNoteMutation,
    RemoveContactNoteMutationVariables
  >(RemoveContactNoteDocument, options);
}
export type RemoveContactNoteMutationHookResult = ReturnType<
  typeof useRemoveContactNoteMutation
>;
export type RemoveContactNoteMutationResult =
  Apollo.MutationResult<RemoveContactNoteMutation>;
export type RemoveContactNoteMutationOptions = Apollo.BaseMutationOptions<
  RemoveContactNoteMutation,
  RemoveContactNoteMutationVariables
>;
export const GetContactsCountersDocument = gql`
  query GetContactsCounters {
    getContactsCounters {
      activeGroups
      activeContacts
      deletedContacts
    }
  }
`;

/**
 * __useGetContactsCountersQuery__
 *
 * To run a query within a React component, call `useGetContactsCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsCountersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactsCountersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContactsCountersQuery,
    GetContactsCountersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContactsCountersQuery,
    GetContactsCountersQueryVariables
  >(GetContactsCountersDocument, options);
}
export function useGetContactsCountersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactsCountersQuery,
    GetContactsCountersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContactsCountersQuery,
    GetContactsCountersQueryVariables
  >(GetContactsCountersDocument, options);
}
export function useGetContactsCountersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContactsCountersQuery,
        GetContactsCountersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContactsCountersQuery,
    GetContactsCountersQueryVariables
  >(GetContactsCountersDocument, options);
}
export type GetContactsCountersQueryHookResult = ReturnType<
  typeof useGetContactsCountersQuery
>;
export type GetContactsCountersLazyQueryHookResult = ReturnType<
  typeof useGetContactsCountersLazyQuery
>;
export type GetContactsCountersSuspenseQueryHookResult = ReturnType<
  typeof useGetContactsCountersSuspenseQuery
>;
export type GetContactsCountersQueryResult = Apollo.QueryResult<
  GetContactsCountersQuery,
  GetContactsCountersQueryVariables
>;
export const FindContactsDocument = gql`
  query FindContacts(
    $filter: ContactFilter
    $pagination: Pagination
    $order: ContactOrder
  ) {
    findContacts(filter: $filter, pagination: $pagination, order: $order) {
      hasNext
      items {
        ...ContactPreview
      }
      nextCreatedAt
      total
      nextId
    }
  }
  ${ContactPreviewFragmentDoc}
`;

/**
 * __useFindContactsQuery__
 *
 * To run a query within a React component, call `useFindContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindContactsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useFindContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindContactsQuery,
    FindContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindContactsQuery, FindContactsQueryVariables>(
    FindContactsDocument,
    options,
  );
}
export function useFindContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindContactsQuery,
    FindContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindContactsQuery, FindContactsQueryVariables>(
    FindContactsDocument,
    options,
  );
}
export function useFindContactsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindContactsQuery,
        FindContactsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindContactsQuery, FindContactsQueryVariables>(
    FindContactsDocument,
    options,
  );
}
export type FindContactsQueryHookResult = ReturnType<
  typeof useFindContactsQuery
>;
export type FindContactsLazyQueryHookResult = ReturnType<
  typeof useFindContactsLazyQuery
>;
export type FindContactsSuspenseQueryHookResult = ReturnType<
  typeof useFindContactsSuspenseQuery
>;
export type FindContactsQueryResult = Apollo.QueryResult<
  FindContactsQuery,
  FindContactsQueryVariables
>;
export const CountContactsDocument = gql`
  query CountContacts(
    $filter: ContactFilter
    $pagination: Pagination
    $order: ContactOrder
  ) {
    findContacts(filter: $filter, pagination: $pagination, order: $order) {
      total
    }
  }
`;

/**
 * __useCountContactsQuery__
 *
 * To run a query within a React component, call `useCountContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountContactsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCountContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CountContactsQuery,
    CountContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountContactsQuery, CountContactsQueryVariables>(
    CountContactsDocument,
    options,
  );
}
export function useCountContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountContactsQuery,
    CountContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountContactsQuery, CountContactsQueryVariables>(
    CountContactsDocument,
    options,
  );
}
export function useCountContactsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CountContactsQuery,
        CountContactsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CountContactsQuery,
    CountContactsQueryVariables
  >(CountContactsDocument, options);
}
export type CountContactsQueryHookResult = ReturnType<
  typeof useCountContactsQuery
>;
export type CountContactsLazyQueryHookResult = ReturnType<
  typeof useCountContactsLazyQuery
>;
export type CountContactsSuspenseQueryHookResult = ReturnType<
  typeof useCountContactsSuspenseQuery
>;
export type CountContactsQueryResult = Apollo.QueryResult<
  CountContactsQuery,
  CountContactsQueryVariables
>;
export const FindContactsPhonesDocument = gql`
  query FindContactsPhones($pagination: Pagination, $filter: ContactFilter) {
    findContacts(pagination: $pagination, filter: $filter) {
      hasNext
      items {
        id
        firstName
        lastName
        phone
      }
      nextCreatedAt
      total
      nextId
    }
  }
`;

/**
 * __useFindContactsPhonesQuery__
 *
 * To run a query within a React component, call `useFindContactsPhonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindContactsPhonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindContactsPhonesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindContactsPhonesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindContactsPhonesQuery,
    FindContactsPhonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindContactsPhonesQuery,
    FindContactsPhonesQueryVariables
  >(FindContactsPhonesDocument, options);
}
export function useFindContactsPhonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindContactsPhonesQuery,
    FindContactsPhonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindContactsPhonesQuery,
    FindContactsPhonesQueryVariables
  >(FindContactsPhonesDocument, options);
}
export function useFindContactsPhonesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindContactsPhonesQuery,
        FindContactsPhonesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindContactsPhonesQuery,
    FindContactsPhonesQueryVariables
  >(FindContactsPhonesDocument, options);
}
export type FindContactsPhonesQueryHookResult = ReturnType<
  typeof useFindContactsPhonesQuery
>;
export type FindContactsPhonesLazyQueryHookResult = ReturnType<
  typeof useFindContactsPhonesLazyQuery
>;
export type FindContactsPhonesSuspenseQueryHookResult = ReturnType<
  typeof useFindContactsPhonesSuspenseQuery
>;
export type FindContactsPhonesQueryResult = Apollo.QueryResult<
  FindContactsPhonesQuery,
  FindContactsPhonesQueryVariables
>;
export const GetContactDocument = gql`
  query GetContact($id: String!) {
    getContact(id: $id) {
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactQuery,
    GetContactQueryVariables
  > &
    (
      | { variables: GetContactQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContactQuery, GetContactQueryVariables>(
    GetContactDocument,
    options,
  );
}
export function useGetContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactQuery,
    GetContactQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContactQuery, GetContactQueryVariables>(
    GetContactDocument,
    options,
  );
}
export function useGetContactSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContactQuery,
        GetContactQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetContactQuery, GetContactQueryVariables>(
    GetContactDocument,
    options,
  );
}
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<
  typeof useGetContactLazyQuery
>;
export type GetContactSuspenseQueryHookResult = ReturnType<
  typeof useGetContactSuspenseQuery
>;
export type GetContactQueryResult = Apollo.QueryResult<
  GetContactQuery,
  GetContactQueryVariables
>;
export const GetContactPhoneDocument = gql`
  query GetContactPhone($id: String!) {
    getContact(id: $id) {
      phone
      firstName
      lastName
    }
  }
`;

/**
 * __useGetContactPhoneQuery__
 *
 * To run a query within a React component, call `useGetContactPhoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactPhoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactPhoneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactPhoneQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactPhoneQuery,
    GetContactPhoneQueryVariables
  > &
    (
      | { variables: GetContactPhoneQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContactPhoneQuery, GetContactPhoneQueryVariables>(
    GetContactPhoneDocument,
    options,
  );
}
export function useGetContactPhoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactPhoneQuery,
    GetContactPhoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContactPhoneQuery,
    GetContactPhoneQueryVariables
  >(GetContactPhoneDocument, options);
}
export function useGetContactPhoneSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContactPhoneQuery,
        GetContactPhoneQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContactPhoneQuery,
    GetContactPhoneQueryVariables
  >(GetContactPhoneDocument, options);
}
export type GetContactPhoneQueryHookResult = ReturnType<
  typeof useGetContactPhoneQuery
>;
export type GetContactPhoneLazyQueryHookResult = ReturnType<
  typeof useGetContactPhoneLazyQuery
>;
export type GetContactPhoneSuspenseQueryHookResult = ReturnType<
  typeof useGetContactPhoneSuspenseQuery
>;
export type GetContactPhoneQueryResult = Apollo.QueryResult<
  GetContactPhoneQuery,
  GetContactPhoneQueryVariables
>;
export const GetContactNotesDocument = gql`
  query GetContactNotes($id: String!) {
    getContact(id: $id) {
      notes {
        ...ContactNote
      }
    }
  }
  ${ContactNoteFragmentDoc}
`;

/**
 * __useGetContactNotesQuery__
 *
 * To run a query within a React component, call `useGetContactNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactNotesQuery,
    GetContactNotesQueryVariables
  > &
    (
      | { variables: GetContactNotesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContactNotesQuery, GetContactNotesQueryVariables>(
    GetContactNotesDocument,
    options,
  );
}
export function useGetContactNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactNotesQuery,
    GetContactNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContactNotesQuery,
    GetContactNotesQueryVariables
  >(GetContactNotesDocument, options);
}
export function useGetContactNotesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContactNotesQuery,
        GetContactNotesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContactNotesQuery,
    GetContactNotesQueryVariables
  >(GetContactNotesDocument, options);
}
export type GetContactNotesQueryHookResult = ReturnType<
  typeof useGetContactNotesQuery
>;
export type GetContactNotesLazyQueryHookResult = ReturnType<
  typeof useGetContactNotesLazyQuery
>;
export type GetContactNotesSuspenseQueryHookResult = ReturnType<
  typeof useGetContactNotesSuspenseQuery
>;
export type GetContactNotesQueryResult = Apollo.QueryResult<
  GetContactNotesQuery,
  GetContactNotesQueryVariables
>;
export const CreateContactDocument = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;
export type CreateContactMutationFn = Apollo.MutationFunction<
  CreateContactMutation,
  CreateContactMutationVariables
>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContactMutation,
    CreateContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(CreateContactDocument, options);
}
export type CreateContactMutationHookResult = ReturnType<
  typeof useCreateContactMutation
>;
export type CreateContactMutationResult =
  Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const UpdateContactDocument = gql`
  mutation UpdateContact($input: UpdateContactInput!, $id: String!) {
    updateContact(input: $input, id: $id) {
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;
export type UpdateContactMutationFn = Apollo.MutationFunction<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >(UpdateContactDocument, options);
}
export type UpdateContactMutationHookResult = ReturnType<
  typeof useUpdateContactMutation
>;
export type UpdateContactMutationResult =
  Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;
export const RecoverContactsDocument = gql`
  mutation RecoverContacts($input: RecoverContactsInput!) {
    recoverContacts(input: $input) {
      success
    }
  }
`;
export type RecoverContactsMutationFn = Apollo.MutationFunction<
  RecoverContactsMutation,
  RecoverContactsMutationVariables
>;

/**
 * __useRecoverContactsMutation__
 *
 * To run a mutation, you first call `useRecoverContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverContactsMutation, { data, loading, error }] = useRecoverContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecoverContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RecoverContactsMutation,
    RecoverContactsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RecoverContactsMutation,
    RecoverContactsMutationVariables
  >(RecoverContactsDocument, options);
}
export type RecoverContactsMutationHookResult = ReturnType<
  typeof useRecoverContactsMutation
>;
export type RecoverContactsMutationResult =
  Apollo.MutationResult<RecoverContactsMutation>;
export type RecoverContactsMutationOptions = Apollo.BaseMutationOptions<
  RecoverContactsMutation,
  RecoverContactsMutationVariables
>;
export const RemoveContactsDocument = gql`
  mutation RemoveContacts($input: RemoveContactsInput!) {
    removeContacts(input: $input) {
      success
    }
  }
`;
export type RemoveContactsMutationFn = Apollo.MutationFunction<
  RemoveContactsMutation,
  RemoveContactsMutationVariables
>;

/**
 * __useRemoveContactsMutation__
 *
 * To run a mutation, you first call `useRemoveContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactsMutation, { data, loading, error }] = useRemoveContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveContactsMutation,
    RemoveContactsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveContactsMutation,
    RemoveContactsMutationVariables
  >(RemoveContactsDocument, options);
}
export type RemoveContactsMutationHookResult = ReturnType<
  typeof useRemoveContactsMutation
>;
export type RemoveContactsMutationResult =
  Apollo.MutationResult<RemoveContactsMutation>;
export type RemoveContactsMutationOptions = Apollo.BaseMutationOptions<
  RemoveContactsMutation,
  RemoveContactsMutationVariables
>;
export const BlockContactsDocument = gql`
  mutation BlockContacts($input: BlockContactsInput!) {
    blockContacts(input: $input) {
      success
    }
  }
`;
export type BlockContactsMutationFn = Apollo.MutationFunction<
  BlockContactsMutation,
  BlockContactsMutationVariables
>;

/**
 * __useBlockContactsMutation__
 *
 * To run a mutation, you first call `useBlockContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockContactsMutation, { data, loading, error }] = useBlockContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BlockContactsMutation,
    BlockContactsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BlockContactsMutation,
    BlockContactsMutationVariables
  >(BlockContactsDocument, options);
}
export type BlockContactsMutationHookResult = ReturnType<
  typeof useBlockContactsMutation
>;
export type BlockContactsMutationResult =
  Apollo.MutationResult<BlockContactsMutation>;
export type BlockContactsMutationOptions = Apollo.BaseMutationOptions<
  BlockContactsMutation,
  BlockContactsMutationVariables
>;
export const AddLabelsToContactsDocument = gql`
  mutation AddLabelsToContacts($input: AddLabelsToContactsInput!) {
    addLabelsToContacts(input: $input) {
      labels {
        title
        id
        color
        createdAt
        default
        firestoreId
      }
      id
      firstName
      email
      lastName
    }
  }
`;
export type AddLabelsToContactsMutationFn = Apollo.MutationFunction<
  AddLabelsToContactsMutation,
  AddLabelsToContactsMutationVariables
>;

/**
 * __useAddLabelsToContactsMutation__
 *
 * To run a mutation, you first call `useAddLabelsToContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLabelsToContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLabelsToContactsMutation, { data, loading, error }] = useAddLabelsToContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLabelsToContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLabelsToContactsMutation,
    AddLabelsToContactsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddLabelsToContactsMutation,
    AddLabelsToContactsMutationVariables
  >(AddLabelsToContactsDocument, options);
}
export type AddLabelsToContactsMutationHookResult = ReturnType<
  typeof useAddLabelsToContactsMutation
>;
export type AddLabelsToContactsMutationResult =
  Apollo.MutationResult<AddLabelsToContactsMutation>;
export type AddLabelsToContactsMutationOptions = Apollo.BaseMutationOptions<
  AddLabelsToContactsMutation,
  AddLabelsToContactsMutationVariables
>;
export const RemoveLabelsFromContactsDocument = gql`
  mutation RemoveLabelsFromContacts($input: RemoveLabelsFromContactsInput!) {
    removeLabelsFromContacts(input: $input) {
      firstName
      lastName
      labels {
        title
        id
        color
        createdAt
        default
        firestoreId
      }
      id
    }
  }
`;
export type RemoveLabelsFromContactsMutationFn = Apollo.MutationFunction<
  RemoveLabelsFromContactsMutation,
  RemoveLabelsFromContactsMutationVariables
>;

/**
 * __useRemoveLabelsFromContactsMutation__
 *
 * To run a mutation, you first call `useRemoveLabelsFromContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLabelsFromContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLabelsFromContactsMutation, { data, loading, error }] = useRemoveLabelsFromContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveLabelsFromContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveLabelsFromContactsMutation,
    RemoveLabelsFromContactsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveLabelsFromContactsMutation,
    RemoveLabelsFromContactsMutationVariables
  >(RemoveLabelsFromContactsDocument, options);
}
export type RemoveLabelsFromContactsMutationHookResult = ReturnType<
  typeof useRemoveLabelsFromContactsMutation
>;
export type RemoveLabelsFromContactsMutationResult =
  Apollo.MutationResult<RemoveLabelsFromContactsMutation>;
export type RemoveLabelsFromContactsMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveLabelsFromContactsMutation,
    RemoveLabelsFromContactsMutationVariables
  >;
export const FindConversationsDocument = gql`
  query FindConversations(
    $filter: ConversationFilter
    $pagination: Pagination
    $order: ConversationOrder
  ) {
    findConversations(filter: $filter, pagination: $pagination, order: $order) {
      items {
        ...Conversation
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${ConversationFragmentDoc}
`;

/**
 * __useFindConversationsQuery__
 *
 * To run a query within a React component, call `useFindConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindConversationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useFindConversationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindConversationsQuery,
    FindConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindConversationsQuery,
    FindConversationsQueryVariables
  >(FindConversationsDocument, options);
}
export function useFindConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindConversationsQuery,
    FindConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindConversationsQuery,
    FindConversationsQueryVariables
  >(FindConversationsDocument, options);
}
export function useFindConversationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindConversationsQuery,
        FindConversationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindConversationsQuery,
    FindConversationsQueryVariables
  >(FindConversationsDocument, options);
}
export type FindConversationsQueryHookResult = ReturnType<
  typeof useFindConversationsQuery
>;
export type FindConversationsLazyQueryHookResult = ReturnType<
  typeof useFindConversationsLazyQuery
>;
export type FindConversationsSuspenseQueryHookResult = ReturnType<
  typeof useFindConversationsSuspenseQuery
>;
export type FindConversationsQueryResult = Apollo.QueryResult<
  FindConversationsQuery,
  FindConversationsQueryVariables
>;
export const FindConversationMessagesDocument = gql`
  query FindConversationMessages(
    $contactId: String!
    $filter: MessageFilter
    $pagination: Pagination
    $order: MessageOrder
  ) {
    findConversationMessages(
      contactId: $contactId
      filter: $filter
      pagination: $pagination
      order: $order
    ) {
      items {
        ...Message
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${MessageFragmentDoc}
`;

/**
 * __useFindConversationMessagesQuery__
 *
 * To run a query within a React component, call `useFindConversationMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindConversationMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindConversationMessagesQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useFindConversationMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindConversationMessagesQuery,
    FindConversationMessagesQueryVariables
  > &
    (
      | { variables: FindConversationMessagesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindConversationMessagesQuery,
    FindConversationMessagesQueryVariables
  >(FindConversationMessagesDocument, options);
}
export function useFindConversationMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindConversationMessagesQuery,
    FindConversationMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindConversationMessagesQuery,
    FindConversationMessagesQueryVariables
  >(FindConversationMessagesDocument, options);
}
export function useFindConversationMessagesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindConversationMessagesQuery,
        FindConversationMessagesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindConversationMessagesQuery,
    FindConversationMessagesQueryVariables
  >(FindConversationMessagesDocument, options);
}
export type FindConversationMessagesQueryHookResult = ReturnType<
  typeof useFindConversationMessagesQuery
>;
export type FindConversationMessagesLazyQueryHookResult = ReturnType<
  typeof useFindConversationMessagesLazyQuery
>;
export type FindConversationMessagesSuspenseQueryHookResult = ReturnType<
  typeof useFindConversationMessagesSuspenseQuery
>;
export type FindConversationMessagesQueryResult = Apollo.QueryResult<
  FindConversationMessagesQuery,
  FindConversationMessagesQueryVariables
>;
export const FindScheduledMessagesDocument = gql`
  query FindScheduledMessages(
    $filter: ScheduledMessageFilter
    $pagination: Pagination
  ) {
    findScheduledMessages(filter: $filter, pagination: $pagination) {
      items {
        id
        createdAt
        scheduledAt
        content
        attachments {
          ...Attachment
        }
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${AttachmentFragmentDoc}
`;

/**
 * __useFindScheduledMessagesQuery__
 *
 * To run a query within a React component, call `useFindScheduledMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindScheduledMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindScheduledMessagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindScheduledMessagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindScheduledMessagesQuery,
    FindScheduledMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindScheduledMessagesQuery,
    FindScheduledMessagesQueryVariables
  >(FindScheduledMessagesDocument, options);
}
export function useFindScheduledMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindScheduledMessagesQuery,
    FindScheduledMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindScheduledMessagesQuery,
    FindScheduledMessagesQueryVariables
  >(FindScheduledMessagesDocument, options);
}
export function useFindScheduledMessagesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindScheduledMessagesQuery,
        FindScheduledMessagesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindScheduledMessagesQuery,
    FindScheduledMessagesQueryVariables
  >(FindScheduledMessagesDocument, options);
}
export type FindScheduledMessagesQueryHookResult = ReturnType<
  typeof useFindScheduledMessagesQuery
>;
export type FindScheduledMessagesLazyQueryHookResult = ReturnType<
  typeof useFindScheduledMessagesLazyQuery
>;
export type FindScheduledMessagesSuspenseQueryHookResult = ReturnType<
  typeof useFindScheduledMessagesSuspenseQuery
>;
export type FindScheduledMessagesQueryResult = Apollo.QueryResult<
  FindScheduledMessagesQuery,
  FindScheduledMessagesQueryVariables
>;
export const GetMessageAttachmentSignedUrlDocument = gql`
  query GetMessageAttachmentSignedUrl(
    $input: GetMessageAttachmentSignedUrlInput!
  ) {
    getMessageAttachmentSignedUrl(input: $input) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useGetMessageAttachmentSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetMessageAttachmentSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageAttachmentSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageAttachmentSignedUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMessageAttachmentSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMessageAttachmentSignedUrlQuery,
    GetMessageAttachmentSignedUrlQueryVariables
  > &
    (
      | {
          variables: GetMessageAttachmentSignedUrlQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMessageAttachmentSignedUrlQuery,
    GetMessageAttachmentSignedUrlQueryVariables
  >(GetMessageAttachmentSignedUrlDocument, options);
}
export function useGetMessageAttachmentSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMessageAttachmentSignedUrlQuery,
    GetMessageAttachmentSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMessageAttachmentSignedUrlQuery,
    GetMessageAttachmentSignedUrlQueryVariables
  >(GetMessageAttachmentSignedUrlDocument, options);
}
export function useGetMessageAttachmentSignedUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMessageAttachmentSignedUrlQuery,
        GetMessageAttachmentSignedUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMessageAttachmentSignedUrlQuery,
    GetMessageAttachmentSignedUrlQueryVariables
  >(GetMessageAttachmentSignedUrlDocument, options);
}
export type GetMessageAttachmentSignedUrlQueryHookResult = ReturnType<
  typeof useGetMessageAttachmentSignedUrlQuery
>;
export type GetMessageAttachmentSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetMessageAttachmentSignedUrlLazyQuery
>;
export type GetMessageAttachmentSignedUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetMessageAttachmentSignedUrlSuspenseQuery
>;
export type GetMessageAttachmentSignedUrlQueryResult = Apollo.QueryResult<
  GetMessageAttachmentSignedUrlQuery,
  GetMessageAttachmentSignedUrlQueryVariables
>;
export const GetConversationDocument = gql`
  query GetConversation($id: String!) {
    getConversation(id: $id) {
      id
      lastMessage {
        ...LastMessage
      }
      lastMessageAt
      isFavorite
      isRead
      isUnsubscribed
      status
      name
      labels {
        id
        title
        color
      }
      campaigns {
        id
        name
      }
      isBlocked
      phone
      outgoingPhone
      ownPhone {
        id
        type
        number
        location {
          abbreviation
          state
        }
      }
    }
  }
  ${LastMessageFragmentDoc}
`;

/**
 * __useGetConversationQuery__
 *
 * To run a query within a React component, call `useGetConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConversationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConversationQuery,
    GetConversationQueryVariables
  > &
    (
      | { variables: GetConversationQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConversationQuery, GetConversationQueryVariables>(
    GetConversationDocument,
    options,
  );
}
export function useGetConversationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationQuery,
    GetConversationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationQuery,
    GetConversationQueryVariables
  >(GetConversationDocument, options);
}
export function useGetConversationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetConversationQuery,
        GetConversationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetConversationQuery,
    GetConversationQueryVariables
  >(GetConversationDocument, options);
}
export type GetConversationQueryHookResult = ReturnType<
  typeof useGetConversationQuery
>;
export type GetConversationLazyQueryHookResult = ReturnType<
  typeof useGetConversationLazyQuery
>;
export type GetConversationSuspenseQueryHookResult = ReturnType<
  typeof useGetConversationSuspenseQuery
>;
export type GetConversationQueryResult = Apollo.QueryResult<
  GetConversationQuery,
  GetConversationQueryVariables
>;
export const GetConversationOwnPhoneDocument = gql`
  query GetConversationOwnPhone($id: String!) {
    getConversation(id: $id) {
      ownPhone {
        id
        status
        type
        number
        isTrial
        messagingEnabled
        callingEnabled
      }
    }
  }
`;

/**
 * __useGetConversationOwnPhoneQuery__
 *
 * To run a query within a React component, call `useGetConversationOwnPhoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationOwnPhoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationOwnPhoneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConversationOwnPhoneQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConversationOwnPhoneQuery,
    GetConversationOwnPhoneQueryVariables
  > &
    (
      | { variables: GetConversationOwnPhoneQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConversationOwnPhoneQuery,
    GetConversationOwnPhoneQueryVariables
  >(GetConversationOwnPhoneDocument, options);
}
export function useGetConversationOwnPhoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationOwnPhoneQuery,
    GetConversationOwnPhoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationOwnPhoneQuery,
    GetConversationOwnPhoneQueryVariables
  >(GetConversationOwnPhoneDocument, options);
}
export function useGetConversationOwnPhoneSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetConversationOwnPhoneQuery,
        GetConversationOwnPhoneQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetConversationOwnPhoneQuery,
    GetConversationOwnPhoneQueryVariables
  >(GetConversationOwnPhoneDocument, options);
}
export type GetConversationOwnPhoneQueryHookResult = ReturnType<
  typeof useGetConversationOwnPhoneQuery
>;
export type GetConversationOwnPhoneLazyQueryHookResult = ReturnType<
  typeof useGetConversationOwnPhoneLazyQuery
>;
export type GetConversationOwnPhoneSuspenseQueryHookResult = ReturnType<
  typeof useGetConversationOwnPhoneSuspenseQuery
>;
export type GetConversationOwnPhoneQueryResult = Apollo.QueryResult<
  GetConversationOwnPhoneQuery,
  GetConversationOwnPhoneQueryVariables
>;
export const GetUnreadConversationsCountersDocument = gql`
  query GetUnreadConversationsCounters($profileId: String) {
    getUnreadConversationsCounters(profileId: $profileId) {
      withUnreadMessages
      withMissedCalls
    }
  }
`;

/**
 * __useGetUnreadConversationsCountersQuery__
 *
 * To run a query within a React component, call `useGetUnreadConversationsCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadConversationsCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadConversationsCountersQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetUnreadConversationsCountersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUnreadConversationsCountersQuery,
    GetUnreadConversationsCountersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUnreadConversationsCountersQuery,
    GetUnreadConversationsCountersQueryVariables
  >(GetUnreadConversationsCountersDocument, options);
}
export function useGetUnreadConversationsCountersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnreadConversationsCountersQuery,
    GetUnreadConversationsCountersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUnreadConversationsCountersQuery,
    GetUnreadConversationsCountersQueryVariables
  >(GetUnreadConversationsCountersDocument, options);
}
export function useGetUnreadConversationsCountersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUnreadConversationsCountersQuery,
        GetUnreadConversationsCountersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUnreadConversationsCountersQuery,
    GetUnreadConversationsCountersQueryVariables
  >(GetUnreadConversationsCountersDocument, options);
}
export type GetUnreadConversationsCountersQueryHookResult = ReturnType<
  typeof useGetUnreadConversationsCountersQuery
>;
export type GetUnreadConversationsCountersLazyQueryHookResult = ReturnType<
  typeof useGetUnreadConversationsCountersLazyQuery
>;
export type GetUnreadConversationsCountersSuspenseQueryHookResult = ReturnType<
  typeof useGetUnreadConversationsCountersSuspenseQuery
>;
export type GetUnreadConversationsCountersQueryResult = Apollo.QueryResult<
  GetUnreadConversationsCountersQuery,
  GetUnreadConversationsCountersQueryVariables
>;
export const CreateConversationDocument = gql`
  mutation CreateConversation($input: CreateConversationInput!) {
    createConversation(input: $input) {
      id
    }
  }
`;
export type CreateConversationMutationFn = Apollo.MutationFunction<
  CreateConversationMutation,
  CreateConversationMutationVariables
>;

/**
 * __useCreateConversationMutation__
 *
 * To run a mutation, you first call `useCreateConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversationMutation, { data, loading, error }] = useCreateConversationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConversationMutation,
    CreateConversationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConversationMutation,
    CreateConversationMutationVariables
  >(CreateConversationDocument, options);
}
export type CreateConversationMutationHookResult = ReturnType<
  typeof useCreateConversationMutation
>;
export type CreateConversationMutationResult =
  Apollo.MutationResult<CreateConversationMutation>;
export type CreateConversationMutationOptions = Apollo.BaseMutationOptions<
  CreateConversationMutation,
  CreateConversationMutationVariables
>;
export const MarkConversationsReadDocument = gql`
  mutation MarkConversationsRead($input: MarkConversationsReadInput!) {
    markConversationsRead(input: $input) {
      success
    }
  }
`;
export type MarkConversationsReadMutationFn = Apollo.MutationFunction<
  MarkConversationsReadMutation,
  MarkConversationsReadMutationVariables
>;

/**
 * __useMarkConversationsReadMutation__
 *
 * To run a mutation, you first call `useMarkConversationsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkConversationsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markConversationsReadMutation, { data, loading, error }] = useMarkConversationsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkConversationsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkConversationsReadMutation,
    MarkConversationsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkConversationsReadMutation,
    MarkConversationsReadMutationVariables
  >(MarkConversationsReadDocument, options);
}
export type MarkConversationsReadMutationHookResult = ReturnType<
  typeof useMarkConversationsReadMutation
>;
export type MarkConversationsReadMutationResult =
  Apollo.MutationResult<MarkConversationsReadMutation>;
export type MarkConversationsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkConversationsReadMutation,
  MarkConversationsReadMutationVariables
>;
export const SendMessageDocument = gql`
  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      ...Message
    }
  }
  ${MessageFragmentDoc}
`;
export type SendMessageMutationFn = Apollo.MutationFunction<
  SendMessageMutation,
  SendMessageMutationVariables
>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMessageMutation,
    SendMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(
    SendMessageDocument,
    options,
  );
}
export type SendMessageMutationHookResult = ReturnType<
  typeof useSendMessageMutation
>;
export type SendMessageMutationResult =
  Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
  SendMessageMutation,
  SendMessageMutationVariables
>;
export const CreateScheduledMessageDocument = gql`
  mutation CreateScheduledMessage($input: CreateScheduledMessageInput!) {
    createScheduledMessage(input: $input) {
      id
      createdAt
      scheduledAt
      content
      attachments {
        ...Attachment
      }
    }
  }
  ${AttachmentFragmentDoc}
`;
export type CreateScheduledMessageMutationFn = Apollo.MutationFunction<
  CreateScheduledMessageMutation,
  CreateScheduledMessageMutationVariables
>;

/**
 * __useCreateScheduledMessageMutation__
 *
 * To run a mutation, you first call `useCreateScheduledMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledMessageMutation, { data, loading, error }] = useCreateScheduledMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScheduledMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScheduledMessageMutation,
    CreateScheduledMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScheduledMessageMutation,
    CreateScheduledMessageMutationVariables
  >(CreateScheduledMessageDocument, options);
}
export type CreateScheduledMessageMutationHookResult = ReturnType<
  typeof useCreateScheduledMessageMutation
>;
export type CreateScheduledMessageMutationResult =
  Apollo.MutationResult<CreateScheduledMessageMutation>;
export type CreateScheduledMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateScheduledMessageMutation,
  CreateScheduledMessageMutationVariables
>;
export const RemoveScheduledMessageDocument = gql`
  mutation RemoveScheduledMessage($id: String!) {
    removeScheduledMessage(id: $id) {
      success
    }
  }
`;
export type RemoveScheduledMessageMutationFn = Apollo.MutationFunction<
  RemoveScheduledMessageMutation,
  RemoveScheduledMessageMutationVariables
>;

/**
 * __useRemoveScheduledMessageMutation__
 *
 * To run a mutation, you first call `useRemoveScheduledMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveScheduledMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeScheduledMessageMutation, { data, loading, error }] = useRemoveScheduledMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveScheduledMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveScheduledMessageMutation,
    RemoveScheduledMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveScheduledMessageMutation,
    RemoveScheduledMessageMutationVariables
  >(RemoveScheduledMessageDocument, options);
}
export type RemoveScheduledMessageMutationHookResult = ReturnType<
  typeof useRemoveScheduledMessageMutation
>;
export type RemoveScheduledMessageMutationResult =
  Apollo.MutationResult<RemoveScheduledMessageMutation>;
export type RemoveScheduledMessageMutationOptions = Apollo.BaseMutationOptions<
  RemoveScheduledMessageMutation,
  RemoveScheduledMessageMutationVariables
>;
export const UpdateConversationOutgoingPhoneDocument = gql`
  mutation UpdateConversationOutgoingPhone(
    $input: UpdateConversationOutgoingPhoneInput!
  ) {
    updateConversationOutgoingPhone(input: $input) {
      success
    }
  }
`;
export type UpdateConversationOutgoingPhoneMutationFn = Apollo.MutationFunction<
  UpdateConversationOutgoingPhoneMutation,
  UpdateConversationOutgoingPhoneMutationVariables
>;

/**
 * __useUpdateConversationOutgoingPhoneMutation__
 *
 * To run a mutation, you first call `useUpdateConversationOutgoingPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConversationOutgoingPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConversationOutgoingPhoneMutation, { data, loading, error }] = useUpdateConversationOutgoingPhoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConversationOutgoingPhoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConversationOutgoingPhoneMutation,
    UpdateConversationOutgoingPhoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConversationOutgoingPhoneMutation,
    UpdateConversationOutgoingPhoneMutationVariables
  >(UpdateConversationOutgoingPhoneDocument, options);
}
export type UpdateConversationOutgoingPhoneMutationHookResult = ReturnType<
  typeof useUpdateConversationOutgoingPhoneMutation
>;
export type UpdateConversationOutgoingPhoneMutationResult =
  Apollo.MutationResult<UpdateConversationOutgoingPhoneMutation>;
export type UpdateConversationOutgoingPhoneMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateConversationOutgoingPhoneMutation,
    UpdateConversationOutgoingPhoneMutationVariables
  >;
export const ConversationUpdatedDocument = gql`
  subscription ConversationUpdated {
    conversationUpdated {
      entityId
      operation
    }
  }
`;

/**
 * __useConversationUpdatedSubscription__
 *
 * To run a query within a React component, call `useConversationUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConversationUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useConversationUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ConversationUpdatedSubscription,
    ConversationUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ConversationUpdatedSubscription,
    ConversationUpdatedSubscriptionVariables
  >(ConversationUpdatedDocument, options);
}
export type ConversationUpdatedSubscriptionHookResult = ReturnType<
  typeof useConversationUpdatedSubscription
>;
export type ConversationUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<ConversationUpdatedSubscription>;
export const MessageCreatedDocument = gql`
  subscription MessageCreated($contactId: String!) {
    messageCreated(contactId: $contactId) {
      entityId
    }
  }
`;

/**
 * __useMessageCreatedSubscription__
 *
 * To run a query within a React component, call `useMessageCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageCreatedSubscription({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useMessageCreatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    MessageCreatedSubscription,
    MessageCreatedSubscriptionVariables
  > &
    (
      | { variables: MessageCreatedSubscriptionVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MessageCreatedSubscription,
    MessageCreatedSubscriptionVariables
  >(MessageCreatedDocument, options);
}
export type MessageCreatedSubscriptionHookResult = ReturnType<
  typeof useMessageCreatedSubscription
>;
export type MessageCreatedSubscriptionResult =
  Apollo.SubscriptionResult<MessageCreatedSubscription>;
export const MessageUpdatedDocument = gql`
  subscription MessageUpdated($contactId: String!) {
    messageUpdated(contactId: $contactId) {
      entityId
    }
  }
`;

/**
 * __useMessageUpdatedSubscription__
 *
 * To run a query within a React component, call `useMessageUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageUpdatedSubscription({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useMessageUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    MessageUpdatedSubscription,
    MessageUpdatedSubscriptionVariables
  > &
    (
      | { variables: MessageUpdatedSubscriptionVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MessageUpdatedSubscription,
    MessageUpdatedSubscriptionVariables
  >(MessageUpdatedDocument, options);
}
export type MessageUpdatedSubscriptionHookResult = ReturnType<
  typeof useMessageUpdatedSubscription
>;
export type MessageUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<MessageUpdatedSubscription>;
export const IncomingMessageReceivedDocument = gql`
  subscription IncomingMessageReceived {
    incomingMessageReceived {
      profileId
      status
      conversationId
      type
      statusReason
      contentType
      id
    }
  }
`;

/**
 * __useIncomingMessageReceivedSubscription__
 *
 * To run a query within a React component, call `useIncomingMessageReceivedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIncomingMessageReceivedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomingMessageReceivedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useIncomingMessageReceivedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    IncomingMessageReceivedSubscription,
    IncomingMessageReceivedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    IncomingMessageReceivedSubscription,
    IncomingMessageReceivedSubscriptionVariables
  >(IncomingMessageReceivedDocument, options);
}
export type IncomingMessageReceivedSubscriptionHookResult = ReturnType<
  typeof useIncomingMessageReceivedSubscription
>;
export type IncomingMessageReceivedSubscriptionResult =
  Apollo.SubscriptionResult<IncomingMessageReceivedSubscription>;
export const UnreadConversationsMessagesCounterUpdatedDocument = gql`
  subscription UnreadConversationsMessagesCounterUpdated($profileId: String) {
    unreadConversationsMessagesCounterUpdated(profileId: $profileId) {
      counter
      profileId
    }
  }
`;

/**
 * __useUnreadConversationsMessagesCounterUpdatedSubscription__
 *
 * To run a query within a React component, call `useUnreadConversationsMessagesCounterUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUnreadConversationsMessagesCounterUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadConversationsMessagesCounterUpdatedSubscription({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useUnreadConversationsMessagesCounterUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    UnreadConversationsMessagesCounterUpdatedSubscription,
    UnreadConversationsMessagesCounterUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    UnreadConversationsMessagesCounterUpdatedSubscription,
    UnreadConversationsMessagesCounterUpdatedSubscriptionVariables
  >(UnreadConversationsMessagesCounterUpdatedDocument, options);
}
export type UnreadConversationsMessagesCounterUpdatedSubscriptionHookResult =
  ReturnType<typeof useUnreadConversationsMessagesCounterUpdatedSubscription>;
export type UnreadConversationsMessagesCounterUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<UnreadConversationsMessagesCounterUpdatedSubscription>;
export const MissedConversationsCallsCounterUpdatedDocument = gql`
  subscription MissedConversationsCallsCounterUpdated($profileId: String) {
    missedConversationsCallsCounterUpdated(profileId: $profileId) {
      counter
      profileId
    }
  }
`;

/**
 * __useMissedConversationsCallsCounterUpdatedSubscription__
 *
 * To run a query within a React component, call `useMissedConversationsCallsCounterUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMissedConversationsCallsCounterUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMissedConversationsCallsCounterUpdatedSubscription({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useMissedConversationsCallsCounterUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    MissedConversationsCallsCounterUpdatedSubscription,
    MissedConversationsCallsCounterUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MissedConversationsCallsCounterUpdatedSubscription,
    MissedConversationsCallsCounterUpdatedSubscriptionVariables
  >(MissedConversationsCallsCounterUpdatedDocument, options);
}
export type MissedConversationsCallsCounterUpdatedSubscriptionHookResult =
  ReturnType<typeof useMissedConversationsCallsCounterUpdatedSubscription>;
export type MissedConversationsCallsCounterUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<MissedConversationsCallsCounterUpdatedSubscription>;
export const GenerateWebRtcTokenDocument = gql`
  query GenerateWebRTCToken {
    generateWebRTCToken {
      expiresAt
      token
    }
  }
`;

/**
 * __useGenerateWebRtcTokenQuery__
 *
 * To run a query within a React component, call `useGenerateWebRtcTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateWebRtcTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateWebRtcTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerateWebRtcTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GenerateWebRtcTokenQuery,
    GenerateWebRtcTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GenerateWebRtcTokenQuery,
    GenerateWebRtcTokenQueryVariables
  >(GenerateWebRtcTokenDocument, options);
}
export function useGenerateWebRtcTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateWebRtcTokenQuery,
    GenerateWebRtcTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GenerateWebRtcTokenQuery,
    GenerateWebRtcTokenQueryVariables
  >(GenerateWebRtcTokenDocument, options);
}
export function useGenerateWebRtcTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GenerateWebRtcTokenQuery,
        GenerateWebRtcTokenQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GenerateWebRtcTokenQuery,
    GenerateWebRtcTokenQueryVariables
  >(GenerateWebRtcTokenDocument, options);
}
export type GenerateWebRtcTokenQueryHookResult = ReturnType<
  typeof useGenerateWebRtcTokenQuery
>;
export type GenerateWebRtcTokenLazyQueryHookResult = ReturnType<
  typeof useGenerateWebRtcTokenLazyQuery
>;
export type GenerateWebRtcTokenSuspenseQueryHookResult = ReturnType<
  typeof useGenerateWebRtcTokenSuspenseQuery
>;
export type GenerateWebRtcTokenQueryResult = Apollo.QueryResult<
  GenerateWebRtcTokenQuery,
  GenerateWebRtcTokenQueryVariables
>;
export const GetInfobipConfigDocument = gql`
  query GetInfobipConfig {
    getInfobipConfig {
      applicationId
      callsConfigId
    }
  }
`;

/**
 * __useGetInfobipConfigQuery__
 *
 * To run a query within a React component, call `useGetInfobipConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfobipConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfobipConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInfobipConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInfobipConfigQuery,
    GetInfobipConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInfobipConfigQuery, GetInfobipConfigQueryVariables>(
    GetInfobipConfigDocument,
    options,
  );
}
export function useGetInfobipConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInfobipConfigQuery,
    GetInfobipConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInfobipConfigQuery,
    GetInfobipConfigQueryVariables
  >(GetInfobipConfigDocument, options);
}
export function useGetInfobipConfigSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetInfobipConfigQuery,
        GetInfobipConfigQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetInfobipConfigQuery,
    GetInfobipConfigQueryVariables
  >(GetInfobipConfigDocument, options);
}
export type GetInfobipConfigQueryHookResult = ReturnType<
  typeof useGetInfobipConfigQuery
>;
export type GetInfobipConfigLazyQueryHookResult = ReturnType<
  typeof useGetInfobipConfigLazyQuery
>;
export type GetInfobipConfigSuspenseQueryHookResult = ReturnType<
  typeof useGetInfobipConfigSuspenseQuery
>;
export type GetInfobipConfigQueryResult = Apollo.QueryResult<
  GetInfobipConfigQuery,
  GetInfobipConfigQueryVariables
>;
export const FindDialerCampaignsDocument = gql`
  query FindDialerCampaigns(
    $filter: DialerCampaignFilter
    $pagination: Pagination
  ) {
    findDialerCampaigns(pagination: $pagination, filter: $filter) {
      items {
        ...DialerCampaignFragment
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${DialerCampaignFragmentFragmentDoc}
`;

/**
 * __useFindDialerCampaignsQuery__
 *
 * To run a query within a React component, call `useFindDialerCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDialerCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDialerCampaignsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindDialerCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindDialerCampaignsQuery,
    FindDialerCampaignsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindDialerCampaignsQuery,
    FindDialerCampaignsQueryVariables
  >(FindDialerCampaignsDocument, options);
}
export function useFindDialerCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindDialerCampaignsQuery,
    FindDialerCampaignsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindDialerCampaignsQuery,
    FindDialerCampaignsQueryVariables
  >(FindDialerCampaignsDocument, options);
}
export function useFindDialerCampaignsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindDialerCampaignsQuery,
        FindDialerCampaignsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindDialerCampaignsQuery,
    FindDialerCampaignsQueryVariables
  >(FindDialerCampaignsDocument, options);
}
export type FindDialerCampaignsQueryHookResult = ReturnType<
  typeof useFindDialerCampaignsQuery
>;
export type FindDialerCampaignsLazyQueryHookResult = ReturnType<
  typeof useFindDialerCampaignsLazyQuery
>;
export type FindDialerCampaignsSuspenseQueryHookResult = ReturnType<
  typeof useFindDialerCampaignsSuspenseQuery
>;
export type FindDialerCampaignsQueryResult = Apollo.QueryResult<
  FindDialerCampaignsQuery,
  FindDialerCampaignsQueryVariables
>;
export const FindListDialerCampaignsDocument = gql`
  query FindListDialerCampaigns(
    $filter: DialerCampaignFilter
    $pagination: Pagination
  ) {
    findDialerCampaigns(pagination: $pagination, filter: $filter) {
      items {
        id
        name
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
`;

/**
 * __useFindListDialerCampaignsQuery__
 *
 * To run a query within a React component, call `useFindListDialerCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindListDialerCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindListDialerCampaignsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindListDialerCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindListDialerCampaignsQuery,
    FindListDialerCampaignsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindListDialerCampaignsQuery,
    FindListDialerCampaignsQueryVariables
  >(FindListDialerCampaignsDocument, options);
}
export function useFindListDialerCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindListDialerCampaignsQuery,
    FindListDialerCampaignsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindListDialerCampaignsQuery,
    FindListDialerCampaignsQueryVariables
  >(FindListDialerCampaignsDocument, options);
}
export function useFindListDialerCampaignsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindListDialerCampaignsQuery,
        FindListDialerCampaignsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindListDialerCampaignsQuery,
    FindListDialerCampaignsQueryVariables
  >(FindListDialerCampaignsDocument, options);
}
export type FindListDialerCampaignsQueryHookResult = ReturnType<
  typeof useFindListDialerCampaignsQuery
>;
export type FindListDialerCampaignsLazyQueryHookResult = ReturnType<
  typeof useFindListDialerCampaignsLazyQuery
>;
export type FindListDialerCampaignsSuspenseQueryHookResult = ReturnType<
  typeof useFindListDialerCampaignsSuspenseQuery
>;
export type FindListDialerCampaignsQueryResult = Apollo.QueryResult<
  FindListDialerCampaignsQuery,
  FindListDialerCampaignsQueryVariables
>;
export const GetDialerCampaignsTotalDocument = gql`
  query GetDialerCampaignsTotal(
    $filter: DialerCampaignFilter
    $pagination: Pagination
  ) {
    findDialerCampaigns(pagination: $pagination, filter: $filter) {
      total
    }
  }
`;

/**
 * __useGetDialerCampaignsTotalQuery__
 *
 * To run a query within a React component, call `useGetDialerCampaignsTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDialerCampaignsTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDialerCampaignsTotalQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetDialerCampaignsTotalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDialerCampaignsTotalQuery,
    GetDialerCampaignsTotalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDialerCampaignsTotalQuery,
    GetDialerCampaignsTotalQueryVariables
  >(GetDialerCampaignsTotalDocument, options);
}
export function useGetDialerCampaignsTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDialerCampaignsTotalQuery,
    GetDialerCampaignsTotalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDialerCampaignsTotalQuery,
    GetDialerCampaignsTotalQueryVariables
  >(GetDialerCampaignsTotalDocument, options);
}
export function useGetDialerCampaignsTotalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetDialerCampaignsTotalQuery,
        GetDialerCampaignsTotalQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDialerCampaignsTotalQuery,
    GetDialerCampaignsTotalQueryVariables
  >(GetDialerCampaignsTotalDocument, options);
}
export type GetDialerCampaignsTotalQueryHookResult = ReturnType<
  typeof useGetDialerCampaignsTotalQuery
>;
export type GetDialerCampaignsTotalLazyQueryHookResult = ReturnType<
  typeof useGetDialerCampaignsTotalLazyQuery
>;
export type GetDialerCampaignsTotalSuspenseQueryHookResult = ReturnType<
  typeof useGetDialerCampaignsTotalSuspenseQuery
>;
export type GetDialerCampaignsTotalQueryResult = Apollo.QueryResult<
  GetDialerCampaignsTotalQuery,
  GetDialerCampaignsTotalQueryVariables
>;
export const GetDialerCampaignsCountersDocument = gql`
  query GetDialerCampaignsCounters {
    getDialerCampaignsCounters {
      dialer {
        active
        draft
        deleted
        total
      }
    }
  }
`;

/**
 * __useGetDialerCampaignsCountersQuery__
 *
 * To run a query within a React component, call `useGetDialerCampaignsCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDialerCampaignsCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDialerCampaignsCountersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDialerCampaignsCountersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDialerCampaignsCountersQuery,
    GetDialerCampaignsCountersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDialerCampaignsCountersQuery,
    GetDialerCampaignsCountersQueryVariables
  >(GetDialerCampaignsCountersDocument, options);
}
export function useGetDialerCampaignsCountersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDialerCampaignsCountersQuery,
    GetDialerCampaignsCountersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDialerCampaignsCountersQuery,
    GetDialerCampaignsCountersQueryVariables
  >(GetDialerCampaignsCountersDocument, options);
}
export function useGetDialerCampaignsCountersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetDialerCampaignsCountersQuery,
        GetDialerCampaignsCountersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDialerCampaignsCountersQuery,
    GetDialerCampaignsCountersQueryVariables
  >(GetDialerCampaignsCountersDocument, options);
}
export type GetDialerCampaignsCountersQueryHookResult = ReturnType<
  typeof useGetDialerCampaignsCountersQuery
>;
export type GetDialerCampaignsCountersLazyQueryHookResult = ReturnType<
  typeof useGetDialerCampaignsCountersLazyQuery
>;
export type GetDialerCampaignsCountersSuspenseQueryHookResult = ReturnType<
  typeof useGetDialerCampaignsCountersSuspenseQuery
>;
export type GetDialerCampaignsCountersQueryResult = Apollo.QueryResult<
  GetDialerCampaignsCountersQuery,
  GetDialerCampaignsCountersQueryVariables
>;
export const GetDialerCampaignDocument = gql`
  query GetDialerCampaign($id: String!) {
    getDialerCampaign(id: $id) {
      ...DialerCampaignDetailedFragment
    }
  }
  ${DialerCampaignDetailedFragmentFragmentDoc}
`;

/**
 * __useGetDialerCampaignQuery__
 *
 * To run a query within a React component, call `useGetDialerCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDialerCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDialerCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDialerCampaignQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDialerCampaignQuery,
    GetDialerCampaignQueryVariables
  > &
    (
      | { variables: GetDialerCampaignQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDialerCampaignQuery,
    GetDialerCampaignQueryVariables
  >(GetDialerCampaignDocument, options);
}
export function useGetDialerCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDialerCampaignQuery,
    GetDialerCampaignQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDialerCampaignQuery,
    GetDialerCampaignQueryVariables
  >(GetDialerCampaignDocument, options);
}
export function useGetDialerCampaignSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetDialerCampaignQuery,
        GetDialerCampaignQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDialerCampaignQuery,
    GetDialerCampaignQueryVariables
  >(GetDialerCampaignDocument, options);
}
export type GetDialerCampaignQueryHookResult = ReturnType<
  typeof useGetDialerCampaignQuery
>;
export type GetDialerCampaignLazyQueryHookResult = ReturnType<
  typeof useGetDialerCampaignLazyQuery
>;
export type GetDialerCampaignSuspenseQueryHookResult = ReturnType<
  typeof useGetDialerCampaignSuspenseQuery
>;
export type GetDialerCampaignQueryResult = Apollo.QueryResult<
  GetDialerCampaignQuery,
  GetDialerCampaignQueryVariables
>;
export const ExportDialerCampaignToFileDocument = gql`
  query ExportDialerCampaignToFile($input: ExportDialerCampaignToFileInput!) {
    exportDialerCampaignToFile(input: $input) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useExportDialerCampaignToFileQuery__
 *
 * To run a query within a React component, call `useExportDialerCampaignToFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportDialerCampaignToFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportDialerCampaignToFileQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportDialerCampaignToFileQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportDialerCampaignToFileQuery,
    ExportDialerCampaignToFileQueryVariables
  > &
    (
      | { variables: ExportDialerCampaignToFileQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExportDialerCampaignToFileQuery,
    ExportDialerCampaignToFileQueryVariables
  >(ExportDialerCampaignToFileDocument, options);
}
export function useExportDialerCampaignToFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportDialerCampaignToFileQuery,
    ExportDialerCampaignToFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExportDialerCampaignToFileQuery,
    ExportDialerCampaignToFileQueryVariables
  >(ExportDialerCampaignToFileDocument, options);
}
export function useExportDialerCampaignToFileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExportDialerCampaignToFileQuery,
        ExportDialerCampaignToFileQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExportDialerCampaignToFileQuery,
    ExportDialerCampaignToFileQueryVariables
  >(ExportDialerCampaignToFileDocument, options);
}
export type ExportDialerCampaignToFileQueryHookResult = ReturnType<
  typeof useExportDialerCampaignToFileQuery
>;
export type ExportDialerCampaignToFileLazyQueryHookResult = ReturnType<
  typeof useExportDialerCampaignToFileLazyQuery
>;
export type ExportDialerCampaignToFileSuspenseQueryHookResult = ReturnType<
  typeof useExportDialerCampaignToFileSuspenseQuery
>;
export type ExportDialerCampaignToFileQueryResult = Apollo.QueryResult<
  ExportDialerCampaignToFileQuery,
  ExportDialerCampaignToFileQueryVariables
>;
export const CreateDialerCampaignDocument = gql`
  mutation CreateDialerCampaign($input: CreateDialerCampaignInput!) {
    createDialerCampaign(input: $input) {
      ...DialerCampaignFragment
    }
  }
  ${DialerCampaignFragmentFragmentDoc}
`;
export type CreateDialerCampaignMutationFn = Apollo.MutationFunction<
  CreateDialerCampaignMutation,
  CreateDialerCampaignMutationVariables
>;

/**
 * __useCreateDialerCampaignMutation__
 *
 * To run a mutation, you first call `useCreateDialerCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDialerCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDialerCampaignMutation, { data, loading, error }] = useCreateDialerCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDialerCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDialerCampaignMutation,
    CreateDialerCampaignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDialerCampaignMutation,
    CreateDialerCampaignMutationVariables
  >(CreateDialerCampaignDocument, options);
}
export type CreateDialerCampaignMutationHookResult = ReturnType<
  typeof useCreateDialerCampaignMutation
>;
export type CreateDialerCampaignMutationResult =
  Apollo.MutationResult<CreateDialerCampaignMutation>;
export type CreateDialerCampaignMutationOptions = Apollo.BaseMutationOptions<
  CreateDialerCampaignMutation,
  CreateDialerCampaignMutationVariables
>;
export const RemoveDialerCampaignDocument = gql`
  mutation RemoveDialerCampaign($input: RemoveDialerCampaignInput!) {
    removeDialerCampaign(input: $input) {
      success
    }
  }
`;
export type RemoveDialerCampaignMutationFn = Apollo.MutationFunction<
  RemoveDialerCampaignMutation,
  RemoveDialerCampaignMutationVariables
>;

/**
 * __useRemoveDialerCampaignMutation__
 *
 * To run a mutation, you first call `useRemoveDialerCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDialerCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDialerCampaignMutation, { data, loading, error }] = useRemoveDialerCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDialerCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveDialerCampaignMutation,
    RemoveDialerCampaignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveDialerCampaignMutation,
    RemoveDialerCampaignMutationVariables
  >(RemoveDialerCampaignDocument, options);
}
export type RemoveDialerCampaignMutationHookResult = ReturnType<
  typeof useRemoveDialerCampaignMutation
>;
export type RemoveDialerCampaignMutationResult =
  Apollo.MutationResult<RemoveDialerCampaignMutation>;
export type RemoveDialerCampaignMutationOptions = Apollo.BaseMutationOptions<
  RemoveDialerCampaignMutation,
  RemoveDialerCampaignMutationVariables
>;
export const StartDialerCampaignDocument = gql`
  mutation StartDialerCampaign($id: String!) {
    startDialerCampaign(id: $id) {
      success
    }
  }
`;
export type StartDialerCampaignMutationFn = Apollo.MutationFunction<
  StartDialerCampaignMutation,
  StartDialerCampaignMutationVariables
>;

/**
 * __useStartDialerCampaignMutation__
 *
 * To run a mutation, you first call `useStartDialerCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartDialerCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startDialerCampaignMutation, { data, loading, error }] = useStartDialerCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartDialerCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartDialerCampaignMutation,
    StartDialerCampaignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartDialerCampaignMutation,
    StartDialerCampaignMutationVariables
  >(StartDialerCampaignDocument, options);
}
export type StartDialerCampaignMutationHookResult = ReturnType<
  typeof useStartDialerCampaignMutation
>;
export type StartDialerCampaignMutationResult =
  Apollo.MutationResult<StartDialerCampaignMutation>;
export type StartDialerCampaignMutationOptions = Apollo.BaseMutationOptions<
  StartDialerCampaignMutation,
  StartDialerCampaignMutationVariables
>;
export const PauseDialerCampaignDocument = gql`
  mutation PauseDialerCampaign($id: String!) {
    pauseDialerCampaign(id: $id) {
      success
    }
  }
`;
export type PauseDialerCampaignMutationFn = Apollo.MutationFunction<
  PauseDialerCampaignMutation,
  PauseDialerCampaignMutationVariables
>;

/**
 * __usePauseDialerCampaignMutation__
 *
 * To run a mutation, you first call `usePauseDialerCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseDialerCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseDialerCampaignMutation, { data, loading, error }] = usePauseDialerCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePauseDialerCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PauseDialerCampaignMutation,
    PauseDialerCampaignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PauseDialerCampaignMutation,
    PauseDialerCampaignMutationVariables
  >(PauseDialerCampaignDocument, options);
}
export type PauseDialerCampaignMutationHookResult = ReturnType<
  typeof usePauseDialerCampaignMutation
>;
export type PauseDialerCampaignMutationResult =
  Apollo.MutationResult<PauseDialerCampaignMutation>;
export type PauseDialerCampaignMutationOptions = Apollo.BaseMutationOptions<
  PauseDialerCampaignMutation,
  PauseDialerCampaignMutationVariables
>;
export const ResumeDialerCampaignDocument = gql`
  mutation ResumeDialerCampaign($id: String!) {
    resumeDialerCampaign(id: $id) {
      success
    }
  }
`;
export type ResumeDialerCampaignMutationFn = Apollo.MutationFunction<
  ResumeDialerCampaignMutation,
  ResumeDialerCampaignMutationVariables
>;

/**
 * __useResumeDialerCampaignMutation__
 *
 * To run a mutation, you first call `useResumeDialerCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeDialerCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeDialerCampaignMutation, { data, loading, error }] = useResumeDialerCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResumeDialerCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResumeDialerCampaignMutation,
    ResumeDialerCampaignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResumeDialerCampaignMutation,
    ResumeDialerCampaignMutationVariables
  >(ResumeDialerCampaignDocument, options);
}
export type ResumeDialerCampaignMutationHookResult = ReturnType<
  typeof useResumeDialerCampaignMutation
>;
export type ResumeDialerCampaignMutationResult =
  Apollo.MutationResult<ResumeDialerCampaignMutation>;
export type ResumeDialerCampaignMutationOptions = Apollo.BaseMutationOptions<
  ResumeDialerCampaignMutation,
  ResumeDialerCampaignMutationVariables
>;
export const ExportDialerCampaignToGroupDocument = gql`
  mutation ExportDialerCampaignToGroup(
    $input: ExportDialerCampaignToGroupInput!
    $id: String!
  ) {
    exportDialerCampaignToGroup(input: $input, id: $id) {
      id
    }
  }
`;
export type ExportDialerCampaignToGroupMutationFn = Apollo.MutationFunction<
  ExportDialerCampaignToGroupMutation,
  ExportDialerCampaignToGroupMutationVariables
>;

/**
 * __useExportDialerCampaignToGroupMutation__
 *
 * To run a mutation, you first call `useExportDialerCampaignToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDialerCampaignToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDialerCampaignToGroupMutation, { data, loading, error }] = useExportDialerCampaignToGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportDialerCampaignToGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportDialerCampaignToGroupMutation,
    ExportDialerCampaignToGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportDialerCampaignToGroupMutation,
    ExportDialerCampaignToGroupMutationVariables
  >(ExportDialerCampaignToGroupDocument, options);
}
export type ExportDialerCampaignToGroupMutationHookResult = ReturnType<
  typeof useExportDialerCampaignToGroupMutation
>;
export type ExportDialerCampaignToGroupMutationResult =
  Apollo.MutationResult<ExportDialerCampaignToGroupMutation>;
export type ExportDialerCampaignToGroupMutationOptions =
  Apollo.BaseMutationOptions<
    ExportDialerCampaignToGroupMutation,
    ExportDialerCampaignToGroupMutationVariables
  >;
export const UpdateDialerCampaignDocument = gql`
  mutation UpdateDialerCampaign(
    $input: UpdateDialerCampaignInput!
    $updateDialerCampaignId: String!
  ) {
    updateDialerCampaign(input: $input, id: $updateDialerCampaignId) {
      id
    }
  }
`;
export type UpdateDialerCampaignMutationFn = Apollo.MutationFunction<
  UpdateDialerCampaignMutation,
  UpdateDialerCampaignMutationVariables
>;

/**
 * __useUpdateDialerCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateDialerCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDialerCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDialerCampaignMutation, { data, loading, error }] = useUpdateDialerCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *      updateDialerCampaignId: // value for 'updateDialerCampaignId'
 *   },
 * });
 */
export function useUpdateDialerCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDialerCampaignMutation,
    UpdateDialerCampaignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDialerCampaignMutation,
    UpdateDialerCampaignMutationVariables
  >(UpdateDialerCampaignDocument, options);
}
export type UpdateDialerCampaignMutationHookResult = ReturnType<
  typeof useUpdateDialerCampaignMutation
>;
export type UpdateDialerCampaignMutationResult =
  Apollo.MutationResult<UpdateDialerCampaignMutation>;
export type UpdateDialerCampaignMutationOptions = Apollo.BaseMutationOptions<
  UpdateDialerCampaignMutation,
  UpdateDialerCampaignMutationVariables
>;
export const ExportConversationsToFileDocument = gql`
  query ExportConversationsToFile($input: ExportConversationsToFileInput!) {
    exportConversationsToFile(input: $input) {
      filePath
      signedUrl
    }
  }
`;

/**
 * __useExportConversationsToFileQuery__
 *
 * To run a query within a React component, call `useExportConversationsToFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportConversationsToFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportConversationsToFileQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportConversationsToFileQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportConversationsToFileQuery,
    ExportConversationsToFileQueryVariables
  > &
    (
      | { variables: ExportConversationsToFileQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExportConversationsToFileQuery,
    ExportConversationsToFileQueryVariables
  >(ExportConversationsToFileDocument, options);
}
export function useExportConversationsToFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportConversationsToFileQuery,
    ExportConversationsToFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExportConversationsToFileQuery,
    ExportConversationsToFileQueryVariables
  >(ExportConversationsToFileDocument, options);
}
export function useExportConversationsToFileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExportConversationsToFileQuery,
        ExportConversationsToFileQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExportConversationsToFileQuery,
    ExportConversationsToFileQueryVariables
  >(ExportConversationsToFileDocument, options);
}
export type ExportConversationsToFileQueryHookResult = ReturnType<
  typeof useExportConversationsToFileQuery
>;
export type ExportConversationsToFileLazyQueryHookResult = ReturnType<
  typeof useExportConversationsToFileLazyQuery
>;
export type ExportConversationsToFileSuspenseQueryHookResult = ReturnType<
  typeof useExportConversationsToFileSuspenseQuery
>;
export type ExportConversationsToFileQueryResult = Apollo.QueryResult<
  ExportConversationsToFileQuery,
  ExportConversationsToFileQueryVariables
>;
export const FindLastFeedbackDocument = gql`
  query FindLastFeedback($source: FeedbackSource!) {
    findLastFeedback(source: $source) {
      id
      score
      createdAt
      source
      message
    }
  }
`;

/**
 * __useFindLastFeedbackQuery__
 *
 * To run a query within a React component, call `useFindLastFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLastFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLastFeedbackQuery({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useFindLastFeedbackQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindLastFeedbackQuery,
    FindLastFeedbackQueryVariables
  > &
    (
      | { variables: FindLastFeedbackQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindLastFeedbackQuery, FindLastFeedbackQueryVariables>(
    FindLastFeedbackDocument,
    options,
  );
}
export function useFindLastFeedbackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindLastFeedbackQuery,
    FindLastFeedbackQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindLastFeedbackQuery,
    FindLastFeedbackQueryVariables
  >(FindLastFeedbackDocument, options);
}
export function useFindLastFeedbackSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindLastFeedbackQuery,
        FindLastFeedbackQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindLastFeedbackQuery,
    FindLastFeedbackQueryVariables
  >(FindLastFeedbackDocument, options);
}
export type FindLastFeedbackQueryHookResult = ReturnType<
  typeof useFindLastFeedbackQuery
>;
export type FindLastFeedbackLazyQueryHookResult = ReturnType<
  typeof useFindLastFeedbackLazyQuery
>;
export type FindLastFeedbackSuspenseQueryHookResult = ReturnType<
  typeof useFindLastFeedbackSuspenseQuery
>;
export type FindLastFeedbackQueryResult = Apollo.QueryResult<
  FindLastFeedbackQuery,
  FindLastFeedbackQueryVariables
>;
export const CreateFeedbackDocument = gql`
  mutation CreateFeedback($input: CreateFeedbackInput!) {
    createFeedback(input: $input) {
      success
    }
  }
`;
export type CreateFeedbackMutationFn = Apollo.MutationFunction<
  CreateFeedbackMutation,
  CreateFeedbackMutationVariables
>;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFeedbackMutation,
    CreateFeedbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFeedbackMutation,
    CreateFeedbackMutationVariables
  >(CreateFeedbackDocument, options);
}
export type CreateFeedbackMutationHookResult = ReturnType<
  typeof useCreateFeedbackMutation
>;
export type CreateFeedbackMutationResult =
  Apollo.MutationResult<CreateFeedbackMutation>;
export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<
  CreateFeedbackMutation,
  CreateFeedbackMutationVariables
>;
export const FindFieldsTemplatesDocument = gql`
  query FindFieldsTemplates {
    findFieldsTemplates {
      items {
        ...FieldsTemplate
      }
    }
  }
  ${FieldsTemplateFragmentDoc}
`;

/**
 * __useFindFieldsTemplatesQuery__
 *
 * To run a query within a React component, call `useFindFieldsTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFieldsTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFieldsTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindFieldsTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindFieldsTemplatesQuery,
    FindFieldsTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindFieldsTemplatesQuery,
    FindFieldsTemplatesQueryVariables
  >(FindFieldsTemplatesDocument, options);
}
export function useFindFieldsTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindFieldsTemplatesQuery,
    FindFieldsTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindFieldsTemplatesQuery,
    FindFieldsTemplatesQueryVariables
  >(FindFieldsTemplatesDocument, options);
}
export function useFindFieldsTemplatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindFieldsTemplatesQuery,
        FindFieldsTemplatesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindFieldsTemplatesQuery,
    FindFieldsTemplatesQueryVariables
  >(FindFieldsTemplatesDocument, options);
}
export type FindFieldsTemplatesQueryHookResult = ReturnType<
  typeof useFindFieldsTemplatesQuery
>;
export type FindFieldsTemplatesLazyQueryHookResult = ReturnType<
  typeof useFindFieldsTemplatesLazyQuery
>;
export type FindFieldsTemplatesSuspenseQueryHookResult = ReturnType<
  typeof useFindFieldsTemplatesSuspenseQuery
>;
export type FindFieldsTemplatesQueryResult = Apollo.QueryResult<
  FindFieldsTemplatesQuery,
  FindFieldsTemplatesQueryVariables
>;
export const CreateFieldsTemplateDocument = gql`
  mutation CreateFieldsTemplate($input: CreateFieldsTemplateInput!) {
    createFieldsTemplate(input: $input) {
      ...FieldsTemplate
    }
  }
  ${FieldsTemplateFragmentDoc}
`;
export type CreateFieldsTemplateMutationFn = Apollo.MutationFunction<
  CreateFieldsTemplateMutation,
  CreateFieldsTemplateMutationVariables
>;

/**
 * __useCreateFieldsTemplateMutation__
 *
 * To run a mutation, you first call `useCreateFieldsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFieldsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFieldsTemplateMutation, { data, loading, error }] = useCreateFieldsTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFieldsTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFieldsTemplateMutation,
    CreateFieldsTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFieldsTemplateMutation,
    CreateFieldsTemplateMutationVariables
  >(CreateFieldsTemplateDocument, options);
}
export type CreateFieldsTemplateMutationHookResult = ReturnType<
  typeof useCreateFieldsTemplateMutation
>;
export type CreateFieldsTemplateMutationResult =
  Apollo.MutationResult<CreateFieldsTemplateMutation>;
export type CreateFieldsTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateFieldsTemplateMutation,
  CreateFieldsTemplateMutationVariables
>;
export const RemoveFieldsTemplateDocument = gql`
  mutation RemoveFieldsTemplate($id: String!) {
    removeFieldsTemplate(id: $id) {
      success
    }
  }
`;
export type RemoveFieldsTemplateMutationFn = Apollo.MutationFunction<
  RemoveFieldsTemplateMutation,
  RemoveFieldsTemplateMutationVariables
>;

/**
 * __useRemoveFieldsTemplateMutation__
 *
 * To run a mutation, you first call `useRemoveFieldsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFieldsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFieldsTemplateMutation, { data, loading, error }] = useRemoveFieldsTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFieldsTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFieldsTemplateMutation,
    RemoveFieldsTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveFieldsTemplateMutation,
    RemoveFieldsTemplateMutationVariables
  >(RemoveFieldsTemplateDocument, options);
}
export type RemoveFieldsTemplateMutationHookResult = ReturnType<
  typeof useRemoveFieldsTemplateMutation
>;
export type RemoveFieldsTemplateMutationResult =
  Apollo.MutationResult<RemoveFieldsTemplateMutation>;
export type RemoveFieldsTemplateMutationOptions = Apollo.BaseMutationOptions<
  RemoveFieldsTemplateMutation,
  RemoveFieldsTemplateMutationVariables
>;
export const FindFieldsDocument = gql`
  query FindFields($filter: FieldsFilter) {
    findFields(filter: $filter) {
      ...Field
    }
  }
  ${FieldFragmentDoc}
`;

/**
 * __useFindFieldsQuery__
 *
 * To run a query within a React component, call `useFindFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFieldsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindFieldsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindFieldsQuery,
    FindFieldsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindFieldsQuery, FindFieldsQueryVariables>(
    FindFieldsDocument,
    options,
  );
}
export function useFindFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindFieldsQuery,
    FindFieldsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindFieldsQuery, FindFieldsQueryVariables>(
    FindFieldsDocument,
    options,
  );
}
export function useFindFieldsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindFieldsQuery,
        FindFieldsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindFieldsQuery, FindFieldsQueryVariables>(
    FindFieldsDocument,
    options,
  );
}
export type FindFieldsQueryHookResult = ReturnType<typeof useFindFieldsQuery>;
export type FindFieldsLazyQueryHookResult = ReturnType<
  typeof useFindFieldsLazyQuery
>;
export type FindFieldsSuspenseQueryHookResult = ReturnType<
  typeof useFindFieldsSuspenseQuery
>;
export type FindFieldsQueryResult = Apollo.QueryResult<
  FindFieldsQuery,
  FindFieldsQueryVariables
>;
export const FindMergeTagsDocument = gql`
  query FindMergeTags {
    findMergeTags {
      id
      key
      predefined
      name
      mergeTag
    }
  }
`;

/**
 * __useFindMergeTagsQuery__
 *
 * To run a query within a React component, call `useFindMergeTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMergeTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMergeTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindMergeTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindMergeTagsQuery,
    FindMergeTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindMergeTagsQuery, FindMergeTagsQueryVariables>(
    FindMergeTagsDocument,
    options,
  );
}
export function useFindMergeTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindMergeTagsQuery,
    FindMergeTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindMergeTagsQuery, FindMergeTagsQueryVariables>(
    FindMergeTagsDocument,
    options,
  );
}
export function useFindMergeTagsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindMergeTagsQuery,
        FindMergeTagsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindMergeTagsQuery,
    FindMergeTagsQueryVariables
  >(FindMergeTagsDocument, options);
}
export type FindMergeTagsQueryHookResult = ReturnType<
  typeof useFindMergeTagsQuery
>;
export type FindMergeTagsLazyQueryHookResult = ReturnType<
  typeof useFindMergeTagsLazyQuery
>;
export type FindMergeTagsSuspenseQueryHookResult = ReturnType<
  typeof useFindMergeTagsSuspenseQuery
>;
export type FindMergeTagsQueryResult = Apollo.QueryResult<
  FindMergeTagsQuery,
  FindMergeTagsQueryVariables
>;
export const CreateFieldDocument = gql`
  mutation CreateField($input: CreateFieldInput!) {
    createField(input: $input) {
      ...Field
    }
  }
  ${FieldFragmentDoc}
`;
export type CreateFieldMutationFn = Apollo.MutationFunction<
  CreateFieldMutation,
  CreateFieldMutationVariables
>;

/**
 * __useCreateFieldMutation__
 *
 * To run a mutation, you first call `useCreateFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFieldMutation, { data, loading, error }] = useCreateFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFieldMutation,
    CreateFieldMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFieldMutation, CreateFieldMutationVariables>(
    CreateFieldDocument,
    options,
  );
}
export type CreateFieldMutationHookResult = ReturnType<
  typeof useCreateFieldMutation
>;
export type CreateFieldMutationResult =
  Apollo.MutationResult<CreateFieldMutation>;
export type CreateFieldMutationOptions = Apollo.BaseMutationOptions<
  CreateFieldMutation,
  CreateFieldMutationVariables
>;
export const UpdateFieldDocument = gql`
  mutation UpdateField($input: UpdateFieldInput!, $id: String!) {
    updateField(input: $input, id: $id) {
      ...Field
    }
  }
  ${FieldFragmentDoc}
`;
export type UpdateFieldMutationFn = Apollo.MutationFunction<
  UpdateFieldMutation,
  UpdateFieldMutationVariables
>;

/**
 * __useUpdateFieldMutation__
 *
 * To run a mutation, you first call `useUpdateFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFieldMutation, { data, loading, error }] = useUpdateFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFieldMutation,
    UpdateFieldMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFieldMutation, UpdateFieldMutationVariables>(
    UpdateFieldDocument,
    options,
  );
}
export type UpdateFieldMutationHookResult = ReturnType<
  typeof useUpdateFieldMutation
>;
export type UpdateFieldMutationResult =
  Apollo.MutationResult<UpdateFieldMutation>;
export type UpdateFieldMutationOptions = Apollo.BaseMutationOptions<
  UpdateFieldMutation,
  UpdateFieldMutationVariables
>;
export const RemoveFieldDocument = gql`
  mutation RemoveField($id: String!) {
    removeField(id: $id) {
      success
    }
  }
`;
export type RemoveFieldMutationFn = Apollo.MutationFunction<
  RemoveFieldMutation,
  RemoveFieldMutationVariables
>;

/**
 * __useRemoveFieldMutation__
 *
 * To run a mutation, you first call `useRemoveFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFieldMutation, { data, loading, error }] = useRemoveFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFieldMutation,
    RemoveFieldMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFieldMutation, RemoveFieldMutationVariables>(
    RemoveFieldDocument,
    options,
  );
}
export type RemoveFieldMutationHookResult = ReturnType<
  typeof useRemoveFieldMutation
>;
export type RemoveFieldMutationResult =
  Apollo.MutationResult<RemoveFieldMutation>;
export type RemoveFieldMutationOptions = Apollo.BaseMutationOptions<
  RemoveFieldMutation,
  RemoveFieldMutationVariables
>;
export const GetGroupDocument = gql`
  query GetGroup($id: String!) {
    getGroup(id: $id) {
      ...GroupView
    }
  }
  ${GroupViewFragmentDoc}
`;

/**
 * __useGetGroupQuery__
 *
 * To run a query within a React component, call `useGetGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGroupQuery(
  baseOptions: Apollo.QueryHookOptions<GetGroupQuery, GetGroupQueryVariables> &
    ({ variables: GetGroupQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGroupQuery, GetGroupQueryVariables>(
    GetGroupDocument,
    options,
  );
}
export function useGetGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupQuery,
    GetGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGroupQuery, GetGroupQueryVariables>(
    GetGroupDocument,
    options,
  );
}
export function useGetGroupSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetGroupQuery, GetGroupQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetGroupQuery, GetGroupQueryVariables>(
    GetGroupDocument,
    options,
  );
}
export type GetGroupQueryHookResult = ReturnType<typeof useGetGroupQuery>;
export type GetGroupLazyQueryHookResult = ReturnType<
  typeof useGetGroupLazyQuery
>;
export type GetGroupSuspenseQueryHookResult = ReturnType<
  typeof useGetGroupSuspenseQuery
>;
export type GetGroupQueryResult = Apollo.QueryResult<
  GetGroupQuery,
  GetGroupQueryVariables
>;
export const FindGroupsDocument = gql`
  query FindGroups(
    $order: Order
    $filter: GroupsFilter
    $pagination: Pagination
  ) {
    findGroups(order: $order, filter: $filter, pagination: $pagination) {
      items {
        ...GroupView
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${GroupViewFragmentDoc}
`;

/**
 * __useFindGroupsQuery__
 *
 * To run a query within a React component, call `useFindGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindGroupsQuery({
 *   variables: {
 *      order: // value for 'order'
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindGroupsQuery,
    FindGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindGroupsQuery, FindGroupsQueryVariables>(
    FindGroupsDocument,
    options,
  );
}
export function useFindGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindGroupsQuery,
    FindGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindGroupsQuery, FindGroupsQueryVariables>(
    FindGroupsDocument,
    options,
  );
}
export function useFindGroupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindGroupsQuery,
        FindGroupsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindGroupsQuery, FindGroupsQueryVariables>(
    FindGroupsDocument,
    options,
  );
}
export type FindGroupsQueryHookResult = ReturnType<typeof useFindGroupsQuery>;
export type FindGroupsLazyQueryHookResult = ReturnType<
  typeof useFindGroupsLazyQuery
>;
export type FindGroupsSuspenseQueryHookResult = ReturnType<
  typeof useFindGroupsSuspenseQuery
>;
export type FindGroupsQueryResult = Apollo.QueryResult<
  FindGroupsQuery,
  FindGroupsQueryVariables
>;
export const FindAlertGroupsDocument = gql`
  query FindAlertGroups(
    $order: Order
    $filter: GroupsFilter
    $pagination: Pagination
  ) {
    findGroups(order: $order, filter: $filter, pagination: $pagination) {
      items {
        ...GroupView
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${GroupViewFragmentDoc}
`;

/**
 * __useFindAlertGroupsQuery__
 *
 * To run a query within a React component, call `useFindAlertGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAlertGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAlertGroupsQuery({
 *   variables: {
 *      order: // value for 'order'
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindAlertGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindAlertGroupsQuery,
    FindAlertGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAlertGroupsQuery, FindAlertGroupsQueryVariables>(
    FindAlertGroupsDocument,
    options,
  );
}
export function useFindAlertGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAlertGroupsQuery,
    FindAlertGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAlertGroupsQuery,
    FindAlertGroupsQueryVariables
  >(FindAlertGroupsDocument, options);
}
export function useFindAlertGroupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindAlertGroupsQuery,
        FindAlertGroupsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindAlertGroupsQuery,
    FindAlertGroupsQueryVariables
  >(FindAlertGroupsDocument, options);
}
export type FindAlertGroupsQueryHookResult = ReturnType<
  typeof useFindAlertGroupsQuery
>;
export type FindAlertGroupsLazyQueryHookResult = ReturnType<
  typeof useFindAlertGroupsLazyQuery
>;
export type FindAlertGroupsSuspenseQueryHookResult = ReturnType<
  typeof useFindAlertGroupsSuspenseQuery
>;
export type FindAlertGroupsQueryResult = Apollo.QueryResult<
  FindAlertGroupsQuery,
  FindAlertGroupsQueryVariables
>;
export const CreateGroupDocument = gql`
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      id
      name
      verified
      createdAt
      canBeCloned
    }
  }
`;
export type CreateGroupMutationFn = Apollo.MutationFunction<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(
    CreateGroupDocument,
    options,
  );
}
export type CreateGroupMutationHookResult = ReturnType<
  typeof useCreateGroupMutation
>;
export type CreateGroupMutationResult =
  Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;
export const UpdateGroupDocument = gql`
  mutation UpdateGroup($input: UpdateGroupInput!, $id: String!) {
    updateGroup(input: $input, id: $id) {
      ...GroupView
    }
  }
  ${GroupViewFragmentDoc}
`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(
    UpdateGroupDocument,
    options,
  );
}
export type UpdateGroupMutationHookResult = ReturnType<
  typeof useUpdateGroupMutation
>;
export type UpdateGroupMutationResult =
  Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;
export const RemoveGroupsDocument = gql`
  mutation RemoveGroups($input: RemoveGroupsInput!) {
    removeGroups(input: $input) {
      success
    }
  }
`;
export type RemoveGroupsMutationFn = Apollo.MutationFunction<
  RemoveGroupsMutation,
  RemoveGroupsMutationVariables
>;

/**
 * __useRemoveGroupsMutation__
 *
 * To run a mutation, you first call `useRemoveGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupsMutation, { data, loading, error }] = useRemoveGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGroupsMutation,
    RemoveGroupsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGroupsMutation,
    RemoveGroupsMutationVariables
  >(RemoveGroupsDocument, options);
}
export type RemoveGroupsMutationHookResult = ReturnType<
  typeof useRemoveGroupsMutation
>;
export type RemoveGroupsMutationResult =
  Apollo.MutationResult<RemoveGroupsMutation>;
export type RemoveGroupsMutationOptions = Apollo.BaseMutationOptions<
  RemoveGroupsMutation,
  RemoveGroupsMutationVariables
>;
export const DuplicateGroupsDocument = gql`
  mutation DuplicateGroups($duplicateGroupsId: String!) {
    duplicateGroups(id: $duplicateGroupsId) {
      ...GroupView
    }
  }
  ${GroupViewFragmentDoc}
`;
export type DuplicateGroupsMutationFn = Apollo.MutationFunction<
  DuplicateGroupsMutation,
  DuplicateGroupsMutationVariables
>;

/**
 * __useDuplicateGroupsMutation__
 *
 * To run a mutation, you first call `useDuplicateGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateGroupsMutation, { data, loading, error }] = useDuplicateGroupsMutation({
 *   variables: {
 *      duplicateGroupsId: // value for 'duplicateGroupsId'
 *   },
 * });
 */
export function useDuplicateGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateGroupsMutation,
    DuplicateGroupsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateGroupsMutation,
    DuplicateGroupsMutationVariables
  >(DuplicateGroupsDocument, options);
}
export type DuplicateGroupsMutationHookResult = ReturnType<
  typeof useDuplicateGroupsMutation
>;
export type DuplicateGroupsMutationResult =
  Apollo.MutationResult<DuplicateGroupsMutation>;
export type DuplicateGroupsMutationOptions = Apollo.BaseMutationOptions<
  DuplicateGroupsMutation,
  DuplicateGroupsMutationVariables
>;
export const AddGroupToContactsDocument = gql`
  mutation AddGroupToContacts($input: AddGroupToContactsInput!) {
    addGroupToContacts(input: $input) {
      success
    }
  }
`;
export type AddGroupToContactsMutationFn = Apollo.MutationFunction<
  AddGroupToContactsMutation,
  AddGroupToContactsMutationVariables
>;

/**
 * __useAddGroupToContactsMutation__
 *
 * To run a mutation, you first call `useAddGroupToContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupToContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupToContactsMutation, { data, loading, error }] = useAddGroupToContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGroupToContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupToContactsMutation,
    AddGroupToContactsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGroupToContactsMutation,
    AddGroupToContactsMutationVariables
  >(AddGroupToContactsDocument, options);
}
export type AddGroupToContactsMutationHookResult = ReturnType<
  typeof useAddGroupToContactsMutation
>;
export type AddGroupToContactsMutationResult =
  Apollo.MutationResult<AddGroupToContactsMutation>;
export type AddGroupToContactsMutationOptions = Apollo.BaseMutationOptions<
  AddGroupToContactsMutation,
  AddGroupToContactsMutationVariables
>;
export const RemoveGroupFromContactsDocument = gql`
  mutation RemoveGroupFromContacts($input: RemoveGroupFromContactsInput!) {
    removeGroupFromContacts(input: $input) {
      success
    }
  }
`;
export type RemoveGroupFromContactsMutationFn = Apollo.MutationFunction<
  RemoveGroupFromContactsMutation,
  RemoveGroupFromContactsMutationVariables
>;

/**
 * __useRemoveGroupFromContactsMutation__
 *
 * To run a mutation, you first call `useRemoveGroupFromContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupFromContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupFromContactsMutation, { data, loading, error }] = useRemoveGroupFromContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveGroupFromContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGroupFromContactsMutation,
    RemoveGroupFromContactsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGroupFromContactsMutation,
    RemoveGroupFromContactsMutationVariables
  >(RemoveGroupFromContactsDocument, options);
}
export type RemoveGroupFromContactsMutationHookResult = ReturnType<
  typeof useRemoveGroupFromContactsMutation
>;
export type RemoveGroupFromContactsMutationResult =
  Apollo.MutationResult<RemoveGroupFromContactsMutation>;
export type RemoveGroupFromContactsMutationOptions = Apollo.BaseMutationOptions<
  RemoveGroupFromContactsMutation,
  RemoveGroupFromContactsMutationVariables
>;
export const ExportGroupDocument = gql`
  query ExportGroup($exportGroupId: String!) {
    exportGroup(id: $exportGroupId) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useExportGroupQuery__
 *
 * To run a query within a React component, call `useExportGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportGroupQuery({
 *   variables: {
 *      exportGroupId: // value for 'exportGroupId'
 *   },
 * });
 */
export function useExportGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportGroupQuery,
    ExportGroupQueryVariables
  > &
    (
      | { variables: ExportGroupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportGroupQuery, ExportGroupQueryVariables>(
    ExportGroupDocument,
    options,
  );
}
export function useExportGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportGroupQuery,
    ExportGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportGroupQuery, ExportGroupQueryVariables>(
    ExportGroupDocument,
    options,
  );
}
export function useExportGroupSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExportGroupQuery,
        ExportGroupQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExportGroupQuery, ExportGroupQueryVariables>(
    ExportGroupDocument,
    options,
  );
}
export type ExportGroupQueryHookResult = ReturnType<typeof useExportGroupQuery>;
export type ExportGroupLazyQueryHookResult = ReturnType<
  typeof useExportGroupLazyQuery
>;
export type ExportGroupSuspenseQueryHookResult = ReturnType<
  typeof useExportGroupSuspenseQuery
>;
export type ExportGroupQueryResult = Apollo.QueryResult<
  ExportGroupQuery,
  ExportGroupQueryVariables
>;
export const FindCrmIntegrationsDocument = gql`
  query FindCrmIntegrations(
    $filter: CrmIntegrationFilter
    $pagination: Pagination
    $order: CrmIntegrationOrder
  ) {
    findCrmIntegrations(
      filter: $filter
      pagination: $pagination
      order: $order
    ) {
      items {
        ...crmIntegrationFragment
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${CrmIntegrationFragmentFragmentDoc}
`;

/**
 * __useFindCrmIntegrationsQuery__
 *
 * To run a query within a React component, call `useFindCrmIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCrmIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCrmIntegrationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useFindCrmIntegrationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindCrmIntegrationsQuery,
    FindCrmIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindCrmIntegrationsQuery,
    FindCrmIntegrationsQueryVariables
  >(FindCrmIntegrationsDocument, options);
}
export function useFindCrmIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCrmIntegrationsQuery,
    FindCrmIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindCrmIntegrationsQuery,
    FindCrmIntegrationsQueryVariables
  >(FindCrmIntegrationsDocument, options);
}
export function useFindCrmIntegrationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindCrmIntegrationsQuery,
        FindCrmIntegrationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindCrmIntegrationsQuery,
    FindCrmIntegrationsQueryVariables
  >(FindCrmIntegrationsDocument, options);
}
export type FindCrmIntegrationsQueryHookResult = ReturnType<
  typeof useFindCrmIntegrationsQuery
>;
export type FindCrmIntegrationsLazyQueryHookResult = ReturnType<
  typeof useFindCrmIntegrationsLazyQuery
>;
export type FindCrmIntegrationsSuspenseQueryHookResult = ReturnType<
  typeof useFindCrmIntegrationsSuspenseQuery
>;
export type FindCrmIntegrationsQueryResult = Apollo.QueryResult<
  FindCrmIntegrationsQuery,
  FindCrmIntegrationsQueryVariables
>;
export const GetCrmIntegrationDocument = gql`
  query getCrmIntegration($id: String!) {
    getCrmIntegration(id: $id) {
      ...crmIntegrationFields
    }
  }
  ${CrmIntegrationFieldsFragmentDoc}
`;

/**
 * __useGetCrmIntegrationQuery__
 *
 * To run a query within a React component, call `useGetCrmIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrmIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrmIntegrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCrmIntegrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCrmIntegrationQuery,
    GetCrmIntegrationQueryVariables
  > &
    (
      | { variables: GetCrmIntegrationQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCrmIntegrationQuery,
    GetCrmIntegrationQueryVariables
  >(GetCrmIntegrationDocument, options);
}
export function useGetCrmIntegrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCrmIntegrationQuery,
    GetCrmIntegrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCrmIntegrationQuery,
    GetCrmIntegrationQueryVariables
  >(GetCrmIntegrationDocument, options);
}
export function useGetCrmIntegrationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCrmIntegrationQuery,
        GetCrmIntegrationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCrmIntegrationQuery,
    GetCrmIntegrationQueryVariables
  >(GetCrmIntegrationDocument, options);
}
export type GetCrmIntegrationQueryHookResult = ReturnType<
  typeof useGetCrmIntegrationQuery
>;
export type GetCrmIntegrationLazyQueryHookResult = ReturnType<
  typeof useGetCrmIntegrationLazyQuery
>;
export type GetCrmIntegrationSuspenseQueryHookResult = ReturnType<
  typeof useGetCrmIntegrationSuspenseQuery
>;
export type GetCrmIntegrationQueryResult = Apollo.QueryResult<
  GetCrmIntegrationQuery,
  GetCrmIntegrationQueryVariables
>;
export const ExportConversationsToCrmIntegrationDocument = gql`
  query ExportConversationsToCrmIntegration(
    $input: ExportConversationsToCRMIntegrationInput!
  ) {
    exportConversationsToCrmIntegration(input: $input) {
      success
      total
    }
  }
`;

/**
 * __useExportConversationsToCrmIntegrationQuery__
 *
 * To run a query within a React component, call `useExportConversationsToCrmIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportConversationsToCrmIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportConversationsToCrmIntegrationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportConversationsToCrmIntegrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportConversationsToCrmIntegrationQuery,
    ExportConversationsToCrmIntegrationQueryVariables
  > &
    (
      | {
          variables: ExportConversationsToCrmIntegrationQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExportConversationsToCrmIntegrationQuery,
    ExportConversationsToCrmIntegrationQueryVariables
  >(ExportConversationsToCrmIntegrationDocument, options);
}
export function useExportConversationsToCrmIntegrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportConversationsToCrmIntegrationQuery,
    ExportConversationsToCrmIntegrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExportConversationsToCrmIntegrationQuery,
    ExportConversationsToCrmIntegrationQueryVariables
  >(ExportConversationsToCrmIntegrationDocument, options);
}
export function useExportConversationsToCrmIntegrationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExportConversationsToCrmIntegrationQuery,
        ExportConversationsToCrmIntegrationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExportConversationsToCrmIntegrationQuery,
    ExportConversationsToCrmIntegrationQueryVariables
  >(ExportConversationsToCrmIntegrationDocument, options);
}
export type ExportConversationsToCrmIntegrationQueryHookResult = ReturnType<
  typeof useExportConversationsToCrmIntegrationQuery
>;
export type ExportConversationsToCrmIntegrationLazyQueryHookResult = ReturnType<
  typeof useExportConversationsToCrmIntegrationLazyQuery
>;
export type ExportConversationsToCrmIntegrationSuspenseQueryHookResult =
  ReturnType<typeof useExportConversationsToCrmIntegrationSuspenseQuery>;
export type ExportConversationsToCrmIntegrationQueryResult = Apollo.QueryResult<
  ExportConversationsToCrmIntegrationQuery,
  ExportConversationsToCrmIntegrationQueryVariables
>;
export const CreateCrmIntegrationDocument = gql`
  mutation CreateCrmIntegration($input: CreateCrmIntegrationInput!) {
    createCrmIntegration(input: $input) {
      ...crmIntegrationFields
    }
  }
  ${CrmIntegrationFieldsFragmentDoc}
`;
export type CreateCrmIntegrationMutationFn = Apollo.MutationFunction<
  CreateCrmIntegrationMutation,
  CreateCrmIntegrationMutationVariables
>;

/**
 * __useCreateCrmIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateCrmIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCrmIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCrmIntegrationMutation, { data, loading, error }] = useCreateCrmIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCrmIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCrmIntegrationMutation,
    CreateCrmIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCrmIntegrationMutation,
    CreateCrmIntegrationMutationVariables
  >(CreateCrmIntegrationDocument, options);
}
export type CreateCrmIntegrationMutationHookResult = ReturnType<
  typeof useCreateCrmIntegrationMutation
>;
export type CreateCrmIntegrationMutationResult =
  Apollo.MutationResult<CreateCrmIntegrationMutation>;
export type CreateCrmIntegrationMutationOptions = Apollo.BaseMutationOptions<
  CreateCrmIntegrationMutation,
  CreateCrmIntegrationMutationVariables
>;
export const UpdateCrmIntegrationDocument = gql`
  mutation UpdateCrmIntegration(
    $input: UpdateCrmIntegrationInput!
    $id: String!
  ) {
    updateCrmIntegration(input: $input, id: $id) {
      ...crmIntegrationFields
      id
    }
  }
  ${CrmIntegrationFieldsFragmentDoc}
`;
export type UpdateCrmIntegrationMutationFn = Apollo.MutationFunction<
  UpdateCrmIntegrationMutation,
  UpdateCrmIntegrationMutationVariables
>;

/**
 * __useUpdateCrmIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateCrmIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrmIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrmIntegrationMutation, { data, loading, error }] = useUpdateCrmIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCrmIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCrmIntegrationMutation,
    UpdateCrmIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCrmIntegrationMutation,
    UpdateCrmIntegrationMutationVariables
  >(UpdateCrmIntegrationDocument, options);
}
export type UpdateCrmIntegrationMutationHookResult = ReturnType<
  typeof useUpdateCrmIntegrationMutation
>;
export type UpdateCrmIntegrationMutationResult =
  Apollo.MutationResult<UpdateCrmIntegrationMutation>;
export type UpdateCrmIntegrationMutationOptions = Apollo.BaseMutationOptions<
  UpdateCrmIntegrationMutation,
  UpdateCrmIntegrationMutationVariables
>;
export const RemoveCrmIntegrationDocument = gql`
  mutation RemoveCrmIntegration($id: String!) {
    removeCrmIntegration(id: $id) {
      success
    }
  }
`;
export type RemoveCrmIntegrationMutationFn = Apollo.MutationFunction<
  RemoveCrmIntegrationMutation,
  RemoveCrmIntegrationMutationVariables
>;

/**
 * __useRemoveCrmIntegrationMutation__
 *
 * To run a mutation, you first call `useRemoveCrmIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCrmIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCrmIntegrationMutation, { data, loading, error }] = useRemoveCrmIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCrmIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCrmIntegrationMutation,
    RemoveCrmIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCrmIntegrationMutation,
    RemoveCrmIntegrationMutationVariables
  >(RemoveCrmIntegrationDocument, options);
}
export type RemoveCrmIntegrationMutationHookResult = ReturnType<
  typeof useRemoveCrmIntegrationMutation
>;
export type RemoveCrmIntegrationMutationResult =
  Apollo.MutationResult<RemoveCrmIntegrationMutation>;
export type RemoveCrmIntegrationMutationOptions = Apollo.BaseMutationOptions<
  RemoveCrmIntegrationMutation,
  RemoveCrmIntegrationMutationVariables
>;
export const FindKeywordTemplatesDocument = gql`
  query FindKeywordTemplates(
    $filter: KeywordTemplatesFilter
    $pagination: Pagination
  ) {
    findKeywordTemplates(filter: $filter, pagination: $pagination) {
      hasNext
      items {
        ...KeywordTemplate
      }
      nextCreatedAt
      nextId
      total
    }
  }
  ${KeywordTemplateFragmentDoc}
`;

/**
 * __useFindKeywordTemplatesQuery__
 *
 * To run a query within a React component, call `useFindKeywordTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindKeywordTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindKeywordTemplatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindKeywordTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindKeywordTemplatesQuery,
    FindKeywordTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindKeywordTemplatesQuery,
    FindKeywordTemplatesQueryVariables
  >(FindKeywordTemplatesDocument, options);
}
export function useFindKeywordTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindKeywordTemplatesQuery,
    FindKeywordTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindKeywordTemplatesQuery,
    FindKeywordTemplatesQueryVariables
  >(FindKeywordTemplatesDocument, options);
}
export function useFindKeywordTemplatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindKeywordTemplatesQuery,
        FindKeywordTemplatesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindKeywordTemplatesQuery,
    FindKeywordTemplatesQueryVariables
  >(FindKeywordTemplatesDocument, options);
}
export type FindKeywordTemplatesQueryHookResult = ReturnType<
  typeof useFindKeywordTemplatesQuery
>;
export type FindKeywordTemplatesLazyQueryHookResult = ReturnType<
  typeof useFindKeywordTemplatesLazyQuery
>;
export type FindKeywordTemplatesSuspenseQueryHookResult = ReturnType<
  typeof useFindKeywordTemplatesSuspenseQuery
>;
export type FindKeywordTemplatesQueryResult = Apollo.QueryResult<
  FindKeywordTemplatesQuery,
  FindKeywordTemplatesQueryVariables
>;
export const GetKeywordTemplateDocument = gql`
  query GetKeywordTemplate($getKeywordTemplateId: String!) {
    getKeywordTemplate(id: $getKeywordTemplateId) {
      ...KeywordTemplate
    }
  }
  ${KeywordTemplateFragmentDoc}
`;

/**
 * __useGetKeywordTemplateQuery__
 *
 * To run a query within a React component, call `useGetKeywordTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeywordTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeywordTemplateQuery({
 *   variables: {
 *      getKeywordTemplateId: // value for 'getKeywordTemplateId'
 *   },
 * });
 */
export function useGetKeywordTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetKeywordTemplateQuery,
    GetKeywordTemplateQueryVariables
  > &
    (
      | { variables: GetKeywordTemplateQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetKeywordTemplateQuery,
    GetKeywordTemplateQueryVariables
  >(GetKeywordTemplateDocument, options);
}
export function useGetKeywordTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKeywordTemplateQuery,
    GetKeywordTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetKeywordTemplateQuery,
    GetKeywordTemplateQueryVariables
  >(GetKeywordTemplateDocument, options);
}
export function useGetKeywordTemplateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetKeywordTemplateQuery,
        GetKeywordTemplateQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetKeywordTemplateQuery,
    GetKeywordTemplateQueryVariables
  >(GetKeywordTemplateDocument, options);
}
export type GetKeywordTemplateQueryHookResult = ReturnType<
  typeof useGetKeywordTemplateQuery
>;
export type GetKeywordTemplateLazyQueryHookResult = ReturnType<
  typeof useGetKeywordTemplateLazyQuery
>;
export type GetKeywordTemplateSuspenseQueryHookResult = ReturnType<
  typeof useGetKeywordTemplateSuspenseQuery
>;
export type GetKeywordTemplateQueryResult = Apollo.QueryResult<
  GetKeywordTemplateQuery,
  GetKeywordTemplateQueryVariables
>;
export const CreateKeywordTemplateDocument = gql`
  mutation CreateKeywordTemplate($input: CreateKeywordTemplateInput!) {
    createKeywordTemplate(input: $input) {
      ...KeywordTemplate
    }
  }
  ${KeywordTemplateFragmentDoc}
`;
export type CreateKeywordTemplateMutationFn = Apollo.MutationFunction<
  CreateKeywordTemplateMutation,
  CreateKeywordTemplateMutationVariables
>;

/**
 * __useCreateKeywordTemplateMutation__
 *
 * To run a mutation, you first call `useCreateKeywordTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeywordTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKeywordTemplateMutation, { data, loading, error }] = useCreateKeywordTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKeywordTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKeywordTemplateMutation,
    CreateKeywordTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateKeywordTemplateMutation,
    CreateKeywordTemplateMutationVariables
  >(CreateKeywordTemplateDocument, options);
}
export type CreateKeywordTemplateMutationHookResult = ReturnType<
  typeof useCreateKeywordTemplateMutation
>;
export type CreateKeywordTemplateMutationResult =
  Apollo.MutationResult<CreateKeywordTemplateMutation>;
export type CreateKeywordTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateKeywordTemplateMutation,
  CreateKeywordTemplateMutationVariables
>;
export const UpdateKeywordTemplateDocument = gql`
  mutation UpdateKeywordTemplate(
    $input: UpdateKeywordTemplateInput!
    $updateKeywordTemplateId: String!
  ) {
    updateKeywordTemplate(input: $input, id: $updateKeywordTemplateId) {
      ...KeywordTemplate
    }
  }
  ${KeywordTemplateFragmentDoc}
`;
export type UpdateKeywordTemplateMutationFn = Apollo.MutationFunction<
  UpdateKeywordTemplateMutation,
  UpdateKeywordTemplateMutationVariables
>;

/**
 * __useUpdateKeywordTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateKeywordTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKeywordTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKeywordTemplateMutation, { data, loading, error }] = useUpdateKeywordTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      updateKeywordTemplateId: // value for 'updateKeywordTemplateId'
 *   },
 * });
 */
export function useUpdateKeywordTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateKeywordTemplateMutation,
    UpdateKeywordTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateKeywordTemplateMutation,
    UpdateKeywordTemplateMutationVariables
  >(UpdateKeywordTemplateDocument, options);
}
export type UpdateKeywordTemplateMutationHookResult = ReturnType<
  typeof useUpdateKeywordTemplateMutation
>;
export type UpdateKeywordTemplateMutationResult =
  Apollo.MutationResult<UpdateKeywordTemplateMutation>;
export type UpdateKeywordTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateKeywordTemplateMutation,
  UpdateKeywordTemplateMutationVariables
>;
export const RemoveKeywordTemplateDocument = gql`
  mutation RemoveKeywordTemplate($removeKeywordTemplateId: String!) {
    removeKeywordTemplate(id: $removeKeywordTemplateId) {
      success
    }
  }
`;
export type RemoveKeywordTemplateMutationFn = Apollo.MutationFunction<
  RemoveKeywordTemplateMutation,
  RemoveKeywordTemplateMutationVariables
>;

/**
 * __useRemoveKeywordTemplateMutation__
 *
 * To run a mutation, you first call `useRemoveKeywordTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKeywordTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKeywordTemplateMutation, { data, loading, error }] = useRemoveKeywordTemplateMutation({
 *   variables: {
 *      removeKeywordTemplateId: // value for 'removeKeywordTemplateId'
 *   },
 * });
 */
export function useRemoveKeywordTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveKeywordTemplateMutation,
    RemoveKeywordTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveKeywordTemplateMutation,
    RemoveKeywordTemplateMutationVariables
  >(RemoveKeywordTemplateDocument, options);
}
export type RemoveKeywordTemplateMutationHookResult = ReturnType<
  typeof useRemoveKeywordTemplateMutation
>;
export type RemoveKeywordTemplateMutationResult =
  Apollo.MutationResult<RemoveKeywordTemplateMutation>;
export type RemoveKeywordTemplateMutationOptions = Apollo.BaseMutationOptions<
  RemoveKeywordTemplateMutation,
  RemoveKeywordTemplateMutationVariables
>;
export const RankKeywordTemplateDocument = gql`
  mutation RankKeywordTemplate(
    $input: RankKeywordTemplateInput!
    $rankKeywordTemplateId: String!
  ) {
    rankKeywordTemplate(input: $input, id: $rankKeywordTemplateId) {
      success
    }
  }
`;
export type RankKeywordTemplateMutationFn = Apollo.MutationFunction<
  RankKeywordTemplateMutation,
  RankKeywordTemplateMutationVariables
>;

/**
 * __useRankKeywordTemplateMutation__
 *
 * To run a mutation, you first call `useRankKeywordTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRankKeywordTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rankKeywordTemplateMutation, { data, loading, error }] = useRankKeywordTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      rankKeywordTemplateId: // value for 'rankKeywordTemplateId'
 *   },
 * });
 */
export function useRankKeywordTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RankKeywordTemplateMutation,
    RankKeywordTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RankKeywordTemplateMutation,
    RankKeywordTemplateMutationVariables
  >(RankKeywordTemplateDocument, options);
}
export type RankKeywordTemplateMutationHookResult = ReturnType<
  typeof useRankKeywordTemplateMutation
>;
export type RankKeywordTemplateMutationResult =
  Apollo.MutationResult<RankKeywordTemplateMutation>;
export type RankKeywordTemplateMutationOptions = Apollo.BaseMutationOptions<
  RankKeywordTemplateMutation,
  RankKeywordTemplateMutationVariables
>;
export const FindLabelsDocument = gql`
  query FindLabels($filter: LabelFilter, $pagination: CursorPagination) {
    findLabels(filter: $filter, pagination: $pagination) {
      items {
        ...Label
      }
      total
      nextPageToken
    }
  }
  ${LabelFragmentDoc}
`;

/**
 * __useFindLabelsQuery__
 *
 * To run a query within a React component, call `useFindLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLabelsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindLabelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindLabelsQuery,
    FindLabelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindLabelsQuery, FindLabelsQueryVariables>(
    FindLabelsDocument,
    options,
  );
}
export function useFindLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindLabelsQuery,
    FindLabelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindLabelsQuery, FindLabelsQueryVariables>(
    FindLabelsDocument,
    options,
  );
}
export function useFindLabelsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindLabelsQuery,
        FindLabelsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindLabelsQuery, FindLabelsQueryVariables>(
    FindLabelsDocument,
    options,
  );
}
export type FindLabelsQueryHookResult = ReturnType<typeof useFindLabelsQuery>;
export type FindLabelsLazyQueryHookResult = ReturnType<
  typeof useFindLabelsLazyQuery
>;
export type FindLabelsSuspenseQueryHookResult = ReturnType<
  typeof useFindLabelsSuspenseQuery
>;
export type FindLabelsQueryResult = Apollo.QueryResult<
  FindLabelsQuery,
  FindLabelsQueryVariables
>;
export const GetLabelDocument = gql`
  query GetLabel($id: String!) {
    getLabel(id: $id) {
      ...Label
    }
  }
  ${LabelFragmentDoc}
`;

/**
 * __useGetLabelQuery__
 *
 * To run a query within a React component, call `useGetLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLabelQuery(
  baseOptions: Apollo.QueryHookOptions<GetLabelQuery, GetLabelQueryVariables> &
    ({ variables: GetLabelQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLabelQuery, GetLabelQueryVariables>(
    GetLabelDocument,
    options,
  );
}
export function useGetLabelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabelQuery,
    GetLabelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLabelQuery, GetLabelQueryVariables>(
    GetLabelDocument,
    options,
  );
}
export function useGetLabelSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLabelQuery, GetLabelQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLabelQuery, GetLabelQueryVariables>(
    GetLabelDocument,
    options,
  );
}
export type GetLabelQueryHookResult = ReturnType<typeof useGetLabelQuery>;
export type GetLabelLazyQueryHookResult = ReturnType<
  typeof useGetLabelLazyQuery
>;
export type GetLabelSuspenseQueryHookResult = ReturnType<
  typeof useGetLabelSuspenseQuery
>;
export type GetLabelQueryResult = Apollo.QueryResult<
  GetLabelQuery,
  GetLabelQueryVariables
>;
export const CreateLabelDocument = gql`
  mutation CreateLabel($input: CreateLabelInput!) {
    createLabel(input: $input) {
      ...Label
    }
  }
  ${LabelFragmentDoc}
`;
export type CreateLabelMutationFn = Apollo.MutationFunction<
  CreateLabelMutation,
  CreateLabelMutationVariables
>;

/**
 * __useCreateLabelMutation__
 *
 * To run a mutation, you first call `useCreateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLabelMutation, { data, loading, error }] = useCreateLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLabelMutation,
    CreateLabelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLabelMutation, CreateLabelMutationVariables>(
    CreateLabelDocument,
    options,
  );
}
export type CreateLabelMutationHookResult = ReturnType<
  typeof useCreateLabelMutation
>;
export type CreateLabelMutationResult =
  Apollo.MutationResult<CreateLabelMutation>;
export type CreateLabelMutationOptions = Apollo.BaseMutationOptions<
  CreateLabelMutation,
  CreateLabelMutationVariables
>;
export const UpdateLabelDocument = gql`
  mutation UpdateLabel($input: UpdateLabelInput!, $id: String!) {
    updateLabel(input: $input, id: $id) {
      ...Label
    }
  }
  ${LabelFragmentDoc}
`;
export type UpdateLabelMutationFn = Apollo.MutationFunction<
  UpdateLabelMutation,
  UpdateLabelMutationVariables
>;

/**
 * __useUpdateLabelMutation__
 *
 * To run a mutation, you first call `useUpdateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabelMutation, { data, loading, error }] = useUpdateLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLabelMutation,
    UpdateLabelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLabelMutation, UpdateLabelMutationVariables>(
    UpdateLabelDocument,
    options,
  );
}
export type UpdateLabelMutationHookResult = ReturnType<
  typeof useUpdateLabelMutation
>;
export type UpdateLabelMutationResult =
  Apollo.MutationResult<UpdateLabelMutation>;
export type UpdateLabelMutationOptions = Apollo.BaseMutationOptions<
  UpdateLabelMutation,
  UpdateLabelMutationVariables
>;
export const RemoveLabelDocument = gql`
  mutation RemoveLabel($id: String!) {
    removeLabel(id: $id) {
      success
    }
  }
`;
export type RemoveLabelMutationFn = Apollo.MutationFunction<
  RemoveLabelMutation,
  RemoveLabelMutationVariables
>;

/**
 * __useRemoveLabelMutation__
 *
 * To run a mutation, you first call `useRemoveLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLabelMutation, { data, loading, error }] = useRemoveLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveLabelMutation,
    RemoveLabelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveLabelMutation, RemoveLabelMutationVariables>(
    RemoveLabelDocument,
    options,
  );
}
export type RemoveLabelMutationHookResult = ReturnType<
  typeof useRemoveLabelMutation
>;
export type RemoveLabelMutationResult =
  Apollo.MutationResult<RemoveLabelMutation>;
export type RemoveLabelMutationOptions = Apollo.BaseMutationOptions<
  RemoveLabelMutation,
  RemoveLabelMutationVariables
>;
export const RankLabelDocument = gql`
  mutation RankLabel($input: RankLabelInput!, $rankLabelId: String!) {
    rankLabel(input: $input, id: $rankLabelId) {
      ...RankResult
    }
  }
  ${RankResultFragmentDoc}
`;
export type RankLabelMutationFn = Apollo.MutationFunction<
  RankLabelMutation,
  RankLabelMutationVariables
>;

/**
 * __useRankLabelMutation__
 *
 * To run a mutation, you first call `useRankLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRankLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rankLabelMutation, { data, loading, error }] = useRankLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *      rankLabelId: // value for 'rankLabelId'
 *   },
 * });
 */
export function useRankLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RankLabelMutation,
    RankLabelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RankLabelMutation, RankLabelMutationVariables>(
    RankLabelDocument,
    options,
  );
}
export type RankLabelMutationHookResult = ReturnType<
  typeof useRankLabelMutation
>;
export type RankLabelMutationResult = Apollo.MutationResult<RankLabelMutation>;
export type RankLabelMutationOptions = Apollo.BaseMutationOptions<
  RankLabelMutation,
  RankLabelMutationVariables
>;
export const GetListDocument = gql`
  query GetList($id: String!) {
    getList(id: $id) {
      id
      type
      options {
        ...ListOptions
      }
      fields {
        ...Field
      }
    }
  }
  ${ListOptionsFragmentDoc}
  ${FieldFragmentDoc}
`;

/**
 * __useGetListQuery__
 *
 * To run a query within a React component, call `useGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetListQuery(
  baseOptions: Apollo.QueryHookOptions<GetListQuery, GetListQueryVariables> &
    ({ variables: GetListQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetListQuery, GetListQueryVariables>(
    GetListDocument,
    options,
  );
}
export function useGetListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetListQuery,
    GetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetListQuery, GetListQueryVariables>(
    GetListDocument,
    options,
  );
}
export function useGetListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetListQuery, GetListQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetListQuery, GetListQueryVariables>(
    GetListDocument,
    options,
  );
}
export type GetListQueryHookResult = ReturnType<typeof useGetListQuery>;
export type GetListLazyQueryHookResult = ReturnType<typeof useGetListLazyQuery>;
export type GetListSuspenseQueryHookResult = ReturnType<
  typeof useGetListSuspenseQuery
>;
export type GetListQueryResult = Apollo.QueryResult<
  GetListQuery,
  GetListQueryVariables
>;
export const ExportListDocument = gql`
  query ExportList($input: ExportListInput!) {
    exportList(input: $input) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useExportListQuery__
 *
 * To run a query within a React component, call `useExportListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportListQuery,
    ExportListQueryVariables
  > &
    (
      | { variables: ExportListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportListQuery, ExportListQueryVariables>(
    ExportListDocument,
    options,
  );
}
export function useExportListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportListQuery,
    ExportListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportListQuery, ExportListQueryVariables>(
    ExportListDocument,
    options,
  );
}
export function useExportListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExportListQuery,
        ExportListQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExportListQuery, ExportListQueryVariables>(
    ExportListDocument,
    options,
  );
}
export type ExportListQueryHookResult = ReturnType<typeof useExportListQuery>;
export type ExportListLazyQueryHookResult = ReturnType<
  typeof useExportListLazyQuery
>;
export type ExportListSuspenseQueryHookResult = ReturnType<
  typeof useExportListSuspenseQuery
>;
export type ExportListQueryResult = Apollo.QueryResult<
  ExportListQuery,
  ExportListQueryVariables
>;
export const FindListItemsDocument = gql`
  query FindListItems($listId: String!, $pagination: ListItemsPagination) {
    findListItems(listId: $listId, pagination: $pagination) {
      items {
        id
        data
        rowIndex
      }
      total
      hasNext
      nextRowIndex
    }
  }
`;

/**
 * __useFindListItemsQuery__
 *
 * To run a query within a React component, call `useFindListItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindListItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindListItemsQuery({
 *   variables: {
 *      listId: // value for 'listId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindListItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindListItemsQuery,
    FindListItemsQueryVariables
  > &
    (
      | { variables: FindListItemsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindListItemsQuery, FindListItemsQueryVariables>(
    FindListItemsDocument,
    options,
  );
}
export function useFindListItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindListItemsQuery,
    FindListItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindListItemsQuery, FindListItemsQueryVariables>(
    FindListItemsDocument,
    options,
  );
}
export function useFindListItemsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindListItemsQuery,
        FindListItemsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindListItemsQuery,
    FindListItemsQueryVariables
  >(FindListItemsDocument, options);
}
export type FindListItemsQueryHookResult = ReturnType<
  typeof useFindListItemsQuery
>;
export type FindListItemsLazyQueryHookResult = ReturnType<
  typeof useFindListItemsLazyQuery
>;
export type FindListItemsSuspenseQueryHookResult = ReturnType<
  typeof useFindListItemsSuspenseQuery
>;
export type FindListItemsQueryResult = Apollo.QueryResult<
  FindListItemsQuery,
  FindListItemsQueryVariables
>;
export const UpdateListDocument = gql`
  mutation UpdateList($input: UpdateListInput!, $id: String!) {
    updateList(input: $input, id: $id) {
      id
      options {
        ...ListOptions
      }
      fields {
        ...Field
      }
    }
  }
  ${ListOptionsFragmentDoc}
  ${FieldFragmentDoc}
`;
export type UpdateListMutationFn = Apollo.MutationFunction<
  UpdateListMutation,
  UpdateListMutationVariables
>;

/**
 * __useUpdateListMutation__
 *
 * To run a mutation, you first call `useUpdateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListMutation, { data, loading, error }] = useUpdateListMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateListMutation,
    UpdateListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateListMutation, UpdateListMutationVariables>(
    UpdateListDocument,
    options,
  );
}
export type UpdateListMutationHookResult = ReturnType<
  typeof useUpdateListMutation
>;
export type UpdateListMutationResult =
  Apollo.MutationResult<UpdateListMutation>;
export type UpdateListMutationOptions = Apollo.BaseMutationOptions<
  UpdateListMutation,
  UpdateListMutationVariables
>;
export const VerifyListDocument = gql`
  mutation VerifyList($id: String!) {
    verifyList(id: $id) {
      success
    }
  }
`;
export type VerifyListMutationFn = Apollo.MutationFunction<
  VerifyListMutation,
  VerifyListMutationVariables
>;

/**
 * __useVerifyListMutation__
 *
 * To run a mutation, you first call `useVerifyListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyListMutation, { data, loading, error }] = useVerifyListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVerifyListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyListMutation,
    VerifyListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyListMutation, VerifyListMutationVariables>(
    VerifyListDocument,
    options,
  );
}
export type VerifyListMutationHookResult = ReturnType<
  typeof useVerifyListMutation
>;
export type VerifyListMutationResult =
  Apollo.MutationResult<VerifyListMutation>;
export type VerifyListMutationOptions = Apollo.BaseMutationOptions<
  VerifyListMutation,
  VerifyListMutationVariables
>;
export const RemoveListItemDocument = gql`
  mutation RemoveListItem($id: String!) {
    removeListItem(id: $id) {
      success
    }
  }
`;
export type RemoveListItemMutationFn = Apollo.MutationFunction<
  RemoveListItemMutation,
  RemoveListItemMutationVariables
>;

/**
 * __useRemoveListItemMutation__
 *
 * To run a mutation, you first call `useRemoveListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeListItemMutation, { data, loading, error }] = useRemoveListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveListItemMutation,
    RemoveListItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveListItemMutation,
    RemoveListItemMutationVariables
  >(RemoveListItemDocument, options);
}
export type RemoveListItemMutationHookResult = ReturnType<
  typeof useRemoveListItemMutation
>;
export type RemoveListItemMutationResult =
  Apollo.MutationResult<RemoveListItemMutation>;
export type RemoveListItemMutationOptions = Apollo.BaseMutationOptions<
  RemoveListItemMutation,
  RemoveListItemMutationVariables
>;
export const UpdateListItemDocument = gql`
  mutation UpdateListItem($input: UpdateListItemInput!, $id: String!) {
    updateListItem(input: $input, id: $id) {
      success
    }
  }
`;
export type UpdateListItemMutationFn = Apollo.MutationFunction<
  UpdateListItemMutation,
  UpdateListItemMutationVariables
>;

/**
 * __useUpdateListItemMutation__
 *
 * To run a mutation, you first call `useUpdateListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListItemMutation, { data, loading, error }] = useUpdateListItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateListItemMutation,
    UpdateListItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateListItemMutation,
    UpdateListItemMutationVariables
  >(UpdateListItemDocument, options);
}
export type UpdateListItemMutationHookResult = ReturnType<
  typeof useUpdateListItemMutation
>;
export type UpdateListItemMutationResult =
  Apollo.MutationResult<UpdateListItemMutation>;
export type UpdateListItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateListItemMutation,
  UpdateListItemMutationVariables
>;
export const GetListUploadSignedUrlDocument = gql`
  query GetListUploadSignedUrl($input: GenerateSignedUrlInput!) {
    getListUploadSignedUrl(input: $input) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useGetListUploadSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetListUploadSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListUploadSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListUploadSignedUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListUploadSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetListUploadSignedUrlQuery,
    GetListUploadSignedUrlQueryVariables
  > &
    (
      | { variables: GetListUploadSignedUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetListUploadSignedUrlQuery,
    GetListUploadSignedUrlQueryVariables
  >(GetListUploadSignedUrlDocument, options);
}
export function useGetListUploadSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetListUploadSignedUrlQuery,
    GetListUploadSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetListUploadSignedUrlQuery,
    GetListUploadSignedUrlQueryVariables
  >(GetListUploadSignedUrlDocument, options);
}
export function useGetListUploadSignedUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetListUploadSignedUrlQuery,
        GetListUploadSignedUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetListUploadSignedUrlQuery,
    GetListUploadSignedUrlQueryVariables
  >(GetListUploadSignedUrlDocument, options);
}
export type GetListUploadSignedUrlQueryHookResult = ReturnType<
  typeof useGetListUploadSignedUrlQuery
>;
export type GetListUploadSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetListUploadSignedUrlLazyQuery
>;
export type GetListUploadSignedUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetListUploadSignedUrlSuspenseQuery
>;
export type GetListUploadSignedUrlQueryResult = Apollo.QueryResult<
  GetListUploadSignedUrlQuery,
  GetListUploadSignedUrlQueryVariables
>;
export const CreateListDocument = gql`
  mutation CreateList($input: CreateListInput!) {
    createList(input: $input) {
      id
      type
      options {
        ...ListOptions
      }
      fields {
        id
        name
        predefined
        key
      }
    }
  }
  ${ListOptionsFragmentDoc}
`;
export type CreateListMutationFn = Apollo.MutationFunction<
  CreateListMutation,
  CreateListMutationVariables
>;

/**
 * __useCreateListMutation__
 *
 * To run a mutation, you first call `useCreateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListMutation, { data, loading, error }] = useCreateListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateListMutation,
    CreateListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateListMutation, CreateListMutationVariables>(
    CreateListDocument,
    options,
  );
}
export type CreateListMutationHookResult = ReturnType<
  typeof useCreateListMutation
>;
export type CreateListMutationResult =
  Apollo.MutationResult<CreateListMutation>;
export type CreateListMutationOptions = Apollo.BaseMutationOptions<
  CreateListMutation,
  CreateListMutationVariables
>;
export const ListVerificationProgressDocument = gql`
  subscription ListVerificationProgress {
    listVerificationProgress {
      listId
      progress
    }
  }
`;

/**
 * __useListVerificationProgressSubscription__
 *
 * To run a query within a React component, call `useListVerificationProgressSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListVerificationProgressSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVerificationProgressSubscription({
 *   variables: {
 *   },
 * });
 */
export function useListVerificationProgressSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ListVerificationProgressSubscription,
    ListVerificationProgressSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ListVerificationProgressSubscription,
    ListVerificationProgressSubscriptionVariables
  >(ListVerificationProgressDocument, options);
}
export type ListVerificationProgressSubscriptionHookResult = ReturnType<
  typeof useListVerificationProgressSubscription
>;
export type ListVerificationProgressSubscriptionResult =
  Apollo.SubscriptionResult<ListVerificationProgressSubscription>;
export const ListVerificationCompletedDocument = gql`
  subscription ListVerificationCompleted {
    listVerificationCompleted {
      ...ListVerificationResult
    }
  }
  ${ListVerificationResultFragmentDoc}
`;

/**
 * __useListVerificationCompletedSubscription__
 *
 * To run a query within a React component, call `useListVerificationCompletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListVerificationCompletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVerificationCompletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useListVerificationCompletedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ListVerificationCompletedSubscription,
    ListVerificationCompletedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ListVerificationCompletedSubscription,
    ListVerificationCompletedSubscriptionVariables
  >(ListVerificationCompletedDocument, options);
}
export type ListVerificationCompletedSubscriptionHookResult = ReturnType<
  typeof useListVerificationCompletedSubscription
>;
export type ListVerificationCompletedSubscriptionResult =
  Apollo.SubscriptionResult<ListVerificationCompletedSubscription>;
export const ListUploadProgressDocument = gql`
  subscription ListUploadProgress {
    listUploadProgress {
      listId
      progress
    }
  }
`;

/**
 * __useListUploadProgressSubscription__
 *
 * To run a query within a React component, call `useListUploadProgressSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListUploadProgressSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUploadProgressSubscription({
 *   variables: {
 *   },
 * });
 */
export function useListUploadProgressSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ListUploadProgressSubscription,
    ListUploadProgressSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ListUploadProgressSubscription,
    ListUploadProgressSubscriptionVariables
  >(ListUploadProgressDocument, options);
}
export type ListUploadProgressSubscriptionHookResult = ReturnType<
  typeof useListUploadProgressSubscription
>;
export type ListUploadProgressSubscriptionResult =
  Apollo.SubscriptionResult<ListUploadProgressSubscription>;
export const ListUploadCompletedDocument = gql`
  subscription ListUploadCompleted {
    listUploadCompleted {
      listId
    }
  }
`;

/**
 * __useListUploadCompletedSubscription__
 *
 * To run a query within a React component, call `useListUploadCompletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListUploadCompletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUploadCompletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useListUploadCompletedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ListUploadCompletedSubscription,
    ListUploadCompletedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ListUploadCompletedSubscription,
    ListUploadCompletedSubscriptionVariables
  >(ListUploadCompletedDocument, options);
}
export type ListUploadCompletedSubscriptionHookResult = ReturnType<
  typeof useListUploadCompletedSubscription
>;
export type ListUploadCompletedSubscriptionResult =
  Apollo.SubscriptionResult<ListUploadCompletedSubscription>;
export const FindMacrosDocument = gql`
  query FindMacros($filter: MacrosFilter, $pagination: CursorPagination) {
    findMacros(filter: $filter, pagination: $pagination) {
      items {
        ...Macro
      }
      total
      nextPageToken
    }
  }
  ${MacroFragmentDoc}
`;

/**
 * __useFindMacrosQuery__
 *
 * To run a query within a React component, call `useFindMacrosQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMacrosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMacrosQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindMacrosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindMacrosQuery,
    FindMacrosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindMacrosQuery, FindMacrosQueryVariables>(
    FindMacrosDocument,
    options,
  );
}
export function useFindMacrosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindMacrosQuery,
    FindMacrosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindMacrosQuery, FindMacrosQueryVariables>(
    FindMacrosDocument,
    options,
  );
}
export function useFindMacrosSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindMacrosQuery,
        FindMacrosQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindMacrosQuery, FindMacrosQueryVariables>(
    FindMacrosDocument,
    options,
  );
}
export type FindMacrosQueryHookResult = ReturnType<typeof useFindMacrosQuery>;
export type FindMacrosLazyQueryHookResult = ReturnType<
  typeof useFindMacrosLazyQuery
>;
export type FindMacrosSuspenseQueryHookResult = ReturnType<
  typeof useFindMacrosSuspenseQuery
>;
export type FindMacrosQueryResult = Apollo.QueryResult<
  FindMacrosQuery,
  FindMacrosQueryVariables
>;
export const GetMacroDocument = gql`
  query GetMacro($getMacroId: String!) {
    getMacro(id: $getMacroId) {
      ...Macro
    }
  }
  ${MacroFragmentDoc}
`;

/**
 * __useGetMacroQuery__
 *
 * To run a query within a React component, call `useGetMacroQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMacroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMacroQuery({
 *   variables: {
 *      getMacroId: // value for 'getMacroId'
 *   },
 * });
 */
export function useGetMacroQuery(
  baseOptions: Apollo.QueryHookOptions<GetMacroQuery, GetMacroQueryVariables> &
    ({ variables: GetMacroQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMacroQuery, GetMacroQueryVariables>(
    GetMacroDocument,
    options,
  );
}
export function useGetMacroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMacroQuery,
    GetMacroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMacroQuery, GetMacroQueryVariables>(
    GetMacroDocument,
    options,
  );
}
export function useGetMacroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetMacroQuery, GetMacroQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMacroQuery, GetMacroQueryVariables>(
    GetMacroDocument,
    options,
  );
}
export type GetMacroQueryHookResult = ReturnType<typeof useGetMacroQuery>;
export type GetMacroLazyQueryHookResult = ReturnType<
  typeof useGetMacroLazyQuery
>;
export type GetMacroSuspenseQueryHookResult = ReturnType<
  typeof useGetMacroSuspenseQuery
>;
export type GetMacroQueryResult = Apollo.QueryResult<
  GetMacroQuery,
  GetMacroQueryVariables
>;
export const CreateMacroDocument = gql`
  mutation CreateMacro($input: CreateMacroInput!) {
    createMacro(input: $input) {
      ...Macro
    }
  }
  ${MacroFragmentDoc}
`;
export type CreateMacroMutationFn = Apollo.MutationFunction<
  CreateMacroMutation,
  CreateMacroMutationVariables
>;

/**
 * __useCreateMacroMutation__
 *
 * To run a mutation, you first call `useCreateMacroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMacroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMacroMutation, { data, loading, error }] = useCreateMacroMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMacroMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMacroMutation,
    CreateMacroMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMacroMutation, CreateMacroMutationVariables>(
    CreateMacroDocument,
    options,
  );
}
export type CreateMacroMutationHookResult = ReturnType<
  typeof useCreateMacroMutation
>;
export type CreateMacroMutationResult =
  Apollo.MutationResult<CreateMacroMutation>;
export type CreateMacroMutationOptions = Apollo.BaseMutationOptions<
  CreateMacroMutation,
  CreateMacroMutationVariables
>;
export const UpdateMacroDocument = gql`
  mutation UpdateMacro($input: UpdateMacroInput!, $updateMacroId: String!) {
    updateMacro(input: $input, id: $updateMacroId) {
      ...Macro
    }
  }
  ${MacroFragmentDoc}
`;
export type UpdateMacroMutationFn = Apollo.MutationFunction<
  UpdateMacroMutation,
  UpdateMacroMutationVariables
>;

/**
 * __useUpdateMacroMutation__
 *
 * To run a mutation, you first call `useUpdateMacroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMacroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMacroMutation, { data, loading, error }] = useUpdateMacroMutation({
 *   variables: {
 *      input: // value for 'input'
 *      updateMacroId: // value for 'updateMacroId'
 *   },
 * });
 */
export function useUpdateMacroMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMacroMutation,
    UpdateMacroMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMacroMutation, UpdateMacroMutationVariables>(
    UpdateMacroDocument,
    options,
  );
}
export type UpdateMacroMutationHookResult = ReturnType<
  typeof useUpdateMacroMutation
>;
export type UpdateMacroMutationResult =
  Apollo.MutationResult<UpdateMacroMutation>;
export type UpdateMacroMutationOptions = Apollo.BaseMutationOptions<
  UpdateMacroMutation,
  UpdateMacroMutationVariables
>;
export const RemoveMacroDocument = gql`
  mutation RemoveMacro($removeMacroId: String!) {
    removeMacro(id: $removeMacroId) {
      success
    }
  }
`;
export type RemoveMacroMutationFn = Apollo.MutationFunction<
  RemoveMacroMutation,
  RemoveMacroMutationVariables
>;

/**
 * __useRemoveMacroMutation__
 *
 * To run a mutation, you first call `useRemoveMacroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMacroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMacroMutation, { data, loading, error }] = useRemoveMacroMutation({
 *   variables: {
 *      removeMacroId: // value for 'removeMacroId'
 *   },
 * });
 */
export function useRemoveMacroMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMacroMutation,
    RemoveMacroMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMacroMutation, RemoveMacroMutationVariables>(
    RemoveMacroDocument,
    options,
  );
}
export type RemoveMacroMutationHookResult = ReturnType<
  typeof useRemoveMacroMutation
>;
export type RemoveMacroMutationResult =
  Apollo.MutationResult<RemoveMacroMutation>;
export type RemoveMacroMutationOptions = Apollo.BaseMutationOptions<
  RemoveMacroMutation,
  RemoveMacroMutationVariables
>;
export const FindMessageTemplatesDocument = gql`
  query FindMessageTemplates(
    $filter: MessageTemplatesFilter
    $pagination: Pagination
  ) {
    findMessageTemplates(filter: $filter, pagination: $pagination) {
      items {
        ...MessageTemplate
      }
      hasNext
      nextId
      nextCreatedAt
      total
    }
  }
  ${MessageTemplateFragmentDoc}
`;

/**
 * __useFindMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useFindMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMessageTemplatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindMessageTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindMessageTemplatesQuery,
    FindMessageTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindMessageTemplatesQuery,
    FindMessageTemplatesQueryVariables
  >(FindMessageTemplatesDocument, options);
}
export function useFindMessageTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindMessageTemplatesQuery,
    FindMessageTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindMessageTemplatesQuery,
    FindMessageTemplatesQueryVariables
  >(FindMessageTemplatesDocument, options);
}
export function useFindMessageTemplatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindMessageTemplatesQuery,
        FindMessageTemplatesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindMessageTemplatesQuery,
    FindMessageTemplatesQueryVariables
  >(FindMessageTemplatesDocument, options);
}
export type FindMessageTemplatesQueryHookResult = ReturnType<
  typeof useFindMessageTemplatesQuery
>;
export type FindMessageTemplatesLazyQueryHookResult = ReturnType<
  typeof useFindMessageTemplatesLazyQuery
>;
export type FindMessageTemplatesSuspenseQueryHookResult = ReturnType<
  typeof useFindMessageTemplatesSuspenseQuery
>;
export type FindMessageTemplatesQueryResult = Apollo.QueryResult<
  FindMessageTemplatesQuery,
  FindMessageTemplatesQueryVariables
>;
export const GetMessageTemplateDocument = gql`
  query GetMessageTemplate($id: String!) {
    getMessageTemplate(id: $id) {
      ...MessageTemplate
    }
  }
  ${MessageTemplateFragmentDoc}
`;

/**
 * __useGetMessageTemplateQuery__
 *
 * To run a query within a React component, call `useGetMessageTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMessageTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMessageTemplateQuery,
    GetMessageTemplateQueryVariables
  > &
    (
      | { variables: GetMessageTemplateQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMessageTemplateQuery,
    GetMessageTemplateQueryVariables
  >(GetMessageTemplateDocument, options);
}
export function useGetMessageTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMessageTemplateQuery,
    GetMessageTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMessageTemplateQuery,
    GetMessageTemplateQueryVariables
  >(GetMessageTemplateDocument, options);
}
export function useGetMessageTemplateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMessageTemplateQuery,
        GetMessageTemplateQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMessageTemplateQuery,
    GetMessageTemplateQueryVariables
  >(GetMessageTemplateDocument, options);
}
export type GetMessageTemplateQueryHookResult = ReturnType<
  typeof useGetMessageTemplateQuery
>;
export type GetMessageTemplateLazyQueryHookResult = ReturnType<
  typeof useGetMessageTemplateLazyQuery
>;
export type GetMessageTemplateSuspenseQueryHookResult = ReturnType<
  typeof useGetMessageTemplateSuspenseQuery
>;
export type GetMessageTemplateQueryResult = Apollo.QueryResult<
  GetMessageTemplateQuery,
  GetMessageTemplateQueryVariables
>;
export const FindMessageTemplatesForFuckingFirestoreDocument = gql`
  query FindMessageTemplatesForFuckingFirestore(
    $filter: MessageTemplatesFilter
  ) {
    findMessageTemplatesForFuckingFirestore(filter: $filter) {
      ...MessageTemplate
    }
  }
  ${MessageTemplateFragmentDoc}
`;

/**
 * __useFindMessageTemplatesForFuckingFirestoreQuery__
 *
 * To run a query within a React component, call `useFindMessageTemplatesForFuckingFirestoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMessageTemplatesForFuckingFirestoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMessageTemplatesForFuckingFirestoreQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindMessageTemplatesForFuckingFirestoreQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindMessageTemplatesForFuckingFirestoreQuery,
    FindMessageTemplatesForFuckingFirestoreQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindMessageTemplatesForFuckingFirestoreQuery,
    FindMessageTemplatesForFuckingFirestoreQueryVariables
  >(FindMessageTemplatesForFuckingFirestoreDocument, options);
}
export function useFindMessageTemplatesForFuckingFirestoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindMessageTemplatesForFuckingFirestoreQuery,
    FindMessageTemplatesForFuckingFirestoreQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindMessageTemplatesForFuckingFirestoreQuery,
    FindMessageTemplatesForFuckingFirestoreQueryVariables
  >(FindMessageTemplatesForFuckingFirestoreDocument, options);
}
export function useFindMessageTemplatesForFuckingFirestoreSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindMessageTemplatesForFuckingFirestoreQuery,
        FindMessageTemplatesForFuckingFirestoreQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindMessageTemplatesForFuckingFirestoreQuery,
    FindMessageTemplatesForFuckingFirestoreQueryVariables
  >(FindMessageTemplatesForFuckingFirestoreDocument, options);
}
export type FindMessageTemplatesForFuckingFirestoreQueryHookResult = ReturnType<
  typeof useFindMessageTemplatesForFuckingFirestoreQuery
>;
export type FindMessageTemplatesForFuckingFirestoreLazyQueryHookResult =
  ReturnType<typeof useFindMessageTemplatesForFuckingFirestoreLazyQuery>;
export type FindMessageTemplatesForFuckingFirestoreSuspenseQueryHookResult =
  ReturnType<typeof useFindMessageTemplatesForFuckingFirestoreSuspenseQuery>;
export type FindMessageTemplatesForFuckingFirestoreQueryResult =
  Apollo.QueryResult<
    FindMessageTemplatesForFuckingFirestoreQuery,
    FindMessageTemplatesForFuckingFirestoreQueryVariables
  >;
export const CreateMessageTemplateDocument = gql`
  mutation CreateMessageTemplate($input: CreateMessageTemplateInput!) {
    createMessageTemplate(input: $input) {
      ...MessageTemplate
    }
  }
  ${MessageTemplateFragmentDoc}
`;
export type CreateMessageTemplateMutationFn = Apollo.MutationFunction<
  CreateMessageTemplateMutation,
  CreateMessageTemplateMutationVariables
>;

/**
 * __useCreateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageTemplateMutation, { data, loading, error }] = useCreateMessageTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMessageTemplateMutation,
    CreateMessageTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMessageTemplateMutation,
    CreateMessageTemplateMutationVariables
  >(CreateMessageTemplateDocument, options);
}
export type CreateMessageTemplateMutationHookResult = ReturnType<
  typeof useCreateMessageTemplateMutation
>;
export type CreateMessageTemplateMutationResult =
  Apollo.MutationResult<CreateMessageTemplateMutation>;
export type CreateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateMessageTemplateMutation,
  CreateMessageTemplateMutationVariables
>;
export const UpdateMessageTemplateDocument = gql`
  mutation UpdateMessageTemplate(
    $input: UpdateMessageTemplateInput!
    $updateMessageTemplateId: String!
  ) {
    updateMessageTemplate(input: $input, id: $updateMessageTemplateId) {
      ...MessageTemplate
    }
  }
  ${MessageTemplateFragmentDoc}
`;
export type UpdateMessageTemplateMutationFn = Apollo.MutationFunction<
  UpdateMessageTemplateMutation,
  UpdateMessageTemplateMutationVariables
>;

/**
 * __useUpdateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageTemplateMutation, { data, loading, error }] = useUpdateMessageTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      updateMessageTemplateId: // value for 'updateMessageTemplateId'
 *   },
 * });
 */
export function useUpdateMessageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMessageTemplateMutation,
    UpdateMessageTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMessageTemplateMutation,
    UpdateMessageTemplateMutationVariables
  >(UpdateMessageTemplateDocument, options);
}
export type UpdateMessageTemplateMutationHookResult = ReturnType<
  typeof useUpdateMessageTemplateMutation
>;
export type UpdateMessageTemplateMutationResult =
  Apollo.MutationResult<UpdateMessageTemplateMutation>;
export type UpdateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateMessageTemplateMutation,
  UpdateMessageTemplateMutationVariables
>;
export const RemoveMessageTemplateDocument = gql`
  mutation RemoveMessageTemplate($removeMessageTemplateId: String!) {
    removeMessageTemplate(id: $removeMessageTemplateId) {
      success
    }
  }
`;
export type RemoveMessageTemplateMutationFn = Apollo.MutationFunction<
  RemoveMessageTemplateMutation,
  RemoveMessageTemplateMutationVariables
>;

/**
 * __useRemoveMessageTemplateMutation__
 *
 * To run a mutation, you first call `useRemoveMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMessageTemplateMutation, { data, loading, error }] = useRemoveMessageTemplateMutation({
 *   variables: {
 *      removeMessageTemplateId: // value for 'removeMessageTemplateId'
 *   },
 * });
 */
export function useRemoveMessageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMessageTemplateMutation,
    RemoveMessageTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveMessageTemplateMutation,
    RemoveMessageTemplateMutationVariables
  >(RemoveMessageTemplateDocument, options);
}
export type RemoveMessageTemplateMutationHookResult = ReturnType<
  typeof useRemoveMessageTemplateMutation
>;
export type RemoveMessageTemplateMutationResult =
  Apollo.MutationResult<RemoveMessageTemplateMutation>;
export type RemoveMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
  RemoveMessageTemplateMutation,
  RemoveMessageTemplateMutationVariables
>;
export const RankMessageTemplateDocument = gql`
  mutation RankMessageTemplate(
    $input: RankMessageTemplateInput!
    $rankMessageTemplateId: String!
  ) {
    rankMessageTemplate(input: $input, id: $rankMessageTemplateId) {
      success
    }
  }
`;
export type RankMessageTemplateMutationFn = Apollo.MutationFunction<
  RankMessageTemplateMutation,
  RankMessageTemplateMutationVariables
>;

/**
 * __useRankMessageTemplateMutation__
 *
 * To run a mutation, you first call `useRankMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRankMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rankMessageTemplateMutation, { data, loading, error }] = useRankMessageTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      rankMessageTemplateId: // value for 'rankMessageTemplateId'
 *   },
 * });
 */
export function useRankMessageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RankMessageTemplateMutation,
    RankMessageTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RankMessageTemplateMutation,
    RankMessageTemplateMutationVariables
  >(RankMessageTemplateDocument, options);
}
export type RankMessageTemplateMutationHookResult = ReturnType<
  typeof useRankMessageTemplateMutation
>;
export type RankMessageTemplateMutationResult =
  Apollo.MutationResult<RankMessageTemplateMutation>;
export type RankMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
  RankMessageTemplateMutation,
  RankMessageTemplateMutationVariables
>;
export const BuildMessageTemplatesDocument = gql`
  query BuildMessageTemplates($input: BuildMessageTemplateInput!) {
    buildMessageTemplates(input: $input) {
      ...MessageTemplate
    }
  }
  ${MessageTemplateFragmentDoc}
`;

/**
 * __useBuildMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useBuildMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildMessageTemplatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildMessageTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    BuildMessageTemplatesQuery,
    BuildMessageTemplatesQueryVariables
  > &
    (
      | { variables: BuildMessageTemplatesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BuildMessageTemplatesQuery,
    BuildMessageTemplatesQueryVariables
  >(BuildMessageTemplatesDocument, options);
}
export function useBuildMessageTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BuildMessageTemplatesQuery,
    BuildMessageTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BuildMessageTemplatesQuery,
    BuildMessageTemplatesQueryVariables
  >(BuildMessageTemplatesDocument, options);
}
export function useBuildMessageTemplatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BuildMessageTemplatesQuery,
        BuildMessageTemplatesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BuildMessageTemplatesQuery,
    BuildMessageTemplatesQueryVariables
  >(BuildMessageTemplatesDocument, options);
}
export type BuildMessageTemplatesQueryHookResult = ReturnType<
  typeof useBuildMessageTemplatesQuery
>;
export type BuildMessageTemplatesLazyQueryHookResult = ReturnType<
  typeof useBuildMessageTemplatesLazyQuery
>;
export type BuildMessageTemplatesSuspenseQueryHookResult = ReturnType<
  typeof useBuildMessageTemplatesSuspenseQuery
>;
export type BuildMessageTemplatesQueryResult = Apollo.QueryResult<
  BuildMessageTemplatesQuery,
  BuildMessageTemplatesQueryVariables
>;
export const GetMessageTemplateSignedUrlDocument = gql`
  query GetMessageTemplateSignedUrl($input: GenerateSignedUrlInput!) {
    getMessageTemplateSignedUrl(input: $input) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useGetMessageTemplateSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetMessageTemplateSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageTemplateSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageTemplateSignedUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMessageTemplateSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMessageTemplateSignedUrlQuery,
    GetMessageTemplateSignedUrlQueryVariables
  > &
    (
      | { variables: GetMessageTemplateSignedUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMessageTemplateSignedUrlQuery,
    GetMessageTemplateSignedUrlQueryVariables
  >(GetMessageTemplateSignedUrlDocument, options);
}
export function useGetMessageTemplateSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMessageTemplateSignedUrlQuery,
    GetMessageTemplateSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMessageTemplateSignedUrlQuery,
    GetMessageTemplateSignedUrlQueryVariables
  >(GetMessageTemplateSignedUrlDocument, options);
}
export function useGetMessageTemplateSignedUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMessageTemplateSignedUrlQuery,
        GetMessageTemplateSignedUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMessageTemplateSignedUrlQuery,
    GetMessageTemplateSignedUrlQueryVariables
  >(GetMessageTemplateSignedUrlDocument, options);
}
export type GetMessageTemplateSignedUrlQueryHookResult = ReturnType<
  typeof useGetMessageTemplateSignedUrlQuery
>;
export type GetMessageTemplateSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetMessageTemplateSignedUrlLazyQuery
>;
export type GetMessageTemplateSignedUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetMessageTemplateSignedUrlSuspenseQuery
>;
export type GetMessageTemplateSignedUrlQueryResult = Apollo.QueryResult<
  GetMessageTemplateSignedUrlQuery,
  GetMessageTemplateSignedUrlQueryVariables
>;
export const GetMessagingProfileDocument = gql`
  query GetMessagingProfile {
    getMessagingProfile {
      ...MessagingProfile
    }
  }
  ${MessagingProfileFragmentDoc}
`;

/**
 * __useGetMessagingProfileQuery__
 *
 * To run a query within a React component, call `useGetMessagingProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagingProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagingProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMessagingProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMessagingProfileQuery,
    GetMessagingProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMessagingProfileQuery,
    GetMessagingProfileQueryVariables
  >(GetMessagingProfileDocument, options);
}
export function useGetMessagingProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMessagingProfileQuery,
    GetMessagingProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMessagingProfileQuery,
    GetMessagingProfileQueryVariables
  >(GetMessagingProfileDocument, options);
}
export function useGetMessagingProfileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMessagingProfileQuery,
        GetMessagingProfileQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMessagingProfileQuery,
    GetMessagingProfileQueryVariables
  >(GetMessagingProfileDocument, options);
}
export type GetMessagingProfileQueryHookResult = ReturnType<
  typeof useGetMessagingProfileQuery
>;
export type GetMessagingProfileLazyQueryHookResult = ReturnType<
  typeof useGetMessagingProfileLazyQuery
>;
export type GetMessagingProfileSuspenseQueryHookResult = ReturnType<
  typeof useGetMessagingProfileSuspenseQuery
>;
export type GetMessagingProfileQueryResult = Apollo.QueryResult<
  GetMessagingProfileQuery,
  GetMessagingProfileQueryVariables
>;
export const UpdateMessagingProfileDocument = gql`
  mutation UpdateMessagingProfile($input: UpdateMessagingProfileInput!) {
    updateMessagingProfile(input: $input) {
      id
    }
  }
`;
export type UpdateMessagingProfileMutationFn = Apollo.MutationFunction<
  UpdateMessagingProfileMutation,
  UpdateMessagingProfileMutationVariables
>;

/**
 * __useUpdateMessagingProfileMutation__
 *
 * To run a mutation, you first call `useUpdateMessagingProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessagingProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessagingProfileMutation, { data, loading, error }] = useUpdateMessagingProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMessagingProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMessagingProfileMutation,
    UpdateMessagingProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMessagingProfileMutation,
    UpdateMessagingProfileMutationVariables
  >(UpdateMessagingProfileDocument, options);
}
export type UpdateMessagingProfileMutationHookResult = ReturnType<
  typeof useUpdateMessagingProfileMutation
>;
export type UpdateMessagingProfileMutationResult =
  Apollo.MutationResult<UpdateMessagingProfileMutation>;
export type UpdateMessagingProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateMessagingProfileMutation,
  UpdateMessagingProfileMutationVariables
>;
export const FindNotificationsDocument = gql`
  query FindNotifications($pagination: Pagination) {
    findNotifications(pagination: $pagination) {
      hasNext
      items {
        ...Notification
      }
      nextCreatedAt
      total
      nextId
    }
  }
  ${NotificationFragmentDoc}
`;

/**
 * __useFindNotificationsQuery__
 *
 * To run a query within a React component, call `useFindNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindNotificationsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindNotificationsQuery,
    FindNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindNotificationsQuery,
    FindNotificationsQueryVariables
  >(FindNotificationsDocument, options);
}
export function useFindNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindNotificationsQuery,
    FindNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindNotificationsQuery,
    FindNotificationsQueryVariables
  >(FindNotificationsDocument, options);
}
export function useFindNotificationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindNotificationsQuery,
        FindNotificationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindNotificationsQuery,
    FindNotificationsQueryVariables
  >(FindNotificationsDocument, options);
}
export type FindNotificationsQueryHookResult = ReturnType<
  typeof useFindNotificationsQuery
>;
export type FindNotificationsLazyQueryHookResult = ReturnType<
  typeof useFindNotificationsLazyQuery
>;
export type FindNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useFindNotificationsSuspenseQuery
>;
export type FindNotificationsQueryResult = Apollo.QueryResult<
  FindNotificationsQuery,
  FindNotificationsQueryVariables
>;
export const GetNotificationSettingsDocument = gql`
  query GetNotificationSettings {
    getNotificationSettings {
      ...NotificationSettings
    }
  }
  ${NotificationSettingsFragmentDoc}
`;

/**
 * __useGetNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useGetNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNotificationSettingsQuery,
    GetNotificationSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNotificationSettingsQuery,
    GetNotificationSettingsQueryVariables
  >(GetNotificationSettingsDocument, options);
}
export function useGetNotificationSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationSettingsQuery,
    GetNotificationSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotificationSettingsQuery,
    GetNotificationSettingsQueryVariables
  >(GetNotificationSettingsDocument, options);
}
export function useGetNotificationSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetNotificationSettingsQuery,
        GetNotificationSettingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetNotificationSettingsQuery,
    GetNotificationSettingsQueryVariables
  >(GetNotificationSettingsDocument, options);
}
export type GetNotificationSettingsQueryHookResult = ReturnType<
  typeof useGetNotificationSettingsQuery
>;
export type GetNotificationSettingsLazyQueryHookResult = ReturnType<
  typeof useGetNotificationSettingsLazyQuery
>;
export type GetNotificationSettingsSuspenseQueryHookResult = ReturnType<
  typeof useGetNotificationSettingsSuspenseQuery
>;
export type GetNotificationSettingsQueryResult = Apollo.QueryResult<
  GetNotificationSettingsQuery,
  GetNotificationSettingsQueryVariables
>;
export const GetUnreadNotificationCounterDocument = gql`
  query GetUnreadNotificationCounter {
    getUnreadNotificationCounter {
      accountId
      unreadCount
    }
  }
`;

/**
 * __useGetUnreadNotificationCounterQuery__
 *
 * To run a query within a React component, call `useGetUnreadNotificationCounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadNotificationCounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadNotificationCounterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnreadNotificationCounterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUnreadNotificationCounterQuery,
    GetUnreadNotificationCounterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUnreadNotificationCounterQuery,
    GetUnreadNotificationCounterQueryVariables
  >(GetUnreadNotificationCounterDocument, options);
}
export function useGetUnreadNotificationCounterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnreadNotificationCounterQuery,
    GetUnreadNotificationCounterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUnreadNotificationCounterQuery,
    GetUnreadNotificationCounterQueryVariables
  >(GetUnreadNotificationCounterDocument, options);
}
export function useGetUnreadNotificationCounterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUnreadNotificationCounterQuery,
        GetUnreadNotificationCounterQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUnreadNotificationCounterQuery,
    GetUnreadNotificationCounterQueryVariables
  >(GetUnreadNotificationCounterDocument, options);
}
export type GetUnreadNotificationCounterQueryHookResult = ReturnType<
  typeof useGetUnreadNotificationCounterQuery
>;
export type GetUnreadNotificationCounterLazyQueryHookResult = ReturnType<
  typeof useGetUnreadNotificationCounterLazyQuery
>;
export type GetUnreadNotificationCounterSuspenseQueryHookResult = ReturnType<
  typeof useGetUnreadNotificationCounterSuspenseQuery
>;
export type GetUnreadNotificationCounterQueryResult = Apollo.QueryResult<
  GetUnreadNotificationCounterQuery,
  GetUnreadNotificationCounterQueryVariables
>;
export const MarkNotificationsReadDocument = gql`
  mutation MarkNotificationsRead($input: MarkNotificationsReadInput!) {
    markNotificationsRead(input: $input) {
      success
    }
  }
`;
export type MarkNotificationsReadMutationFn = Apollo.MutationFunction<
  MarkNotificationsReadMutation,
  MarkNotificationsReadMutationVariables
>;

/**
 * __useMarkNotificationsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsReadMutation, { data, loading, error }] = useMarkNotificationsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkNotificationsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkNotificationsReadMutation,
    MarkNotificationsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkNotificationsReadMutation,
    MarkNotificationsReadMutationVariables
  >(MarkNotificationsReadDocument, options);
}
export type MarkNotificationsReadMutationHookResult = ReturnType<
  typeof useMarkNotificationsReadMutation
>;
export type MarkNotificationsReadMutationResult =
  Apollo.MutationResult<MarkNotificationsReadMutation>;
export type MarkNotificationsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkNotificationsReadMutation,
  MarkNotificationsReadMutationVariables
>;
export const MarkNotificationsSeenDocument = gql`
  mutation MarkNotificationsSeen($input: MarkNotificationsSeenInput!) {
    markNotificationsSeen(input: $input) {
      success
    }
  }
`;
export type MarkNotificationsSeenMutationFn = Apollo.MutationFunction<
  MarkNotificationsSeenMutation,
  MarkNotificationsSeenMutationVariables
>;

/**
 * __useMarkNotificationsSeenMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsSeenMutation, { data, loading, error }] = useMarkNotificationsSeenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkNotificationsSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkNotificationsSeenMutation,
    MarkNotificationsSeenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkNotificationsSeenMutation,
    MarkNotificationsSeenMutationVariables
  >(MarkNotificationsSeenDocument, options);
}
export type MarkNotificationsSeenMutationHookResult = ReturnType<
  typeof useMarkNotificationsSeenMutation
>;
export type MarkNotificationsSeenMutationResult =
  Apollo.MutationResult<MarkNotificationsSeenMutation>;
export type MarkNotificationsSeenMutationOptions = Apollo.BaseMutationOptions<
  MarkNotificationsSeenMutation,
  MarkNotificationsSeenMutationVariables
>;
export const UpdateNotificationSettingsDocument = gql`
  mutation UpdateNotificationSettings(
    $input: UpdateNotificationSettingsInput!
  ) {
    updateNotificationSettings(input: $input) {
      success
    }
  }
`;
export type UpdateNotificationSettingsMutationFn = Apollo.MutationFunction<
  UpdateNotificationSettingsMutation,
  UpdateNotificationSettingsMutationVariables
>;

/**
 * __useUpdateNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingsMutation, { data, loading, error }] = useUpdateNotificationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationSettingsMutation,
    UpdateNotificationSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNotificationSettingsMutation,
    UpdateNotificationSettingsMutationVariables
  >(UpdateNotificationSettingsDocument, options);
}
export type UpdateNotificationSettingsMutationHookResult = ReturnType<
  typeof useUpdateNotificationSettingsMutation
>;
export type UpdateNotificationSettingsMutationResult =
  Apollo.MutationResult<UpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateNotificationSettingsMutation,
    UpdateNotificationSettingsMutationVariables
  >;
export const NotificationCreatedDocument = gql`
  subscription NotificationCreated {
    notificationCreated {
      id
      type
      isRead
      isSeen
      createdAt
      details {
        ...DetailsNotification
      }
    }
  }
  ${DetailsNotificationFragmentDoc}
`;

/**
 * __useNotificationCreatedSubscription__
 *
 * To run a query within a React component, call `useNotificationCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationCreatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NotificationCreatedSubscription,
    NotificationCreatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NotificationCreatedSubscription,
    NotificationCreatedSubscriptionVariables
  >(NotificationCreatedDocument, options);
}
export type NotificationCreatedSubscriptionHookResult = ReturnType<
  typeof useNotificationCreatedSubscription
>;
export type NotificationCreatedSubscriptionResult =
  Apollo.SubscriptionResult<NotificationCreatedSubscription>;
export const NotificationUnreadCounterUpdatedDocument = gql`
  subscription NotificationUnreadCounterUpdated {
    notificationUnreadCounterUpdated {
      accountId
      unreadCount
    }
  }
`;

/**
 * __useNotificationUnreadCounterUpdatedSubscription__
 *
 * To run a query within a React component, call `useNotificationUnreadCounterUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationUnreadCounterUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationUnreadCounterUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationUnreadCounterUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NotificationUnreadCounterUpdatedSubscription,
    NotificationUnreadCounterUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NotificationUnreadCounterUpdatedSubscription,
    NotificationUnreadCounterUpdatedSubscriptionVariables
  >(NotificationUnreadCounterUpdatedDocument, options);
}
export type NotificationUnreadCounterUpdatedSubscriptionHookResult = ReturnType<
  typeof useNotificationUnreadCounterUpdatedSubscription
>;
export type NotificationUnreadCounterUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<NotificationUnreadCounterUpdatedSubscription>;
export const FindAvailablePhonesDocument = gql`
  query FindAvailablePhones($filter: FindAvailablePhonesFilter!) {
    findAvailablePhones(filter: $filter) {
      phone
    }
  }
`;

/**
 * __useFindAvailablePhonesQuery__
 *
 * To run a query within a React component, call `useFindAvailablePhonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAvailablePhonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAvailablePhonesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindAvailablePhonesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAvailablePhonesQuery,
    FindAvailablePhonesQueryVariables
  > &
    (
      | { variables: FindAvailablePhonesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAvailablePhonesQuery,
    FindAvailablePhonesQueryVariables
  >(FindAvailablePhonesDocument, options);
}
export function useFindAvailablePhonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAvailablePhonesQuery,
    FindAvailablePhonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAvailablePhonesQuery,
    FindAvailablePhonesQueryVariables
  >(FindAvailablePhonesDocument, options);
}
export function useFindAvailablePhonesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindAvailablePhonesQuery,
        FindAvailablePhonesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindAvailablePhonesQuery,
    FindAvailablePhonesQueryVariables
  >(FindAvailablePhonesDocument, options);
}
export type FindAvailablePhonesQueryHookResult = ReturnType<
  typeof useFindAvailablePhonesQuery
>;
export type FindAvailablePhonesLazyQueryHookResult = ReturnType<
  typeof useFindAvailablePhonesLazyQuery
>;
export type FindAvailablePhonesSuspenseQueryHookResult = ReturnType<
  typeof useFindAvailablePhonesSuspenseQuery
>;
export type FindAvailablePhonesQueryResult = Apollo.QueryResult<
  FindAvailablePhonesQuery,
  FindAvailablePhonesQueryVariables
>;
export const FindOwnPhonesDocument = gql`
  query FindOwnPhones($filter: OwnPhoneFilter, $pagination: Pagination) {
    findOwnPhones(filter: $filter, pagination: $pagination) {
      items {
        ...OwnPhone
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${OwnPhoneFragmentDoc}
`;

/**
 * __useFindOwnPhonesQuery__
 *
 * To run a query within a React component, call `useFindOwnPhonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOwnPhonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOwnPhonesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindOwnPhonesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindOwnPhonesQuery,
    FindOwnPhonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindOwnPhonesQuery, FindOwnPhonesQueryVariables>(
    FindOwnPhonesDocument,
    options,
  );
}
export function useFindOwnPhonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindOwnPhonesQuery,
    FindOwnPhonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindOwnPhonesQuery, FindOwnPhonesQueryVariables>(
    FindOwnPhonesDocument,
    options,
  );
}
export function useFindOwnPhonesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindOwnPhonesQuery,
        FindOwnPhonesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindOwnPhonesQuery,
    FindOwnPhonesQueryVariables
  >(FindOwnPhonesDocument, options);
}
export type FindOwnPhonesQueryHookResult = ReturnType<
  typeof useFindOwnPhonesQuery
>;
export type FindOwnPhonesLazyQueryHookResult = ReturnType<
  typeof useFindOwnPhonesLazyQuery
>;
export type FindOwnPhonesSuspenseQueryHookResult = ReturnType<
  typeof useFindOwnPhonesSuspenseQuery
>;
export type FindOwnPhonesQueryResult = Apollo.QueryResult<
  FindOwnPhonesQuery,
  FindOwnPhonesQueryVariables
>;
export const FindOwnPhonesReportDocument = gql`
  query FindOwnPhonesReport($filter: OwnPhoneFilter, $pagination: Pagination) {
    findOwnPhones(filter: $filter, pagination: $pagination) {
      items {
        ...OwnPhoneReport
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${OwnPhoneReportFragmentDoc}
`;

/**
 * __useFindOwnPhonesReportQuery__
 *
 * To run a query within a React component, call `useFindOwnPhonesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOwnPhonesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOwnPhonesReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindOwnPhonesReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindOwnPhonesReportQuery,
    FindOwnPhonesReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindOwnPhonesReportQuery,
    FindOwnPhonesReportQueryVariables
  >(FindOwnPhonesReportDocument, options);
}
export function useFindOwnPhonesReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindOwnPhonesReportQuery,
    FindOwnPhonesReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindOwnPhonesReportQuery,
    FindOwnPhonesReportQueryVariables
  >(FindOwnPhonesReportDocument, options);
}
export function useFindOwnPhonesReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindOwnPhonesReportQuery,
        FindOwnPhonesReportQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindOwnPhonesReportQuery,
    FindOwnPhonesReportQueryVariables
  >(FindOwnPhonesReportDocument, options);
}
export type FindOwnPhonesReportQueryHookResult = ReturnType<
  typeof useFindOwnPhonesReportQuery
>;
export type FindOwnPhonesReportLazyQueryHookResult = ReturnType<
  typeof useFindOwnPhonesReportLazyQuery
>;
export type FindOwnPhonesReportSuspenseQueryHookResult = ReturnType<
  typeof useFindOwnPhonesReportSuspenseQuery
>;
export type FindOwnPhonesReportQueryResult = Apollo.QueryResult<
  FindOwnPhonesReportQuery,
  FindOwnPhonesReportQueryVariables
>;
export const GetOwnPhoneCountersDocument = gql`
  query GetOwnPhoneCounters($scope: Scope) {
    getOwnPhoneCounters(scope: $scope) {
      local
      tollFree
    }
  }
`;

/**
 * __useGetOwnPhoneCountersQuery__
 *
 * To run a query within a React component, call `useGetOwnPhoneCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnPhoneCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnPhoneCountersQuery({
 *   variables: {
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetOwnPhoneCountersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOwnPhoneCountersQuery,
    GetOwnPhoneCountersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOwnPhoneCountersQuery,
    GetOwnPhoneCountersQueryVariables
  >(GetOwnPhoneCountersDocument, options);
}
export function useGetOwnPhoneCountersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOwnPhoneCountersQuery,
    GetOwnPhoneCountersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOwnPhoneCountersQuery,
    GetOwnPhoneCountersQueryVariables
  >(GetOwnPhoneCountersDocument, options);
}
export function useGetOwnPhoneCountersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetOwnPhoneCountersQuery,
        GetOwnPhoneCountersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOwnPhoneCountersQuery,
    GetOwnPhoneCountersQueryVariables
  >(GetOwnPhoneCountersDocument, options);
}
export type GetOwnPhoneCountersQueryHookResult = ReturnType<
  typeof useGetOwnPhoneCountersQuery
>;
export type GetOwnPhoneCountersLazyQueryHookResult = ReturnType<
  typeof useGetOwnPhoneCountersLazyQuery
>;
export type GetOwnPhoneCountersSuspenseQueryHookResult = ReturnType<
  typeof useGetOwnPhoneCountersSuspenseQuery
>;
export type GetOwnPhoneCountersQueryResult = Apollo.QueryResult<
  GetOwnPhoneCountersQuery,
  GetOwnPhoneCountersQueryVariables
>;
export const PurchaseOwnPhoneDocument = gql`
  mutation PurchaseOwnPhone($input: PurchaseOwnPhoneInput!) {
    purchaseOwnPhone(input: $input) {
      id
      number
    }
  }
`;
export type PurchaseOwnPhoneMutationFn = Apollo.MutationFunction<
  PurchaseOwnPhoneMutation,
  PurchaseOwnPhoneMutationVariables
>;

/**
 * __usePurchaseOwnPhoneMutation__
 *
 * To run a mutation, you first call `usePurchaseOwnPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOwnPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseOwnPhoneMutation, { data, loading, error }] = usePurchaseOwnPhoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseOwnPhoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PurchaseOwnPhoneMutation,
    PurchaseOwnPhoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PurchaseOwnPhoneMutation,
    PurchaseOwnPhoneMutationVariables
  >(PurchaseOwnPhoneDocument, options);
}
export type PurchaseOwnPhoneMutationHookResult = ReturnType<
  typeof usePurchaseOwnPhoneMutation
>;
export type PurchaseOwnPhoneMutationResult =
  Apollo.MutationResult<PurchaseOwnPhoneMutation>;
export type PurchaseOwnPhoneMutationOptions = Apollo.BaseMutationOptions<
  PurchaseOwnPhoneMutation,
  PurchaseOwnPhoneMutationVariables
>;
export const RemoveOwnPhoneDocument = gql`
  mutation RemoveOwnPhone($id: String!) {
    removeOwnPhone(ownPhoneId: $id) {
      success
    }
  }
`;
export type RemoveOwnPhoneMutationFn = Apollo.MutationFunction<
  RemoveOwnPhoneMutation,
  RemoveOwnPhoneMutationVariables
>;

/**
 * __useRemoveOwnPhoneMutation__
 *
 * To run a mutation, you first call `useRemoveOwnPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOwnPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOwnPhoneMutation, { data, loading, error }] = useRemoveOwnPhoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOwnPhoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOwnPhoneMutation,
    RemoveOwnPhoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveOwnPhoneMutation,
    RemoveOwnPhoneMutationVariables
  >(RemoveOwnPhoneDocument, options);
}
export type RemoveOwnPhoneMutationHookResult = ReturnType<
  typeof useRemoveOwnPhoneMutation
>;
export type RemoveOwnPhoneMutationResult =
  Apollo.MutationResult<RemoveOwnPhoneMutation>;
export type RemoveOwnPhoneMutationOptions = Apollo.BaseMutationOptions<
  RemoveOwnPhoneMutation,
  RemoveOwnPhoneMutationVariables
>;
export const GetActivePlansDocument = gql`
  query GetActivePlans {
    getActivePlans {
      ...Plan
    }
  }
  ${PlanFragmentDoc}
`;

/**
 * __useGetActivePlansQuery__
 *
 * To run a query within a React component, call `useGetActivePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActivePlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActivePlansQuery,
    GetActivePlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActivePlansQuery, GetActivePlansQueryVariables>(
    GetActivePlansDocument,
    options,
  );
}
export function useGetActivePlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivePlansQuery,
    GetActivePlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActivePlansQuery, GetActivePlansQueryVariables>(
    GetActivePlansDocument,
    options,
  );
}
export function useGetActivePlansSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetActivePlansQuery,
        GetActivePlansQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetActivePlansQuery,
    GetActivePlansQueryVariables
  >(GetActivePlansDocument, options);
}
export type GetActivePlansQueryHookResult = ReturnType<
  typeof useGetActivePlansQuery
>;
export type GetActivePlansLazyQueryHookResult = ReturnType<
  typeof useGetActivePlansLazyQuery
>;
export type GetActivePlansSuspenseQueryHookResult = ReturnType<
  typeof useGetActivePlansSuspenseQuery
>;
export type GetActivePlansQueryResult = Apollo.QueryResult<
  GetActivePlansQuery,
  GetActivePlansQueryVariables
>;
export const GetPlansDocument = gql`
  query GetPlans {
    getPlans {
      ...Plan
    }
  }
  ${PlanFragmentDoc}
`;

/**
 * __useGetPlansQuery__
 *
 * To run a query within a React component, call `useGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPlansQuery, GetPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlansQuery, GetPlansQueryVariables>(
    GetPlansDocument,
    options,
  );
}
export function useGetPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlansQuery,
    GetPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlansQuery, GetPlansQueryVariables>(
    GetPlansDocument,
    options,
  );
}
export function useGetPlansSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPlansQuery, GetPlansQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPlansQuery, GetPlansQueryVariables>(
    GetPlansDocument,
    options,
  );
}
export type GetPlansQueryHookResult = ReturnType<typeof useGetPlansQuery>;
export type GetPlansLazyQueryHookResult = ReturnType<
  typeof useGetPlansLazyQuery
>;
export type GetPlansSuspenseQueryHookResult = ReturnType<
  typeof useGetPlansSuspenseQuery
>;
export type GetPlansQueryResult = Apollo.QueryResult<
  GetPlansQuery,
  GetPlansQueryVariables
>;
export const CreateMarketingPopupDocument = gql`
  mutation createMarketingPopup($input: CreateMarketingPopupInput!) {
    createMarketingPopup(input: $input) {
      ...MarketingPopup
    }
  }
  ${MarketingPopupFragmentDoc}
`;
export type CreateMarketingPopupMutationFn = Apollo.MutationFunction<
  CreateMarketingPopupMutation,
  CreateMarketingPopupMutationVariables
>;

/**
 * __useCreateMarketingPopupMutation__
 *
 * To run a mutation, you first call `useCreateMarketingPopupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarketingPopupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarketingPopupMutation, { data, loading, error }] = useCreateMarketingPopupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMarketingPopupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMarketingPopupMutation,
    CreateMarketingPopupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMarketingPopupMutation,
    CreateMarketingPopupMutationVariables
  >(CreateMarketingPopupDocument, options);
}
export type CreateMarketingPopupMutationHookResult = ReturnType<
  typeof useCreateMarketingPopupMutation
>;
export type CreateMarketingPopupMutationResult =
  Apollo.MutationResult<CreateMarketingPopupMutation>;
export type CreateMarketingPopupMutationOptions = Apollo.BaseMutationOptions<
  CreateMarketingPopupMutation,
  CreateMarketingPopupMutationVariables
>;
export const GetMarketingPopupSignedUrlDocument = gql`
  query GetMarketingPopupSignedUrl($input: GenerateSignedUrlInput!) {
    getMarketingPopupSignedUrl(input: $input) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useGetMarketingPopupSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetMarketingPopupSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketingPopupSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketingPopupSignedUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMarketingPopupSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMarketingPopupSignedUrlQuery,
    GetMarketingPopupSignedUrlQueryVariables
  > &
    (
      | { variables: GetMarketingPopupSignedUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMarketingPopupSignedUrlQuery,
    GetMarketingPopupSignedUrlQueryVariables
  >(GetMarketingPopupSignedUrlDocument, options);
}
export function useGetMarketingPopupSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMarketingPopupSignedUrlQuery,
    GetMarketingPopupSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMarketingPopupSignedUrlQuery,
    GetMarketingPopupSignedUrlQueryVariables
  >(GetMarketingPopupSignedUrlDocument, options);
}
export function useGetMarketingPopupSignedUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMarketingPopupSignedUrlQuery,
        GetMarketingPopupSignedUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMarketingPopupSignedUrlQuery,
    GetMarketingPopupSignedUrlQueryVariables
  >(GetMarketingPopupSignedUrlDocument, options);
}
export type GetMarketingPopupSignedUrlQueryHookResult = ReturnType<
  typeof useGetMarketingPopupSignedUrlQuery
>;
export type GetMarketingPopupSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetMarketingPopupSignedUrlLazyQuery
>;
export type GetMarketingPopupSignedUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetMarketingPopupSignedUrlSuspenseQuery
>;
export type GetMarketingPopupSignedUrlQueryResult = Apollo.QueryResult<
  GetMarketingPopupSignedUrlQuery,
  GetMarketingPopupSignedUrlQueryVariables
>;
export const FindMarketingPopupsDocument = gql`
  query FindMarketingPopups($pagination: Pagination) {
    findMarketingPopups(pagination: $pagination) {
      items {
        ...MarketingPopup
      }
      total
      nextId
      hasNext
      nextCreatedAt
    }
  }
  ${MarketingPopupFragmentDoc}
`;

/**
 * __useFindMarketingPopupsQuery__
 *
 * To run a query within a React component, call `useFindMarketingPopupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMarketingPopupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMarketingPopupsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindMarketingPopupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindMarketingPopupsQuery,
    FindMarketingPopupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindMarketingPopupsQuery,
    FindMarketingPopupsQueryVariables
  >(FindMarketingPopupsDocument, options);
}
export function useFindMarketingPopupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindMarketingPopupsQuery,
    FindMarketingPopupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindMarketingPopupsQuery,
    FindMarketingPopupsQueryVariables
  >(FindMarketingPopupsDocument, options);
}
export function useFindMarketingPopupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindMarketingPopupsQuery,
        FindMarketingPopupsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindMarketingPopupsQuery,
    FindMarketingPopupsQueryVariables
  >(FindMarketingPopupsDocument, options);
}
export type FindMarketingPopupsQueryHookResult = ReturnType<
  typeof useFindMarketingPopupsQuery
>;
export type FindMarketingPopupsLazyQueryHookResult = ReturnType<
  typeof useFindMarketingPopupsLazyQuery
>;
export type FindMarketingPopupsSuspenseQueryHookResult = ReturnType<
  typeof useFindMarketingPopupsSuspenseQuery
>;
export type FindMarketingPopupsQueryResult = Apollo.QueryResult<
  FindMarketingPopupsQuery,
  FindMarketingPopupsQueryVariables
>;
export const RemoveMarketingPopupDocument = gql`
  mutation RemoveMarketingPopup($removeMarketingPopupId: String!) {
    removeMarketingPopup(id: $removeMarketingPopupId) {
      success
    }
  }
`;
export type RemoveMarketingPopupMutationFn = Apollo.MutationFunction<
  RemoveMarketingPopupMutation,
  RemoveMarketingPopupMutationVariables
>;

/**
 * __useRemoveMarketingPopupMutation__
 *
 * To run a mutation, you first call `useRemoveMarketingPopupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMarketingPopupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMarketingPopupMutation, { data, loading, error }] = useRemoveMarketingPopupMutation({
 *   variables: {
 *      removeMarketingPopupId: // value for 'removeMarketingPopupId'
 *   },
 * });
 */
export function useRemoveMarketingPopupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMarketingPopupMutation,
    RemoveMarketingPopupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveMarketingPopupMutation,
    RemoveMarketingPopupMutationVariables
  >(RemoveMarketingPopupDocument, options);
}
export type RemoveMarketingPopupMutationHookResult = ReturnType<
  typeof useRemoveMarketingPopupMutation
>;
export type RemoveMarketingPopupMutationResult =
  Apollo.MutationResult<RemoveMarketingPopupMutation>;
export type RemoveMarketingPopupMutationOptions = Apollo.BaseMutationOptions<
  RemoveMarketingPopupMutation,
  RemoveMarketingPopupMutationVariables
>;
export const GetMarketingPopupDocument = gql`
  query GetMarketingPopup($id: String!) {
    getMarketingPopup(id: $id) {
      id
      status
      audiences
      title
      startDate
      endDate
      description
      template {
        type
        imageSrc
        videoSrc
        buttonText
        buttonLink
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMarketingPopupQuery__
 *
 * To run a query within a React component, call `useGetMarketingPopupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketingPopupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketingPopupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMarketingPopupQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMarketingPopupQuery,
    GetMarketingPopupQueryVariables
  > &
    (
      | { variables: GetMarketingPopupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMarketingPopupQuery,
    GetMarketingPopupQueryVariables
  >(GetMarketingPopupDocument, options);
}
export function useGetMarketingPopupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMarketingPopupQuery,
    GetMarketingPopupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMarketingPopupQuery,
    GetMarketingPopupQueryVariables
  >(GetMarketingPopupDocument, options);
}
export function useGetMarketingPopupSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMarketingPopupQuery,
        GetMarketingPopupQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMarketingPopupQuery,
    GetMarketingPopupQueryVariables
  >(GetMarketingPopupDocument, options);
}
export type GetMarketingPopupQueryHookResult = ReturnType<
  typeof useGetMarketingPopupQuery
>;
export type GetMarketingPopupLazyQueryHookResult = ReturnType<
  typeof useGetMarketingPopupLazyQuery
>;
export type GetMarketingPopupSuspenseQueryHookResult = ReturnType<
  typeof useGetMarketingPopupSuspenseQuery
>;
export type GetMarketingPopupQueryResult = Apollo.QueryResult<
  GetMarketingPopupQuery,
  GetMarketingPopupQueryVariables
>;
export const UpdateMarketingPopupDocument = gql`
  mutation UpdateMarketingPopup(
    $input: UpdateMarketingPopupInput!
    $id: String!
  ) {
    updateMarketingPopup(input: $input, id: $id) {
      id
      status
      audiences
      title
      startDate
      endDate
      description
      template {
        type
        imageSrc
        videoSrc
        buttonText
        buttonLink
      }
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMarketingPopupMutationFn = Apollo.MutationFunction<
  UpdateMarketingPopupMutation,
  UpdateMarketingPopupMutationVariables
>;

/**
 * __useUpdateMarketingPopupMutation__
 *
 * To run a mutation, you first call `useUpdateMarketingPopupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketingPopupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketingPopupMutation, { data, loading, error }] = useUpdateMarketingPopupMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateMarketingPopupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMarketingPopupMutation,
    UpdateMarketingPopupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMarketingPopupMutation,
    UpdateMarketingPopupMutationVariables
  >(UpdateMarketingPopupDocument, options);
}
export type UpdateMarketingPopupMutationHookResult = ReturnType<
  typeof useUpdateMarketingPopupMutation
>;
export type UpdateMarketingPopupMutationResult =
  Apollo.MutationResult<UpdateMarketingPopupMutation>;
export type UpdateMarketingPopupMutationOptions = Apollo.BaseMutationOptions<
  UpdateMarketingPopupMutation,
  UpdateMarketingPopupMutationVariables
>;
export const FindActiveMarketingPopupsDocument = gql`
  query FindActiveMarketingPopups {
    findActiveMarketingPopups {
      id
      status
      title
      description
      template {
        type
        imageSrc
        videoSrc
        buttonText
        buttonLink
      }
    }
  }
`;

/**
 * __useFindActiveMarketingPopupsQuery__
 *
 * To run a query within a React component, call `useFindActiveMarketingPopupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindActiveMarketingPopupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindActiveMarketingPopupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindActiveMarketingPopupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindActiveMarketingPopupsQuery,
    FindActiveMarketingPopupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindActiveMarketingPopupsQuery,
    FindActiveMarketingPopupsQueryVariables
  >(FindActiveMarketingPopupsDocument, options);
}
export function useFindActiveMarketingPopupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindActiveMarketingPopupsQuery,
    FindActiveMarketingPopupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindActiveMarketingPopupsQuery,
    FindActiveMarketingPopupsQueryVariables
  >(FindActiveMarketingPopupsDocument, options);
}
export function useFindActiveMarketingPopupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindActiveMarketingPopupsQuery,
        FindActiveMarketingPopupsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindActiveMarketingPopupsQuery,
    FindActiveMarketingPopupsQueryVariables
  >(FindActiveMarketingPopupsDocument, options);
}
export type FindActiveMarketingPopupsQueryHookResult = ReturnType<
  typeof useFindActiveMarketingPopupsQuery
>;
export type FindActiveMarketingPopupsLazyQueryHookResult = ReturnType<
  typeof useFindActiveMarketingPopupsLazyQuery
>;
export type FindActiveMarketingPopupsSuspenseQueryHookResult = ReturnType<
  typeof useFindActiveMarketingPopupsSuspenseQuery
>;
export type FindActiveMarketingPopupsQueryResult = Apollo.QueryResult<
  FindActiveMarketingPopupsQuery,
  FindActiveMarketingPopupsQueryVariables
>;
export const MarkAccountPopupViewedDocument = gql`
  mutation MarkAccountPopupViewed($input: MarkAccountPopupViewedInput!) {
    markAccountPopupViewed(input: $input) {
      popupId
    }
  }
`;
export type MarkAccountPopupViewedMutationFn = Apollo.MutationFunction<
  MarkAccountPopupViewedMutation,
  MarkAccountPopupViewedMutationVariables
>;

/**
 * __useMarkAccountPopupViewedMutation__
 *
 * To run a mutation, you first call `useMarkAccountPopupViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAccountPopupViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAccountPopupViewedMutation, { data, loading, error }] = useMarkAccountPopupViewedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkAccountPopupViewedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAccountPopupViewedMutation,
    MarkAccountPopupViewedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkAccountPopupViewedMutation,
    MarkAccountPopupViewedMutationVariables
  >(MarkAccountPopupViewedDocument, options);
}
export type MarkAccountPopupViewedMutationHookResult = ReturnType<
  typeof useMarkAccountPopupViewedMutation
>;
export type MarkAccountPopupViewedMutationResult =
  Apollo.MutationResult<MarkAccountPopupViewedMutation>;
export type MarkAccountPopupViewedMutationOptions = Apollo.BaseMutationOptions<
  MarkAccountPopupViewedMutation,
  MarkAccountPopupViewedMutationVariables
>;
export const GetPowerDialerCampaignDocument = gql`
  query GetPowerDialerCampaign($id: String!) {
    getDialerCampaign(id: $id) {
      id
      status
      lines
      labels
      script
      contactsWithOutcome
      contacts
    }
  }
`;

/**
 * __useGetPowerDialerCampaignQuery__
 *
 * To run a query within a React component, call `useGetPowerDialerCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPowerDialerCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPowerDialerCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPowerDialerCampaignQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPowerDialerCampaignQuery,
    GetPowerDialerCampaignQueryVariables
  > &
    (
      | { variables: GetPowerDialerCampaignQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPowerDialerCampaignQuery,
    GetPowerDialerCampaignQueryVariables
  >(GetPowerDialerCampaignDocument, options);
}
export function useGetPowerDialerCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPowerDialerCampaignQuery,
    GetPowerDialerCampaignQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPowerDialerCampaignQuery,
    GetPowerDialerCampaignQueryVariables
  >(GetPowerDialerCampaignDocument, options);
}
export function useGetPowerDialerCampaignSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPowerDialerCampaignQuery,
        GetPowerDialerCampaignQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPowerDialerCampaignQuery,
    GetPowerDialerCampaignQueryVariables
  >(GetPowerDialerCampaignDocument, options);
}
export type GetPowerDialerCampaignQueryHookResult = ReturnType<
  typeof useGetPowerDialerCampaignQuery
>;
export type GetPowerDialerCampaignLazyQueryHookResult = ReturnType<
  typeof useGetPowerDialerCampaignLazyQuery
>;
export type GetPowerDialerCampaignSuspenseQueryHookResult = ReturnType<
  typeof useGetPowerDialerCampaignSuspenseQuery
>;
export type GetPowerDialerCampaignQueryResult = Apollo.QueryResult<
  GetPowerDialerCampaignQuery,
  GetPowerDialerCampaignQueryVariables
>;
export const FindPowerDialerOutcomeLabelsDocument = gql`
  query FindPowerDialerOutcomeLabels($filter: LabelFilter) {
    findLabels(filter: $filter) {
      items {
        id
        title
        color
      }
    }
  }
`;

/**
 * __useFindPowerDialerOutcomeLabelsQuery__
 *
 * To run a query within a React component, call `useFindPowerDialerOutcomeLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPowerDialerOutcomeLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPowerDialerOutcomeLabelsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindPowerDialerOutcomeLabelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindPowerDialerOutcomeLabelsQuery,
    FindPowerDialerOutcomeLabelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindPowerDialerOutcomeLabelsQuery,
    FindPowerDialerOutcomeLabelsQueryVariables
  >(FindPowerDialerOutcomeLabelsDocument, options);
}
export function useFindPowerDialerOutcomeLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindPowerDialerOutcomeLabelsQuery,
    FindPowerDialerOutcomeLabelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindPowerDialerOutcomeLabelsQuery,
    FindPowerDialerOutcomeLabelsQueryVariables
  >(FindPowerDialerOutcomeLabelsDocument, options);
}
export function useFindPowerDialerOutcomeLabelsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindPowerDialerOutcomeLabelsQuery,
        FindPowerDialerOutcomeLabelsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindPowerDialerOutcomeLabelsQuery,
    FindPowerDialerOutcomeLabelsQueryVariables
  >(FindPowerDialerOutcomeLabelsDocument, options);
}
export type FindPowerDialerOutcomeLabelsQueryHookResult = ReturnType<
  typeof useFindPowerDialerOutcomeLabelsQuery
>;
export type FindPowerDialerOutcomeLabelsLazyQueryHookResult = ReturnType<
  typeof useFindPowerDialerOutcomeLabelsLazyQuery
>;
export type FindPowerDialerOutcomeLabelsSuspenseQueryHookResult = ReturnType<
  typeof useFindPowerDialerOutcomeLabelsSuspenseQuery
>;
export type FindPowerDialerOutcomeLabelsQueryResult = Apollo.QueryResult<
  FindPowerDialerOutcomeLabelsQuery,
  FindPowerDialerOutcomeLabelsQueryVariables
>;
export const GeneratePowerDialerWebRtcTokenDocument = gql`
  query GeneratePowerDialerWebRTCToken {
    generatePowerDialerWebRTCToken {
      token
    }
  }
`;

/**
 * __useGeneratePowerDialerWebRtcTokenQuery__
 *
 * To run a query within a React component, call `useGeneratePowerDialerWebRtcTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratePowerDialerWebRtcTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratePowerDialerWebRtcTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeneratePowerDialerWebRtcTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneratePowerDialerWebRtcTokenQuery,
    GeneratePowerDialerWebRtcTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratePowerDialerWebRtcTokenQuery,
    GeneratePowerDialerWebRtcTokenQueryVariables
  >(GeneratePowerDialerWebRtcTokenDocument, options);
}
export function useGeneratePowerDialerWebRtcTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratePowerDialerWebRtcTokenQuery,
    GeneratePowerDialerWebRtcTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratePowerDialerWebRtcTokenQuery,
    GeneratePowerDialerWebRtcTokenQueryVariables
  >(GeneratePowerDialerWebRtcTokenDocument, options);
}
export function useGeneratePowerDialerWebRtcTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratePowerDialerWebRtcTokenQuery,
        GeneratePowerDialerWebRtcTokenQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratePowerDialerWebRtcTokenQuery,
    GeneratePowerDialerWebRtcTokenQueryVariables
  >(GeneratePowerDialerWebRtcTokenDocument, options);
}
export type GeneratePowerDialerWebRtcTokenQueryHookResult = ReturnType<
  typeof useGeneratePowerDialerWebRtcTokenQuery
>;
export type GeneratePowerDialerWebRtcTokenLazyQueryHookResult = ReturnType<
  typeof useGeneratePowerDialerWebRtcTokenLazyQuery
>;
export type GeneratePowerDialerWebRtcTokenSuspenseQueryHookResult = ReturnType<
  typeof useGeneratePowerDialerWebRtcTokenSuspenseQuery
>;
export type GeneratePowerDialerWebRtcTokenQueryResult = Apollo.QueryResult<
  GeneratePowerDialerWebRtcTokenQuery,
  GeneratePowerDialerWebRtcTokenQueryVariables
>;
export const GetPowerDialerCallingBarContactDocument = gql`
  query GetPowerDialerCallingBarContact($id: String!) {
    getContact(id: $id) {
      phone
      firstName
      lastName
    }
  }
`;

/**
 * __useGetPowerDialerCallingBarContactQuery__
 *
 * To run a query within a React component, call `useGetPowerDialerCallingBarContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPowerDialerCallingBarContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPowerDialerCallingBarContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPowerDialerCallingBarContactQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPowerDialerCallingBarContactQuery,
    GetPowerDialerCallingBarContactQueryVariables
  > &
    (
      | {
          variables: GetPowerDialerCallingBarContactQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPowerDialerCallingBarContactQuery,
    GetPowerDialerCallingBarContactQueryVariables
  >(GetPowerDialerCallingBarContactDocument, options);
}
export function useGetPowerDialerCallingBarContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPowerDialerCallingBarContactQuery,
    GetPowerDialerCallingBarContactQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPowerDialerCallingBarContactQuery,
    GetPowerDialerCallingBarContactQueryVariables
  >(GetPowerDialerCallingBarContactDocument, options);
}
export function useGetPowerDialerCallingBarContactSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPowerDialerCallingBarContactQuery,
        GetPowerDialerCallingBarContactQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPowerDialerCallingBarContactQuery,
    GetPowerDialerCallingBarContactQueryVariables
  >(GetPowerDialerCallingBarContactDocument, options);
}
export type GetPowerDialerCallingBarContactQueryHookResult = ReturnType<
  typeof useGetPowerDialerCallingBarContactQuery
>;
export type GetPowerDialerCallingBarContactLazyQueryHookResult = ReturnType<
  typeof useGetPowerDialerCallingBarContactLazyQuery
>;
export type GetPowerDialerCallingBarContactSuspenseQueryHookResult = ReturnType<
  typeof useGetPowerDialerCallingBarContactSuspenseQuery
>;
export type GetPowerDialerCallingBarContactQueryResult = Apollo.QueryResult<
  GetPowerDialerCallingBarContactQuery,
  GetPowerDialerCallingBarContactQueryVariables
>;
export const ManagePowerDialerCallRecordingDocument = gql`
  mutation ManagePowerDialerCallRecording(
    $input: ManagePowerDialerCallRecordingInput!
  ) {
    managePowerDialerCallRecording(input: $input) {
      success
    }
  }
`;
export type ManagePowerDialerCallRecordingMutationFn = Apollo.MutationFunction<
  ManagePowerDialerCallRecordingMutation,
  ManagePowerDialerCallRecordingMutationVariables
>;

/**
 * __useManagePowerDialerCallRecordingMutation__
 *
 * To run a mutation, you first call `useManagePowerDialerCallRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagePowerDialerCallRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managePowerDialerCallRecordingMutation, { data, loading, error }] = useManagePowerDialerCallRecordingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManagePowerDialerCallRecordingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManagePowerDialerCallRecordingMutation,
    ManagePowerDialerCallRecordingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManagePowerDialerCallRecordingMutation,
    ManagePowerDialerCallRecordingMutationVariables
  >(ManagePowerDialerCallRecordingDocument, options);
}
export type ManagePowerDialerCallRecordingMutationHookResult = ReturnType<
  typeof useManagePowerDialerCallRecordingMutation
>;
export type ManagePowerDialerCallRecordingMutationResult =
  Apollo.MutationResult<ManagePowerDialerCallRecordingMutation>;
export type ManagePowerDialerCallRecordingMutationOptions =
  Apollo.BaseMutationOptions<
    ManagePowerDialerCallRecordingMutation,
    ManagePowerDialerCallRecordingMutationVariables
  >;
export const StartPowerDialingDocument = gql`
  mutation StartPowerDialing($input: StartPowerDialingInput!) {
    startPowerDialing(input: $input) {
      success
    }
  }
`;
export type StartPowerDialingMutationFn = Apollo.MutationFunction<
  StartPowerDialingMutation,
  StartPowerDialingMutationVariables
>;

/**
 * __useStartPowerDialingMutation__
 *
 * To run a mutation, you first call `useStartPowerDialingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPowerDialingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPowerDialingMutation, { data, loading, error }] = useStartPowerDialingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartPowerDialingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartPowerDialingMutation,
    StartPowerDialingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartPowerDialingMutation,
    StartPowerDialingMutationVariables
  >(StartPowerDialingDocument, options);
}
export type StartPowerDialingMutationHookResult = ReturnType<
  typeof useStartPowerDialingMutation
>;
export type StartPowerDialingMutationResult =
  Apollo.MutationResult<StartPowerDialingMutation>;
export type StartPowerDialingMutationOptions = Apollo.BaseMutationOptions<
  StartPowerDialingMutation,
  StartPowerDialingMutationVariables
>;
export const HangUpPowerDialerCallDocument = gql`
  mutation HangUpPowerDialerCall($batchId: String!) {
    hangUpPowerDialerCall(batchId: $batchId) {
      success
    }
  }
`;
export type HangUpPowerDialerCallMutationFn = Apollo.MutationFunction<
  HangUpPowerDialerCallMutation,
  HangUpPowerDialerCallMutationVariables
>;

/**
 * __useHangUpPowerDialerCallMutation__
 *
 * To run a mutation, you first call `useHangUpPowerDialerCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHangUpPowerDialerCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hangUpPowerDialerCallMutation, { data, loading, error }] = useHangUpPowerDialerCallMutation({
 *   variables: {
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useHangUpPowerDialerCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HangUpPowerDialerCallMutation,
    HangUpPowerDialerCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HangUpPowerDialerCallMutation,
    HangUpPowerDialerCallMutationVariables
  >(HangUpPowerDialerCallDocument, options);
}
export type HangUpPowerDialerCallMutationHookResult = ReturnType<
  typeof useHangUpPowerDialerCallMutation
>;
export type HangUpPowerDialerCallMutationResult =
  Apollo.MutationResult<HangUpPowerDialerCallMutation>;
export type HangUpPowerDialerCallMutationOptions = Apollo.BaseMutationOptions<
  HangUpPowerDialerCallMutation,
  HangUpPowerDialerCallMutationVariables
>;
export const AddLabelToCallAndContactDocument = gql`
  mutation AddLabelToCallAndContact($input: AddLabelToCallAndContactInput!) {
    addLabelToCallAndContact(input: $input) {
      success
    }
  }
`;
export type AddLabelToCallAndContactMutationFn = Apollo.MutationFunction<
  AddLabelToCallAndContactMutation,
  AddLabelToCallAndContactMutationVariables
>;

/**
 * __useAddLabelToCallAndContactMutation__
 *
 * To run a mutation, you first call `useAddLabelToCallAndContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLabelToCallAndContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLabelToCallAndContactMutation, { data, loading, error }] = useAddLabelToCallAndContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLabelToCallAndContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLabelToCallAndContactMutation,
    AddLabelToCallAndContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddLabelToCallAndContactMutation,
    AddLabelToCallAndContactMutationVariables
  >(AddLabelToCallAndContactDocument, options);
}
export type AddLabelToCallAndContactMutationHookResult = ReturnType<
  typeof useAddLabelToCallAndContactMutation
>;
export type AddLabelToCallAndContactMutationResult =
  Apollo.MutationResult<AddLabelToCallAndContactMutation>;
export type AddLabelToCallAndContactMutationOptions =
  Apollo.BaseMutationOptions<
    AddLabelToCallAndContactMutation,
    AddLabelToCallAndContactMutationVariables
  >;
export const SwitchDialerOfflineDocument = gql`
  mutation SwitchDialerOffline($inPowerDialer: Boolean!) {
    switchDialerOffline(inPowerDialer: $inPowerDialer) {
      success
    }
  }
`;
export type SwitchDialerOfflineMutationFn = Apollo.MutationFunction<
  SwitchDialerOfflineMutation,
  SwitchDialerOfflineMutationVariables
>;

/**
 * __useSwitchDialerOfflineMutation__
 *
 * To run a mutation, you first call `useSwitchDialerOfflineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchDialerOfflineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchDialerOfflineMutation, { data, loading, error }] = useSwitchDialerOfflineMutation({
 *   variables: {
 *      inPowerDialer: // value for 'inPowerDialer'
 *   },
 * });
 */
export function useSwitchDialerOfflineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SwitchDialerOfflineMutation,
    SwitchDialerOfflineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SwitchDialerOfflineMutation,
    SwitchDialerOfflineMutationVariables
  >(SwitchDialerOfflineDocument, options);
}
export type SwitchDialerOfflineMutationHookResult = ReturnType<
  typeof useSwitchDialerOfflineMutation
>;
export type SwitchDialerOfflineMutationResult =
  Apollo.MutationResult<SwitchDialerOfflineMutation>;
export type SwitchDialerOfflineMutationOptions = Apollo.BaseMutationOptions<
  SwitchDialerOfflineMutation,
  SwitchDialerOfflineMutationVariables
>;
export const SwitchDialerOnlineDocument = gql`
  mutation SwitchDialerOnline($campaignId: String!) {
    switchDialerOnline(campaignId: $campaignId) {
      success
    }
  }
`;
export type SwitchDialerOnlineMutationFn = Apollo.MutationFunction<
  SwitchDialerOnlineMutation,
  SwitchDialerOnlineMutationVariables
>;

/**
 * __useSwitchDialerOnlineMutation__
 *
 * To run a mutation, you first call `useSwitchDialerOnlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchDialerOnlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchDialerOnlineMutation, { data, loading, error }] = useSwitchDialerOnlineMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useSwitchDialerOnlineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SwitchDialerOnlineMutation,
    SwitchDialerOnlineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SwitchDialerOnlineMutation,
    SwitchDialerOnlineMutationVariables
  >(SwitchDialerOnlineDocument, options);
}
export type SwitchDialerOnlineMutationHookResult = ReturnType<
  typeof useSwitchDialerOnlineMutation
>;
export type SwitchDialerOnlineMutationResult =
  Apollo.MutationResult<SwitchDialerOnlineMutation>;
export type SwitchDialerOnlineMutationOptions = Apollo.BaseMutationOptions<
  SwitchDialerOnlineMutation,
  SwitchDialerOnlineMutationVariables
>;
export const PowerDialerStateUpdatedDocument = gql`
  subscription PowerDialerStateUpdated {
    powerDialerStateUpdated {
      batchId
      campaignId
      lines {
        ...Line
      }
      connectedLineId
      currentOutboundNumber
      currentContactId
      establishedAt
      duration
      campaignIsCompleted
      showOutcomeLabels
      isConnecting
      isDialing
      hangupIsDisabled
      isRecording
    }
  }
  ${LineFragmentDoc}
`;

/**
 * __usePowerDialerStateUpdatedSubscription__
 *
 * To run a query within a React component, call `usePowerDialerStateUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePowerDialerStateUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowerDialerStateUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function usePowerDialerStateUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    PowerDialerStateUpdatedSubscription,
    PowerDialerStateUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    PowerDialerStateUpdatedSubscription,
    PowerDialerStateUpdatedSubscriptionVariables
  >(PowerDialerStateUpdatedDocument, options);
}
export type PowerDialerStateUpdatedSubscriptionHookResult = ReturnType<
  typeof usePowerDialerStateUpdatedSubscription
>;
export type PowerDialerStateUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<PowerDialerStateUpdatedSubscription>;
export const PowerDialerFailedDocument = gql`
  subscription PowerDialerFailed {
    powerDialerFailed {
      errorType
      errorMessage
    }
  }
`;

/**
 * __usePowerDialerFailedSubscription__
 *
 * To run a query within a React component, call `usePowerDialerFailedSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePowerDialerFailedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowerDialerFailedSubscription({
 *   variables: {
 *   },
 * });
 */
export function usePowerDialerFailedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    PowerDialerFailedSubscription,
    PowerDialerFailedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    PowerDialerFailedSubscription,
    PowerDialerFailedSubscriptionVariables
  >(PowerDialerFailedDocument, options);
}
export type PowerDialerFailedSubscriptionHookResult = ReturnType<
  typeof usePowerDialerFailedSubscription
>;
export type PowerDialerFailedSubscriptionResult =
  Apollo.SubscriptionResult<PowerDialerFailedSubscription>;
export const GetCampaignReportDocument = gql`
  query GetCampaignReport($filter: ReportsFilter!) {
    getCampaignReport(filter: $filter) {
      totalStandardCampaigns
      totalKeywordCampaigns
    }
  }
`;

/**
 * __useGetCampaignReportQuery__
 *
 * To run a query within a React component, call `useGetCampaignReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCampaignReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCampaignReportQuery,
    GetCampaignReportQueryVariables
  > &
    (
      | { variables: GetCampaignReportQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCampaignReportQuery,
    GetCampaignReportQueryVariables
  >(GetCampaignReportDocument, options);
}
export function useGetCampaignReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCampaignReportQuery,
    GetCampaignReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCampaignReportQuery,
    GetCampaignReportQueryVariables
  >(GetCampaignReportDocument, options);
}
export function useGetCampaignReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCampaignReportQuery,
        GetCampaignReportQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCampaignReportQuery,
    GetCampaignReportQueryVariables
  >(GetCampaignReportDocument, options);
}
export type GetCampaignReportQueryHookResult = ReturnType<
  typeof useGetCampaignReportQuery
>;
export type GetCampaignReportLazyQueryHookResult = ReturnType<
  typeof useGetCampaignReportLazyQuery
>;
export type GetCampaignReportSuspenseQueryHookResult = ReturnType<
  typeof useGetCampaignReportSuspenseQuery
>;
export type GetCampaignReportQueryResult = Apollo.QueryResult<
  GetCampaignReportQuery,
  GetCampaignReportQueryVariables
>;
export const GetMessageReportDocument = gql`
  query GetMessageReport($filter: ReportsFilter!) {
    getMessageReport(filter: $filter) {
      incomingTotal
      outgoingTotal
      campaignOutgoingTotal
      campaignOutgoingDelivered
      campaignOutgoingBlocked
      campaignIncomingOptedOut
      campaignAiFilteredMessages
      medianResponseTime
    }
  }
`;

/**
 * __useGetMessageReportQuery__
 *
 * To run a query within a React component, call `useGetMessageReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetMessageReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMessageReportQuery,
    GetMessageReportQueryVariables
  > &
    (
      | { variables: GetMessageReportQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMessageReportQuery, GetMessageReportQueryVariables>(
    GetMessageReportDocument,
    options,
  );
}
export function useGetMessageReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMessageReportQuery,
    GetMessageReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMessageReportQuery,
    GetMessageReportQueryVariables
  >(GetMessageReportDocument, options);
}
export function useGetMessageReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMessageReportQuery,
        GetMessageReportQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMessageReportQuery,
    GetMessageReportQueryVariables
  >(GetMessageReportDocument, options);
}
export type GetMessageReportQueryHookResult = ReturnType<
  typeof useGetMessageReportQuery
>;
export type GetMessageReportLazyQueryHookResult = ReturnType<
  typeof useGetMessageReportLazyQuery
>;
export type GetMessageReportSuspenseQueryHookResult = ReturnType<
  typeof useGetMessageReportSuspenseQuery
>;
export type GetMessageReportQueryResult = Apollo.QueryResult<
  GetMessageReportQuery,
  GetMessageReportQueryVariables
>;
export const GetLabelReportDocument = gql`
  query GetLabelReport($filter: ReportsFilter!) {
    getLabelReport(filter: $filter) {
      id
      title
      contactsWithCurrentLabel
      allLabeledContacts
    }
  }
`;

/**
 * __useGetLabelReportQuery__
 *
 * To run a query within a React component, call `useGetLabelReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetLabelReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLabelReportQuery,
    GetLabelReportQueryVariables
  > &
    (
      | { variables: GetLabelReportQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLabelReportQuery, GetLabelReportQueryVariables>(
    GetLabelReportDocument,
    options,
  );
}
export function useGetLabelReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabelReportQuery,
    GetLabelReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLabelReportQuery, GetLabelReportQueryVariables>(
    GetLabelReportDocument,
    options,
  );
}
export function useGetLabelReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetLabelReportQuery,
        GetLabelReportQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetLabelReportQuery,
    GetLabelReportQueryVariables
  >(GetLabelReportDocument, options);
}
export type GetLabelReportQueryHookResult = ReturnType<
  typeof useGetLabelReportQuery
>;
export type GetLabelReportLazyQueryHookResult = ReturnType<
  typeof useGetLabelReportLazyQuery
>;
export type GetLabelReportSuspenseQueryHookResult = ReturnType<
  typeof useGetLabelReportSuspenseQuery
>;
export type GetLabelReportQueryResult = Apollo.QueryResult<
  GetLabelReportQuery,
  GetLabelReportQueryVariables
>;
export const FindCampaignsReportDocument = gql`
  query FindCampaignsReport($filter: CampaignFilter, $pagination: Pagination) {
    findCampaigns(pagination: $pagination, filter: $filter) {
      items {
        id
        type
        name
        sendFirstMessage
        sequences {
          templates
        }
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
`;

/**
 * __useFindCampaignsReportQuery__
 *
 * To run a query within a React component, call `useFindCampaignsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCampaignsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCampaignsReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindCampaignsReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindCampaignsReportQuery,
    FindCampaignsReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindCampaignsReportQuery,
    FindCampaignsReportQueryVariables
  >(FindCampaignsReportDocument, options);
}
export function useFindCampaignsReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCampaignsReportQuery,
    FindCampaignsReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindCampaignsReportQuery,
    FindCampaignsReportQueryVariables
  >(FindCampaignsReportDocument, options);
}
export function useFindCampaignsReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindCampaignsReportQuery,
        FindCampaignsReportQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindCampaignsReportQuery,
    FindCampaignsReportQueryVariables
  >(FindCampaignsReportDocument, options);
}
export type FindCampaignsReportQueryHookResult = ReturnType<
  typeof useFindCampaignsReportQuery
>;
export type FindCampaignsReportLazyQueryHookResult = ReturnType<
  typeof useFindCampaignsReportLazyQuery
>;
export type FindCampaignsReportSuspenseQueryHookResult = ReturnType<
  typeof useFindCampaignsReportSuspenseQuery
>;
export type FindCampaignsReportQueryResult = Apollo.QueryResult<
  FindCampaignsReportQuery,
  FindCampaignsReportQueryVariables
>;
export const GetContactReportDocument = gql`
  query GetContactReport($filter: ReportsFilter!) {
    getContactReport(filter: $filter) {
      leadCount
      totalContacts
      totalContactsResponded
    }
  }
`;

/**
 * __useGetContactReportQuery__
 *
 * To run a query within a React component, call `useGetContactReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetContactReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactReportQuery,
    GetContactReportQueryVariables
  > &
    (
      | { variables: GetContactReportQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContactReportQuery, GetContactReportQueryVariables>(
    GetContactReportDocument,
    options,
  );
}
export function useGetContactReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactReportQuery,
    GetContactReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContactReportQuery,
    GetContactReportQueryVariables
  >(GetContactReportDocument, options);
}
export function useGetContactReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContactReportQuery,
        GetContactReportQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContactReportQuery,
    GetContactReportQueryVariables
  >(GetContactReportDocument, options);
}
export type GetContactReportQueryHookResult = ReturnType<
  typeof useGetContactReportQuery
>;
export type GetContactReportLazyQueryHookResult = ReturnType<
  typeof useGetContactReportLazyQuery
>;
export type GetContactReportSuspenseQueryHookResult = ReturnType<
  typeof useGetContactReportSuspenseQuery
>;
export type GetContactReportQueryResult = Apollo.QueryResult<
  GetContactReportQuery,
  GetContactReportQueryVariables
>;
export const GetDialerCallReportDocument = gql`
  query GetDialerCallReport($filter: DialerReportsFilter) {
    getDialerCallReport(filter: $filter) {
      answered
      averageDuration
      conversationRate
      conversations
      responseRate
      total
    }
  }
`;

/**
 * __useGetDialerCallReportQuery__
 *
 * To run a query within a React component, call `useGetDialerCallReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDialerCallReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDialerCallReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDialerCallReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDialerCallReportQuery,
    GetDialerCallReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDialerCallReportQuery,
    GetDialerCallReportQueryVariables
  >(GetDialerCallReportDocument, options);
}
export function useGetDialerCallReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDialerCallReportQuery,
    GetDialerCallReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDialerCallReportQuery,
    GetDialerCallReportQueryVariables
  >(GetDialerCallReportDocument, options);
}
export function useGetDialerCallReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetDialerCallReportQuery,
        GetDialerCallReportQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDialerCallReportQuery,
    GetDialerCallReportQueryVariables
  >(GetDialerCallReportDocument, options);
}
export type GetDialerCallReportQueryHookResult = ReturnType<
  typeof useGetDialerCallReportQuery
>;
export type GetDialerCallReportLazyQueryHookResult = ReturnType<
  typeof useGetDialerCallReportLazyQuery
>;
export type GetDialerCallReportSuspenseQueryHookResult = ReturnType<
  typeof useGetDialerCallReportSuspenseQuery
>;
export type GetDialerCallReportQueryResult = Apollo.QueryResult<
  GetDialerCallReportQuery,
  GetDialerCallReportQueryVariables
>;
export const GetDialerContactReportDocument = gql`
  query GetDialerContactReport($filter: DialerReportsFilter) {
    getDialerContactReport(filter: $filter) {
      deals
      leads
    }
  }
`;

/**
 * __useGetDialerContactReportQuery__
 *
 * To run a query within a React component, call `useGetDialerContactReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDialerContactReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDialerContactReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDialerContactReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDialerContactReportQuery,
    GetDialerContactReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDialerContactReportQuery,
    GetDialerContactReportQueryVariables
  >(GetDialerContactReportDocument, options);
}
export function useGetDialerContactReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDialerContactReportQuery,
    GetDialerContactReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDialerContactReportQuery,
    GetDialerContactReportQueryVariables
  >(GetDialerContactReportDocument, options);
}
export function useGetDialerContactReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetDialerContactReportQuery,
        GetDialerContactReportQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDialerContactReportQuery,
    GetDialerContactReportQueryVariables
  >(GetDialerContactReportDocument, options);
}
export type GetDialerContactReportQueryHookResult = ReturnType<
  typeof useGetDialerContactReportQuery
>;
export type GetDialerContactReportLazyQueryHookResult = ReturnType<
  typeof useGetDialerContactReportLazyQuery
>;
export type GetDialerContactReportSuspenseQueryHookResult = ReturnType<
  typeof useGetDialerContactReportSuspenseQuery
>;
export type GetDialerContactReportQueryResult = Apollo.QueryResult<
  GetDialerContactReportQuery,
  GetDialerContactReportQueryVariables
>;
export const GetDialerCallOutcomeReportDocument = gql`
  query GetDialerCallOutcomeReport($filter: DialerReportsFilter) {
    getDialerCallOutcomeReport(filter: $filter) {
      title
      contactsWithCurrentLabel
      allLabeledContacts
    }
  }
`;

/**
 * __useGetDialerCallOutcomeReportQuery__
 *
 * To run a query within a React component, call `useGetDialerCallOutcomeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDialerCallOutcomeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDialerCallOutcomeReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDialerCallOutcomeReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDialerCallOutcomeReportQuery,
    GetDialerCallOutcomeReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDialerCallOutcomeReportQuery,
    GetDialerCallOutcomeReportQueryVariables
  >(GetDialerCallOutcomeReportDocument, options);
}
export function useGetDialerCallOutcomeReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDialerCallOutcomeReportQuery,
    GetDialerCallOutcomeReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDialerCallOutcomeReportQuery,
    GetDialerCallOutcomeReportQueryVariables
  >(GetDialerCallOutcomeReportDocument, options);
}
export function useGetDialerCallOutcomeReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetDialerCallOutcomeReportQuery,
        GetDialerCallOutcomeReportQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDialerCallOutcomeReportQuery,
    GetDialerCallOutcomeReportQueryVariables
  >(GetDialerCallOutcomeReportDocument, options);
}
export type GetDialerCallOutcomeReportQueryHookResult = ReturnType<
  typeof useGetDialerCallOutcomeReportQuery
>;
export type GetDialerCallOutcomeReportLazyQueryHookResult = ReturnType<
  typeof useGetDialerCallOutcomeReportLazyQuery
>;
export type GetDialerCallOutcomeReportSuspenseQueryHookResult = ReturnType<
  typeof useGetDialerCallOutcomeReportSuspenseQuery
>;
export type GetDialerCallOutcomeReportQueryResult = Apollo.QueryResult<
  GetDialerCallOutcomeReportQuery,
  GetDialerCallOutcomeReportQueryVariables
>;
export const SessionUpdatedDocument = gql`
  subscription SessionUpdated {
    sessionUpdated {
      online
    }
  }
`;

/**
 * __useSessionUpdatedSubscription__
 *
 * To run a query within a React component, call `useSessionUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSessionUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SessionUpdatedSubscription,
    SessionUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SessionUpdatedSubscription,
    SessionUpdatedSubscriptionVariables
  >(SessionUpdatedDocument, options);
}
export type SessionUpdatedSubscriptionHookResult = ReturnType<
  typeof useSessionUpdatedSubscription
>;
export type SessionUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<SessionUpdatedSubscription>;
export const FindSkiptracesDocument = gql`
  query FindSkiptraces($pagination: Pagination, $order: Order) {
    findSkiptraces(pagination: $pagination, order: $order) {
      items {
        id
        fileName
        mobileContacts
        landlineContacts
        createdAt
        excludeFirstRow
        fields {
          ...SkiptraceField
        }
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${SkiptraceFieldFragmentDoc}
`;

/**
 * __useFindSkiptracesQuery__
 *
 * To run a query within a React component, call `useFindSkiptracesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSkiptracesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSkiptracesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useFindSkiptracesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindSkiptracesQuery,
    FindSkiptracesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindSkiptracesQuery, FindSkiptracesQueryVariables>(
    FindSkiptracesDocument,
    options,
  );
}
export function useFindSkiptracesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSkiptracesQuery,
    FindSkiptracesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindSkiptracesQuery, FindSkiptracesQueryVariables>(
    FindSkiptracesDocument,
    options,
  );
}
export function useFindSkiptracesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindSkiptracesQuery,
        FindSkiptracesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindSkiptracesQuery,
    FindSkiptracesQueryVariables
  >(FindSkiptracesDocument, options);
}
export type FindSkiptracesQueryHookResult = ReturnType<
  typeof useFindSkiptracesQuery
>;
export type FindSkiptracesLazyQueryHookResult = ReturnType<
  typeof useFindSkiptracesLazyQuery
>;
export type FindSkiptracesSuspenseQueryHookResult = ReturnType<
  typeof useFindSkiptracesSuspenseQuery
>;
export type FindSkiptracesQueryResult = Apollo.QueryResult<
  FindSkiptracesQuery,
  FindSkiptracesQueryVariables
>;
export const FindSkiptraceFieldsDocument = gql`
  query FindSkiptraceFields {
    findSkiptraceFields {
      ...SkiptraceField
    }
  }
  ${SkiptraceFieldFragmentDoc}
`;

/**
 * __useFindSkiptraceFieldsQuery__
 *
 * To run a query within a React component, call `useFindSkiptraceFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSkiptraceFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSkiptraceFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindSkiptraceFieldsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindSkiptraceFieldsQuery,
    FindSkiptraceFieldsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindSkiptraceFieldsQuery,
    FindSkiptraceFieldsQueryVariables
  >(FindSkiptraceFieldsDocument, options);
}
export function useFindSkiptraceFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSkiptraceFieldsQuery,
    FindSkiptraceFieldsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindSkiptraceFieldsQuery,
    FindSkiptraceFieldsQueryVariables
  >(FindSkiptraceFieldsDocument, options);
}
export function useFindSkiptraceFieldsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindSkiptraceFieldsQuery,
        FindSkiptraceFieldsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindSkiptraceFieldsQuery,
    FindSkiptraceFieldsQueryVariables
  >(FindSkiptraceFieldsDocument, options);
}
export type FindSkiptraceFieldsQueryHookResult = ReturnType<
  typeof useFindSkiptraceFieldsQuery
>;
export type FindSkiptraceFieldsLazyQueryHookResult = ReturnType<
  typeof useFindSkiptraceFieldsLazyQuery
>;
export type FindSkiptraceFieldsSuspenseQueryHookResult = ReturnType<
  typeof useFindSkiptraceFieldsSuspenseQuery
>;
export type FindSkiptraceFieldsQueryResult = Apollo.QueryResult<
  FindSkiptraceFieldsQuery,
  FindSkiptraceFieldsQueryVariables
>;
export const GetSkiptraceDocument = gql`
  query GetSkiptrace($id: String!) {
    getSkiptrace(id: $id) {
      id
      fileName
      mobileContacts
      landlineContacts
      createdAt
      excludeFirstRow
      processedContacts
      foundContacts
      cachedContacts
      fields {
        ...SkiptraceField
      }
    }
  }
  ${SkiptraceFieldFragmentDoc}
`;

/**
 * __useGetSkiptraceQuery__
 *
 * To run a query within a React component, call `useGetSkiptraceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkiptraceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkiptraceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSkiptraceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSkiptraceQuery,
    GetSkiptraceQueryVariables
  > &
    (
      | { variables: GetSkiptraceQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSkiptraceQuery, GetSkiptraceQueryVariables>(
    GetSkiptraceDocument,
    options,
  );
}
export function useGetSkiptraceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSkiptraceQuery,
    GetSkiptraceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSkiptraceQuery, GetSkiptraceQueryVariables>(
    GetSkiptraceDocument,
    options,
  );
}
export function useGetSkiptraceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetSkiptraceQuery,
        GetSkiptraceQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSkiptraceQuery, GetSkiptraceQueryVariables>(
    GetSkiptraceDocument,
    options,
  );
}
export type GetSkiptraceQueryHookResult = ReturnType<
  typeof useGetSkiptraceQuery
>;
export type GetSkiptraceLazyQueryHookResult = ReturnType<
  typeof useGetSkiptraceLazyQuery
>;
export type GetSkiptraceSuspenseQueryHookResult = ReturnType<
  typeof useGetSkiptraceSuspenseQuery
>;
export type GetSkiptraceQueryResult = Apollo.QueryResult<
  GetSkiptraceQuery,
  GetSkiptraceQueryVariables
>;
export const RemoveSkiptracesDocument = gql`
  mutation RemoveSkiptraces($input: RemoveSkiptracesInput!) {
    removeSkiptraces(input: $input) {
      success
    }
  }
`;
export type RemoveSkiptracesMutationFn = Apollo.MutationFunction<
  RemoveSkiptracesMutation,
  RemoveSkiptracesMutationVariables
>;

/**
 * __useRemoveSkiptracesMutation__
 *
 * To run a mutation, you first call `useRemoveSkiptracesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSkiptracesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSkiptracesMutation, { data, loading, error }] = useRemoveSkiptracesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSkiptracesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSkiptracesMutation,
    RemoveSkiptracesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveSkiptracesMutation,
    RemoveSkiptracesMutationVariables
  >(RemoveSkiptracesDocument, options);
}
export type RemoveSkiptracesMutationHookResult = ReturnType<
  typeof useRemoveSkiptracesMutation
>;
export type RemoveSkiptracesMutationResult =
  Apollo.MutationResult<RemoveSkiptracesMutation>;
export type RemoveSkiptracesMutationOptions = Apollo.BaseMutationOptions<
  RemoveSkiptracesMutation,
  RemoveSkiptracesMutationVariables
>;
export const UpdateSkiptraceDocument = gql`
  mutation UpdateSkiptrace(
    $input: UpdateSkiptraceInput!
    $updateSkiptraceId: String!
  ) {
    updateSkiptrace(input: $input, id: $updateSkiptraceId) {
      id
      fileName
      mobileContacts
      landlineContacts
      createdAt
      excludeFirstRow
      fields {
        ...SkiptraceField
      }
    }
  }
  ${SkiptraceFieldFragmentDoc}
`;
export type UpdateSkiptraceMutationFn = Apollo.MutationFunction<
  UpdateSkiptraceMutation,
  UpdateSkiptraceMutationVariables
>;

/**
 * __useUpdateSkiptraceMutation__
 *
 * To run a mutation, you first call `useUpdateSkiptraceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSkiptraceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSkiptraceMutation, { data, loading, error }] = useUpdateSkiptraceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      updateSkiptraceId: // value for 'updateSkiptraceId'
 *   },
 * });
 */
export function useUpdateSkiptraceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSkiptraceMutation,
    UpdateSkiptraceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSkiptraceMutation,
    UpdateSkiptraceMutationVariables
  >(UpdateSkiptraceDocument, options);
}
export type UpdateSkiptraceMutationHookResult = ReturnType<
  typeof useUpdateSkiptraceMutation
>;
export type UpdateSkiptraceMutationResult =
  Apollo.MutationResult<UpdateSkiptraceMutation>;
export type UpdateSkiptraceMutationOptions = Apollo.BaseMutationOptions<
  UpdateSkiptraceMutation,
  UpdateSkiptraceMutationVariables
>;
export const RunSkiptraceDocument = gql`
  mutation RunSkiptrace($input: RunSkiptraceInput!) {
    runSkiptrace(input: $input) {
      success
    }
  }
`;
export type RunSkiptraceMutationFn = Apollo.MutationFunction<
  RunSkiptraceMutation,
  RunSkiptraceMutationVariables
>;

/**
 * __useRunSkiptraceMutation__
 *
 * To run a mutation, you first call `useRunSkiptraceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunSkiptraceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runSkiptraceMutation, { data, loading, error }] = useRunSkiptraceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunSkiptraceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunSkiptraceMutation,
    RunSkiptraceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunSkiptraceMutation,
    RunSkiptraceMutationVariables
  >(RunSkiptraceDocument, options);
}
export type RunSkiptraceMutationHookResult = ReturnType<
  typeof useRunSkiptraceMutation
>;
export type RunSkiptraceMutationResult =
  Apollo.MutationResult<RunSkiptraceMutation>;
export type RunSkiptraceMutationOptions = Apollo.BaseMutationOptions<
  RunSkiptraceMutation,
  RunSkiptraceMutationVariables
>;
export const ExportSkiptraceDocument = gql`
  query ExportSkiptrace($input: ExportSkiptraceInput!) {
    exportSkiptrace(input: $input) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useExportSkiptraceQuery__
 *
 * To run a query within a React component, call `useExportSkiptraceQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSkiptraceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSkiptraceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportSkiptraceQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportSkiptraceQuery,
    ExportSkiptraceQueryVariables
  > &
    (
      | { variables: ExportSkiptraceQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportSkiptraceQuery, ExportSkiptraceQueryVariables>(
    ExportSkiptraceDocument,
    options,
  );
}
export function useExportSkiptraceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportSkiptraceQuery,
    ExportSkiptraceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExportSkiptraceQuery,
    ExportSkiptraceQueryVariables
  >(ExportSkiptraceDocument, options);
}
export function useExportSkiptraceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExportSkiptraceQuery,
        ExportSkiptraceQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExportSkiptraceQuery,
    ExportSkiptraceQueryVariables
  >(ExportSkiptraceDocument, options);
}
export type ExportSkiptraceQueryHookResult = ReturnType<
  typeof useExportSkiptraceQuery
>;
export type ExportSkiptraceLazyQueryHookResult = ReturnType<
  typeof useExportSkiptraceLazyQuery
>;
export type ExportSkiptraceSuspenseQueryHookResult = ReturnType<
  typeof useExportSkiptraceSuspenseQuery
>;
export type ExportSkiptraceQueryResult = Apollo.QueryResult<
  ExportSkiptraceQuery,
  ExportSkiptraceQueryVariables
>;
export const PushSkiptraceToGroupDocument = gql`
  mutation PushSkiptraceToGroup($input: PushSkiptraceToGroupInput!) {
    pushSkiptraceToGroup(input: $input) {
      id
      type
      fields {
        id
        name
        predefined
        key
      }
    }
  }
`;
export type PushSkiptraceToGroupMutationFn = Apollo.MutationFunction<
  PushSkiptraceToGroupMutation,
  PushSkiptraceToGroupMutationVariables
>;

/**
 * __usePushSkiptraceToGroupMutation__
 *
 * To run a mutation, you first call `usePushSkiptraceToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushSkiptraceToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushSkiptraceToGroupMutation, { data, loading, error }] = usePushSkiptraceToGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePushSkiptraceToGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PushSkiptraceToGroupMutation,
    PushSkiptraceToGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PushSkiptraceToGroupMutation,
    PushSkiptraceToGroupMutationVariables
  >(PushSkiptraceToGroupDocument, options);
}
export type PushSkiptraceToGroupMutationHookResult = ReturnType<
  typeof usePushSkiptraceToGroupMutation
>;
export type PushSkiptraceToGroupMutationResult =
  Apollo.MutationResult<PushSkiptraceToGroupMutation>;
export type PushSkiptraceToGroupMutationOptions = Apollo.BaseMutationOptions<
  PushSkiptraceToGroupMutation,
  PushSkiptraceToGroupMutationVariables
>;
export const SkiptraceProgressDocument = gql`
  subscription SkiptraceProgress {
    skiptraceProgress {
      skiptraceId
      progress
    }
  }
`;

/**
 * __useSkiptraceProgressSubscription__
 *
 * To run a query within a React component, call `useSkiptraceProgressSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSkiptraceProgressSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkiptraceProgressSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSkiptraceProgressSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SkiptraceProgressSubscription,
    SkiptraceProgressSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SkiptraceProgressSubscription,
    SkiptraceProgressSubscriptionVariables
  >(SkiptraceProgressDocument, options);
}
export type SkiptraceProgressSubscriptionHookResult = ReturnType<
  typeof useSkiptraceProgressSubscription
>;
export type SkiptraceProgressSubscriptionResult =
  Apollo.SubscriptionResult<SkiptraceProgressSubscription>;
export const SkiptraceCompletedDocument = gql`
  subscription SkiptraceCompleted {
    skiptraceCompleted {
      skiptraceId
    }
  }
`;

/**
 * __useSkiptraceCompletedSubscription__
 *
 * To run a query within a React component, call `useSkiptraceCompletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSkiptraceCompletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkiptraceCompletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSkiptraceCompletedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SkiptraceCompletedSubscription,
    SkiptraceCompletedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SkiptraceCompletedSubscription,
    SkiptraceCompletedSubscriptionVariables
  >(SkiptraceCompletedDocument, options);
}
export type SkiptraceCompletedSubscriptionHookResult = ReturnType<
  typeof useSkiptraceCompletedSubscription
>;
export type SkiptraceCompletedSubscriptionResult =
  Apollo.SubscriptionResult<SkiptraceCompletedSubscription>;
export const SubscribeDocument = gql`
  mutation Subscribe($input: SubscribeInput!) {
    subscribe(input: $input) {
      ...Subscription
    }
  }
  ${SubscriptionFragmentDoc}
`;
export type SubscribeMutationFn = Apollo.MutationFunction<
  SubscribeMutation,
  SubscribeMutationVariables
>;

/**
 * __useSubscribeMutation__
 *
 * To run a mutation, you first call `useSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeMutation, { data, loading, error }] = useSubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeMutation,
    SubscribeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubscribeMutation, SubscribeMutationVariables>(
    SubscribeDocument,
    options,
  );
}
export type SubscribeMutationHookResult = ReturnType<
  typeof useSubscribeMutation
>;
export type SubscribeMutationResult = Apollo.MutationResult<SubscribeMutation>;
export type SubscribeMutationOptions = Apollo.BaseMutationOptions<
  SubscribeMutation,
  SubscribeMutationVariables
>;
export const UnsubscribeDocument = gql`
  mutation Unsubscribe($id: String!, $reason: String) {
    unsubscribe(subscriptionId: $id, reason: $reason) {
      success
    }
  }
`;
export type UnsubscribeMutationFn = Apollo.MutationFunction<
  UnsubscribeMutation,
  UnsubscribeMutationVariables
>;

/**
 * __useUnsubscribeMutation__
 *
 * To run a mutation, you first call `useUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeMutation, { data, loading, error }] = useUnsubscribeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useUnsubscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeMutation,
    UnsubscribeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnsubscribeMutation, UnsubscribeMutationVariables>(
    UnsubscribeDocument,
    options,
  );
}
export type UnsubscribeMutationHookResult = ReturnType<
  typeof useUnsubscribeMutation
>;
export type UnsubscribeMutationResult =
  Apollo.MutationResult<UnsubscribeMutation>;
export type UnsubscribeMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeMutation,
  UnsubscribeMutationVariables
>;
export const UpdateSubscriptionDocument = gql`
  mutation UpdateSubscription($input: UpdateSubscriptionInput!, $id: String!) {
    updateSubscription(input: $input, subscriptionId: $id) {
      ...Subscription
    }
  }
  ${SubscriptionFragmentDoc}
`;
export type UpdateSubscriptionMutationFn = Apollo.MutationFunction<
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables
>;

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationVariables
  >(UpdateSubscriptionDocument, options);
}
export type UpdateSubscriptionMutationHookResult = ReturnType<
  typeof useUpdateSubscriptionMutation
>;
export type UpdateSubscriptionMutationResult =
  Apollo.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables
>;
export const EndTrialDocument = gql`
  mutation EndTrial($id: String!, $input: EndTrialInput!) {
    endTrial(subscriptionId: $id, input: $input) {
      ...Subscription
    }
  }
  ${SubscriptionFragmentDoc}
`;
export type EndTrialMutationFn = Apollo.MutationFunction<
  EndTrialMutation,
  EndTrialMutationVariables
>;

/**
 * __useEndTrialMutation__
 *
 * To run a mutation, you first call `useEndTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endTrialMutation, { data, loading, error }] = useEndTrialMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndTrialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EndTrialMutation,
    EndTrialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EndTrialMutation, EndTrialMutationVariables>(
    EndTrialDocument,
    options,
  );
}
export type EndTrialMutationHookResult = ReturnType<typeof useEndTrialMutation>;
export type EndTrialMutationResult = Apollo.MutationResult<EndTrialMutation>;
export type EndTrialMutationOptions = Apollo.BaseMutationOptions<
  EndTrialMutation,
  EndTrialMutationVariables
>;
export const VerifyPromoCodeDocument = gql`
  mutation VerifyPromoCode($input: VerifyPromoCodeInput!) {
    verifyPromoCode(input: $input) {
      id
      code
      coupon {
        id
        amountOff
        percentOff
      }
    }
  }
`;
export type VerifyPromoCodeMutationFn = Apollo.MutationFunction<
  VerifyPromoCodeMutation,
  VerifyPromoCodeMutationVariables
>;

/**
 * __useVerifyPromoCodeMutation__
 *
 * To run a mutation, you first call `useVerifyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPromoCodeMutation, { data, loading, error }] = useVerifyPromoCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyPromoCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyPromoCodeMutation,
    VerifyPromoCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyPromoCodeMutation,
    VerifyPromoCodeMutationVariables
  >(VerifyPromoCodeDocument, options);
}
export type VerifyPromoCodeMutationHookResult = ReturnType<
  typeof useVerifyPromoCodeMutation
>;
export type VerifyPromoCodeMutationResult =
  Apollo.MutationResult<VerifyPromoCodeMutation>;
export type VerifyPromoCodeMutationOptions = Apollo.BaseMutationOptions<
  VerifyPromoCodeMutation,
  VerifyPromoCodeMutationVariables
>;
export const ApplyDiscountDocument = gql`
  mutation ApplyDiscount(
    $subscriptionId: String!
    $input: ApplyDiscountInput!
  ) {
    applyDiscount(subscriptionId: $subscriptionId, input: $input) {
      amount
      coupon
      startedAt
      promoCode
      expiresAt
      percent
    }
  }
`;
export type ApplyDiscountMutationFn = Apollo.MutationFunction<
  ApplyDiscountMutation,
  ApplyDiscountMutationVariables
>;

/**
 * __useApplyDiscountMutation__
 *
 * To run a mutation, you first call `useApplyDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyDiscountMutation, { data, loading, error }] = useApplyDiscountMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyDiscountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyDiscountMutation,
    ApplyDiscountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApplyDiscountMutation,
    ApplyDiscountMutationVariables
  >(ApplyDiscountDocument, options);
}
export type ApplyDiscountMutationHookResult = ReturnType<
  typeof useApplyDiscountMutation
>;
export type ApplyDiscountMutationResult =
  Apollo.MutationResult<ApplyDiscountMutation>;
export type ApplyDiscountMutationOptions = Apollo.BaseMutationOptions<
  ApplyDiscountMutation,
  ApplyDiscountMutationVariables
>;
export const SubscriptionsDocument = gql`
  query Subscriptions {
    subscriptions {
      id
      product
      status
      price
      title
      nextBillingDate
      discount {
        amount
        coupon
        percent
        promoCode
        startedAt
        expiresAt
      }
      startedAt
      endAt
      updatedAt
      trialEndedAt
      membershipMonths
      reason
      base
      secondary
      plan {
        ...Plan
      }
    }
  }
  ${PlanFragmentDoc}
`;

/**
 * __useSubscriptionsQuery__
 *
 * To run a query within a React component, call `useSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(
    SubscriptionsDocument,
    options,
  );
}
export function useSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(
    SubscriptionsDocument,
    options,
  );
}
export function useSubscriptionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SubscriptionsQuery,
        SubscriptionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >(SubscriptionsDocument, options);
}
export type SubscriptionsQueryHookResult = ReturnType<
  typeof useSubscriptionsQuery
>;
export type SubscriptionsLazyQueryHookResult = ReturnType<
  typeof useSubscriptionsLazyQuery
>;
export type SubscriptionsSuspenseQueryHookResult = ReturnType<
  typeof useSubscriptionsSuspenseQuery
>;
export type SubscriptionsQueryResult = Apollo.QueryResult<
  SubscriptionsQuery,
  SubscriptionsQueryVariables
>;
export const RetrieveUpcomingInvoiceDocument = gql`
  query RetrieveUpcomingInvoice($products: [Product!]!, $subAccountId: String) {
    retrieveUpcomingInvoice(products: $products, subAccountId: $subAccountId) {
      totalBilledNow
    }
  }
`;

/**
 * __useRetrieveUpcomingInvoiceQuery__
 *
 * To run a query within a React component, call `useRetrieveUpcomingInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveUpcomingInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveUpcomingInvoiceQuery({
 *   variables: {
 *      products: // value for 'products'
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useRetrieveUpcomingInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<
    RetrieveUpcomingInvoiceQuery,
    RetrieveUpcomingInvoiceQueryVariables
  > &
    (
      | { variables: RetrieveUpcomingInvoiceQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RetrieveUpcomingInvoiceQuery,
    RetrieveUpcomingInvoiceQueryVariables
  >(RetrieveUpcomingInvoiceDocument, options);
}
export function useRetrieveUpcomingInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetrieveUpcomingInvoiceQuery,
    RetrieveUpcomingInvoiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RetrieveUpcomingInvoiceQuery,
    RetrieveUpcomingInvoiceQueryVariables
  >(RetrieveUpcomingInvoiceDocument, options);
}
export function useRetrieveUpcomingInvoiceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RetrieveUpcomingInvoiceQuery,
        RetrieveUpcomingInvoiceQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RetrieveUpcomingInvoiceQuery,
    RetrieveUpcomingInvoiceQueryVariables
  >(RetrieveUpcomingInvoiceDocument, options);
}
export type RetrieveUpcomingInvoiceQueryHookResult = ReturnType<
  typeof useRetrieveUpcomingInvoiceQuery
>;
export type RetrieveUpcomingInvoiceLazyQueryHookResult = ReturnType<
  typeof useRetrieveUpcomingInvoiceLazyQuery
>;
export type RetrieveUpcomingInvoiceSuspenseQueryHookResult = ReturnType<
  typeof useRetrieveUpcomingInvoiceSuspenseQuery
>;
export type RetrieveUpcomingInvoiceQueryResult = Apollo.QueryResult<
  RetrieveUpcomingInvoiceQuery,
  RetrieveUpcomingInvoiceQueryVariables
>;
export const FindSuppressionsDocument = gql`
  query FindSuppressions($filter: SuppressionsFilter, $pagination: Pagination) {
    findSuppressions(filter: $filter, pagination: $pagination) {
      items {
        ...Suppression
      }
      total
      hasNext
      nextId
      nextCreatedAt
    }
  }
  ${SuppressionFragmentDoc}
`;

/**
 * __useFindSuppressionsQuery__
 *
 * To run a query within a React component, call `useFindSuppressionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSuppressionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSuppressionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindSuppressionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindSuppressionsQuery,
    FindSuppressionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindSuppressionsQuery, FindSuppressionsQueryVariables>(
    FindSuppressionsDocument,
    options,
  );
}
export function useFindSuppressionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSuppressionsQuery,
    FindSuppressionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindSuppressionsQuery,
    FindSuppressionsQueryVariables
  >(FindSuppressionsDocument, options);
}
export function useFindSuppressionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindSuppressionsQuery,
        FindSuppressionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindSuppressionsQuery,
    FindSuppressionsQueryVariables
  >(FindSuppressionsDocument, options);
}
export type FindSuppressionsQueryHookResult = ReturnType<
  typeof useFindSuppressionsQuery
>;
export type FindSuppressionsLazyQueryHookResult = ReturnType<
  typeof useFindSuppressionsLazyQuery
>;
export type FindSuppressionsSuspenseQueryHookResult = ReturnType<
  typeof useFindSuppressionsSuspenseQuery
>;
export type FindSuppressionsQueryResult = Apollo.QueryResult<
  FindSuppressionsQuery,
  FindSuppressionsQueryVariables
>;
export const SuppressionsSummaryDocument = gql`
  query SuppressionsSummary {
    suppressionsSummary {
      dnc
      unsubscribed
      filtered
      undeliverable
      carrierBlocked
    }
  }
`;

/**
 * __useSuppressionsSummaryQuery__
 *
 * To run a query within a React component, call `useSuppressionsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppressionsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppressionsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuppressionsSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SuppressionsSummaryQuery,
    SuppressionsSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SuppressionsSummaryQuery,
    SuppressionsSummaryQueryVariables
  >(SuppressionsSummaryDocument, options);
}
export function useSuppressionsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuppressionsSummaryQuery,
    SuppressionsSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SuppressionsSummaryQuery,
    SuppressionsSummaryQueryVariables
  >(SuppressionsSummaryDocument, options);
}
export function useSuppressionsSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SuppressionsSummaryQuery,
        SuppressionsSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SuppressionsSummaryQuery,
    SuppressionsSummaryQueryVariables
  >(SuppressionsSummaryDocument, options);
}
export type SuppressionsSummaryQueryHookResult = ReturnType<
  typeof useSuppressionsSummaryQuery
>;
export type SuppressionsSummaryLazyQueryHookResult = ReturnType<
  typeof useSuppressionsSummaryLazyQuery
>;
export type SuppressionsSummarySuspenseQueryHookResult = ReturnType<
  typeof useSuppressionsSummarySuspenseQuery
>;
export type SuppressionsSummaryQueryResult = Apollo.QueryResult<
  SuppressionsSummaryQuery,
  SuppressionsSummaryQueryVariables
>;
export const ExportSuppressionsDocument = gql`
  query ExportSuppressions($input: ExportSuppressionsInput!) {
    exportSuppressions(input: $input) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useExportSuppressionsQuery__
 *
 * To run a query within a React component, call `useExportSuppressionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSuppressionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSuppressionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportSuppressionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportSuppressionsQuery,
    ExportSuppressionsQueryVariables
  > &
    (
      | { variables: ExportSuppressionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExportSuppressionsQuery,
    ExportSuppressionsQueryVariables
  >(ExportSuppressionsDocument, options);
}
export function useExportSuppressionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportSuppressionsQuery,
    ExportSuppressionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExportSuppressionsQuery,
    ExportSuppressionsQueryVariables
  >(ExportSuppressionsDocument, options);
}
export function useExportSuppressionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExportSuppressionsQuery,
        ExportSuppressionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExportSuppressionsQuery,
    ExportSuppressionsQueryVariables
  >(ExportSuppressionsDocument, options);
}
export type ExportSuppressionsQueryHookResult = ReturnType<
  typeof useExportSuppressionsQuery
>;
export type ExportSuppressionsLazyQueryHookResult = ReturnType<
  typeof useExportSuppressionsLazyQuery
>;
export type ExportSuppressionsSuspenseQueryHookResult = ReturnType<
  typeof useExportSuppressionsSuspenseQuery
>;
export type ExportSuppressionsQueryResult = Apollo.QueryResult<
  ExportSuppressionsQuery,
  ExportSuppressionsQueryVariables
>;
export const GetUploadDncPhonesSignedUrlDocument = gql`
  query GetUploadDNCPhonesSignedUrl($input: GenerateSignedUrlInput!) {
    getUploadDNCPhonesSignedUrl(input: $input) {
      signedUrl
      filePath
    }
  }
`;

/**
 * __useGetUploadDncPhonesSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetUploadDncPhonesSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadDncPhonesSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadDncPhonesSignedUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUploadDncPhonesSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUploadDncPhonesSignedUrlQuery,
    GetUploadDncPhonesSignedUrlQueryVariables
  > &
    (
      | { variables: GetUploadDncPhonesSignedUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUploadDncPhonesSignedUrlQuery,
    GetUploadDncPhonesSignedUrlQueryVariables
  >(GetUploadDncPhonesSignedUrlDocument, options);
}
export function useGetUploadDncPhonesSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUploadDncPhonesSignedUrlQuery,
    GetUploadDncPhonesSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUploadDncPhonesSignedUrlQuery,
    GetUploadDncPhonesSignedUrlQueryVariables
  >(GetUploadDncPhonesSignedUrlDocument, options);
}
export function useGetUploadDncPhonesSignedUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUploadDncPhonesSignedUrlQuery,
        GetUploadDncPhonesSignedUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUploadDncPhonesSignedUrlQuery,
    GetUploadDncPhonesSignedUrlQueryVariables
  >(GetUploadDncPhonesSignedUrlDocument, options);
}
export type GetUploadDncPhonesSignedUrlQueryHookResult = ReturnType<
  typeof useGetUploadDncPhonesSignedUrlQuery
>;
export type GetUploadDncPhonesSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetUploadDncPhonesSignedUrlLazyQuery
>;
export type GetUploadDncPhonesSignedUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetUploadDncPhonesSignedUrlSuspenseQuery
>;
export type GetUploadDncPhonesSignedUrlQueryResult = Apollo.QueryResult<
  GetUploadDncPhonesSignedUrlQuery,
  GetUploadDncPhonesSignedUrlQueryVariables
>;
export const UploadDncPhonesDocument = gql`
  mutation UploadDNCPhones($input: UploadDNCPhonesInput!) {
    uploadDNCPhones(input: $input) {
      success
    }
  }
`;
export type UploadDncPhonesMutationFn = Apollo.MutationFunction<
  UploadDncPhonesMutation,
  UploadDncPhonesMutationVariables
>;

/**
 * __useUploadDncPhonesMutation__
 *
 * To run a mutation, you first call `useUploadDncPhonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDncPhonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDncPhonesMutation, { data, loading, error }] = useUploadDncPhonesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadDncPhonesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadDncPhonesMutation,
    UploadDncPhonesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadDncPhonesMutation,
    UploadDncPhonesMutationVariables
  >(UploadDncPhonesDocument, options);
}
export type UploadDncPhonesMutationHookResult = ReturnType<
  typeof useUploadDncPhonesMutation
>;
export type UploadDncPhonesMutationResult =
  Apollo.MutationResult<UploadDncPhonesMutation>;
export type UploadDncPhonesMutationOptions = Apollo.BaseMutationOptions<
  UploadDncPhonesMutation,
  UploadDncPhonesMutationVariables
>;
export const CreateDncPhoneDocument = gql`
  mutation CreateDNCPhone($input: CreateDNCPhoneInput!) {
    createDNCPhone(input: $input) {
      ...Suppression
    }
  }
  ${SuppressionFragmentDoc}
`;
export type CreateDncPhoneMutationFn = Apollo.MutationFunction<
  CreateDncPhoneMutation,
  CreateDncPhoneMutationVariables
>;

/**
 * __useCreateDncPhoneMutation__
 *
 * To run a mutation, you first call `useCreateDncPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDncPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDncPhoneMutation, { data, loading, error }] = useCreateDncPhoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDncPhoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDncPhoneMutation,
    CreateDncPhoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDncPhoneMutation,
    CreateDncPhoneMutationVariables
  >(CreateDncPhoneDocument, options);
}
export type CreateDncPhoneMutationHookResult = ReturnType<
  typeof useCreateDncPhoneMutation
>;
export type CreateDncPhoneMutationResult =
  Apollo.MutationResult<CreateDncPhoneMutation>;
export type CreateDncPhoneMutationOptions = Apollo.BaseMutationOptions<
  CreateDncPhoneMutation,
  CreateDncPhoneMutationVariables
>;
export const AddContactToDncDocument = gql`
  mutation AddContactToDNC($input: AddContactToDNCInput!) {
    addContactToDNC(input: $input) {
      ...Suppression
    }
  }
  ${SuppressionFragmentDoc}
`;
export type AddContactToDncMutationFn = Apollo.MutationFunction<
  AddContactToDncMutation,
  AddContactToDncMutationVariables
>;

/**
 * __useAddContactToDncMutation__
 *
 * To run a mutation, you first call `useAddContactToDncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactToDncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactToDncMutation, { data, loading, error }] = useAddContactToDncMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddContactToDncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddContactToDncMutation,
    AddContactToDncMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddContactToDncMutation,
    AddContactToDncMutationVariables
  >(AddContactToDncDocument, options);
}
export type AddContactToDncMutationHookResult = ReturnType<
  typeof useAddContactToDncMutation
>;
export type AddContactToDncMutationResult =
  Apollo.MutationResult<AddContactToDncMutation>;
export type AddContactToDncMutationOptions = Apollo.BaseMutationOptions<
  AddContactToDncMutation,
  AddContactToDncMutationVariables
>;
export const RemoveContactFromDncSuppressionsDocument = gql`
  mutation RemoveContactFromDNCSuppressions(
    $input: RemoveContactFromDNCSuppressionsInput!
  ) {
    removeContactFromDNCSuppressions(input: $input) {
      success
    }
  }
`;
export type RemoveContactFromDncSuppressionsMutationFn =
  Apollo.MutationFunction<
    RemoveContactFromDncSuppressionsMutation,
    RemoveContactFromDncSuppressionsMutationVariables
  >;

/**
 * __useRemoveContactFromDncSuppressionsMutation__
 *
 * To run a mutation, you first call `useRemoveContactFromDncSuppressionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactFromDncSuppressionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactFromDncSuppressionsMutation, { data, loading, error }] = useRemoveContactFromDncSuppressionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveContactFromDncSuppressionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveContactFromDncSuppressionsMutation,
    RemoveContactFromDncSuppressionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveContactFromDncSuppressionsMutation,
    RemoveContactFromDncSuppressionsMutationVariables
  >(RemoveContactFromDncSuppressionsDocument, options);
}
export type RemoveContactFromDncSuppressionsMutationHookResult = ReturnType<
  typeof useRemoveContactFromDncSuppressionsMutation
>;
export type RemoveContactFromDncSuppressionsMutationResult =
  Apollo.MutationResult<RemoveContactFromDncSuppressionsMutation>;
export type RemoveContactFromDncSuppressionsMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveContactFromDncSuppressionsMutation,
    RemoveContactFromDncSuppressionsMutationVariables
  >;
export const RemoveSuppressionsDocument = gql`
  mutation RemoveSuppressions($input: RemoveSuppressionsInput!) {
    removeSuppressions(input: $input) {
      success
    }
  }
`;
export type RemoveSuppressionsMutationFn = Apollo.MutationFunction<
  RemoveSuppressionsMutation,
  RemoveSuppressionsMutationVariables
>;

/**
 * __useRemoveSuppressionsMutation__
 *
 * To run a mutation, you first call `useRemoveSuppressionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSuppressionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSuppressionsMutation, { data, loading, error }] = useRemoveSuppressionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSuppressionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSuppressionsMutation,
    RemoveSuppressionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveSuppressionsMutation,
    RemoveSuppressionsMutationVariables
  >(RemoveSuppressionsDocument, options);
}
export type RemoveSuppressionsMutationHookResult = ReturnType<
  typeof useRemoveSuppressionsMutation
>;
export type RemoveSuppressionsMutationResult =
  Apollo.MutationResult<RemoveSuppressionsMutation>;
export type RemoveSuppressionsMutationOptions = Apollo.BaseMutationOptions<
  RemoveSuppressionsMutation,
  RemoveSuppressionsMutationVariables
>;
export const GetTollFreeRegistrationInfoDocument = gql`
  query GetTollFreeRegistrationInfo($accountId: String!) {
    getTollFreeRegistrationInfo(accountId: $accountId) {
      brand {
        ...TollFreeBrand
      }
      ownPhone {
        ...OwnPhone
      }
    }
  }
  ${TollFreeBrandFragmentDoc}
  ${OwnPhoneFragmentDoc}
`;

/**
 * __useGetTollFreeRegistrationInfoQuery__
 *
 * To run a query within a React component, call `useGetTollFreeRegistrationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTollFreeRegistrationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTollFreeRegistrationInfoQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetTollFreeRegistrationInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTollFreeRegistrationInfoQuery,
    GetTollFreeRegistrationInfoQueryVariables
  > &
    (
      | { variables: GetTollFreeRegistrationInfoQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTollFreeRegistrationInfoQuery,
    GetTollFreeRegistrationInfoQueryVariables
  >(GetTollFreeRegistrationInfoDocument, options);
}
export function useGetTollFreeRegistrationInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTollFreeRegistrationInfoQuery,
    GetTollFreeRegistrationInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTollFreeRegistrationInfoQuery,
    GetTollFreeRegistrationInfoQueryVariables
  >(GetTollFreeRegistrationInfoDocument, options);
}
export function useGetTollFreeRegistrationInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetTollFreeRegistrationInfoQuery,
        GetTollFreeRegistrationInfoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetTollFreeRegistrationInfoQuery,
    GetTollFreeRegistrationInfoQueryVariables
  >(GetTollFreeRegistrationInfoDocument, options);
}
export type GetTollFreeRegistrationInfoQueryHookResult = ReturnType<
  typeof useGetTollFreeRegistrationInfoQuery
>;
export type GetTollFreeRegistrationInfoLazyQueryHookResult = ReturnType<
  typeof useGetTollFreeRegistrationInfoLazyQuery
>;
export type GetTollFreeRegistrationInfoSuspenseQueryHookResult = ReturnType<
  typeof useGetTollFreeRegistrationInfoSuspenseQuery
>;
export type GetTollFreeRegistrationInfoQueryResult = Apollo.QueryResult<
  GetTollFreeRegistrationInfoQuery,
  GetTollFreeRegistrationInfoQueryVariables
>;
export const RegisterTollFreeDocument = gql`
  mutation RegisterTollFree($accountId: String!) {
    registerTollFree(accountId: $accountId) {
      success
    }
  }
`;
export type RegisterTollFreeMutationFn = Apollo.MutationFunction<
  RegisterTollFreeMutation,
  RegisterTollFreeMutationVariables
>;

/**
 * __useRegisterTollFreeMutation__
 *
 * To run a mutation, you first call `useRegisterTollFreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterTollFreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerTollFreeMutation, { data, loading, error }] = useRegisterTollFreeMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRegisterTollFreeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterTollFreeMutation,
    RegisterTollFreeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterTollFreeMutation,
    RegisterTollFreeMutationVariables
  >(RegisterTollFreeDocument, options);
}
export type RegisterTollFreeMutationHookResult = ReturnType<
  typeof useRegisterTollFreeMutation
>;
export type RegisterTollFreeMutationResult =
  Apollo.MutationResult<RegisterTollFreeMutation>;
export type RegisterTollFreeMutationOptions = Apollo.BaseMutationOptions<
  RegisterTollFreeMutation,
  RegisterTollFreeMutationVariables
>;
export const UpdateTollFreeRegistrationInfoDocument = gql`
  mutation UpdateTollFreeRegistrationInfo(
    $input: UpdateTollFreeRegistrationInfoInput!
    $accountId: String!
  ) {
    updateTollFreeRegistrationInfo(input: $input, accountId: $accountId) {
      brand {
        ...TollFreeBrand
      }
      ownPhone {
        ...OwnPhone
      }
    }
  }
  ${TollFreeBrandFragmentDoc}
  ${OwnPhoneFragmentDoc}
`;
export type UpdateTollFreeRegistrationInfoMutationFn = Apollo.MutationFunction<
  UpdateTollFreeRegistrationInfoMutation,
  UpdateTollFreeRegistrationInfoMutationVariables
>;

/**
 * __useUpdateTollFreeRegistrationInfoMutation__
 *
 * To run a mutation, you first call `useUpdateTollFreeRegistrationInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTollFreeRegistrationInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTollFreeRegistrationInfoMutation, { data, loading, error }] = useUpdateTollFreeRegistrationInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useUpdateTollFreeRegistrationInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTollFreeRegistrationInfoMutation,
    UpdateTollFreeRegistrationInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTollFreeRegistrationInfoMutation,
    UpdateTollFreeRegistrationInfoMutationVariables
  >(UpdateTollFreeRegistrationInfoDocument, options);
}
export type UpdateTollFreeRegistrationInfoMutationHookResult = ReturnType<
  typeof useUpdateTollFreeRegistrationInfoMutation
>;
export type UpdateTollFreeRegistrationInfoMutationResult =
  Apollo.MutationResult<UpdateTollFreeRegistrationInfoMutation>;
export type UpdateTollFreeRegistrationInfoMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTollFreeRegistrationInfoMutation,
    UpdateTollFreeRegistrationInfoMutationVariables
  >;
