import React, { FC, memo } from 'react';
import { Button, IconButton, IconButtonProps } from '@chakra-ui/react';

import CopyIcon from '@app/icons/copy-icon.svg?react';
import ToastNotify from '@app/components/ToastNotifier';
import { useCopyToClipboard } from '@app/components/CopyToClipboard/useCopyToClipboard';
import { catchErrorLog } from '@app/utils/logger';

interface CopyToClipboardProps extends Partial<IconButtonProps> {
  copy: string;
  size?: string;
  withLabel?: boolean;
}

const CopyToClipboard: FC<CopyToClipboardProps> = ({ copy, ...props }) => {
  const copyTextToClipboard = useCopyToClipboard();

  const copyHandler = async () => {
    try {
      await copyTextToClipboard(copy);
      ToastNotify({
        status: 'success',
        position: 'top-right',
        title: 'Successfully copied to clipboard',
      });
    } catch (e) {
      catchErrorLog(e, 'CopyToClipboard');
    }
  };

  if (props.withLabel) {
    return (
      <Button
        leftIcon={<CopyIcon />}
        size="xs"
        variant="iconButton"
        onClick={copyHandler}
        {...props}>
        Copy to clipboard
      </Button>
    );
  }

  return (
    <IconButton
      isRound
      aria-label="Copy to clipboard"
      size="xs"
      variant="iconButton"
      onClick={copyHandler}
      {...props}>
      <CopyIcon />
    </IconButton>
  );
};

export default memo(CopyToClipboard);
