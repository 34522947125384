/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: string; output: string };
  /** Email scalar type */
  Email: { input: string; output: string };
  /** Money scalar type */
  Money: { input: number; output: number };
  /** Phone number scalar type */
  PhoneNumberType: { input: string; output: string };
};

/** A2P brand */
export type A2PBrand = {
  __typename?: 'A2PBrand';
  city: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createWebsite: Scalars['Boolean']['output'];
  customerCareEmail?: Maybe<Scalars['String']['output']>;
  customerCarePhone?: Maybe<Scalars['String']['output']>;
  ein: Scalars['String']['output'];
  einFile?: Maybe<BrandRegistrationFile>;
  einIssuingCountry: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  incorporationDate?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  termsAndConditionsUrl?: Maybe<Scalars['String']['output']>;
  vertical: A2PBrandVertical;
  website?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

/** A2P brand campaign */
export type A2PBrandCampaign = {
  __typename?: 'A2PBrandCampaign';
  description: Scalars['String']['output'];
  helpKeywords: Array<Scalars['String']['output']>;
  helpMessage: Scalars['String']['output'];
  messageFlow: Scalars['String']['output'];
  optInKeywords: Array<Scalars['String']['output']>;
  optInMessage: Scalars['String']['output'];
  optOutKeywords: Array<Scalars['String']['output']>;
  optOutMessage: Scalars['String']['output'];
  sample1: Scalars['String']['output'];
  sample2: Scalars['String']['output'];
  sendAffiliateMarketing: Scalars['Boolean']['output'];
  sendAgeGatedContent: Scalars['Boolean']['output'];
  sendDirectLending: Scalars['Boolean']['output'];
  sendHelp: Scalars['Boolean']['output'];
  sendLinks: Scalars['Boolean']['output'];
  sendOptIn: Scalars['Boolean']['output'];
  sendOptOut: Scalars['Boolean']['output'];
  sendPhones: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  usecase: A2PBrandCampaignUseCase;
};

export type A2PBrandCampaignInput = {
  description: Scalars['String']['input'];
  messageFlow: Scalars['String']['input'];
  sample1: Scalars['String']['input'];
  sample2: Scalars['String']['input'];
  sendPhones: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  usecase: A2PBrandCampaignUseCase;
};

/** A2P brand campaign use case */
export enum A2PBrandCampaignUseCase {
  ACCOUNT_NOTIFICATION = 'ACCOUNT_NOTIFICATION',
  /** @deprecated Legacy */
  AGENTS_FRANCHISES = 'AGENTS_FRANCHISES',
  /** @deprecated Legacy */
  CARRIER_EXEMPT = 'CARRIER_EXEMPT',
  /** @deprecated Legacy */
  CHARITY = 'CHARITY',
  CUSTOMER_CARE = 'CUSTOMER_CARE',
  /** @deprecated Legacy */
  DELIVERY_NOTIFICATION = 'DELIVERY_NOTIFICATION',
  /** @deprecated Legacy */
  EMERGENCY = 'EMERGENCY',
  /** @deprecated Legacy */
  EMPTY = 'EMPTY',
  FRAUD_ALERT = 'FRAUD_ALERT',
  /** @deprecated Legacy */
  HIGHER_EDUCATION = 'HIGHER_EDUCATION',
  /** @deprecated Legacy */
  K12_EDUCATION = 'K12_EDUCATION',
  /** @deprecated Legacy */
  LOW_VOLUME = 'LOW_VOLUME',
  /** @deprecated Legacy */
  M2M = 'M2M',
  MARKETING = 'MARKETING',
  /** @deprecated Legacy */
  MIXED = 'MIXED',
  /** @deprecated Legacy */
  POLITICAL = 'POLITICAL',
  /** @deprecated Legacy */
  POLLING_VOTING = 'POLLING_VOTING',
  /** @deprecated Legacy */
  PROXY = 'PROXY',
  PUBLIC_SERVICE_ANNOUNCEMENT = 'PUBLIC_SERVICE_ANNOUNCEMENT',
  /** @deprecated Legacy */
  SECURITY_ALERT = 'SECURITY_ALERT',
  /** @deprecated Legacy */
  SOCIAL = 'SOCIAL',
  /** @deprecated Legacy */
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  /** @deprecated Legacy */
  STARTER = 'STARTER',
  /** @deprecated Legacy */
  SWEEPSTAKE = 'SWEEPSTAKE',
  /** @deprecated Legacy */
  TRIAL = 'TRIAL',
  /** @deprecated Legacy */
  TWO_FACTOR_AUTH = 'TWO_FACTOR_AUTH',
  /** @deprecated Legacy */
  UCAAS_HIGH = 'UCAAS_HIGH',
  /** @deprecated Legacy */
  UCAAS_LOW = 'UCAAS_LOW',
}

export type A2PBrandInput = {
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  country: Scalars['String']['input'];
  createWebsite?: InputMaybe<Scalars['Boolean']['input']>;
  customerCareEmail?: InputMaybe<Scalars['String']['input']>;
  customerCarePhone?: InputMaybe<Scalars['String']['input']>;
  ein: Scalars['String']['input'];
  einFile?: InputMaybe<UploadEinFileInput>;
  einIssuingCountry: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  incorporationDate: Scalars['DateTime']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  termsAndConditionsUrl?: InputMaybe<Scalars['String']['input']>;
  vertical: A2PBrandVertical;
  website?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

/** A2P brand vertical */
export enum A2PBrandVertical {
  AGRICULTURE = 'AGRICULTURE',
  /** @deprecated Legacy */
  AUTOMOTIVE = 'AUTOMOTIVE',
  /** @deprecated Legacy */
  BANKING = 'BANKING',
  COMMUNICATION = 'COMMUNICATION',
  CONSTRUCTION = 'CONSTRUCTION',
  /** @deprecated Legacy */
  CONSUMER = 'CONSUMER',
  EDUCATION = 'EDUCATION',
  /** @deprecated Legacy */
  ELECTRONICS = 'ELECTRONICS',
  /** @deprecated Legacy */
  EMPTY = 'EMPTY',
  ENERGY = 'ENERGY',
  /** @deprecated Legacy */
  ENGINEERING = 'ENGINEERING',
  ENTERTAINMENT = 'ENTERTAINMENT',
  /** @deprecated Legacy */
  FAST_MOVING_CONSUMER_GOODS = 'FAST_MOVING_CONSUMER_GOODS',
  FINANCIAL = 'FINANCIAL',
  /** @deprecated Legacy */
  FINTECH = 'FINTECH',
  /** @deprecated Legacy */
  FOOD_AND_BEVERAGE = 'FOOD_AND_BEVERAGE',
  GAMBLING = 'GAMBLING',
  /** @deprecated Legacy */
  GOVERNMENT = 'GOVERNMENT',
  HEALTHCARE = 'HEALTHCARE',
  HOSPITALITY = 'HOSPITALITY',
  /** @deprecated Legacy */
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
  INSURANCE = 'INSURANCE',
  /** @deprecated Legacy */
  JEWELRY = 'JEWELRY',
  /** @deprecated Legacy */
  LEGAL = 'LEGAL',
  MANUFACTURING = 'MANUFACTURING',
  /** @deprecated Legacy */
  MEDIA = 'MEDIA',
  NGO = 'NGO',
  /** @deprecated Legacy */
  NOT_FOR_PROFIT = 'NOT_FOR_PROFIT',
  /** @deprecated Legacy */
  OIL_AND_GAS = 'OIL_AND_GAS',
  /** @deprecated Legacy */
  ONLINE = 'ONLINE',
  /** @deprecated Legacy */
  POLITICAL = 'POLITICAL',
  /** @deprecated Legacy */
  POSTAL = 'POSTAL',
  /** @deprecated Legacy */
  PROFESSIONAL = 'PROFESSIONAL',
  /** @deprecated Legacy */
  RAW_MATERIALS = 'RAW_MATERIALS',
  REAL_ESTATE = 'REAL_ESTATE',
  /** @deprecated Legacy */
  RELIGION = 'RELIGION',
  RETAIL = 'RETAIL',
  TECHNOLOGY = 'TECHNOLOGY',
  /** @deprecated Legacy */
  TELECOMMUNICATIONS = 'TELECOMMUNICATIONS',
  /** @deprecated Legacy */
  TRANSPORTATION = 'TRANSPORTATION',
  /** @deprecated Legacy */
  TRAVEL = 'TRAVEL',
}

/** A2P brand registration general info */
export type A2PInfo = {
  __typename?: 'A2PInfo';
  brandId?: Maybe<Scalars['String']['output']>;
  campaignId?: Maybe<Scalars['String']['output']>;
  incorporationDate?: Maybe<Scalars['DateTime']['output']>;
  maxTpm: Scalars['Float']['output'];
  status: A2PInfoStatus;
  statusUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** A2P brand registration general status */
export enum A2PInfoStatus {
  APPROVED = 'APPROVED',
  DEACTIVATED = 'DEACTIVATED',
  DECLINED = 'DECLINED',
  NONE = 'NONE',
  PENDING = 'PENDING',
  REACTIVATION_SUBMITTED = 'REACTIVATION_SUBMITTED',
  REVIEW = 'REVIEW',
}

/** A2P registration all info */
export type A2PRegistration = {
  __typename?: 'A2PRegistration';
  brand: A2PBrand;
  campaign: A2PBrandCampaign;
  ownPhone?: Maybe<OwnPhone>;
};

/** Account */
export type Account = {
  __typename?: 'Account';
  a2PStatus?: Maybe<A2PInfoStatus>;
  affiliate?: Maybe<Affiliate>;
  analytics?: Maybe<AccountAnalytics>;
  billingProfile?: Maybe<BillingProfile>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Return dialer status. */
  dialerStatus: DialerStatus;
  email: Scalars['Email']['output'];
  /** List of account features */
  features?: Maybe<Array<Feature>>;
  firestoreId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  inBusinessHours: Scalars['Boolean']['output'];
  isOnboarded: Scalars['Boolean']['output'];
  isSubAccount: Scalars['Boolean']['output'];
  isTest: Scalars['Boolean']['output'];
  messagingProfile?: Maybe<MessagingProfile>;
  name?: Maybe<Name>;
  ownerId: Scalars['String']['output'];
  parentAccount?: Maybe<Account>;
  permissions: AccountPermissionsView;
  phone?: Maybe<Scalars['String']['output']>;
  products: Array<Product>;
  questionnaire?: Maybe<AccountQuestionnaire>;
  quotasUsage: Array<QuotaUsage>;
  secondaryPlans?: Maybe<Array<Plan>>;
  secondarySubscriptions?: Maybe<Array<CurrentSubscription>>;
  settings?: Maybe<AccountSettings>;
  status: AccountStatus;
  subAccounts?: Maybe<Array<Account>>;
  subscription?: Maybe<CurrentSubscription>;
  subscriptions?: Maybe<Array<CurrentSubscription>>;
  summary: AccountSummary;
  timeZone?: Maybe<Scalars['String']['output']>;
  tollFreeStatus?: Maybe<TollFreeInfoStatus>;
  type: AccountType;
};

/** Account analytics */
export type AccountAnalytics = {
  __typename?: 'AccountAnalytics';
  campaign?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  fbclid?: Maybe<Scalars['String']['output']>;
  gclid?: Maybe<Scalars['String']['output']>;
  googleClientId?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  msclkid?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AccountAnalyticsInput = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  fbclid?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  googleClientId?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  msclkid?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Account counters */
export type AccountCounters = {
  __typename?: 'AccountCounters';
  activePrimary?: Maybe<Scalars['Int']['output']>;
  activeSecondary?: Maybe<Scalars['Int']['output']>;
  inactive?: Maybe<Scalars['Int']['output']>;
  monthNewActive?: Maybe<Scalars['Int']['output']>;
  plans: Array<AccountCountersPlan>;
  trial?: Maybe<Scalars['Int']['output']>;
};

/** Account counters plan */
export type AccountCountersPlan = {
  __typename?: 'AccountCountersPlan';
  total?: Maybe<Scalars['Int']['output']>;
  type: PlanType;
};

export type AccountList = {
  __typename?: 'AccountList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Account>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** The view of restricted actions outside business hours for an account */
export type AccountOutsideBusinessHoursRestrictionsView = {
  __typename?: 'AccountOutsideBusinessHoursRestrictionsView';
  accountId: Scalars['String']['output'];
  restriction: OutsideBusinessHoursRestrictionType;
};

/** Account password */
export type AccountPassword = {
  __typename?: 'AccountPassword';
  password: Scalars['String']['output'];
};

/** Account permissions */
export type AccountPermissionsView = {
  __typename?: 'AccountPermissionsView';
  allowCrmIntegrations: Scalars['Boolean']['output'];
  allowDripCampaign: Scalars['Boolean']['output'];
  allowKeywordCampaign: Scalars['Boolean']['output'];
  allowOptOutDisable: Scalars['Boolean']['output'];
  allowScheduleCampaign: Scalars['Boolean']['output'];
  allowThrottleCampaign: Scalars['Boolean']['output'];
  optOutDisabled: Scalars['Boolean']['output'];
};

/** Account popup */
export type AccountPopup = {
  __typename?: 'AccountPopup';
  accountId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  popupId: Scalars['String']['output'];
};

/** Account questionnaire */
export type AccountQuestionnaire = {
  __typename?: 'AccountQuestionnaire';
  employees?: Maybe<Scalars['String']['output']>;
  experience?: Maybe<Scalars['String']['output']>;
  industry?: Maybe<Scalars['String']['output']>;
  marketingChannels?: Maybe<Array<Scalars['String']['output']>>;
  marketingTraining?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  realEstate?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  smsMarketing?: Maybe<Scalars['String']['output']>;
};

export type AccountQuestionnaireInput = {
  employees: Scalars['String']['input'];
  industry: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

/** Account settings */
export type AccountSettings = {
  __typename?: 'AccountSettings';
  defaultLabelColor?: Maybe<Scalars['String']['output']>;
  openNewTab?: Maybe<Scalars['Boolean']['output']>;
  refreshRate?: Maybe<Scalars['Float']['output']>;
  viewedPopups?: Maybe<Array<Scalars['String']['output']>>;
};

/** The ID for a given popup */
export enum AccountSettingsPopup {
  anyPasswordReset = 'anyPasswordReset',
  campaignNewTemplatesModal = 'campaignNewTemplatesModal',
  campaignPromoModal = 'campaignPromoModal',
  firstLogin = 'firstLogin',
  forcePasswordReset = 'forcePasswordReset',
  newTermsAndConditions = 'newTermsAndConditions',
  passwordResetPopup = 'passwordResetPopup',
  popupOnboarding = 'popupOnboarding',
  popupReferralPromo = 'popupReferralPromo',
  popupTrialFirstDay = 'popupTrialFirstDay',
  popupTrialSecondDay = 'popupTrialSecondDay',
  showPasswordResetPopup = 'showPasswordResetPopup',
  softPasswordReset = 'softPasswordReset',
}

/** Account status */
export enum AccountStatus {
  ABANDONED = 'ABANDONED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  TRIAL = 'TRIAL',
}

/** Account summary */
export type AccountSummary = {
  __typename?: 'AccountSummary';
  /** @deprecated The type will be changed to `ProfileBIlling` in the on dialer step */
  lastBilling?: Maybe<LastBilling>;
  productsFees?: Maybe<Array<ProfileProductFees>>;
  totalBilled?: Maybe<Scalars['Int']['output']>;
  totalBilledToDate?: Maybe<Scalars['Int']['output']>;
  /** @deprecated User `productsFees` instead */
  totalMembershipFees?: Maybe<Scalars['Int']['output']>;
  totalSkiptraceUsed?: Maybe<Scalars['Int']['output']>;
  totalSmsUsed?: Maybe<Scalars['Int']['output']>;
  trialPeriod?: Maybe<Period>;
};

/** Account type */
export enum AccountType {
  REAL_ESTATE = 'REAL_ESTATE',
  UNIVERSAL = 'UNIVERSAL',
}

export type AccountsFilter = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  fullSearch?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Accounts summary */
export type AccountsSummary = {
  __typename?: 'AccountsSummary';
  products: Array<ProductSummary>;
  subscriptions: SubscriptionsSummary;
};

export type AddContactToDncInput = {
  contactId: Scalars['String']['input'];
};

export type AddGroupToContactsInput = {
  groupId: Scalars['String']['input'];
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AddLabelToCallAndContactInput = {
  applicationCallId: Scalars['String']['input'];
  batchId: Scalars['String']['input'];
  labelId: Scalars['String']['input'];
};

export type AddLabelsToContactsInput = {
  ids: Array<Scalars['String']['input']>;
  labelIds: Array<Scalars['String']['input']>;
};

export type AdminAddOwnPhoneInput = {
  accountId: Scalars['String']['input'];
  phone: Scalars['PhoneNumberType']['input'];
  scope?: InputMaybe<Scope>;
  type?: InputMaybe<OwnPhoneType>;
};

export type AdminChangeAccountMessagingProviderInput = {
  accountId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider: MessagingProviderType;
};

export type AdminChangeSubAccountMessagingProviderInput = {
  accountId: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AdminRegisterAffiliateLeadInput = {
  accountId: Scalars['String']['input'];
  affiliateId: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AdminRemoveOwnPhoneInput = {
  phone: Scalars['String']['input'];
};

export type AdminUpdateAccountInput = {
  email?: InputMaybe<Scalars['Email']['input']>;
  enterpriseLevel?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  isOnboarded?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  optOutDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptions?: InputMaybe<Array<UpdateAccountSubscriptionInput>>;
};

/** Affiliate */
export type Affiliate = {
  __typename?: 'Affiliate';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['Email']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  referralId: Scalars['String']['output'];
  referralLink: Scalars['String']['output'];
};

export type AffiliateFilter = {
  fullSearch?: InputMaybe<Scalars['String']['input']>;
};

export type AffiliateList = {
  __typename?: 'AffiliateList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Affiliate>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Possible machine detection results */
export enum AmdResult {
  FAILED = 'FAILED',
  HUMAN = 'HUMAN',
  MACHINE = 'MACHINE',
  UNKNOWN = 'UNKNOWN',
}

export type ApplyDiscountInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  coupon?: InputMaybe<Scalars['String']['input']>;
};

/** Article */
export type Article = {
  __typename?: 'Article';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

/** Available phone */
export type AvailablePhoneView = {
  __typename?: 'AvailablePhoneView';
  phone: Scalars['String']['output'];
};

/** Balance */
export type Balance = {
  __typename?: 'Balance';
  balance: Scalars['Money']['output'];
  firstTopUpBonusApplied: Scalars['Boolean']['output'];
  lastTopUp?: Maybe<LastTopUp>;
  schedule?: Maybe<BalanceSchedule>;
  topUpAmount: Scalars['Money']['output'];
  topUpBonus: Scalars['Money']['output'];
};

/** Balance schedule */
export type BalanceSchedule = {
  __typename?: 'BalanceSchedule';
  amount: Scalars['Money']['output'];
  enabled: Scalars['Boolean']['output'];
  limit: Scalars['Money']['output'];
};

/** Billing interval */
export enum BillingInterval {
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

/** Billing profile */
export type BillingProfile = {
  __typename?: 'BillingProfile';
  backupPaymentMethod: Scalars['Boolean']['output'];
  balance?: Maybe<Balance>;
  email: Scalars['Email']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Last billing */
  lastBilling?: Maybe<ProfileBilling>;
  productsFees?: Maybe<Array<ProfileProductFees>>;
  sources?: Maybe<Array<PaymentSource>>;
  unsubscribeCoupons?: Maybe<Array<Scalars['String']['output']>>;
};

/** Billing report */
export type BillingReport = {
  __typename?: 'BillingReport';
  url: Scalars['String']['output'];
};

export type BlockContactsInput = {
  labelIds: Array<Scalars['String']['input']>;
};

/** Brand registration file */
export type BrandRegistrationFile = {
  __typename?: 'BrandRegistrationFile';
  contentType: Scalars['String']['output'];
  filePath: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type BuildMessageTemplateInput = {
  content: Scalars['String']['input'];
  optOut: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};

/** Calendar event */
export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  relationId?: Maybe<Scalars['String']['output']>;
  startAt: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  type: CalendarEventType;
};

export type CalendarEventList = {
  __typename?: 'CalendarEventList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<CalendarEvent>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Calendar event type */
export enum CalendarEventType {
  CAMPAIGN = 'CAMPAIGN',
  CONTACT = 'CONTACT',
  FOLLOWUP = 'FOLLOWUP',
}

export type CalendarEventsFilter = {
  endAt?: InputMaybe<QueryDateFilter>;
  startAt?: InputMaybe<QueryDateFilter>;
};

/** Call mode */
export enum CallMode {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

/** Call recording action */
export enum CallRecordingAction {
  PAUSE = 'PAUSE',
  RESUME = 'RESUME',
  START = 'START',
  STOP = 'STOP',
}

/** Call Script */
export type CallScript = {
  __typename?: 'CallScript';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CallScriptList = {
  __typename?: 'CallScriptList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<CallScript>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CallScriptsFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Call settings */
export type CallSettings = {
  __typename?: 'CallSettings';
  activeIn?: Maybe<Scalars['String']['output']>;
  callAutoReply?: Maybe<CallSettingsAutoReply>;
  isSipEnabled?: Maybe<Scalars['Boolean']['output']>;
  voiceMail?: Maybe<CallSettingsAutoReply>;
};

/** Call settings auto reply */
export type CallSettingsAutoReply = {
  __typename?: 'CallSettingsAutoReply';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  voiceType?: Maybe<CallSettingsVoiceType>;
};

export type CallSettingsAutoReplyInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  voiceType?: InputMaybe<CallSettingsVoiceType>;
};

export type CallSettingsInput = {
  activeIn?: InputMaybe<Scalars['String']['input']>;
  callAutoReply?: InputMaybe<CallSettingsAutoReplyInput>;
  isSipEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  voiceMail?: InputMaybe<CallSettingsAutoReplyInput>;
};

/** Call voice type */
export enum CallSettingsVoiceType {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

/** Dialer call state */
export enum CallState {
  ANSWERED = 'ANSWERED',
  CANCELLED = 'CANCELLED',
  DIALING = 'DIALING',
  DISCONNECTED = 'DISCONNECTED',
  FAILED = 'FAILED',
  INITIAL = 'INITIAL',
  RINGING = 'RINGING',
}

/** Campaign */
export type Campaign = {
  __typename?: 'Campaign';
  clickThroughProgress?: Maybe<Scalars['Float']['output']>;
  clickThroughStartAfter?: Maybe<ClickThroughStartAfterView>;
  contacts: Scalars['Int']['output'];
  costs?: Maybe<CampaignCosts>;
  createdAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  firestoreId?: Maybe<Scalars['String']['output']>;
  firstMessageTemplate?: Maybe<MessageTemplate>;
  groups?: Maybe<Array<GroupView>>;
  id: Scalars['String']['output'];
  keyword?: Maybe<KeywordTemplate>;
  lastSentAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  ownPhones: Array<OwnPhone>;
  rule?: Maybe<CampaignRule>;
  scheduleOptions: CampaignScheduleOptions;
  sendFirstMessage?: Maybe<Scalars['Boolean']['output']>;
  sequences: Array<SequenceView>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  state: CampaignState;
  stats?: Maybe<CampaignStats>;
  type: CampaignType;
};

/** Campaign costs */
export type CampaignCosts = {
  __typename?: 'CampaignCosts';
  captured: Scalars['Float']['output'];
  refunded: Scalars['Float']['output'];
};

export type CampaignDynamicDataInput = {
  clickThroughProgress?: InputMaybe<Scalars['Int']['input']>;
  clickThroughStartAfter?: InputMaybe<ClickThroughStartAfter>;
};

/** Campaign export type */
export enum CampaignExportType {
  ALL = 'ALL',
  CARRIER_BLOCKED = 'CARRIER_BLOCKED',
  DELIVERED = 'DELIVERED',
  RESPONDED = 'RESPONDED',
  SENT = 'SENT',
  UNDELIVERED = 'UNDELIVERED',
  UNRESPONDED = 'UNRESPONDED',
  UNSENT = 'UNSENT',
}

export type CampaignFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  profileId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<CampaignStatus>>;
  type?: InputMaybe<CampaignType>;
};

export type CampaignList = {
  __typename?: 'CampaignList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Campaign>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Campaign message status */
export enum CampaignMessageStatus {
  ACTIVE = 'ACTIVE',
  DONE = 'DONE',
  INACTIVE = 'INACTIVE',
}

/** Informs of a change on the progress of the campaign's sequences */
export type CampaignProgressUpdated = {
  __typename?: 'CampaignProgressUpdated';
  campaignId: Scalars['String']['output'];
  /** Amount already sent */
  progress: Array<SequenceProgress>;
};

/** Campaign report view */
export type CampaignReport = {
  __typename?: 'CampaignReport';
  totalKeywordCampaigns: Scalars['Float']['output'];
  totalStandardCampaigns: Scalars['Float']['output'];
};

/** Campaign rule */
export type CampaignRule = {
  __typename?: 'CampaignRule';
  groupId?: Maybe<Scalars['String']['output']>;
  labelId?: Maybe<Scalars['String']['output']>;
  removeFromNextSequences?: Maybe<Scalars['Boolean']['output']>;
  strategy?: Maybe<CampaignRuleStrategy>;
};

export type CampaignRuleInput = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  labelId?: InputMaybe<Scalars['String']['input']>;
  removeFromNextSequences?: InputMaybe<Scalars['Boolean']['input']>;
  strategy?: InputMaybe<CampaignRuleStrategy>;
};

/** Campaign rule strategy */
export enum CampaignRuleStrategy {
  CALL_REPLY = 'CALL_REPLY',
  NO_REPLY = 'NO_REPLY',
  REPLY = 'REPLY',
  SMS_REPLY = 'SMS_REPLY',
}

/** Campaign schedule */
export type CampaignSchedule = {
  __typename?: 'CampaignSchedule';
  endTime: Scalars['Float']['output'];
  nextTime?: Maybe<Scalars['Float']['output']>;
  startTime: Scalars['Float']['output'];
};

/** Campaign schedule options */
export type CampaignScheduleOptions = {
  __typename?: 'CampaignScheduleOptions';
  frequency?: Maybe<Frequency>;
  hasCustomBatchSize?: Maybe<Scalars['Boolean']['output']>;
  hasCustomStartDate?: Maybe<Scalars['Boolean']['output']>;
  interval?: Maybe<Scalars['Float']['output']>;
  perBatch?: Maybe<Scalars['Float']['output']>;
  startAt?: Maybe<Scalars['DateTime']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type CampaignScheduleOptionsInput = {
  expiresAt?: InputMaybe<Scalars['Float']['input']>;
  frequency: Frequency;
  hasCustomBatchSize?: InputMaybe<Scalars['Boolean']['input']>;
  hasCustomStartDate?: InputMaybe<Scalars['Boolean']['input']>;
  interval: Scalars['Int']['input'];
  ownPhones: Array<Scalars['String']['input']>;
  perBatch: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  sequence: Array<SequenceOptions>;
  startAt: Scalars['DateTime']['input'];
};

export type CampaignSequenceInput = {
  days?: InputMaybe<Array<Scalars['Int']['input']>>;
  delay?: InputMaybe<Scalars['Float']['input']>;
  hasCustomTimeRestrictions?: InputMaybe<Scalars['Boolean']['input']>;
  rule?: InputMaybe<CampaignRuleInput>;
  step?: InputMaybe<Scalars['Int']['input']>;
  templates: Array<Scalars['String']['input']>;
  timeRange: TimeRangeInput;
  type: SequenceType;
};

/** Campaign state */
export type CampaignState = {
  __typename?: 'CampaignState';
  reason?: Maybe<StateReason>;
  status: CampaignStatus;
};

/** Campaign state reason type */
export enum CampaignStateReasonType {
  HIGH_CARRIER_BLOCK_RATE = 'HIGH_CARRIER_BLOCK_RATE',
  HIGH_OPT_OUT_RATE = 'HIGH_OPT_OUT_RATE',
  LOW_DELIVERY_RATE = 'LOW_DELIVERY_RATE',
  PROVIDER_SUSPENDED = 'PROVIDER_SUSPENDED',
  RVM_LIMIT = 'RVM_LIMIT',
  SMS_WEEKLY_LIMIT = 'SMS_WEEKLY_LIMIT',
}

/** Campaign stats */
export type CampaignStats = {
  __typename?: 'CampaignStats';
  smsRefunded?: Maybe<Scalars['Float']['output']>;
  smsUsed?: Maybe<Scalars['Float']['output']>;
};

/** Campaign status */
export enum CampaignStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED',
  PAUSED = 'PAUSED',
  STOPPED = 'STOPPED',
}

/** Campaign type */
export enum CampaignType {
  KEYWORD = 'KEYWORD',
  STANDARD = 'STANDARD',
}

/** Campaigns aggregation counters */
export type CampaignsAggregationCountersView = {
  __typename?: 'CampaignsAggregationCountersView';
  active: Scalars['Float']['output'];
  deleted: Scalars['Float']['output'];
  draft: Scalars['Float']['output'];
  ended: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

/** Campaigns aggregation */
export type CampaignsAggregationView = {
  __typename?: 'CampaignsAggregationView';
  keyword: CampaignsAggregationCountersView;
  standard: CampaignsAggregationCountersView;
};

export type ChangesItemView = {
  __typename?: 'ChangesItemView';
  entityId: Scalars['String']['output'];
  operation: ChangesOperation;
  operationAt: Scalars['DateTime']['output'];
};

/** Changes operation */
export enum ChangesOperation {
  CREATED = 'CREATED',
  REMOVED = 'REMOVED',
  UPDATED = 'UPDATED',
}

export type ChangesView = {
  __typename?: 'ChangesView';
  items: Array<ChangesItemView>;
};

/** Click-through start after */
export type ClickThroughStartAfter = {
  createdAt: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
};

/** Click-through start after */
export type ClickThroughStartAfterView = {
  __typename?: 'ClickThroughStartAfterView';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
};

/** Contact */
export type Contact = {
  __typename?: 'Contact';
  attrs?: Maybe<Array<ContactAttribute>>;
  blocked?: Maybe<Scalars['Boolean']['output']>;
  canBeRemoved: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  dealClosed?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['Email']['output']>;
  favorite?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<GroupView>>;
  id: Scalars['String']['output'];
  labels?: Maybe<Array<Label>>;
  lastName?: Maybe<Scalars['String']['output']>;
  notes: Array<ContactNote>;
  phone: Scalars['String']['output'];
  realEstateAttrs?: Maybe<ContactRealEstateAttributes>;
  status: ContactStatus;
  unsubscribed?: Maybe<Scalars['Boolean']['output']>;
};

/** Contact attribute */
export type ContactAttribute = {
  __typename?: 'ContactAttribute';
  fieldId: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ContactAttributesInput = {
  fieldId: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Contact counters */
export type ContactCountersView = {
  __typename?: 'ContactCountersView';
  activeContacts: Scalars['Int']['output'];
  activeGroups: Scalars['Int']['output'];
  deletedContacts: Scalars['Int']['output'];
};

export type ContactFilter = {
  fullSearch?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  isBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  status: ContactStatus;
};

/** List of contacts */
export type ContactList = {
  __typename?: 'ContactList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Contact>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Contact note */
export type ContactNote = {
  __typename?: 'ContactNote';
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ContactNoteFilter = {
  contactId: Scalars['String']['input'];
  profileId?: InputMaybe<Scalars['String']['input']>;
};

export type ContactNoteList = {
  __typename?: 'ContactNoteList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<ContactNote>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ContactOrder = {
  by?: InputMaybe<ContactOrderBy>;
  direction?: InputMaybe<OrderQuery>;
};

/** Contact order pagination */
export enum ContactOrderBy {
  CREATED_AT = 'CREATED_AT',
  FAVORITE = 'FAVORITE',
}

/** Contact real estate attribute */
export type ContactRealEstateAttribute = {
  __typename?: 'ContactRealEstateAttribute';
  id: RealEstateAttribute;
  value: Scalars['String']['output'];
};

/** Contact real estate attributes */
export type ContactRealEstateAttributes = {
  __typename?: 'ContactRealEstateAttributes';
  attrs: Array<ContactRealEstateAttribute>;
  provider: Scalars['String']['output'];
};

export type ContactRealEstateAttributesInput = {
  id: RealEstateAttribute;
  value: Scalars['String']['input'];
};

/** Contact report view */
export type ContactReportView = {
  __typename?: 'ContactReportView';
  leadCount: Scalars['Float']['output'];
  totalContacts: Scalars['Float']['output'];
  totalContactsResponded: Scalars['Float']['output'];
};

/** Contact status */
export enum ContactStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
}

/** Conversation */
export type Conversation = {
  __typename?: 'Conversation';
  campaigns?: Maybe<Array<Campaign>>;
  canBeRemoved: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isAiFiltered?: Maybe<Scalars['Boolean']['output']>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  isRead?: Maybe<Scalars['Boolean']['output']>;
  isUnsubscribed?: Maybe<Scalars['Boolean']['output']>;
  labels?: Maybe<Array<Label>>;
  lastMessage?: Maybe<Message>;
  lastMessageAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  outgoingPhone?: Maybe<Scalars['PhoneNumberType']['output']>;
  ownPhone?: Maybe<OwnPhone>;
  phone: Scalars['PhoneNumberType']['output'];
  profileId: Scalars['String']['output'];
  status: ConversationStatus;
  unreadMessages?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Conversation category */
export enum ConversationCategory {
  AI_FILTERED = 'AI_FILTERED',
  ALL = 'ALL',
  DELETED = 'DELETED',
  MISSED_CALL = 'MISSED_CALL',
  OPTED_OUT = 'OPTED_OUT',
  SENT = 'SENT',
  UNREAD = 'UNREAD',
}

export type ConversationFilter = {
  campaigns?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<ConversationCategory>;
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  fullSearch?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  profileId?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConversationList = {
  __typename?: 'ConversationList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Conversation>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ConversationOrder = {
  by?: InputMaybe<ConversationOrderBy>;
  direction?: InputMaybe<OrderQuery>;
};

/** Conversation order pagination */
export enum ConversationOrderBy {
  DEAL_CLOSED_AT = 'DEAL_CLOSED_AT',
  FAVORITE = 'FAVORITE',
  ID = 'ID',
  UPDATED_AT = 'UPDATED_AT',
}

/** Conversation status */
export enum ConversationStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

/** Coupon */
export type Coupon = {
  __typename?: 'Coupon';
  amountOff?: Maybe<Scalars['Money']['output']>;
  id: Scalars['String']['output'];
  percentOff?: Maybe<Scalars['Int']['output']>;
};

export type CreateCalendarEventInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endAt: Scalars['DateTime']['input'];
  relationId?: InputMaybe<Scalars['String']['input']>;
  startAt: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
  type: CalendarEventType;
};

export type CreateCallScriptInput = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateCampaignInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  ownPhones: Array<Scalars['String']['input']>;
  rule?: InputMaybe<CampaignRuleInput>;
  scheduleOptions: ScheduleOptionsInput;
  sendFirstMessage?: InputMaybe<Scalars['Boolean']['input']>;
  sequences?: InputMaybe<Array<CampaignSequenceInput>>;
  type: CampaignType;
};

export type CreateContactInput = {
  attrs?: InputMaybe<Array<ContactAttributesInput>>;
  firstName: Scalars['String']['input'];
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CreateContactNoteInput = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  text: Scalars['String']['input'];
};

export type CreateConversationInput = {
  contactId: Scalars['String']['input'];
};

export type CreateCrmIntegrationInput = {
  name: Scalars['String']['input'];
  provider?: InputMaybe<CrmProviderInput>;
  status: CrmIntegrationStatus;
  type: CrmProviderType;
};

export type CreateDncPhoneInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CreateDialerCampaignInput = {
  groups: Array<Scalars['String']['input']>;
  labels: Array<Scalars['String']['input']>;
  mode?: InputMaybe<CallMode>;
  name: Scalars['String']['input'];
  phones: Array<Scalars['String']['input']>;
  script: Scalars['String']['input'];
};

export type CreateFeedbackInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  score: Scalars['Int']['input'];
  source?: InputMaybe<FeedbackSource>;
};

export type CreateFieldInput = {
  name: Scalars['String']['input'];
};

export type CreateFieldsTemplateInput = {
  fields: Array<InputMaybe<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
};

export type CreateGroupInput = {
  name: Scalars['String']['input'];
};

export type CreateKeywordTemplateInput = {
  keyword: Scalars['String']['input'];
  template: KeywordTemplateTemplateInput;
};

export type CreateLabelInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  scopes?: InputMaybe<Scope>;
  title: Scalars['String']['input'];
};

export type CreateListInput = {
  fileName: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
  type: ListType;
};

export type CreateMacroInput = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateMarketingPopupInput = {
  audiences?: InputMaybe<Array<MarketingPopupAudience>>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<MarketingPopupStatus>;
  template?: InputMaybe<MarketingPopupTemplateInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMessageTemplateInput = {
  name: Scalars['String']['input'];
  template: MessageTemplateTemplateInput;
  type: MessageTemplateType;
};

export type CreateRestrictedPhoneInput = {
  phone: Scalars['String']['input'];
};

export type CreateScheduledMessageInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<Array<MessageAttachmentInput>>;
  contactId: Scalars['String']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  fromNumber?: InputMaybe<Scalars['String']['input']>;
  scheduledAt: Scalars['DateTime']['input'];
};

export type CreateSubaccountInput = {
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['PhoneNumberType']['input'];
  products?: InputMaybe<Array<Product>>;
};

/** CRM integration */
export type CrmIntegration = {
  __typename?: 'CrmIntegration';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  provider?: Maybe<CrmProviderView>;
  status: CrmIntegrationStatus;
  type?: Maybe<CrmProviderType>;
};

export type CrmIntegrationFilter = {
  status?: InputMaybe<CrmIntegrationStatus>;
};

/** List of CRM integrations */
export type CrmIntegrationList = {
  __typename?: 'CrmIntegrationList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<CrmIntegration>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CrmIntegrationOrder = {
  by?: InputMaybe<CrmIntegrationOrderBy>;
  direction?: InputMaybe<OrderQuery>;
};

/** CRM integration order by */
export enum CrmIntegrationOrderBy {
  TYPE = 'TYPE',
}

/** CRM integration status */
export enum CrmIntegrationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type CrmProviderInput = {
  apiKey?: InputMaybe<Scalars['String']['input']>;
  labelsPhoneStatuses?: InputMaybe<Array<CrmProviderLabelMap>>;
  labelsPhoneTags?: InputMaybe<Array<Scalars['String']['input']>>;
  labelsPropertyStatuses?: InputMaybe<Array<CrmProviderLabelMap>>;
  sendNotes?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CrmProviderLabelMap = {
  labelId: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

/** CRM provider label data */
export type CrmProviderLabelView = {
  __typename?: 'CrmProviderLabelView';
  label: Label;
  status: Scalars['String']['output'];
};

/** CRM provider type */
export enum CrmProviderType {
  REISIFT = 'REISIFT',
  ZAPIER = 'ZAPIER',
}

/** CRM provider */
export type CrmProviderView = {
  __typename?: 'CrmProviderView';
  apiKey?: Maybe<Scalars['String']['output']>;
  labelsPhoneStatuses?: Maybe<Array<CrmProviderLabelView>>;
  labelsPhoneTags?: Maybe<Array<Label>>;
  labelsPropertyStatuses?: Maybe<Array<CrmProviderLabelView>>;
  sendNotes?: Maybe<Scalars['Boolean']['output']>;
  targetUrl?: Maybe<Scalars['String']['output']>;
};

/** Current subscription */
export type CurrentSubscription = {
  __typename?: 'CurrentSubscription';
  base: Scalars['Boolean']['output'];
  discount?: Maybe<Discount>;
  endAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  membershipMonths?: Maybe<Scalars['Float']['output']>;
  nextBillingDate?: Maybe<Scalars['DateTime']['output']>;
  plan?: Maybe<Plan>;
  price: Scalars['Money']['output'];
  product: Product;
  reason?: Maybe<Scalars['String']['output']>;
  secondary: Scalars['Boolean']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: SubscriptionStatus;
  title: Scalars['String']['output'];
  trialEndedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CursorPagination = {
  /** Max number of items to return in the query. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Token for fetching the next page of items. */
  nextPageToken?: InputMaybe<Scalars['String']['input']>;
};

/** Deals closed report view */
export type DealsClosedReport = {
  __typename?: 'DealsClosedReport';
  dealsClosed: Scalars['Float']['output'];
};

/** Device platform */
export enum DevicePlatform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

/** Dialer call outcome report */
export type DialerCallOutcomeReportView = {
  __typename?: 'DialerCallOutcomeReportView';
  allLabeledContacts: Scalars['Float']['output'];
  contactsWithCurrentLabel: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};

/** Dialer call report view */
export type DialerCallReportView = {
  __typename?: 'DialerCallReportView';
  answered: Scalars['Float']['output'];
  averageDuration: Scalars['Float']['output'];
  conversationRate: Scalars['Float']['output'];
  conversations: Scalars['Float']['output'];
  responseRate: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

/** Dialer Campaign */
export type DialerCampaign = {
  __typename?: 'DialerCampaign';
  contacts: Scalars['Int']['output'];
  contactsWithOutcome?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  groups?: Maybe<Array<GroupView>>;
  id: Scalars['String']['output'];
  labels: Array<Scalars['String']['output']>;
  lastCallAt?: Maybe<Scalars['DateTime']['output']>;
  lines: Array<Scalars['String']['output']>;
  mode: CallMode;
  name: Scalars['String']['output'];
  ownPhones: Array<OwnPhone>;
  phones: Array<Scalars['String']['output']>;
  /** Script ID */
  script?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: CampaignStatus;
};

/** Type of export for dialer campaign */
export enum DialerCampaignExportType {
  ALL = 'ALL',
  ANSWERED = 'ANSWERED',
  NOT_ANSWERED = 'NOT_ANSWERED',
}

export type DialerCampaignFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  profileId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<CampaignStatus>>;
};

export type DialerCampaignList = {
  __typename?: 'DialerCampaignList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<DialerCampaign>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Dialer campaigns aggregation counters */
export type DialerCampaignsAggregationCountersView = {
  __typename?: 'DialerCampaignsAggregationCountersView';
  active: Scalars['Float']['output'];
  deleted: Scalars['Float']['output'];
  draft: Scalars['Float']['output'];
  ended: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

/** Dialer Campaigns aggregation */
export type DialerCampaignsAggregationView = {
  __typename?: 'DialerCampaignsAggregationView';
  dialer: DialerCampaignsAggregationCountersView;
};

/** Dialer contact report view */
export type DialerContactReportView = {
  __typename?: 'DialerContactReportView';
  deals: Scalars['Float']['output'];
  leads: Scalars['Float']['output'];
};

export type DialerReportsFilter = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['String']['input']>;
};

/** Dialer status */
export enum DialerStatus {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

/** Discount */
export type Discount = {
  __typename?: 'Discount';
  amount: Scalars['Money']['output'];
  coupon: Scalars['String']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  percent?: Maybe<Scalars['Int']['output']>;
  promoCode?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EndTrialInput = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type ExportCampaignToFileInput = {
  id: Scalars['String']['input'];
  type: CampaignExportType;
};

export type ExportCampaignToGroupInput = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  newGroupName?: InputMaybe<Scalars['String']['input']>;
  type: CampaignExportType;
};

export type ExportConversationsToCrmIntegrationInput = {
  conversationId?: InputMaybe<Scalars['String']['input']>;
  crmIntegrationId: Scalars['String']['input'];
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  exportType?: InputMaybe<ExportType>;
  status?: InputMaybe<ConversationStatus>;
};

/** Export conversations to crm integration result */
export type ExportConversationsToCrmIntegrationResultView = {
  __typename?: 'ExportConversationsToCRMIntegrationResultView';
  success: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ExportConversationsToFileInput = {
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  exportType: ExportType;
  status?: InputMaybe<ConversationStatus>;
};

export type ExportDialerCampaignToFileInput = {
  id: Scalars['String']['input'];
  type: DialerCampaignExportType;
};

export type ExportDialerCampaignToGroupInput = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  newGroupName?: InputMaybe<Scalars['String']['input']>;
  type: DialerCampaignExportType;
};

export type ExportListInput = {
  id: Scalars['String']['input'];
};

export type ExportSkiptraceInput = {
  exportType: ExportSkiptraceType;
  id: Scalars['String']['input'];
};

/** Export skiptrace type */
export enum ExportSkiptraceType {
  ALL = 'ALL',
  LANDLINE_ONLY = 'LANDLINE_ONLY',
  MOBILE_ONLY = 'MOBILE_ONLY',
}

export type ExportSuppressionsInput = {
  source?: InputMaybe<SuppressionSource>;
};

/** Export conversation type */
export enum ExportType {
  CAMPAIGNS = 'CAMPAIGNS',
  GROUPS = 'GROUPS',
  LABELS = 'LABELS',
}

/** Feature */
export type Feature = {
  __typename?: 'Feature';
  limit?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Money']['output']>;
  product?: Maybe<Product>;
  tier?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  type: FeatureType;
};

/** Feature */
export enum FeatureType {
  A2P_10DLC = 'A2P_10DLC',
  ALLOW_OPT_OUT_DISABLE = 'ALLOW_OPT_OUT_DISABLE',
  CALL = 'CALL',
  CAMPAIGN_SMS = 'CAMPAIGN_SMS',
  CONTACTS = 'CONTACTS',
  CRM_INTEGRATIONS = 'CRM_INTEGRATIONS',
  CUSTOM_WEBSITE = 'CUSTOM_WEBSITE',
  DIALER_CALL = 'DIALER_CALL',
  DIALER_CONTACTS = 'DIALER_CONTACTS',
  DIALER_SUB_ACCOUNTS = 'DIALER_SUB_ACCOUNTS',
  DRIP_CAMPAIGN = 'DRIP_CAMPAIGN',
  KEYWORD_CAMPAIGN = 'KEYWORD_CAMPAIGN',
  LOCAL_PHONE = 'LOCAL_PHONE',
  LOOKUP = 'LOOKUP',
  MESSAGING = 'MESSAGING',
  MMS = 'MMS',
  OPT_OUT_DISABLED = 'OPT_OUT_DISABLED',
  OWN_PHONE = 'OWN_PHONE',
  RVM = 'RVM',
  SCHEDULE_CAMPAIGN = 'SCHEDULE_CAMPAIGN',
  SKIPTRACE = 'SKIPTRACE',
  SMS = 'SMS',
  SMS_ALERT = 'SMS_ALERT',
  SUB_ACCOUNTS = 'SUB_ACCOUNTS',
  TEMPLATES = 'TEMPLATES',
  TEMPLATES_AI = 'TEMPLATES_AI',
  THROTTLE_CAMPAIGN = 'THROTTLE_CAMPAIGN',
  TOLL_FREE_PHONE = 'TOLL_FREE_PHONE',
  TRIAL_PHONE = 'TRIAL_PHONE',
}

/** Feedback */
export type Feedback = {
  __typename?: 'Feedback';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  source: FeedbackSource;
};

/** Feedback Source */
export enum FeedbackSource {
  MOBILE = 'MOBILE',
  WEB = 'WEB',
}

/** Field */
export type Field = {
  __typename?: 'Field';
  id: Scalars['String']['output'];
  key?: Maybe<PredefinedField>;
  name: Scalars['String']['output'];
  predefined: Scalars['Boolean']['output'];
};

export type FieldsFilter = {
  predefined?: InputMaybe<Scalars['Boolean']['input']>;
  subAccountId?: InputMaybe<Scalars['String']['input']>;
};

/** Fields template */
export type FieldsTemplate = {
  __typename?: 'FieldsTemplate';
  createdAt: Scalars['DateTime']['output'];
  fields?: Maybe<Array<Field>>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FieldsTemplateList = {
  __typename?: 'FieldsTemplateList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<FieldsTemplate>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type FindAvailablePhonesFilter = {
  areaCode?: InputMaybe<Scalars['String']['input']>;
  type: OwnPhoneType;
};

/** Frequency */
export enum Frequency {
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
  MINUTELY = 'MINUTELY',
}

export type GenerateSignedUrlInput = {
  /** The action to be permitted by signed url */
  action?: InputMaybe<SignedUrlAction>;
  /** The content type of the file */
  contentType: Scalars['String']['input'];
  /** The name of file */
  fileName: Scalars['String']['input'];
};

export type GetMessageAttachmentSignedUrlInput = {
  /** The action to be permitted by signed url */
  action?: InputMaybe<SignedUrlAction>;
  /** The content length of the file */
  contentLength: Scalars['Int']['input'];
  /** The content type of the file */
  contentType: Scalars['String']['input'];
  /** The name of file */
  fileName: Scalars['String']['input'];
};

export type GroupList = {
  __typename?: 'GroupList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<GroupView>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Group */
export type GroupView = {
  __typename?: 'GroupView';
  activeChats: Scalars['Int']['output'];
  campaigns: Array<Campaign>;
  canBeCloned: Scalars['Boolean']['output'];
  canBeRemoved: Scalars['Boolean']['output'];
  contacts: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  dialerCampaigns: Array<DialerCampaign>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type GroupsFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Health */
export type Health = {
  __typename?: 'Health';
  status: HealthStatus;
};

/** Health status */
export enum HealthStatus {
  DOWN = 'DOWN',
  UP = 'UP',
}

export type HourMinuteInput = {
  hour: Scalars['Int']['input'];
  minute: Scalars['Int']['input'];
};

export type HourMinuteView = {
  __typename?: 'HourMinuteView';
  hour: Scalars['Int']['output'];
  minute: Scalars['Int']['output'];
};

/** HubSpot profile field */
export enum HubSpotProfileField {
  A2P10DLC_REGISTRATION_STATUS = 'A2P10DLC_REGISTRATION_STATUS',
  A2P10DLC_REGISTRATION_STATUS_DATE = 'A2P10DLC_REGISTRATION_STATUS_DATE',
  ABANDONED_SIGN_UP = 'ABANDONED_SIGN_UP',
  ABANDONED_SIGN_UP_DATE = 'ABANDONED_SIGN_UP_DATE',
  ACCOUNT_TYPE = 'ACCOUNT_TYPE',
  ADDED_NEW_CARD = 'ADDED_NEW_CARD',
  ADDED_NEW_CARD_DATE = 'ADDED_NEW_CARD_DATE',
  ADDED_PAYMENT_INFO = 'ADDED_PAYMENT_INFO',
  ADDED_PAYMENT_INFO_DATE = 'ADDED_PAYMENT_INFO_DATE',
  AMOUNT_SPENT_ON_MESSAGES = 'AMOUNT_SPENT_ON_MESSAGES',
  AMOUNT_SPENT_ON_PHONE_NUMBERS = 'AMOUNT_SPENT_ON_PHONE_NUMBERS',
  AMOUNT_SPENT_ON_RVM = 'AMOUNT_SPENT_ON_RVM',
  AMOUNT_SPENT_ON_SKIPTRACING = 'AMOUNT_SPENT_ON_SKIPTRACING',
  AUTO_RECHARGE = 'AUTO_RECHARGE',
  AUTO_RECHARGE_ACTIVE_DATE = 'AUTO_RECHARGE_ACTIVE_DATE',
  AUTO_RECHARGE_INACTIVE_DATE = 'AUTO_RECHARGE_INACTIVE_DATE',
  BALANCE = 'BALANCE',
  CALLING_MONTHS_OF_MEMBERSHIP_STARTED_AT = 'CALLING_MONTHS_OF_MEMBERSHIP_STARTED_AT',
  CALLING_PLAN_AMOUNT = 'CALLING_PLAN_AMOUNT',
  CALLING_PLAN_INTERVAL = 'CALLING_PLAN_INTERVAL',
  CALLING_PLAN_NAME = 'CALLING_PLAN_NAME',
  CALLING_SUBSCRIPTION_STATUS = 'CALLING_SUBSCRIPTION_STATUS',
  CALLING_SUBSCRIPTION_STATUS_DATE = 'CALLING_SUBSCRIPTION_STATUS_DATE',
  CANCELED_SUBSCRIPTION = 'CANCELED_SUBSCRIPTION',
  CANCELED_SUBSCRIPTION_DATE = 'CANCELED_SUBSCRIPTION_DATE',
  COMPANY_NAME = 'COMPANY_NAME',
  COMPLETED_SIGN_UP = 'COMPLETED_SIGN_UP',
  COMPLETED_SIGN_UP_DATE = 'COMPLETED_SIGN_UP_DATE',
  CREATED_ACCOUNT = 'CREATED_ACCOUNT',
  CREATED_ACCOUNT_DATE = 'CREATED_ACCOUNT_DATE',
  CREATED_AT = 'CREATED_AT',
  CURRENT_BALANCE = 'CURRENT_BALANCE',
  DOWNGRADED_PLAN = 'DOWNGRADED_PLAN',
  DOWNGRADED_PLAN_DATE = 'DOWNGRADED_PLAN_DATE',
  EMAIL = 'EMAIL',
  EMPLOYEES = 'EMPLOYEES',
  ENDED_TRIAL = 'ENDED_TRIAL',
  ENDED_TRIAL_DATE = 'ENDED_TRIAL_DATE',
  FAILED_PAYMENT = 'FAILED_PAYMENT',
  FAILED_PAYMENT_REASON = 'FAILED_PAYMENT_REASON',
  FIRST_NAME = 'FIRST_NAME',
  INDUSTRY = 'INDUSTRY',
  INTEGRATION_STATUS = 'INTEGRATION_STATUS',
  JOB_TITLE = 'JOB_TITLE',
  LAST_BILLED_AMOUNT = 'LAST_BILLED_AMOUNT',
  LAST_BILLING_DATE = 'LAST_BILLING_DATE',
  LAST_INTEGRATION_ACTIVATED = 'LAST_INTEGRATION_ACTIVATED',
  LAST_INTEGRATION_ACTIVATED_DATE = 'LAST_INTEGRATION_ACTIVATED_DATE',
  LAST_INTEGRATION_INACTIVATED = 'LAST_INTEGRATION_INACTIVATED',
  LAST_INTEGRATION_INACTIVATED_DATE = 'LAST_INTEGRATION_INACTIVATED_DATE',
  LAST_LOGIN_DATE = 'LAST_LOGIN_DATE',
  LAST_NAME = 'LAST_NAME',
  MARKETING_CONTACT_STATUS = 'MARKETING_CONTACT_STATUS',
  MEMBERSHIP_STARTED_AT = 'MEMBERSHIP_STARTED_AT',
  MESSAGING_MONTHS_OF_MEMBERSHIP_STARTED_AT = 'MESSAGING_MONTHS_OF_MEMBERSHIP_STARTED_AT',
  MESSAGING_SUBSCRIPTION_STATUS = 'MESSAGING_SUBSCRIPTION_STATUS',
  MESSAGING_SUBSCRIPTION_STATUS_DATE = 'MESSAGING_SUBSCRIPTION_STATUS_DATE',
  MOBILE_APP_DOWNLOADED = 'MOBILE_APP_DOWNLOADED',
  MOBILE_APP_DOWNLOADED_DATE = 'MOBILE_APP_DOWNLOADED_DATE',
  NUMBER_OF_ACTIVE_INTEGRATIONS = 'NUMBER_OF_ACTIVE_INTEGRATIONS',
  NUMBER_OF_CARDS_ON_FILE = 'NUMBER_OF_CARDS_ON_FILE',
  NUMBER_OF_CONTACTS_SKIPTRACED = 'NUMBER_OF_CONTACTS_SKIPTRACED',
  NUMBER_OF_MESSAGES_RECEIVED = 'NUMBER_OF_MESSAGES_RECEIVED',
  NUMBER_OF_MESSAGES_SENT = 'NUMBER_OF_MESSAGES_SENT',
  NUMBER_OF_RVM_SENT = 'NUMBER_OF_RVM_SENT',
  PERSONA = 'PERSONA',
  PHONE = 'PHONE',
  PHONE_NUMBER_REGISTRATION_STATUS = 'PHONE_NUMBER_REGISTRATION_STATUS',
  PLAN = 'PLAN',
  PLAN_AMOUNT = 'PLAN_AMOUNT',
  PLAN_INTERVAL = 'PLAN_INTERVAL',
  PLAN_NAME = 'PLAN_NAME',
  PREVIOUS_SMS_PROVIDER = 'PREVIOUS_SMS_PROVIDER',
  RATING_REVIEW = 'RATING_REVIEW',
  REAL_ESTATE = 'REAL_ESTATE',
  RECHARGED_ACCOUNT = 'RECHARGED_ACCOUNT',
  RECHARGED_ACCOUNT_AMOUNT = 'RECHARGED_ACCOUNT_AMOUNT',
  RECHARGED_ACCOUNT_DATE = 'RECHARGED_ACCOUNT_DATE',
  REMOVED_CARD = 'REMOVED_CARD',
  REMOVED_CARD_DATE = 'REMOVED_CARD_DATE',
  STARTED_SIGN_UP = 'STARTED_SIGN_UP',
  STARTED_SIGN_UP_DATE = 'STARTED_SIGN_UP_DATE',
  STARTED_SUBSCRIPTION = 'STARTED_SUBSCRIPTION',
  STARTED_SUBSCRIPTION_DATE = 'STARTED_SUBSCRIPTION_DATE',
  STARTED_TRIAL = 'STARTED_TRIAL',
  STARTED_TRIAL_DATE = 'STARTED_TRIAL_DATE',
  TIMEZONE = 'TIMEZONE',
  TOTAL_BILLING_TO_DATE = 'TOTAL_BILLING_TO_DATE',
  TOTAL_MEMBERSHIP_FEES = 'TOTAL_MEMBERSHIP_FEES',
  TOTAL_SKIPTRACE_USAGE = 'TOTAL_SKIPTRACE_USAGE',
  TOTAL_SMS_USAGE = 'TOTAL_SMS_USAGE',
  TRIAL_STARTED_AT = 'TRIAL_STARTED_AT',
  TYPE = 'TYPE',
  UNSUBSCRIBE_REASON = 'UNSUBSCRIBE_REASON',
  UPDATED_FROM = 'UPDATED_FROM',
  UPDATED_FROM_DATE = 'UPDATED_FROM_DATE',
  UPGRADED_PLAN = 'UPGRADED_PLAN',
  UPGRADED_PLAN_DATE = 'UPGRADED_PLAN_DATE',
}

/** ImpersonateToken */
export type ImpersonateToken = {
  __typename?: 'ImpersonateToken';
  token: Scalars['String']['output'];
};

/** Inactive account summary */
export type InactiveAccountSummary = {
  __typename?: 'InactiveAccountSummary';
  total?: Maybe<Scalars['Int']['output']>;
};

/** Configuration values for Infobip. */
export type InfobipConfigView = {
  __typename?: 'InfobipConfigView';
  applicationId: Scalars['String']['output'];
  callsConfigId: Scalars['String']['output'];
};

/** Keyword template */
export type KeywordTemplate = {
  __typename?: 'KeywordTemplate';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  keyword: Scalars['String']['output'];
  template: KeywordTemplateTemplate;
};

export type KeywordTemplateList = {
  __typename?: 'KeywordTemplateList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<KeywordTemplate>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Keyword template template */
export type KeywordTemplateTemplate = {
  __typename?: 'KeywordTemplateTemplate';
  characters?: Maybe<Scalars['Float']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  optOut?: Maybe<Scalars['Boolean']['output']>;
  segments?: Maybe<Scalars['Float']['output']>;
};

export type KeywordTemplateTemplateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  optOut?: InputMaybe<Scalars['Boolean']['input']>;
};

export type KeywordTemplatesFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

/** Label */
export type Label = {
  __typename?: 'Label';
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  default: Scalars['Boolean']['output'];
  firestoreId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  readOnly: Scalars['Boolean']['output'];
  scopes: Array<Scope>;
  title: Scalars['String']['output'];
};

export type LabelFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  scopes?: InputMaybe<Array<Scope>>;
};

/** List of labels */
export type LabelList = {
  __typename?: 'LabelList';
  items: Array<Label>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Label report */
export type LabelReportView = {
  __typename?: 'LabelReportView';
  allLabeledContacts: Scalars['Float']['output'];
  contactsWithCurrentLabel: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** Last billing */
export type LastBilling = {
  __typename?: 'LastBilling';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
};

/** Last balance top up */
export type LastTopUp = {
  __typename?: 'LastTopUp';
  amount: Scalars['Money']['output'];
  bonus?: Maybe<Scalars['Money']['output']>;
  topUpAt: Scalars['DateTime']['output'];
};

/** Information about the line */
export type LineView = {
  __typename?: 'LineView';
  amdResult?: Maybe<AmdResult>;
  callId?: Maybe<Scalars['String']['output']>;
  contact: Contact;
  id: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  state: CallState;
  stateReason?: Maybe<Scalars['String']['output']>;
};

export type ListItemList = {
  __typename?: 'ListItemList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<ListItemView>;
  nextRowIndex?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** ListItem */
export type ListItemView = {
  __typename?: 'ListItemView';
  data: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  rowIndex: Scalars['Float']['output'];
};

export type ListItemsPagination = {
  /** Number of items returned in query. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Row index after which to fetch records */
  nextRowIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type ListOptions = {
  __typename?: 'ListOptions';
  removeCarrierBlocked: Scalars['Boolean']['output'];
  removeExisting: Scalars['Boolean']['output'];
  removeFiltered: Scalars['Boolean']['output'];
  removeGlobalUnsubscribed: Scalars['Boolean']['output'];
  removeInternalDnc: Scalars['Boolean']['output'];
  removeLitigators: Scalars['Boolean']['output'];
  removeNationalDnc: Scalars['Boolean']['output'];
  removeNonSmsCapable: Scalars['Boolean']['output'];
  removeUndeliverable: Scalars['Boolean']['output'];
  removeUnsubscribed: Scalars['Boolean']['output'];
};

/** List type */
export enum ListType {
  GROUP = 'GROUP',
  SKIPTRACE = 'SKIPTRACE',
}

/** List upload completed */
export type ListUploadCompleted = {
  __typename?: 'ListUploadCompleted';
  listId: Scalars['String']['output'];
};

/** List upload progress */
export type ListUploadProgress = {
  __typename?: 'ListUploadProgress';
  listId: Scalars['String']['output'];
  progress: Scalars['Float']['output'];
};

/** List verification progress */
export type ListVerificationProgress = {
  __typename?: 'ListVerificationProgress';
  listId: Scalars['String']['output'];
  progress: Scalars['Float']['output'];
};

/** List verification result */
export type ListVerificationResult = {
  __typename?: 'ListVerificationResult';
  carrierBlocked: Scalars['Int']['output'];
  existing: Scalars['Int']['output'];
  filtered: Scalars['Int']['output'];
  globalUnsubscribed: Scalars['Int']['output'];
  internalDnc: Scalars['Int']['output'];
  listId: Scalars['String']['output'];
  litigators: Scalars['Int']['output'];
  nationalDnc: Scalars['Int']['output'];
  nonSmsCapable: Scalars['Int']['output'];
  totalPhones: Scalars['Int']['output'];
  undeliverable: Scalars['Int']['output'];
  unsubscribed: Scalars['Int']['output'];
  verified: Scalars['Boolean']['output'];
  verifiedPhones: Scalars['Int']['output'];
};

/** List */
export type ListView = {
  __typename?: 'ListView';
  fields?: Maybe<Array<Field>>;
  id: Scalars['String']['output'];
  options?: Maybe<ListOptions>;
  type: ListType;
};

/** Macro */
export type Macro = {
  __typename?: 'Macro';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** List of macros */
export type MacroList = {
  __typename?: 'MacroList';
  items: Array<Macro>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type MacrosFilter = {
  /** Search by macro name and content with merge tags */
  fullSearch?: InputMaybe<Scalars['String']['input']>;
  /** Search by macro name and content without merge tags */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ManageCallRecordingInput = {
  action: CallRecordingAction;
  id: Scalars['String']['input'];
};

export type ManagePowerDialerCallRecordingInput = {
  action: CallRecordingAction;
  campaignId: Scalars['String']['input'];
};

export type MarkAccountPopupViewedInput = {
  popupId: Scalars['String']['input'];
};

export type MarkConversationsReadInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isRead: Scalars['Boolean']['input'];
  profileId?: InputMaybe<Scalars['String']['input']>;
};

export type MarkNotificationsReadInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MarkNotificationsSeenInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Marketing popup */
export type MarketingPopup = {
  __typename?: 'MarketingPopup';
  audiences?: Maybe<Array<MarketingPopupAudience>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status: MarketingPopupStatus;
  template?: Maybe<MarketingPopupTemplate>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Marketing popup audience */
export enum MarketingPopupAudience {
  ACTIVE_DIALER = 'ACTIVE_DIALER',
  ACTIVE_MESSAGING = 'ACTIVE_MESSAGING',
  AFFILIATES = 'AFFILIATES',
  ALL = 'ALL',
  CANCELLED_DIALER = 'CANCELLED_DIALER',
  CANCELLED_MESSAGING = 'CANCELLED_MESSAGING',
  INCOMPLETE_MESSAGING = 'INCOMPLETE_MESSAGING',
  PAST_DUE_DIALER = 'PAST_DUE_DIALER',
  PAST_DUE_MESSAGING = 'PAST_DUE_MESSAGING',
  PENDING_CANCELLATION_DIALER = 'PENDING_CANCELLATION_DIALER',
  PENDING_CANCELLATION_MESSAGING = 'PENDING_CANCELLATION_MESSAGING',
  SKIPTRACE_USED = 'SKIPTRACE_USED',
  TEST = 'TEST',
  TRIAL_MESSAGING = 'TRIAL_MESSAGING',
}

export type MarketingPopupList = {
  __typename?: 'MarketingPopupList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<MarketingPopup>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Marketing popup status */
export enum MarketingPopupStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

/** Marketing popup template */
export type MarketingPopupTemplate = {
  __typename?: 'MarketingPopupTemplate';
  buttonLink?: Maybe<Scalars['String']['output']>;
  buttonText?: Maybe<Scalars['String']['output']>;
  imageSrc?: Maybe<Scalars['String']['output']>;
  type: MarketingPopupTemplateType;
  videoSrc?: Maybe<Scalars['String']['output']>;
};

export type MarketingPopupTemplateInput = {
  buttonLink?: InputMaybe<Scalars['String']['input']>;
  buttonText?: InputMaybe<Scalars['String']['input']>;
  imagePath?: InputMaybe<Scalars['String']['input']>;
  type: MarketingPopupTemplateType;
  videoSrc?: InputMaybe<Scalars['String']['input']>;
};

/** Marketing popup template type */
export enum MarketingPopupTemplateType {
  IMAGE = 'IMAGE',
  IMAGE_WITH_BUTTON = 'IMAGE_WITH_BUTTON',
  VIDEO = 'VIDEO',
  VIDEO_WITH_BUTTON = 'VIDEO_WITH_BUTTON',
}

/** Merge tag */
export type MergeTag = {
  __typename?: 'MergeTag';
  id: Scalars['String']['output'];
  key?: Maybe<PredefinedField>;
  mergeTag: Scalars['String']['output'];
  name: Scalars['String']['output'];
  predefined: Scalars['Boolean']['output'];
};

/** Message */
export type Message = {
  __typename?: 'Message';
  attachments?: Maybe<Array<MessageAttachment>>;
  campaign?: Maybe<Campaign>;
  content?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<MessageContentType>;
  conversationId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dialerCampaign?: Maybe<DialerCampaign>;
  direction: MessageDirection;
  duration?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<MessageError>;
  fromNumber?: Maybe<PhoneNumber>;
  id: Scalars['String']['output'];
  isConversation?: Maybe<Scalars['Boolean']['output']>;
  isManualRecording?: Maybe<Scalars['Boolean']['output']>;
  keyword?: Maybe<KeywordTemplate>;
  profileId: Scalars['String']['output'];
  status: MessageStatus;
  statusReason?: Maybe<MessageStateReasonType>;
  template?: Maybe<MessageTemplate>;
  toNumber?: Maybe<Scalars['String']['output']>;
  type: MessageType;
};

/** Message attachment */
export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  bucket: Scalars['String']['output'];
  contentType?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type MessageAttachmentInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  path: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Message content type */
export enum MessageContentType {
  INCOMING_CALL = 'INCOMING_CALL',
  MEDIA_AUDIO = 'MEDIA_AUDIO',
  MEDIA_IMAGE = 'MEDIA_IMAGE',
  MISSED_CALL = 'MISSED_CALL',
  OUTGOING_CALL = 'OUTGOING_CALL',
  TEXT = 'TEXT',
  UNSUPPORTED_FILE = 'UNSUPPORTED_FILE',
  VOICE = 'VOICE',
  VOICEMAIL = 'VOICEMAIL',
}

/** Message direction */
export enum MessageDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

/** Message error */
export type MessageError = {
  __typename?: 'MessageError';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type MessageFilter = {
  profileId?: InputMaybe<Scalars['String']['input']>;
};

export type MessageList = {
  __typename?: 'MessageList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Message>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type MessageOrder = {
  by?: InputMaybe<MessageOrderBy>;
  direction?: InputMaybe<OrderQuery>;
};

/** Message order by */
export enum MessageOrderBy {
  CREATED_AT = 'CREATED_AT',
}

/** Message report view */
export type MessageReport = {
  __typename?: 'MessageReport';
  campaignAiFilteredMessages: Scalars['Float']['output'];
  campaignIncomingOptedOut: Scalars['Float']['output'];
  campaignOutgoingBlocked: Scalars['Float']['output'];
  campaignOutgoingDelivered: Scalars['Float']['output'];
  campaignOutgoingTotal: Scalars['Float']['output'];
  incomingTotal: Scalars['Float']['output'];
  /** Represents a median response time in this format: 1min 15sec, 1d 3h */
  medianResponseTime: Scalars['String']['output'];
  outgoingTotal: Scalars['Float']['output'];
};

/** Message state reason */
export enum MessageStateReasonType {
  AI_FILTERED = 'AI_FILTERED',
  CARRIER_BLOCKED = 'CARRIER_BLOCKED',
  DAILY_LIMIT_REACHED = 'DAILY_LIMIT_REACHED',
  FLOODING = 'FLOODING',
  LIMIT_REACHED = 'LIMIT_REACHED',
  OPTED_OUT = 'OPTED_OUT',
  PROVIDER_DNC = 'PROVIDER_DNC',
  RECEIVER_NON_SMS_CAPABLE = 'RECEIVER_NON_SMS_CAPABLE',
}

/** Message status */
export enum MessageStatus {
  CALL_FINISHED = 'CALL_FINISHED',
  CALL_MISSED = 'CALL_MISSED',
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  SENT = 'SENT',
  UNDELIVERED = 'UNDELIVERED',
}

/** Message template */
export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  characters?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  firestoreId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  segments?: Maybe<Scalars['Float']['output']>;
  template: MessageTemplateTemplate;
  type: MessageTemplateType;
};

export type MessageTemplateList = {
  __typename?: 'MessageTemplateList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<MessageTemplate>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Message Template message type */
export enum MessageTemplateMessageType {
  FOLLOWUP = 'FOLLOWUP',
  INITIAL = 'INITIAL',
}

/** Message template template */
export type MessageTemplateTemplate = {
  __typename?: 'MessageTemplateTemplate';
  audioFileBucket?: Maybe<Scalars['String']['output']>;
  audioFilePath?: Maybe<Scalars['String']['output']>;
  audioFileUrl?: Maybe<Scalars['String']['output']>;
  characters?: Maybe<Scalars['Float']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  messageType?: Maybe<MessageTemplateMessageType>;
  optOut?: Maybe<Scalars['Boolean']['output']>;
  segments?: Maybe<Scalars['Float']['output']>;
};

export type MessageTemplateTemplateInput = {
  audioFileBucket?: InputMaybe<Scalars['String']['input']>;
  audioFilePath?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  messageType?: InputMaybe<MessageTemplateMessageType>;
  optOut?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Message Template type */
export enum MessageTemplateType {
  KEYWORD = 'KEYWORD',
  RVM = 'RVM',
  SMS = 'SMS',
}

export type MessageTemplatesFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MessageTemplateType>;
};

/** Message type */
export enum MessageType {
  CALL = 'CALL',
  MMS = 'MMS',
  RVM = 'RVM',
  SMS = 'SMS',
}

/** Messaging profile */
export type MessagingProfile = {
  __typename?: 'MessagingProfile';
  a2pInfo?: Maybe<A2PInfo>;
  allowUrl: Scalars['Boolean']['output'];
  callSettings?: Maybe<CallSettings>;
  id: Scalars['String']['output'];
  isEINDocRequired: Scalars['Boolean']['output'];
  phoneSettings?: Maybe<PhoneSettings>;
  provider?: Maybe<MessagingProvider>;
  responseRemoval?: Maybe<ResponseRemoval>;
  tollFreeInfo?: Maybe<TollFreeInfo>;
};

/** Messaging provider */
export type MessagingProvider = {
  __typename?: 'MessagingProvider';
  sipPassword?: Maybe<Scalars['String']['output']>;
  sipUsername?: Maybe<Scalars['String']['output']>;
  type: MessagingProviderType;
};

/** Single provider statistic */
export type MessagingProviderCounters = {
  __typename?: 'MessagingProviderCounters';
  approved?: Maybe<Scalars['Int']['output']>;
  declined?: Maybe<Scalars['Int']['output']>;
  deliveryRate?: Maybe<Scalars['Float']['output']>;
  pending?: Maybe<Scalars['Int']['output']>;
  weeklySmsUsage?: Maybe<Scalars['Int']['output']>;
};

/** Messaging provider type */
export enum MessagingProviderType {
  COMMIO = 'COMMIO',
  INFOBIP = 'INFOBIP',
  PLIVO = 'PLIVO',
  TELNYX = 'TELNYX',
  TWILIO = 'TWILIO',
}

/** Total provider statistics */
export type MessagingProvidersCounters = {
  __typename?: 'MessagingProvidersCounters';
  commio: MessagingProviderCounters;
  infobip: MessagingProviderCounters;
  plivo: MessagingProviderCounters;
  telnyx: MessagingProviderCounters;
  twilio: MessagingProviderCounters;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Mark latest Terms and Conditions as accepted */
  acceptTermsAndConditions: OperationStatus;
  /** Add label to the contact */
  addContactLabel: OperationStatus;
  /** Add contact to DNC List */
  addContactToDNC: Suppression;
  /** Add group to contacts */
  addGroupToContacts: OperationStatus;
  /** Adds outcome labels to a particular call and the contact */
  addLabelToCallAndContact: OperationStatus;
  /** Add labels to contacts */
  addLabelsToContacts: Array<Contact>;
  /** Add payment source */
  addPaymentSource: OperationStatus;
  /** Add own phone to account by admin */
  adminAddOwnPhone: OperationStatus;
  /** Update account and subaccounts messaging provider */
  adminChangeAccountMessagingProvider: OperationStatus;
  /** Update sub account messaging provider to parent messaging provider */
  adminChangeSubAccountMessagingProvider: OperationStatus;
  /** An admin is deleting a phone number from another account. Generally used in the "user setup" panel */
  adminDeletePhone: Account;
  /** Admin migrate accounts features */
  adminMigrateAccountsFeatures: OperationStatus;
  /** Remove account and all related data */
  adminRemoveAccount: OperationStatus;
  /** Remove own phone by admin */
  adminRemoveOwnPhone: OperationStatus;
  /** Resync whole list of local phones with 10DLC campaign */
  adminResyncLocalOwnPhones: OperationStatus;
  /** Admin schedule check stucked campaigns tasks */
  adminScheduleCheckStuckedCampaigns: OperationStatus;
  /** Admin sync `Not interested 📞` labels */
  adminSyncNotInterestedLabels: OperationStatus;
  /** Top Up account balance by admin */
  adminTopUpBalance: OperationStatus;
  /** An admin account is updating another account. Generally used in the "user setup" panel */
  adminUpdateAccount: Account;
  /** Admin update account password */
  adminUpdatePassword: AccountPassword;
  /** Admin setup upsert campaigns progress tasks */
  adminUpsertCampaignProgress: OperationStatus;
  /** Manually verify caller id for bring your own carrier feature */
  adminVerifyCallerIdForBYOC: OperationStatus;
  /** Apply discount to subscription */
  applyDiscount: Discount;
  /** Add contacts to DNC by criteria */
  blockContacts: OperationStatus;
  /** Cancel scheduled messaging payments for inactive users */
  cancelScheduleMessagingPaymentInactiveUser: OperationStatus;
  confirmPasswordReset: OperationStatus;
  /** Create a remove account request */
  createAccountRemoveRequest: OperationStatus;
  /** Create new calendar event */
  createCalendarEvent: CalendarEvent;
  /** Create new call script */
  createCallScript: CallScript;
  /** Create new campaign */
  createCampaign: Campaign;
  /** Create new contact */
  createContact: Contact;
  /** Create new contact note */
  createContactNote: ContactNote;
  /** Create conversation */
  createConversation: Conversation;
  /** Create CRM integration */
  createCrmIntegration: CrmIntegration;
  /** Create or update existing phone to add it to DNC */
  createDNCPhone: Suppression;
  /** Create new dialer campaign */
  createDialerCampaign: DialerCampaign;
  /** Create feedback */
  createFeedback: OperationStatus;
  /** Create new field */
  createField: Field;
  /** Create new fields template */
  createFieldsTemplate: FieldsTemplate;
  /** Create group */
  createGroup: GroupView;
  /** Create impersonate token for account */
  createImpersonateToken: ImpersonateToken;
  /** Create new keyword template */
  createKeywordTemplate: KeywordTemplate;
  /** Create new label */
  createLabel: Label;
  /** Upload list by csv file */
  createList: ListView;
  /** Create new macro */
  createMacro: Macro;
  /** Create new marketing popup */
  createMarketingPopup: MarketingPopup;
  /** Create new message template */
  createMessageTemplate: MessageTemplate;
  /** Create restricted phone and remove it from all contacts */
  createRestrictedPhone: OperationStatus;
  /** Create a scheduled message */
  createScheduledMessage: ScheduledMessage;
  /** Create subAccount */
  createSubaccount: Account;
  /** Duplicate group */
  duplicateGroups: GroupView;
  /** End subscription trial period */
  endTrial: CurrentSubscription;
  /** Export dialer campaign results to the new or existing group */
  exportDialerCampaignToGroup: GroupView;
  /** Hang up a power dialer batch */
  hangUpPowerDialerCall: OperationStatus;
  /** Manage call recording */
  manageCallRecording: OperationStatus;
  /** Manage call recording */
  managePowerDialerCallRecording: OperationStatus;
  /** Mark account popup viewed */
  markAccountPopupViewed: AccountPopup;
  /** Mark conversations as read or unread */
  markConversationsRead: OperationStatus;
  /** Mark notifications as read or unread */
  markNotificationsRead: OperationStatus;
  /** Mark notifications as seen or unseen */
  markNotificationsSeen: OperationStatus;
  /** Pause campaign */
  pauseCampaign: OperationStatus;
  /** Pause dialer campaign */
  pauseDialerCampaign: OperationStatus;
  /** Pay bill manually */
  payBill: OperationStatus;
  /** Buy new phone */
  purchaseOwnPhone: OwnPhone;
  /** Push skiptrace to group */
  pushSkiptraceToGroup: ListView;
  /** Rank call script */
  rankCallScript: OperationStatus;
  /** Rank keyword template */
  rankKeywordTemplate: OperationStatus;
  /** Rank label */
  rankLabel: RankResult;
  /** Rank macro */
  rankMacro: OperationStatus;
  /** Rank message template */
  rankMessageTemplate: OperationStatus;
  /** Reactivate A2P registration */
  reactivateA2P: OperationStatus;
  /** Recover contacts */
  recoverContacts: OperationStatus;
  /** Resume campaign */
  refreshCampaignMessages: OperationStatus;
  /** Register A2P brand */
  registerA2P: OperationStatus;
  /** Register new affiliate */
  registerAffiliate: Affiliate;
  /** Admin set 'Referred By affiliate' on account */
  registerAffiliateLead: Affiliate;
  /** Register A2P campaign for subaccount */
  registerSubAccountA2P: OperationStatus;
  /** Register toll-free brand */
  registerTollFree: OperationStatus;
  /** Remove affiliate */
  removeAffiliate: OperationStatus;
  /** Remove calendar event and all related data */
  removeCalendarEvent: OperationStatus;
  /** Remove call script and all related data */
  removeCallScript: OperationStatus;
  /** Remove campaign and all related data */
  removeCampaign: OperationStatus;
  /** Remove contact from DNC List */
  removeContactFromDNCSuppressions: OperationStatus;
  /** Remove label from the contact */
  removeContactLabel: OperationStatus;
  /** Remove contact note */
  removeContactNote: OperationStatus;
  /** Remove contacts */
  removeContacts: OperationStatus;
  /** Remove CRM integration */
  removeCrmIntegration: OperationStatus;
  /** Remove dialer campaign and all related data */
  removeDialerCampaign: OperationStatus;
  /** Remove field and all related data */
  removeField: OperationStatus;
  /** Remove fields template and all related data */
  removeFieldsTemplate: OperationStatus;
  /** Remove group from contacts */
  removeGroupFromContacts: OperationStatus;
  /** Remove groups */
  removeGroups: OperationStatus;
  /** Remove keyword template and all related data */
  removeKeywordTemplate: OperationStatus;
  /** Remove label */
  removeLabel: OperationStatus;
  /** Remove labels from contacts */
  removeLabelsFromContacts: Array<Contact>;
  /** Remove list item */
  removeListItem: OperationStatus;
  /** Remove macro and all related data */
  removeMacro: OperationStatus;
  /** Remove marketing popup */
  removeMarketingPopup: OperationStatus;
  /** Remove message template and all related data */
  removeMessageTemplate: OperationStatus;
  /** Remove own phone */
  removeOwnPhone: OperationStatus;
  /** Remove payment source */
  removePaymentSource: OperationStatus;
  /** Remove a scheduled message */
  removeScheduledMessage: OperationStatus;
  /** Remove skiptraces */
  removeSkiptraces: OperationStatus;
  /** Remove subAccount */
  removeSubaccount: OperationStatus;
  /** Remove suppressions by source or by ids */
  removeSuppressions: OperationStatus;
  resendPasswordResetToken: OperationStatus;
  /** Resume campaign */
  resumeCampaign: OperationStatus;
  /** Resume dialer campaign */
  resumeDialerCampaign: OperationStatus;
  /** Run skiptrace */
  runSkiptrace: OperationStatus;
  /** Schedule balance */
  scheduleBalance: OperationStatus;
  /** Admin schedule check months of membership daily task */
  scheduleCheckMonthsOfMembershipTask: OperationStatus;
  /** Admin schedule daily sync messaging usage to HubSpot task */
  scheduleDailySyncMessagingUsageTask: OperationStatus;
  /** Admin schedule delete old notifications weekly task */
  scheduleDeleteOldNotificationsTask: OperationStatus;
  /** Scheduling expiring phone verifications */
  scheduleExpirePhoneVerifications: OperationStatus;
  /** Scheduling marking landline phones */
  scheduleMarkLandlinePhones: OperationStatus;
  /** Scheduling migrating old monthly subscriptions to new quarterly plans */
  scheduleMigratingSubscriptionsToQuarterlyPlans: OperationStatus;
  /** Schedules a monthly job to run and deliver reports */
  scheduleMonthlyReports: OperationStatus;
  /** Scheduling payments for own_phones */
  scheduleOwnPhonePayments: OperationStatus;
  /** Admin schedule send email exhausted phones weekly task */
  scheduleSendEmailExhaustedPhonesTask: OperationStatus;
  /** Scheduling weekly statistics job */
  scheduleWeeklyStatistics: OperationStatus;
  /** Send message */
  sendMessage: Message;
  /** Set default payment source */
  setDefaultPaymentSource: OperationStatus;
  /** Admin setup messaging quota usage reset tasks */
  setupMessagingQuotaUsageResetTasks: OperationStatus;
  /** Create account */
  signUp: Account;
  /** Start campaign */
  startCampaign: OperationStatus;
  /** Start dialer campaign */
  startDialerCampaign: OperationStatus;
  /** Indicates to the BE that the user wants to start dialing */
  startPowerDialing: OperationStatus;
  /** Submit A2P reactivation */
  submitA2PReactivation: OperationStatus;
  /** Submit 10DLC brand registration for parent account */
  submitA2PRegistration: OperationStatus;
  /** Submit 10DLC brand registration for subaccount */
  submitA2PSubAccountRegistration: OperationStatus;
  /** Submit toll-free brand registration */
  submitTollFreeRegistration: OperationStatus;
  /** Subscribe to plan */
  subscribe: CurrentSubscription;
  /** Switch dialer offline */
  switchDialerOffline: OperationStatus;
  /** Switch dialer online */
  switchDialerOnline: OperationStatus;
  /** Sync hubspot profiles */
  syncHubspotProfiles: OperationStatus;
  /** Top Up balance */
  topUpBalance: OperationStatus;
  /** Unsubscribe to plan */
  unsubscribe: OperationStatus;
  /** Update A2P registration external data */
  updateA2PRegistrationExternalData: OperationStatus;
  /** Update A2P registration info */
  updateA2PRegistrationInfo: A2PRegistration;
  /** Update account info */
  updateAccount: Account;
  /** Update account phone number in Auth DB and in Postgres */
  updateAccountPhoneNumber: OperationStatus;
  /** Update account subscription */
  updateAccountSubscription: CurrentSubscription;
  /** Update backup payment method */
  updateBillingProfile: OperationStatus;
  /** Update calendar event */
  updateCalendarEvent: CalendarEvent;
  /** Update call script */
  updateCallScript: CallScript;
  /** Update new campaign */
  updateCampaign: Campaign;
  /** Update campaign dynamic data */
  updateCampaignDynamicData: OperationStatus;
  /** Update campaign own phones */
  updateCampaignOwnPhones: OperationStatus;
  /** Update contact */
  updateContact: Contact;
  /** Update contact note */
  updateContactNote: ContactNote;
  /** Update conversation outgoing phone */
  updateConversationOutgoingPhone: OperationStatus;
  /** Update CRM integration */
  updateCrmIntegration: CrmIntegration;
  /** Update device */
  updateDevice: OperationStatus;
  /** Update dialer campaign */
  updateDialerCampaign: DialerCampaign;
  /** Update field */
  updateField: Field;
  /** Update fields template */
  updateFieldsTemplate: FieldsTemplate;
  /** Update group */
  updateGroup: GroupView;
  /** Update keyword template */
  updateKeywordTemplate: KeywordTemplate;
  /** Update label */
  updateLabel: Label;
  /** Update list */
  updateList: ListView;
  /** Update list item */
  updateListItem: OperationStatus;
  /** Update macro */
  updateMacro: Macro;
  /** Update marketing popup */
  updateMarketingPopup: MarketingPopup;
  /** Update message template */
  updateMessageTemplate: MessageTemplate;
  /** Update messaging profile */
  updateMessagingProfile: MessagingProfile;
  /** Update notification settings */
  updateNotificationSettings: OperationStatus;
  /** Update a scheduled message */
  updateScheduledMessage: ScheduledMessage;
  /** Update skiptrace */
  updateSkiptrace: Skiptrace;
  /** Update subAccount */
  updateSubaccount: Account;
  /** Update subscription */
  updateSubscription: CurrentSubscription;
  /** Update A2P registration info */
  updateTollFreeRegistrationInfo: TollFreeRegistration;
  /** Upload list of phone numbers to add them to DNC by csv file */
  uploadDNCPhones: OperationStatus;
  /** Upload EIN file */
  uploadEINFile: OperationStatus;
  /** Verify list according to selected fields */
  verifyList: OperationStatus;
  /** Verify promo code */
  verifyPromoCode: PromoCode;
};

export type MutationAddContactLabelArgs = {
  contactId: Scalars['String']['input'];
  labelId: Scalars['String']['input'];
};

export type MutationAddContactToDncArgs = {
  input: AddContactToDncInput;
};

export type MutationAddGroupToContactsArgs = {
  input: AddGroupToContactsInput;
};

export type MutationAddLabelToCallAndContactArgs = {
  input: AddLabelToCallAndContactInput;
};

export type MutationAddLabelsToContactsArgs = {
  input: AddLabelsToContactsInput;
};

export type MutationAddPaymentSourceArgs = {
  input: PaymentSourceInput;
};

export type MutationAdminAddOwnPhoneArgs = {
  input: AdminAddOwnPhoneInput;
};

export type MutationAdminChangeAccountMessagingProviderArgs = {
  input: AdminChangeAccountMessagingProviderInput;
};

export type MutationAdminChangeSubAccountMessagingProviderArgs = {
  input: AdminChangeSubAccountMessagingProviderInput;
};

export type MutationAdminDeletePhoneArgs = {
  id: Scalars['String']['input'];
};

export type MutationAdminMigrateAccountsFeaturesArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type MutationAdminRemoveAccountArgs = {
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationAdminRemoveOwnPhoneArgs = {
  input: AdminRemoveOwnPhoneInput;
};

export type MutationAdminResyncLocalOwnPhonesArgs = {
  profileId: Scalars['String']['input'];
  scope?: InputMaybe<Scope>;
};

export type MutationAdminTopUpBalanceArgs = {
  input: TopUpAccountBalanceInput;
};

export type MutationAdminUpdateAccountArgs = {
  data: AdminUpdateAccountInput;
  id: Scalars['String']['input'];
};

export type MutationAdminUpdatePasswordArgs = {
  id: Scalars['String']['input'];
};

export type MutationAdminUpsertCampaignProgressArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAdminVerifyCallerIdForByocArgs = {
  phone: Scalars['PhoneNumberType']['input'];
};

export type MutationApplyDiscountArgs = {
  input: ApplyDiscountInput;
  subscriptionId: Scalars['String']['input'];
};

export type MutationBlockContactsArgs = {
  input: BlockContactsInput;
};

export type MutationCancelScheduleMessagingPaymentInactiveUserArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationConfirmPasswordResetArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationCreateCalendarEventArgs = {
  input: CreateCalendarEventInput;
};

export type MutationCreateCallScriptArgs = {
  input: CreateCallScriptInput;
};

export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};

export type MutationCreateContactArgs = {
  input: CreateContactInput;
};

export type MutationCreateContactNoteArgs = {
  contactId: Scalars['String']['input'];
  input: CreateContactNoteInput;
};

export type MutationCreateConversationArgs = {
  input: CreateConversationInput;
};

export type MutationCreateCrmIntegrationArgs = {
  input: CreateCrmIntegrationInput;
};

export type MutationCreateDncPhoneArgs = {
  input: CreateDncPhoneInput;
};

export type MutationCreateDialerCampaignArgs = {
  input: CreateDialerCampaignInput;
};

export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};

export type MutationCreateFieldArgs = {
  input: CreateFieldInput;
};

export type MutationCreateFieldsTemplateArgs = {
  input: CreateFieldsTemplateInput;
};

export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};

export type MutationCreateImpersonateTokenArgs = {
  id: Scalars['String']['input'];
};

export type MutationCreateKeywordTemplateArgs = {
  input: CreateKeywordTemplateInput;
};

export type MutationCreateLabelArgs = {
  input: CreateLabelInput;
};

export type MutationCreateListArgs = {
  input: CreateListInput;
};

export type MutationCreateMacroArgs = {
  input: CreateMacroInput;
};

export type MutationCreateMarketingPopupArgs = {
  input: CreateMarketingPopupInput;
};

export type MutationCreateMessageTemplateArgs = {
  input: CreateMessageTemplateInput;
};

export type MutationCreateRestrictedPhoneArgs = {
  input: CreateRestrictedPhoneInput;
};

export type MutationCreateScheduledMessageArgs = {
  input: CreateScheduledMessageInput;
};

export type MutationCreateSubaccountArgs = {
  input: CreateSubaccountInput;
};

export type MutationDuplicateGroupsArgs = {
  id: Scalars['String']['input'];
};

export type MutationEndTrialArgs = {
  input?: InputMaybe<EndTrialInput>;
  subscriptionId: Scalars['String']['input'];
};

export type MutationExportDialerCampaignToGroupArgs = {
  id: Scalars['String']['input'];
  input: ExportDialerCampaignToGroupInput;
};

export type MutationHangUpPowerDialerCallArgs = {
  batchId: Scalars['String']['input'];
};

export type MutationManageCallRecordingArgs = {
  input: ManageCallRecordingInput;
};

export type MutationManagePowerDialerCallRecordingArgs = {
  input: ManagePowerDialerCallRecordingInput;
};

export type MutationMarkAccountPopupViewedArgs = {
  input: MarkAccountPopupViewedInput;
};

export type MutationMarkConversationsReadArgs = {
  input: MarkConversationsReadInput;
};

export type MutationMarkNotificationsReadArgs = {
  input: MarkNotificationsReadInput;
};

export type MutationMarkNotificationsSeenArgs = {
  input: MarkNotificationsSeenInput;
};

export type MutationPauseCampaignArgs = {
  id: Scalars['String']['input'];
};

export type MutationPauseDialerCampaignArgs = {
  id: Scalars['String']['input'];
};

export type MutationPayBillArgs = {
  input: PayBillInput;
};

export type MutationPurchaseOwnPhoneArgs = {
  input: PurchaseOwnPhoneInput;
};

export type MutationPushSkiptraceToGroupArgs = {
  input: PushSkiptraceToGroupInput;
};

export type MutationRankCallScriptArgs = {
  id: Scalars['String']['input'];
  input: RankCallScriptInput;
};

export type MutationRankKeywordTemplateArgs = {
  id: Scalars['String']['input'];
  input: RankKeywordTemplateInput;
};

export type MutationRankLabelArgs = {
  id: Scalars['String']['input'];
  input: RankLabelInput;
};

export type MutationRankMacroArgs = {
  id: Scalars['String']['input'];
  input: RankMacroInput;
};

export type MutationRankMessageTemplateArgs = {
  id: Scalars['String']['input'];
  input: RankMessageTemplateInput;
};

export type MutationReactivateA2PArgs = {
  accountId: Scalars['String']['input'];
};

export type MutationRecoverContactsArgs = {
  input: RecoverContactsInput;
};

export type MutationRefreshCampaignMessagesArgs = {
  id: Scalars['String']['input'];
};

export type MutationRegisterA2PArgs = {
  accountId: Scalars['String']['input'];
};

export type MutationRegisterAffiliateArgs = {
  input: RegisterAffiliateInput;
};

export type MutationRegisterAffiliateLeadArgs = {
  input: AdminRegisterAffiliateLeadInput;
};

export type MutationRegisterSubAccountA2PArgs = {
  accountId: Scalars['String']['input'];
};

export type MutationRegisterTollFreeArgs = {
  accountId: Scalars['String']['input'];
};

export type MutationRemoveAffiliateArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveCalendarEventArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveCallScriptArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveCampaignArgs = {
  input: RemoveCampaignInput;
};

export type MutationRemoveContactFromDncSuppressionsArgs = {
  input: RemoveContactFromDncSuppressionsInput;
};

export type MutationRemoveContactLabelArgs = {
  contactId: Scalars['String']['input'];
  labelId: Scalars['String']['input'];
};

export type MutationRemoveContactNoteArgs = {
  contactId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationRemoveContactsArgs = {
  input: RemoveContactsInput;
};

export type MutationRemoveCrmIntegrationArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveDialerCampaignArgs = {
  input: RemoveDialerCampaignInput;
};

export type MutationRemoveFieldArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveFieldsTemplateArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveGroupFromContactsArgs = {
  input: RemoveGroupFromContactsInput;
};

export type MutationRemoveGroupsArgs = {
  input: RemoveGroupsInput;
};

export type MutationRemoveKeywordTemplateArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveLabelArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveLabelsFromContactsArgs = {
  input: RemoveLabelsFromContactsInput;
};

export type MutationRemoveListItemArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveMacroArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveMarketingPopupArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveMessageTemplateArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveOwnPhoneArgs = {
  ownPhoneId: Scalars['String']['input'];
};

export type MutationRemovePaymentSourceArgs = {
  input: PaymentSourceInput;
};

export type MutationRemoveScheduledMessageArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveSkiptracesArgs = {
  input: RemoveSkiptracesInput;
};

export type MutationRemoveSubaccountArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveSuppressionsArgs = {
  input: RemoveSuppressionsInput;
};

export type MutationResumeCampaignArgs = {
  id: Scalars['String']['input'];
};

export type MutationResumeDialerCampaignArgs = {
  id: Scalars['String']['input'];
};

export type MutationRunSkiptraceArgs = {
  input: RunSkiptraceInput;
};

export type MutationScheduleBalanceArgs = {
  input: ScheduleBalanceInput;
};

export type MutationScheduleCheckMonthsOfMembershipTaskArgs = {
  input: ScheduleCheckMonthsOfMembershipTaskInput;
};

export type MutationScheduleMigratingSubscriptionsToQuarterlyPlansArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationScheduleOwnPhonePaymentsArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type MutationSendMessageArgs = {
  input: SendMessageInput;
};

export type MutationSetDefaultPaymentSourceArgs = {
  input: PaymentSourceInput;
};

export type MutationSetupMessagingQuotaUsageResetTasksArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type MutationStartCampaignArgs = {
  id: Scalars['String']['input'];
};

export type MutationStartDialerCampaignArgs = {
  id: Scalars['String']['input'];
};

export type MutationStartPowerDialingArgs = {
  input: StartPowerDialingInput;
};

export type MutationSubmitA2PRegistrationArgs = {
  input: SubmitA2PRegistrationInput;
};

export type MutationSubmitA2PSubAccountRegistrationArgs = {
  input: SubmitA2PSubAccountRegistrationInput;
};

export type MutationSubmitTollFreeRegistrationArgs = {
  input: TollFreeBrandInput;
};

export type MutationSubscribeArgs = {
  input: SubscribeInput;
};

export type MutationSwitchDialerOfflineArgs = {
  inPowerDialer: Scalars['Boolean']['input'];
};

export type MutationSwitchDialerOnlineArgs = {
  campaignId: Scalars['String']['input'];
};

export type MutationSyncHubspotProfilesArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<SyncHubspotProfilesInput>;
};

export type MutationTopUpBalanceArgs = {
  input: TopUpBalanceInput;
};

export type MutationUnsubscribeArgs = {
  reason?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['String']['input'];
};

export type MutationUpdateA2PRegistrationExternalDataArgs = {
  accountId: Scalars['String']['input'];
  input: UpdateA2PRegistrationExternalDataInput;
};

export type MutationUpdateA2PRegistrationInfoArgs = {
  accountId: Scalars['String']['input'];
  input: UpdateA2PRegistrationInfoInput;
};

export type MutationUpdateAccountArgs = {
  data: UpdateAccountInput;
};

export type MutationUpdateAccountPhoneNumberArgs = {
  accountId: Scalars['String']['input'];
  phone: Scalars['PhoneNumberType']['input'];
};

export type MutationUpdateAccountSubscriptionArgs = {
  accountId: Scalars['String']['input'];
  input: UpdateSubscriptionInput;
  subscriptionId: Scalars['String']['input'];
};

export type MutationUpdateBillingProfileArgs = {
  backupPaymentMethod: Scalars['Boolean']['input'];
};

export type MutationUpdateCalendarEventArgs = {
  id: Scalars['String']['input'];
  input: UpdateCalendarEventInput;
};

export type MutationUpdateCallScriptArgs = {
  id: Scalars['String']['input'];
  input: UpdateCallScriptInput;
};

export type MutationUpdateCampaignArgs = {
  id: Scalars['String']['input'];
  input: UpdateCampaignInput;
};

export type MutationUpdateCampaignDynamicDataArgs = {
  id: Scalars['String']['input'];
  input: CampaignDynamicDataInput;
};

export type MutationUpdateCampaignOwnPhonesArgs = {
  id: Scalars['String']['input'];
  input: UpdateCampaignOwnPhonesInput;
};

export type MutationUpdateContactArgs = {
  id: Scalars['String']['input'];
  input: UpdateContactInput;
};

export type MutationUpdateContactNoteArgs = {
  contactId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  input: UpdateContactNoteInput;
};

export type MutationUpdateConversationOutgoingPhoneArgs = {
  input: UpdateConversationOutgoingPhoneInput;
};

export type MutationUpdateCrmIntegrationArgs = {
  id: Scalars['String']['input'];
  input: UpdateCrmIntegrationInput;
};

export type MutationUpdateDeviceArgs = {
  input: UpdateDeviceInput;
};

export type MutationUpdateDialerCampaignArgs = {
  id: Scalars['String']['input'];
  input: UpdateDialerCampaignInput;
};

export type MutationUpdateFieldArgs = {
  id: Scalars['String']['input'];
  input: UpdateFieldInput;
};

export type MutationUpdateFieldsTemplateArgs = {
  id: Scalars['String']['input'];
  input: UpdateFieldsTemplateInput;
};

export type MutationUpdateGroupArgs = {
  id: Scalars['String']['input'];
  input: UpdateGroupInput;
};

export type MutationUpdateKeywordTemplateArgs = {
  id: Scalars['String']['input'];
  input: UpdateKeywordTemplateInput;
};

export type MutationUpdateLabelArgs = {
  id: Scalars['String']['input'];
  input: UpdateLabelInput;
};

export type MutationUpdateListArgs = {
  id: Scalars['String']['input'];
  input: UpdateListInput;
};

export type MutationUpdateListItemArgs = {
  id: Scalars['String']['input'];
  input: UpdateListItemInput;
};

export type MutationUpdateMacroArgs = {
  id: Scalars['String']['input'];
  input: UpdateMacroInput;
};

export type MutationUpdateMarketingPopupArgs = {
  id: Scalars['String']['input'];
  input: UpdateMarketingPopupInput;
};

export type MutationUpdateMessageTemplateArgs = {
  id: Scalars['String']['input'];
  input: UpdateMessageTemplateInput;
};

export type MutationUpdateMessagingProfileArgs = {
  input: UpdateMessagingProfileInput;
};

export type MutationUpdateNotificationSettingsArgs = {
  input: UpdateNotificationSettingsInput;
};

export type MutationUpdateScheduledMessageArgs = {
  id: Scalars['String']['input'];
  input: UpdateScheduledMessageInput;
};

export type MutationUpdateSkiptraceArgs = {
  id: Scalars['String']['input'];
  input: UpdateSkiptraceInput;
};

export type MutationUpdateSubaccountArgs = {
  id: Scalars['String']['input'];
  input: UpdateSubaccountInput;
};

export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
  subscriptionId: Scalars['String']['input'];
};

export type MutationUpdateTollFreeRegistrationInfoArgs = {
  accountId: Scalars['String']['input'];
  input: UpdateTollFreeRegistrationInfoInput;
};

export type MutationUploadDncPhonesArgs = {
  input: UploadDncPhonesInput;
};

export type MutationUploadEinFileArgs = {
  input: UploadEinFileInput;
};

export type MutationVerifyListArgs = {
  id: Scalars['String']['input'];
};

export type MutationVerifyPromoCodeArgs = {
  input: VerifyPromoCodeInput;
};

/** Name */
export type Name = {
  __typename?: 'Name';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

/** Notification */
export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime']['output'];
  details?: Maybe<NotificationDetails>;
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  isSeen: Scalars['Boolean']['output'];
  type: NotificationType;
};

/** Notification counter */
export type NotificationCounter = {
  __typename?: 'NotificationCounter';
  accountId: Scalars['String']['output'];
  unreadCount: Scalars['Int']['output'];
};

/** Notification details */
export type NotificationDetails = {
  __typename?: 'NotificationDetails';
  calendarEventDate?: Maybe<Scalars['String']['output']>;
  calendarEventId?: Maybe<Scalars['String']['output']>;
  calendarEventName?: Maybe<Scalars['String']['output']>;
  isKeywordCampaign?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  relationId?: Maybe<Scalars['String']['output']>;
};

export type NotificationList = {
  __typename?: 'NotificationList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Notification>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Notification settings */
export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  enabledCalendar?: Maybe<Scalars['Boolean']['output']>;
  enabledCampaigns?: Maybe<Scalars['Boolean']['output']>;
  enabledPayments?: Maybe<Scalars['Boolean']['output']>;
};

/** Notification Type */
export enum NotificationType {
  BLOCKED_CAMPAIGN = 'BLOCKED_CAMPAIGN',
  CALENDAR_EVENT_CREATED = 'CALENDAR_EVENT_CREATED',
  CAMPAIGN_ENDED = 'CAMPAIGN_ENDED',
  CAMPAIGN_STARTED = 'CAMPAIGN_STARTED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PHONE_EXHAUSTED = 'PHONE_EXHAUSTED',
}

export type OperationStatus = {
  __typename?: 'OperationStatus';
  success: Scalars['Boolean']['output'];
};

export type OptInWorkflowFileInput = {
  contentType: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
};

export type Order = {
  direction?: InputMaybe<OrderQuery>;
};

/** Order pagination */
export enum OrderPagination {
  NEXT = 'NEXT',
  PREV = 'PREV',
}

/** Order direction */
export enum OrderQuery {
  ASC = 'ASC',
  DESC = 'DESC',
}

/** Outside business hours restriction type */
export enum OutsideBusinessHoursRestrictionType {
  CAMPAIGN = 'CAMPAIGN',
}

/** View for reporting outbound call counts for a phone number */
export type OwnDialerPhoneReportView = {
  __typename?: 'OwnDialerPhoneReportView';
  outboundCalls?: Maybe<Scalars['Float']['output']>;
  phoneId: Scalars['String']['output'];
};

/** Own phone */
export type OwnPhone = {
  __typename?: 'OwnPhone';
  callingEnabled: Scalars['Boolean']['output'];
  canBeRemoved: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isTrial: Scalars['Boolean']['output'];
  location?: Maybe<PhoneNumberLocation>;
  messagingEnabled: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  reportDialer?: Maybe<OwnDialerPhoneReportView>;
  reportMessage?: Maybe<OwnPhoneReport>;
  scope: Scope;
  status: OwnPhoneStatus;
  type: OwnPhoneType;
};

/** Own phone counters */
export type OwnPhoneCounters = {
  __typename?: 'OwnPhoneCounters';
  local: Scalars['Int']['output'];
  tollFree: Scalars['Int']['output'];
};

export type OwnPhoneFilter = {
  callingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  messagingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  scope?: InputMaybe<Scope>;
  status?: InputMaybe<OwnPhoneStatus>;
  type?: InputMaybe<OwnPhoneType>;
};

export type OwnPhoneList = {
  __typename?: 'OwnPhoneList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<OwnPhone>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Own phone report view */
export type OwnPhoneReport = {
  __typename?: 'OwnPhoneReport';
  activeConversations: Scalars['Float']['output'];
  blockedRateMessages: Scalars['Float']['output'];
  lastUsedAt?: Maybe<Scalars['DateTime']['output']>;
  outgoingMessages: Scalars['Float']['output'];
  phoneId: Scalars['String']['output'];
};

/** Own phone status */
export enum OwnPhoneStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  NONE = 'NONE',
  PENDING = 'PENDING',
}

/** Own phone type */
export enum OwnPhoneType {
  LOCAL = 'LOCAL',
  TOLL_FREE = 'TOLL_FREE',
}

export type Pagination = {
  /** Number of items returned in query. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Direction getting the corresponding page of data. */
  moveTo?: InputMaybe<OrderPagination>;
  /** Created at after which to fetch records. */
  nextCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** ID after which to fetch records. */
  nextId?: InputMaybe<Scalars['String']['input']>;
};

export type PayBillInput = {
  paymentSourceId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

/** Payment */
export type Payment = {
  __typename?: 'Payment';
  amount?: Maybe<Scalars['Float']['output']>;
  amountBonus?: Maybe<Scalars['Float']['output']>;
  amountCapture?: Maybe<Scalars['Float']['output']>;
  amountRefund?: Maybe<Scalars['Float']['output']>;
  billingProfile?: Maybe<BillingProfile>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  feature: PaymentFeature;
  id: Scalars['String']['output'];
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  method: PaymentMethod;
  profileId: Scalars['String']['output'];
  purpose: PaymentPurpose;
  status: PaymentStatus;
  type: PaymentType;
};

/** Payment feature */
export enum PaymentFeature {
  A2P_10DLC = 'A2P_10DLC',
  CALL = 'CALL',
  CAMPAIGN = 'CAMPAIGN',
  CUSTOM_WEBSITE = 'CUSTOM_WEBSITE',
  DIALER_CALL = 'DIALER_CALL',
  LOOKUP = 'LOOKUP',
  MESSAGING = 'MESSAGING',
  MMS = 'MMS',
  PHONE = 'PHONE',
  RECHARGE = 'RECHARGE',
  RVM = 'RVM',
  SKIPTRACE = 'SKIPTRACE',
  SMS = 'SMS',
  SMS_ALERT = 'SMS_ALERT',
  SUBACCOUNT = 'SUBACCOUNT',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export type PaymentList = {
  __typename?: 'PaymentList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Payment>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Payment method */
export enum PaymentMethod {
  BALANCE = 'BALANCE',
  CARD = 'CARD',
}

/** Payment purpose */
export enum PaymentPurpose {
  FEATURE = 'FEATURE',
  RECHARGE = 'RECHARGE',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

/** Payment source */
export type PaymentSource = {
  __typename?: 'PaymentSource';
  brand?: Maybe<Scalars['String']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  expMonth?: Maybe<Scalars['Float']['output']>;
  expYear?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  last4?: Maybe<Scalars['String']['output']>;
};

export type PaymentSourceInput = {
  id: Scalars['String']['input'];
};

/** Payment status */
export enum PaymentStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
}

/** Payment type */
export enum PaymentType {
  CAPTURE = 'CAPTURE',
  REFUND = 'REFUND',
}

export type PaymentsFilter = {
  createdAtEnd?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtStart?: InputMaybe<Scalars['DateTime']['input']>;
  method?: InputMaybe<PaymentMethod>;
};

/** Period */
export type Period = {
  __typename?: 'Period';
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  startedAt: Scalars['DateTime']['output'];
};

/** Phone number */
export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  location?: Maybe<PhoneNumberLocation>;
  number: Scalars['String']['output'];
};

/** Phone number location */
export type PhoneNumberLocation = {
  __typename?: 'PhoneNumberLocation';
  abbreviation: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

/** Phone settings */
export type PhoneSettings = {
  __typename?: 'PhoneSettings';
  activeIn?: Maybe<Scalars['String']['output']>;
  alertFilter?: Maybe<PhoneSettingsFilter>;
  callAutoReply?: Maybe<PhoneSettingsAutoReply>;
  isSipEnabled?: Maybe<Scalars['Boolean']['output']>;
  notificationFilter?: Maybe<PhoneSettingsFilter>;
  notificationsEnabled?: Maybe<Scalars['Boolean']['output']>;
  voiceMail?: Maybe<PhoneSettingsAutoReply>;
};

/** Phone settings auto reply */
export type PhoneSettingsAutoReply = {
  __typename?: 'PhoneSettingsAutoReply';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  voiceType?: Maybe<PhoneSettingsVoiceType>;
};

export type PhoneSettingsAutoReplyInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  voiceType?: InputMaybe<PhoneSettingsVoiceType>;
};

/** Phone settings filter */
export type PhoneSettingsFilter = {
  __typename?: 'PhoneSettingsFilter';
  campaigns?: Maybe<Array<Scalars['String']['output']>>;
  groups?: Maybe<Array<Scalars['String']['output']>>;
  labels?: Maybe<Array<Scalars['String']['output']>>;
};

export type PhoneSettingsFilterInput = {
  campaigns?: InputMaybe<Array<Scalars['String']['input']>>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PhoneSettingsInput = {
  activeIn?: InputMaybe<Scalars['String']['input']>;
  alertFilter?: InputMaybe<PhoneSettingsFilterInput>;
  callAutoReply?: InputMaybe<PhoneSettingsAutoReplyInput>;
  isSipEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  notificationFilter?: InputMaybe<PhoneSettingsFilterInput>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  voiceMail?: InputMaybe<PhoneSettingsAutoReplyInput>;
};

/** Phone settings voice type */
export enum PhoneSettingsVoiceType {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

/** Plan */
export type Plan = {
  __typename?: 'Plan';
  active: Scalars['Boolean']['output'];
  base: Scalars['Boolean']['output'];
  couponId?: Maybe<Scalars['String']['output']>;
  default: Scalars['Boolean']['output'];
  discount: Scalars['Money']['output'];
  displayPrice: Scalars['Money']['output'];
  enterprise: Scalars['Boolean']['output'];
  features: Array<PlanFeature>;
  id: Scalars['String']['output'];
  interval: BillingInterval;
  lexorank?: Maybe<Scalars['String']['output']>;
  monthlyPrice?: Maybe<Scalars['Money']['output']>;
  mostPopular: Scalars['Boolean']['output'];
  price: Scalars['Money']['output'];
  product: Product;
  secondary: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: PlanType;
  userTitle: Scalars['String']['output'];
};

/** Plan feature */
export type PlanFeature = {
  __typename?: 'PlanFeature';
  limit?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Money']['output']>;
  product?: Maybe<Product>;
  tier?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  trialLimits: PlanFeatureTrial;
  type: FeatureType;
};

/** Plan feature trial */
export type PlanFeatureTrial = {
  __typename?: 'PlanFeatureTrial';
  limit?: Maybe<Scalars['Float']['output']>;
  tier?: Maybe<Scalars['Float']['output']>;
  use: Scalars['Boolean']['output'];
};

/** Plan type */
export enum PlanType {
  BASIC = 'BASIC',
  ELITE = 'ELITE',
  ELITE_OLD = 'ELITE_OLD',
  PRO = 'PRO',
  STANDARD = 'STANDARD',
}

/** Power dialer error type */
export enum PowerDialerFailedErrorType {
  ALREADY_ONLINE = 'ALREADY_ONLINE',
  BAD_MESSAGING_PROVIDER_TYPE = 'BAD_MESSAGING_PROVIDER_TYPE',
  CAMPAIGN_COULDNT_START = 'CAMPAIGN_COULDNT_START',
  CAMPAIGN_NOT_FOUND = 'CAMPAIGN_NOT_FOUND',
  DAILY_CONTACT_LIMIT_REACHED = 'DAILY_CONTACT_LIMIT_REACHED',
  FAILED_TO_CONNECT_CALL = 'FAILED_TO_CONNECT_CALL',
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  LABEL_NOT_APPLIED = 'LABEL_NOT_APPLIED',
}

/** Error encountered during power dialer execution */
export type PowerDialerFailedView = {
  __typename?: 'PowerDialerFailedView';
  errorMessage: Scalars['String']['output'];
  errorType: PowerDialerFailedErrorType;
};

/** Info about the latest Dialer call state. */
export type PowerDialerStateUpdatedView = {
  __typename?: 'PowerDialerStateUpdatedView';
  batchId: Scalars['String']['output'];
  campaignId: Scalars['String']['output'];
  campaignIsCompleted?: Maybe<Scalars['Boolean']['output']>;
  connectedLineId?: Maybe<Scalars['String']['output']>;
  currentContactId?: Maybe<Scalars['String']['output']>;
  currentOutboundNumber?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  establishedAt?: Maybe<Scalars['DateTime']['output']>;
  hangupIsDisabled: Scalars['Boolean']['output'];
  isConnecting?: Maybe<Scalars['Boolean']['output']>;
  isDialing: Scalars['Boolean']['output'];
  isRecording?: Maybe<Scalars['Boolean']['output']>;
  lines: Array<LineView>;
  showOutcomeLabels: Scalars['Boolean']['output'];
};

/** Predefined field type */
export enum PredefinedField {
  ADDRESS = 'ADDRESS',
  CITY = 'CITY',
  EMAIL = 'EMAIL',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  MAILING_ADDRESS = 'MAILING_ADDRESS',
  MAILING_CITY = 'MAILING_CITY',
  MAILING_STATE = 'MAILING_STATE',
  MAILING_ZIP = 'MAILING_ZIP',
  PHONE = 'PHONE',
  STATE = 'STATE',
  ZIP = 'ZIP',
}

/** Product */
export enum Product {
  BASE = 'BASE',
  DIALER = 'DIALER',
  MESSAGING = 'MESSAGING',
}

/** Product summary */
export type ProductSummary = {
  __typename?: 'ProductSummary';
  activePrimary?: Maybe<Scalars['Int']['output']>;
  activeSecondary?: Maybe<Scalars['Int']['output']>;
  inactive?: Maybe<Scalars['Int']['output']>;
  monthNewActive?: Maybe<Scalars['Int']['output']>;
  product: Product;
  trial?: Maybe<Scalars['Int']['output']>;
};

/** Profile billing */
export type ProfileBilling = {
  __typename?: 'ProfileBilling';
  amount: Scalars['Money']['output'];
  paidAt: Scalars['DateTime']['output'];
};

/** Product fees */
export type ProfileProductFees = {
  __typename?: 'ProfileProductFees';
  amount: Scalars['Money']['output'];
  product: Product;
};

/** Promotional code */
export type PromoCode = {
  __typename?: 'PromoCode';
  code: Scalars['String']['output'];
  coupon: Coupon;
  id: Scalars['String']['output'];
};

export type PurchaseOwnPhoneInput = {
  phone: Scalars['String']['input'];
  scope: Scope;
  type: OwnPhoneType;
};

export type PushSkiptraceToGroupInput = {
  id: Scalars['String']['input'];
  type: PushToGroupSkiptraceType;
};

/** Push to group skiptrace type */
export enum PushToGroupSkiptraceType {
  ALL = 'ALL',
  MOBILE_ONLY = 'MOBILE_ONLY',
}

export type Query = {
  __typename?: 'Query';
  /** Get current account info */
  account: Account;
  /** Get current account affiliate */
  affiliate: Affiliate;
  /** Build message templates utilizing AI completion */
  buildMessageTemplates: Array<MessageTemplate>;
  /** Download billing report */
  downloadBillingReport: BillingReport;
  /** Export accounts */
  exportAccounts: SignedUrl;
  /** Export campaign results to file */
  exportCampaignToFile: SignedUrl;
  /** Export campaign results to group */
  exportCampaignToGroup: GroupView;
  /** Export contacts to CRM integration */
  exportConversationsToCrmIntegration: ExportConversationsToCrmIntegrationResultView;
  /** Export conversations to file */
  exportConversationsToFile: SignedUrl;
  /** Export dialer campaign results to file */
  exportDialerCampaignToFile: SignedUrl;
  /** Export group */
  exportGroup: SignedUrl;
  /** Generate signed url for downloading list from storage */
  exportList: SignedUrl;
  /** Export skiptrace to CSV file */
  exportSkiptrace: SignedUrl;
  /** Export suppressions */
  exportSuppressions: SignedUrl;
  /** Find accounts */
  findAccounts: AccountList;
  /** Find active marketing popups */
  findActiveMarketingPopups: Array<MarketingPopup>;
  /** Get list of affiliates */
  findAffiliates: AffiliateList;
  /** Find help articles */
  findArticles: Array<Article>;
  /** Find available phones */
  findAvailablePhones: Array<AvailablePhoneView>;
  /** Find calendar events */
  findCalendarEvents: CalendarEventList;
  /** Find call script */
  findCallScripts: CallScriptList;
  /** Find campaign messages */
  findCampaignMessages: ScheduledMessageList;
  /** Find campaigns */
  findCampaigns: CampaignList;
  /** Find closest calendar event for relation ID for application access */
  findClosestCalendarEventApplicationForRelation?: Maybe<CalendarEvent>;
  /** Find closest calendar event for relation ID */
  findClosestCalendarEventForRelation?: Maybe<CalendarEvent>;
  /** Find contact notes */
  findContactNotes: ContactNoteList;
  /** Find contact notes for contact by Firestore ID for application access */
  findContactNotesApplicationForContactByFirestoreId: Array<ContactNote>;
  /** Find contact notes for contact by Firestore ID */
  findContactNotesForContactByFirestoreId: ContactNoteList;
  /** Find contacts */
  findContacts: ContactList;
  /** Find conversation messages */
  findConversationMessages: MessageList;
  /** Find conversations */
  findConversations: ConversationList;
  /** Find CRM integrations */
  findCrmIntegrations: CrmIntegrationList;
  /** Find dialer campaigns */
  findDialerCampaigns: DialerCampaignList;
  /**
   * Find feedbacks
   * @deprecated Legacy API, use findLastBeedback instead
   */
  findFeedbacks: Array<Feedback>;
  /** Find fields */
  findFields: Array<Field>;
  /** Find fields templates */
  findFieldsTemplates: FieldsTemplateList;
  /** Find groups */
  findGroups: GroupList;
  /** Find keyword templates */
  findKeywordTemplates: KeywordTemplateList;
  /** Find labels */
  findLabels: LabelList;
  /**
   * Find labels for Firestore. It will be removed after the complete migration.
   * @deprecated It's will be removed soon.
   */
  findLabelsForFuckingFirestore: Array<Label>;
  /** Find last feedback by source */
  findLastFeedback?: Maybe<Feedback>;
  /** Find list items */
  findListItems: ListItemList;
  /** Find macros */
  findMacros: MacroList;
  /** Find marketing popups */
  findMarketingPopups: MarketingPopupList;
  /** Find fields that could be used as merge tags */
  findMergeTags: Array<MergeTag>;
  /** Find message templates */
  findMessageTemplates: MessageTemplateList;
  /**
   * Find message templates for Firestore. It will be removed after the complete migration.
   * @deprecated It's will be removed soon.
   */
  findMessageTemplatesForFuckingFirestore: Array<MessageTemplate>;
  /** Get list of notifications */
  findNotifications: NotificationList;
  /** Find own phones */
  findOwnPhones: OwnPhoneList;
  /** Get list of payments */
  findPayments: PaymentList;
  /** Find refunded bonus payments. Temporary integration query. */
  findRefundedBonusPayments: Array<Payment>;
  /** Find scheduled messages */
  findScheduledMessages: ScheduledMessageList;
  /** Find skiptrace fields */
  findSkiptraceFields: Array<SkiptraceField>;
  /** Find skiptraces */
  findSkiptraces: SkiptraceList;
  /** Find suppressions */
  findSuppressions: SuppressionList;
  /** Generate WebRTC token for PowerDialer use */
  generatePowerDialerWebRTCToken: WebRtcToken;
  /** Generate WebRTC token for web client */
  generateWebRTCToken: WebRtcToken;
  /** Get A2P registration info for reactivation form */
  getA2PReactivationInfo: A2PRegistration;
  /** Get A2P registration info */
  getA2PRegistrationInfo: A2PRegistration;
  /** Find account by ID */
  getAccount: Account;
  /**
   * Get accounts counters
   * @deprecated Use `getAccountsSummary` instead. This query will be removed once `getAccountsSummary` implemented.
   */
  getAccountsCounters: AccountCounters;
  /** Get accounts summary */
  getAccountsSummary: AccountsSummary;
  /** Get active plans */
  getActivePlans: Array<Plan>;
  /** Get affiliate by ID */
  getAffiliate: Affiliate;
  /** Find calendar event by ID */
  getCalendarEvent: CalendarEvent;
  /** Get call script by ID */
  getCallScript: CallScript;
  /** Find campaign by ID */
  getCampaign: Campaign;
  /** Get campaign report */
  getCampaignReport: CampaignReport;
  /** Get campaign schedule */
  getCampaignSchedule: CampaignSchedule;
  /** Get campaigns counters */
  getCampaignsCounters: CampaignsAggregationView;
  /** Find contact by ID */
  getContact: Contact;
  /** Generate signed url for uploading contact note images to Storage */
  getContactNoteSignedUrl: SignedUrl;
  /** Get contact report */
  getContactReport: ContactReportView;
  /** Get contact counters */
  getContactsCounters: ContactCountersView;
  /** Find conversation by ID */
  getConversation: Conversation;
  /** Get CRM integration */
  getCrmIntegration: CrmIntegration;
  /** Get current call */
  getCurrentCall: Message;
  /** Get deals closed report */
  getDealsClosedReport: DealsClosedReport;
  /** Get dialer call outcome report */
  getDialerCallOutcomeReport: Array<DialerCallOutcomeReportView>;
  /** Get dialer call report */
  getDialerCallReport: DialerCallReportView;
  /** Find dialer campaign by ID */
  getDialerCampaign: DialerCampaign;
  /** Get dialer campaigns counters */
  getDialerCampaignsCounters: DialerCampaignsAggregationView;
  /** Get dialer contact report */
  getDialerContactReport: DialerContactReportView;
  /** Generate signed url for uploading EIN file */
  getEINFileUploadSignedUrl: SignedUrl;
  /** Find fields template by ID */
  getFieldsTemplate: FieldsTemplate;
  /** Get group by id */
  getGroup: GroupView;
  /** Get some Infobip configuration values */
  getInfobipConfig: InfobipConfigView;
  /** Get keyword template by ID */
  getKeywordTemplate: KeywordTemplate;
  /** Find label by ID */
  getLabel: Label;
  /** Get label report */
  getLabelReport: Array<LabelReportView>;
  /** Get list by id */
  getList: ListView;
  /** Generate signed url for uploading list to storage */
  getListUploadSignedUrl: SignedUrl;
  /** Find macro by ID */
  getMacro: Macro;
  /** Get marketing popup by ID */
  getMarketingPopup: MarketingPopup;
  /** Generate signed url for uploading marketing popups images to Google Cloud Storage */
  getMarketingPopupSignedUrl: SignedUrl;
  /** Generate signed url for uploading message attachments */
  getMessageAttachmentSignedUrl: SignedUrl;
  /** Get message report */
  getMessageReport: MessageReport;
  /** Get message template by ID */
  getMessageTemplate: MessageTemplate;
  /** Generate signed url for uploading audio files */
  getMessageTemplateSignedUrl: SignedUrl;
  /** Get messaging profile */
  getMessagingProfile: MessagingProfile;
  /** Get notification settings */
  getNotificationSettings: NotificationSettings;
  /** Generate signed url for uploading opt-in workflow file */
  getOptInWorkflowFileSignedUrl: SignedUrl;
  /** Find own phone by id */
  getOwnPhone: OwnPhone;
  /** Get counters by own phone types */
  getOwnPhoneCounters: OwnPhoneCounters;
  /** Get plans */
  getPlans: Array<Plan>;
  /** Get skiptrace by id */
  getSkiptrace: Skiptrace;
  /** Get provider-related account statistics */
  getStatistics: MessagingProvidersCounters;
  /** Get toll-free registration info */
  getTollFreeRegistrationInfo: TollFreeRegistration;
  /** Get unread conversations counters */
  getUnreadConversationsCounters: UnreadConversationsCountersView;
  /** Get unread notification counter */
  getUnreadNotificationCounter: NotificationCounter;
  /** Generate signed url for uploading list of phone numbers to add them to DNC */
  getUploadDNCPhonesSignedUrl: SignedUrl;
  health: Health;
  /** Retrieve upcoming invoice */
  retrieveUpcomingInvoice: UpcomingInvoice;
  /** Get account subscriptions */
  subscriptions: Array<CurrentSubscription>;
  /** Suppressions summary */
  suppressionsSummary: SuppressionsSummary;
  version: Version;
};

export type QueryBuildMessageTemplatesArgs = {
  input: BuildMessageTemplateInput;
};

export type QueryDownloadBillingReportArgs = {
  filter?: InputMaybe<PaymentsFilter>;
};

export type QueryExportAccountsArgs = {
  filter?: InputMaybe<AccountsFilter>;
  password: Scalars['String']['input'];
};

export type QueryExportCampaignToFileArgs = {
  input: ExportCampaignToFileInput;
};

export type QueryExportCampaignToGroupArgs = {
  id: Scalars['String']['input'];
  input: ExportCampaignToGroupInput;
};

export type QueryExportConversationsToCrmIntegrationArgs = {
  input: ExportConversationsToCrmIntegrationInput;
};

export type QueryExportConversationsToFileArgs = {
  input: ExportConversationsToFileInput;
};

export type QueryExportDialerCampaignToFileArgs = {
  input: ExportDialerCampaignToFileInput;
};

export type QueryExportGroupArgs = {
  id: Scalars['String']['input'];
};

export type QueryExportListArgs = {
  input: ExportListInput;
};

export type QueryExportSkiptraceArgs = {
  input: ExportSkiptraceInput;
};

export type QueryExportSuppressionsArgs = {
  input: ExportSuppressionsInput;
};

export type QueryFindAccountsArgs = {
  filter?: InputMaybe<AccountsFilter>;
  order?: InputMaybe<Order>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindAffiliatesArgs = {
  filter?: InputMaybe<AffiliateFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindAvailablePhonesArgs = {
  filter: FindAvailablePhonesFilter;
};

export type QueryFindCalendarEventsArgs = {
  filter?: InputMaybe<CalendarEventsFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindCallScriptsArgs = {
  filter?: InputMaybe<CallScriptsFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindCampaignMessagesArgs = {
  filter?: InputMaybe<ScheduledMessageFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindCampaignsArgs = {
  filter?: InputMaybe<CampaignFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindClosestCalendarEventApplicationForRelationArgs = {
  relationId: Scalars['String']['input'];
};

export type QueryFindClosestCalendarEventForRelationArgs = {
  relationId: Scalars['String']['input'];
};

export type QueryFindContactNotesArgs = {
  filter: ContactNoteFilter;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindContactNotesApplicationForContactByFirestoreIdArgs = {
  firestoreId: Scalars['String']['input'];
};

export type QueryFindContactNotesForContactByFirestoreIdArgs = {
  filter: ContactNoteFilter;
  firestoreId: Scalars['String']['input'];
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindContactsArgs = {
  filter?: InputMaybe<ContactFilter>;
  order?: InputMaybe<ContactOrder>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindConversationMessagesArgs = {
  contactId: Scalars['String']['input'];
  filter?: InputMaybe<MessageFilter>;
  order?: InputMaybe<MessageOrder>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindConversationsArgs = {
  filter?: InputMaybe<ConversationFilter>;
  order?: InputMaybe<ConversationOrder>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindCrmIntegrationsArgs = {
  filter?: InputMaybe<CrmIntegrationFilter>;
  order?: InputMaybe<CrmIntegrationOrder>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindDialerCampaignsArgs = {
  filter?: InputMaybe<DialerCampaignFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindFieldsArgs = {
  filter?: InputMaybe<FieldsFilter>;
};

export type QueryFindFieldsTemplatesArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindGroupsArgs = {
  filter?: InputMaybe<GroupsFilter>;
  order?: InputMaybe<Order>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindKeywordTemplatesArgs = {
  filter?: InputMaybe<KeywordTemplatesFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindLabelsArgs = {
  filter?: InputMaybe<LabelFilter>;
  pagination?: InputMaybe<CursorPagination>;
};

export type QueryFindLabelsForFuckingFirestoreArgs = {
  filter?: InputMaybe<LabelFilter>;
  pagination?: InputMaybe<CursorPagination>;
};

export type QueryFindLastFeedbackArgs = {
  source: FeedbackSource;
};

export type QueryFindListItemsArgs = {
  listId: Scalars['String']['input'];
  pagination?: InputMaybe<ListItemsPagination>;
};

export type QueryFindMacrosArgs = {
  filter?: InputMaybe<MacrosFilter>;
  pagination?: InputMaybe<CursorPagination>;
};

export type QueryFindMarketingPopupsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindMessageTemplatesArgs = {
  filter?: InputMaybe<MessageTemplatesFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindMessageTemplatesForFuckingFirestoreArgs = {
  filter?: InputMaybe<MessageTemplatesFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindNotificationsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindOwnPhonesArgs = {
  filter?: InputMaybe<OwnPhoneFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindPaymentsArgs = {
  filter?: InputMaybe<PaymentsFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindRefundedBonusPaymentsArgs = {
  filter?: InputMaybe<PaymentsFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindScheduledMessagesArgs = {
  filter?: InputMaybe<ScheduledMessageFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindSkiptracesArgs = {
  order?: InputMaybe<Order>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryFindSuppressionsArgs = {
  filter?: InputMaybe<SuppressionsFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryGeneratePowerDialerWebRtcTokenArgs = {
  devicePlatform?: InputMaybe<DevicePlatform>;
};

export type QueryGenerateWebRtcTokenArgs = {
  devicePlatform?: InputMaybe<DevicePlatform>;
};

export type QueryGetA2PRegistrationInfoArgs = {
  accountId: Scalars['String']['input'];
};

export type QueryGetAccountArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetAffiliateArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetCalendarEventArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetCallScriptArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetCampaignArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetCampaignReportArgs = {
  filter: ReportsFilter;
};

export type QueryGetCampaignScheduleArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<CampaignScheduleOptionsInput>;
};

export type QueryGetContactArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetContactNoteSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};

export type QueryGetContactReportArgs = {
  filter: ReportsFilter;
};

export type QueryGetConversationArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetCrmIntegrationArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetCurrentCallArgs = {
  contactId: Scalars['String']['input'];
};

export type QueryGetDialerCallOutcomeReportArgs = {
  filter?: InputMaybe<DialerReportsFilter>;
};

export type QueryGetDialerCallReportArgs = {
  filter?: InputMaybe<DialerReportsFilter>;
};

export type QueryGetDialerCampaignArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetDialerContactReportArgs = {
  filter?: InputMaybe<DialerReportsFilter>;
};

export type QueryGetEinFileUploadSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};

export type QueryGetFieldsTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetGroupArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetKeywordTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetLabelArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetLabelReportArgs = {
  filter: ReportsFilter;
};

export type QueryGetListArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetListUploadSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};

export type QueryGetMacroArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetMarketingPopupArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetMarketingPopupSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};

export type QueryGetMessageAttachmentSignedUrlArgs = {
  input: GetMessageAttachmentSignedUrlInput;
};

export type QueryGetMessageReportArgs = {
  filter: ReportsFilter;
};

export type QueryGetMessageTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetMessageTemplateSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};

export type QueryGetOptInWorkflowFileSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};

export type QueryGetOwnPhoneArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetOwnPhoneCountersArgs = {
  scope?: InputMaybe<Scope>;
};

export type QueryGetSkiptraceArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetTollFreeRegistrationInfoArgs = {
  accountId: Scalars['String']['input'];
};

export type QueryGetUnreadConversationsCountersArgs = {
  profileId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetUploadDncPhonesSignedUrlArgs = {
  input: GenerateSignedUrlInput;
};

export type QueryRetrieveUpcomingInvoiceArgs = {
  products: Array<Product>;
  subAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryDateFilter = {
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Quota type */
export enum QuotaType {
  CALL = 'CALL',
  CAMPAIGN_SMS = 'CAMPAIGN_SMS',
  CONTACTS = 'CONTACTS',
  DIALER_CALL = 'DIALER_CALL',
  DIALER_CONTACTS = 'DIALER_CONTACTS',
  DIALER_SUB_ACCOUNTS = 'DIALER_SUB_ACCOUNTS',
  LOCAL_PHONE = 'LOCAL_PHONE',
  LOOKUP = 'LOOKUP',
  OWN_PHONE = 'OWN_PHONE',
  RVM = 'RVM',
  SKIPTRACE = 'SKIPTRACE',
  SMS = 'SMS',
  SMS_ALERT = 'SMS_ALERT',
  SUB_ACCOUNTS = 'SUB_ACCOUNTS',
  TEMPLATES = 'TEMPLATES',
  TEMPLATES_AI = 'TEMPLATES_AI',
  TOLL_FREE_PHONE = 'TOLL_FREE_PHONE',
  TRIAL_PHONE = 'TRIAL_PHONE',
}

/** Quota Usage */
export type QuotaUsage = {
  __typename?: 'QuotaUsage';
  type: QuotaType;
  used: Scalars['Int']['output'];
};

export type RankCallScriptInput = {
  afterId?: InputMaybe<Scalars['String']['input']>;
  beforeId?: InputMaybe<Scalars['String']['input']>;
};

export type RankKeywordTemplateInput = {
  afterId?: InputMaybe<Scalars['String']['input']>;
  beforeId?: InputMaybe<Scalars['String']['input']>;
};

export type RankLabelInput = {
  afterId?: InputMaybe<Scalars['String']['input']>;
  beforeId?: InputMaybe<Scalars['String']['input']>;
};

export type RankMacroInput = {
  afterId?: InputMaybe<Scalars['String']['input']>;
  beforeId?: InputMaybe<Scalars['String']['input']>;
};

export type RankMessageTemplateInput = {
  afterId?: InputMaybe<Scalars['String']['input']>;
  beforeId?: InputMaybe<Scalars['String']['input']>;
};

/** Rank result view */
export type RankResult = {
  __typename?: 'RankResult';
  afterId?: Maybe<Scalars['String']['output']>;
  beforeId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

/** Real Estate Attribute */
export enum RealEstateAttribute {
  AC = 'AC',
  BATH = 'BATH',
  BED = 'BED',
  GARAGE = 'GARAGE',
  LAST_SOLD_DATE = 'LAST_SOLD_DATE',
  LOT_SIZE = 'LOT_SIZE',
  OCCUPANCY = 'OCCUPANCY',
  OFFER = 'OFFER',
  OWES = 'OWES',
  POOL = 'POOL',
  PROPERTY_TYPE = 'PROPERTY_TYPE',
  RENT = 'RENT',
  REPAIRS = 'REPAIRS',
  ROOF = 'ROOF',
  SQ_FT = 'SQ_FT',
  TAX_ASSESSMENT = 'TAX_ASSESSMENT',
  YEAR_BUILT = 'YEAR_BUILT',
  ZILLOW_ESTIMATE = 'ZILLOW_ESTIMATE',
}

export type RecoverContactsInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  subAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterAffiliateInput = {
  referralId: Scalars['String']['input'];
};

export type RemoveCampaignInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RemoveContactFromDncSuppressionsInput = {
  contactId: Scalars['String']['input'];
};

export type RemoveContactsInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  subAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveDialerCampaignInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RemoveGroupFromContactsInput = {
  groupId: Scalars['String']['input'];
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RemoveGroupsInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RemoveLabelsFromContactsInput = {
  ids: Array<Scalars['String']['input']>;
  labelIds: Array<Scalars['String']['input']>;
};

export type RemoveSkiptracesInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RemoveSuppressionsInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  source: SuppressionSource;
};

export type ReportsFilter = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  endAt: Scalars['DateTime']['input'];
  profileId?: InputMaybe<Scalars['String']['input']>;
  startAt: Scalars['DateTime']['input'];
  templateId?: InputMaybe<Scalars['String']['input']>;
};

/** Response removal */
export type ResponseRemoval = {
  __typename?: 'ResponseRemoval';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  responseFilters?: Maybe<Array<Scalars['String']['output']>>;
};

export type ResponseRemovalInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  responseFilters?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RunSkiptraceInput = {
  excludeFirstRow?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  useCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ScheduleBalanceInput = {
  amount: Scalars['Int']['input'];
  enabled: Scalars['Boolean']['input'];
  limit: Scalars['Int']['input'];
};

export type ScheduleCheckMonthsOfMembershipTaskInput = {
  profileIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ScheduleOptionsInput = {
  frequency: Frequency;
  hasCustomBatchSize?: InputMaybe<Scalars['Boolean']['input']>;
  hasCustomStartDate?: InputMaybe<Scalars['Boolean']['input']>;
  interval: Scalars['Int']['input'];
  perBatch: Scalars['Int']['input'];
  startAt: Scalars['DateTime']['input'];
};

/** ScheduledMessage */
export type ScheduledMessage = {
  __typename?: 'ScheduledMessage';
  attachments?: Maybe<Array<MessageAttachment>>;
  contact?: Maybe<Contact>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  template?: Maybe<MessageTemplate>;
};

export type ScheduledMessageFilter = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  contactId?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CampaignMessageStatus>;
  step?: InputMaybe<Scalars['Int']['input']>;
};

export type ScheduledMessageList = {
  __typename?: 'ScheduledMessageList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<ScheduledMessage>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Application Scope */
export enum Scope {
  DIALER = 'DIALER',
  MESSAGE = 'MESSAGE',
}

export type SendMessageInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<Array<MessageAttachmentInput>>;
  contactId: Scalars['String']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  fromNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SequenceOptions = {
  days?: InputMaybe<Array<Scalars['Int']['input']>>;
  delay?: InputMaybe<Scalars['Float']['input']>;
  hasCustomTimeRestrictions?: InputMaybe<Scalars['Boolean']['input']>;
  timeRange?: InputMaybe<TimeRangeInput>;
};

/** Sequence progress */
export type SequenceProgress = {
  __typename?: 'SequenceProgress';
  sent: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Sequence rule */
export type SequenceRule = {
  __typename?: 'SequenceRule';
  groupId?: Maybe<Scalars['String']['output']>;
  labelId?: Maybe<Scalars['String']['output']>;
  removeFromNextSequences?: Maybe<Scalars['Boolean']['output']>;
  strategy?: Maybe<CampaignRuleStrategy>;
};

/** Sequence type */
export enum SequenceType {
  RVM = 'RVM',
  SMS = 'SMS',
}

/** Sequence view */
export type SequenceView = {
  __typename?: 'SequenceView';
  content?: Maybe<Scalars['String']['output']>;
  days: Array<Scalars['Int']['output']>;
  delay: Scalars['Float']['output'];
  hasCustomTimeRestrictions: Scalars['Boolean']['output'];
  progress?: Maybe<SequenceProgress>;
  rule?: Maybe<SequenceRule>;
  templates: Array<Scalars['String']['output']>;
  timeRange: TimeRange;
  type: SequenceType;
};

/** Session */
export type Session = {
  __typename?: 'Session';
  online: Scalars['Boolean']['output'];
};

export type SignUpInput = {
  accountType?: InputMaybe<AccountType>;
  analytics?: InputMaybe<AccountAnalyticsInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['PhoneNumberType']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  questionnaire: AccountQuestionnaireInput;
  referralId?: InputMaybe<Scalars['String']['input']>;
  timeZone: Scalars['String']['input'];
};

/** Signed URL */
export type SignedUrl = {
  __typename?: 'SignedUrl';
  filePath: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

/** Signed url action */
export enum SignedUrlAction {
  DELETE = 'DELETE',
  READ = 'READ',
  RESUMABLE = 'RESUMABLE',
  WRITE = 'WRITE',
}

/** Skiptrace */
export type Skiptrace = {
  __typename?: 'Skiptrace';
  cachedContacts?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  excludeFirstRow?: Maybe<Scalars['Boolean']['output']>;
  fields?: Maybe<Array<SkiptraceField>>;
  fileName: Scalars['String']['output'];
  foundContacts?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  landlineContacts?: Maybe<Scalars['Int']['output']>;
  mobileContacts?: Maybe<Scalars['Int']['output']>;
  processedContacts?: Maybe<Scalars['Int']['output']>;
};

/** Skiptrace completed */
export type SkiptraceCompleted = {
  __typename?: 'SkiptraceCompleted';
  skiptraceId: Scalars['String']['output'];
};

/** Skiptrace field */
export type SkiptraceField = {
  __typename?: 'SkiptraceField';
  id: Scalars['String']['output'];
  key?: Maybe<SkiptracePredefinedField>;
  name: Scalars['String']['output'];
  predefined: Scalars['Boolean']['output'];
};

/** List of skiptraces */
export type SkiptraceList = {
  __typename?: 'SkiptraceList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Skiptrace>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Skiptrace predefined field */
export enum SkiptracePredefinedField {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  MAILING_ADDRESS = 'MAILING_ADDRESS',
  MAILING_CITY = 'MAILING_CITY',
  MAILING_STATE = 'MAILING_STATE',
  MAILING_ZIP = 'MAILING_ZIP',
  PHONE = 'PHONE',
  PHONE_TYPE = 'PHONE_TYPE',
  PROPERTY_ADDRESS = 'PROPERTY_ADDRESS',
  PROPERTY_CITY = 'PROPERTY_CITY',
  PROPERTY_STATE = 'PROPERTY_STATE',
  PROPERTY_ZIP = 'PROPERTY_ZIP',
}

/** Skiptrace progress */
export type SkiptraceProgress = {
  __typename?: 'SkiptraceProgress';
  progress: Scalars['Float']['output'];
  skiptraceId: Scalars['String']['output'];
};

export type StartPowerDialingInput = {
  applicationCallId: Scalars['String']['input'];
  dialerCampaignId: Scalars['String']['input'];
};

/** State reason */
export type StateReason = {
  __typename?: 'StateReason';
  ttl?: Maybe<Scalars['DateTime']['output']>;
  type: CampaignStateReasonType;
};

export type SubmitA2PRegistrationInput = {
  brand: A2PBrandInput;
  campaign: A2PBrandCampaignInput;
};

export type SubmitA2PSubAccountRegistrationInput = {
  campaign: A2PBrandCampaignInput;
};

export type SubscribeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Account updated event */
  accountUpdated: ChangesItemView;
  /** Change on the progress of the campaign's sequences */
  campaignProgressUpdated: CampaignProgressUpdated;
  /** Campaign upserted event */
  campaignUpserted: ChangesItemView;
  /** Contacts changes */
  contactsChanges: ChangesView;
  /** Conversation created event */
  conversationCreated: ChangesItemView;
  /** Conversation updated event */
  conversationUpdated: ChangesItemView;
  /** Message received event */
  incomingMessageReceived: Message;
  /** List upload completed event */
  listUploadCompleted: ListUploadCompleted;
  /** List upload progress updated event */
  listUploadProgress: ListUploadProgress;
  /** List verification completed event */
  listVerificationCompleted: ListVerificationResult;
  /** List verification progress updated event */
  listVerificationProgress: ListVerificationProgress;
  /** Message created event */
  messageCreated: ChangesItemView;
  /** Message updated event */
  messageUpdated: ChangesItemView;
  /** Missed conversations calls counter updated event */
  missedConversationsCallsCounterUpdated: UnreadConversationsCounterUpdatedView;
  /** Notification created event */
  notificationCreated: Notification;
  /** Notification counter updated event */
  notificationUnreadCounterUpdated: NotificationCounter;
  /** Notifies about restricted actions outside business hours */
  outsideBusinessHoursRestrictions: AccountOutsideBusinessHoursRestrictionsView;
  /** Indicates an error during power dialer execution */
  powerDialerFailed: PowerDialerFailedView;
  /** Latest power dialer state to display for a given campaign */
  powerDialerStateUpdated: PowerDialerStateUpdatedView;
  /** Session updated event */
  sessionUpdated: Session;
  /** Skiptrace completed event */
  skiptraceCompleted: SkiptraceCompleted;
  /** Skiptrace progress updated event */
  skiptraceProgress: SkiptraceProgress;
  /** Unread conversations messages counter updated event */
  unreadConversationsMessagesCounterUpdated: UnreadConversationsCounterUpdatedView;
};

export type SubscriptionMessageCreatedArgs = {
  contactId: Scalars['String']['input'];
};

export type SubscriptionMessageUpdatedArgs = {
  contactId: Scalars['String']['input'];
};

export type SubscriptionMissedConversationsCallsCounterUpdatedArgs = {
  profileId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionUnreadConversationsMessagesCounterUpdatedArgs = {
  profileId?: InputMaybe<Scalars['String']['input']>;
};

/** Subscription status */
export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  INCOMPLETE = 'INCOMPLETE',
  PAST_DUE = 'PAST_DUE',
  TRIALING = 'TRIALING',
}

/** Subscription summary */
export type SubscriptionSummary = {
  __typename?: 'SubscriptionSummary';
  total?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};

/** Subscriptions summary */
export type SubscriptionsSummary = {
  __typename?: 'SubscriptionsSummary';
  dialer: Array<SubscriptionSummary>;
  elite: Array<SubscriptionSummary>;
  inactiveDialer: Array<InactiveAccountSummary>;
  inactiveMessaging: Array<InactiveAccountSummary>;
  messaging: Array<SubscriptionSummary>;
};

/** Suppression phone */
export type Suppression = {
  __typename?: 'Suppression';
  createdAt: Scalars['DateTime']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  source: SuppressionSource;
};

export type SuppressionList = {
  __typename?: 'SuppressionList';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  items: Array<Suppression>;
  nextCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  nextId?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Suppression source */
export enum SuppressionSource {
  CARRIER_BLOCKED = 'CARRIER_BLOCKED',
  DNC = 'DNC',
  FILTERED = 'FILTERED',
  UNDELIVERABLE = 'UNDELIVERABLE',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}

export type SuppressionsFilter = {
  source?: InputMaybe<SuppressionSource>;
};

/** Suppressions summary */
export type SuppressionsSummary = {
  __typename?: 'SuppressionsSummary';
  carrierBlocked?: Maybe<Scalars['Int']['output']>;
  dnc?: Maybe<Scalars['Int']['output']>;
  filtered?: Maybe<Scalars['Int']['output']>;
  undeliverable?: Maybe<Scalars['Int']['output']>;
  unsubscribed?: Maybe<Scalars['Int']['output']>;
};

export type SyncHubspotProfilesInput = {
  fields?: InputMaybe<Array<HubSpotProfileField>>;
};

/** Time range */
export type TimeRange = {
  __typename?: 'TimeRange';
  from: HourMinuteView;
  to: HourMinuteView;
};

export type TimeRangeInput = {
  from: HourMinuteInput;
  to: HourMinuteInput;
};

/** Toll-free brand */
export type TollFreeBrand = {
  __typename?: 'TollFreeBrand';
  address1: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  country: Scalars['String']['output'];
  description: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  messageVolume: Scalars['String']['output'];
  optInWorkflow: Scalars['String']['output'];
  optInWorkflowFiles: Array<BrandRegistrationFile>;
  phone: Scalars['String']['output'];
  sampleMessage: Scalars['String']['output'];
  state: Scalars['String']['output'];
  usecase: TollFreeBrandUseCase;
  website: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type TollFreeBrandInput = {
  address1: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  country: Scalars['String']['input'];
  description: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  messageVolume: Scalars['String']['input'];
  optInWorkflow: Scalars['String']['input'];
  optInWorkflowFiles: Array<OptInWorkflowFileInput>;
  phone: Scalars['String']['input'];
  sampleMessage: Scalars['String']['input'];
  state: Scalars['String']['input'];
  usecase: TollFreeBrandUseCase;
  website: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

/** Toll-free brand use case */
export enum TollFreeBrandUseCase {
  APPOINTMENTS = 'APPOINTMENTS',
  APP_NOTIFICATIONS = 'APP_NOTIFICATIONS',
  AUCTIONS = 'AUCTIONS',
  AUTO_REPAIR_SERVICE = 'AUTO_REPAIR_SERVICE',
  BANK_TRANSFERS = 'BANK_TRANSFERS',
  BILLING = 'BILLING',
  BOOKING_CONFIRMATIONS = 'BOOKING_CONFIRMATIONS',
  BUSINESS_UPDATES = 'BUSINESS_UPDATES',
  CAREER_TRAINING = 'CAREER_TRAINING',
  CHATBOT = 'CHATBOT',
  CONVERSATIONAL_OR_ALERTS = 'CONVERSATIONAL_OR_ALERTS',
  COURIER_SERVICCESE_AND_DELIVERIES = 'COURIER_SERVICCESE_AND_DELIVERIES',
  COVID_19_ALERTS = 'COVID_19_ALERTS',
  EMERGENCY_ALERTS = 'EMERGENCY_ALERTS',
  EVENTS_AND_PLANING = 'EVENTS_AND_PLANING',
  FINANCIAL_SERVICES = 'FINANCIAL_SERVICES',
  FRAUD_ALERTS = 'FRAUD_ALERTS',
  FUNDRAISING = 'FUNDRAISING',
  GENERAL_MARKETING = 'GENERAL_MARKETING',
  GENERAL_SCHOOL_UPDATES = 'GENERAL_SCHOOL_UPDATES',
  HEALTHCARE_ALERTS = 'HEALTHCARE_ALERTS',
  HOUSING_COMMUNITY_UPDATES = 'HOUSING_COMMUNITY_UPDATES',
  HR_OR_STAFFING = 'HR_OR_STAFFING',
  INSURANCE_SERVICES = 'INSURANCE_SERVICES',
  JOB_DISPATCH = 'JOB_DISPATCH',
  LEGAL_SERVICES = 'LEGAL_SERVICES',
  MIXED = 'MIXED',
  MOTIVATIONAL_REMINDERS = 'MOTIVATIONAL_REMINDERS',
  NOTARY_NOTIFICATIONS = 'NOTARY_NOTIFICATIONS',
  ORDER_NOTIFICATIONS = 'ORDER_NOTIFICATIONS',
  POLITICAL = 'POLITICAL',
  PUBLIC_WORKS = 'PUBLIC_WORKS',
  REAL_ESTATE_SERVICES = 'REAL_ESTATE_SERVICES',
  RELIGIOUS_SERVICES = 'RELIGIOUS_SERVICES',
  REPAIR_AND_DIAGNOSTICS_ALERTS = 'REPAIR_AND_DIAGNOSTICS_ALERTS',
  REWARDS_PROGRAM = 'REWARDS_PROGRAM',
  SURVEYS = 'SURVEYS',
  SYSTEM_ALERTS = 'SYSTEM_ALERTS',
  TWO_FACTOR_AUTH = 'TWO_FACTOR_AUTH',
  VOTING_REMINDERS = 'VOTING_REMINDERS',
  WAITLIST_ALERTS = 'WAITLIST_ALERTS',
  WEBINAR_REMINDERS = 'WEBINAR_REMINDERS',
  WORKSHOP_ALERTS = 'WORKSHOP_ALERTS',
}

/** Toll-Free brand registration general info */
export type TollFreeInfo = {
  __typename?: 'TollFreeInfo';
  limits: TollFreeInfoLimits;
  maxTpm: Scalars['Float']['output'];
  status: TollFreeInfoStatus;
};

/** Toll-Free brand registration limits */
export type TollFreeInfoLimits = {
  __typename?: 'TollFreeInfoLimits';
  daily: Scalars['Float']['output'];
  monthly: Scalars['Float']['output'];
  weekly: Scalars['Float']['output'];
};

/** Toll-brand registration general status */
export enum TollFreeInfoStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  NONE = 'NONE',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
}

/** Toll-free registration all info */
export type TollFreeRegistration = {
  __typename?: 'TollFreeRegistration';
  brand: TollFreeBrand;
  ownPhone?: Maybe<OwnPhone>;
};

export type TopUpAccountBalanceInput = {
  accountId: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
};

export type TopUpBalanceInput = {
  amount: Scalars['Int']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
};

/** Unread conversation counter for GQL subscription. It might contain unread messages or missed calls depending on specific subscription */
export type UnreadConversationsCounterUpdatedView = {
  __typename?: 'UnreadConversationsCounterUpdatedView';
  counter: Scalars['Float']['output'];
  profileId: Scalars['String']['output'];
};

/** Unread conversation counters */
export type UnreadConversationsCountersView = {
  __typename?: 'UnreadConversationsCountersView';
  withMissedCalls?: Maybe<Scalars['Float']['output']>;
  withUnreadMessages?: Maybe<Scalars['Float']['output']>;
};

/** Upcoming invoice */
export type UpcomingInvoice = {
  __typename?: 'UpcomingInvoice';
  totalBilledNow: Scalars['Money']['output'];
};

export type UpdateA2PBrandCampaignInput = {
  description: Scalars['String']['input'];
  messageFlow: Scalars['String']['input'];
  sample1: Scalars['String']['input'];
  sample2: Scalars['String']['input'];
  sendLinks: Scalars['Boolean']['input'];
  sendPhones: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  usecase: A2PBrandCampaignUseCase;
};

export type UpdateA2PRegistrationExternalDataInput = {
  brandId?: InputMaybe<Scalars['String']['input']>;
  campaignId: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type UpdateA2PRegistrationInfoInput = {
  brand?: InputMaybe<A2PBrandInput>;
  campaign?: InputMaybe<UpdateA2PBrandCampaignInput>;
};

export type UpdateAccountInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  defaultLabelColor?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  openNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  refreshRate?: InputMaybe<Scalars['Int']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  viewedPopup?: InputMaybe<AccountSettingsPopup>;
};

export type UpdateAccountSubscriptionInput = {
  /** Plan ID that should be updated */
  planId?: InputMaybe<Scalars['String']['input']>;
  /** Subscription status */
  status?: InputMaybe<SubscriptionStatus>;
  /** Subscription ID */
  subscriptionId: Scalars['String']['input'];
};

export type UpdateCalendarEventInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  relationId?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCallScriptInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCampaignInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownPhones: Array<Scalars['String']['input']>;
  rule?: InputMaybe<CampaignRuleInput>;
  scheduleOptions?: InputMaybe<ScheduleOptionsInput>;
  sendFirstMessage?: InputMaybe<Scalars['Boolean']['input']>;
  sequences?: InputMaybe<Array<CampaignSequenceInput>>;
};

export type UpdateCampaignOwnPhonesInput = {
  ownPhones: Array<Scalars['String']['input']>;
};

export type UpdateContactInput = {
  attrs?: InputMaybe<Array<ContactAttributesInput>>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  dealClosed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  favorite?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  realEstateAttrs?: InputMaybe<Array<ContactRealEstateAttributesInput>>;
};

export type UpdateContactNoteInput = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateConversationOutgoingPhoneInput = {
  id: Scalars['String']['input'];
  outgoingPhone: Scalars['String']['input'];
};

export type UpdateCrmIntegrationInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<CrmProviderInput>;
  status?: InputMaybe<CrmIntegrationStatus>;
};

export type UpdateDeviceInput = {
  appVersion: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  platform: DevicePlatform;
  token: Scalars['String']['input'];
};

export type UpdateDialerCampaignInput = {
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  mode?: InputMaybe<CallMode>;
  name: Scalars['String']['input'];
  phones?: InputMaybe<Array<Scalars['String']['input']>>;
  scriptId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFieldInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFieldsTemplateInput = {
  fields: Array<InputMaybe<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupInput = {
  name: Scalars['String']['input'];
};

export type UpdateKeywordTemplateInput = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<KeywordTemplateTemplateInput>;
};

export type UpdateLabelInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateListInput = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  options?: InputMaybe<UpdateListOptionsInput>;
};

export type UpdateListItemInput = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateListOptionsInput = {
  removeCarrierBlocked: Scalars['Boolean']['input'];
  removeExisting: Scalars['Boolean']['input'];
  removeFiltered: Scalars['Boolean']['input'];
  removeGlobalUnsubscribed: Scalars['Boolean']['input'];
  removeInternalDnc: Scalars['Boolean']['input'];
  removeLitigators: Scalars['Boolean']['input'];
  removeNationalDnc: Scalars['Boolean']['input'];
  removeNonSmsCapable: Scalars['Boolean']['input'];
  removeUndeliverable: Scalars['Boolean']['input'];
  removeUnsubscribed: Scalars['Boolean']['input'];
};

export type UpdateMacroInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMarketingPopupInput = {
  audiences?: InputMaybe<Array<MarketingPopupAudience>>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<MarketingPopupStatus>;
  template?: InputMaybe<MarketingPopupTemplateInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMessageTemplateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<MessageTemplateTemplateInput>;
};

export type UpdateMessagingProfileInput = {
  callSettings?: InputMaybe<CallSettingsInput>;
  phoneSettings?: InputMaybe<PhoneSettingsInput>;
  responseRemoval?: InputMaybe<ResponseRemovalInput>;
};

export type UpdateNotificationSettingsInput = {
  enabledCalendar?: InputMaybe<Scalars['Boolean']['input']>;
  enabledCampaigns?: InputMaybe<Scalars['Boolean']['input']>;
  enabledPayments?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateScheduledMessageInput = {
  templateId: Scalars['String']['input'];
};

export type UpdateSkiptraceInput = {
  excludeFirstRow: Scalars['Boolean']['input'];
  fileName: Scalars['String']['input'];
};

export type UpdateSubaccountInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  products?: InputMaybe<Array<Product>>;
  status: AccountStatus;
};

export type UpdateSubscriptionInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['String']['input'];
};

export type UpdateTollFreeRegistrationInfoInput = {
  brand: TollFreeBrandInput;
};

export type UploadDncPhonesInput = {
  filePath: Scalars['String']['input'];
};

export type UploadEinFileInput = {
  contentType: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
};

export type VerifyPromoCodeInput = {
  code: Scalars['String']['input'];
  planId: Scalars['String']['input'];
};

/** Version */
export type Version = {
  __typename?: 'Version';
  version: Scalars['String']['output'];
};

/** WebRTC Token for web client */
export type WebRtcToken = {
  __typename?: 'WebRTCToken';
  expiresAt: Scalars['DateTime']['output'];
  token: Scalars['String']['output'];
};

export type A2PBrandFragment = {
  __typename?: 'A2PBrand';
  companyName: string;
  vertical: A2PBrandVertical;
  ein: string;
  einIssuingCountry: string;
  country: string;
  state: string;
  city: string;
  street: string;
  zip: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  website?: string | null;
  createWebsite: boolean;
  customerCarePhone?: string | null;
  customerCareEmail?: string | null;
  termsAndConditionsUrl?: string | null;
  incorporationDate?: string | null;
  einFile?: {
    __typename?: 'BrandRegistrationFile';
    filePath: string;
    contentType: string;
    url: string;
  } | null;
} & { ' $fragmentName'?: 'A2PBrandFragment' };

export type A2PBrandCampaignFragment = {
  __typename?: 'A2PBrandCampaign';
  title: string;
  description: string;
  usecase: A2PBrandCampaignUseCase;
  sample1: string;
  sample2: string;
  messageFlow: string;
  sendPhones: boolean;
  sendLinks: boolean;
  sendDirectLending: boolean;
  sendAgeGatedContent: boolean;
  sendAffiliateMarketing: boolean;
  sendOptIn: boolean;
  optInKeywords: Array<string>;
  optInMessage: string;
  sendOptOut: boolean;
  optOutKeywords: Array<string>;
  optOutMessage: string;
  sendHelp: boolean;
  helpKeywords: Array<string>;
  helpMessage: string;
} & { ' $fragmentName'?: 'A2PBrandCampaignFragment' };

export type GetA2PRegistrationInfoQueryVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type GetA2PRegistrationInfoQuery = {
  __typename?: 'Query';
  getA2PRegistrationInfo: {
    __typename?: 'A2PRegistration';
    brand: { __typename?: 'A2PBrand' } & {
      ' $fragmentRefs'?: { A2PBrandFragment: A2PBrandFragment };
    };
    campaign: { __typename?: 'A2PBrandCampaign' } & {
      ' $fragmentRefs'?: { A2PBrandCampaignFragment: A2PBrandCampaignFragment };
    };
    ownPhone?:
      | ({ __typename?: 'OwnPhone' } & {
          ' $fragmentRefs'?: { OwnPhoneFragment: OwnPhoneFragment };
        })
      | null;
  };
};

export type UpdateA2PRegistrationInfoMutationVariables = Exact<{
  input: UpdateA2PRegistrationInfoInput;
  accountId: Scalars['String']['input'];
}>;

export type UpdateA2PRegistrationInfoMutation = {
  __typename?: 'Mutation';
  updateA2PRegistrationInfo: {
    __typename?: 'A2PRegistration';
    brand: { __typename?: 'A2PBrand' } & {
      ' $fragmentRefs'?: { A2PBrandFragment: A2PBrandFragment };
    };
    campaign: { __typename?: 'A2PBrandCampaign' } & {
      ' $fragmentRefs'?: { A2PBrandCampaignFragment: A2PBrandCampaignFragment };
    };
    ownPhone?:
      | ({ __typename?: 'OwnPhone' } & {
          ' $fragmentRefs'?: { OwnPhoneFragment: OwnPhoneFragment };
        })
      | null;
  };
};

export type RegisterA2PMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type RegisterA2PMutation = {
  __typename?: 'Mutation';
  registerA2P: { __typename?: 'OperationStatus'; success: boolean };
};

export type RegisterSubAccountA2PMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type RegisterSubAccountA2PMutation = {
  __typename?: 'Mutation';
  registerSubAccountA2P: { __typename?: 'OperationStatus'; success: boolean };
};

export type ReactivateA2PMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type ReactivateA2PMutation = {
  __typename?: 'Mutation';
  reactivateA2P: { __typename?: 'OperationStatus'; success: boolean };
};

export type GetA2PReactivationInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetA2PReactivationInfoQuery = {
  __typename?: 'Query';
  getA2PReactivationInfo: {
    __typename?: 'A2PRegistration';
    brand: { __typename?: 'A2PBrand' } & {
      ' $fragmentRefs'?: { A2PBrandFragment: A2PBrandFragment };
    };
    campaign: { __typename?: 'A2PBrandCampaign' } & {
      ' $fragmentRefs'?: { A2PBrandCampaignFragment: A2PBrandCampaignFragment };
    };
    ownPhone?:
      | ({ __typename?: 'OwnPhone' } & {
          ' $fragmentRefs'?: { OwnPhoneFragment: OwnPhoneFragment };
        })
      | null;
  };
};

export type GetEinFileUploadSignedUrlQueryVariables = Exact<{
  input: GenerateSignedUrlInput;
}>;

export type GetEinFileUploadSignedUrlQuery = {
  __typename?: 'Query';
  getEINFileUploadSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type SubmitA2PReactivationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SubmitA2PReactivationMutation = {
  __typename?: 'Mutation';
  submitA2PReactivation: { __typename?: 'OperationStatus'; success: boolean };
};

export type SubmitA2PRegistrationMutationVariables = Exact<{
  input: SubmitA2PRegistrationInput;
}>;

export type SubmitA2PRegistrationMutation = {
  __typename?: 'Mutation';
  submitA2PRegistration: { __typename?: 'OperationStatus'; success: boolean };
};

export type SubmitA2PSubAccountRegistrationMutationVariables = Exact<{
  input: SubmitA2PSubAccountRegistrationInput;
}>;

export type SubmitA2PSubAccountRegistrationMutation = {
  __typename?: 'Mutation';
  submitA2PSubAccountRegistration: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type GetOptInWorkflowFileSignedUrlQueryVariables = Exact<{
  input: GenerateSignedUrlInput;
}>;

export type GetOptInWorkflowFileSignedUrlQuery = {
  __typename?: 'Query';
  getOptInWorkflowFileSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type SubmitTollFreeRegistrationMutationVariables = Exact<{
  input: TollFreeBrandInput;
}>;

export type SubmitTollFreeRegistrationMutation = {
  __typename?: 'Mutation';
  submitTollFreeRegistration: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type UploadEinFileMutationVariables = Exact<{
  input: UploadEinFileInput;
}>;

export type UploadEinFileMutation = {
  __typename?: 'Mutation';
  uploadEINFile: { __typename?: 'OperationStatus'; success: boolean };
};

export type SubAccountFragmentFragment = {
  __typename?: 'Account';
  id: string;
  firestoreId?: string | null;
  ownerId: string;
  status: AccountStatus;
  createdAt: string;
  type: AccountType;
  email: string;
  products: Array<Product>;
  secondarySubscriptions?: Array<{
    __typename?: 'CurrentSubscription';
    title: string;
    product: Product;
    price: number;
    discount?: {
      __typename?: 'Discount';
      amount: number;
      coupon: string;
    } | null;
  }> | null;
  name?: {
    __typename?: 'Name';
    firstName?: string | null;
    lastName?: string | null;
  } | null;
} & { ' $fragmentName'?: 'SubAccountFragmentFragment' };

export type BillingProfileFragment = {
  __typename?: 'BillingProfile';
  id: string;
  email: string;
  externalId?: string | null;
  unsubscribeCoupons?: Array<string> | null;
  backupPaymentMethod: boolean;
  balance?: {
    __typename?: 'Balance';
    balance: number;
    topUpAmount: number;
    topUpBonus: number;
    firstTopUpBonusApplied: boolean;
    lastTopUp?: {
      __typename?: 'LastTopUp';
      amount: number;
      bonus?: number | null;
      topUpAt: string;
    } | null;
    schedule?: {
      __typename?: 'BalanceSchedule';
      amount: number;
      limit: number;
      enabled: boolean;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'BillingProfileFragment' };

export type AccountFragmentFragment = {
  __typename?: 'Account';
  id: string;
  firestoreId?: string | null;
  ownerId: string;
  status: AccountStatus;
  type: AccountType;
  email: string;
  companyName?: string | null;
  products: Array<Product>;
  createdAt: string;
  timeZone?: string | null;
  dialerStatus: DialerStatus;
  isOnboarded: boolean;
  isTest: boolean;
  inBusinessHours: boolean;
  secondaryPlans?: Array<
    { __typename?: 'Plan' } & {
      ' $fragmentRefs'?: { PlanFragment: PlanFragment };
    }
  > | null;
  name?: {
    __typename?: 'Name';
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  affiliate?: {
    __typename?: 'Affiliate';
    id: string;
    referralId: string;
    referralLink: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  billingProfile?:
    | ({ __typename?: 'BillingProfile' } & {
        ' $fragmentRefs'?: { BillingProfileFragment: BillingProfileFragment };
      })
    | null;
  subAccounts?: Array<
    { __typename?: 'Account' } & {
      ' $fragmentRefs'?: {
        SubAccountFragmentFragment: SubAccountFragmentFragment;
      };
    }
  > | null;
  questionnaire?: {
    __typename?: 'AccountQuestionnaire';
    realEstate?: string | null;
    employees?: string | null;
    role?: string | null;
    industry?: string | null;
    experience?: string | null;
    marketingChannels?: Array<string> | null;
    marketingTraining?: string | null;
    smsMarketing?: string | null;
    provider?: string | null;
  } | null;
  settings?: {
    __typename?: 'AccountSettings';
    refreshRate?: number | null;
    openNewTab?: boolean | null;
    defaultLabelColor?: string | null;
    viewedPopups?: Array<string> | null;
  } | null;
  subscription?:
    | ({ __typename?: 'CurrentSubscription' } & {
        ' $fragmentRefs'?: { SubscriptionFragment: SubscriptionFragment };
      })
    | null;
  subscriptions?: Array<
    { __typename?: 'CurrentSubscription' } & {
      ' $fragmentRefs'?: { SubscriptionFragment: SubscriptionFragment };
    }
  > | null;
  messagingProfile?:
    | ({ __typename?: 'MessagingProfile' } & {
        ' $fragmentRefs'?: {
          MessagingProfileFragment: MessagingProfileFragment;
        };
      })
    | null;
  quotasUsage: Array<{
    __typename?: 'QuotaUsage';
    used: number;
    type: QuotaType;
  }>;
  features?: Array<{
    __typename?: 'Feature';
    type: FeatureType;
    title: string;
    price?: number | null;
    tier?: number | null;
    limit?: number | null;
    min?: number | null;
    product?: Product | null;
  }> | null;
  permissions: {
    __typename?: 'AccountPermissionsView';
    optOutDisabled: boolean;
    allowOptOutDisable: boolean;
    allowCrmIntegrations: boolean;
    allowDripCampaign: boolean;
    allowKeywordCampaign: boolean;
    allowScheduleCampaign: boolean;
    allowThrottleCampaign: boolean;
  };
} & { ' $fragmentName'?: 'AccountFragmentFragment' };

export type AccountQueryVariables = Exact<{ [key: string]: never }>;

export type AccountQuery = {
  __typename?: 'Query';
  account: { __typename?: 'Account'; isSubAccount: boolean } & {
    ' $fragmentRefs'?: { AccountFragmentFragment: AccountFragmentFragment };
  };
};

export type AccountBillingSourcesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AccountBillingSourcesQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    isSubAccount: boolean;
    billingProfile?:
      | ({
          __typename?: 'BillingProfile';
          sources?: Array<
            { __typename?: 'PaymentSource' } & {
              ' $fragmentRefs'?: {
                PaymentSourceFragment: PaymentSourceFragment;
              };
            }
          > | null;
        } & {
          ' $fragmentRefs'?: { BillingProfileFragment: BillingProfileFragment };
        })
      | null;
  } & {
    ' $fragmentRefs'?: { AccountFragmentFragment: AccountFragmentFragment };
  };
};

export type CreateImpersonateTokenMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type CreateImpersonateTokenMutation = {
  __typename?: 'Mutation';
  createImpersonateToken: { __typename?: 'ImpersonateToken'; token: string };
};

export type UpdateAccountMutationVariables = Exact<{
  data: UpdateAccountInput;
}>;

export type UpdateAccountMutation = {
  __typename?: 'Mutation';
  updateAccount: { __typename?: 'Account' } & {
    ' $fragmentRefs'?: { AccountFragmentFragment: AccountFragmentFragment };
  };
};

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;

export type SignUpMutation = {
  __typename?: 'Mutation';
  signUp: { __typename?: 'Account'; id: string };
};

export type CreateSubaccountMutationVariables = Exact<{
  input: CreateSubaccountInput;
}>;

export type CreateSubaccountMutation = {
  __typename?: 'Mutation';
  createSubaccount: { __typename?: 'Account' } & {
    ' $fragmentRefs'?: {
      SubAccountFragmentFragment: SubAccountFragmentFragment;
    };
  };
};

export type UpdateSubaccountMutationVariables = Exact<{
  input: UpdateSubaccountInput;
  id: Scalars['String']['input'];
}>;

export type UpdateSubaccountMutation = {
  __typename?: 'Mutation';
  updateSubaccount: { __typename?: 'Account' } & {
    ' $fragmentRefs'?: {
      SubAccountFragmentFragment: SubAccountFragmentFragment;
    };
  };
};

export type RemoveSubaccountMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveSubaccountMutation = {
  __typename?: 'Mutation';
  removeSubaccount: { __typename?: 'OperationStatus'; success: boolean };
};

export type AccountUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type AccountUpdatedSubscription = {
  __typename?: 'Subscription';
  accountUpdated: {
    __typename?: 'ChangesItemView';
    entityId: string;
    operation: ChangesOperation;
    operationAt: string;
  };
};

export type ResendPasswordResetTokenMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ResendPasswordResetTokenMutation = {
  __typename?: 'Mutation';
  resendPasswordResetToken: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type ConfirmPasswordResetMutationVariables = Exact<{
  token: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type ConfirmPasswordResetMutation = {
  __typename?: 'Mutation';
  confirmPasswordReset: { __typename?: 'OperationStatus'; success: boolean };
};

export type AcceptTermsAndConditionsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type AcceptTermsAndConditionsMutation = {
  __typename?: 'Mutation';
  acceptTermsAndConditions: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type AccountOutsideBusinessHoursRestrictionsSubscriptionVariables =
  Exact<{ [key: string]: never }>;

export type AccountOutsideBusinessHoursRestrictionsSubscription = {
  __typename?: 'Subscription';
  outsideBusinessHoursRestrictions: {
    __typename?: 'AccountOutsideBusinessHoursRestrictionsView';
    accountId: string;
    restriction: OutsideBusinessHoursRestrictionType;
  };
};

export type GetAccountsCountersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountsCountersQuery = {
  __typename?: 'Query';
  getAccountsCounters: {
    __typename?: 'AccountCounters';
    activePrimary?: number | null;
    activeSecondary?: number | null;
    trial?: number | null;
    inactive?: number | null;
    monthNewActive?: number | null;
    plans: Array<{
      __typename?: 'AccountCountersPlan';
      total?: number | null;
      type: PlanType;
    }>;
  };
};

export type GetAccountsSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountsSummaryQuery = {
  __typename?: 'Query';
  getAccountsSummary: {
    __typename?: 'AccountsSummary';
    products: Array<{
      __typename?: 'ProductSummary';
      activePrimary?: number | null;
      activeSecondary?: number | null;
      inactive?: number | null;
      monthNewActive?: number | null;
      product: Product;
      trial?: number | null;
    }>;
    subscriptions: {
      __typename?: 'SubscriptionsSummary';
      dialer: Array<{
        __typename?: 'SubscriptionSummary';
        total?: number | null;
        type: string;
      }>;
      elite: Array<{
        __typename?: 'SubscriptionSummary';
        total?: number | null;
        type: string;
      }>;
      messaging: Array<{
        __typename?: 'SubscriptionSummary';
        total?: number | null;
        type: string;
      }>;
      inactiveMessaging: Array<{
        __typename?: 'InactiveAccountSummary';
        total?: number | null;
      }>;
      inactiveDialer: Array<{
        __typename?: 'InactiveAccountSummary';
        total?: number | null;
      }>;
    };
  };
};

export type GetStatisticsQueryVariables = Exact<{ [key: string]: never }>;

export type GetStatisticsQuery = {
  __typename?: 'Query';
  getStatistics: {
    __typename?: 'MessagingProvidersCounters';
    infobip: {
      __typename?: 'MessagingProviderCounters';
      weeklySmsUsage?: number | null;
      deliveryRate?: number | null;
      approved?: number | null;
      declined?: number | null;
      pending?: number | null;
    };
    telnyx: {
      __typename?: 'MessagingProviderCounters';
      weeklySmsUsage?: number | null;
      deliveryRate?: number | null;
      approved?: number | null;
      declined?: number | null;
      pending?: number | null;
    };
    twilio: {
      __typename?: 'MessagingProviderCounters';
      weeklySmsUsage?: number | null;
      deliveryRate?: number | null;
      approved?: number | null;
      declined?: number | null;
      pending?: number | null;
    };
    commio: {
      __typename?: 'MessagingProviderCounters';
      weeklySmsUsage?: number | null;
      deliveryRate?: number | null;
      approved?: number | null;
      declined?: number | null;
      pending?: number | null;
    };
  };
};

export type ExportAccountsQueryVariables = Exact<{
  password: Scalars['String']['input'];
  filter?: InputMaybe<AccountsFilter>;
}>;

export type ExportAccountsQuery = {
  __typename?: 'Query';
  exportAccounts: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type AdminAccountFragmentFragment = {
  __typename?: 'Account';
  id: string;
  ownerId: string;
  status: AccountStatus;
  type: AccountType;
  email: string;
  phone?: string | null;
  isSubAccount: boolean;
  createdAt: string;
  isTest: boolean;
  isOnboarded: boolean;
  firestoreId?: string | null;
  companyName?: string | null;
  timeZone?: string | null;
  a2PStatus?: A2PInfoStatus | null;
  tollFreeStatus?: TollFreeInfoStatus | null;
  permissions: {
    __typename?: 'AccountPermissionsView';
    optOutDisabled: boolean;
    allowOptOutDisable: boolean;
    allowCrmIntegrations: boolean;
    allowDripCampaign: boolean;
    allowKeywordCampaign: boolean;
    allowScheduleCampaign: boolean;
    allowThrottleCampaign: boolean;
  };
  settings?: {
    __typename?: 'AccountSettings';
    refreshRate?: number | null;
    openNewTab?: boolean | null;
  } | null;
  name?: {
    __typename?: 'Name';
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  questionnaire?: {
    __typename?: 'AccountQuestionnaire';
    realEstate?: string | null;
    experience?: string | null;
    marketingChannels?: Array<string> | null;
    marketingTraining?: string | null;
    smsMarketing?: string | null;
    provider?: string | null;
    employees?: string | null;
    role?: string | null;
    industry?: string | null;
  } | null;
  affiliate?: {
    __typename?: 'Affiliate';
    id: string;
    referralId: string;
    referralLink: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    createdAt: string;
  } | null;
  subscription?: {
    __typename?: 'CurrentSubscription';
    id: string;
    endAt?: string | null;
    membershipMonths?: number | null;
    trialEndedAt?: string | null;
    startedAt?: string | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      title: string;
      userTitle: string;
      type: PlanType;
    } | null;
  } | null;
  subscriptions?: Array<{
    __typename?: 'CurrentSubscription';
    id: string;
    title: string;
    product: Product;
    status: SubscriptionStatus;
    price: number;
    nextBillingDate?: string | null;
    startedAt?: string | null;
    endAt?: string | null;
    updatedAt?: string | null;
    trialEndedAt?: string | null;
    membershipMonths?: number | null;
    reason?: string | null;
    base: boolean;
    secondary: boolean;
    discount?: {
      __typename?: 'Discount';
      amount: number;
      coupon: string;
      percent?: number | null;
      promoCode?: string | null;
      startedAt?: string | null;
      expiresAt?: string | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      secondary: boolean;
      product: Product;
      active: boolean;
      enterprise: boolean;
      base: boolean;
      default: boolean;
      type: PlanType;
      interval: BillingInterval;
      title: string;
      userTitle: string;
      price: number;
      displayPrice: number;
      discount: number;
      mostPopular: boolean;
      monthlyPrice?: number | null;
      couponId?: string | null;
      features: Array<{
        __typename?: 'PlanFeature';
        type: FeatureType;
        title: string;
        tier?: number | null;
        limit?: number | null;
        min?: number | null;
        price?: number | null;
        trialLimits: {
          __typename?: 'PlanFeatureTrial';
          use: boolean;
          tier?: number | null;
          limit?: number | null;
        };
      }>;
    } | null;
  }> | null;
  messagingProfile?: {
    __typename?: 'MessagingProfile';
    id: string;
    allowUrl: boolean;
    a2pInfo?: {
      __typename?: 'A2PInfo';
      maxTpm: number;
      status: A2PInfoStatus;
      campaignId?: string | null;
      brandId?: string | null;
      statusUpdatedAt?: string | null;
      incorporationDate?: string | null;
    } | null;
    tollFreeInfo?: {
      __typename?: 'TollFreeInfo';
      status: TollFreeInfoStatus;
      maxTpm: number;
      limits: {
        __typename?: 'TollFreeInfoLimits';
        daily: number;
        weekly: number;
        monthly: number;
      };
    } | null;
    provider?: {
      __typename?: 'MessagingProvider';
      type: MessagingProviderType;
    } | null;
  } | null;
  billingProfile?: {
    __typename?: 'BillingProfile';
    balance?: {
      __typename?: 'Balance';
      balance: number;
      topUpAmount: number;
      lastTopUp?: {
        __typename?: 'LastTopUp';
        topUpAt: string;
        amount: number;
      } | null;
    } | null;
  } | null;
  summary: {
    __typename?: 'AccountSummary';
    totalSmsUsed?: number | null;
    totalSkiptraceUsed?: number | null;
    totalMembershipFees?: number | null;
    totalBilledToDate?: number | null;
    totalBilled?: number | null;
    productsFees?: Array<{
      __typename?: 'ProfileProductFees';
      amount: number;
      product: Product;
    }> | null;
    lastBilling?: {
      __typename?: 'LastBilling';
      amount: number;
      createdAt: string;
    } | null;
    trialPeriod?: {
      __typename?: 'Period';
      endedAt?: string | null;
      startedAt: string;
    } | null;
  };
} & { ' $fragmentName'?: 'AdminAccountFragmentFragment' };

export type FindAccountsQueryVariables = Exact<{
  filter?: InputMaybe<AccountsFilter>;
  pagination?: InputMaybe<Pagination>;
  order?: InputMaybe<Order>;
}>;

export type FindAccountsQuery = {
  __typename?: 'Query';
  findAccounts: {
    __typename?: 'AccountList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'Account' } & {
        ' $fragmentRefs'?: {
          AdminAccountFragmentFragment: AdminAccountFragmentFragment;
        };
      }
    >;
  };
};

export type AdminUpdateAccountMutationVariables = Exact<{
  data: AdminUpdateAccountInput;
  id: Scalars['String']['input'];
}>;

export type AdminUpdateAccountMutation = {
  __typename?: 'Mutation';
  adminUpdateAccount: { __typename?: 'Account'; id: string };
};

export type AdminDeletePhoneMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AdminDeletePhoneMutation = {
  __typename?: 'Mutation';
  adminDeletePhone: { __typename?: 'Account'; id: string };
};

export type AdminRemoveAccountMutationVariables = Exact<{
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type AdminRemoveAccountMutation = {
  __typename?: 'Mutation';
  adminRemoveAccount: { __typename?: 'OperationStatus'; success: boolean };
};

export type GetAccountQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetAccountQuery = {
  __typename?: 'Query';
  getAccount: {
    __typename?: 'Account';
    parentAccount?: {
      __typename?: 'Account';
      id: string;
      ownerId: string;
      status: AccountStatus;
      type: AccountType;
      email: string;
      isSubAccount: boolean;
      createdAt: string;
      isTest: boolean;
      isOnboarded: boolean;
      firestoreId?: string | null;
      companyName?: string | null;
      timeZone?: string | null;
      a2PStatus?: A2PInfoStatus | null;
      tollFreeStatus?: TollFreeInfoStatus | null;
      phone?: string | null;
    } | null;
    subAccounts?: Array<{ __typename?: 'Account'; id: string }> | null;
  } & {
    ' $fragmentRefs'?: {
      AdminAccountFragmentFragment: AdminAccountFragmentFragment;
    };
  };
};

export type AdminUpdatePasswordMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AdminUpdatePasswordMutation = {
  __typename?: 'Mutation';
  adminUpdatePassword: { __typename?: 'AccountPassword'; password: string };
};

export type AdminTopUpBalanceMutationVariables = Exact<{
  input: TopUpAccountBalanceInput;
}>;

export type AdminTopUpBalanceMutation = {
  __typename?: 'Mutation';
  adminTopUpBalance: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateAccountSubscriptionMutationVariables = Exact<{
  input: UpdateSubscriptionInput;
  subscriptionId: Scalars['String']['input'];
  accountId: Scalars['String']['input'];
}>;

export type UpdateAccountSubscriptionMutation = {
  __typename?: 'Mutation';
  updateAccountSubscription: {
    __typename?: 'CurrentSubscription';
    id: string;
    endAt?: string | null;
    membershipMonths?: number | null;
    trialEndedAt?: string | null;
    startedAt?: string | null;
    plan?: {
      __typename?: 'Plan';
      id: string;
      userTitle: string;
      type: PlanType;
    } | null;
  };
};

export type AdminChangeAccountMessagingProviderMutationVariables = Exact<{
  input: AdminChangeAccountMessagingProviderInput;
}>;

export type AdminChangeAccountMessagingProviderMutation = {
  __typename?: 'Mutation';
  adminChangeAccountMessagingProvider: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type AdminChangeSubAccountMessagingProviderMutationVariables = Exact<{
  input: AdminChangeSubAccountMessagingProviderInput;
}>;

export type AdminChangeSubAccountMessagingProviderMutation = {
  __typename?: 'Mutation';
  adminChangeSubAccountMessagingProvider: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type UpdateA2PRegistrationExternalDataMutationVariables = Exact<{
  input: UpdateA2PRegistrationExternalDataInput;
  accountId: Scalars['String']['input'];
}>;

export type UpdateA2PRegistrationExternalDataMutation = {
  __typename?: 'Mutation';
  updateA2PRegistrationExternalData: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type AdminAddOwnPhoneMutationVariables = Exact<{
  input: AdminAddOwnPhoneInput;
}>;

export type AdminAddOwnPhoneMutation = {
  __typename?: 'Mutation';
  adminAddOwnPhone: { __typename?: 'OperationStatus'; success: boolean };
};

export type RegisterAffiliateMutationVariables = Exact<{
  input: RegisterAffiliateInput;
}>;

export type RegisterAffiliateMutation = {
  __typename?: 'Mutation';
  registerAffiliate: { __typename?: 'Affiliate' } & {
    ' $fragmentRefs'?: { AffiliateFragment: AffiliateFragment };
  };
};

export type RegisterAffiliateLeadMutationVariables = Exact<{
  input: AdminRegisterAffiliateLeadInput;
}>;

export type RegisterAffiliateLeadMutation = {
  __typename?: 'Mutation';
  registerAffiliateLead: { __typename?: 'Affiliate' } & {
    ' $fragmentRefs'?: { AffiliateFragment: AffiliateFragment };
  };
};

export type AffiliateFragment = {
  __typename?: 'Affiliate';
  id: string;
  referralId: string;
  referralLink: string;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  createdAt: string;
} & { ' $fragmentName'?: 'AffiliateFragment' };

export type AffiliateQueryVariables = Exact<{ [key: string]: never }>;

export type AffiliateQuery = {
  __typename?: 'Query';
  affiliate: { __typename?: 'Affiliate' } & {
    ' $fragmentRefs'?: { AffiliateFragment: AffiliateFragment };
  };
};

export type FindAffiliatesQueryVariables = Exact<{
  filter?: InputMaybe<AffiliateFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindAffiliatesQuery = {
  __typename?: 'Query';
  findAffiliates: {
    __typename?: 'AffiliateList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'Affiliate' } & {
        ' $fragmentRefs'?: { AffiliateFragment: AffiliateFragment };
      }
    >;
  };
};

export type GetAffiliateQueryVariables = Exact<{
  getAffiliateId: Scalars['String']['input'];
}>;

export type GetAffiliateQuery = {
  __typename?: 'Query';
  getAffiliate: { __typename?: 'Affiliate' } & {
    ' $fragmentRefs'?: { AffiliateFragment: AffiliateFragment };
  };
};

export type RemoveAffiliateMutationVariables = Exact<{
  removeAffiliateId: Scalars['String']['input'];
}>;

export type RemoveAffiliateMutation = {
  __typename?: 'Mutation';
  removeAffiliate: { __typename?: 'OperationStatus'; success: boolean };
};

export type ArticleFragment = {
  __typename?: 'Article';
  id: string;
  title: string;
  url: string;
  createdAt: string;
  updatedAt: string;
} & { ' $fragmentName'?: 'ArticleFragment' };

export type FindArticlesQueryVariables = Exact<{ [key: string]: never }>;

export type FindArticlesQuery = {
  __typename?: 'Query';
  findArticles: Array<
    { __typename?: 'Article' } & {
      ' $fragmentRefs'?: { ArticleFragment: ArticleFragment };
    }
  >;
};

export type PaymentFragment = {
  __typename?: 'Payment';
  invoiceUrl?: string | null;
  id: string;
  feature: PaymentFeature;
  description?: string | null;
  createdAt: string;
  amount?: number | null;
  type: PaymentType;
  method: PaymentMethod;
  purpose: PaymentPurpose;
  status: PaymentStatus;
} & { ' $fragmentName'?: 'PaymentFragment' };

export type PaymentSourceFragment = {
  __typename?: 'PaymentSource';
  id: string;
  brand?: string | null;
  expMonth?: number | null;
  expYear?: number | null;
  last4?: string | null;
  default?: boolean | null;
} & { ' $fragmentName'?: 'PaymentSourceFragment' };

export type DownloadBillingReportQueryVariables = Exact<{
  filter?: InputMaybe<PaymentsFilter>;
}>;

export type DownloadBillingReportQuery = {
  __typename?: 'Query';
  downloadBillingReport: { __typename?: 'BillingReport'; url: string };
};

export type FindPaymentsQueryVariables = Exact<{
  filter?: InputMaybe<PaymentsFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindPaymentsQuery = {
  __typename?: 'Query';
  findPayments: {
    __typename?: 'PaymentList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'Payment' } & {
        ' $fragmentRefs'?: { PaymentFragment: PaymentFragment };
      }
    >;
  };
};

export type FindLatestPaymentQueryVariables = Exact<{
  filter?: InputMaybe<PaymentsFilter>;
}>;

export type FindLatestPaymentQuery = {
  __typename?: 'Query';
  findPayments: {
    __typename?: 'PaymentList';
    items: Array<{ __typename?: 'Payment'; createdAt: string }>;
  };
};

export type TopUpBalanceMutationVariables = Exact<{
  input: TopUpBalanceInput;
}>;

export type TopUpBalanceMutation = {
  __typename?: 'Mutation';
  topUpBalance: { __typename?: 'OperationStatus'; success: boolean };
};

export type SetDefaultPaymentSourceMutationVariables = Exact<{
  input: PaymentSourceInput;
}>;

export type SetDefaultPaymentSourceMutation = {
  __typename?: 'Mutation';
  setDefaultPaymentSource: { __typename?: 'OperationStatus'; success: boolean };
};

export type AddPaymentSourceMutationVariables = Exact<{
  input: PaymentSourceInput;
}>;

export type AddPaymentSourceMutation = {
  __typename?: 'Mutation';
  addPaymentSource: { __typename?: 'OperationStatus'; success: boolean };
};

export type RemovePaymentSourceMutationVariables = Exact<{
  input: PaymentSourceInput;
}>;

export type RemovePaymentSourceMutation = {
  __typename?: 'Mutation';
  removePaymentSource: { __typename?: 'OperationStatus'; success: boolean };
};

export type ScheduleBalanceMutationVariables = Exact<{
  input: ScheduleBalanceInput;
}>;

export type ScheduleBalanceMutation = {
  __typename?: 'Mutation';
  scheduleBalance: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateBillingProfileMutationVariables = Exact<{
  backupPaymentMethod: Scalars['Boolean']['input'];
}>;

export type UpdateBillingProfileMutation = {
  __typename?: 'Mutation';
  updateBillingProfile: { __typename?: 'OperationStatus'; success: boolean };
};

export type PayBillMutationVariables = Exact<{
  input: PayBillInput;
}>;

export type PayBillMutation = {
  __typename?: 'Mutation';
  payBill: { __typename?: 'OperationStatus'; success: boolean };
};

export type CalendarEventFragment = {
  __typename?: 'CalendarEvent';
  id: string;
  type: CalendarEventType;
  title: string;
  endAt: string;
  startAt: string;
  relationId?: string | null;
  description?: string | null;
} & { ' $fragmentName'?: 'CalendarEventFragment' };

export type FindClosestCalendarEventForRelationQueryVariables = Exact<{
  relationId: Scalars['String']['input'];
}>;

export type FindClosestCalendarEventForRelationQuery = {
  __typename?: 'Query';
  findClosestCalendarEventForRelation?: {
    __typename?: 'CalendarEvent';
    id: string;
    title: string;
    startAt: string;
    description?: string | null;
  } | null;
};

export type FindCalendarEventsQueryVariables = Exact<{
  filter?: InputMaybe<CalendarEventsFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindCalendarEventsQuery = {
  __typename?: 'Query';
  findCalendarEvents: {
    __typename?: 'CalendarEventList';
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    total?: number | null;
    items: Array<
      { __typename?: 'CalendarEvent' } & {
        ' $fragmentRefs'?: { CalendarEventFragment: CalendarEventFragment };
      }
    >;
  };
};

export type CreateCalendarEventMutationVariables = Exact<{
  input: CreateCalendarEventInput;
}>;

export type CreateCalendarEventMutation = {
  __typename?: 'Mutation';
  createCalendarEvent: { __typename?: 'CalendarEvent' } & {
    ' $fragmentRefs'?: { CalendarEventFragment: CalendarEventFragment };
  };
};

export type UpdateCalendarEventMutationVariables = Exact<{
  input: UpdateCalendarEventInput;
  id: Scalars['String']['input'];
}>;

export type UpdateCalendarEventMutation = {
  __typename?: 'Mutation';
  updateCalendarEvent: { __typename?: 'CalendarEvent' } & {
    ' $fragmentRefs'?: { CalendarEventFragment: CalendarEventFragment };
  };
};

export type DeleteCalendarEventMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteCalendarEventMutation = {
  __typename?: 'Mutation';
  removeCalendarEvent: { __typename?: 'OperationStatus'; success: boolean };
};

export type GetCurrentCallQueryVariables = Exact<{
  contactId: Scalars['String']['input'];
}>;

export type GetCurrentCallQuery = {
  __typename?: 'Query';
  getCurrentCall: { __typename?: 'Message'; id: string };
};

export type ManageCallRecordingMutationVariables = Exact<{
  input: ManageCallRecordingInput;
}>;

export type ManageCallRecordingMutation = {
  __typename?: 'Mutation';
  manageCallRecording: { __typename?: 'OperationStatus'; success: boolean };
};

export type CallScriptFragment = {
  __typename?: 'CallScript';
  id: string;
  name: string;
  content: string;
  createdAt: string;
} & { ' $fragmentName'?: 'CallScriptFragment' };

export type FindCallScriptsQueryVariables = Exact<{
  filter?: InputMaybe<CallScriptsFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindCallScriptsQuery = {
  __typename?: 'Query';
  findCallScripts: {
    __typename?: 'CallScriptList';
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    total?: number | null;
    items: Array<
      { __typename?: 'CallScript' } & {
        ' $fragmentRefs'?: { CallScriptFragment: CallScriptFragment };
      }
    >;
  };
};

export type GetCallScriptQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetCallScriptQuery = {
  __typename?: 'Query';
  getCallScript: { __typename?: 'CallScript' } & {
    ' $fragmentRefs'?: { CallScriptFragment: CallScriptFragment };
  };
};

export type CreateCallScriptMutationVariables = Exact<{
  input: CreateCallScriptInput;
}>;

export type CreateCallScriptMutation = {
  __typename?: 'Mutation';
  createCallScript: { __typename?: 'CallScript' } & {
    ' $fragmentRefs'?: { CallScriptFragment: CallScriptFragment };
  };
};

export type UpdateCallScriptMutationVariables = Exact<{
  input: UpdateCallScriptInput;
  updateCallScriptId: Scalars['String']['input'];
}>;

export type UpdateCallScriptMutation = {
  __typename?: 'Mutation';
  updateCallScript: { __typename?: 'CallScript' } & {
    ' $fragmentRefs'?: { CallScriptFragment: CallScriptFragment };
  };
};

export type RemoveCallScriptMutationVariables = Exact<{
  removeCallScriptId: Scalars['String']['input'];
}>;

export type RemoveCallScriptMutation = {
  __typename?: 'Mutation';
  removeCallScript: { __typename?: 'OperationStatus'; success: boolean };
};

export type RankCallScriptMutationVariables = Exact<{
  input: RankCallScriptInput;
  rankCallScriptId: Scalars['String']['input'];
}>;

export type RankCallScriptMutation = {
  __typename?: 'Mutation';
  rankCallScript: { __typename?: 'OperationStatus'; success: boolean };
};

export type SequenceFragmentFragment = {
  __typename?: 'SequenceView';
  type: SequenceType;
  delay: number;
  templates: Array<string>;
  content?: string | null;
  days: Array<number>;
  hasCustomTimeRestrictions: boolean;
  timeRange: {
    __typename?: 'TimeRange';
    from: { __typename?: 'HourMinuteView'; hour: number; minute: number };
    to: { __typename?: 'HourMinuteView'; hour: number; minute: number };
  };
  rule?: {
    __typename?: 'SequenceRule';
    removeFromNextSequences?: boolean | null;
    strategy?: CampaignRuleStrategy | null;
    groupId?: string | null;
    labelId?: string | null;
  } | null;
  progress?: {
    __typename?: 'SequenceProgress';
    sent: number;
    total: number;
  } | null;
} & { ' $fragmentName'?: 'SequenceFragmentFragment' };

export type StateFragmentFragment = {
  __typename?: 'CampaignState';
  status: CampaignStatus;
  reason?: {
    __typename?: 'StateReason';
    type: CampaignStateReasonType;
    ttl?: string | null;
  } | null;
} & { ' $fragmentName'?: 'StateFragmentFragment' };

export type KeywordTemplateFragmentFragment = {
  __typename?: 'KeywordTemplate';
  id: string;
  keyword: string;
  template: {
    __typename?: 'KeywordTemplateTemplate';
    content?: string | null;
    optOut?: boolean | null;
  };
} & { ' $fragmentName'?: 'KeywordTemplateFragmentFragment' };

export type GroupFragmentFragment = {
  __typename?: 'GroupView';
  id: string;
  name: string;
  verified: boolean;
  createdAt: string;
  contacts: number;
  activeChats: number;
  canBeCloned: boolean;
  canBeRemoved: boolean;
} & { ' $fragmentName'?: 'GroupFragmentFragment' };

export type CostFragmentFragment = {
  __typename?: 'CampaignCosts';
  captured: number;
  refunded: number;
} & { ' $fragmentName'?: 'CostFragmentFragment' };

export type StatsFragmentFragment = {
  __typename?: 'CampaignStats';
  smsUsed?: number | null;
  smsRefunded?: number | null;
} & { ' $fragmentName'?: 'StatsFragmentFragment' };

export type ScheduleOptionsFragment = {
  __typename?: 'CampaignScheduleOptions';
  startAt?: string | null;
  perBatch?: number | null;
  frequency?: Frequency | null;
  interval?: number | null;
  hasCustomBatchSize?: boolean | null;
  hasCustomStartDate?: boolean | null;
  timezone?: string | null;
} & { ' $fragmentName'?: 'ScheduleOptionsFragment' };

export type CampaignFragmentFragment = {
  __typename?: 'Campaign';
  id: string;
  type: CampaignType;
  name: string;
  clickThroughProgress?: number | null;
  createdAt: string;
  firestoreId?: string | null;
  lastSentAt?: string | null;
  contacts: number;
  sendFirstMessage?: boolean | null;
  expiresAt?: string | null;
  clickThroughStartAfter?: {
    __typename?: 'ClickThroughStartAfterView';
    id: string;
    createdAt: string;
  } | null;
  ownPhones: Array<{
    __typename?: 'OwnPhone';
    id: string;
    status: OwnPhoneStatus;
    type: OwnPhoneType;
    number: string;
    isTrial: boolean;
    createdAt: string;
    location?: {
      __typename?: 'PhoneNumberLocation';
      abbreviation: string;
      state: string;
    } | null;
  }>;
  state: { __typename?: 'CampaignState' } & {
    ' $fragmentRefs'?: { StateFragmentFragment: StateFragmentFragment };
  };
  sequences: Array<
    { __typename?: 'SequenceView' } & {
      ' $fragmentRefs'?: { SequenceFragmentFragment: SequenceFragmentFragment };
    }
  >;
  groups?: Array<
    { __typename?: 'GroupView' } & {
      ' $fragmentRefs'?: { GroupFragmentFragment: GroupFragmentFragment };
    }
  > | null;
  keyword?:
    | ({ __typename?: 'KeywordTemplate' } & {
        ' $fragmentRefs'?: {
          KeywordTemplateFragmentFragment: KeywordTemplateFragmentFragment;
        };
      })
    | null;
  costs?:
    | ({ __typename?: 'CampaignCosts' } & {
        ' $fragmentRefs'?: { CostFragmentFragment: CostFragmentFragment };
      })
    | null;
  stats?:
    | ({ __typename?: 'CampaignStats' } & {
        ' $fragmentRefs'?: { StatsFragmentFragment: StatsFragmentFragment };
      })
    | null;
  scheduleOptions: { __typename?: 'CampaignScheduleOptions' } & {
    ' $fragmentRefs'?: { ScheduleOptionsFragment: ScheduleOptionsFragment };
  };
  rule?: {
    __typename?: 'CampaignRule';
    removeFromNextSequences?: boolean | null;
    strategy?: CampaignRuleStrategy | null;
    groupId?: string | null;
    labelId?: string | null;
  } | null;
} & { ' $fragmentName'?: 'CampaignFragmentFragment' };

export type CampaignScheduledMessageFragment = {
  __typename?: 'ScheduledMessage';
  id: string;
  content?: string | null;
  createdAt: string;
  scheduledAt?: string | null;
  template?: {
    __typename?: 'MessageTemplate';
    name: string;
    id: string;
    template: {
      __typename?: 'MessageTemplateTemplate';
      optOut?: boolean | null;
    };
  } | null;
  contact?: {
    __typename?: 'Contact';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    phone: string;
    email?: string | null;
    attrs?: Array<{
      __typename?: 'ContactAttribute';
      fieldId: string;
      value: string;
    }> | null;
  } | null;
} & { ' $fragmentName'?: 'CampaignScheduledMessageFragment' };

export type FindCampaignsQueryVariables = Exact<{
  filter?: InputMaybe<CampaignFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindCampaignsQuery = {
  __typename?: 'Query';
  findCampaigns: {
    __typename?: 'CampaignList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'Campaign' } & {
        ' $fragmentRefs'?: {
          CampaignFragmentFragment: CampaignFragmentFragment;
        };
      }
    >;
  };
};

export type FindListCampaignsQueryVariables = Exact<{
  filter?: InputMaybe<CampaignFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindListCampaignsQuery = {
  __typename?: 'Query';
  findCampaigns: {
    __typename?: 'CampaignList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'Campaign' } & {
        ' $fragmentRefs'?: {
          CampaignFragmentFragment: CampaignFragmentFragment;
        };
      }
    >;
  };
};

export type GetCampaignsTotalQueryVariables = Exact<{
  filter?: InputMaybe<CampaignFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type GetCampaignsTotalQuery = {
  __typename?: 'Query';
  findCampaigns: { __typename?: 'CampaignList'; total?: number | null };
};

export type GetCampaignsCountersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCampaignsCountersQuery = {
  __typename?: 'Query';
  getCampaignsCounters: {
    __typename?: 'CampaignsAggregationView';
    keyword: {
      __typename?: 'CampaignsAggregationCountersView';
      active: number;
      draft: number;
      deleted: number;
      total: number;
    };
    standard: {
      __typename?: 'CampaignsAggregationCountersView';
      active: number;
      draft: number;
      deleted: number;
      total: number;
    };
  };
};

export type GetCampaignQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetCampaignQuery = {
  __typename?: 'Query';
  getCampaign: { __typename?: 'Campaign' } & {
    ' $fragmentRefs'?: { CampaignFragmentFragment: CampaignFragmentFragment };
  };
};

export type ExportCampaignToFileQueryVariables = Exact<{
  input: ExportCampaignToFileInput;
}>;

export type ExportCampaignToFileQuery = {
  __typename?: 'Query';
  exportCampaignToFile: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type GetCampaignScheduleQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<CampaignScheduleOptionsInput>;
}>;

export type GetCampaignScheduleQuery = {
  __typename?: 'Query';
  getCampaignSchedule: {
    __typename?: 'CampaignSchedule';
    startTime: number;
    endTime: number;
    nextTime?: number | null;
  };
};

export type FindCampaignMessagesQueryVariables = Exact<{
  filter?: InputMaybe<ScheduledMessageFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindCampaignMessagesQuery = {
  __typename?: 'Query';
  findCampaignMessages: {
    __typename?: 'ScheduledMessageList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'ScheduledMessage' } & {
        ' $fragmentRefs'?: {
          CampaignScheduledMessageFragment: CampaignScheduledMessageFragment;
        };
      }
    >;
  };
};

export type CreateCampaignMutationVariables = Exact<{
  input: CreateCampaignInput;
}>;

export type CreateCampaignMutation = {
  __typename?: 'Mutation';
  createCampaign: { __typename?: 'Campaign' } & {
    ' $fragmentRefs'?: { CampaignFragmentFragment: CampaignFragmentFragment };
  };
};

export type UpdateCampaignMutationVariables = Exact<{
  input: UpdateCampaignInput;
  id: Scalars['String']['input'];
}>;

export type UpdateCampaignMutation = {
  __typename?: 'Mutation';
  updateCampaign: { __typename?: 'Campaign' } & {
    ' $fragmentRefs'?: { CampaignFragmentFragment: CampaignFragmentFragment };
  };
};

export type RemoveCampaignMutationVariables = Exact<{
  input: RemoveCampaignInput;
}>;

export type RemoveCampaignMutation = {
  __typename?: 'Mutation';
  removeCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type ExportCampaignToGroupQueryVariables = Exact<{
  input: ExportCampaignToGroupInput;
  id: Scalars['String']['input'];
}>;

export type ExportCampaignToGroupQuery = {
  __typename?: 'Query';
  exportCampaignToGroup: { __typename?: 'GroupView'; id: string };
};

export type StartCampaignMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type StartCampaignMutation = {
  __typename?: 'Mutation';
  startCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type PauseCampaignMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type PauseCampaignMutation = {
  __typename?: 'Mutation';
  pauseCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type ResumeCampaignMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type ResumeCampaignMutation = {
  __typename?: 'Mutation';
  resumeCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateCampaignDynamicDataMutationVariables = Exact<{
  input: CampaignDynamicDataInput;
  id: Scalars['String']['input'];
}>;

export type UpdateCampaignDynamicDataMutation = {
  __typename?: 'Mutation';
  updateCampaignDynamicData: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type UpdateScheduledMessageMutationVariables = Exact<{
  input: UpdateScheduledMessageInput;
  id: Scalars['String']['input'];
}>;

export type UpdateScheduledMessageMutation = {
  __typename?: 'Mutation';
  updateScheduledMessage: { __typename?: 'ScheduledMessage' } & {
    ' $fragmentRefs'?: {
      CampaignScheduledMessageFragment: CampaignScheduledMessageFragment;
    };
  };
};

export type RefreshCampaignMessagesMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RefreshCampaignMessagesMutation = {
  __typename?: 'Mutation';
  refreshCampaignMessages: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateCampaignOwnPhonesMutationVariables = Exact<{
  input: UpdateCampaignOwnPhonesInput;
  updateCampaignOwnPhonesId: Scalars['String']['input'];
}>;

export type UpdateCampaignOwnPhonesMutation = {
  __typename?: 'Mutation';
  updateCampaignOwnPhones: { __typename?: 'OperationStatus'; success: boolean };
};

export type CampaignUpsertedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type CampaignUpsertedSubscription = {
  __typename?: 'Subscription';
  campaignUpserted: {
    __typename?: 'ChangesItemView';
    operation: ChangesOperation;
    entityId: string;
  };
};

export type CampaignProgressUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type CampaignProgressUpdatedSubscription = {
  __typename?: 'Subscription';
  campaignProgressUpdated: {
    __typename?: 'CampaignProgressUpdated';
    campaignId: string;
    progress: Array<{
      __typename?: 'SequenceProgress';
      sent: number;
      total: number;
    }>;
  };
};

export type ContactNoteFragment = {
  __typename?: 'ContactNote';
  id: string;
  text: string;
  attachments?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
} & { ' $fragmentName'?: 'ContactNoteFragment' };

export type GetContactNoteSignedUrlQueryVariables = Exact<{
  input: GenerateSignedUrlInput;
}>;

export type GetContactNoteSignedUrlQuery = {
  __typename?: 'Query';
  getContactNoteSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type FindContactNotesQueryVariables = Exact<{
  filter: ContactNoteFilter;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindContactNotesQuery = {
  __typename?: 'Query';
  findContactNotes: {
    __typename?: 'ContactNoteList';
    hasNext?: boolean | null;
    nextCreatedAt?: string | null;
    total?: number | null;
    nextId?: string | null;
    items: Array<
      { __typename?: 'ContactNote' } & {
        ' $fragmentRefs'?: { ContactNoteFragment: ContactNoteFragment };
      }
    >;
  };
};

export type CreateContactNoteMutationVariables = Exact<{
  input: CreateContactNoteInput;
  contactId: Scalars['String']['input'];
}>;

export type CreateContactNoteMutation = {
  __typename?: 'Mutation';
  createContactNote: { __typename?: 'ContactNote' } & {
    ' $fragmentRefs'?: { ContactNoteFragment: ContactNoteFragment };
  };
};

export type UpdateContactNoteMutationVariables = Exact<{
  input: UpdateContactNoteInput;
  noteId: Scalars['String']['input'];
  contactId: Scalars['String']['input'];
}>;

export type UpdateContactNoteMutation = {
  __typename?: 'Mutation';
  updateContactNote: { __typename?: 'ContactNote' } & {
    ' $fragmentRefs'?: { ContactNoteFragment: ContactNoteFragment };
  };
};

export type RemoveContactNoteMutationVariables = Exact<{
  noteId: Scalars['String']['input'];
  contactId: Scalars['String']['input'];
}>;

export type RemoveContactNoteMutation = {
  __typename?: 'Mutation';
  removeContactNote: { __typename?: 'OperationStatus'; success: boolean };
};

export type ContactAttributeFragment = {
  __typename?: 'ContactAttribute';
  fieldId: string;
  value: string;
} & { ' $fragmentName'?: 'ContactAttributeFragment' };

export type RealEstateAttrsFragment = {
  __typename?: 'ContactRealEstateAttribute';
  id: RealEstateAttribute;
  value: string;
} & { ' $fragmentName'?: 'RealEstateAttrsFragment' };

export type ContactFragment = {
  __typename?: 'Contact';
  id: string;
  status: ContactStatus;
  createdAt: string;
  phone: string;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  favorite?: boolean | null;
  blocked?: boolean | null;
  unsubscribed?: boolean | null;
  dealClosed?: boolean | null;
  labels?: Array<
    { __typename?: 'Label' } & {
      ' $fragmentRefs'?: { LabelFragment: LabelFragment };
    }
  > | null;
  groups?: Array<{ __typename?: 'GroupView'; id: string; name: string }> | null;
  attrs?: Array<
    { __typename?: 'ContactAttribute' } & {
      ' $fragmentRefs'?: { ContactAttributeFragment: ContactAttributeFragment };
    }
  > | null;
  realEstateAttrs?: {
    __typename?: 'ContactRealEstateAttributes';
    provider: string;
    attrs: Array<
      { __typename?: 'ContactRealEstateAttribute' } & {
        ' $fragmentRefs'?: { RealEstateAttrsFragment: RealEstateAttrsFragment };
      }
    >;
  } | null;
} & { ' $fragmentName'?: 'ContactFragment' };

export type ContactPreviewFragment = {
  __typename?: 'Contact';
  id: string;
  createdAt: string;
  phone: string;
  firstName?: string | null;
  lastName?: string | null;
  favorite?: boolean | null;
  dealClosed?: boolean | null;
  canBeRemoved: boolean;
  status: ContactStatus;
  labels?: Array<{
    __typename?: 'Label';
    id: string;
    title: string;
    color: string;
    createdAt: string;
    default: boolean;
    firestoreId: string;
    readOnly: boolean;
    scopes: Array<Scope>;
  }> | null;
  groups?: Array<{ __typename?: 'GroupView'; id: string; name: string }> | null;
} & { ' $fragmentName'?: 'ContactPreviewFragment' };

export type GetContactsCountersQueryVariables = Exact<{ [key: string]: never }>;

export type GetContactsCountersQuery = {
  __typename?: 'Query';
  getContactsCounters: {
    __typename?: 'ContactCountersView';
    activeGroups: number;
    activeContacts: number;
    deletedContacts: number;
  };
};

export type FindContactsQueryVariables = Exact<{
  filter?: InputMaybe<ContactFilter>;
  pagination?: InputMaybe<Pagination>;
  order?: InputMaybe<ContactOrder>;
}>;

export type FindContactsQuery = {
  __typename?: 'Query';
  findContacts: {
    __typename?: 'ContactList';
    hasNext?: boolean | null;
    nextCreatedAt?: string | null;
    total?: number | null;
    nextId?: string | null;
    items: Array<
      { __typename?: 'Contact' } & {
        ' $fragmentRefs'?: { ContactPreviewFragment: ContactPreviewFragment };
      }
    >;
  };
};

export type CountContactsQueryVariables = Exact<{
  filter?: InputMaybe<ContactFilter>;
  pagination?: InputMaybe<Pagination>;
  order?: InputMaybe<ContactOrder>;
}>;

export type CountContactsQuery = {
  __typename?: 'Query';
  findContacts: { __typename?: 'ContactList'; total?: number | null };
};

export type FindContactsPhonesQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filter?: InputMaybe<ContactFilter>;
}>;

export type FindContactsPhonesQuery = {
  __typename?: 'Query';
  findContacts: {
    __typename?: 'ContactList';
    hasNext?: boolean | null;
    nextCreatedAt?: string | null;
    total?: number | null;
    nextId?: string | null;
    items: Array<{
      __typename?: 'Contact';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      phone: string;
    }>;
  };
};

export type GetContactQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetContactQuery = {
  __typename?: 'Query';
  getContact: { __typename?: 'Contact' } & {
    ' $fragmentRefs'?: { ContactFragment: ContactFragment };
  };
};

export type GetContactPhoneQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetContactPhoneQuery = {
  __typename?: 'Query';
  getContact: {
    __typename?: 'Contact';
    phone: string;
    firstName?: string | null;
    lastName?: string | null;
  };
};

export type GetContactNotesQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetContactNotesQuery = {
  __typename?: 'Query';
  getContact: {
    __typename?: 'Contact';
    notes: Array<
      { __typename?: 'ContactNote' } & {
        ' $fragmentRefs'?: { ContactNoteFragment: ContactNoteFragment };
      }
    >;
  };
};

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;

export type CreateContactMutation = {
  __typename?: 'Mutation';
  createContact: { __typename?: 'Contact' } & {
    ' $fragmentRefs'?: { ContactFragment: ContactFragment };
  };
};

export type UpdateContactMutationVariables = Exact<{
  input: UpdateContactInput;
  id: Scalars['String']['input'];
}>;

export type UpdateContactMutation = {
  __typename?: 'Mutation';
  updateContact: { __typename?: 'Contact' } & {
    ' $fragmentRefs'?: { ContactFragment: ContactFragment };
  };
};

export type RecoverContactsMutationVariables = Exact<{
  input: RecoverContactsInput;
}>;

export type RecoverContactsMutation = {
  __typename?: 'Mutation';
  recoverContacts: { __typename?: 'OperationStatus'; success: boolean };
};

export type RemoveContactsMutationVariables = Exact<{
  input: RemoveContactsInput;
}>;

export type RemoveContactsMutation = {
  __typename?: 'Mutation';
  removeContacts: { __typename?: 'OperationStatus'; success: boolean };
};

export type BlockContactsMutationVariables = Exact<{
  input: BlockContactsInput;
}>;

export type BlockContactsMutation = {
  __typename?: 'Mutation';
  blockContacts: { __typename?: 'OperationStatus'; success: boolean };
};

export type AddLabelsToContactsMutationVariables = Exact<{
  input: AddLabelsToContactsInput;
}>;

export type AddLabelsToContactsMutation = {
  __typename?: 'Mutation';
  addLabelsToContacts: Array<{
    __typename?: 'Contact';
    id: string;
    firstName?: string | null;
    email?: string | null;
    lastName?: string | null;
    labels?: Array<{
      __typename?: 'Label';
      title: string;
      id: string;
      color: string;
      createdAt: string;
      default: boolean;
      firestoreId: string;
    }> | null;
  }>;
};

export type RemoveLabelsFromContactsMutationVariables = Exact<{
  input: RemoveLabelsFromContactsInput;
}>;

export type RemoveLabelsFromContactsMutation = {
  __typename?: 'Mutation';
  removeLabelsFromContacts: Array<{
    __typename?: 'Contact';
    firstName?: string | null;
    lastName?: string | null;
    id: string;
    labels?: Array<{
      __typename?: 'Label';
      title: string;
      id: string;
      color: string;
      createdAt: string;
      default: boolean;
      firestoreId: string;
    }> | null;
  }>;
};

export type SingleMessageCampaignFragment = {
  __typename?: 'Campaign';
  id: string;
  name: string;
  type: CampaignType;
} & { ' $fragmentName'?: 'SingleMessageCampaignFragment' };

export type SingleMessageTemplateFragment = {
  __typename?: 'MessageTemplate';
  id: string;
  name: string;
  characters?: number | null;
  createdAt: string;
  template: {
    __typename?: 'MessageTemplateTemplate';
    audioFilePath?: string | null;
  };
} & { ' $fragmentName'?: 'SingleMessageTemplateFragment' };

export type SingleMessageKeywordFragment = {
  __typename?: 'KeywordTemplate';
  id: string;
  keyword: string;
} & { ' $fragmentName'?: 'SingleMessageKeywordFragment' };

export type AttachmentFragment = {
  __typename?: 'MessageAttachment';
  path: string;
  url?: string | null;
  contentType?: string | null;
} & { ' $fragmentName'?: 'AttachmentFragment' };

export type MessageFragment = {
  __typename?: 'Message';
  id: string;
  status: MessageStatus;
  direction: MessageDirection;
  type: MessageType;
  createdAt: string;
  content?: string | null;
  contentType?: MessageContentType | null;
  duration?: number | null;
  isConversation?: boolean | null;
  isManualRecording?: boolean | null;
  attachments?: Array<
    { __typename?: 'MessageAttachment' } & {
      ' $fragmentRefs'?: { AttachmentFragment: AttachmentFragment };
    }
  > | null;
  campaign?:
    | ({ __typename?: 'Campaign' } & {
        ' $fragmentRefs'?: {
          SingleMessageCampaignFragment: SingleMessageCampaignFragment;
        };
      })
    | null;
  template?:
    | ({ __typename?: 'MessageTemplate' } & {
        ' $fragmentRefs'?: {
          SingleMessageTemplateFragment: SingleMessageTemplateFragment;
        };
      })
    | null;
  keyword?:
    | ({ __typename?: 'KeywordTemplate' } & {
        ' $fragmentRefs'?: {
          SingleMessageKeywordFragment: SingleMessageKeywordFragment;
        };
      })
    | null;
  dialerCampaign?: { __typename?: 'DialerCampaign'; name: string } | null;
  fromNumber?: { __typename?: 'PhoneNumber'; number: string } | null;
} & { ' $fragmentName'?: 'MessageFragment' };

export type LastMessageFragment = {
  __typename?: 'Message';
  id: string;
  status: MessageStatus;
  direction: MessageDirection;
  type: MessageType;
  content?: string | null;
  contentType?: MessageContentType | null;
} & { ' $fragmentName'?: 'LastMessageFragment' };

export type ConversationFragment = {
  __typename?: 'Conversation';
  id: string;
  profileId: string;
  isFavorite?: boolean | null;
  isRead?: boolean | null;
  isAiFiltered?: boolean | null;
  name?: string | null;
  lastMessageAt?: string | null;
  updatedAt: string;
  unreadMessages?: number | null;
  phone: string;
  createdAt: string;
  canBeRemoved: boolean;
  lastMessage?:
    | ({ __typename?: 'Message' } & {
        ' $fragmentRefs'?: { LastMessageFragment: LastMessageFragment };
      })
    | null;
  labels?: Array<{
    __typename?: 'Label';
    id: string;
    title: string;
    color: string;
  }> | null;
} & { ' $fragmentName'?: 'ConversationFragment' };

export type FindConversationsQueryVariables = Exact<{
  filter?: InputMaybe<ConversationFilter>;
  pagination?: InputMaybe<Pagination>;
  order?: InputMaybe<ConversationOrder>;
}>;

export type FindConversationsQuery = {
  __typename?: 'Query';
  findConversations: {
    __typename?: 'ConversationList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'Conversation' } & {
        ' $fragmentRefs'?: { ConversationFragment: ConversationFragment };
      }
    >;
  };
};

export type FindConversationMessagesQueryVariables = Exact<{
  contactId: Scalars['String']['input'];
  filter?: InputMaybe<MessageFilter>;
  pagination?: InputMaybe<Pagination>;
  order?: InputMaybe<MessageOrder>;
}>;

export type FindConversationMessagesQuery = {
  __typename?: 'Query';
  findConversationMessages: {
    __typename?: 'MessageList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'Message' } & {
        ' $fragmentRefs'?: { MessageFragment: MessageFragment };
      }
    >;
  };
};

export type FindScheduledMessagesQueryVariables = Exact<{
  filter?: InputMaybe<ScheduledMessageFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindScheduledMessagesQuery = {
  __typename?: 'Query';
  findScheduledMessages: {
    __typename?: 'ScheduledMessageList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'ScheduledMessage';
      id: string;
      createdAt: string;
      scheduledAt?: string | null;
      content?: string | null;
      attachments?: Array<
        { __typename?: 'MessageAttachment' } & {
          ' $fragmentRefs'?: { AttachmentFragment: AttachmentFragment };
        }
      > | null;
    }>;
  };
};

export type GetMessageAttachmentSignedUrlQueryVariables = Exact<{
  input: GetMessageAttachmentSignedUrlInput;
}>;

export type GetMessageAttachmentSignedUrlQuery = {
  __typename?: 'Query';
  getMessageAttachmentSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type GetConversationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetConversationQuery = {
  __typename?: 'Query';
  getConversation: {
    __typename?: 'Conversation';
    id: string;
    lastMessageAt?: string | null;
    isFavorite?: boolean | null;
    isRead?: boolean | null;
    isUnsubscribed?: boolean | null;
    status: ConversationStatus;
    name?: string | null;
    isBlocked?: boolean | null;
    phone: string;
    outgoingPhone?: string | null;
    lastMessage?:
      | ({ __typename?: 'Message' } & {
          ' $fragmentRefs'?: { LastMessageFragment: LastMessageFragment };
        })
      | null;
    labels?: Array<{
      __typename?: 'Label';
      id: string;
      title: string;
      color: string;
    }> | null;
    campaigns?: Array<{
      __typename?: 'Campaign';
      id: string;
      name: string;
    }> | null;
    ownPhone?: {
      __typename?: 'OwnPhone';
      id: string;
      type: OwnPhoneType;
      number: string;
      location?: {
        __typename?: 'PhoneNumberLocation';
        abbreviation: string;
        state: string;
      } | null;
    } | null;
  };
};

export type GetConversationOwnPhoneQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetConversationOwnPhoneQuery = {
  __typename?: 'Query';
  getConversation: {
    __typename?: 'Conversation';
    ownPhone?: {
      __typename?: 'OwnPhone';
      id: string;
      status: OwnPhoneStatus;
      type: OwnPhoneType;
      number: string;
      isTrial: boolean;
      messagingEnabled: boolean;
      callingEnabled: boolean;
    } | null;
  };
};

export type GetUnreadConversationsCountersQueryVariables = Exact<{
  profileId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetUnreadConversationsCountersQuery = {
  __typename?: 'Query';
  getUnreadConversationsCounters: {
    __typename?: 'UnreadConversationsCountersView';
    withUnreadMessages?: number | null;
    withMissedCalls?: number | null;
  };
};

export type CreateConversationMutationVariables = Exact<{
  input: CreateConversationInput;
}>;

export type CreateConversationMutation = {
  __typename?: 'Mutation';
  createConversation: { __typename?: 'Conversation'; id: string };
};

export type MarkConversationsReadMutationVariables = Exact<{
  input: MarkConversationsReadInput;
}>;

export type MarkConversationsReadMutation = {
  __typename?: 'Mutation';
  markConversationsRead: { __typename?: 'OperationStatus'; success: boolean };
};

export type SendMessageMutationVariables = Exact<{
  input: SendMessageInput;
}>;

export type SendMessageMutation = {
  __typename?: 'Mutation';
  sendMessage: { __typename?: 'Message' } & {
    ' $fragmentRefs'?: { MessageFragment: MessageFragment };
  };
};

export type CreateScheduledMessageMutationVariables = Exact<{
  input: CreateScheduledMessageInput;
}>;

export type CreateScheduledMessageMutation = {
  __typename?: 'Mutation';
  createScheduledMessage: {
    __typename?: 'ScheduledMessage';
    id: string;
    createdAt: string;
    scheduledAt?: string | null;
    content?: string | null;
    attachments?: Array<
      { __typename?: 'MessageAttachment' } & {
        ' $fragmentRefs'?: { AttachmentFragment: AttachmentFragment };
      }
    > | null;
  };
};

export type RemoveScheduledMessageMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveScheduledMessageMutation = {
  __typename?: 'Mutation';
  removeScheduledMessage: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateConversationOutgoingPhoneMutationVariables = Exact<{
  input: UpdateConversationOutgoingPhoneInput;
}>;

export type UpdateConversationOutgoingPhoneMutation = {
  __typename?: 'Mutation';
  updateConversationOutgoingPhone: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type ConversationUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ConversationUpdatedSubscription = {
  __typename?: 'Subscription';
  conversationUpdated: {
    __typename?: 'ChangesItemView';
    entityId: string;
    operation: ChangesOperation;
  };
};

export type MessageCreatedSubscriptionVariables = Exact<{
  contactId: Scalars['String']['input'];
}>;

export type MessageCreatedSubscription = {
  __typename?: 'Subscription';
  messageCreated: { __typename?: 'ChangesItemView'; entityId: string };
};

export type MessageUpdatedSubscriptionVariables = Exact<{
  contactId: Scalars['String']['input'];
}>;

export type MessageUpdatedSubscription = {
  __typename?: 'Subscription';
  messageUpdated: { __typename?: 'ChangesItemView'; entityId: string };
};

export type IncomingMessageReceivedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type IncomingMessageReceivedSubscription = {
  __typename?: 'Subscription';
  incomingMessageReceived: {
    __typename?: 'Message';
    profileId: string;
    status: MessageStatus;
    conversationId: string;
    type: MessageType;
    statusReason?: MessageStateReasonType | null;
    contentType?: MessageContentType | null;
    id: string;
  };
};

export type UnreadConversationsMessagesCounterUpdatedSubscriptionVariables =
  Exact<{
    profileId?: InputMaybe<Scalars['String']['input']>;
  }>;

export type UnreadConversationsMessagesCounterUpdatedSubscription = {
  __typename?: 'Subscription';
  unreadConversationsMessagesCounterUpdated: {
    __typename?: 'UnreadConversationsCounterUpdatedView';
    counter: number;
    profileId: string;
  };
};

export type MissedConversationsCallsCounterUpdatedSubscriptionVariables =
  Exact<{
    profileId?: InputMaybe<Scalars['String']['input']>;
  }>;

export type MissedConversationsCallsCounterUpdatedSubscription = {
  __typename?: 'Subscription';
  missedConversationsCallsCounterUpdated: {
    __typename?: 'UnreadConversationsCounterUpdatedView';
    counter: number;
    profileId: string;
  };
};

export type GenerateWebRtcTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GenerateWebRtcTokenQuery = {
  __typename?: 'Query';
  generateWebRTCToken: {
    __typename?: 'WebRTCToken';
    expiresAt: string;
    token: string;
  };
};

export type GetInfobipConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetInfobipConfigQuery = {
  __typename?: 'Query';
  getInfobipConfig: {
    __typename?: 'InfobipConfigView';
    applicationId: string;
    callsConfigId: string;
  };
};

export type DialerCampaignFragmentFragment = {
  __typename?: 'DialerCampaign';
  id: string;
  name: string;
  status: CampaignStatus;
  createdAt: string;
  lastCallAt?: string | null;
  contacts: number;
  mode: CallMode;
  script?: string | null;
  phones: Array<string>;
  labels: Array<string>;
  lines: Array<string>;
  deletedAt?: string | null;
  endedAt?: string | null;
  startedAt?: string | null;
  groups?: Array<{ __typename?: 'GroupView'; id: string; name: string }> | null;
  ownPhones: Array<
    { __typename?: 'OwnPhone' } & {
      ' $fragmentRefs'?: { OwnPhoneFragment: OwnPhoneFragment };
    }
  >;
} & { ' $fragmentName'?: 'DialerCampaignFragmentFragment' };

export type DialerCampaignDetailedFragmentFragment = {
  __typename?: 'DialerCampaign';
  id: string;
  name: string;
  status: CampaignStatus;
  createdAt: string;
  lastCallAt?: string | null;
  contacts: number;
  mode: CallMode;
  script?: string | null;
  phones: Array<string>;
  labels: Array<string>;
  contactsWithOutcome?: number | null;
  lines: Array<string>;
  deletedAt?: string | null;
  endedAt?: string | null;
  startedAt?: string | null;
  groups?: Array<{
    __typename?: 'GroupView';
    id: string;
    name: string;
    verified: boolean;
    createdAt: string;
    contacts: number;
  }> | null;
  ownPhones: Array<
    { __typename?: 'OwnPhone' } & {
      ' $fragmentRefs'?: { OwnPhoneFragment: OwnPhoneFragment };
    }
  >;
} & { ' $fragmentName'?: 'DialerCampaignDetailedFragmentFragment' };

export type FindDialerCampaignsQueryVariables = Exact<{
  filter?: InputMaybe<DialerCampaignFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindDialerCampaignsQuery = {
  __typename?: 'Query';
  findDialerCampaigns: {
    __typename?: 'DialerCampaignList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'DialerCampaign' } & {
        ' $fragmentRefs'?: {
          DialerCampaignFragmentFragment: DialerCampaignFragmentFragment;
        };
      }
    >;
  };
};

export type FindListDialerCampaignsQueryVariables = Exact<{
  filter?: InputMaybe<DialerCampaignFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindListDialerCampaignsQuery = {
  __typename?: 'Query';
  findDialerCampaigns: {
    __typename?: 'DialerCampaignList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{ __typename?: 'DialerCampaign'; id: string; name: string }>;
  };
};

export type GetDialerCampaignsTotalQueryVariables = Exact<{
  filter?: InputMaybe<DialerCampaignFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type GetDialerCampaignsTotalQuery = {
  __typename?: 'Query';
  findDialerCampaigns: {
    __typename?: 'DialerCampaignList';
    total?: number | null;
  };
};

export type GetDialerCampaignsCountersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDialerCampaignsCountersQuery = {
  __typename?: 'Query';
  getDialerCampaignsCounters: {
    __typename?: 'DialerCampaignsAggregationView';
    dialer: {
      __typename?: 'DialerCampaignsAggregationCountersView';
      active: number;
      draft: number;
      deleted: number;
      total: number;
    };
  };
};

export type GetDialerCampaignQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetDialerCampaignQuery = {
  __typename?: 'Query';
  getDialerCampaign: { __typename?: 'DialerCampaign' } & {
    ' $fragmentRefs'?: {
      DialerCampaignDetailedFragmentFragment: DialerCampaignDetailedFragmentFragment;
    };
  };
};

export type ExportDialerCampaignToFileQueryVariables = Exact<{
  input: ExportDialerCampaignToFileInput;
}>;

export type ExportDialerCampaignToFileQuery = {
  __typename?: 'Query';
  exportDialerCampaignToFile: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type CreateDialerCampaignMutationVariables = Exact<{
  input: CreateDialerCampaignInput;
}>;

export type CreateDialerCampaignMutation = {
  __typename?: 'Mutation';
  createDialerCampaign: { __typename?: 'DialerCampaign' } & {
    ' $fragmentRefs'?: {
      DialerCampaignFragmentFragment: DialerCampaignFragmentFragment;
    };
  };
};

export type RemoveDialerCampaignMutationVariables = Exact<{
  input: RemoveDialerCampaignInput;
}>;

export type RemoveDialerCampaignMutation = {
  __typename?: 'Mutation';
  removeDialerCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type StartDialerCampaignMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type StartDialerCampaignMutation = {
  __typename?: 'Mutation';
  startDialerCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type PauseDialerCampaignMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type PauseDialerCampaignMutation = {
  __typename?: 'Mutation';
  pauseDialerCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type ResumeDialerCampaignMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type ResumeDialerCampaignMutation = {
  __typename?: 'Mutation';
  resumeDialerCampaign: { __typename?: 'OperationStatus'; success: boolean };
};

export type ExportDialerCampaignToGroupMutationVariables = Exact<{
  input: ExportDialerCampaignToGroupInput;
  id: Scalars['String']['input'];
}>;

export type ExportDialerCampaignToGroupMutation = {
  __typename?: 'Mutation';
  exportDialerCampaignToGroup: { __typename?: 'GroupView'; id: string };
};

export type UpdateDialerCampaignMutationVariables = Exact<{
  input: UpdateDialerCampaignInput;
  updateDialerCampaignId: Scalars['String']['input'];
}>;

export type UpdateDialerCampaignMutation = {
  __typename?: 'Mutation';
  updateDialerCampaign: { __typename?: 'DialerCampaign'; id: string };
};

export type ExportConversationsToFileQueryVariables = Exact<{
  input: ExportConversationsToFileInput;
}>;

export type ExportConversationsToFileQuery = {
  __typename?: 'Query';
  exportConversationsToFile: {
    __typename?: 'SignedUrl';
    filePath: string;
    signedUrl: string;
  };
};

export type FindLastFeedbackQueryVariables = Exact<{
  source: FeedbackSource;
}>;

export type FindLastFeedbackQuery = {
  __typename?: 'Query';
  findLastFeedback?: {
    __typename?: 'Feedback';
    id: string;
    score: number;
    createdAt: string;
    source: FeedbackSource;
    message?: string | null;
  } | null;
};

export type CreateFeedbackMutationVariables = Exact<{
  input: CreateFeedbackInput;
}>;

export type CreateFeedbackMutation = {
  __typename?: 'Mutation';
  createFeedback: { __typename?: 'OperationStatus'; success: boolean };
};

export type FieldsTemplateFragment = {
  __typename?: 'FieldsTemplate';
  id: string;
  name: string;
  createdAt: string;
  fields?: Array<
    { __typename?: 'Field' } & {
      ' $fragmentRefs'?: { FieldFragment: FieldFragment };
    }
  > | null;
} & { ' $fragmentName'?: 'FieldsTemplateFragment' };

export type FindFieldsTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type FindFieldsTemplatesQuery = {
  __typename?: 'Query';
  findFieldsTemplates: {
    __typename?: 'FieldsTemplateList';
    items: Array<
      { __typename?: 'FieldsTemplate' } & {
        ' $fragmentRefs'?: { FieldsTemplateFragment: FieldsTemplateFragment };
      }
    >;
  };
};

export type CreateFieldsTemplateMutationVariables = Exact<{
  input: CreateFieldsTemplateInput;
}>;

export type CreateFieldsTemplateMutation = {
  __typename?: 'Mutation';
  createFieldsTemplate: { __typename?: 'FieldsTemplate' } & {
    ' $fragmentRefs'?: { FieldsTemplateFragment: FieldsTemplateFragment };
  };
};

export type RemoveFieldsTemplateMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveFieldsTemplateMutation = {
  __typename?: 'Mutation';
  removeFieldsTemplate: { __typename?: 'OperationStatus'; success: boolean };
};

export type FieldFragment = {
  __typename?: 'Field';
  id: string;
  key?: PredefinedField | null;
  predefined: boolean;
  name: string;
} & { ' $fragmentName'?: 'FieldFragment' };

export type FindFieldsQueryVariables = Exact<{
  filter?: InputMaybe<FieldsFilter>;
}>;

export type FindFieldsQuery = {
  __typename?: 'Query';
  findFields: Array<
    { __typename?: 'Field' } & {
      ' $fragmentRefs'?: { FieldFragment: FieldFragment };
    }
  >;
};

export type FindMergeTagsQueryVariables = Exact<{ [key: string]: never }>;

export type FindMergeTagsQuery = {
  __typename?: 'Query';
  findMergeTags: Array<{
    __typename?: 'MergeTag';
    id: string;
    key?: PredefinedField | null;
    predefined: boolean;
    name: string;
    mergeTag: string;
  }>;
};

export type CreateFieldMutationVariables = Exact<{
  input: CreateFieldInput;
}>;

export type CreateFieldMutation = {
  __typename?: 'Mutation';
  createField: { __typename?: 'Field' } & {
    ' $fragmentRefs'?: { FieldFragment: FieldFragment };
  };
};

export type UpdateFieldMutationVariables = Exact<{
  input: UpdateFieldInput;
  id: Scalars['String']['input'];
}>;

export type UpdateFieldMutation = {
  __typename?: 'Mutation';
  updateField: { __typename?: 'Field' } & {
    ' $fragmentRefs'?: { FieldFragment: FieldFragment };
  };
};

export type RemoveFieldMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveFieldMutation = {
  __typename?: 'Mutation';
  removeField: { __typename?: 'OperationStatus'; success: boolean };
};

export type GroupViewFragment = {
  __typename?: 'GroupView';
  id: string;
  name: string;
  verified: boolean;
  createdAt: string;
  contacts: number;
  activeChats: number;
  canBeCloned: boolean;
  canBeRemoved: boolean;
  campaigns: Array<{ __typename?: 'Campaign'; name: string }>;
  dialerCampaigns: Array<{ __typename?: 'DialerCampaign'; name: string }>;
} & { ' $fragmentName'?: 'GroupViewFragment' };

export type GetGroupQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetGroupQuery = {
  __typename?: 'Query';
  getGroup: { __typename?: 'GroupView' } & {
    ' $fragmentRefs'?: { GroupViewFragment: GroupViewFragment };
  };
};

export type FindGroupsQueryVariables = Exact<{
  order?: InputMaybe<Order>;
  filter?: InputMaybe<GroupsFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindGroupsQuery = {
  __typename?: 'Query';
  findGroups: {
    __typename?: 'GroupList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'GroupView' } & {
        ' $fragmentRefs'?: { GroupViewFragment: GroupViewFragment };
      }
    >;
  };
};

export type FindAlertGroupsQueryVariables = Exact<{
  order?: InputMaybe<Order>;
  filter?: InputMaybe<GroupsFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindAlertGroupsQuery = {
  __typename?: 'Query';
  findGroups: {
    __typename?: 'GroupList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'GroupView' } & {
        ' $fragmentRefs'?: { GroupViewFragment: GroupViewFragment };
      }
    >;
  };
};

export type CreateGroupMutationVariables = Exact<{
  input: CreateGroupInput;
}>;

export type CreateGroupMutation = {
  __typename?: 'Mutation';
  createGroup: {
    __typename?: 'GroupView';
    id: string;
    name: string;
    verified: boolean;
    createdAt: string;
    canBeCloned: boolean;
  };
};

export type UpdateGroupMutationVariables = Exact<{
  input: UpdateGroupInput;
  id: Scalars['String']['input'];
}>;

export type UpdateGroupMutation = {
  __typename?: 'Mutation';
  updateGroup: { __typename?: 'GroupView' } & {
    ' $fragmentRefs'?: { GroupViewFragment: GroupViewFragment };
  };
};

export type RemoveGroupsMutationVariables = Exact<{
  input: RemoveGroupsInput;
}>;

export type RemoveGroupsMutation = {
  __typename?: 'Mutation';
  removeGroups: { __typename?: 'OperationStatus'; success: boolean };
};

export type DuplicateGroupsMutationVariables = Exact<{
  duplicateGroupsId: Scalars['String']['input'];
}>;

export type DuplicateGroupsMutation = {
  __typename?: 'Mutation';
  duplicateGroups: { __typename?: 'GroupView' } & {
    ' $fragmentRefs'?: { GroupViewFragment: GroupViewFragment };
  };
};

export type AddGroupToContactsMutationVariables = Exact<{
  input: AddGroupToContactsInput;
}>;

export type AddGroupToContactsMutation = {
  __typename?: 'Mutation';
  addGroupToContacts: { __typename?: 'OperationStatus'; success: boolean };
};

export type RemoveGroupFromContactsMutationVariables = Exact<{
  input: RemoveGroupFromContactsInput;
}>;

export type RemoveGroupFromContactsMutation = {
  __typename?: 'Mutation';
  removeGroupFromContacts: { __typename?: 'OperationStatus'; success: boolean };
};

export type ExportGroupQueryVariables = Exact<{
  exportGroupId: Scalars['String']['input'];
}>;

export type ExportGroupQuery = {
  __typename?: 'Query';
  exportGroup: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type CrmIntegrationFragmentFragment = {
  __typename?: 'CrmIntegration';
  id: string;
  createdAt: string;
  status: CrmIntegrationStatus;
  name: string;
  type?: CrmProviderType | null;
} & { ' $fragmentName'?: 'CrmIntegrationFragmentFragment' };

export type CrmIntegrationFieldsFragment = ({
  __typename?: 'CrmIntegration';
  provider?: {
    __typename?: 'CrmProviderView';
    apiKey?: string | null;
    sendNotes?: boolean | null;
    labelsPropertyStatuses?: Array<{
      __typename?: 'CrmProviderLabelView';
      status: string;
      label: {
        __typename?: 'Label';
        id: string;
        title: string;
        color: string;
        default: boolean;
        firestoreId: string;
      };
    }> | null;
    labelsPhoneStatuses?: Array<{
      __typename?: 'CrmProviderLabelView';
      status: string;
      label: {
        __typename?: 'Label';
        id: string;
        title: string;
        color: string;
        default: boolean;
        firestoreId: string;
      };
    }> | null;
    labelsPhoneTags?: Array<{
      __typename?: 'Label';
      id: string;
      title: string;
      color: string;
      default: boolean;
      firestoreId: string;
    }> | null;
  } | null;
} & {
  ' $fragmentRefs'?: {
    CrmIntegrationFragmentFragment: CrmIntegrationFragmentFragment;
  };
}) & { ' $fragmentName'?: 'CrmIntegrationFieldsFragment' };

export type FindCrmIntegrationsQueryVariables = Exact<{
  filter?: InputMaybe<CrmIntegrationFilter>;
  pagination?: InputMaybe<Pagination>;
  order?: InputMaybe<CrmIntegrationOrder>;
}>;

export type FindCrmIntegrationsQuery = {
  __typename?: 'Query';
  findCrmIntegrations: {
    __typename?: 'CrmIntegrationList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'CrmIntegration' } & {
        ' $fragmentRefs'?: {
          CrmIntegrationFragmentFragment: CrmIntegrationFragmentFragment;
        };
      }
    >;
  };
};

export type GetCrmIntegrationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetCrmIntegrationQuery = {
  __typename?: 'Query';
  getCrmIntegration: { __typename?: 'CrmIntegration' } & {
    ' $fragmentRefs'?: {
      CrmIntegrationFieldsFragment: CrmIntegrationFieldsFragment;
    };
  };
};

export type ExportConversationsToCrmIntegrationQueryVariables = Exact<{
  input: ExportConversationsToCrmIntegrationInput;
}>;

export type ExportConversationsToCrmIntegrationQuery = {
  __typename?: 'Query';
  exportConversationsToCrmIntegration: {
    __typename?: 'ExportConversationsToCRMIntegrationResultView';
    success: number;
    total: number;
  };
};

export type CreateCrmIntegrationMutationVariables = Exact<{
  input: CreateCrmIntegrationInput;
}>;

export type CreateCrmIntegrationMutation = {
  __typename?: 'Mutation';
  createCrmIntegration: { __typename?: 'CrmIntegration' } & {
    ' $fragmentRefs'?: {
      CrmIntegrationFieldsFragment: CrmIntegrationFieldsFragment;
    };
  };
};

export type UpdateCrmIntegrationMutationVariables = Exact<{
  input: UpdateCrmIntegrationInput;
  id: Scalars['String']['input'];
}>;

export type UpdateCrmIntegrationMutation = {
  __typename?: 'Mutation';
  updateCrmIntegration: { __typename?: 'CrmIntegration'; id: string } & {
    ' $fragmentRefs'?: {
      CrmIntegrationFieldsFragment: CrmIntegrationFieldsFragment;
    };
  };
};

export type RemoveCrmIntegrationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveCrmIntegrationMutation = {
  __typename?: 'Mutation';
  removeCrmIntegration: { __typename?: 'OperationStatus'; success: boolean };
};

export type KeywordTemplateFragment = {
  __typename?: 'KeywordTemplate';
  id: string;
  keyword: string;
  createdAt: string;
  template: {
    __typename?: 'KeywordTemplateTemplate';
    content?: string | null;
    optOut?: boolean | null;
    characters?: number | null;
    segments?: number | null;
  };
} & { ' $fragmentName'?: 'KeywordTemplateFragment' };

export type FindKeywordTemplatesQueryVariables = Exact<{
  filter?: InputMaybe<KeywordTemplatesFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindKeywordTemplatesQuery = {
  __typename?: 'Query';
  findKeywordTemplates: {
    __typename?: 'KeywordTemplateList';
    hasNext?: boolean | null;
    nextCreatedAt?: string | null;
    nextId?: string | null;
    total?: number | null;
    items: Array<
      { __typename?: 'KeywordTemplate' } & {
        ' $fragmentRefs'?: { KeywordTemplateFragment: KeywordTemplateFragment };
      }
    >;
  };
};

export type GetKeywordTemplateQueryVariables = Exact<{
  getKeywordTemplateId: Scalars['String']['input'];
}>;

export type GetKeywordTemplateQuery = {
  __typename?: 'Query';
  getKeywordTemplate: { __typename?: 'KeywordTemplate' } & {
    ' $fragmentRefs'?: { KeywordTemplateFragment: KeywordTemplateFragment };
  };
};

export type CreateKeywordTemplateMutationVariables = Exact<{
  input: CreateKeywordTemplateInput;
}>;

export type CreateKeywordTemplateMutation = {
  __typename?: 'Mutation';
  createKeywordTemplate: { __typename?: 'KeywordTemplate' } & {
    ' $fragmentRefs'?: { KeywordTemplateFragment: KeywordTemplateFragment };
  };
};

export type UpdateKeywordTemplateMutationVariables = Exact<{
  input: UpdateKeywordTemplateInput;
  updateKeywordTemplateId: Scalars['String']['input'];
}>;

export type UpdateKeywordTemplateMutation = {
  __typename?: 'Mutation';
  updateKeywordTemplate: { __typename?: 'KeywordTemplate' } & {
    ' $fragmentRefs'?: { KeywordTemplateFragment: KeywordTemplateFragment };
  };
};

export type RemoveKeywordTemplateMutationVariables = Exact<{
  removeKeywordTemplateId: Scalars['String']['input'];
}>;

export type RemoveKeywordTemplateMutation = {
  __typename?: 'Mutation';
  removeKeywordTemplate: { __typename?: 'OperationStatus'; success: boolean };
};

export type RankKeywordTemplateMutationVariables = Exact<{
  input: RankKeywordTemplateInput;
  rankKeywordTemplateId: Scalars['String']['input'];
}>;

export type RankKeywordTemplateMutation = {
  __typename?: 'Mutation';
  rankKeywordTemplate: { __typename?: 'OperationStatus'; success: boolean };
};

export type LabelFragment = {
  __typename?: 'Label';
  id: string;
  title: string;
  color: string;
  default: boolean;
  scopes: Array<Scope>;
  readOnly: boolean;
  createdAt: string;
  firestoreId: string;
} & { ' $fragmentName'?: 'LabelFragment' };

export type RankResultFragment = {
  __typename?: 'RankResult';
  id: string;
  afterId?: string | null;
  beforeId?: string | null;
} & { ' $fragmentName'?: 'RankResultFragment' };

export type FindLabelsQueryVariables = Exact<{
  filter?: InputMaybe<LabelFilter>;
  pagination?: InputMaybe<CursorPagination>;
}>;

export type FindLabelsQuery = {
  __typename?: 'Query';
  findLabels: {
    __typename?: 'LabelList';
    total?: number | null;
    nextPageToken?: string | null;
    items: Array<
      { __typename?: 'Label' } & {
        ' $fragmentRefs'?: { LabelFragment: LabelFragment };
      }
    >;
  };
};

export type GetLabelQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetLabelQuery = {
  __typename?: 'Query';
  getLabel: { __typename?: 'Label' } & {
    ' $fragmentRefs'?: { LabelFragment: LabelFragment };
  };
};

export type CreateLabelMutationVariables = Exact<{
  input: CreateLabelInput;
}>;

export type CreateLabelMutation = {
  __typename?: 'Mutation';
  createLabel: { __typename?: 'Label' } & {
    ' $fragmentRefs'?: { LabelFragment: LabelFragment };
  };
};

export type UpdateLabelMutationVariables = Exact<{
  input: UpdateLabelInput;
  id: Scalars['String']['input'];
}>;

export type UpdateLabelMutation = {
  __typename?: 'Mutation';
  updateLabel: { __typename?: 'Label' } & {
    ' $fragmentRefs'?: { LabelFragment: LabelFragment };
  };
};

export type RemoveLabelMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveLabelMutation = {
  __typename?: 'Mutation';
  removeLabel: { __typename?: 'OperationStatus'; success: boolean };
};

export type RankLabelMutationVariables = Exact<{
  input: RankLabelInput;
  rankLabelId: Scalars['String']['input'];
}>;

export type RankLabelMutation = {
  __typename?: 'Mutation';
  rankLabel: { __typename?: 'RankResult' } & {
    ' $fragmentRefs'?: { RankResultFragment: RankResultFragment };
  };
};

export type ListOptionsFragment = {
  __typename?: 'ListOptions';
  removeFiltered: boolean;
  removeUnsubscribed: boolean;
  removeInternalDnc: boolean;
  removeExisting: boolean;
  removeNonSmsCapable: boolean;
  removeLitigators: boolean;
  removeNationalDnc: boolean;
  removeCarrierBlocked: boolean;
  removeGlobalUnsubscribed: boolean;
  removeUndeliverable: boolean;
} & { ' $fragmentName'?: 'ListOptionsFragment' };

export type ListVerificationResultFragment = {
  __typename?: 'ListVerificationResult';
  listId: string;
  verified: boolean;
  totalPhones: number;
  verifiedPhones: number;
  filtered: number;
  unsubscribed: number;
  internalDnc: number;
  existing: number;
  nonSmsCapable: number;
  litigators: number;
  nationalDnc: number;
  undeliverable: number;
  carrierBlocked: number;
  globalUnsubscribed: number;
} & { ' $fragmentName'?: 'ListVerificationResultFragment' };

export type GetListQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetListQuery = {
  __typename?: 'Query';
  getList: {
    __typename?: 'ListView';
    id: string;
    type: ListType;
    options?:
      | ({ __typename?: 'ListOptions' } & {
          ' $fragmentRefs'?: { ListOptionsFragment: ListOptionsFragment };
        })
      | null;
    fields?: Array<
      { __typename?: 'Field' } & {
        ' $fragmentRefs'?: { FieldFragment: FieldFragment };
      }
    > | null;
  };
};

export type ExportListQueryVariables = Exact<{
  input: ExportListInput;
}>;

export type ExportListQuery = {
  __typename?: 'Query';
  exportList: { __typename?: 'SignedUrl'; signedUrl: string; filePath: string };
};

export type FindListItemsQueryVariables = Exact<{
  listId: Scalars['String']['input'];
  pagination?: InputMaybe<ListItemsPagination>;
}>;

export type FindListItemsQuery = {
  __typename?: 'Query';
  findListItems: {
    __typename?: 'ListItemList';
    total?: number | null;
    hasNext?: boolean | null;
    nextRowIndex?: number | null;
    items: Array<{
      __typename?: 'ListItemView';
      id: string;
      data: Array<string>;
      rowIndex: number;
    }>;
  };
};

export type UpdateListMutationVariables = Exact<{
  input: UpdateListInput;
  id: Scalars['String']['input'];
}>;

export type UpdateListMutation = {
  __typename?: 'Mutation';
  updateList: {
    __typename?: 'ListView';
    id: string;
    options?:
      | ({ __typename?: 'ListOptions' } & {
          ' $fragmentRefs'?: { ListOptionsFragment: ListOptionsFragment };
        })
      | null;
    fields?: Array<
      { __typename?: 'Field' } & {
        ' $fragmentRefs'?: { FieldFragment: FieldFragment };
      }
    > | null;
  };
};

export type VerifyListMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type VerifyListMutation = {
  __typename?: 'Mutation';
  verifyList: { __typename?: 'OperationStatus'; success: boolean };
};

export type RemoveListItemMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveListItemMutation = {
  __typename?: 'Mutation';
  removeListItem: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateListItemMutationVariables = Exact<{
  input: UpdateListItemInput;
  id: Scalars['String']['input'];
}>;

export type UpdateListItemMutation = {
  __typename?: 'Mutation';
  updateListItem: { __typename?: 'OperationStatus'; success: boolean };
};

export type GetListUploadSignedUrlQueryVariables = Exact<{
  input: GenerateSignedUrlInput;
}>;

export type GetListUploadSignedUrlQuery = {
  __typename?: 'Query';
  getListUploadSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type CreateListMutationVariables = Exact<{
  input: CreateListInput;
}>;

export type CreateListMutation = {
  __typename?: 'Mutation';
  createList: {
    __typename?: 'ListView';
    id: string;
    type: ListType;
    options?:
      | ({ __typename?: 'ListOptions' } & {
          ' $fragmentRefs'?: { ListOptionsFragment: ListOptionsFragment };
        })
      | null;
    fields?: Array<{
      __typename?: 'Field';
      id: string;
      name: string;
      predefined: boolean;
      key?: PredefinedField | null;
    }> | null;
  };
};

export type ListVerificationProgressSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ListVerificationProgressSubscription = {
  __typename?: 'Subscription';
  listVerificationProgress: {
    __typename?: 'ListVerificationProgress';
    listId: string;
    progress: number;
  };
};

export type ListVerificationCompletedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ListVerificationCompletedSubscription = {
  __typename?: 'Subscription';
  listVerificationCompleted: { __typename?: 'ListVerificationResult' } & {
    ' $fragmentRefs'?: {
      ListVerificationResultFragment: ListVerificationResultFragment;
    };
  };
};

export type ListUploadProgressSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ListUploadProgressSubscription = {
  __typename?: 'Subscription';
  listUploadProgress: {
    __typename?: 'ListUploadProgress';
    listId: string;
    progress: number;
  };
};

export type ListUploadCompletedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ListUploadCompletedSubscription = {
  __typename?: 'Subscription';
  listUploadCompleted: { __typename?: 'ListUploadCompleted'; listId: string };
};

export type MacroFragment = {
  __typename?: 'Macro';
  id: string;
  name: string;
  content: string;
} & { ' $fragmentName'?: 'MacroFragment' };

export type FindMacrosQueryVariables = Exact<{
  filter?: InputMaybe<MacrosFilter>;
  pagination?: InputMaybe<CursorPagination>;
}>;

export type FindMacrosQuery = {
  __typename?: 'Query';
  findMacros: {
    __typename?: 'MacroList';
    total?: number | null;
    nextPageToken?: string | null;
    items: Array<
      { __typename?: 'Macro' } & {
        ' $fragmentRefs'?: { MacroFragment: MacroFragment };
      }
    >;
  };
};

export type GetMacroQueryVariables = Exact<{
  getMacroId: Scalars['String']['input'];
}>;

export type GetMacroQuery = {
  __typename?: 'Query';
  getMacro: { __typename?: 'Macro' } & {
    ' $fragmentRefs'?: { MacroFragment: MacroFragment };
  };
};

export type CreateMacroMutationVariables = Exact<{
  input: CreateMacroInput;
}>;

export type CreateMacroMutation = {
  __typename?: 'Mutation';
  createMacro: { __typename?: 'Macro' } & {
    ' $fragmentRefs'?: { MacroFragment: MacroFragment };
  };
};

export type UpdateMacroMutationVariables = Exact<{
  input: UpdateMacroInput;
  updateMacroId: Scalars['String']['input'];
}>;

export type UpdateMacroMutation = {
  __typename?: 'Mutation';
  updateMacro: { __typename?: 'Macro' } & {
    ' $fragmentRefs'?: { MacroFragment: MacroFragment };
  };
};

export type RemoveMacroMutationVariables = Exact<{
  removeMacroId: Scalars['String']['input'];
}>;

export type RemoveMacroMutation = {
  __typename?: 'Mutation';
  removeMacro: { __typename?: 'OperationStatus'; success: boolean };
};

export type MessageTemplateFragment = {
  __typename?: 'MessageTemplate';
  id: string;
  firestoreId?: string | null;
  name: string;
  type: MessageTemplateType;
  characters?: number | null;
  segments?: number | null;
  createdAt: string;
  template: {
    __typename?: 'MessageTemplateTemplate';
    audioFileBucket?: string | null;
    audioFilePath?: string | null;
    audioFileUrl?: string | null;
    content?: string | null;
    contentType?: string | null;
    description?: string | null;
    optOut?: boolean | null;
    messageType?: MessageTemplateMessageType | null;
    characters?: number | null;
    segments?: number | null;
  };
} & { ' $fragmentName'?: 'MessageTemplateFragment' };

export type FindMessageTemplatesQueryVariables = Exact<{
  filter?: InputMaybe<MessageTemplatesFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindMessageTemplatesQuery = {
  __typename?: 'Query';
  findMessageTemplates: {
    __typename?: 'MessageTemplateList';
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    total?: number | null;
    items: Array<
      { __typename?: 'MessageTemplate' } & {
        ' $fragmentRefs'?: { MessageTemplateFragment: MessageTemplateFragment };
      }
    >;
  };
};

export type GetMessageTemplateQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetMessageTemplateQuery = {
  __typename?: 'Query';
  getMessageTemplate: { __typename?: 'MessageTemplate' } & {
    ' $fragmentRefs'?: { MessageTemplateFragment: MessageTemplateFragment };
  };
};

export type FindMessageTemplatesForFuckingFirestoreQueryVariables = Exact<{
  filter?: InputMaybe<MessageTemplatesFilter>;
}>;

export type FindMessageTemplatesForFuckingFirestoreQuery = {
  __typename?: 'Query';
  findMessageTemplatesForFuckingFirestore: Array<
    { __typename?: 'MessageTemplate' } & {
      ' $fragmentRefs'?: { MessageTemplateFragment: MessageTemplateFragment };
    }
  >;
};

export type CreateMessageTemplateMutationVariables = Exact<{
  input: CreateMessageTemplateInput;
}>;

export type CreateMessageTemplateMutation = {
  __typename?: 'Mutation';
  createMessageTemplate: { __typename?: 'MessageTemplate' } & {
    ' $fragmentRefs'?: { MessageTemplateFragment: MessageTemplateFragment };
  };
};

export type UpdateMessageTemplateMutationVariables = Exact<{
  input: UpdateMessageTemplateInput;
  updateMessageTemplateId: Scalars['String']['input'];
}>;

export type UpdateMessageTemplateMutation = {
  __typename?: 'Mutation';
  updateMessageTemplate: { __typename?: 'MessageTemplate' } & {
    ' $fragmentRefs'?: { MessageTemplateFragment: MessageTemplateFragment };
  };
};

export type RemoveMessageTemplateMutationVariables = Exact<{
  removeMessageTemplateId: Scalars['String']['input'];
}>;

export type RemoveMessageTemplateMutation = {
  __typename?: 'Mutation';
  removeMessageTemplate: { __typename?: 'OperationStatus'; success: boolean };
};

export type RankMessageTemplateMutationVariables = Exact<{
  input: RankMessageTemplateInput;
  rankMessageTemplateId: Scalars['String']['input'];
}>;

export type RankMessageTemplateMutation = {
  __typename?: 'Mutation';
  rankMessageTemplate: { __typename?: 'OperationStatus'; success: boolean };
};

export type BuildMessageTemplatesQueryVariables = Exact<{
  input: BuildMessageTemplateInput;
}>;

export type BuildMessageTemplatesQuery = {
  __typename?: 'Query';
  buildMessageTemplates: Array<
    { __typename?: 'MessageTemplate' } & {
      ' $fragmentRefs'?: { MessageTemplateFragment: MessageTemplateFragment };
    }
  >;
};

export type GetMessageTemplateSignedUrlQueryVariables = Exact<{
  input: GenerateSignedUrlInput;
}>;

export type GetMessageTemplateSignedUrlQuery = {
  __typename?: 'Query';
  getMessageTemplateSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type MessagingProfileFragment = {
  __typename?: 'MessagingProfile';
  id: string;
  allowUrl: boolean;
  isEINDocRequired: boolean;
  phoneSettings?: {
    __typename?: 'PhoneSettings';
    activeIn?: string | null;
    isSipEnabled?: boolean | null;
    notificationsEnabled?: boolean | null;
    alertFilter?: {
      __typename?: 'PhoneSettingsFilter';
      campaigns?: Array<string> | null;
      groups?: Array<string> | null;
      labels?: Array<string> | null;
    } | null;
    notificationFilter?: {
      __typename?: 'PhoneSettingsFilter';
      campaigns?: Array<string> | null;
      groups?: Array<string> | null;
      labels?: Array<string> | null;
    } | null;
    callAutoReply?: {
      __typename?: 'PhoneSettingsAutoReply';
      enabled?: boolean | null;
      voiceType?: PhoneSettingsVoiceType | null;
      payload?: string | null;
    } | null;
    voiceMail?: {
      __typename?: 'PhoneSettingsAutoReply';
      enabled?: boolean | null;
      voiceType?: PhoneSettingsVoiceType | null;
      payload?: string | null;
    } | null;
  } | null;
  callSettings?: {
    __typename?: 'CallSettings';
    activeIn?: string | null;
    isSipEnabled?: boolean | null;
    callAutoReply?: {
      __typename?: 'CallSettingsAutoReply';
      enabled?: boolean | null;
      voiceType?: CallSettingsVoiceType | null;
      payload?: string | null;
    } | null;
    voiceMail?: {
      __typename?: 'CallSettingsAutoReply';
      enabled?: boolean | null;
      voiceType?: CallSettingsVoiceType | null;
      payload?: string | null;
    } | null;
  } | null;
  a2pInfo?: {
    __typename?: 'A2PInfo';
    status: A2PInfoStatus;
    maxTpm: number;
  } | null;
  tollFreeInfo?: {
    __typename?: 'TollFreeInfo';
    status: TollFreeInfoStatus;
    maxTpm: number;
    limits: {
      __typename?: 'TollFreeInfoLimits';
      daily: number;
      weekly: number;
      monthly: number;
    };
  } | null;
  responseRemoval?: {
    __typename?: 'ResponseRemoval';
    enabled?: boolean | null;
    responseFilters?: Array<string> | null;
  } | null;
  provider?: {
    __typename?: 'MessagingProvider';
    sipPassword?: string | null;
    sipUsername?: string | null;
    type: MessagingProviderType;
  } | null;
} & { ' $fragmentName'?: 'MessagingProfileFragment' };

export type GetMessagingProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetMessagingProfileQuery = {
  __typename?: 'Query';
  getMessagingProfile: { __typename?: 'MessagingProfile' } & {
    ' $fragmentRefs'?: { MessagingProfileFragment: MessagingProfileFragment };
  };
};

export type UpdateMessagingProfileMutationVariables = Exact<{
  input: UpdateMessagingProfileInput;
}>;

export type UpdateMessagingProfileMutation = {
  __typename?: 'Mutation';
  updateMessagingProfile: { __typename?: 'MessagingProfile'; id: string };
};

export type DetailsNotificationFragment = {
  __typename?: 'NotificationDetails';
  name?: string | null;
  relationId?: string | null;
  isKeywordCampaign?: boolean | null;
  calendarEventName?: string | null;
  calendarEventDate?: string | null;
  calendarEventId?: string | null;
} & { ' $fragmentName'?: 'DetailsNotificationFragment' };

export type NotificationFragment = {
  __typename?: 'Notification';
  id: string;
  type: NotificationType;
  isRead: boolean;
  isSeen: boolean;
  createdAt: string;
  details?:
    | ({ __typename?: 'NotificationDetails' } & {
        ' $fragmentRefs'?: {
          DetailsNotificationFragment: DetailsNotificationFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'NotificationFragment' };

export type NotificationSettingsFragment = {
  __typename?: 'NotificationSettings';
  enabledCampaigns?: boolean | null;
  enabledPayments?: boolean | null;
  enabledCalendar?: boolean | null;
} & { ' $fragmentName'?: 'NotificationSettingsFragment' };

export type FindNotificationsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;

export type FindNotificationsQuery = {
  __typename?: 'Query';
  findNotifications: {
    __typename?: 'NotificationList';
    hasNext?: boolean | null;
    nextCreatedAt?: string | null;
    total?: number | null;
    nextId?: string | null;
    items: Array<
      { __typename?: 'Notification' } & {
        ' $fragmentRefs'?: { NotificationFragment: NotificationFragment };
      }
    >;
  };
};

export type GetNotificationSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetNotificationSettingsQuery = {
  __typename?: 'Query';
  getNotificationSettings: { __typename?: 'NotificationSettings' } & {
    ' $fragmentRefs'?: {
      NotificationSettingsFragment: NotificationSettingsFragment;
    };
  };
};

export type GetUnreadNotificationCounterQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUnreadNotificationCounterQuery = {
  __typename?: 'Query';
  getUnreadNotificationCounter: {
    __typename?: 'NotificationCounter';
    accountId: string;
    unreadCount: number;
  };
};

export type MarkNotificationsReadMutationVariables = Exact<{
  input: MarkNotificationsReadInput;
}>;

export type MarkNotificationsReadMutation = {
  __typename?: 'Mutation';
  markNotificationsRead: { __typename?: 'OperationStatus'; success: boolean };
};

export type MarkNotificationsSeenMutationVariables = Exact<{
  input: MarkNotificationsSeenInput;
}>;

export type MarkNotificationsSeenMutation = {
  __typename?: 'Mutation';
  markNotificationsSeen: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateNotificationSettingsMutationVariables = Exact<{
  input: UpdateNotificationSettingsInput;
}>;

export type UpdateNotificationSettingsMutation = {
  __typename?: 'Mutation';
  updateNotificationSettings: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type NotificationCreatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type NotificationCreatedSubscription = {
  __typename?: 'Subscription';
  notificationCreated: {
    __typename?: 'Notification';
    id: string;
    type: NotificationType;
    isRead: boolean;
    isSeen: boolean;
    createdAt: string;
    details?:
      | ({ __typename?: 'NotificationDetails' } & {
          ' $fragmentRefs'?: {
            DetailsNotificationFragment: DetailsNotificationFragment;
          };
        })
      | null;
  };
};

export type NotificationUnreadCounterUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type NotificationUnreadCounterUpdatedSubscription = {
  __typename?: 'Subscription';
  notificationUnreadCounterUpdated: {
    __typename?: 'NotificationCounter';
    accountId: string;
    unreadCount: number;
  };
};

export type OwnPhoneFragment = {
  __typename?: 'OwnPhone';
  id: string;
  status: OwnPhoneStatus;
  type: OwnPhoneType;
  number: string;
  scope: Scope;
  isTrial: boolean;
  createdAt: string;
  canBeRemoved: boolean;
  callingEnabled: boolean;
  messagingEnabled: boolean;
  location?: {
    __typename?: 'PhoneNumberLocation';
    abbreviation: string;
    state: string;
  } | null;
} & { ' $fragmentName'?: 'OwnPhoneFragment' };

export type OwnPhoneReportFragment = {
  __typename?: 'OwnPhone';
  id: string;
  status: OwnPhoneStatus;
  type: OwnPhoneType;
  number: string;
  scope: Scope;
  isTrial: boolean;
  createdAt: string;
  canBeRemoved: boolean;
  callingEnabled: boolean;
  messagingEnabled: boolean;
  location?: {
    __typename?: 'PhoneNumberLocation';
    abbreviation: string;
    state: string;
  } | null;
  reportDialer?: {
    __typename?: 'OwnDialerPhoneReportView';
    phoneId: string;
    outboundCalls?: number | null;
  } | null;
  reportMessage?: {
    __typename?: 'OwnPhoneReport';
    phoneId: string;
    outgoingMessages: number;
    blockedRateMessages: number;
    activeConversations: number;
    lastUsedAt?: string | null;
  } | null;
} & { ' $fragmentName'?: 'OwnPhoneReportFragment' };

export type FindAvailablePhonesQueryVariables = Exact<{
  filter: FindAvailablePhonesFilter;
}>;

export type FindAvailablePhonesQuery = {
  __typename?: 'Query';
  findAvailablePhones: Array<{
    __typename?: 'AvailablePhoneView';
    phone: string;
  }>;
};

export type FindOwnPhonesQueryVariables = Exact<{
  filter?: InputMaybe<OwnPhoneFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindOwnPhonesQuery = {
  __typename?: 'Query';
  findOwnPhones: {
    __typename?: 'OwnPhoneList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'OwnPhone' } & {
        ' $fragmentRefs'?: { OwnPhoneFragment: OwnPhoneFragment };
      }
    >;
  };
};

export type FindOwnPhonesReportQueryVariables = Exact<{
  filter?: InputMaybe<OwnPhoneFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindOwnPhonesReportQuery = {
  __typename?: 'Query';
  findOwnPhones: {
    __typename?: 'OwnPhoneList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'OwnPhone' } & {
        ' $fragmentRefs'?: { OwnPhoneReportFragment: OwnPhoneReportFragment };
      }
    >;
  };
};

export type GetOwnPhoneCountersQueryVariables = Exact<{
  scope?: InputMaybe<Scope>;
}>;

export type GetOwnPhoneCountersQuery = {
  __typename?: 'Query';
  getOwnPhoneCounters: {
    __typename?: 'OwnPhoneCounters';
    local: number;
    tollFree: number;
  };
};

export type PurchaseOwnPhoneMutationVariables = Exact<{
  input: PurchaseOwnPhoneInput;
}>;

export type PurchaseOwnPhoneMutation = {
  __typename?: 'Mutation';
  purchaseOwnPhone: { __typename?: 'OwnPhone'; id: string; number: string };
};

export type RemoveOwnPhoneMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveOwnPhoneMutation = {
  __typename?: 'Mutation';
  removeOwnPhone: { __typename?: 'OperationStatus'; success: boolean };
};

export type PlanFragment = {
  __typename?: 'Plan';
  id: string;
  active: boolean;
  enterprise: boolean;
  base: boolean;
  default: boolean;
  type: PlanType;
  interval: BillingInterval;
  title: string;
  userTitle: string;
  product: Product;
  price: number;
  displayPrice: number;
  discount: number;
  secondary: boolean;
  lexorank?: string | null;
  mostPopular: boolean;
  monthlyPrice?: number | null;
  couponId?: string | null;
  features: Array<{
    __typename?: 'PlanFeature';
    type: FeatureType;
    title: string;
    tier?: number | null;
    limit?: number | null;
    min?: number | null;
    price?: number | null;
    trialLimits: {
      __typename?: 'PlanFeatureTrial';
      use: boolean;
      tier?: number | null;
      limit?: number | null;
    };
  }>;
} & { ' $fragmentName'?: 'PlanFragment' };

export type GetActivePlansQueryVariables = Exact<{ [key: string]: never }>;

export type GetActivePlansQuery = {
  __typename?: 'Query';
  getActivePlans: Array<
    { __typename?: 'Plan' } & {
      ' $fragmentRefs'?: { PlanFragment: PlanFragment };
    }
  >;
};

export type GetPlansQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlansQuery = {
  __typename?: 'Query';
  getPlans: Array<
    { __typename?: 'Plan' } & {
      ' $fragmentRefs'?: { PlanFragment: PlanFragment };
    }
  >;
};

export type MarketingPopupFragment = {
  __typename?: 'MarketingPopup';
  id: string;
  status: MarketingPopupStatus;
  audiences?: Array<MarketingPopupAudience> | null;
  title?: string | null;
  description?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  createdAt: string;
  updatedAt: string;
  template?: {
    __typename?: 'MarketingPopupTemplate';
    type: MarketingPopupTemplateType;
    imageSrc?: string | null;
    videoSrc?: string | null;
    buttonText?: string | null;
    buttonLink?: string | null;
  } | null;
} & { ' $fragmentName'?: 'MarketingPopupFragment' };

export type CreateMarketingPopupMutationVariables = Exact<{
  input: CreateMarketingPopupInput;
}>;

export type CreateMarketingPopupMutation = {
  __typename?: 'Mutation';
  createMarketingPopup: { __typename?: 'MarketingPopup' } & {
    ' $fragmentRefs'?: { MarketingPopupFragment: MarketingPopupFragment };
  };
};

export type GetMarketingPopupSignedUrlQueryVariables = Exact<{
  input: GenerateSignedUrlInput;
}>;

export type GetMarketingPopupSignedUrlQuery = {
  __typename?: 'Query';
  getMarketingPopupSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type FindMarketingPopupsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;

export type FindMarketingPopupsQuery = {
  __typename?: 'Query';
  findMarketingPopups: {
    __typename?: 'MarketingPopupList';
    total?: number | null;
    nextId?: string | null;
    hasNext?: boolean | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'MarketingPopup' } & {
        ' $fragmentRefs'?: { MarketingPopupFragment: MarketingPopupFragment };
      }
    >;
  };
};

export type RemoveMarketingPopupMutationVariables = Exact<{
  removeMarketingPopupId: Scalars['String']['input'];
}>;

export type RemoveMarketingPopupMutation = {
  __typename?: 'Mutation';
  removeMarketingPopup: { __typename?: 'OperationStatus'; success: boolean };
};

export type GetMarketingPopupQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetMarketingPopupQuery = {
  __typename?: 'Query';
  getMarketingPopup: {
    __typename?: 'MarketingPopup';
    id: string;
    status: MarketingPopupStatus;
    audiences?: Array<MarketingPopupAudience> | null;
    title?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
    template?: {
      __typename?: 'MarketingPopupTemplate';
      type: MarketingPopupTemplateType;
      imageSrc?: string | null;
      videoSrc?: string | null;
      buttonText?: string | null;
      buttonLink?: string | null;
    } | null;
  };
};

export type UpdateMarketingPopupMutationVariables = Exact<{
  input: UpdateMarketingPopupInput;
  id: Scalars['String']['input'];
}>;

export type UpdateMarketingPopupMutation = {
  __typename?: 'Mutation';
  updateMarketingPopup: {
    __typename?: 'MarketingPopup';
    id: string;
    status: MarketingPopupStatus;
    audiences?: Array<MarketingPopupAudience> | null;
    title?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
    template?: {
      __typename?: 'MarketingPopupTemplate';
      type: MarketingPopupTemplateType;
      imageSrc?: string | null;
      videoSrc?: string | null;
      buttonText?: string | null;
      buttonLink?: string | null;
    } | null;
  };
};

export type FindActiveMarketingPopupsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FindActiveMarketingPopupsQuery = {
  __typename?: 'Query';
  findActiveMarketingPopups: Array<{
    __typename?: 'MarketingPopup';
    id: string;
    status: MarketingPopupStatus;
    title?: string | null;
    description?: string | null;
    template?: {
      __typename?: 'MarketingPopupTemplate';
      type: MarketingPopupTemplateType;
      imageSrc?: string | null;
      videoSrc?: string | null;
      buttonText?: string | null;
      buttonLink?: string | null;
    } | null;
  }>;
};

export type MarkAccountPopupViewedMutationVariables = Exact<{
  input: MarkAccountPopupViewedInput;
}>;

export type MarkAccountPopupViewedMutation = {
  __typename?: 'Mutation';
  markAccountPopupViewed: { __typename?: 'AccountPopup'; popupId: string };
};

export type GetPowerDialerCampaignQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetPowerDialerCampaignQuery = {
  __typename?: 'Query';
  getDialerCampaign: {
    __typename?: 'DialerCampaign';
    id: string;
    status: CampaignStatus;
    lines: Array<string>;
    labels: Array<string>;
    script?: string | null;
    contactsWithOutcome?: number | null;
    contacts: number;
  };
};

export type FindPowerDialerOutcomeLabelsQueryVariables = Exact<{
  filter?: InputMaybe<LabelFilter>;
}>;

export type FindPowerDialerOutcomeLabelsQuery = {
  __typename?: 'Query';
  findLabels: {
    __typename?: 'LabelList';
    items: Array<{
      __typename?: 'Label';
      id: string;
      title: string;
      color: string;
    }>;
  };
};

export type GeneratePowerDialerWebRtcTokenQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GeneratePowerDialerWebRtcTokenQuery = {
  __typename?: 'Query';
  generatePowerDialerWebRTCToken: { __typename?: 'WebRTCToken'; token: string };
};

export type GetPowerDialerCallingBarContactQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetPowerDialerCallingBarContactQuery = {
  __typename?: 'Query';
  getContact: {
    __typename?: 'Contact';
    phone: string;
    firstName?: string | null;
    lastName?: string | null;
  };
};

export type ManagePowerDialerCallRecordingMutationVariables = Exact<{
  input: ManagePowerDialerCallRecordingInput;
}>;

export type ManagePowerDialerCallRecordingMutation = {
  __typename?: 'Mutation';
  managePowerDialerCallRecording: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type StartPowerDialingMutationVariables = Exact<{
  input: StartPowerDialingInput;
}>;

export type StartPowerDialingMutation = {
  __typename?: 'Mutation';
  startPowerDialing: { __typename?: 'OperationStatus'; success: boolean };
};

export type HangUpPowerDialerCallMutationVariables = Exact<{
  batchId: Scalars['String']['input'];
}>;

export type HangUpPowerDialerCallMutation = {
  __typename?: 'Mutation';
  hangUpPowerDialerCall: { __typename?: 'OperationStatus'; success: boolean };
};

export type AddLabelToCallAndContactMutationVariables = Exact<{
  input: AddLabelToCallAndContactInput;
}>;

export type AddLabelToCallAndContactMutation = {
  __typename?: 'Mutation';
  addLabelToCallAndContact: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type SwitchDialerOfflineMutationVariables = Exact<{
  inPowerDialer: Scalars['Boolean']['input'];
}>;

export type SwitchDialerOfflineMutation = {
  __typename?: 'Mutation';
  switchDialerOffline: { __typename?: 'OperationStatus'; success: boolean };
};

export type SwitchDialerOnlineMutationVariables = Exact<{
  campaignId: Scalars['String']['input'];
}>;

export type SwitchDialerOnlineMutation = {
  __typename?: 'Mutation';
  switchDialerOnline: { __typename?: 'OperationStatus'; success: boolean };
};

export type LineFragment = {
  __typename?: 'LineView';
  id: string;
  callId?: string | null;
  state: CallState;
  stateReason?: string | null;
  amdResult?: AmdResult | null;
  phone: string;
  contact: {
    __typename?: 'Contact';
    id: string;
    phone: string;
    firstName?: string | null;
    lastName?: string | null;
  };
} & { ' $fragmentName'?: 'LineFragment' };

export type PowerDialerStateUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type PowerDialerStateUpdatedSubscription = {
  __typename?: 'Subscription';
  powerDialerStateUpdated: {
    __typename?: 'PowerDialerStateUpdatedView';
    batchId: string;
    campaignId: string;
    connectedLineId?: string | null;
    currentOutboundNumber?: string | null;
    currentContactId?: string | null;
    establishedAt?: string | null;
    duration?: number | null;
    campaignIsCompleted?: boolean | null;
    showOutcomeLabels: boolean;
    isConnecting?: boolean | null;
    isDialing: boolean;
    hangupIsDisabled: boolean;
    isRecording?: boolean | null;
    lines: Array<
      { __typename?: 'LineView' } & {
        ' $fragmentRefs'?: { LineFragment: LineFragment };
      }
    >;
  };
};

export type PowerDialerFailedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type PowerDialerFailedSubscription = {
  __typename?: 'Subscription';
  powerDialerFailed: {
    __typename?: 'PowerDialerFailedView';
    errorType: PowerDialerFailedErrorType;
    errorMessage: string;
  };
};

export type GetCampaignReportQueryVariables = Exact<{
  filter: ReportsFilter;
}>;

export type GetCampaignReportQuery = {
  __typename?: 'Query';
  getCampaignReport: {
    __typename?: 'CampaignReport';
    totalStandardCampaigns: number;
    totalKeywordCampaigns: number;
  };
};

export type GetMessageReportQueryVariables = Exact<{
  filter: ReportsFilter;
}>;

export type GetMessageReportQuery = {
  __typename?: 'Query';
  getMessageReport: {
    __typename?: 'MessageReport';
    incomingTotal: number;
    outgoingTotal: number;
    campaignOutgoingTotal: number;
    campaignOutgoingDelivered: number;
    campaignOutgoingBlocked: number;
    campaignIncomingOptedOut: number;
    campaignAiFilteredMessages: number;
    medianResponseTime: string;
  };
};

export type GetLabelReportQueryVariables = Exact<{
  filter: ReportsFilter;
}>;

export type GetLabelReportQuery = {
  __typename?: 'Query';
  getLabelReport: Array<{
    __typename?: 'LabelReportView';
    id: string;
    title: string;
    contactsWithCurrentLabel: number;
    allLabeledContacts: number;
  }>;
};

export type FindCampaignsReportQueryVariables = Exact<{
  filter?: InputMaybe<CampaignFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindCampaignsReportQuery = {
  __typename?: 'Query';
  findCampaigns: {
    __typename?: 'CampaignList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'Campaign';
      id: string;
      type: CampaignType;
      name: string;
      sendFirstMessage?: boolean | null;
      sequences: Array<{
        __typename?: 'SequenceView';
        templates: Array<string>;
      }>;
    }>;
  };
};

export type GetContactReportQueryVariables = Exact<{
  filter: ReportsFilter;
}>;

export type GetContactReportQuery = {
  __typename?: 'Query';
  getContactReport: {
    __typename?: 'ContactReportView';
    leadCount: number;
    totalContacts: number;
    totalContactsResponded: number;
  };
};

export type GetDialerCallReportQueryVariables = Exact<{
  filter?: InputMaybe<DialerReportsFilter>;
}>;

export type GetDialerCallReportQuery = {
  __typename?: 'Query';
  getDialerCallReport: {
    __typename?: 'DialerCallReportView';
    answered: number;
    averageDuration: number;
    conversationRate: number;
    conversations: number;
    responseRate: number;
    total: number;
  };
};

export type GetDialerContactReportQueryVariables = Exact<{
  filter?: InputMaybe<DialerReportsFilter>;
}>;

export type GetDialerContactReportQuery = {
  __typename?: 'Query';
  getDialerContactReport: {
    __typename?: 'DialerContactReportView';
    deals: number;
    leads: number;
  };
};

export type GetDialerCallOutcomeReportQueryVariables = Exact<{
  filter?: InputMaybe<DialerReportsFilter>;
}>;

export type GetDialerCallOutcomeReportQuery = {
  __typename?: 'Query';
  getDialerCallOutcomeReport: Array<{
    __typename?: 'DialerCallOutcomeReportView';
    title: string;
    contactsWithCurrentLabel: number;
    allLabeledContacts: number;
  }>;
};

export type SessionUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type SessionUpdatedSubscription = {
  __typename?: 'Subscription';
  sessionUpdated: { __typename?: 'Session'; online: boolean };
};

export type SkiptraceFieldFragment = {
  __typename?: 'SkiptraceField';
  id: string;
  name: string;
  predefined: boolean;
  key?: SkiptracePredefinedField | null;
} & { ' $fragmentName'?: 'SkiptraceFieldFragment' };

export type FindSkiptracesQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  order?: InputMaybe<Order>;
}>;

export type FindSkiptracesQuery = {
  __typename?: 'Query';
  findSkiptraces: {
    __typename?: 'SkiptraceList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<{
      __typename?: 'Skiptrace';
      id: string;
      fileName: string;
      mobileContacts?: number | null;
      landlineContacts?: number | null;
      createdAt: string;
      excludeFirstRow?: boolean | null;
      fields?: Array<
        { __typename?: 'SkiptraceField' } & {
          ' $fragmentRefs'?: { SkiptraceFieldFragment: SkiptraceFieldFragment };
        }
      > | null;
    }>;
  };
};

export type FindSkiptraceFieldsQueryVariables = Exact<{ [key: string]: never }>;

export type FindSkiptraceFieldsQuery = {
  __typename?: 'Query';
  findSkiptraceFields: Array<
    { __typename?: 'SkiptraceField' } & {
      ' $fragmentRefs'?: { SkiptraceFieldFragment: SkiptraceFieldFragment };
    }
  >;
};

export type GetSkiptraceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetSkiptraceQuery = {
  __typename?: 'Query';
  getSkiptrace: {
    __typename?: 'Skiptrace';
    id: string;
    fileName: string;
    mobileContacts?: number | null;
    landlineContacts?: number | null;
    createdAt: string;
    excludeFirstRow?: boolean | null;
    processedContacts?: number | null;
    foundContacts?: number | null;
    cachedContacts?: number | null;
    fields?: Array<
      { __typename?: 'SkiptraceField' } & {
        ' $fragmentRefs'?: { SkiptraceFieldFragment: SkiptraceFieldFragment };
      }
    > | null;
  };
};

export type RemoveSkiptracesMutationVariables = Exact<{
  input: RemoveSkiptracesInput;
}>;

export type RemoveSkiptracesMutation = {
  __typename?: 'Mutation';
  removeSkiptraces: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateSkiptraceMutationVariables = Exact<{
  input: UpdateSkiptraceInput;
  updateSkiptraceId: Scalars['String']['input'];
}>;

export type UpdateSkiptraceMutation = {
  __typename?: 'Mutation';
  updateSkiptrace: {
    __typename?: 'Skiptrace';
    id: string;
    fileName: string;
    mobileContacts?: number | null;
    landlineContacts?: number | null;
    createdAt: string;
    excludeFirstRow?: boolean | null;
    fields?: Array<
      { __typename?: 'SkiptraceField' } & {
        ' $fragmentRefs'?: { SkiptraceFieldFragment: SkiptraceFieldFragment };
      }
    > | null;
  };
};

export type RunSkiptraceMutationVariables = Exact<{
  input: RunSkiptraceInput;
}>;

export type RunSkiptraceMutation = {
  __typename?: 'Mutation';
  runSkiptrace: { __typename?: 'OperationStatus'; success: boolean };
};

export type ExportSkiptraceQueryVariables = Exact<{
  input: ExportSkiptraceInput;
}>;

export type ExportSkiptraceQuery = {
  __typename?: 'Query';
  exportSkiptrace: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type PushSkiptraceToGroupMutationVariables = Exact<{
  input: PushSkiptraceToGroupInput;
}>;

export type PushSkiptraceToGroupMutation = {
  __typename?: 'Mutation';
  pushSkiptraceToGroup: {
    __typename?: 'ListView';
    id: string;
    type: ListType;
    fields?: Array<{
      __typename?: 'Field';
      id: string;
      name: string;
      predefined: boolean;
      key?: PredefinedField | null;
    }> | null;
  };
};

export type SkiptraceProgressSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type SkiptraceProgressSubscription = {
  __typename?: 'Subscription';
  skiptraceProgress: {
    __typename?: 'SkiptraceProgress';
    skiptraceId: string;
    progress: number;
  };
};

export type SkiptraceCompletedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type SkiptraceCompletedSubscription = {
  __typename?: 'Subscription';
  skiptraceCompleted: {
    __typename?: 'SkiptraceCompleted';
    skiptraceId: string;
  };
};

export type SubscriptionFragment = {
  __typename?: 'CurrentSubscription';
  id: string;
  status: SubscriptionStatus;
  startedAt?: string | null;
  endAt?: string | null;
  updatedAt?: string | null;
  nextBillingDate?: string | null;
  trialEndedAt?: string | null;
  membershipMonths?: number | null;
  product: Product;
  reason?: string | null;
  base: boolean;
  price: number;
  secondary: boolean;
  title: string;
  discount?: {
    __typename?: 'Discount';
    amount: number;
    coupon: string;
    promoCode?: string | null;
    expiresAt?: string | null;
    percent?: number | null;
    startedAt?: string | null;
  } | null;
  plan?:
    | ({ __typename?: 'Plan' } & {
        ' $fragmentRefs'?: { PlanFragment: PlanFragment };
      })
    | null;
} & { ' $fragmentName'?: 'SubscriptionFragment' };

export type SubscribeMutationVariables = Exact<{
  input: SubscribeInput;
}>;

export type SubscribeMutation = {
  __typename?: 'Mutation';
  subscribe: { __typename?: 'CurrentSubscription' } & {
    ' $fragmentRefs'?: { SubscriptionFragment: SubscriptionFragment };
  };
};

export type UnsubscribeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
}>;

export type UnsubscribeMutation = {
  __typename?: 'Mutation';
  unsubscribe: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateSubscriptionMutationVariables = Exact<{
  input: UpdateSubscriptionInput;
  id: Scalars['String']['input'];
}>;

export type UpdateSubscriptionMutation = {
  __typename?: 'Mutation';
  updateSubscription: { __typename?: 'CurrentSubscription' } & {
    ' $fragmentRefs'?: { SubscriptionFragment: SubscriptionFragment };
  };
};

export type EndTrialMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: EndTrialInput;
}>;

export type EndTrialMutation = {
  __typename?: 'Mutation';
  endTrial: { __typename?: 'CurrentSubscription' } & {
    ' $fragmentRefs'?: { SubscriptionFragment: SubscriptionFragment };
  };
};

export type VerifyPromoCodeMutationVariables = Exact<{
  input: VerifyPromoCodeInput;
}>;

export type VerifyPromoCodeMutation = {
  __typename?: 'Mutation';
  verifyPromoCode: {
    __typename?: 'PromoCode';
    id: string;
    code: string;
    coupon: {
      __typename?: 'Coupon';
      id: string;
      amountOff?: number | null;
      percentOff?: number | null;
    };
  };
};

export type ApplyDiscountMutationVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
  input: ApplyDiscountInput;
}>;

export type ApplyDiscountMutation = {
  __typename?: 'Mutation';
  applyDiscount: {
    __typename?: 'Discount';
    amount: number;
    coupon: string;
    startedAt?: string | null;
    promoCode?: string | null;
    expiresAt?: string | null;
    percent?: number | null;
  };
};

export type SubscriptionsQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionsQuery = {
  __typename?: 'Query';
  subscriptions: Array<{
    __typename?: 'CurrentSubscription';
    id: string;
    product: Product;
    status: SubscriptionStatus;
    price: number;
    title: string;
    nextBillingDate?: string | null;
    startedAt?: string | null;
    endAt?: string | null;
    updatedAt?: string | null;
    trialEndedAt?: string | null;
    membershipMonths?: number | null;
    reason?: string | null;
    base: boolean;
    secondary: boolean;
    discount?: {
      __typename?: 'Discount';
      amount: number;
      coupon: string;
      percent?: number | null;
      promoCode?: string | null;
      startedAt?: string | null;
      expiresAt?: string | null;
    } | null;
    plan?:
      | ({ __typename?: 'Plan' } & {
          ' $fragmentRefs'?: { PlanFragment: PlanFragment };
        })
      | null;
  }>;
};

export type RetrieveUpcomingInvoiceQueryVariables = Exact<{
  products: Array<Product> | Product;
  subAccountId?: InputMaybe<Scalars['String']['input']>;
}>;

export type RetrieveUpcomingInvoiceQuery = {
  __typename?: 'Query';
  retrieveUpcomingInvoice: {
    __typename?: 'UpcomingInvoice';
    totalBilledNow: number;
  };
};

export type SuppressionFragment = {
  __typename?: 'Suppression';
  id: string;
  number: string;
  createdAt: string;
  source: SuppressionSource;
  firstName?: string | null;
  lastName?: string | null;
} & { ' $fragmentName'?: 'SuppressionFragment' };

export type FindSuppressionsQueryVariables = Exact<{
  filter?: InputMaybe<SuppressionsFilter>;
  pagination?: InputMaybe<Pagination>;
}>;

export type FindSuppressionsQuery = {
  __typename?: 'Query';
  findSuppressions: {
    __typename?: 'SuppressionList';
    total?: number | null;
    hasNext?: boolean | null;
    nextId?: string | null;
    nextCreatedAt?: string | null;
    items: Array<
      { __typename?: 'Suppression' } & {
        ' $fragmentRefs'?: { SuppressionFragment: SuppressionFragment };
      }
    >;
  };
};

export type SuppressionsSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type SuppressionsSummaryQuery = {
  __typename?: 'Query';
  suppressionsSummary: {
    __typename?: 'SuppressionsSummary';
    dnc?: number | null;
    unsubscribed?: number | null;
    filtered?: number | null;
    undeliverable?: number | null;
    carrierBlocked?: number | null;
  };
};

export type ExportSuppressionsQueryVariables = Exact<{
  input: ExportSuppressionsInput;
}>;

export type ExportSuppressionsQuery = {
  __typename?: 'Query';
  exportSuppressions: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type GetUploadDncPhonesSignedUrlQueryVariables = Exact<{
  input: GenerateSignedUrlInput;
}>;

export type GetUploadDncPhonesSignedUrlQuery = {
  __typename?: 'Query';
  getUploadDNCPhonesSignedUrl: {
    __typename?: 'SignedUrl';
    signedUrl: string;
    filePath: string;
  };
};

export type UploadDncPhonesMutationVariables = Exact<{
  input: UploadDncPhonesInput;
}>;

export type UploadDncPhonesMutation = {
  __typename?: 'Mutation';
  uploadDNCPhones: { __typename?: 'OperationStatus'; success: boolean };
};

export type CreateDncPhoneMutationVariables = Exact<{
  input: CreateDncPhoneInput;
}>;

export type CreateDncPhoneMutation = {
  __typename?: 'Mutation';
  createDNCPhone: { __typename?: 'Suppression' } & {
    ' $fragmentRefs'?: { SuppressionFragment: SuppressionFragment };
  };
};

export type AddContactToDncMutationVariables = Exact<{
  input: AddContactToDncInput;
}>;

export type AddContactToDncMutation = {
  __typename?: 'Mutation';
  addContactToDNC: { __typename?: 'Suppression' } & {
    ' $fragmentRefs'?: { SuppressionFragment: SuppressionFragment };
  };
};

export type RemoveContactFromDncSuppressionsMutationVariables = Exact<{
  input: RemoveContactFromDncSuppressionsInput;
}>;

export type RemoveContactFromDncSuppressionsMutation = {
  __typename?: 'Mutation';
  removeContactFromDNCSuppressions: {
    __typename?: 'OperationStatus';
    success: boolean;
  };
};

export type RemoveSuppressionsMutationVariables = Exact<{
  input: RemoveSuppressionsInput;
}>;

export type RemoveSuppressionsMutation = {
  __typename?: 'Mutation';
  removeSuppressions: { __typename?: 'OperationStatus'; success: boolean };
};

export type TollFreeBrandFragment = {
  __typename?: 'TollFreeBrand';
  companyName: string;
  usecase: TollFreeBrandUseCase;
  messageVolume: string;
  country: string;
  state: string;
  city: string;
  address1: string;
  address2: string;
  zip: string;
  firstName: string;
  lastName: string;
  phone: string;
  website: string;
  email: string;
  description: string;
  sampleMessage: string;
  optInWorkflow: string;
  optInWorkflowFiles: Array<{
    __typename?: 'BrandRegistrationFile';
    filePath: string;
    contentType: string;
    url: string;
  }>;
} & { ' $fragmentName'?: 'TollFreeBrandFragment' };

export type GetTollFreeRegistrationInfoQueryVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type GetTollFreeRegistrationInfoQuery = {
  __typename?: 'Query';
  getTollFreeRegistrationInfo: {
    __typename?: 'TollFreeRegistration';
    brand: { __typename?: 'TollFreeBrand' } & {
      ' $fragmentRefs'?: { TollFreeBrandFragment: TollFreeBrandFragment };
    };
    ownPhone?:
      | ({ __typename?: 'OwnPhone' } & {
          ' $fragmentRefs'?: { OwnPhoneFragment: OwnPhoneFragment };
        })
      | null;
  };
};

export type RegisterTollFreeMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type RegisterTollFreeMutation = {
  __typename?: 'Mutation';
  registerTollFree: { __typename?: 'OperationStatus'; success: boolean };
};

export type UpdateTollFreeRegistrationInfoMutationVariables = Exact<{
  input: UpdateTollFreeRegistrationInfoInput;
  accountId: Scalars['String']['input'];
}>;

export type UpdateTollFreeRegistrationInfoMutation = {
  __typename?: 'Mutation';
  updateTollFreeRegistrationInfo: {
    __typename?: 'TollFreeRegistration';
    brand: { __typename?: 'TollFreeBrand' } & {
      ' $fragmentRefs'?: { TollFreeBrandFragment: TollFreeBrandFragment };
    };
    ownPhone?:
      | ({ __typename?: 'OwnPhone' } & {
          ' $fragmentRefs'?: { OwnPhoneFragment: OwnPhoneFragment };
        })
      | null;
  };
};

export const A2PBrandFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'A2PBrand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'A2PBrand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vertical' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ein' } },
          { kind: 'Field', name: { kind: 'Name', value: 'einIssuingCountry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createWebsite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCarePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCareEmail' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termsAndConditionsUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'einFile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'incorporationDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<A2PBrandFragment, unknown>;
export const A2PBrandCampaignFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'A2PBrandCampaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'A2PBrandCampaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usecase' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sample1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sample2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageFlow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendPhones' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendDirectLending' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendAgeGatedContent' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendAffiliateMarketing' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendOptIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optInKeywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optInMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendOptOut' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optOutKeywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optOutMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendHelp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpKeywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpMessage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<A2PBrandCampaignFragment, unknown>;
export const PlanFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enterprise' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lexorank' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialLimits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'use' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mostPopular' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'couponId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlanFragment, unknown>;
export const BillingProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BillingProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BillingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'balance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topUpAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topUpBonus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstTopUpBonusApplied' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastTopUp' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bonus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topUpAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unsubscribeCoupons' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backupPaymentMethod' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BillingProfileFragment, unknown>;
export const SubAccountFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubAccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySubscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coupon' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'products' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubAccountFragmentFragment, unknown>;
export const SubscriptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Subscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CurrentSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextBillingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'membershipMonths' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coupon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enterprise' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lexorank' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialLimits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'use' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mostPopular' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'couponId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionFragment, unknown>;
export const MessagingProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessagingProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessagingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activeIn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSipEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alertFilter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaigns' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationFilter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaigns' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationsEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'callAutoReply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'voiceMail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activeIn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSipEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'callAutoReply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'voiceMail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'allowUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'a2pInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxTpm' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tollFreeInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxTpm' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'limits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'daily' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEINDocRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responseRemoval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responseFilters' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'provider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sipPassword' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sipUsername' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessagingProfileFragment, unknown>;
export const AccountFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'products' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondaryPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'affiliate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'referralId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referralLink' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BillingProfile' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subAccounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubAccountFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionnaire' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'realEstate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openNewTab' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultLabelColor' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'viewedPopups' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionnaire' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'realEstate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'experience' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingChannels' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingTraining' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smsMarketing' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messagingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessagingProfile' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotasUsage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'used' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dialerStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnboarded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTest' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutDisabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowOptOutDisable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowCrmIntegrations' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowDripCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowKeywordCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowScheduleCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowThrottleCampaign' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'inBusinessHours' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enterprise' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lexorank' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialLimits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'use' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mostPopular' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'couponId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BillingProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BillingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'balance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topUpAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topUpBonus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstTopUpBonusApplied' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastTopUp' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bonus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topUpAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unsubscribeCoupons' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backupPaymentMethod' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubAccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySubscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coupon' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'products' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Subscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CurrentSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextBillingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'membershipMonths' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coupon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessagingProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessagingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activeIn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSipEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alertFilter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaigns' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationFilter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaigns' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationsEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'callAutoReply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'voiceMail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activeIn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSipEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'callAutoReply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'voiceMail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'allowUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'a2pInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxTpm' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tollFreeInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxTpm' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'limits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'daily' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEINDocRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responseRemoval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responseFilters' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'provider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sipPassword' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sipUsername' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountFragmentFragment, unknown>;
export const AdminAccountFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminAccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutDisabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowOptOutDisable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowCrmIntegrations' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowDripCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowKeywordCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowScheduleCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowThrottleCampaign' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSubAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnboarded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openNewTab' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionnaire' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'realEstate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'experience' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingChannels' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingTraining' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smsMarketing' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'a2PStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tollFreeStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'affiliate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'referralId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referralLink' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'membershipMonths' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialEndedAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userTitle' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextBillingDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coupon' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'percent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promoCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expiresAt' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialEndedAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'membershipMonths' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'base' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enterprise' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'base' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interval' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userTitle' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayPrice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'features' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tier' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'limit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'min' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'trialLimits' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'use' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tier' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'limit' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mostPopular' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthlyPrice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'couponId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messagingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allowUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'a2pInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxTpm' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaignId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brandId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusUpdatedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'incorporationDate' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tollFreeInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxTpm' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'limits' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'daily' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'weekly' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'monthly' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'balance' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastTopUp' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'topUpAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topUpAmount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalSmsUsed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalSkiptraceUsed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalMembershipFees' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productsFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalBilledToDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalBilled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastBilling' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminAccountFragmentFragment, unknown>;
export const AffiliateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Affiliate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Affiliate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AffiliateFragment, unknown>;
export const ArticleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Article' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Article' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArticleFragment, unknown>;
export const PaymentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Payment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Payment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feature' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'method' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaymentFragment, unknown>;
export const PaymentSourceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaymentSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaymentSourceFragment, unknown>;
export const CalendarEventFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CalendarEventFragment, unknown>;
export const CallScriptFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallScript' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CallScript' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CallScriptFragment, unknown>;
export const StateFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ttl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StateFragmentFragment, unknown>;
export const SequenceFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SequenceView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'days' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomTimeRestrictions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeRange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'from' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minute' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'to' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minute' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeFromNextSequences' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SequenceFragmentFragment, unknown>;
export const GroupFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeChats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeCloned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupFragmentFragment, unknown>;
export const KeywordTemplateFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeywordTemplateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KeywordTemplateFragmentFragment, unknown>;
export const CostFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'captured' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refunded' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostFragmentFragment, unknown>;
export const StatsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StatsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignStats' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'smsUsed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smsRefunded' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatsFragmentFragment, unknown>;
export const ScheduleOptionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ScheduleOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignScheduleOptions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'perBatch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomBatchSize' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomStartDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScheduleOptionsFragment, unknown>;
export const CampaignFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickThroughStartAfter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickThroughProgress' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownPhones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'abbreviation' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'state' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StateFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SequenceFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendFirstMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keyword' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KeywordTemplateFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CostFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StatsFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduleOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ScheduleOptions' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeFromNextSequences' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ttl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SequenceView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'days' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomTimeRestrictions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeRange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'from' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minute' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'to' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minute' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeFromNextSequences' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeChats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeCloned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeywordTemplateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'captured' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refunded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StatsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignStats' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'smsUsed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smsRefunded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ScheduleOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignScheduleOptions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'perBatch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomBatchSize' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomStartDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignFragmentFragment, unknown>;
export const CampaignScheduledMessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignScheduledMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ScheduledMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'template' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optOut' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attrs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignScheduledMessageFragment, unknown>;
export const ContactNoteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attachments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactNoteFragment, unknown>;
export const LabelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Label' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scopes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readOnly' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LabelFragment, unknown>;
export const ContactAttributeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAttribute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactAttributeFragment, unknown>;
export const RealEstateAttrsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RealEstateAttrs' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactRealEstateAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RealEstateAttrsFragment, unknown>;
export const ContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unsubscribed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealClosed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Label' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attrs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactAttribute' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'realEstateAttrs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attrs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RealEstateAttrs' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Label' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scopes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readOnly' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAttribute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RealEstateAttrs' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactRealEstateAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactFragment, unknown>;
export const ContactPreviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactPreview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealClosed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'default' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'readOnly' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scopes' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactPreviewFragment, unknown>;
export const AttachmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Attachment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'path' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AttachmentFragment, unknown>;
export const SingleMessageCampaignFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleMessageCampaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleMessageCampaignFragment, unknown>;
export const SingleMessageTemplateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleMessageTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFilePath' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleMessageTemplateFragment, unknown>;
export const SingleMessageKeywordFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleMessageKeyword' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleMessageKeywordFragment, unknown>;
export const MessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Attachment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isConversation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManualRecording' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SingleMessageCampaign' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SingleMessageTemplate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keyword' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SingleMessageKeyword' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dialerCampaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fromNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Attachment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'path' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleMessageCampaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleMessageTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFilePath' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleMessageKeyword' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageFragment, unknown>;
export const LastMessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LastMessageFragment, unknown>;
export const ConversationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Conversation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Conversation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAiFiltered' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastMessageAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LastMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConversationFragment, unknown>;
export const OwnPhoneFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OwnPhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OwnPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagingEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OwnPhoneFragment, unknown>;
export const DialerCampaignFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DialerCampaignFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DialerCampaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastCallAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'script' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phones' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownPhones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OwnPhone' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lines' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OwnPhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OwnPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagingEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DialerCampaignFragmentFragment, unknown>;
export const DialerCampaignDetailedFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DialerCampaignDetailedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DialerCampaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastCallAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'script' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phones' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactsWithOutcome' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lines' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownPhones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OwnPhone' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OwnPhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OwnPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagingEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DialerCampaignDetailedFragmentFragment, unknown>;
export const FieldFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Field' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Field' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FieldFragment, unknown>;
export const FieldsTemplateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FieldsTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FieldsTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Field' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Field' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Field' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FieldsTemplateFragment, unknown>;
export const GroupViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeChats' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dialerCampaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeCloned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupViewFragment, unknown>;
export const CrmIntegrationFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'crmIntegrationFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CrmIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CrmIntegrationFragmentFragment, unknown>;
export const CrmIntegrationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'crmIntegrationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CrmIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'crmIntegrationFragment' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'provider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelsPropertyStatuses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'default' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firestoreId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendNotes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelsPhoneStatuses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'default' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firestoreId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelsPhoneTags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firestoreId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'crmIntegrationFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CrmIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CrmIntegrationFieldsFragment, unknown>;
export const KeywordTemplateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeywordTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KeywordTemplateFragment, unknown>;
export const RankResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'afterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beforeId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RankResultFragment, unknown>;
export const ListOptionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListOptions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'removeFiltered' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUnsubscribed' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInternalDnc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeExisting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeNonSmsCapable' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'removeLitigators' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeNationalDnc' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeCarrierBlocked' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeGlobalUnsubscribed' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUndeliverable' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListOptionsFragment, unknown>;
export const ListVerificationResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListVerificationResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListVerificationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'listId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPhones' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verifiedPhones' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filtered' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unsubscribed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalDnc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'existing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nonSmsCapable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'litigators' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalDnc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'undeliverable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrierBlocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalUnsubscribed' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListVerificationResultFragment, unknown>;
export const MacroFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Macro' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Macro' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MacroFragment, unknown>;
export const MessageTemplateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFileBucket' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFilePath' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFileUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageTemplateFragment, unknown>;
export const DetailsNotificationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailsNotification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NotificationDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isKeywordCampaign' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarEventName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarEventDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarEventId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DetailsNotificationFragment, unknown>;
export const NotificationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Notification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Notification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DetailsNotification' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailsNotification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NotificationDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isKeywordCampaign' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarEventName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarEventDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarEventId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NotificationFragment, unknown>;
export const NotificationSettingsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NotificationSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'enabledCampaigns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabledPayments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabledCalendar' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NotificationSettingsFragment, unknown>;
export const OwnPhoneReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OwnPhoneReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OwnPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callingEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportDialer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'phoneId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outboundCalls' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'messagingEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'phoneId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outgoingMessages' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blockedRateMessages' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeConversations' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OwnPhoneReportFragment, unknown>;
export const MarketingPopupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingPopup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingPopup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'audiences' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageSrc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoSrc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarketingPopupFragment, unknown>;
export const LineFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Line' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LineView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stateReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amdResult' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LineFragment, unknown>;
export const SkiptraceFieldFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SkiptraceField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SkiptraceField' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SkiptraceFieldFragment, unknown>;
export const SuppressionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Suppression' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Suppression' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SuppressionFragment, unknown>;
export const TollFreeBrandFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TollFreeBrand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TollFreeBrand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usecase' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageVolume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sampleMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optInWorkflow' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'optInWorkflowFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TollFreeBrandFragment, unknown>;
export const GetA2PRegistrationInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetA2PRegistrationInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getA2PRegistrationInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'A2PBrand' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'A2PBrandCampaign' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ownPhone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OwnPhone' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'A2PBrand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'A2PBrand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vertical' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ein' } },
          { kind: 'Field', name: { kind: 'Name', value: 'einIssuingCountry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createWebsite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCarePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCareEmail' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termsAndConditionsUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'einFile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'incorporationDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'A2PBrandCampaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'A2PBrandCampaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usecase' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sample1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sample2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageFlow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendPhones' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendDirectLending' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendAgeGatedContent' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendAffiliateMarketing' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendOptIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optInKeywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optInMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendOptOut' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optOutKeywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optOutMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendHelp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpKeywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OwnPhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OwnPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagingEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetA2PRegistrationInfoQuery,
  GetA2PRegistrationInfoQueryVariables
>;
export const UpdateA2PRegistrationInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateA2PRegistrationInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateA2PRegistrationInfoInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateA2PRegistrationInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'A2PBrand' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'A2PBrandCampaign' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ownPhone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OwnPhone' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'A2PBrand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'A2PBrand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vertical' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ein' } },
          { kind: 'Field', name: { kind: 'Name', value: 'einIssuingCountry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createWebsite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCarePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCareEmail' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termsAndConditionsUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'einFile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'incorporationDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'A2PBrandCampaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'A2PBrandCampaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usecase' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sample1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sample2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageFlow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendPhones' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendDirectLending' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendAgeGatedContent' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendAffiliateMarketing' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendOptIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optInKeywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optInMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendOptOut' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optOutKeywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optOutMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendHelp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpKeywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OwnPhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OwnPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagingEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateA2PRegistrationInfoMutation,
  UpdateA2PRegistrationInfoMutationVariables
>;
export const RegisterA2PDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegisterA2P' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerA2P' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegisterA2PMutation, RegisterA2PMutationVariables>;
export const RegisterSubAccountA2PDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegisterSubAccountA2P' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerSubAccountA2P' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterSubAccountA2PMutation,
  RegisterSubAccountA2PMutationVariables
>;
export const ReactivateA2PDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReactivateA2P' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reactivateA2P' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReactivateA2PMutation,
  ReactivateA2PMutationVariables
>;
export const GetA2PReactivationInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetA2PReactivationInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getA2PReactivationInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'A2PBrand' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'A2PBrandCampaign' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ownPhone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OwnPhone' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'A2PBrand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'A2PBrand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vertical' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ein' } },
          { kind: 'Field', name: { kind: 'Name', value: 'einIssuingCountry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createWebsite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCarePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerCareEmail' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termsAndConditionsUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'einFile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'incorporationDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'A2PBrandCampaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'A2PBrandCampaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usecase' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sample1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sample2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageFlow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendPhones' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendDirectLending' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendAgeGatedContent' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendAffiliateMarketing' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendOptIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optInKeywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optInMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendOptOut' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optOutKeywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optOutMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendHelp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpKeywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OwnPhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OwnPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagingEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetA2PReactivationInfoQuery,
  GetA2PReactivationInfoQueryVariables
>;
export const GetEinFileUploadSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEINFileUploadSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GenerateSignedUrlInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEINFileUploadSignedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEinFileUploadSignedUrlQuery,
  GetEinFileUploadSignedUrlQueryVariables
>;
export const SubmitA2PReactivationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitA2PReactivation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitA2PReactivation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitA2PReactivationMutation,
  SubmitA2PReactivationMutationVariables
>;
export const SubmitA2PRegistrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitA2PRegistration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubmitA2PRegistrationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitA2PRegistration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitA2PRegistrationMutation,
  SubmitA2PRegistrationMutationVariables
>;
export const SubmitA2PSubAccountRegistrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitA2PSubAccountRegistration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'SubmitA2PSubAccountRegistrationInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitA2PSubAccountRegistration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitA2PSubAccountRegistrationMutation,
  SubmitA2PSubAccountRegistrationMutationVariables
>;
export const GetOptInWorkflowFileSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOptInWorkflowFileSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GenerateSignedUrlInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOptInWorkflowFileSignedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOptInWorkflowFileSignedUrlQuery,
  GetOptInWorkflowFileSignedUrlQueryVariables
>;
export const SubmitTollFreeRegistrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitTollFreeRegistration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TollFreeBrandInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitTollFreeRegistration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitTollFreeRegistrationMutation,
  SubmitTollFreeRegistrationMutationVariables
>;
export const UploadEinFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadEINFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UploadEINFileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadEINFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadEinFileMutation,
  UploadEinFileMutationVariables
>;
export const AccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Account' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSubAccount' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AccountFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enterprise' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lexorank' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialLimits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'use' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mostPopular' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'couponId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BillingProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BillingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'balance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topUpAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topUpBonus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstTopUpBonusApplied' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastTopUp' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bonus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topUpAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unsubscribeCoupons' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backupPaymentMethod' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubAccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySubscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coupon' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'products' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Subscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CurrentSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextBillingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'membershipMonths' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coupon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessagingProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessagingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activeIn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSipEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alertFilter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaigns' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationFilter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaigns' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationsEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'callAutoReply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'voiceMail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activeIn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSipEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'callAutoReply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'voiceMail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'allowUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'a2pInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxTpm' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tollFreeInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxTpm' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'limits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'daily' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEINDocRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responseRemoval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responseFilters' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'provider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sipPassword' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sipUsername' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'products' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondaryPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'affiliate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'referralId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referralLink' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BillingProfile' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subAccounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubAccountFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionnaire' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'realEstate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openNewTab' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultLabelColor' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'viewedPopups' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionnaire' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'realEstate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'experience' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingChannels' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingTraining' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smsMarketing' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messagingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessagingProfile' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotasUsage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'used' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dialerStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnboarded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTest' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutDisabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowOptOutDisable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowCrmIntegrations' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowDripCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowKeywordCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowScheduleCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowThrottleCampaign' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'inBusinessHours' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountQuery, AccountQueryVariables>;
export const AccountBillingSourcesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountBillingSources' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSubAccount' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AccountFragment' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BillingProfile' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sources' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PaymentSource' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enterprise' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lexorank' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialLimits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'use' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mostPopular' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'couponId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BillingProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BillingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'balance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topUpAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topUpBonus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstTopUpBonusApplied' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastTopUp' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bonus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topUpAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unsubscribeCoupons' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backupPaymentMethod' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubAccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySubscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coupon' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'products' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Subscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CurrentSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextBillingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'membershipMonths' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coupon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessagingProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessagingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activeIn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSipEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alertFilter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaigns' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationFilter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaigns' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationsEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'callAutoReply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'voiceMail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activeIn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSipEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'callAutoReply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'voiceMail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'allowUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'a2pInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxTpm' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tollFreeInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxTpm' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'limits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'daily' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEINDocRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responseRemoval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responseFilters' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'provider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sipPassword' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sipUsername' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'products' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondaryPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'affiliate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'referralId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referralLink' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BillingProfile' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subAccounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubAccountFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionnaire' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'realEstate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openNewTab' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultLabelColor' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'viewedPopups' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionnaire' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'realEstate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'experience' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingChannels' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingTraining' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smsMarketing' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messagingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessagingProfile' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotasUsage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'used' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dialerStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnboarded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTest' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutDisabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowOptOutDisable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowCrmIntegrations' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowDripCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowKeywordCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowScheduleCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowThrottleCampaign' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'inBusinessHours' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaymentSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountBillingSourcesQuery,
  AccountBillingSourcesQueryVariables
>;
export const CreateImpersonateTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateImpersonateToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createImpersonateToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateImpersonateTokenMutation,
  CreateImpersonateTokenMutationVariables
>;
export const UpdateAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAccountInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AccountFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enterprise' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lexorank' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialLimits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'use' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mostPopular' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'couponId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BillingProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BillingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'balance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topUpAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topUpBonus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstTopUpBonusApplied' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastTopUp' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bonus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topUpAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unsubscribeCoupons' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backupPaymentMethod' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubAccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySubscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coupon' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'products' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Subscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CurrentSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextBillingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'membershipMonths' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coupon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessagingProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessagingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activeIn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSipEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alertFilter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaigns' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationFilter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaigns' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationsEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'callAutoReply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'voiceMail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activeIn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSipEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'callAutoReply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'voiceMail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'allowUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'a2pInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxTpm' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tollFreeInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxTpm' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'limits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'daily' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEINDocRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responseRemoval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responseFilters' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'provider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sipPassword' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sipUsername' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'products' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondaryPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'affiliate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'referralId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referralLink' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BillingProfile' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subAccounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubAccountFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionnaire' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'realEstate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openNewTab' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultLabelColor' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'viewedPopups' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionnaire' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'realEstate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'experience' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingChannels' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingTraining' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smsMarketing' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messagingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessagingProfile' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotasUsage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'used' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dialerStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnboarded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTest' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutDisabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowOptOutDisable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowCrmIntegrations' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowDripCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowKeywordCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowScheduleCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowThrottleCampaign' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'inBusinessHours' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const SignUpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SignUp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SignUpInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signUp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignUpMutation, SignUpMutationVariables>;
export const CreateSubaccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSubaccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSubaccountInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSubaccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubAccountFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubAccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySubscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coupon' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'products' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSubaccountMutation,
  CreateSubaccountMutationVariables
>;
export const UpdateSubaccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSubaccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSubaccountInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSubaccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubAccountFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubAccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySubscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coupon' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'products' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSubaccountMutation,
  UpdateSubaccountMutationVariables
>;
export const RemoveSubaccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveSubaccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeSubaccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveSubaccountMutation,
  RemoveSubaccountMutationVariables
>;
export const AccountUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'AccountUpdated' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountUpdated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'operation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'operationAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountUpdatedSubscription,
  AccountUpdatedSubscriptionVariables
>;
export const ResendPasswordResetTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResendPasswordResetToken' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendPasswordResetToken' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResendPasswordResetTokenMutation,
  ResendPasswordResetTokenMutationVariables
>;
export const ConfirmPasswordResetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmPasswordReset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmPasswordReset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmPasswordResetMutation,
  ConfirmPasswordResetMutationVariables
>;
export const AcceptTermsAndConditionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptTermsAndConditions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptTermsAndConditions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptTermsAndConditionsMutation,
  AcceptTermsAndConditionsMutationVariables
>;
export const AccountOutsideBusinessHoursRestrictionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'AccountOutsideBusinessHoursRestrictions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outsideBusinessHoursRestrictions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'restriction' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountOutsideBusinessHoursRestrictionsSubscription,
  AccountOutsideBusinessHoursRestrictionsSubscriptionVariables
>;
export const GetAccountsCountersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountsCounters' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAccountsCounters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activePrimary' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeSecondary' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'trial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inactive' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthNewActive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAccountsCountersQuery,
  GetAccountsCountersQueryVariables
>;
export const GetAccountsSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountsSummary' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAccountsSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activePrimary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activeSecondary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inactive' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthNewActive' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trial' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dialer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'total' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'elite' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'total' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messaging' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'total' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inactiveMessaging' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inactiveDialer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'total' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAccountsSummaryQuery,
  GetAccountsSummaryQueryVariables
>;
export const GetStatisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStatistics' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'infobip' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weeklySmsUsage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deliveryRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'declined' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pending' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'telnyx' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weeklySmsUsage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deliveryRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'declined' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pending' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'twilio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weeklySmsUsage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deliveryRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'declined' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pending' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weeklySmsUsage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deliveryRate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'declined' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pending' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStatisticsQuery, GetStatisticsQueryVariables>;
export const ExportAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExportAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'AccountsFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportAccounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExportAccountsQuery, ExportAccountsQueryVariables>;
export const FindAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'AccountsFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Order' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findAccounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminAccountFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminAccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutDisabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowOptOutDisable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowCrmIntegrations' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowDripCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowKeywordCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowScheduleCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowThrottleCampaign' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSubAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnboarded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openNewTab' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionnaire' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'realEstate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'experience' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingChannels' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingTraining' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smsMarketing' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'a2PStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tollFreeStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'affiliate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'referralId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referralLink' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'membershipMonths' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialEndedAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userTitle' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextBillingDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coupon' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'percent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promoCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expiresAt' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialEndedAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'membershipMonths' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'base' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enterprise' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'base' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interval' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userTitle' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayPrice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'features' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tier' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'limit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'min' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'trialLimits' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'use' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tier' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'limit' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mostPopular' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthlyPrice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'couponId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messagingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allowUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'a2pInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxTpm' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaignId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brandId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusUpdatedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'incorporationDate' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tollFreeInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxTpm' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'limits' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'daily' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'weekly' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'monthly' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'balance' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastTopUp' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'topUpAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topUpAmount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalSmsUsed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalSkiptraceUsed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalMembershipFees' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productsFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalBilledToDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalBilled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastBilling' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindAccountsQuery, FindAccountsQueryVariables>;
export const AdminUpdateAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminUpdateAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminUpdateAccountInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminUpdateAccountMutation,
  AdminUpdateAccountMutationVariables
>;
export const AdminDeletePhoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminDeletePhone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminDeletePhone' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminDeletePhoneMutation,
  AdminDeletePhoneMutationVariables
>;
export const AdminRemoveAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminRemoveAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminRemoveAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminRemoveAccountMutation,
  AdminRemoveAccountMutationVariables
>;
export const GetAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminAccountFragment' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ownerId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isSubAccount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isTest' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isOnboarded' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firestoreId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeZone' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'a2PStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tollFreeStatus' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subAccounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminAccountFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optOutDisabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowOptOutDisable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowCrmIntegrations' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowDripCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowKeywordCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowScheduleCampaign' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowThrottleCampaign' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSubAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnboarded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openNewTab' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionnaire' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'realEstate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'experience' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingChannels' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingTraining' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smsMarketing' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'a2PStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tollFreeStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'affiliate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'referralId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referralLink' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'membershipMonths' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialEndedAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userTitle' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextBillingDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coupon' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'percent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promoCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expiresAt' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialEndedAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'membershipMonths' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'base' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enterprise' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'base' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interval' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userTitle' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayPrice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'features' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tier' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'limit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'min' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'trialLimits' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'use' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tier' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'limit' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mostPopular' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthlyPrice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'couponId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messagingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allowUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'a2pInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxTpm' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaignId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brandId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusUpdatedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'incorporationDate' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tollFreeInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxTpm' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'limits' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'daily' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'weekly' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'monthly' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'balance' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastTopUp' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'topUpAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topUpAmount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalSmsUsed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalSkiptraceUsed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalMembershipFees' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productsFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalBilledToDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalBilled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastBilling' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAccountQuery, GetAccountQueryVariables>;
export const AdminUpdatePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminUpdatePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdatePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'password' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminUpdatePasswordMutation,
  AdminUpdatePasswordMutationVariables
>;
export const AdminTopUpBalanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminTopUpBalance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TopUpAccountBalanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminTopUpBalance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminTopUpBalanceMutation,
  AdminTopUpBalanceMutationVariables
>;
export const UpdateAccountSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccountSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSubscriptionInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subscriptionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccountSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subscriptionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'membershipMonths' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialEndedAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userTitle' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAccountSubscriptionMutation,
  UpdateAccountSubscriptionMutationVariables
>;
export const AdminChangeAccountMessagingProviderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminChangeAccountMessagingProvider' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'AdminChangeAccountMessagingProviderInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'adminChangeAccountMessagingProvider',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminChangeAccountMessagingProviderMutation,
  AdminChangeAccountMessagingProviderMutationVariables
>;
export const AdminChangeSubAccountMessagingProviderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminChangeSubAccountMessagingProvider' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'AdminChangeSubAccountMessagingProviderInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'adminChangeSubAccountMessagingProvider',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminChangeSubAccountMessagingProviderMutation,
  AdminChangeSubAccountMessagingProviderMutationVariables
>;
export const UpdateA2PRegistrationExternalDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateA2PRegistrationExternalData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateA2PRegistrationExternalDataInput',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateA2PRegistrationExternalData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateA2PRegistrationExternalDataMutation,
  UpdateA2PRegistrationExternalDataMutationVariables
>;
export const AdminAddOwnPhoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminAddOwnPhone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminAddOwnPhoneInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminAddOwnPhone' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminAddOwnPhoneMutation,
  AdminAddOwnPhoneMutationVariables
>;
export const RegisterAffiliateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegisterAffiliate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RegisterAffiliateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerAffiliate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Affiliate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Affiliate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Affiliate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterAffiliateMutation,
  RegisterAffiliateMutationVariables
>;
export const RegisterAffiliateLeadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegisterAffiliateLead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminRegisterAffiliateLeadInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerAffiliateLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Affiliate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Affiliate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Affiliate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterAffiliateLeadMutation,
  RegisterAffiliateLeadMutationVariables
>;
export const AffiliateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Affiliate' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'affiliate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Affiliate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Affiliate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Affiliate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AffiliateQuery, AffiliateQueryVariables>;
export const FindAffiliatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindAffiliates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'AffiliateFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findAffiliates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Affiliate' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Affiliate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Affiliate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindAffiliatesQuery, FindAffiliatesQueryVariables>;
export const GetAffiliateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAffiliate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'getAffiliateId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAffiliate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'getAffiliateId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Affiliate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Affiliate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Affiliate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAffiliateQuery, GetAffiliateQueryVariables>;
export const RemoveAffiliateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveAffiliate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removeAffiliateId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeAffiliate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'removeAffiliateId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveAffiliateMutation,
  RemoveAffiliateMutationVariables
>;
export const FindArticlesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindArticles' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findArticles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Article' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Article' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Article' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindArticlesQuery, FindArticlesQueryVariables>;
export const DownloadBillingReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadBillingReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PaymentsFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadBillingReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadBillingReportQuery,
  DownloadBillingReportQueryVariables
>;
export const FindPaymentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindPayments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PaymentsFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findPayments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Payment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Payment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Payment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feature' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'method' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindPaymentsQuery, FindPaymentsQueryVariables>;
export const FindLatestPaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindLatestPayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PaymentsFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findPayments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindLatestPaymentQuery,
  FindLatestPaymentQueryVariables
>;
export const TopUpBalanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TopUpBalance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TopUpBalanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topUpBalance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TopUpBalanceMutation,
  TopUpBalanceMutationVariables
>;
export const SetDefaultPaymentSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetDefaultPaymentSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PaymentSourceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setDefaultPaymentSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetDefaultPaymentSourceMutation,
  SetDefaultPaymentSourceMutationVariables
>;
export const AddPaymentSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddPaymentSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PaymentSourceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPaymentSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddPaymentSourceMutation,
  AddPaymentSourceMutationVariables
>;
export const RemovePaymentSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemovePaymentSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PaymentSourceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removePaymentSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemovePaymentSourceMutation,
  RemovePaymentSourceMutationVariables
>;
export const ScheduleBalanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ScheduleBalance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ScheduleBalanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduleBalance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScheduleBalanceMutation,
  ScheduleBalanceMutationVariables
>;
export const UpdateBillingProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBillingProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'backupPaymentMethod' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBillingProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'backupPaymentMethod' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'backupPaymentMethod' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBillingProfileMutation,
  UpdateBillingProfileMutationVariables
>;
export const PayBillDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PayBill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PayBillInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payBill' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PayBillMutation, PayBillMutationVariables>;
export const FindClosestCalendarEventForRelationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindClosestCalendarEventForRelation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'relationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'findClosestCalendarEventForRelation',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'relationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'relationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindClosestCalendarEventForRelationQuery,
  FindClosestCalendarEventForRelationQueryVariables
>;
export const FindCalendarEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindCalendarEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CalendarEventsFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findCalendarEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CalendarEvent' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindCalendarEventsQuery,
  FindCalendarEventsQueryVariables
>;
export const CreateCalendarEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCalendarEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCalendarEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCalendarEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CalendarEvent' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCalendarEventMutation,
  CreateCalendarEventMutationVariables
>;
export const UpdateCalendarEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCalendarEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCalendarEventInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCalendarEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CalendarEvent' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCalendarEventMutation,
  UpdateCalendarEventMutationVariables
>;
export const DeleteCalendarEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCalendarEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeCalendarEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCalendarEventMutation,
  DeleteCalendarEventMutationVariables
>;
export const GetCurrentCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCurrentCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCurrentCallQuery, GetCurrentCallQueryVariables>;
export const ManageCallRecordingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ManageCallRecording' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ManageCallRecordingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manageCallRecording' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ManageCallRecordingMutation,
  ManageCallRecordingMutationVariables
>;
export const FindCallScriptsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindCallScripts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CallScriptsFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findCallScripts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CallScript' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallScript' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CallScript' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindCallScriptsQuery,
  FindCallScriptsQueryVariables
>;
export const GetCallScriptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCallScript' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCallScript' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CallScript' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallScript' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CallScript' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCallScriptQuery, GetCallScriptQueryVariables>;
export const CreateCallScriptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCallScript' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCallScriptInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCallScript' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CallScript' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallScript' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CallScript' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCallScriptMutation,
  CreateCallScriptMutationVariables
>;
export const UpdateCallScriptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCallScript' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCallScriptInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateCallScriptId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCallScript' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateCallScriptId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CallScript' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallScript' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CallScript' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCallScriptMutation,
  UpdateCallScriptMutationVariables
>;
export const RemoveCallScriptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveCallScript' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removeCallScriptId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeCallScript' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'removeCallScriptId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveCallScriptMutation,
  RemoveCallScriptMutationVariables
>;
export const RankCallScriptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RankCallScript' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RankCallScriptInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rankCallScriptId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankCallScript' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rankCallScriptId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RankCallScriptMutation,
  RankCallScriptMutationVariables
>;
export const FindCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CampaignFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CampaignFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ttl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SequenceView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'days' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomTimeRestrictions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeRange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'from' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minute' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'to' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minute' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeFromNextSequences' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeChats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeCloned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeywordTemplateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'captured' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refunded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StatsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignStats' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'smsUsed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smsRefunded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ScheduleOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignScheduleOptions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'perBatch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomBatchSize' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomStartDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickThroughStartAfter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickThroughProgress' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownPhones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'abbreviation' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'state' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StateFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SequenceFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendFirstMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keyword' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KeywordTemplateFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CostFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StatsFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduleOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ScheduleOptions' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeFromNextSequences' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindCampaignsQuery, FindCampaignsQueryVariables>;
export const FindListCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindListCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CampaignFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CampaignFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ttl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SequenceView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'days' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomTimeRestrictions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeRange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'from' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minute' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'to' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minute' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeFromNextSequences' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeChats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeCloned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeywordTemplateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'captured' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refunded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StatsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignStats' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'smsUsed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smsRefunded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ScheduleOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignScheduleOptions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'perBatch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomBatchSize' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomStartDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickThroughStartAfter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickThroughProgress' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownPhones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'abbreviation' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'state' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StateFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SequenceFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendFirstMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keyword' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KeywordTemplateFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CostFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StatsFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduleOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ScheduleOptions' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeFromNextSequences' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindListCampaignsQuery,
  FindListCampaignsQueryVariables
>;
export const GetCampaignsTotalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignsTotal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CampaignFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCampaignsTotalQuery,
  GetCampaignsTotalQueryVariables
>;
export const GetCampaignsCountersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignsCounters' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCampaignsCounters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'keyword' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'draft' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleted' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'standard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'draft' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleted' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCampaignsCountersQuery,
  GetCampaignsCountersQueryVariables
>;
export const GetCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ttl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SequenceView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'days' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomTimeRestrictions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeRange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'from' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minute' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'to' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minute' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeFromNextSequences' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeChats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeCloned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeywordTemplateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'captured' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refunded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StatsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignStats' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'smsUsed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smsRefunded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ScheduleOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignScheduleOptions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'perBatch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomBatchSize' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomStartDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickThroughStartAfter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickThroughProgress' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownPhones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'abbreviation' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'state' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StateFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SequenceFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendFirstMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keyword' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KeywordTemplateFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CostFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StatsFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduleOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ScheduleOptions' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeFromNextSequences' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCampaignQuery, GetCampaignQueryVariables>;
export const ExportCampaignToFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExportCampaignToFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExportCampaignToFileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportCampaignToFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExportCampaignToFileQuery,
  ExportCampaignToFileQueryVariables
>;
export const GetCampaignScheduleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignSchedule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'options' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CampaignScheduleOptionsInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCampaignSchedule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'options' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'options' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextTime' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCampaignScheduleQuery,
  GetCampaignScheduleQueryVariables
>;
export const FindCampaignMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindCampaignMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ScheduledMessageFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findCampaignMessages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CampaignScheduledMessage',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignScheduledMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ScheduledMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'template' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optOut' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attrs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindCampaignMessagesQuery,
  FindCampaignMessagesQueryVariables
>;
export const CreateCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ttl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SequenceView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'days' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomTimeRestrictions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeRange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'from' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minute' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'to' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minute' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeFromNextSequences' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeChats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeCloned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeywordTemplateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'captured' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refunded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StatsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignStats' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'smsUsed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smsRefunded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ScheduleOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignScheduleOptions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'perBatch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomBatchSize' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomStartDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickThroughStartAfter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickThroughProgress' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownPhones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'abbreviation' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'state' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StateFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SequenceFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendFirstMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keyword' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KeywordTemplateFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CostFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StatsFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduleOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ScheduleOptions' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeFromNextSequences' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;
export const UpdateCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCampaignInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ttl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SequenceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SequenceView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'days' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomTimeRestrictions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeRange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'from' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minute' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'to' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minute' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeFromNextSequences' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeChats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeCloned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeywordTemplateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'captured' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refunded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StatsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignStats' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'smsUsed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smsRefunded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ScheduleOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignScheduleOptions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'perBatch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomBatchSize' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasCustomStartDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickThroughStartAfter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickThroughProgress' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownPhones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'abbreviation' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'state' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StateFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sequences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SequenceFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendFirstMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keyword' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KeywordTemplateFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CostFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StatsFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduleOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ScheduleOptions' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeFromNextSequences' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables
>;
export const RemoveCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveCampaignMutation,
  RemoveCampaignMutationVariables
>;
export const ExportCampaignToGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExportCampaignToGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExportCampaignToGroupInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportCampaignToGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExportCampaignToGroupQuery,
  ExportCampaignToGroupQueryVariables
>;
export const StartCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StartCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartCampaignMutation,
  StartCampaignMutationVariables
>;
export const PauseCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PauseCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pauseCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PauseCampaignMutation,
  PauseCampaignMutationVariables
>;
export const ResumeCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResumeCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumeCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResumeCampaignMutation,
  ResumeCampaignMutationVariables
>;
export const UpdateCampaignDynamicDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCampaignDynamicData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CampaignDynamicDataInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCampaignDynamicData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCampaignDynamicDataMutation,
  UpdateCampaignDynamicDataMutationVariables
>;
export const UpdateScheduledMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateScheduledMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateScheduledMessageInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateScheduledMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignScheduledMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignScheduledMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ScheduledMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'template' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optOut' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attrs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateScheduledMessageMutation,
  UpdateScheduledMessageMutationVariables
>;
export const RefreshCampaignMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RefreshCampaignMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refreshCampaignMessages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RefreshCampaignMessagesMutation,
  RefreshCampaignMessagesMutationVariables
>;
export const UpdateCampaignOwnPhonesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCampaignOwnPhones' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCampaignOwnPhonesInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateCampaignOwnPhonesId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCampaignOwnPhones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateCampaignOwnPhonesId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCampaignOwnPhonesMutation,
  UpdateCampaignOwnPhonesMutationVariables
>;
export const CampaignUpsertedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'CampaignUpserted' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignUpserted' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'operation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CampaignUpsertedSubscription,
  CampaignUpsertedSubscriptionVariables
>;
export const CampaignProgressUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'CampaignProgressUpdated' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignProgressUpdated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'progress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CampaignProgressUpdatedSubscription,
  CampaignProgressUpdatedSubscriptionVariables
>;
export const GetContactNoteSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContactNoteSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GenerateSignedUrlInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getContactNoteSignedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetContactNoteSignedUrlQuery,
  GetContactNoteSignedUrlQueryVariables
>;
export const FindContactNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindContactNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ContactNoteFilter' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findContactNotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContactNote' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attachments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindContactNotesQuery,
  FindContactNotesQueryVariables
>;
export const CreateContactNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateContactNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateContactNoteInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createContactNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactNote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attachments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateContactNoteMutation,
  CreateContactNoteMutationVariables
>;
export const UpdateContactNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateContactNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateContactNoteInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'noteId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateContactNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'noteId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactNote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attachments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateContactNoteMutation,
  UpdateContactNoteMutationVariables
>;
export const RemoveContactNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveContactNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'noteId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeContactNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'noteId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveContactNoteMutation,
  RemoveContactNoteMutationVariables
>;
export const GetContactsCountersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContactsCounters' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getContactsCounters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeGroups' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeContacts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deletedContacts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetContactsCountersQuery,
  GetContactsCountersQueryVariables
>;
export const FindContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ContactFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ContactOrder' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContactPreview' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactPreview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealClosed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'default' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'readOnly' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scopes' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindContactsQuery, FindContactsQueryVariables>;
export const CountContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CountContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ContactFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ContactOrder' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountContactsQuery, CountContactsQueryVariables>;
export const FindContactsPhonesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindContactsPhones' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ContactFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindContactsPhonesQuery,
  FindContactsPhonesQueryVariables
>;
export const GetContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Contact' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Label' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scopes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readOnly' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAttribute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RealEstateAttrs' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactRealEstateAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unsubscribed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealClosed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Label' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attrs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactAttribute' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'realEstateAttrs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attrs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RealEstateAttrs' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetContactQuery, GetContactQueryVariables>;
export const GetContactPhoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContactPhone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetContactPhoneQuery,
  GetContactPhoneQueryVariables
>;
export const GetContactNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContactNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContactNote' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attachments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetContactNotesQuery,
  GetContactNotesQueryVariables
>;
export const CreateContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateContactInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Contact' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Label' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scopes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readOnly' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAttribute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RealEstateAttrs' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactRealEstateAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unsubscribed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealClosed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Label' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attrs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactAttribute' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'realEstateAttrs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attrs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RealEstateAttrs' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const UpdateContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateContactInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Contact' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Label' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scopes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readOnly' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAttribute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RealEstateAttrs' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactRealEstateAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unsubscribed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealClosed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Label' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attrs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactAttribute' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'realEstateAttrs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attrs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RealEstateAttrs' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;
export const RecoverContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RecoverContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RecoverContactsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recoverContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecoverContactsMutation,
  RecoverContactsMutationVariables
>;
export const RemoveContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveContactsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveContactsMutation,
  RemoveContactsMutationVariables
>;
export const BlockContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BlockContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BlockContactsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blockContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BlockContactsMutation,
  BlockContactsMutationVariables
>;
export const AddLabelsToContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddLabelsToContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddLabelsToContactsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addLabelsToContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firestoreId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddLabelsToContactsMutation,
  AddLabelsToContactsMutationVariables
>;
export const RemoveLabelsFromContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveLabelsFromContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveLabelsFromContactsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeLabelsFromContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firestoreId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveLabelsFromContactsMutation,
  RemoveLabelsFromContactsMutationVariables
>;
export const FindConversationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindConversations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ConversationFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ConversationOrder' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findConversations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Conversation' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Conversation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Conversation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAiFiltered' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastMessageAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LastMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindConversationsQuery,
  FindConversationsQueryVariables
>;
export const FindConversationMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindConversationMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MessageFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MessageOrder' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findConversationMessages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Message' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Attachment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'path' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleMessageCampaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleMessageTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFilePath' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleMessageKeyword' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Attachment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isConversation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManualRecording' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SingleMessageCampaign' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SingleMessageTemplate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keyword' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SingleMessageKeyword' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dialerCampaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fromNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindConversationMessagesQuery,
  FindConversationMessagesQueryVariables
>;
export const FindScheduledMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindScheduledMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ScheduledMessageFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findScheduledMessages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scheduledAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attachments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Attachment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Attachment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'path' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindScheduledMessagesQuery,
  FindScheduledMessagesQueryVariables
>;
export const GetMessageAttachmentSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMessageAttachmentSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'GetMessageAttachmentSignedUrlInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMessageAttachmentSignedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMessageAttachmentSignedUrlQuery,
  GetMessageAttachmentSignedUrlQueryVariables
>;
export const GetConversationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetConversation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getConversation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastMessage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'LastMessage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastMessageAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRead' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isUnsubscribed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaigns' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isBlocked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outgoingPhone' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ownPhone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'abbreviation' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetConversationQuery,
  GetConversationQueryVariables
>;
export const GetConversationOwnPhoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetConversationOwnPhone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getConversation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ownPhone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isTrial' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messagingEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'callingEnabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetConversationOwnPhoneQuery,
  GetConversationOwnPhoneQueryVariables
>;
export const GetUnreadConversationsCountersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUnreadConversationsCounters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'profileId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUnreadConversationsCounters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'profileId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'withUnreadMessages' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'withMissedCalls' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUnreadConversationsCountersQuery,
  GetUnreadConversationsCountersQueryVariables
>;
export const CreateConversationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateConversation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateConversationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createConversation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateConversationMutation,
  CreateConversationMutationVariables
>;
export const MarkConversationsReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkConversationsRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MarkConversationsReadInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markConversationsRead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkConversationsReadMutation,
  MarkConversationsReadMutationVariables
>;
export const SendMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SendMessageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Message' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Attachment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'path' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleMessageCampaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleMessageTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFilePath' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleMessageKeyword' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Message' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Attachment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isConversation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManualRecording' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SingleMessageCampaign' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SingleMessageTemplate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keyword' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SingleMessageKeyword' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dialerCampaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fromNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendMessageMutation, SendMessageMutationVariables>;
export const CreateScheduledMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateScheduledMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateScheduledMessageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createScheduledMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Attachment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Attachment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageAttachment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'path' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateScheduledMessageMutation,
  CreateScheduledMessageMutationVariables
>;
export const RemoveScheduledMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveScheduledMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeScheduledMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveScheduledMessageMutation,
  RemoveScheduledMessageMutationVariables
>;
export const UpdateConversationOutgoingPhoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateConversationOutgoingPhone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateConversationOutgoingPhoneInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateConversationOutgoingPhone' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateConversationOutgoingPhoneMutation,
  UpdateConversationOutgoingPhoneMutationVariables
>;
export const ConversationUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'ConversationUpdated' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversationUpdated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'operation' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConversationUpdatedSubscription,
  ConversationUpdatedSubscriptionVariables
>;
export const MessageCreatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'MessageCreated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageCreated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageCreatedSubscription,
  MessageCreatedSubscriptionVariables
>;
export const MessageUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'MessageUpdated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageUpdated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageUpdatedSubscription,
  MessageUpdatedSubscriptionVariables
>;
export const IncomingMessageReceivedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'IncomingMessageReceived' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'incomingMessageReceived' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversationId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'statusReason' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  IncomingMessageReceivedSubscription,
  IncomingMessageReceivedSubscriptionVariables
>;
export const UnreadConversationsMessagesCounterUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: {
        kind: 'Name',
        value: 'UnreadConversationsMessagesCounterUpdated',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'profileId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'unreadConversationsMessagesCounterUpdated',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'profileId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'counter' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnreadConversationsMessagesCounterUpdatedSubscription,
  UnreadConversationsMessagesCounterUpdatedSubscriptionVariables
>;
export const MissedConversationsCallsCounterUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'MissedConversationsCallsCounterUpdated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'profileId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'missedConversationsCallsCounterUpdated',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'profileId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'counter' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MissedConversationsCallsCounterUpdatedSubscription,
  MissedConversationsCallsCounterUpdatedSubscriptionVariables
>;
export const GenerateWebRtcTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GenerateWebRTCToken' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateWebRTCToken' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateWebRtcTokenQuery,
  GenerateWebRtcTokenQueryVariables
>;
export const GetInfobipConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInfobipConfig' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInfobipConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'callsConfigId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInfobipConfigQuery,
  GetInfobipConfigQueryVariables
>;
export const FindDialerCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindDialerCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DialerCampaignFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findDialerCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DialerCampaignFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OwnPhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OwnPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagingEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DialerCampaignFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DialerCampaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastCallAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'script' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phones' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownPhones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OwnPhone' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lines' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindDialerCampaignsQuery,
  FindDialerCampaignsQueryVariables
>;
export const FindListDialerCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindListDialerCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DialerCampaignFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findDialerCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindListDialerCampaignsQuery,
  FindListDialerCampaignsQueryVariables
>;
export const GetDialerCampaignsTotalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDialerCampaignsTotal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DialerCampaignFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findDialerCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDialerCampaignsTotalQuery,
  GetDialerCampaignsTotalQueryVariables
>;
export const GetDialerCampaignsCountersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDialerCampaignsCounters' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDialerCampaignsCounters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dialer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'draft' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleted' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDialerCampaignsCountersQuery,
  GetDialerCampaignsCountersQueryVariables
>;
export const GetDialerCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDialerCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDialerCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DialerCampaignDetailedFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OwnPhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OwnPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagingEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DialerCampaignDetailedFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DialerCampaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastCallAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'script' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phones' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactsWithOutcome' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lines' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownPhones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OwnPhone' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDialerCampaignQuery,
  GetDialerCampaignQueryVariables
>;
export const ExportDialerCampaignToFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExportDialerCampaignToFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExportDialerCampaignToFileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportDialerCampaignToFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExportDialerCampaignToFileQuery,
  ExportDialerCampaignToFileQueryVariables
>;
export const CreateDialerCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDialerCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateDialerCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDialerCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DialerCampaignFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OwnPhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OwnPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagingEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DialerCampaignFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DialerCampaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastCallAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'script' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phones' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownPhones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OwnPhone' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lines' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDialerCampaignMutation,
  CreateDialerCampaignMutationVariables
>;
export const RemoveDialerCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveDialerCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveDialerCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeDialerCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveDialerCampaignMutation,
  RemoveDialerCampaignMutationVariables
>;
export const StartDialerCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StartDialerCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startDialerCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartDialerCampaignMutation,
  StartDialerCampaignMutationVariables
>;
export const PauseDialerCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PauseDialerCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pauseDialerCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PauseDialerCampaignMutation,
  PauseDialerCampaignMutationVariables
>;
export const ResumeDialerCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResumeDialerCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumeDialerCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResumeDialerCampaignMutation,
  ResumeDialerCampaignMutationVariables
>;
export const ExportDialerCampaignToGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExportDialerCampaignToGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExportDialerCampaignToGroupInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportDialerCampaignToGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExportDialerCampaignToGroupMutation,
  ExportDialerCampaignToGroupMutationVariables
>;
export const UpdateDialerCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDialerCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateDialerCampaignInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateDialerCampaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDialerCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateDialerCampaignId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDialerCampaignMutation,
  UpdateDialerCampaignMutationVariables
>;
export const ExportConversationsToFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExportConversationsToFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExportConversationsToFileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportConversationsToFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExportConversationsToFileQuery,
  ExportConversationsToFileQueryVariables
>;
export const FindLastFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindLastFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'source' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FeedbackSource' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findLastFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'source' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'source' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindLastFeedbackQuery,
  FindLastFeedbackQueryVariables
>;
export const CreateFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFeedbackInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFeedbackMutation,
  CreateFeedbackMutationVariables
>;
export const FindFieldsTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindFieldsTemplates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findFieldsTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FieldsTemplate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Field' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Field' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FieldsTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FieldsTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Field' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindFieldsTemplatesQuery,
  FindFieldsTemplatesQueryVariables
>;
export const CreateFieldsTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFieldsTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFieldsTemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFieldsTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FieldsTemplate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Field' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Field' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FieldsTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FieldsTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Field' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFieldsTemplateMutation,
  CreateFieldsTemplateMutationVariables
>;
export const RemoveFieldsTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveFieldsTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeFieldsTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveFieldsTemplateMutation,
  RemoveFieldsTemplateMutationVariables
>;
export const FindFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindFields' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'FieldsFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Field' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Field' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Field' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindFieldsQuery, FindFieldsQueryVariables>;
export const FindMergeTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindMergeTags' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findMergeTags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mergeTag' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindMergeTagsQuery, FindMergeTagsQueryVariables>;
export const CreateFieldDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateField' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFieldInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createField' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Field' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Field' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Field' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFieldMutation, CreateFieldMutationVariables>;
export const UpdateFieldDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateField' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFieldInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateField' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Field' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Field' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Field' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFieldMutation, UpdateFieldMutationVariables>;
export const RemoveFieldDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveField' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeField' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveFieldMutation, RemoveFieldMutationVariables>;
export const GetGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupView' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeChats' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dialerCampaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeCloned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGroupQuery, GetGroupQueryVariables>;
export const FindGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Order' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GroupsFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'GroupView' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeChats' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dialerCampaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeCloned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindGroupsQuery, FindGroupsQueryVariables>;
export const FindAlertGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindAlertGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Order' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GroupsFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'GroupView' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeChats' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dialerCampaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeCloned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindAlertGroupsQuery,
  FindAlertGroupsQueryVariables
>;
export const CreateGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canBeCloned' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateGroupMutation, CreateGroupMutationVariables>;
export const UpdateGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateGroupInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupView' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeChats' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dialerCampaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeCloned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const RemoveGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveGroupsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveGroupsMutation,
  RemoveGroupsMutationVariables
>;
export const DuplicateGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DuplicateGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'duplicateGroupsId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicateGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'duplicateGroupsId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupView' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeChats' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dialerCampaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeCloned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DuplicateGroupsMutation,
  DuplicateGroupsMutationVariables
>;
export const AddGroupToContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddGroupToContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddGroupToContactsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addGroupToContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddGroupToContactsMutation,
  AddGroupToContactsMutationVariables
>;
export const RemoveGroupFromContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveGroupFromContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveGroupFromContactsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeGroupFromContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveGroupFromContactsMutation,
  RemoveGroupFromContactsMutationVariables
>;
export const ExportGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExportGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exportGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'exportGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExportGroupQuery, ExportGroupQueryVariables>;
export const FindCrmIntegrationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindCrmIntegrations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CrmIntegrationFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CrmIntegrationOrder' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findCrmIntegrations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'crmIntegrationFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'crmIntegrationFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CrmIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindCrmIntegrationsQuery,
  FindCrmIntegrationsQueryVariables
>;
export const GetCrmIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCrmIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCrmIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'crmIntegrationFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'crmIntegrationFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CrmIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'crmIntegrationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CrmIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'crmIntegrationFragment' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'provider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelsPropertyStatuses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'default' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firestoreId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendNotes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelsPhoneStatuses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'default' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firestoreId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelsPhoneTags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firestoreId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCrmIntegrationQuery,
  GetCrmIntegrationQueryVariables
>;
export const ExportConversationsToCrmIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExportConversationsToCrmIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ExportConversationsToCRMIntegrationInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'exportConversationsToCrmIntegration',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExportConversationsToCrmIntegrationQuery,
  ExportConversationsToCrmIntegrationQueryVariables
>;
export const CreateCrmIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCrmIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCrmIntegrationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCrmIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'crmIntegrationFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'crmIntegrationFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CrmIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'crmIntegrationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CrmIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'crmIntegrationFragment' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'provider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelsPropertyStatuses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'default' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firestoreId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendNotes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelsPhoneStatuses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'default' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firestoreId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelsPhoneTags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firestoreId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCrmIntegrationMutation,
  CreateCrmIntegrationMutationVariables
>;
export const UpdateCrmIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCrmIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCrmIntegrationInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCrmIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'crmIntegrationFields' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'crmIntegrationFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CrmIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'crmIntegrationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CrmIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'crmIntegrationFragment' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'provider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelsPropertyStatuses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'default' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firestoreId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendNotes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelsPhoneStatuses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'default' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firestoreId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelsPhoneTags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firestoreId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCrmIntegrationMutation,
  UpdateCrmIntegrationMutationVariables
>;
export const RemoveCrmIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveCrmIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeCrmIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveCrmIntegrationMutation,
  RemoveCrmIntegrationMutationVariables
>;
export const FindKeywordTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindKeywordTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'KeywordTemplatesFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findKeywordTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'KeywordTemplate' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeywordTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindKeywordTemplatesQuery,
  FindKeywordTemplatesQueryVariables
>;
export const GetKeywordTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetKeywordTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'getKeywordTemplateId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getKeywordTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'getKeywordTemplateId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KeywordTemplate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeywordTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetKeywordTemplateQuery,
  GetKeywordTemplateQueryVariables
>;
export const CreateKeywordTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateKeywordTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateKeywordTemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createKeywordTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KeywordTemplate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeywordTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateKeywordTemplateMutation,
  CreateKeywordTemplateMutationVariables
>;
export const UpdateKeywordTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateKeywordTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateKeywordTemplateInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateKeywordTemplateId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateKeywordTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateKeywordTemplateId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KeywordTemplate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeywordTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KeywordTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateKeywordTemplateMutation,
  UpdateKeywordTemplateMutationVariables
>;
export const RemoveKeywordTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveKeywordTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removeKeywordTemplateId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeKeywordTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'removeKeywordTemplateId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveKeywordTemplateMutation,
  RemoveKeywordTemplateMutationVariables
>;
export const RankKeywordTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RankKeywordTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RankKeywordTemplateInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rankKeywordTemplateId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankKeywordTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rankKeywordTemplateId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RankKeywordTemplateMutation,
  RankKeywordTemplateMutationVariables
>;
export const FindLabelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindLabels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LabelFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CursorPagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findLabels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Label' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextPageToken' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Label' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scopes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readOnly' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindLabelsQuery, FindLabelsQueryVariables>;
export const GetLabelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLabel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getLabel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Label' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Label' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scopes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readOnly' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLabelQuery, GetLabelQueryVariables>;
export const CreateLabelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLabel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateLabelInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLabel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Label' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Label' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scopes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readOnly' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateLabelMutation, CreateLabelMutationVariables>;
export const UpdateLabelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLabel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateLabelInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLabel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Label' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Label' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scopes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readOnly' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateLabelMutation, UpdateLabelMutationVariables>;
export const RemoveLabelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveLabel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeLabel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveLabelMutation, RemoveLabelMutationVariables>;
export const RankLabelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RankLabel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RankLabelInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rankLabelId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankLabel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rankLabelId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RankResult' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'afterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beforeId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RankLabelMutation, RankLabelMutationVariables>;
export const GetListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ListOptions' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Field' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListOptions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'removeFiltered' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUnsubscribed' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInternalDnc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeExisting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeNonSmsCapable' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'removeLitigators' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeNationalDnc' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeCarrierBlocked' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeGlobalUnsubscribed' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUndeliverable' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Field' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Field' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetListQuery, GetListQueryVariables>;
export const ExportListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExportList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExportListInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExportListQuery, ExportListQueryVariables>;
export const FindListItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindListItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'listId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ListItemsPagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findListItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'listId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'listId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rowIndex' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextRowIndex' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindListItemsQuery, FindListItemsQueryVariables>;
export const UpdateListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateListInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ListOptions' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Field' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListOptions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'removeFiltered' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUnsubscribed' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInternalDnc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeExisting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeNonSmsCapable' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'removeLitigators' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeNationalDnc' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeCarrierBlocked' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeGlobalUnsubscribed' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUndeliverable' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Field' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Field' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateListMutation, UpdateListMutationVariables>;
export const VerifyListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyListMutation, VerifyListMutationVariables>;
export const RemoveListItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveListItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeListItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveListItemMutation,
  RemoveListItemMutationVariables
>;
export const UpdateListItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateListItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateListItemInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateListItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateListItemMutation,
  UpdateListItemMutationVariables
>;
export const GetListUploadSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetListUploadSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GenerateSignedUrlInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getListUploadSignedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetListUploadSignedUrlQuery,
  GetListUploadSignedUrlQueryVariables
>;
export const CreateListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateListInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ListOptions' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'predefined' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListOptions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'removeFiltered' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUnsubscribed' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInternalDnc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeExisting' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeNonSmsCapable' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'removeLitigators' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeNationalDnc' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeCarrierBlocked' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeGlobalUnsubscribed' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUndeliverable' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateListMutation, CreateListMutationVariables>;
export const ListVerificationProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'ListVerificationProgress' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listVerificationProgress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'listId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListVerificationProgressSubscription,
  ListVerificationProgressSubscriptionVariables
>;
export const ListVerificationCompletedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'ListVerificationCompleted' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listVerificationCompleted' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ListVerificationResult' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListVerificationResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListVerificationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'listId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPhones' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verifiedPhones' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filtered' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unsubscribed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalDnc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'existing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nonSmsCapable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'litigators' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalDnc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'undeliverable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrierBlocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalUnsubscribed' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListVerificationCompletedSubscription,
  ListVerificationCompletedSubscriptionVariables
>;
export const ListUploadProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'ListUploadProgress' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listUploadProgress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'listId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListUploadProgressSubscription,
  ListUploadProgressSubscriptionVariables
>;
export const ListUploadCompletedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'ListUploadCompleted' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listUploadCompleted' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'listId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListUploadCompletedSubscription,
  ListUploadCompletedSubscriptionVariables
>;
export const FindMacrosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindMacros' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MacrosFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CursorPagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findMacros' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Macro' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextPageToken' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Macro' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Macro' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindMacrosQuery, FindMacrosQueryVariables>;
export const GetMacroDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMacro' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'getMacroId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMacro' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'getMacroId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Macro' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Macro' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Macro' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMacroQuery, GetMacroQueryVariables>;
export const CreateMacroDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMacro' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMacroInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMacro' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Macro' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Macro' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Macro' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateMacroMutation, CreateMacroMutationVariables>;
export const UpdateMacroDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMacro' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateMacroInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateMacroId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMacro' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateMacroId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Macro' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Macro' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Macro' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMacroMutation, UpdateMacroMutationVariables>;
export const RemoveMacroDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveMacro' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removeMacroId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeMacro' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'removeMacroId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveMacroMutation, RemoveMacroMutationVariables>;
export const FindMessageTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindMessageTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MessageTemplatesFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findMessageTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MessageTemplate' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFileBucket' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFilePath' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFileUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindMessageTemplatesQuery,
  FindMessageTemplatesQueryVariables
>;
export const GetMessageTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMessageTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMessageTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessageTemplate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFileBucket' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFilePath' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFileUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMessageTemplateQuery,
  GetMessageTemplateQueryVariables
>;
export const FindMessageTemplatesForFuckingFirestoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindMessageTemplatesForFuckingFirestore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MessageTemplatesFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'findMessageTemplatesForFuckingFirestore',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessageTemplate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFileBucket' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFilePath' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFileUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindMessageTemplatesForFuckingFirestoreQuery,
  FindMessageTemplatesForFuckingFirestoreQueryVariables
>;
export const CreateMessageTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMessageTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMessageTemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMessageTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessageTemplate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFileBucket' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFilePath' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFileUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMessageTemplateMutation,
  CreateMessageTemplateMutationVariables
>;
export const UpdateMessageTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMessageTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateMessageTemplateInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateMessageTemplateId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMessageTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateMessageTemplateId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessageTemplate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFileBucket' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFilePath' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFileUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMessageTemplateMutation,
  UpdateMessageTemplateMutationVariables
>;
export const RemoveMessageTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveMessageTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removeMessageTemplateId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeMessageTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'removeMessageTemplateId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveMessageTemplateMutation,
  RemoveMessageTemplateMutationVariables
>;
export const RankMessageTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RankMessageTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RankMessageTemplateInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rankMessageTemplateId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankMessageTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rankMessageTemplateId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RankMessageTemplateMutation,
  RankMessageTemplateMutationVariables
>;
export const BuildMessageTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BuildMessageTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BuildMessageTemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buildMessageTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessageTemplate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessageTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firestoreId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFileBucket' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFilePath' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audioFileUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'characters' } },
          { kind: 'Field', name: { kind: 'Name', value: 'segments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BuildMessageTemplatesQuery,
  BuildMessageTemplatesQueryVariables
>;
export const GetMessageTemplateSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMessageTemplateSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GenerateSignedUrlInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMessageTemplateSignedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMessageTemplateSignedUrlQuery,
  GetMessageTemplateSignedUrlQueryVariables
>;
export const GetMessagingProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMessagingProfile' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMessagingProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessagingProfile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessagingProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MessagingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activeIn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSipEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alertFilter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaigns' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationFilter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaigns' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationsEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'callAutoReply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'voiceMail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activeIn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSipEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'callAutoReply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'voiceMail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voiceType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'allowUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'a2pInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxTpm' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tollFreeInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxTpm' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'limits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'daily' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEINDocRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responseRemoval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responseFilters' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'provider' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sipPassword' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sipUsername' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMessagingProfileQuery,
  GetMessagingProfileQueryVariables
>;
export const UpdateMessagingProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMessagingProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateMessagingProfileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMessagingProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMessagingProfileMutation,
  UpdateMessagingProfileMutationVariables
>;
export const FindNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findNotifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Notification' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailsNotification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NotificationDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isKeywordCampaign' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarEventName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarEventDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarEventId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Notification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Notification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DetailsNotification' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindNotificationsQuery,
  FindNotificationsQueryVariables
>;
export const GetNotificationSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotificationSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getNotificationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'NotificationSettings' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NotificationSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'enabledCampaigns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabledPayments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabledCalendar' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNotificationSettingsQuery,
  GetNotificationSettingsQueryVariables
>;
export const GetUnreadNotificationCounterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUnreadNotificationCounter' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUnreadNotificationCounter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUnreadNotificationCounterQuery,
  GetUnreadNotificationCounterQueryVariables
>;
export const MarkNotificationsReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkNotificationsRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MarkNotificationsReadInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markNotificationsRead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkNotificationsReadMutation,
  MarkNotificationsReadMutationVariables
>;
export const MarkNotificationsSeenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkNotificationsSeen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MarkNotificationsSeenInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markNotificationsSeen' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkNotificationsSeenMutation,
  MarkNotificationsSeenMutationVariables
>;
export const UpdateNotificationSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNotificationSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateNotificationSettingsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNotificationSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNotificationSettingsMutation,
  UpdateNotificationSettingsMutationVariables
>;
export const NotificationCreatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'NotificationCreated' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationCreated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRead' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'details' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DetailsNotification' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailsNotification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NotificationDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isKeywordCampaign' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarEventName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarEventDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarEventId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NotificationCreatedSubscription,
  NotificationCreatedSubscriptionVariables
>;
export const NotificationUnreadCounterUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'NotificationUnreadCounterUpdated' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationUnreadCounterUpdated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NotificationUnreadCounterUpdatedSubscription,
  NotificationUnreadCounterUpdatedSubscriptionVariables
>;
export const FindAvailablePhonesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindAvailablePhones' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FindAvailablePhonesFilter' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findAvailablePhones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindAvailablePhonesQuery,
  FindAvailablePhonesQueryVariables
>;
export const FindOwnPhonesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindOwnPhones' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'OwnPhoneFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findOwnPhones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OwnPhone' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OwnPhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OwnPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagingEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindOwnPhonesQuery, FindOwnPhonesQueryVariables>;
export const FindOwnPhonesReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindOwnPhonesReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'OwnPhoneFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findOwnPhones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OwnPhoneReport' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OwnPhoneReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OwnPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callingEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportDialer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'phoneId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outboundCalls' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'messagingEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'phoneId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outgoingMessages' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blockedRateMessages' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeConversations' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindOwnPhonesReportQuery,
  FindOwnPhonesReportQueryVariables
>;
export const GetOwnPhoneCountersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOwnPhoneCounters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'scope' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Scope' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOwnPhoneCounters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scope' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'scope' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'local' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tollFree' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOwnPhoneCountersQuery,
  GetOwnPhoneCountersQueryVariables
>;
export const PurchaseOwnPhoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PurchaseOwnPhone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PurchaseOwnPhoneInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseOwnPhone' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PurchaseOwnPhoneMutation,
  PurchaseOwnPhoneMutationVariables
>;
export const RemoveOwnPhoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveOwnPhone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeOwnPhone' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ownPhoneId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveOwnPhoneMutation,
  RemoveOwnPhoneMutationVariables
>;
export const GetActivePlansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActivePlans' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActivePlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enterprise' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lexorank' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialLimits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'use' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mostPopular' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'couponId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActivePlansQuery, GetActivePlansQueryVariables>;
export const GetPlansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPlans' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enterprise' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lexorank' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialLimits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'use' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mostPopular' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'couponId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPlansQuery, GetPlansQueryVariables>;
export const CreateMarketingPopupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMarketingPopup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMarketingPopupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMarketingPopup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingPopup' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingPopup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingPopup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'audiences' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageSrc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoSrc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMarketingPopupMutation,
  CreateMarketingPopupMutationVariables
>;
export const GetMarketingPopupSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMarketingPopupSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GenerateSignedUrlInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMarketingPopupSignedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMarketingPopupSignedUrlQuery,
  GetMarketingPopupSignedUrlQueryVariables
>;
export const FindMarketingPopupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindMarketingPopups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findMarketingPopups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MarketingPopup' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingPopup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingPopup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'audiences' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageSrc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoSrc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindMarketingPopupsQuery,
  FindMarketingPopupsQueryVariables
>;
export const RemoveMarketingPopupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveMarketingPopup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removeMarketingPopupId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeMarketingPopup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'removeMarketingPopupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveMarketingPopupMutation,
  RemoveMarketingPopupMutationVariables
>;
export const GetMarketingPopupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMarketingPopup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMarketingPopup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'audiences' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'template' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageSrc' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'videoSrc' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'buttonText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'buttonLink' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMarketingPopupQuery,
  GetMarketingPopupQueryVariables
>;
export const UpdateMarketingPopupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMarketingPopup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateMarketingPopupInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMarketingPopup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'audiences' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'template' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageSrc' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'videoSrc' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'buttonText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'buttonLink' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMarketingPopupMutation,
  UpdateMarketingPopupMutationVariables
>;
export const FindActiveMarketingPopupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindActiveMarketingPopups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findActiveMarketingPopups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'template' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageSrc' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'videoSrc' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'buttonText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'buttonLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindActiveMarketingPopupsQuery,
  FindActiveMarketingPopupsQueryVariables
>;
export const MarkAccountPopupViewedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkAccountPopupViewed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MarkAccountPopupViewedInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markAccountPopupViewed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'popupId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkAccountPopupViewedMutation,
  MarkAccountPopupViewedMutationVariables
>;
export const GetPowerDialerCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPowerDialerCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDialerCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lines' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'script' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactsWithOutcome' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contacts' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPowerDialerCampaignQuery,
  GetPowerDialerCampaignQueryVariables
>;
export const FindPowerDialerOutcomeLabelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindPowerDialerOutcomeLabels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LabelFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findLabels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindPowerDialerOutcomeLabelsQuery,
  FindPowerDialerOutcomeLabelsQueryVariables
>;
export const GeneratePowerDialerWebRtcTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GeneratePowerDialerWebRTCToken' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generatePowerDialerWebRTCToken' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GeneratePowerDialerWebRtcTokenQuery,
  GeneratePowerDialerWebRtcTokenQueryVariables
>;
export const GetPowerDialerCallingBarContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPowerDialerCallingBarContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPowerDialerCallingBarContactQuery,
  GetPowerDialerCallingBarContactQueryVariables
>;
export const ManagePowerDialerCallRecordingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ManagePowerDialerCallRecording' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ManagePowerDialerCallRecordingInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managePowerDialerCallRecording' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ManagePowerDialerCallRecordingMutation,
  ManagePowerDialerCallRecordingMutationVariables
>;
export const StartPowerDialingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StartPowerDialing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StartPowerDialingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startPowerDialing' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartPowerDialingMutation,
  StartPowerDialingMutationVariables
>;
export const HangUpPowerDialerCallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'HangUpPowerDialerCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'batchId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hangUpPowerDialerCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'batchId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'batchId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  HangUpPowerDialerCallMutation,
  HangUpPowerDialerCallMutationVariables
>;
export const AddLabelToCallAndContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddLabelToCallAndContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddLabelToCallAndContactInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addLabelToCallAndContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddLabelToCallAndContactMutation,
  AddLabelToCallAndContactMutationVariables
>;
export const SwitchDialerOfflineDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchDialerOffline' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inPowerDialer' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchDialerOffline' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inPowerDialer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inPowerDialer' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SwitchDialerOfflineMutation,
  SwitchDialerOfflineMutationVariables
>;
export const SwitchDialerOnlineDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchDialerOnline' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchDialerOnline' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SwitchDialerOnlineMutation,
  SwitchDialerOnlineMutationVariables
>;
export const PowerDialerStateUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'PowerDialerStateUpdated' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'powerDialerStateUpdated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'batchId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lines' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Line' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectedLineId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentOutboundNumber' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentContactId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'establishedAt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignIsCompleted' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'showOutcomeLabels' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isConnecting' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isDialing' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hangupIsDisabled' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isRecording' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Line' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LineView' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stateReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amdResult' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PowerDialerStateUpdatedSubscription,
  PowerDialerStateUpdatedSubscriptionVariables
>;
export const PowerDialerFailedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'PowerDialerFailed' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'powerDialerFailed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errorType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorMessage' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PowerDialerFailedSubscription,
  PowerDialerFailedSubscriptionVariables
>;
export const GetCampaignReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReportsFilter' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCampaignReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalStandardCampaigns' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalKeywordCampaigns' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCampaignReportQuery,
  GetCampaignReportQueryVariables
>;
export const GetMessageReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMessageReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReportsFilter' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMessageReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'incomingTotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outgoingTotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignOutgoingTotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignOutgoingDelivered' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignOutgoingBlocked' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignIncomingOptedOut' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignAiFilteredMessages' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'medianResponseTime' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMessageReportQuery,
  GetMessageReportQueryVariables
>;
export const GetLabelReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLabelReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReportsFilter' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getLabelReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactsWithCurrentLabel' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allLabeledContacts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLabelReportQuery, GetLabelReportQueryVariables>;
export const FindCampaignsReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindCampaignsReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CampaignFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sendFirstMessage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sequences' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'templates' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindCampaignsReportQuery,
  FindCampaignsReportQueryVariables
>;
export const GetContactReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContactReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReportsFilter' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getContactReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'leadCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalContacts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalContactsResponded' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetContactReportQuery,
  GetContactReportQueryVariables
>;
export const GetDialerCallReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDialerCallReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DialerReportsFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDialerCallReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'answered' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageDuration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversationRate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversations' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responseRate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDialerCallReportQuery,
  GetDialerCallReportQueryVariables
>;
export const GetDialerContactReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDialerContactReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DialerReportsFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDialerContactReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deals' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leads' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDialerContactReportQuery,
  GetDialerContactReportQueryVariables
>;
export const GetDialerCallOutcomeReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDialerCallOutcomeReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DialerReportsFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDialerCallOutcomeReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactsWithCurrentLabel' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allLabeledContacts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDialerCallOutcomeReportQuery,
  GetDialerCallOutcomeReportQueryVariables
>;
export const SessionUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'SessionUpdated' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessionUpdated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'online' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SessionUpdatedSubscription,
  SessionUpdatedSubscriptionVariables
>;
export const FindSkiptracesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindSkiptraces' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Order' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findSkiptraces' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobileContacts' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landlineContacts' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'excludeFirstRow' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fields' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SkiptraceField' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SkiptraceField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SkiptraceField' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindSkiptracesQuery, FindSkiptracesQueryVariables>;
export const FindSkiptraceFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindSkiptraceFields' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findSkiptraceFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SkiptraceField' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SkiptraceField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SkiptraceField' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindSkiptraceFieldsQuery,
  FindSkiptraceFieldsQueryVariables
>;
export const GetSkiptraceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSkiptrace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSkiptrace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mobileContacts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlineContacts' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'excludeFirstRow' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processedContacts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'foundContacts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cachedContacts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SkiptraceField' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SkiptraceField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SkiptraceField' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSkiptraceQuery, GetSkiptraceQueryVariables>;
export const RemoveSkiptracesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveSkiptraces' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveSkiptracesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeSkiptraces' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveSkiptracesMutation,
  RemoveSkiptracesMutationVariables
>;
export const UpdateSkiptraceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSkiptrace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSkiptraceInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateSkiptraceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSkiptrace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateSkiptraceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mobileContacts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landlineContacts' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'excludeFirstRow' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SkiptraceField' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SkiptraceField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SkiptraceField' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'predefined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSkiptraceMutation,
  UpdateSkiptraceMutationVariables
>;
export const RunSkiptraceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RunSkiptrace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RunSkiptraceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'runSkiptrace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RunSkiptraceMutation,
  RunSkiptraceMutationVariables
>;
export const ExportSkiptraceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExportSkiptrace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExportSkiptraceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportSkiptrace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExportSkiptraceQuery,
  ExportSkiptraceQueryVariables
>;
export const PushSkiptraceToGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PushSkiptraceToGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PushSkiptraceToGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pushSkiptraceToGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'predefined' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PushSkiptraceToGroupMutation,
  PushSkiptraceToGroupMutationVariables
>;
export const SkiptraceProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'SkiptraceProgress' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skiptraceProgress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'skiptraceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SkiptraceProgressSubscription,
  SkiptraceProgressSubscriptionVariables
>;
export const SkiptraceCompletedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'SkiptraceCompleted' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skiptraceCompleted' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'skiptraceId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SkiptraceCompletedSubscription,
  SkiptraceCompletedSubscriptionVariables
>;
export const SubscribeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Subscribe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubscribeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscribe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enterprise' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lexorank' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialLimits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'use' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mostPopular' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'couponId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Subscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CurrentSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextBillingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'membershipMonths' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coupon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscribeMutation, SubscribeMutationVariables>;
export const UnsubscribeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Unsubscribe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reason' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unsubscribe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reason' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnsubscribeMutation, UnsubscribeMutationVariables>;
export const UpdateSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSubscriptionInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enterprise' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lexorank' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialLimits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'use' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mostPopular' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'couponId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Subscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CurrentSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextBillingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'membershipMonths' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coupon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables
>;
export const EndTrialDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EndTrial' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EndTrialInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'endTrial' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Subscription' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enterprise' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lexorank' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialLimits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'use' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mostPopular' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'couponId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Subscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CurrentSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextBillingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'membershipMonths' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coupon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EndTrialMutation, EndTrialMutationVariables>;
export const VerifyPromoCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyPromoCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerifyPromoCodeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyPromoCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coupon' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amountOff' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'percentOff' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyPromoCodeMutation,
  VerifyPromoCodeMutationVariables
>;
export const ApplyDiscountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApplyDiscount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subscriptionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApplyDiscountInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyDiscount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subscriptionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coupon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplyDiscountMutation,
  ApplyDiscountMutationVariables
>;
export const SubscriptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Subscriptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextBillingDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coupon' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'percent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promoCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expiresAt' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialEndedAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'membershipMonths' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'base' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Plan' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enterprise' } },
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lexorank' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialLimits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'use' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mostPopular' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'couponId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionsQuery, SubscriptionsQueryVariables>;
export const RetrieveUpcomingInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RetrieveUpcomingInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'products' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Product' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subAccountId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retrieveUpcomingInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'products' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'products' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subAccountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subAccountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalBilledNow' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RetrieveUpcomingInvoiceQuery,
  RetrieveUpcomingInvoiceQueryVariables
>;
export const FindSuppressionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindSuppressions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SuppressionsFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Pagination' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findSuppressions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Suppression' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCreatedAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Suppression' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Suppression' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindSuppressionsQuery,
  FindSuppressionsQueryVariables
>;
export const SuppressionsSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SuppressionsSummary' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'suppressionsSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dnc' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unsubscribed' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'filtered' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'undeliverable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carrierBlocked' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SuppressionsSummaryQuery,
  SuppressionsSummaryQueryVariables
>;
export const ExportSuppressionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ExportSuppressions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExportSuppressionsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportSuppressions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExportSuppressionsQuery,
  ExportSuppressionsQueryVariables
>;
export const GetUploadDncPhonesSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUploadDNCPhonesSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GenerateSignedUrlInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUploadDNCPhonesSignedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUploadDncPhonesSignedUrlQuery,
  GetUploadDncPhonesSignedUrlQueryVariables
>;
export const UploadDncPhonesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadDNCPhones' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UploadDNCPhonesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadDNCPhones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadDncPhonesMutation,
  UploadDncPhonesMutationVariables
>;
export const CreateDncPhoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDNCPhone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateDNCPhoneInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDNCPhone' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Suppression' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Suppression' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Suppression' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDncPhoneMutation,
  CreateDncPhoneMutationVariables
>;
export const AddContactToDncDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddContactToDNC' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddContactToDNCInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addContactToDNC' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Suppression' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Suppression' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Suppression' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddContactToDncMutation,
  AddContactToDncMutationVariables
>;
export const RemoveContactFromDncSuppressionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveContactFromDNCSuppressions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RemoveContactFromDNCSuppressionsInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeContactFromDNCSuppressions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveContactFromDncSuppressionsMutation,
  RemoveContactFromDncSuppressionsMutationVariables
>;
export const RemoveSuppressionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveSuppressions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveSuppressionsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeSuppressions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveSuppressionsMutation,
  RemoveSuppressionsMutationVariables
>;
export const GetTollFreeRegistrationInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTollFreeRegistrationInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTollFreeRegistrationInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TollFreeBrand' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ownPhone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OwnPhone' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TollFreeBrand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TollFreeBrand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usecase' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageVolume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sampleMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optInWorkflow' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'optInWorkflowFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OwnPhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OwnPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagingEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTollFreeRegistrationInfoQuery,
  GetTollFreeRegistrationInfoQueryVariables
>;
export const RegisterTollFreeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegisterTollFree' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerTollFree' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterTollFreeMutation,
  RegisterTollFreeMutationVariables
>;
export const UpdateTollFreeRegistrationInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTollFreeRegistrationInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateTollFreeRegistrationInfoInput',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTollFreeRegistrationInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TollFreeBrand' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ownPhone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OwnPhone' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TollFreeBrand' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TollFreeBrand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usecase' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageVolume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sampleMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optInWorkflow' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'optInWorkflowFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'filePath' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OwnPhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OwnPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'number' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeRemoved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messagingEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTollFreeRegistrationInfoMutation,
  UpdateTollFreeRegistrationInfoMutationVariables
>;
