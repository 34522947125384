import React, { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Stack } from '@chakra-ui/react';

import { extendWithCurrentQueryParams } from '@app/utils/navigationUtils';
import { useSignOut } from '@app/hooks/useSignOut';
import { BackgroundContainer } from '@app/layouts/BackgroundContainer';
import ROUTES from '../../utils/routes';
import { SCClickableLogo } from '../SCClickableLogo';
import MenuContainer from './MenuContainer';
import { AuthHeader } from './AuthHeader';

const RootContainerForm: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { signOut, currentUser } = useSignOut();

  const handleSignOut = async () => {
    if (!!currentUser) {
      await signOut();
      return;
    }
    navigate(extendWithCurrentQueryParams(ROUTES.login));
  };

  return (
    <BackgroundContainer>
      <Flex
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        pt={['19px', '30px']}
        px={['4px', '55px']}>
        <Box cursor="pointer" width={[180, 231]} onClick={handleSignOut}>
          <SCClickableLogo />
        </Box>

        <MenuContainer>
          <AuthHeader />
        </MenuContainer>
      </Flex>

      <Stack
        alignItems="center"
        direction="column"
        height="calc(100% - 70px)"
        justifyContent={{ base: 'start', md: 'center' }}
        mt={{ base: '45px', md: '0px' }}>
        <Box p={['0 4px', '0']} width={['100%', '380px']}>
          {children}
        </Box>
      </Stack>
    </BackgroundContainer>
  );
};

export default RootContainerForm;
