import build from '../../build.json';
import { formatDate, formatDateToIso } from './formatDate';

export function getBuildDate(): Date {
  return new Date(build.buildDate);
}

export function getVersionString(
  dateFormat: 'regular' | 'full' = 'regular',
): string {
  const version = `${build.buildId}.${build.commitHash}`;

  const date = getBuildDate();

  const formattedDate =
    dateFormat === 'full'
      ? formatDateToIso({ date })
      : formatDate({
          date,
          format: 'yyyy-MM-dd',
          timeZone: 'UTC',
        });

  return `${formattedDate}.${version}`;
}
