import {
  Avatar,
  Badge,
  Box,
  Checkbox,
  Flex,
  Grid,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash/fp';
import React, { FC, SyntheticEvent, useCallback, useState } from 'react';

import DateFormat from '@app/components/DateFormat';
import { LabelList } from '@app/components/LabelList';
import { LastMessage } from '@app/pages/Messenger/components/LastMessage';
import { UnreadMessageCount } from '@app/pages/Messenger/components/MessageItem/UnreadMessageCount';
import CheckboxPartial from '@app/icons/checkbox-partial.svg?react';
import CheckboxChecked from '@app/icons/checkbox-checked.svg?react';
import AiFilterIcon from '@app/icons/ai-filtered-icon.svg?react';

import {
  Conversation,
  ConversationFragmentDoc,
  Label,
  UpdateContactMutationVariables,
  useMarkConversationsReadMutation,
} from '@app/api/gql/generated-types';
import { useUpdateContactFragment } from '@app/api/hooks/useUpdateContactFragment';
import { useUpdateContactMutation } from '@app/api/mutations/useUpdateContactMutation';
import * as typenames from '@app/api/typenames';
import { FavoriteCell } from '@app/pages/Contacts/components/TableList/FavoriteCell';
import { catchErrorLog } from '@app/utils/logger';
import {
  DATE_FORMAT_MM_DD_YYYY,
  TIME_FORMAT_HH_MM_A,
} from '@app/utils/formatDate';
import { colors } from '@app/theme/colors';
import { MessageItemProps } from './types';

export const MessageItem: FC<MessageItemProps> = ({
  isActive,
  isChecked,
  onClick,
  onSelectChange,
  contactItem,
  canUpdateFavorite,
  accountId,
  isInactiveAccount,
  isPaymentFailure,
  contactId,
  categoryUnread,
}) => {
  const [isFavourite, setIsFavourite] = useState(contactItem.isFavorite);
  const { updateContactFragment } = useUpdateContactFragment();
  const [mutate] = useUpdateContactMutation();
  const [mutateSetRead] = useMarkConversationsReadMutation({
    notifyOnNetworkStatusChange: true,
  });
  const location = useLocation();
  const isOptedOutRoute = location.pathname?.includes('opted-out');
  const onContactUpdate = async (values: UpdateContactMutationVariables) => {
    try {
      setIsFavourite(values.input?.favorite);
      updateContactFragment({
        contactId: values.id,
        favorite: values.input?.favorite,
      });

      await mutate({
        variables: values,
      });
    } catch (error) {
      catchErrorLog(error, 'MessageItem/onContactUpdate');
    }
  };

  const itemBackground = (() => {
    if (isChecked) {
      return 'primary.100';
    }
    if (isActive) {
      return 'zircon';
    }

    return 'white';
  })();

  const onSetMessageRead = useCallback(() => {
    // unread Category first conversation we don't mark as read
    if (categoryUnread && !contactId) {
      return;
    }

    void mutateSetRead({
      variables: {
        input: {
          ids: [categoryUnread ? contactId : contactItem?.id],
          isRead: true,
        },
      },
      update: (cache) => {
        cache.updateFragment<Conversation>(
          {
            id: `${typenames.Conversation}:${categoryUnread ? contactId : contactItem?.id}`,
            fragment: ConversationFragmentDoc,
            fragmentName: typenames.Conversation,
          },
          (data) => ({
            ...data,
            isRead: true,
          }),
        );
      },
    });
  }, [categoryUnread, contactId, contactItem?.id, mutateSetRead]);

  const onMessageClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      if (
        contactItem?.__typename === typenames.Conversation &&
        !contactItem?.isRead
      ) {
        if (isInactiveAccount) {
          return onClick(contactItem);
        }
        onSetMessageRead();
      }

      onClick(contactItem);
    },
    [contactItem, isInactiveAccount, onClick, onSetMessageRead],
  );

  const lastMessageAt =
    contactItem.updatedAt && new Date(contactItem.updatedAt);

  const showMessageCounter = contactItem?.lastMessage && !contactItem.isRead;
  const showMessageCounterEmpty =
    contactItem?.lastMessage &&
    !contactItem.isRead &&
    !contactItem.unreadMessages;

  return (
    <Box
      key={contactItem?.id}
      _before={
        isActive
          ? {
              content: '" "',
              position: 'absolute',
              width: '3px',
              backgroundColor: colors.switchedBlue[500],
              height: '65px',
              left: 0,
              top: '-1px',
            }
          : {}
      }
      _hover={{
        backgroundColor: !isChecked && 'zircon',
      }}
      background={itemBackground}
      borderBottom="1px"
      borderBottomColor="mystic"
      cursor="pointer"
      position="relative"
      width="100%"
      zIndex="9"
      onClick={onMessageClick}>
      <Flex
        alignItems="center"
        height="62px"
        justifyContent="flex-start"
        justifyItems="stretch">
        <Flex
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          onClick={(event) => onSelectChange(contactItem.id, isChecked, event)}>
          <Checkbox
            icon={isChecked ? <CheckboxChecked /> : <CheckboxPartial />}
            isChecked={!!isChecked}
            m="0 15px"
            variant="primary"
          />

          <Box
            data-cy="messenger-avatar-icon"
            id="messenger-avatar-icon"
            position="relative">
            <Avatar name={contactItem.name} />
            {showMessageCounter && (
              <UnreadMessageCount
                count={Number(contactItem.unreadMessages)}
                empty={showMessageCounterEmpty}
                size={17}
              />
            )}
          </Box>
        </Flex>

        <Grid
          alignItems="center"
          templateColumns={
            isOptedOutRoute
              ? '228px 90px minMax(20px, 1fr)'
              : '228px minMax(20px, 1fr)'
          }
          w="full">
          <Flex>
            <FavoriteCell
              canUpdate={canUpdateFavorite}
              id={contactItem.id}
              isFavorite={isFavourite}
              mb="4px"
              mx="15px"
              p="0"
              onChangeFavorite={onContactUpdate}
            />
            <Text
              color="main.400"
              data-cy="messenger-author-name-text"
              fontSize="16px"
              fontWeight={contactItem.isRead ? '500' : '700'}
              id="messenger-author-name-text"
              lineHeight="18px"
              ml="-6px"
              mr="15px">
              {contactItem?.name}
            </Text>
          </Flex>
          {isOptedOutRoute && contactItem?.isAiFiltered && (
            <Flex w="86px">
              <Stack mr="10px">
                <Badge
                  bg="lavenderWeb"
                  borderRadius="15px"
                  px="8px"
                  py="4px"
                  textTransform="none">
                  <Flex fontSize="12px">
                    <HStack mr="5px">
                      <AiFilterIcon />
                    </HStack>
                    <Text color="main.400" fontWeight="400">
                      AI filtered
                    </Text>
                  </Flex>
                </Badge>
              </Stack>
            </Flex>
          )}
          <Box maxW="95%" ml="15px">
            <LastMessage
              contactItem={contactItem}
              isInactiveAccount={isInactiveAccount}
              isPaymentFailure={isPaymentFailure}
            />
          </Box>
        </Grid>

        <Flex alignItems="center" direction="column" ml="15px" mr="15px">
          <Text
            color="main.400"
            data-cy="messenger-date-of-message-text"
            fontSize="12px"
            id="messenger-date-of-message-text"
            lineHeight="14px"
            mb="3px">
            <DateFormat date={lastMessageAt} format={DATE_FORMAT_MM_DD_YYYY} />
          </Text>
          <Text
            color="cadetBlue"
            data-cy="messenger-time-of-message-text"
            fontSize="12px"
            id="messenger-time-of-message-text"
            lineHeight="14px">
            <DateFormat date={lastMessageAt} format={TIME_FORMAT_HH_MM_A} />
          </Text>
        </Flex>

        <Box
          minWidth="250px"
          mr="15px"
          whiteSpace="nowrap"
          width="250px"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}>
          {!isEmpty(contactItem?.labels) && (
            <LabelList
              editable
              accountId={accountId}
              contactId={contactItem.id}
              labels={contactItem.labels as Label[]}
              visibleItemsCount={2}
            />
          )}
        </Box>
      </Flex>
    </Box>
  );
};
